import React, { DragEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { formatPicture } from '../../core/services/image.service';
import { ImagePlaceholder } from './image-placeholder';
import type { Frame } from '../../lib/utils/transform-image';
import { toastPool } from '../../features/toasts/models/toast-pool';
import { Toast } from '../../features/toasts/models/toast';

interface CoverImageProps {
  title: string;
  src?: string;
  onEdit?: (file: Frame) => void;
  onDoubleClick?: () => void;
  onClick?: () => void;
}

export const CoverImage: React.FC<CoverImageProps> = observer(
  ({ src, title, onEdit, onDoubleClick, onClick }) => {
    const [hasError, setHasError] = useState(!src);
    const [overlay, setOverlay] = useState(false);

    useEffect(() => {
      setHasError(!src);
    }, [src]);

    const setImageError = () => setHasError(true);

    const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();

      if (e.dataTransfer.types.find((el) => el === 'Files')) {
        setOverlay(true);
      }
    };

    const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setOverlay(false);
    };

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
    };

    const handleDoubleClick = () => {
      if (!onDoubleClick) {
        return;
      }

      onDoubleClick();
    };

    const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();

      if (!onEdit) {
        return;
      }

      setOverlay(false);

      const f = Array.from(e.dataTransfer.items).map((item) => item);

      if (f.filter((el) => el.kind === 'string').length > 0) {
        return;
      }

      const file = Array.from(e.dataTransfer.items)[0].getAsFile();

      if (!file) return;

      if (file.type === 'image/gif') {
        const frame: Frame = {
          data: file,
          size: file.size,
          type: file.type,
        };

        return onEdit(frame);
      }

      if (!['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) {
        toastPool.insert(new Toast('Invalid file type.', 'error'));
        return;
      }
      const options = { width: 640, height: 380 };
      const frame = await formatPicture(file, options);

      onEdit(frame);
    };

    const handleImageChange = async (file: File) => {
      if (!onEdit) {
        return;
      }

      if (file) {
        if (!['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) return;
        const options = { width: 640, height: 380 };
        const frame = await formatPicture(file, options);
        onEdit(frame);
      }
    };

    return (
      <CoverHolder
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={onClick}
      >
        {!hasError ? (
          <Image
            loading="lazy"
            src={src}
            alt={title}
            onDoubleClick={handleDoubleClick}
            onError={setImageError}
          />
        ) : (
          <ImagePlaceholder onChange={onEdit && handleImageChange} />
        )}

        <EditOverlay show={overlay}>Edit picture</EditOverlay>
      </CoverHolder>
    );
  },
);

const CoverHolder = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 0.4rem;
  border-radius: 0.6rem;
  overflow: hidden;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  box-shadow: none;
  cursor: pointer;
  border-radius: 0.6rem;
`;

interface EditOverlayProps {
  show: boolean;
}

const EditOverlay = styled.div<EditOverlayProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  color: lightgray;
  font-size: 2.4rem;
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: all 0.2s ease-in-out;
  border-radius: 0.6rem;
  z-index: 1;
`;
