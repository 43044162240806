import { action, makeObservable, observable } from 'mobx';

import { Shot, type UpdateShotsInput } from '../../app/entities/shot';
import { Toast } from '../../features/toasts/models/toast';
import { toastPool } from '../../features/toasts/models/toast-pool';

class BulkUpdate {
  shouldOpenModal = false;
  shotsCount = 1;
  onUpdate: () => void;
  onCancel: () => void;

  constructor() {
    makeObservable(this, {
      shouldOpenModal: observable,
      shotsCount: observable,
      setShouldOpenModal: action,
      setShotsCount: action,
      setOnCancel: action,
      setOnUpdate: action,
    });
  }

  setShouldOpenModal(value = false) {
    this.shouldOpenModal = value;
  }

  setShotsCount(value: number) {
    this.shotsCount = value;
  }

  setOnCancel(cb: () => void) {
    this.onCancel = cb;
  }

  setOnUpdate(cb: () => void) {
    this.onUpdate = cb;
  }

  handleShotsBulkUpdate = (data: UpdateShotsInput, selectedShotsIds: string[], shot: Shot) => {
    if (!shot) return;

    const isBulkUpdate = selectedShotsIds && selectedShotsIds.length > 1;

    const isShotIncludedInSelected = selectedShotsIds.includes(shot._id);
    if (isBulkUpdate && !isShotIncludedInSelected) {
      toastPool.insert(new Toast('Change is done outside multi-selection.', 'warning'));
      return;
    }

    const isBulkUpdatePermitted = JSON.parse(
      localStorage.getItem('isBulkUpdatePermitted') || 'false',
    );

    if (!isBulkUpdatePermitted) {
      this.setOnCancel(() => this.setShouldOpenModal(false));
      this.setOnUpdate(() => Shot.bulkUpdateShots(selectedShotsIds, shot.projectId, data));
      this.setShotsCount(selectedShotsIds?.length);
      this.setShouldOpenModal(true);
    } else {
      Shot.bulkUpdateShots(selectedShotsIds, shot.projectId, data);
    }
  };
}

export const bulkUpdateStore = new BulkUpdate();
