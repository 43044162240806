import { action, makeObservable, observable } from 'mobx';

import { User } from '../../app/entities/user';
import { Member } from '../../app/entities/member';

class AuthStore {
  private _currentUser: User | null = null;
  isAuthorized = true;

  constructor() {
    makeObservable(this, {
      isAuthorized: observable,
      setAuthorized: action,
    });
  }

  setAuthorized(isAuthorized: boolean) {
    this.isAuthorized = isAuthorized;
  }

  get currentUser(): User | null {
    if (!this._currentUser) {
      const users = User.getAll();
      if (users.length === 0) {
        return null;
      }
      this._currentUser = users[0];
    }

    return this._currentUser;
  }

  getCurrentMember(spaceId: string): Member | undefined {
    return this.currentUser?.members.find((member) => member.spaceId === spaceId);
  }
}

export const authStore = new AuthStore();
