import Rect from 'react';

export const ClapperIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.02235 2H12.9783C12.993 1.99968 13.0076 1.99967 13.0224 2H17.9783C17.993 1.99968 18.0076 1.99967 18.0224 2H20C21.6569 2 23 3.34315 23 5V19C23 20.6569 21.6569 22 20 22H4C2.34315 22 1 20.6569 1 19V8V5C1 3.34315 2.34315 2 4 2H7.97831C7.99296 1.99968 8.00764 1.99967 8.02235 2ZM3 9V19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19V9H16.0236C16.0077 9.00038 15.9918 9.00038 15.9758 9H11.0236C11.0077 9.00038 10.9918 9.00038 10.9758 9H6.02359C6.00771 9.00038 5.99179 9.00038 5.97583 9H3ZM21 7V5C21 4.44772 20.5523 4 20 4H18.6771L17.4771 7H21ZM16.523 4H13.6771L12.4771 7H15.323L16.523 4ZM11.523 4H8.67706L7.47706 7H10.323L11.523 4ZM6.523 4L5.323 7H3V5C3 4.44772 3.44772 4 4 4H6.523ZM10.1223 16.3493V12.6512C10.1224 12.4189 10.2464 12.2043 10.4476 12.0882C10.6488 11.9721 10.8966 11.9722 11.0978 12.0885L14.2975 13.9376C14.4985 14.0537 14.6223 14.2682 14.6223 14.5003C14.6223 14.7324 14.4985 14.9469 14.2975 15.063L11.0978 16.9121C10.8966 17.0283 10.6488 17.0284 10.4476 16.9123C10.2464 16.7963 10.1224 16.5816 10.1223 16.3493Z"
      fill="white"
    />
  </svg>
);
