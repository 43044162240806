import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';

import { BaseDialog, DialogHeader, DialogFooter } from '../../../components/dialog';
import { ElevatedButton, TextButton } from '../../../components/buttons';
import { DateInput } from '../../../components/date-input/DateInput';

interface DateSelectionModalProps {
  onSubmit: (date: string | null) => Promise<void>;
  onCancel: () => void;
  fieldName: 'dueDate' | 'startDate';
  value?: Date | string;
  dateValidator?: (date: string) => boolean;
}

export const DateSelectionModal: React.FC<DateSelectionModalProps> = ({
  onSubmit,
  onCancel,
  fieldName,
  value,
  dateValidator,
}) => {
  const { t } = useTranslation('step');
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const schema = useMemo(
    () =>
      yup.object({
        date: yup.string().nullable(),
      }),
    [],
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: {
      date: value ? DateTime.fromJSDate(new Date(value!)).toFormat('yyyy-MM-dd') : '',
    },
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!dateValidator || !value) return;

    const isValid = dateValidator(value.toString());
    if (isValid) return;

    const message =
      fieldName === 'dueDate'
        ? 'Due date cannot be earlier than the start date.'
        : "Start date can't be after due date.";

    setIsDisabled(true);
    setError('date', { message });
  }, []);

  const onHandleSubmit = async (values: any) => {
    if (onSubmit && !isLoading) {
      setIsLoading(true);

      /**
       * The date is receive in year-month-day format, so we need to convert it to a Date object
       * before sending it to the server to get the timezone of the user.
       */
      const date = DateTime.fromFormat(`${values.date} 12:00`, 'yyyy-MM-dd hh:mm').toISO();

      await onSubmit(date);

      setIsLoading(false);
      onCancel();
    }
  };

  const handleDateRevalidate = (input: any) => {
    if (!dateValidator) {
      setValue('date', input.target.value);
      return;
    }

    const isValid = dateValidator(input.target.value);
    if (isValid) {
      setIsDisabled(false);
      setValue('date', input.target.value);
      clearErrors('date');
      return;
    }

    const message =
      fieldName === 'dueDate'
        ? 'Due date cannot be earlier than the start date.'
        : "Start date can't be after due date.";

    setIsDisabled(true);
    setError('date', { message });
  };

  return (
    <BaseDialog onClose={onCancel}>
      <DialogHeader
        title={fieldName === 'startDate' ? 'Set start date' : 'Set due date'}
        onClose={onCancel}
      />

      <Container>
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          <DateInput
            {...register('date', dateValidator && { validate: { dateValidator } })}
            dark={true}
            label={t(fieldName === 'dueDate' ? 'due_date' : 'start_date') || ''}
            errorMessage={errors.date?.message}
            onChange={handleDateRevalidate}
          />
        </form>
      </Container>

      <DialogFooter
        actions={[
          <TextButton key="cancel" text="Cancel" onClick={onCancel} />,
          <ElevatedButton
            key="submit"
            isLoading={isLoading}
            disabled={isLoading || isDisabled}
            onClick={handleSubmit(onHandleSubmit)}
            text="Update"
          />,
        ]}
      />
    </BaseDialog>
  );
};

const Container = styled.div`
  overflow-y: scroll;
  padding: 1.6rem;
`;
