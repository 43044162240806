import styled from 'styled-components';

export const Label = styled.div`
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: var(--color-grayscale-white);
  font-size: 1.4rem;
  margin-bottom: 0.8rem;

  b {
    color: var(--color-primary-telemagenta);
  }
`;

export const Help = styled.div`
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
  color: var(--color-grayscale-light-slate);
  font-weight: 400;
`;

export const Error = styled.span`
  position: absolute;
  right: 0;
  bottom: -1.8rem;
  color: var(--color-error);
  font-family: Inter, sans-serif;
  font-size: 1rem;
  letter-spacing: -0.2px;
  text-align: right;
`;
