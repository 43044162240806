import { computed, makeObservable, observable } from 'mobx';

import { Model } from '../../core/engine/model';
import { ManyToOne } from '../../core/engine/decorators';
import { api } from '../../api';
import { upload } from '../../core/services/file.service';
import { getNameInitials } from '../../lib/utils/get-name-initials';
import { getFullName } from '../../lib/utils/get-full-name';

import type { Space } from './space';

export class Contact extends Model {
  /** The contact's first name **/
  firstName: string;

  /** The contact's last name **/
  lastName: string;

  /** The contact's email **/
  email: string;

  /** The contact's avatar **/
  avatar: string;

  /** The contact's country **/
  country: string = '';

  /** The contact's city **/
  city: string = '';

  /** A link to the contact's website **/
  website: string;

  /** A description of food preferences **/
  foodPreferences: string;

  /** **/
  driverLicense: string;

  /** The contact's main position **/
  role: string;

  /** A free form text **/
  notes: string;

  /** The contact's phone number **/
  phone?: string;

  /** Reference to the space owning this contact **/
  spaceId: string | null = null;

  @ManyToOne('contacts')
  public space: Space | null = null;

  get initials() {
    return getNameInitials(this);
  }

  get fullName() {
    return getFullName(this) || 'Unnamed';
  }

  constructor() {
    super('contacts');

    makeObservable(this, {
      firstName: observable,
      lastName: observable,
      avatar: observable,
      email: observable,
      country: observable,
      city: observable,
      phone: observable,
      website: observable,
      role: observable,
      fullName: computed,
      initials: computed,
    });
  }

  toPOJO(): Record<string, any> {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      avatar: this.avatar,
      email: this.email,
      country: this.country,
      city: this.city,
      phone: this.phone,
      website: this.website,
      role: this.role,
      foodPreferences: this.foodPreferences,
      driverLicense: this.driverLicense,
      notes: this.notes,
    };
  }

  static getOne(id: string): Contact | undefined {
    return Model.getOne(id) as Contact;
  }

  async update({ file, avatar, ...update }: Partial<Omit<Contact, '_id'> & { file: File }>) {
    const input: any = {
      firstName: update.firstName,
      lastName: update.lastName,
      city: update.city,
      country: update.country,
      role: update.role,
      website: update.website,
      foodPreferences: update.foodPreferences,
      driverLicense: update.driverLicense,
      phone: update.phone,
      notes: update.notes,
      email: update.email,
    };

    if (file) {
      input.file = {
        fileSize: file.size,
        fileType: file.type,
      };
    }

    const { data } = await api.patch(`/contacts/${this._id}`, input);

    if (data.links && file) {
      await upload(data.links.upload, file);
    }

    Object.assign(this, {
      ...data,
      ...(typeof data.phone === 'string'
        ? { phone: data.phone }
        : { phone: data.phone?.number || '' }),
    });
  }
}
