import React from 'react';
import styled from 'styled-components';
import { Link2Icon } from '@radix-ui/react-icons';

type SettingsActionProps = {
  title: string;
  icon: React.ReactNode;
  action: React.ReactNode;
  name?: string;
  hint?: string;
};

export const SettingsAction: React.FC<SettingsActionProps> = ({
  title,
  icon,
  action,
  name,
  hint,
}) => (
  <Container>
    {name && <Name>{name}</Name>}

    <Card>
      <Header>
        <Content>
          <Icon>{icon}</Icon>
          <Title>{title}</Title>
        </Content>

        {action}
      </Header>

      {hint && (
        <Footer>
          <Icon>
            <Link2Icon />
          </Icon>
          <span dangerouslySetInnerHTML={{ __html: hint }} />
        </Footer>
      )}
    </Card>
  </Container>
);

const Container = styled.div`
  &:not(:first-child) {
    margin-top: 3.2rem;
  }
`;

const Card = styled.div`
  background-color: rgba(255, 255, 255, 5%);
  border-radius: var(--border-radius-medium);
`;

const Header = styled.div`
  padding: 0 1.6rem;
  height: 5.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.8rem;
  margin-right: 1.6rem;
  color: var(--color-grayscale-white);
`;

const Title = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;

  line-height: 2.4rem;
  color: var(--color-grayscale-white);
`;

const Name = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;

  line-height: 2.4rem;
  color: #e6edf3;

  margin-bottom: 1.6rem;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  border-top: solid 1px rgb(61, 68, 77);
  padding: 0 1.6rem;
  height: 5.6rem;
  font-size: 1.2rem;
  color: #9198a1;

  svg {
    color: #9198a1;
  }

  a {
    color: rgb(68, 147, 248);
  }
`;
