import React from 'react';
import styled from 'styled-components';

import { ProfileAvatar, ProfilePictureProps } from '../profile-avatar/ProfileAvatar';

type SettingsAvatarProps = {
  title: string;
  detail: string;
} & ProfilePictureProps;

export const SettingsAvatar: React.FC<SettingsAvatarProps> = ({ detail, title, ...props }) => (
  <Container>
    <Header>
      <Title>{title}</Title>
      <Detail>{detail}</Detail>
    </Header>

    <Wrapper>
      <ProfileAvatar {...props} />
    </Wrapper>
  </Container>
);

const Container = styled.div`
  width: 100%;
  border-radius: var(--border-radius-medium);
  border: 1.5px dashed var(--color-secondary);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Header = styled.div`
  background: rgba(54, 123, 255, 0.2);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1.6rem;
  align-items: center;
  margin-bottom: 0.8rem;
`;

const Title = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  color: #ffffff;
`;

const Detail = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  text-align: right;
  color: var(--color-grayscale-light-slate);
`;

const Wrapper = styled.div`
  flex: 1;
  max-height: calc(100% - 4.8rem);

  img {
    object-fit: contain;
  }
`;
