import React from 'react';
import { format, isToday, isYesterday } from 'date-fns';
import { CheckIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { FilePreview } from '../../../components/icons/FilePreview';
import { breakPoint } from '../../../app/theme';

type VersionListItemProps = {
  version: number;
  name: string;
  date: Date;
  uploader: string;
  onClick: () => void;
  thumbnail?: string;
  fileType?: string;
  extension?: string;
  isSelected?: boolean;
};

export const VersionListItem: React.FC<VersionListItemProps> = ({
  version,
  name,
  date,
  uploader,
  thumbnail,
  extension,
  isSelected = false,
  onClick,
}) => (
  <Item onClick={onClick}>
    <Version data-selected={isSelected}>
      <CheckIcon color="var(--color-primary-crayola)" />

      <div>
        <span>v</span>
        {version}
      </div>
    </Version>

    {thumbnail ? (
      <Thumbnail src={thumbnail} />
    ) : (
      <FilePreviewWrapper>
        <FilePreview text={extension} />
      </FilePreviewWrapper>
    )}

    <Detail data-selected={isSelected}>
      <h3>{name}</h3>
      <span>
        {uploader && `${uploader} - `}
        {date && isToday(new Date(date))
          ? 'Today'
          : isYesterday(new Date(date))
          ? 'Yesterday'
          : format(new Date(date), 'MMM dd, yyyy')}
        , {date && format(new Date(date), 'hh:mm a')}
      </span>
    </Detail>
  </Item>
);

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.8rem 1.6rem;
  gap: 1.2rem;
  min-width: 0;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-grayscale-tuna);
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    gap: unset;
    padding: 1.2rem 0.8rem;
    transition: background 0.2s ease;
    border-radius: var(--border-radius-medium);

    &:hover {
      background: var(--color-grey-6);
      cursor: pointer;
    }

    &[data-highlighted] {
      background: var(--color-grey-6);
    }
  }
`;

const Version = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-grayscale-light-slate);

  & span {
    text-transform: uppercase;
  }

  &[data-selected='false'] {
    & svg {
      visibility: hidden;
    }
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    min-width: 3rem;
    margin-right: 0.8rem;
    color: var(--color-grayscale-white);

    &[data-selected='true'] {
      color: var(--color-primary-crayola);
    }

    span {
      opacity: 0.5;
      text-transform: unset;
    }

    & svg {
      display: none;
    }
  }
`;

const Thumbnail = styled.img`
  width: 7.6rem;
  min-width: 7.6rem;
  flex-basis: 0;
  height: 4.275rem;
  object-fit: cover;
  border-radius: 0.6rem;

  @media screen and (max-width: ${breakPoint.medium - 1}px) {
    display: none;
  }
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.6rem;
  gap: 0.4rem;
  flex-grow: 1;
  min-width: 0;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 500;

  * {
    max-width: 100%;
  }

  h3 {
    color: var(--color-grayscale-white);
    font-size: 1.2rem;
    font-weight: 300;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.6rem;
    color: var(--color-grayscale-shuttle);
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    h3 {
      color: var(--color-grayscale-white);
      font-size: 1.6rem;
      font-weight: unset;
    }

    span {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: normal;
    }

    &[data-selected='true'] {
      & h3 {
        color: var(--color-primary-crayola);
      }
    }
  }
`;

const FilePreviewWrapper = styled.div`
  width: 7.6rem;
  min-width: 7.6rem;
  height: 4.275rem;
  border-bottom: 0 !important;

  svg {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: ${breakPoint.medium - 1}px) {
    display: none;
  }
`;
