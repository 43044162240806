import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.6rem;
  background-color: var(--color-grayscale-tuna);
  border-radius: 0.4rem 0.4rem 0 0;
`;

export const Title = styled.h1`
  line-height: 3.2rem;
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--color-grayscale-white);
`;

export const TableContainer = styled.div`
  padding: 0 1.6rem;
  background-color: var(--color-grayscale-shark);

  & > * {
    #collapsible-trigger {
      padding: 1.6rem 0;
    }

    #collapsible-content {
      padding: 0.8rem 0 1.6rem;
    }

    &:not(:last-child) {
      border-bottom: 0.1rem solid var(--color-grayscale-trout);
    }
  }
`;

export const RowTitle = styled.h2`
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 500;
  color: var(--color-grayscale-anti-flash-white);
`;

export const RowSubtitle = styled.h6`
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 400;
  color: var(--color-grayscale-manatee);
`;

export const RowHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  &[data-hidden='true'] {
    ${RowTitle} , ${RowSubtitle} {
      color: var(--color-grayscale-trout);
    }
  }
`;

export const VerticalCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const HorizontalCell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CellLabel = styled.span`
  color: var(--color-grayscale-light-slate);
  font-size: 1.2rem;
  font-weight: 400;
`;

export const CellContent = styled.span`
  color: var(--color-grayscale-white);
  font-size: 1.2rem;
  font-weight: 400;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  &[data-hidden='true'] {
    ${CellContent} {
      color: var(--color-grayscale-trout);
    }
  }
`;
