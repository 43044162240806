import React from 'react';

export const FilePreviewAudio = () => (
  <svg width="211" height="211" viewBox="0 0 91 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_397_326)">
      <path
        d="M42.7085 50.25C42.7085 51.791 43.9592 53.0417 45.5002 53.0417C47.0412 53.0417 48.2918 51.791 48.2918 50.25V16.75C48.2918 15.209 47.0412 13.9583 45.5002 13.9583C43.9592 13.9583 42.7085 15.209 42.7085 16.75V50.25Z"
        fill="#367BFF"
      />
      <path
        d="M14.7917 16.75C13.2507 16.75 12 18.0007 12 19.5417V47.4583C12 48.9993 13.2507 50.25 14.7917 50.25C16.3327 50.25 17.5833 48.9993 17.5833 47.4583V19.5417C17.5833 18.0007 16.3327 16.75 14.7917 16.75Z"
        fill="#324872"
      />
      <path
        opacity="0.8"
        d="M38.5208 44.6667V22.3333C38.5208 20.7923 37.2702 19.5417 35.7292 19.5417C34.1882 19.5417 32.9375 20.7923 32.9375 22.3333V44.6667C32.9375 46.2077 34.1882 47.4583 35.7292 47.4583C37.2702 47.4583 38.5208 46.2077 38.5208 44.6667Z"
        fill="#367BFF"
      />
      <path
        opacity="0.6"
        d="M25.9582 41.875C27.4992 41.875 28.7498 40.6243 28.7498 39.0833V27.9167C28.7498 26.3757 27.4992 25.125 25.9582 25.125C24.4172 25.125 23.1665 26.3757 23.1665 27.9167V39.0833C23.1665 40.6243 24.4172 41.875 25.9582 41.875Z"
        fill="#367BFF"
      />
      <path
        opacity="0.8"
        d="M58.0623 44.6667V22.3333C58.0623 20.7923 56.8117 19.5417 55.2707 19.5417C53.7297 19.5417 52.479 20.7923 52.479 22.3333V44.6667C52.479 46.2077 53.7297 47.4583 55.2707 47.4583C56.8117 47.4583 58.0623 46.2077 58.0623 44.6667Z"
        fill="#367BFF"
      />
      <path
        d="M76.2082 16.75C74.6672 16.75 73.4165 18.0007 73.4165 19.5417V47.4583C73.4165 48.9993 74.6672 50.25 76.2082 50.25C77.7492 50.25 78.9998 48.9993 78.9998 47.4583V19.5417C78.9998 18.0007 77.7492 16.75 76.2082 16.75Z"
        fill="#324872"
      />
      <path
        d="M65.0417 25.125C63.5007 25.125 62.25 26.3757 62.25 27.9167V39.0833C62.25 40.6243 63.5007 41.875 65.0417 41.875C66.5827 41.875 67.8333 40.6243 67.8333 39.0833V27.9167C67.8333 26.3757 66.5827 25.125 65.0417 25.125Z"
        fill="#345EAF"
      />
      <path
        d="M42.7085 64.2083C42.7085 65.7493 43.9592 67 45.5002 67C47.0412 67 48.2918 65.7493 48.2918 64.2083V61.4167C48.2918 59.8757 47.0412 58.625 45.5002 58.625C43.9592 58.625 42.7085 59.8757 42.7085 61.4167V64.2083Z"
        fill="#367BFF"
      />
      <path
        d="M45.5002 8.375C47.0412 8.375 48.2918 7.12434 48.2918 5.58333V2.79167C48.2918 1.25066 47.0412 0 45.5002 0C43.9592 0 42.7085 1.25066 42.7085 2.79167V5.58333C42.7085 7.12434 43.9592 8.375 45.5002 8.375Z"
        fill="#367BFF"
      />
    </g>
    <path
      d="M87.7917 25.125C86.2507 25.125 85 26.3757 85 27.9167V39.0833C85 40.6243 86.2507 41.875 87.7917 41.875C89.3327 41.875 90.5833 40.6243 90.5833 39.0833V27.9167C90.5833 26.3757 89.3327 25.125 87.7917 25.125Z"
      fill="#32415E"
    />
    <path
      d="M2.79167 25.125C1.25066 25.125 0 26.3757 0 27.9167V39.0833C0 40.6243 1.25066 41.875 2.79167 41.875C4.33267 41.875 5.58333 40.6243 5.58333 39.0833V27.9167C5.58333 26.3757 4.33267 25.125 2.79167 25.125Z"
      fill="#32415E"
    />
    <defs>
      <clipPath id="clip0_397_326">
        <rect width="67" height="67" fill="white" transform="translate(12)" />
      </clipPath>
    </defs>
  </svg>
);
