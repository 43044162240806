import { Model } from '../../core/engine/model';
import { computed, makeObservable } from 'mobx';

import { OneToMany } from '../../core/engine/decorators';
import { getNameInitials } from '../../lib/utils/get-name-initials';
import { getFullName } from 'lib/utils/get-full-name';

import type { Member } from './member';

export class User extends Model {
  email: string;
  firstName: string;
  lastName: string;
  avatar: string;
  timezone: string;
  title: string;
  location: string;
  phone: string;

  @OneToMany('user')
  members: Member[] = [];

  novuSubscriberId: string;
  deviceTokens: string[];

  notifications: {
    enabled: boolean;
    preferences: Array<{ type: string; enabled: boolean }>;
  };

  get initials() {
    return getNameInitials(this);
  }

  get fullName() {
    return getFullName(this);
  }

  constructor() {
    super('users');
    this.members = [];
    makeObservable(this, {
      initials: computed,
      fullName: computed,
      email: true,
      firstName: true,
      lastName: true,
      avatar: true,
      timezone: true,
      title: true,
      location: true,
      phone: true,
      members: true,
      novuSubscriberId: true,
      deviceTokens: true,
      notifications: true,
    });
  }

  toPOJO(): Record<string, any> {
    const pojo = {
      _id: this._id,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      avatar: this.avatar,
      timezone: this.timezone,
      title: this.title,
      location: this.location,
      phone: this.phone,
      novuSubscriberId: this.novuSubscriberId,
      deviceTokens: this.deviceTokens,
    };

    // @ts-ignore
    Object.keys(pojo).forEach((key) => pojo[key] === undefined && delete pojo[key]);

    return pojo;
  }

  static getOne(id: string): User | undefined {
    return Model.getOne(id) as User;
  }

  static getAll(): User[] {
    return Model.getAll().filter((model) => model instanceof User) as User[];
  }
}
