import React, { useState } from 'react';
import styled from 'styled-components';

import { addLeadingCharacter } from '../../../lib/time-formatters';
import { TimeSelector } from './TimeSelector';
import { timeTo12HourFormat } from '../../../lib/utils/Convertor';
import { KeyboardKeys } from 'assets/enums/keyboard-keys.enum';

import type { TypeTime } from '../models/types';

interface InlineTimeSelectorProps {
  time: {
    hour: number;
    minute: number;
    period?: 'am' | 'pm';
  };
  disabled?: boolean;
  onChange?: (time: TypeTime) => void;
  textToDisplay?: string;
  backgroundVisible?: boolean;
  style?: React.CSSProperties;
}

export const InlineTimeSelector: React.FC<InlineTimeSelectorProps> = ({
  time = { hour: 0, minute: 0 },
  disabled,
  onChange,
  textToDisplay,
  backgroundVisible,
  style = {},
}) => {
  const [showTimeSelector, setShowTimeSelector] = useState(false);

  const handleTimeChange = async (value: TypeTime) => {
    if (onChange) {
      await onChange(value);
    }
    setShowTimeSelector(false);
  };

  let callTime = time;

  if (time && !time?.period) {
    callTime = timeTo12HourFormat(time);
  }

  const { hour, minute, period } = callTime;
  const renderedTime = `${hour}:${addLeadingCharacter(minute.toString(), 2)}`;

  return (
    <>
      <TimeDisplay
        data-background-visible={backgroundVisible}
        {...(!disabled && { tabIndex: 0 })}
        onKeyDown={(e) => {
          if (disabled) {
            return;
          }

          if (e.key === KeyboardKeys.Enter) {
            setShowTimeSelector((prev) => !prev);
          }
        }}
        onClick={() => {
          if (disabled) {
            return;
          }

          setShowTimeSelector((prev) => !prev);
        }}
        style={style}
        data-disabled={disabled}
      >
        {textToDisplay}
        {renderedTime}
        <span>{period}</span>
      </TimeDisplay>

      {showTimeSelector && (
        <TimeSelector
          onTimeChange={handleTimeChange}
          time={callTime}
          onCancel={() => setShowTimeSelector(false)}
        />
      )}
    </>
  );
};

export const TimeDisplay = styled.div`
  display: inline-block;
  font-size: 1.4rem;
  color: var(--color-grayscale-ghost);
  border-radius: 0.4rem;
  margin: 0 -1.2rem;
  padding: 0.8rem 1.2rem;
  transition: all 100ms ease-in-out;
  cursor: pointer;
  text-align: center;

  &[data-background-visible='true'] {
    background-color: #31343c;
    color: var(--color-grayscale-white, #fafafa);
  }

  &:hover,
  &:focus-visible {
    cursor: pointer;
    color: var(--color-grayscale-white);
    background-color: var(--color-grayscale-arsenic);
  }

  &:active {
    border-color: var(--color-secondary);
  }

  &[data-disabled='true'] {
    &:hover,
    &:focus-visible {
      cursor: default;
      background-color: transparent;
      color: var(--color-grayscale-ghost);
    }
  }
`;
