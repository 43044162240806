import React from 'react';
import styled from 'styled-components';

import type { Asset } from '../../../../app/entities/asset';

type VideoCompareProps = {
  versions: Asset[];
  onSelect: (version: string) => void;
};

export const ChooseVideoVersion: React.FC<VideoCompareProps> = ({ versions, onSelect }) => (
  <Container>
    <Header>Versions to compare</Header>

    <ModalContainer>
      {versions?.length ? (
        versions.map((asset) => (
          <VersionItemWrapper key={asset._id} onClick={() => onSelect(asset._id)}>
            <VersionItem>
              <VersionTitle>{asset.name}</VersionTitle>
              <Version>V{asset.version}</Version>
            </VersionItem>
          </VersionItemWrapper>
        ))
      ) : (
        // TODO: Add a proper message and style it
        <>There are no other versions for comparison</>
      )}
    </ModalContainer>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  height: 100%;
  justify-content: center;
`;

const ModalContainer = styled.div`
  background: var(--color-grey-8-5);
  border: 0.1rem solid var(--color-grey-7-5);
  border-radius: 1.2rem;
  max-height: 68rem;
  min-width: 44rem;
  max-width: 44rem;
  width: 100%;
  padding: 1rem;
`;

const Header = styled.div`
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 700;
  color: var(--step-title-color);
`;

const VersionItemWrapper = styled.div`
  padding: 0.8rem 0;
  border-bottom: 0.1rem solid var(--color-grey-7-5);

  :last-child {
    border-bottom: none;
  }
`;

const VersionTitle = styled.div`
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  display: flex;
  align-items: center;
  color: var(--color-grey-5);
`;

const Version = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  color: var(--color-grey-6);
`;

const VersionItem = styled.div`
  padding: 0.8rem;
  border-radius: 0.6rem;
  cursor: pointer;

  &:hover {
    background: var(--color-grey-8);

    ${VersionTitle} {
      color: var(--white-default);
    }

    ${Version} {
      color: var(--color-grey-3);
    }
  }
`;
