import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { TabItem } from './tab-item';
import { tabPool } from '../../features/tabs/models/tabs-pool';
import { observer } from 'mobx-react-lite';
import { api } from '../../api';
import { breakPoint } from '../theme';

const parseUrl = (url: string) => {
  const urlParts = url.split('/');

  const isProject = urlParts.includes('projects') && !urlParts.includes('settings');

  return {
    projectId: isProject ? urlParts[1] : undefined,
    stepId: urlParts[3],
  };
};

export const TabBar: React.FC = observer(() => {
  const navigate = useNavigate();
  const params = useParams() as { '*': string; spaceId: string };
  const { projectId } = parseUrl(params['*']);

  const tabs = tabPool.elements;

  const currentTab = tabs.find((tab) => tab.key === projectId);

  if (projectId && currentTab && currentTab.href !== window.location.href) {
    /**
     * Update the tab href when navigating inside a tab
     */
    currentTab.href = window.location.pathname;
  }

  useEffect(() => {
    if (currentTab?.key) {
      const element = document.getElementById(currentTab.key);

      element?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentTab]);

  const navigateToTab = (href: string) => {
    api.updateSpaceInHeaders(href.split('/')[2]!);
    navigate(href);
  };

  const handleClose = (key: string) => {
    if (currentTab && currentTab.key === key && tabs.length > 1) {
      const index = tabs.findIndex((tab) => tab.key === key);

      if (index >= 1) {
        navigateToTab(tabs[index - 1].href);
      } else {
        navigateToTab(tabs[index + 1].href);
      }
    } else if (tabs.length <= 1) {
      navigateToTab(`/space/${params.spaceId}`);
    }
  };

  return (
    <Tabs>
      {tabs.map((tab) => (
        <TabItem
          key={tab.key}
          id={tab.key}
          tab={tab}
          active={tab.key === projectId}
          onClose={handleClose}
        />
      ))}
    </Tabs>
  );
});

const Tabs = styled.div`
  display: none;

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: flex;
    margin-inline-start: 6.4rem;
    border-inline-start: 1px var(--bg-color-dark-black) solid;
    overflow-x: auto;
    overflow-y: hidden;

    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;
