import React, { FormEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ElevatedButton } from '../../components/buttons';
import { Card } from '../../components/on-boarding/Card';
import { Form } from './components/form';
import { ReactSelect } from '../../components/select';
import { api } from '../../api';
import { Space } from '../../app/entities/space';

const sizeOptions = [
  {
    label: 'Just me',
    value: 'just-me',
  },
  {
    label: '2-10',
    value: '2-10',
  },
  {
    label: '10-50',
    value: '10-50',
  },
  {
    label: '50+',
    value: '50+',
  },
];

const typeOptions = [
  {
    label: 'Individual filmmaker',
    value: 'Individual filmmaker',
  },
  {
    label: 'Content creation agency',
    value: 'Content creation agency',
  },
  {
    label: 'Creative agency',
    value: 'Creative agency',
  },
  {
    label: 'Production company',
    value: 'Production company',
  },
  {
    label: 'Media department',
    value: 'Media department',
  },
];

const contentOptions = [
  {
    label: 'Social media content',
    value: 'Social media content',
  },
  {
    label: 'Commercials',
    value: 'Commercials',
  },
  {
    label: 'Documentaries',
    value: 'Documentaries',
  },
  {
    label: 'Corporate films',
    value: 'Corporate films',
  },
  {
    label: 'Streaming content',
    value: 'Streaming content',
  },
  {
    label: 'Feature films',
    value: 'Feature films',
  },
  {
    label: 'Short films',
    value: 'Short films',
  },
];

const channelOptions = [
  {
    label: 'Search engines',
    value: 'Search engines',
  },
  {
    label: 'Youtube creator',
    value: 'Youtube creator',
  },
  {
    label: 'Youtube ads',
    value: 'Youtube ads',
  },
  {
    label: 'Instagram',
    value: 'Instagram',
  },
  {
    label: 'TikTok',
    value: 'TikTok',
  },
  {
    label: 'LinkedIn',
    value: 'LinkedIn',
  },
  {
    label: 'Article, blog post',
    value: 'Article, blog post',
  },
  {
    label: 'Banner ads',
    value: 'Banner ads',
  },
  {
    label: "Friend's recommendation",
    value: "Friend's recommendation",
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const StatsPage = () => {
  const location = useLocation() as { state: { spaceId: string } };
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState<any>();
  const [type, setType] = useState<any>();
  const [content, setContent] = useState<any>();
  const [channel, setChannel] = useState<any>();

  const goHome = async () => {
    sendFinishOnboardingEvent();

    const href = `/space/${location.state.spaceId}`;
    navigate(href, { state: location.state });
  };

  const sendFinishOnboardingEvent = async () => {
    const spaces = Space.getAll();
    if (spaces?.length === 1) {
      api.post('/track', {
        type: 'event',
        eventName: 'Onboarding Completed',
      });
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (isLoading) return;
    setIsLoading(true);

    try {
      const fields = [
        {
          key: 'companySize',
          value: size?.value,
        },
        {
          key: 'companyType',
          value: type?.value,
        },
        {
          key: 'contentType',
          value: content?.value,
        },
        {
          key: 'channel',
          value: channel?.value,
        },
      ];

      await api.post('/track', {
        type: 'field',
        fields: fields.filter((field) => field.value),
      });

      setIsLoading(false);
      goHome();
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <Card
      title="Create your creative space"
      footer={
        <ElevatedButton
          text="Next"
          isLoading={isLoading}
          isFullWidth
          type="submit"
          form="stats-form"
        />
      }
    >
      <Form id="stats-form" onSubmit={handleSubmit}>
        <InputContainer>
          <Label>How large is your team?</Label>
          <ReactSelect onChange={setSize} value={size} options={sizeOptions} />
        </InputContainer>

        <InputContainer>
          <Label>What describes you best?</Label>
          <ReactSelect onChange={setType} value={type} options={typeOptions} />
        </InputContainer>

        <InputContainer>
          <Label>What type of production do you plan to use PRODUCER for?</Label>
          <ReactSelect onChange={setContent} value={content} options={contentOptions} />
        </InputContainer>

        <InputContainer>
          <Label>How did you find out about us?</Label>
          <ReactSelect
            placeholder="Select"
            onChange={setChannel}
            value={channel}
            options={channelOptions}
          />
        </InputContainer>
      </Form>
    </Card>
  );
};

const InputContainer = styled.div`
  width: 100%;
`;

const Label = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: normal;
  color: var(--color-grayscale-white);
  margin-bottom: 0.8rem;
`;
