import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { ProducerLogo } from '../../components/icons/producer-logo';
import { PagePlaceholder } from '../../components/page-placeholder/page-placeholder';
import { breakPoint } from '../../app/theme';

export const MarketplaceThanksPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const spaceId = searchParams.get('spaceId');

  return (
    <Container>
      <Logo>
        <ProducerLogo />
      </Logo>

      <PagePlaceholder
        description="Thank you for subscription"
        actionButton={{
          text: 'Continue to PRODUCER',
          onClick: () => (spaceId ? navigate(`/space/${spaceId}`) : navigate('/')),
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  height: calc(100% - 11.4rem);

  & > div {
    background-color: var(--color-grayscale-eerie-black);

    & > div {
      background-size: 65%;

      & > div {
        max-width: unset;

        & > p {
          font-size: 2.4rem;
          font-weight: 600;
          width: 100%;
          margin-bottom: 0.8rem;

          @media screen and (min-width: ${breakPoint.small}px) {
            font-size: 4.8rem;
            font-weight: 700;
            margin-bottom: 2.4rem;
          }
        }
      }
    }
  }
`;

const Logo = styled.div`
  padding-top: 5.2rem;

  & svg {
    display: block;
    margin: 0 auto;
  }
`;
