import React from 'react';
import styled from 'styled-components';

import { ElevatedButton } from '../buttons/';

type PagePlaceholderBasicProps = {
  backgroundImgUrl?: string;
  description: string;
  title?: string;
};

type NoActionPlaceholderProps = PagePlaceholderBasicProps & {
  actionButton?: never;
  actions?: never;
};

type SingleActionPlaceholderProps = PagePlaceholderBasicProps & {
  actions?: never;
  actionButton: {
    text: string;
    onClick?: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  };
};

type MultiActionPlaceholderProps = PagePlaceholderBasicProps & {
  actions: React.ReactNode[];
  actionButton?: never;
};

type PagePlaceholderProps =
  | NoActionPlaceholderProps
  | SingleActionPlaceholderProps
  | MultiActionPlaceholderProps;

export const PagePlaceholder: React.FC<PagePlaceholderProps> = ({
  backgroundImgUrl,
  title,
  description,
  actionButton,
  actions,
}) => {
  const { text, onClick = () => {} } = actionButton || {};

  return (
    <Container>
      <LogoBackground backgroundImgUrl={backgroundImgUrl}>
        <WrapperBox>
          {title && <Description>{title}</Description>}

          <Description>{description}</Description>

          {actionButton && <ElevatedButton onClick={onClick} text={text!} />}

          {!!actions?.length && (
            <ActionContainer>{actions.map((action) => action)}</ActionContainer>
          )}
        </WrapperBox>
      </LogoBackground>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1.6rem;
  height: 100%;
  background: var(--color-grey-8-5);
  border-radius: var(--border-radius-medium);
`;

const WrapperBox = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 32rem;
  align-items: center;
  gap: 1.6rem;
`;

const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #f2f3f5;
  text-align: center;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
`;

type LogoBackgroundProps = {
  backgroundImgUrl?: string;
};

const LogoBackground = styled.div<LogoBackgroundProps>`
  width: 100%;
  height: 100%;
  max-width: 67.6rem;
  max-height: 60.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${({ backgroundImgUrl }) =>
    backgroundImgUrl || '/images/default-background.svg'});
  background-repeat: no-repeat;
  background-position: center;
`;
