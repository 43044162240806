import React, { useState } from 'react';
import styled from 'styled-components';

import FilledWhitePlus from '../../../components/icons/plus/FilledWhitePlus';
import { CreateStepDialog } from './create-step.dialog';

type CreateStepIndicatorProps = {
  stageId: string;
};

export const CreateStepIndicator: React.FC<CreateStepIndicatorProps> = ({ stageId }) => {
  const [isAddStepDialog, setIsAddStepDialog] = useState(false);

  return (
    <>
      <LineContainer onClick={() => setIsAddStepDialog(true)}>
        <AddContainer>
          <AddStepUnderline />
          <AddStepContainer>
            <FilledWhitePlus />

            <AddStepText>Add a step</AddStepText>
          </AddStepContainer>
        </AddContainer>
      </LineContainer>

      {isAddStepDialog && (
        <CreateStepDialog stageId={stageId} onCancel={() => setIsAddStepDialog(false)} />
      )}
    </>
  );
};

const LineContainer = styled.div`
  padding: 1.6rem 0;
  color: #8a8f98;

  &:hover {
    cursor: pointer;
    color: #cacdd5;
  }
`;

const AddContainer = styled.div`
  position: relative;
`;

const AddStepContainer = styled.div`
  position: absolute;
  display: flex;
  width: 11rem;
  padding: 0 1.1rem;
  justify-content: space-between;
  align-items: center;
  z-index: 20;
  background: var(--app-background);
  top: -0.7rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;

const AddStepText = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
`;

const AddStepUnderline = styled.div`
  background: currentColor;
  border-radius: 0.6rem;
  height: 0.2rem;
  width: 100%;
  z-index: 19;
`;
