import React from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as Sentry from '@sentry/react';

import { HorizontalMenu } from '../../components/horizontal-menu/HorizontalMenu';
import { DirectorPage, DirectorPageProps } from './pages/director.page';
import { LineProducerPage, LineProducerPageProps } from './pages/line-producer.page';
import { CommonFilePage, CommonFilePageProps } from './pages/common-file.page';
import { StoryboardPage, StoryboardPageProps } from './pages/storyboard.page';
import { LocationPage, LocationPageProps } from './pages/location.page';
import { CastSelectionPage, CastSelectionPageProps } from './pages/cast-selection.page';
import { ShootingDayPage, ShootingDayPageProps } from './pages/shooting-day.page';
import { ShotListPage } from './pages/shot-list.page';
import { CrewPageProps, TeamPage } from './pages/team.page';
import { SchedulePage, SchedulePageProps } from './pages/schedule.page';
import { ProducerPage, ProducerPageProps } from './pages/producer.page';
import { LoaderContainer } from './components/step-container';
import { PageLoader } from '../../app/components/page-loader';
import { CrashErrorScreen } from './components/crash-error-screen';

import { Step } from '../../app/entities/step';
import { Project } from '../../app/entities/project';

interface StepScreenProps {
  project: Project;
}

type ScreenType = Record<
  string,
  | React.FC<CommonFilePageProps>
  | React.FC<SchedulePageProps>
  | React.FC<StoryboardPageProps>
  | React.FC<ProducerPageProps>
  | React.FC<DirectorPageProps>
  | React.FC<LineProducerPageProps>
  | React.FC<LocationPageProps>
  | React.FC<CastSelectionPageProps>
  | React.FC<ShootingDayPageProps>
  | React.FC<CrewPageProps>
>;

const screenType: ScreenType = {
  default: CommonFilePage,
  schedule: SchedulePage,
  storyboard: StoryboardPage,
  producer: ProducerPage,
  director: DirectorPage,
  lineProducer: LineProducerPage,
  video: CommonFilePage,
  review: CommonFilePage,
  wip: CommonFilePage,
  location: LocationPage,
  casting: CastSelectionPage,
  shootingDay: ShootingDayPage,
  shotList: ShotListPage,
  crew: TeamPage,
};

type Params = {
  stepId: string;
};

export const StepPage: React.FC<StepScreenProps> = observer(({ project }) => {
  const { stepId } = useParams() as Params;

  const step = Step.getOne(stepId)!;

  // @ts-ignore
  let Screen = step && screenType[step.type];

  if (!Screen) {
    Screen = screenType['default'];
  }

  /** Handle better the absence of step, redirect? placeholder?**/
  return (
    <>
      <HorizontalMenu projectId={project._id} stages={project.stages} />

      {!step ? (
        <LoaderContainer>
          <PageLoader />
        </LoaderContainer>
      ) : (
        <Sentry.ErrorBoundary fallback={<CrashErrorScreen />}>
          {/* @ts-ignore */}
          <Screen project={project} step={step} />
        </Sentry.ErrorBoundary>
      )}
    </>
  );
});
