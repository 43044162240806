import React from 'react';
import styled from 'styled-components';

import { ElevatedButton } from '../../../../components/buttons';
import { BaseDialog, DialogFooter, DialogHeader } from '../../../../components/dialog';

type ChangePlanModalProps = {
  title: string;
  description: string;
  onCancel: () => void;
};

export const ChangePlanModal: React.FC<ChangePlanModalProps> = ({
  title,
  description,
  onCancel,
}) => {
  return (
    <BaseDialog onClose={onCancel}>
      <DialogHeader title={title} />

      <Container
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />

      <DialogFooter actions={[<ElevatedButton key="close" text="Close" onClick={onCancel} />]} />
    </BaseDialog>
  );
};

const Container = styled.div`
  padding: 1.6rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  max-width: 50rem;
  color: var(--color-grayscale-light-slate);

  a {
    color: white;
    text-decoration: underline;
  }
`;
