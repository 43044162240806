import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import placeholder from '../../../assets/images/project_cover_placeholder.jpg';
import { CircularProgress } from '../../../components/circular-progress/circular-progress';
import { calculatePercentage, getDone } from '../../../lib/utils/math';
import { Stage } from '../../../app/entities/stage';
import { ProjectCollaborators } from './project-collaborators';
import { ProjectContacts } from './project-contacts';
import { EditCoverButton } from './edit-cover.button';
import { Pulse } from '../../../components/page-loader/PageLoader';
import { formatPicture } from '../../../core/services/image.service';
import { Project } from '../../../app/entities/project';
import { breakPoint } from '../../../app/theme';
import { TimeFrame } from './timeframe';

interface ProjectOverviewHeaderProps {
  project: Project;
  stages: Stage[];
}

export const ProjectOverviewHeader: React.FC<ProjectOverviewHeaderProps> = observer(
  ({ project, stages }) => {
    const { sum, done } = getDone(stages);
    const progress = calculatePercentage(done, sum);
    const [isLoading, setIsLoading] = useState(false);

    const handleChangeCover = async (file: File) => {
      if (isLoading) {
        return;
      }

      setIsLoading(true);

      const options = { width: 640, height: 380 };
      const frame = await formatPicture(file, options);

      await project.updateCover(frame);

      setIsLoading(false);
    };

    const handleUpdateDate = (values: { dueDate?: string; startDate?: string }) => {
      project.update(values);
    };

    return (
      <Header>
        <Cover>
          <HeaderImage src={project.avatar || placeholder} alt={project.name} />
          <EditCoverButton onChange={handleChangeCover} />

          {isLoading && (
            <CoverLoading>
              <PulseWrapper>
                <Pulse />
              </PulseWrapper>
            </CoverLoading>
          )}
        </Cover>

        <Right>
          <h1>{project.name}</h1>
          <Client>{project.client?.name}</Client>

          <Infos>
            <DeadLine>
              <IconContainer>
                <CircularProgress progress={progress} />
              </IconContainer>

              <Text>
                Steps completed:&nbsp;
                <b>
                  {done}/{sum}
                </b>
              </Text>
            </DeadLine>

            <DeadLine>
              <TimeFrame
                dueDate={project.dueDate}
                startDate={project.startDate}
                onUpdate={handleUpdateDate}
              />
            </DeadLine>
          </Infos>
        </Right>

        <ProjectContacts
          projectId={project._id}
          spaceId={project.spaceId}
          contacts={project.contacts || []}
        />

        <Spacer />

        <ProjectCollaborators project={project} spaceId={project.spaceId} />
      </Header>
    );
  },
);

const Header = styled.header`
  display: none;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 2.4rem 2.4rem 0 2.4rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: flex;
  }
`;

const Cover = styled.div`
  position: relative;
  height: 13.8rem;
  aspect-ratio: 16 / 9;
  border-radius: 0.4rem;
  overflow: hidden;

  &:hover {
    #edit {
      opacity: 1;
      transform: translateX(0) scale(1);
    }
  }
`;

const CoverLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #494e5a;
  opacity: 0.8;
`;

const PulseWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 9.6rem;
  width: 9.6rem;
  transform: translate(-50%, -50%);
`;

const HeaderImage = styled.img`
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  object-fit: cover;
  border-radius: 0.4rem;
`;

const Spacer = styled.div`
  flex: 1;
`;

export const Right = styled.div`
  margin-left: 2.4rem;
  margin-right: 5.6rem;
`;

export const Client = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  color: #8a8f98;
`;

const Infos = styled.div`
  display: flex;
  flex-direction: column;
  cursor: default;
`;

export const DeadLine = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0.8rem;
`;

export const Text = styled.div`
  color: var(--date-color);
  font-size: 1.2rem;

  b {
    color: var(--text-color-white);
  }
`;

const IconContainer = styled.div`
  padding-right: 0.8rem;
  color: var(--text-color-white);
  font-size: 1.6rem;
  font-weight: 500;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    fill: var(--text-color-white);
  }
`;
