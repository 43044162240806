import React, { useState, useEffect, useCallback, useId } from 'react';
import styled from 'styled-components';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { object as yupObject, string as yupString, array as yupArray } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Close } from '@producer-io/ui-kit';

import { TextInput } from '../../../components/text-input/TextInput';
import { HasServerResponse } from '../../../components/PanelContainer';
import { Tag } from '../../../components/tag/Tag';
import { BaseDialog, DialogFooter, DialogHeader } from '../../../components/dialog';
import { ElevatedButton, OutlinedButton, IconButton } from '../../../components/buttons';
import { ReactSelect } from '../../../components/select';
import { Note } from '../../../components/note/note';
import {
  previewAddMemberUpdates,
  PreviewAddMemberUpdatesResponse,
} from '../../billing/store/billing.slice';
import { Space } from '../../../app/entities/space';
import { createMember } from '../store/member.slice';
import { SelectOption } from '../../../components/select';
import { Toast } from '../../toasts/models/toast';
import { toastPool } from '../../toasts/models/toast-pool';
import { PaymentPreview } from './payment-preview';

type InviteMemberDialogProps = {
  spaceId: string;
  onCancel: () => void;
};

type FormValues = {
  members: {
    email: string;
    role: 'creator' | 'admin';
  }[];
};

const roleOptions = [
  {
    label: 'Creator',
    value: 'creator',
  },
  {
    label: 'Admin',
    value: 'admin',
  },
];

const membersScheme = {
  email: yupString()
    .matches(/^(.*)?\S+(.*)?$/, 'Email is required')
    .required('Email is required')
    .transform((value) => value.replace(/\s/g, '')) // trim only removes leading and trailing spaces, this removes all spaces
    .email('Please enter a valid email'),
};

export const InviteMemberModal: React.FC<InviteMemberDialogProps> = ({ spaceId, onCancel }) => {
  const { t } = useTranslation('profile');

  const [serverError, setServerError] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingPrice, setIsFetchingPrice] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState<PreviewAddMemberUpdatesResponse | null>(
    null,
  );
  const [hint, setHint] = useState(
    'Adding additional creators to your plan will be charged annually based on your existing plan. You will be charged pro rata for additional creators.',
  );

  const space = Space.getOne(spaceId);

  const schema = yupObject({
    members: yupArray().of(yupObject().shape(membersScheme)),
  });

  const fetchPreview = useCallback(
    async (numberOfMembers: number = 1) => {
      if (!space?.hasSubscription() || space?.subscription?.subscriptionId === 'sub_empty') return;

      setIsFetchingPrice(true);

      try {
        const response = await previewAddMemberUpdates(spaceId, numberOfMembers);
        setPaymentDetails(response || null);
      } catch (e) {
        setServerError(e);
      } finally {
        setIsFetchingPrice(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [space?.subscription?.subscriptionId, spaceId],
  );

  const onSubmit = async (values: FormValues) => {
    if (isLoading || isFetchingPrice) return;

    setIsLoading(true);

    createMember({
      spaceId,
      members: values.members,
    })
      .then((members) => {
        toastPool.insert(
          new Toast(`${members.length} invite${members.length === 1 ? '' : 's'} sent`, 'success'),
        );
        setIsLoading(false);
        onCancel();

        space!.seats!.current += members.length;
      })
      .catch((error: any) => {
        setServerError(error?.data?.message);
        setIsLoading(false);
      });
  };

  const { control, watch, handleSubmit } = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      members: [
        {
          email: '',
          role: 'creator',
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'members',
    control,
  });

  const watchedMembers = watch('members');

  useEffect(() => {
    if (watchedMembers.length === 0) return;

    if (space?.hasSubscription() && space.isSelfServing && !isFetchingPrice) {
      fetchPreview(watchedMembers.length);
    }
  }, [fetchPreview, watchedMembers]);

  useEffect(() => {
    if (space?.hasSubscription() && !space.isSelfServing) {
      const current = space?.seats?.current || 0;
      const limit = space?.seats?.limit || 1;

      // TODO: Get the plan name
      setHint(
        `You are on a ${''} plan with seats. With the added creators below, ${
          current + watchedMembers.length
        } of your ${limit} seats would be taken.`,
      );
    }
  }, [watchedMembers]);

  const getRoleValue = (role: 'creator' | 'admin') => {
    return roleOptions.find((option) => option.value === role);
  };

  return (
    <BaseDialog onClose={onCancel} theme="dark">
      <DialogHeader title={t('invite_creators')} onClose={onCancel} />

      <Container>
        {serverError && (
          <ServerErrorMessage>
            <Tag text={serverError} />
          </ServerErrorMessage>
        )}

        {space?.hasSubscription() && <Note>{hint}</Note>}

        <Form id="invite-member-form" onSubmit={handleSubmit(onSubmit)}>
          {fields.map((field, index) => (
            <Row key={field.id}>
              <InputContainer>
                <Controller
                  name={`members.${index}.email`}
                  control={control}
                  render={({ field: { ref, onChange, value }, fieldState: { error } }) => (
                    <TextInput
                      ref={ref}
                      value={value}
                      onChange={onChange}
                      placeholder="name@domain.com"
                      label="Email"
                      errorMessage={error?.message}
                      required
                    />
                  )}
                />
              </InputContainer>

              <RoleContainer>
                <Controller
                  name={`members.${index}.role`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      label="Role"
                      onChange={(selectedOption) =>
                        onChange((selectedOption as SelectOption)!.value)
                      }
                      value={getRoleValue(value)}
                      options={roleOptions}
                      isSearchable={false}
                    />
                  )}
                />
              </RoleContainer>

              <RemoveButtonContainer>
                <IconButton
                  icon={<Close />}
                  disabled={watchedMembers.length <= 1}
                  type="button"
                  onClick={() => {
                    if (!watchedMembers.length) return;

                    remove(index);
                  }}
                />
              </RemoveButtonContainer>
            </Row>
          ))}
        </Form>

        {space?.canAddNewMember(watchedMembers.length + 1) ? (
          <AddNewMemberButton
            type="button"
            onClick={() => {
              append({ email: '', role: 'creator' });
            }}
          >
            + Add another user
          </AddNewMemberButton>
        ) : (
          <span>
            You have reached your limit, to add more creators than the currently selected ones
            please contact the sales team.
          </span>
        )}
      </Container>

      {space?.hasSubscription() &&
        space.isSelfServing &&
        space?.subscription?.subscriptionId !== 'sub_empty' && (
          <PaymentPreview isLoading={isFetchingPrice} paymentDetails={paymentDetails} />
        )}

      <DialogFooter
        actions={[
          <OutlinedButton key="cancel" text="Cancel" onClick={onCancel} />,

          <ElevatedButton
            type="submit"
            text="Invite"
            key="invite"
            form="invite-member-form"
            isLoading={isLoading}
            disabled={
              isLoading || (space?.hasSubscription() && space.isSelfServing && isFetchingPrice)
            }
          />,
        ]}
      />
    </BaseDialog>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  max-width: 50rem;
  padding: 1.6rem;
  max-height: 48rem;
  overflow-y: auto;
  min-height: 24rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const Row = styled.div`
  display: flex;
  gap: 1.2rem;
  align-items: flex-end;
`;

const RoleContainer = styled.div`
  flex: 1 0 auto;
`;

const InputContainer = styled.div`
  flex: 0 1 55%;
`;

const RemoveButtonContainer = styled.div`
  & > button {
    background: var(--color-grayscale-tuna);
    color: var(--color-grayscale-light-slate);

    & svg {
      stroke: var(--color-grayscale-light-slate);
      height: 1.2rem;
      width: 1.2rem;
    }
  }
`;

const AddNewMemberButton = styled.button`
  all: unset;
  color: var(--color-primary-crayola);
  font-size: 1.4rem;
  font-weight: 400;
  width: auto;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const ServerErrorMessage = styled(HasServerResponse)`
  display: flex;
  justify-content: start;
  margin-top: -3rem;
  margin-bottom: 2rem;
  max-width: 100%;

  & > div {
    max-width: 32rem;
  }
`;
