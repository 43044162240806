import { api } from '../../../api';
import { entityPool } from '../../../core/engine/engine';
import { Collaborator } from '../../../app/entities/collaborator';

export const fetchProjectCollaborators = async (projectId: string) => {
  const { data } = await api.get(`/projects/${projectId}/collaborators`);

  data.forEach((el: any) => {
    const collaborator = new Collaborator();
    collaborator._id = el._id;
    collaborator.projectId = el.projectId;
    collaborator.memberId = el.memberId;
    collaborator.invitationId = el.memberId;
    collaborator.firstName = el.firstName;
    collaborator.lastName = el.lastName;
    collaborator.email = el.email;
    collaborator.avatar = el.avatar;

    entityPool.insert(collaborator);
  });
};

type AddProjectCollaboratorInput = {
  projectId: string;
  email?: string;
  contactId?: string;
  grantAccess?: boolean;
  roleId?: string;
  firstName?: string;
  lastName?: string;
  position?: string;
};

export const addProjectCollaborator = async ({
  projectId,
  ...input
}: AddProjectCollaboratorInput) => {
  const { data } = await api.post(`/projects/${projectId}/collaborators`, input);
  const collaborator = Object.assign(new Collaborator(), data);
  entityPool.insert(collaborator);
};
