import styled from 'styled-components';

export const FormTitle = styled.h2`
  margin-top: 2.6rem;
  font-family: Inter, sans-serif;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: var(--color-grayscale-light-slate);
  font-weight: 500;
`;

export const HasServerResponse = styled.div`
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  max-width: 31rem;
`;

export const FieldContainer = styled.div`
  margin-top: 0.8rem;
`;
