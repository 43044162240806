import React from 'react';

export const UserPlusIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.64479 5.34088C3.64479 3.53918 5.10536 2.07861 6.90706 2.07861C8.70877 2.07861 10.1693 3.53918 10.1693 5.34088C10.1693 6.74508 9.28215 7.94207 8.03774 8.40189C9.00397 8.55633 9.83906 8.92677 10.4812 9.55957C11.3638 10.4294 11.8063 11.7203 11.8062 13.393C11.8062 13.6553 11.5936 13.868 11.3312 13.868C11.0689 13.868 10.8562 13.6553 10.8562 13.393C10.8563 11.8803 10.4581 10.8706 9.81436 10.2362C9.1692 9.60041 8.20172 9.26683 6.90699 9.26683C5.61226 9.26683 4.64482 9.60041 3.99968 10.2362C3.35595 10.8706 2.95781 11.8803 2.95781 13.393C2.95781 13.6553 2.74515 13.868 2.48281 13.868C2.22048 13.868 2.00781 13.6553 2.00781 13.393C2.00781 11.7203 2.45025 10.4294 3.33285 9.55957C3.97497 8.92675 4.81008 8.5563 5.77635 8.40188C4.53195 7.94204 3.64479 6.74507 3.64479 5.34088ZM6.90706 3.02861C5.63003 3.02861 4.59479 4.06385 4.59479 5.34088C4.59479 6.61792 5.63003 7.65316 6.90706 7.65316C8.1841 7.65316 9.21933 6.61792 9.21933 5.34088C9.21933 4.06385 8.1841 3.02861 6.90706 3.02861ZM13.6898 6.03252C13.9659 6.03252 14.1898 6.25638 14.1898 6.53252V7.81348H15.3616C15.6377 7.81348 15.8616 8.03734 15.8616 8.31348C15.8616 8.58962 15.6377 8.81348 15.3616 8.81348H14.1898V10.0944C14.1898 10.3705 13.9659 10.5944 13.6898 10.5944C13.4136 10.5944 13.1898 10.3705 13.1898 10.0944V8.81348H12.018C11.7418 8.81348 11.518 8.58962 11.518 8.31348C11.518 8.03734 11.7418 7.81348 12.018 7.81348H13.1898V6.53252C13.1898 6.25638 13.4136 6.03252 13.6898 6.03252Z"
      fill="#FAFAFA"
    />
  </svg>
);
