import orderBy from 'lodash.orderby';

type Orders = 'asc' | 'desc';

export const sortBy = (array: Array<any>, sortKey: string, orders: Orders) => {
  /**
   * ALl items with the property will be ordered
   */
  const leadingItems: any[] = [];

  /**
   * ALl items without the property will append to the end
   */
  const trailingItems: any[] = [];

  array.forEach((item) => {
    if (item[sortKey]) {
      leadingItems.push(item);
    } else {
      trailingItems.push(item);
    }
  });

  return [...orderBy(leadingItems, [(item) => item[sortKey]], [orders]), ...trailingItems];
};
