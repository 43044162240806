import React from 'react';

export const Phone = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6605_10358)">
      <path
        d="M8.21429 4.0348H17.6429C17.8702 4.0348 18.0882 4.121 18.2489 4.27444C18.4097 4.42788 18.5 4.63599 18.5 4.85298V19.5803C18.5 19.7973 18.4097 20.0054 18.2489 20.1588C18.0882 20.3122 17.8702 20.3984 17.6429 20.3984H7.35714C7.12981 20.3984 6.9118 20.3122 6.75105 20.1588C6.59031 20.0054 6.5 19.7973 6.5 19.5803V2.39844H8.21429V4.0348ZM8.21429 5.67116V9.76207H16.7857V5.67116H8.21429Z"
        fill="#626878"
      />
    </g>
    <defs>
      <clipPath id="clip0_6605_10358">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
