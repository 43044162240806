import React from 'react';
import styled from 'styled-components';

interface TextInputProps {
  checked: boolean;
  isDisabled?: boolean;
  onChange?: (e?: any) => void;
}

export const BlueCheckbox: React.FC<TextInputProps> = ({ checked, isDisabled, onChange }) => (
  <CheckboxContainer>
    <StyledCheckbox checked={checked} isDisabled={isDisabled}>
      <Icon checked={checked} isDisabled={isDisabled} viewBox="0 0 22 22">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

interface StyledCheckboxProps {
  checked: boolean;
  isDisabled?: boolean;
}

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg<StyledCheckboxProps>`
  fill: none;
  stroke: ${({ checked, theme }) => (checked ? 'var(--white-default)' : 'unset')};
  stroke-width: 2px;
`;

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  background: ${({ checked }) => (checked ? '#367bff' : 'unset')};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.4' : 'unset')};
  border-radius: 0.2rem;
  border: solid;
  border: 1px solid ${({ checked }) => (checked ? '#367bff' : '#626878')};
  transition: all 150ms;
  cursor: ${({ isDisabled }) => (isDisabled ? 'unset' : 'pointer')};
`;
