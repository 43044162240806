import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import styled from 'styled-components';

import { SortableShotListStrip } from './sortable-shotlist-strip';
import { useSelection } from '../../../core/contexts/selection.context';
import { CountDragPreview } from '../../../features/schedule/components/count-drag-preview';
import { Project } from '../../../app/entities/project';
import { Stripboard } from '../../../app/entities/stripboard';
import { authStore } from '../../../core/stores/auth-store';

import type { DayBreakStrip, ShotStrip } from '../../../features/schedule/models/types';

type SortableShotListProps = {
  stripboard: Stripboard;
  strips: Array<ShotStrip | DayBreakStrip>;
  project: Project;
};

export const SortableShotList: React.FC<SortableShotListProps> = observer(
  ({ stripboard, project, strips }) => {
    const { t } = useTranslation();
    const { elements, select, clear } = useSelection();
    const currentMember = authStore.getCurrentMember(project.spaceId);

    const [activeId, setActiveId] = useState<string | null>(null);
    const stripIds = strips?.map((strip: any) => strip._id);

    const activeIndex = activeId
      ? stripboard.strips?.findIndex((strip) => strip._id === activeId)
      : -1;

    const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 1 } }));

    const handleDragStart = ({ active }: DragStartEvent) => {
      select(active.id.toString());
      setActiveId(active.id.toString());
    };

    const handleDragCancel = () => {
      setActiveId(null);
    };

    const handleDragEnd = (event: DragEndEvent) => {
      const { over } = event;

      if (over) {
        const overIndex = stripboard.strips.findIndex((strip) => strip._id === over.id);

        if (activeIndex !== overIndex) {
          const destination = strips.find((el) => el._id === over.id);
          if (!destination) return;

          stripboard.edit(elements, destination.position);
        }
      }

      clear();
      setActiveId(null);
    };

    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext items={stripIds} strategy={verticalListSortingStrategy}>
          {strips?.map((strip) => (
            <SortableShotListStrip
              key={strip._id}
              stripboard={stripboard}
              strip={strip}
              project={project}
              isSelected={elements.includes(strip._id)}
              isActive={!!activeId}
              readOnly={!currentMember || currentMember.role === 'guest'}
            />
          ))}
        </SortableContext>

        <DragOverlay
          dropAnimation={null}
          style={{ cursor: 'grabbing' }}
          modifiers={[snapCenterToCursor]}
        >
          <OverlayWrapper>
            <CountDragPreview
              count={elements?.length}
              text={t('common:strip', { count: elements?.length })}
            />
          </OverlayWrapper>
        </DragOverlay>
      </DndContext>
    );
  },
);

const OverlayWrapper = styled.div`
  position: absolute;
`;
