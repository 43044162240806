import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { timeDifference } from '../../lib/utils/TimeDifference';
import { novuService } from '../../core/services/novu.service';
import { breakPoint } from '../theme';

import { Notification, NotificationType, StepStatusUpdated } from '../entities/notification';

interface NotificationItemProps {
  notification: Notification;
  onClick?: () => void;
}

export const NotificationItem = observer(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLAnchorElement, NotificationItemProps>(({ notification, onClick }, ref) => {
    const { _id, payload, createdAt, read, url, action, target, parentTarget } = notification;

    const handleNotificationClick = () => {
      onClick && onClick();

      if (!read) {
        novuService.markNotificationsAsRead(_id);
      }
    };

    if (
      payload.workflowId === 'step-status-updated' &&
      (!payload.data.oldStatusTitle || !payload.data.newStatusTitle)
    )
      return null;

    return (
      <Container
        data-testid={`notification-${_id}`}
        ref={ref}
        onClick={handleNotificationClick}
        to={url}
      >
        <Item data-read={read}>
          <Description>
            <span>{payload.initiatorName as string}</span>
            &nbsp;
            {action}
            &nbsp;
            <Target>{target}</Target>
            {payload.workflowId === NotificationType.StepStatusUpdated && (
              <>
                {(payload as StepStatusUpdated).data.oldStatusTitle && (
                  <> from {(payload as StepStatusUpdated).data.oldStatusTitle}</>
                )}
                {(payload as StepStatusUpdated).data.newStatusTitle && (
                  <InlineText>
                    {' '}
                    to{' '}
                    <StepStatusTitle data-status={(payload as StepStatusUpdated).data.newStatus}>
                      {(payload as StepStatusUpdated).data.newStatusTitle}
                    </StepStatusTitle>{' '}
                  </InlineText>
                )}
              </>
            )}
            <br />
            <span>{parentTarget}</span>
          </Description>

          <span>{timeDifference(new Date(), createdAt)}</span>
        </Item>

        <UnreadSpot data-visible={!read} />
      </Container>
    );
  }),
);

const Description = styled.p`
  color: var(--color-grayscale-light-slate, #7a8296);
  font-size: 1.4rem;
  font-weight: 500;

  & > span {
    font-weight: 500;

    &:last-child {
      color: var(--color-grayscale-athens, #e4e6ea);
      font-weight: 300;
      line-height: 2rem;
    }
  }

  @media screen and (max-width: ${breakPoint.small - 1}px) {
    word-break: break-word;
  }
`;

const InlineText = styled.div`
  display: inline;
`;

const StepStatusTitle = styled.span``;

const Container = styled(Link)`
  all: unset;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.6rem;
  cursor: pointer;

  &:hover {
    ${Description} {
      span {
        transition: all 0.2s ease;
        color: var(--color-grayscale-white, #fafafa);

        &:last-child {
          color: var(--color-grayscale-athens, #e4e6ea);
        }
      }

      ${StepStatusTitle} {
        &[data-status='not-started'] {
          color: #fafafacc;
        }

        &[data-status='on-hold'] {
          color: #7a8296cc;
        }

        &[data-status='in-progress'] {
          color: #367bffcc;
        }

        &[data-status='needs-review'] {
          color: #f8a72dcc;
        }

        &[data-status='changes-requested'] {
          color: #db5c66cc;
        }

        &[data-status='approved'] {
          color: #38c2b8cc;
        }
      }
    }
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  min-width: 0;

  & > span {
    font-size: 1.2rem;
    font-weight: 300;
    color: var(--color-grayscale-light-slate, #7a8296);
  }

  &[data-read='false'] {
    & > ${Description} > span {
      &:first-child {
        color: var(--color-grayscale-white, #fafafa);
      }
    }
  }

  &[data-read='true'] {
    & * {
      color: var(--color-grayscale-light-slate, #7a8296);

      &:last-child {
        color: var(--color-grayscale-light-slate, #7a8296);
      }
    }
  }
`;

const Target = styled.span`
  word-break: break-word;
`;

const UnreadSpot = styled.span`
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: var(--color-primary-telemagenta, #d53075);
  visibility: hidden;

  &[data-visible='true'] {
    visibility: visible;
  }
`;
