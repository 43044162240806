import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { createStoryboard } from '../../../features/storyboards/storyboard.slice';
import { useResponsive } from '../../../hooks/useResponsive';
import { ShotListTable } from './shot-list-table';
import { MobileShotListTable } from './shot-list-table.mobile';
import { breakPoint } from '../../../app/theme';
import { Shot } from '../../../app/entities/shot';
import { DayBreakStrip, ShotStrip } from '../../../features/schedule/models/types';
import { LimitReachedModal } from '../../../components/dialogs/limit-reached.modal';
import { useSelection } from '../../../core/contexts/selection.context';
import { ScreenContainer } from 'features/projects/components/styled-project-components';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';

import type { Project } from '../../../app/entities/project';
import type { Stripboard } from '../../../app/entities/stripboard';

export type ShotListProps = {
  stripboard: Stripboard;
  strips: Array<ShotStrip | DayBreakStrip>;
  project: Project;
};

export const ShotList: React.FC<ShotListProps> = observer(({ stripboard, project, strips }) => {
  const { isDesktop } = useResponsive();
  const [isShowingLimitsModal, setIsShowingLimitsModal] = useState(false);
  const [copiedElements, setCopiedElements] = useState<string[]>([]);
  const [showDeleteShotsDialog, setShowDeleteShotsDialog] = useState(false);

  const [isAddingShot, setIsAddingShot] = useState(false);

  const { elements, clear } = useSelection();

  /**
   * Duplicate code
   * TODO centralize the create shot logic and moe the Modal to a store
   */
  const addShot = async () => {
    if (!project.canAddShot()) {
      setIsShowingLimitsModal(true);
      return;
    }

    if (isAddingShot) return;

    setIsAddingShot(true);

    try {
      if (!project.storyboard) {
        await createStoryboard(project);
        setIsAddingShot(false);
        return;
      }

      await Shot.createShot({
        projectId: project._id,
        position: project.shots.length,
      });
      setIsAddingShot(false);
    } catch {
      setIsAddingShot(false);
    }
  };

  useEffect(() => {
    const handleKeyDown = async (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
        if (elements.length) {
          e.preventDefault();
          const strips = stripboard.strips.filter(
            (strip) => strip.type === 'shot' && elements.includes(strip._id),
          );
          const shotIds = strips.map((strip) => strip.data.shotId);
          setCopiedElements(shotIds);
        }
      }
      if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
        if (copiedElements.length) {
          e.preventDefault();
          await Shot.duplicateShots(copiedElements);
          setCopiedElements([]);
        }
      }

      if (e.key === 'Delete') {
        if (elements.length) {
          e.preventDefault();
          setShowDeleteShotsDialog(true);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [elements, copiedElements]);

  const handleDeleteShots = async () => {
    const strips = stripboard.strips.filter(
      (strip) => strip.type === 'shot' && elements.includes(strip._id),
    );
    const shotIds = strips.map((strip) => strip.data.shotId);
    await Shot.deleteShots(shotIds);
    setShowDeleteShotsDialog(false);
  };

  return (
    <CustomScreenContainer onClick={clear}>
      {showDeleteShotsDialog && (
        <DeleteDialog
          onCancel={() => {
            setShowDeleteShotsDialog(false);
          }}
          onSubmit={() => {
            handleDeleteShots();
          }}
          text={`Are you sure you want to delete ${elements.length} shots`}
          title={'Delete multiple shots'}
        />
      )}

      {isDesktop ? (
        <div>
          <ShotListTable project={project} stripboard={stripboard} strips={strips} />
        </div>
      ) : (
        <MobileShotListTable project={project} stripboard={stripboard} strips={strips} />
      )}

      {isShowingLimitsModal && (
        <LimitReachedModal
          type="shots"
          eventName="Shots exceed 10"
          onCancel={() => setIsShowingLimitsModal(false)}
        />
      )}

      <AddShotStrip onClick={addShot}>Add shot</AddShotStrip>
    </CustomScreenContainer>
  );
});

const AddShotStrip = styled.div`
  border-radius: 0.4rem;
  height: 4.8rem;
  color: #8a8f98;
  border: 2px dashed currentColor;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  margin-top: 0.8rem;
  margin-bottom: var(--spacing-bottom-screen-container);

  &:hover {
    cursor: pointer;
    color: var(--color-grayscale-ghost);
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    height: 6.4rem;
    margin-top: 0;
  }
`;

const CustomScreenContainer = styled(ScreenContainer)`
  margin-left: 0;
  padding-left: var(--spacing-left-screen-container);
`;
