import React from 'react';
import { DeleteDialog } from '../dialogs/DeleteDialog';

type DeleteAssetDialogProps = {
  /**
   * Name of the file to delete, e.g. "MyFile.mp4"
   */
  name: string;

  onCancel: () => void;
  onSubmit: () => void;

  /**
   * If the asset is a version stack, how many versions are in the stack
   */
  versionCount?: number;
};

export const DeleteAssetDialog: React.FC<DeleteAssetDialogProps> = ({
  onCancel,
  onSubmit,
  name,
  versionCount,
}) => {
  let title = 'Delete file?';
  let text = `Are you sure you want to delete ${name}?`;

  if (versionCount && versionCount > 1) {
    title = 'Delete all versions?';
    text = `Are you sure you want to delete ${name} and ALL ${versionCount} versions of it? If you want to delete a single version, use the version manager.`;
  }

  return <DeleteDialog title={title} text={text} onCancel={onCancel} onSubmit={onSubmit} />;
};
