import React from 'react';
import { observer } from 'mobx-react-lite';

import { MembersList } from '../../../routes/project/components/shootingday/members-list';
import { Step } from '../../../app/entities/step';
import { TeamContact } from '../../teams/models/team';
import { TableContainer } from '../../../components/table/styled-shared-table';
import { CollapsibleItem } from '../../../components/collapsible-item/collapsible-item';
import { ConditionalWrapper } from '../../../components/conditional-wrapper/conditional-wrapper';
import { useResponsive } from '../../../hooks/useResponsive';

type ProtectedTeamItemProps = {
  name: string;
  contacts: TeamContact[];
  step: Step;
  onDelete?: (contactId: string) => void;
  meta?: Step['contactsMetaData'];
};

export const ProtectedTeamItem: React.FC<ProtectedTeamItemProps> = observer(
  ({ name, contacts, step, meta }) => {
    const { isDesktop } = useResponsive();

    const handleMetaUpdate = async (
      contactId: string,
      values: Omit<Exclude<Step['contactsMetaData'], undefined>[number], 'contactId'>,
    ) => {
      // @ts-ignore
      await step.update({ contactsMetaData: { contactId, ...values } });
    };

    const handleToggleHide = async (contactId: string) => {
      await step.toggleHideContact(contactId);
    };

    return (
      <div>
        <ConditionalWrapper
          condition={isDesktop}
          wrapper={(children) => (
            <CollapsibleItem title={name} defaultOpen>
              <TableContainer>{children}</TableContainer>
            </CollapsibleItem>
          )}
        >
          <MembersList
            title={name}
            departments={[]} // Empty array to load Departments column with default value (Executive)
            protectedTeamName={name}
            contacts={contacts}
            onMetaUpdate={handleMetaUpdate}
            onToggleHide={handleToggleHide}
            meta={meta}
            defaultCallTime={{
              hour: step.info?.generalCallTime?.hour || 0,
              minute: step.info?.generalCallTime?.minute || 0,
            }}
          />
        </ConditionalWrapper>
      </div>
    );
  },
);
