export const getScrollableParent = (element: HTMLElement | null): HTMLElement | null => {
  if (!element) return null;

  let parent: HTMLElement | null = element.parentElement;

  while (parent) {
    const overflowX = window.getComputedStyle(parent).overflowX;
    const isScrollable = overflowX === 'auto' || overflowX === 'scroll';

    if (isScrollable && parent.scrollWidth > parent.clientWidth) {
      return parent;
    }

    parent = parent.parentElement;
  }

  return null;
};

export const handleScrollToStrip = (id: string) => {
  const target = document.getElementById(`strip-${id}`);
  const scrollContainer = getScrollableParent(target);

  if (!target || !scrollContainer) {
    return;
  }

  const targetRect = target.getBoundingClientRect();
  const containerRect = scrollContainer?.getBoundingClientRect();

  const scrollLeft = targetRect.left - containerRect.left + scrollContainer.scrollLeft - 336; // 48px * 7

  scrollContainer.scroll({
    left: scrollLeft,
    behavior: 'smooth',
  });
};

export const onIntersectionChange = ({
  isIntersecting,
  entry,
  scrollableParent,
  stripIndicatorElement,
}: {
  isIntersecting: boolean;
  entry: IntersectionObserverEntry;
  scrollableParent?: HTMLElement | null;
  stripIndicatorElement?: HTMLDivElement | null;
}) => {
  if (!stripIndicatorElement) {
    return;
  }

  if (isIntersecting) {
    stripIndicatorElement.style.display = 'none';
  } else {
    stripIndicatorElement.style.display = 'flex';

    const { target } = entry;

    const containerRect = scrollableParent?.getBoundingClientRect();
    const targetRect = target?.getBoundingClientRect();

    if (!scrollableParent || !containerRect) {
      return;
    }
    const svgElement = stripIndicatorElement.querySelector('svg');
    const stripNameTag = stripIndicatorElement.querySelector('p');

    const targetLeft = targetRect.left - containerRect.left + scrollableParent.scrollLeft - 336; // 48px * 7

    if (targetLeft <= scrollableParent.scrollLeft) {
      stripIndicatorElement.style.justifySelf = 'start';
      stripIndicatorElement.style.flexDirection = 'row';

      if (svgElement) {
        svgElement.style.transform = 'scaleX(1)';
      }

      if (stripNameTag) {
        stripNameTag.style.display = 'none';
      }
    } else {
      stripIndicatorElement.style.justifySelf = 'end';
      stripIndicatorElement.style.flexDirection = 'row-reverse';

      if (svgElement) {
        svgElement.style.transform = 'scaleX(-1)';
      }

      if (stripNameTag) {
        stripNameTag.style.display = 'block';
      }
    }
  }
};

export const getDayColor = ({ isToday, isWeekend }: { isToday: boolean; isWeekend: boolean }) => {
  if (isToday) {
    return 'var(--color-shades-dark-raspberry)';
  }

  if (isWeekend) {
    return 'var(--color-surfaces-bg-elevation-1)';
  }

  return 'var(--color-surfaces-bg-elevation-2)';
};
