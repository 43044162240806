import React from 'react';
import styled from 'styled-components';
import { Plus } from '../../../components/icons';
import { breakPoint } from '../../../app/theme';

type EmptyContactProps = {
  contactName: string;
  onClick: () => void;
};

export const EmptyContact: React.FC<EmptyContactProps> = ({ contactName, onClick }) => (
  <Container onClick={onClick}>
    <Icon>
      <Plus />
    </Icon>

    <Text>{`Choose ${contactName}`}</Text>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: var(--border-radius-medium);
  transition: background-color 100ms ease-in-out;
  gap: 1.6rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    padding: 0.4rem;
  }

  &:hover {
    cursor: pointer;
    background-color: #54575d4d;
  }
`;

const Icon = styled.div`
  z-index: 1;
  height: 3.2rem;
  width: 3.2rem;
  min-width: 3.2rem;
  border-radius: var(--border-radius-medium);
  cursor: pointer;
  display: flex;
  font-size: 2.4rem;
  align-items: center;
  justify-content: center;
  color: var(--color-grayscale-cadet);
  background-color: var(--color-grayscale-arsenic);
  fill: currentColor;

  @media screen and (min-width: ${breakPoint.medium}px) {
    //TODO:  Keeping old colors on Desktop until deciding
    color: #1d1f24;
    background-color: #494e5a;
  }
`;

const Text = styled.p`
  font-size: 1.4rem;
  color: #54575d;

  @media screen and (min-width: ${breakPoint.medium}px) {
    font-size: 1.6rem;
  }
`;
