import React from 'react';

const FilledWhitePlus = () => (
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 0C5.34315 0 4 1.34315 4 3V13C4 14.6569 5.34315 16 7 16H17C18.6569 16 20 14.6569 20 13V3C20 1.34315 18.6569 0 17 0H7ZM12 3C12.5523 3 13 3.44772 13 4V7H16C16.5523 7 17 7.44771 17 8C17 8.55228 16.5523 9 16 9H13V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V9H8C7.44772 9 7 8.55228 7 8C7 7.44772 7.44772 7 8 7H11V4C11 3.44772 11.4477 3 12 3Z"
      fill="currentColor"
    />
  </svg>
);

export default FilledWhitePlus;
