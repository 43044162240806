import React, { useState } from 'react';
import styled from 'styled-components';

import { DownArrow } from '../icons/down-arrow';
import { BlueCheckmark } from '../icons/BlueCheckmark';
import { useOnClickOutside } from '../../hooks/useOutsideClick';
import {
  DescriptiveControl,
  DescriptiveOptionDescription,
  DescriptiveOptionLabel,
  StyledContainer,
  OptionsList,
} from './StyledDropdown';
import { Label, Help } from '../../components/Input/styled-input';
import { DropdownProps } from '.';

type DescriptiveDropdownProps = DropdownProps & {
  helpText?: string;
  label?: string;
  required?: boolean;
};
/**
 * Mostly a copy of Inline Descriptive dropdown...
 * We can share most of the design but the main control item is really different
 * @constructor
 */
export const DescriptiveDropdown: React.FC<DescriptiveDropdownProps> = ({
  onChange,
  options,
  value,
  label,
  helpText,
  required,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { ref } = useOnClickOutside(() => setIsOpen(false));

  const handleChange = (value: string) => {
    onChange(value);
    setIsOpen(false);
  };

  const chosenElement = options.find((el) => el.value === value);
  const optionsLastIndex = options?.length - 1;

  return (
    <div>
      {label && (
        <Label>
          {label} {required && <b>*</b>}
        </Label>
      )}

      {helpText && <Help>{helpText}</Help>}

      <Container ref={ref} onClick={() => setIsOpen(!isOpen)}>
        <DescriptiveControl>
          <ChosenOptionContainer>
            <ChosenOption>
              <DescriptiveOptionLabel>{chosenElement?.label}</DescriptiveOptionLabel>
              <DownArrow />
            </ChosenOption>
            <DescriptiveOptionDescription>
              {chosenElement?.description}
            </DescriptiveOptionDescription>
          </ChosenOptionContainer>
        </DescriptiveControl>

        {isOpen && (
          <Option>
            {options!.map((el, index) => (
              <>
                <ListItem
                  key={index}
                  data-active={el.value === value}
                  onClick={() => handleChange(el.value)}
                >
                  <DescriptiveOptionLabel>
                    {el.label} {el.value === value && <BlueCheckmark />}
                  </DescriptiveOptionLabel>
                  <DescriptiveOptionDescription>{el?.description} </DescriptiveOptionDescription>
                </ListItem>
                {index !== optionsLastIndex && <Separator />}
              </>
            ))}
          </Option>
        )}
      </Container>
    </div>
  );
};

const Container = styled(StyledContainer)`
  min-height: 10rem;
`;

const ChosenOption = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

const ChosenOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Option = styled(OptionsList)`
  max-width: 100%;
  padding: 0.8rem;
  border-radius: 0.4rem;
`;

const Separator = styled.hr`
  margin: 0.8rem 0;
  border: 1px solid var(--color-grey-8);
`;

const ListItem = styled.li`
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  gap: 0.2rem;
`;
