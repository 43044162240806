import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

import { globalConfig } from './configuration/config';
import { keycloak, updateToken } from './keycloak';
import { insufficientStorageStore } from './core/stores/insufficient-storage';
import { authStore } from './core/stores/auth-store';

export class Api {
  private axios: AxiosInstance;

  init() {
    this.axios = axios.create({
      baseURL: globalConfig.config.baseApiUrl,
    });

    this.axios.interceptors.request.use(async (config) => {
      if (keycloak.isTokenExpired(240)) {
        await updateToken();
        config.headers.Authorization = `Bearer ${keycloak.token}`;
      }

      return config;
    });

    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

        /**
         * Sometimes the token doesn't get updated properly, so we need to
         * manually update it.
         *
         * TODO: Retry the previous request after updating the token.
         */
        let shouldRetry = false;

        if (error?.response && error.response.status === 403) {
          shouldRetry = true;
        }

        /**
         * Apisix returns 401 when the token is expired.
         */
        if (error?.response && error.response.status === 401) {
          const apisixHeader = error.response.headers['www-authenticate'];

          if (apisixHeader?.search('producer_realm')) {
            shouldRetry = true;
          }
        }

        if (error?.response && error.response.status === 507) {
          insufficientStorageStore.setState(true);
        }

        if (shouldRetry) {
          await updateToken();

          return this.axios({
            ...originalRequest,
            headers: {
              ...originalRequest.headers,
              Authorization: this.axios.defaults.headers.Authorization,
            },
          });
        } else {
          if (error.response?.status === 401) {
            authStore.setAuthorized(false);
          }
          return Promise.reject(error);
        }
      },
    );
  }

  async get<T>(url: string, config?: AxiosRequestConfig): Promise<T | any> {
    const request = () =>
      this.axios.get(url, {
        ...config,
      });

    try {
      return await request();
    } catch (e) {
      return await this.handleError(e as AxiosError).then((res) => {
        if (res.retry) {
          return request();
        }
      });
    }
  }

  async post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T | any> {
    const request = () =>
      this.axios.post(url, data, {
        ...config,
      });

    try {
      return await request();
    } catch (e) {
      return await this.handleError(e as AxiosError).then((res) => {
        if (res.retry) {
          return request();
        }
      });
    }
  }

  updateSpaceInHeaders(spaceId: string) {
    this.axios.defaults.headers['X-Space-Id'] = spaceId;
  }

  updateTokenInHeaders(token: string) {
    this.axios.defaults.headers['Authorization'] = `Bearer ${token}`;
  }

  async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T | any> {
    const request = () =>
      this.axios.delete(url, {
        ...config,
      });

    try {
      return await request();
    } catch (e) {
      return await this.handleError(e as AxiosError).then((res) => {
        if (res.retry) {
          return request();
        }
      });
    }
  }

  async put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T | any> {
    const request = () =>
      this.axios.put(url, data, {
        ...config,
      });

    try {
      return await request();
    } catch (e) {
      return await this.handleError(e as AxiosError).then((res) => {
        if (res.retry) {
          return request();
        }
      });
    }
  }

  async patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T | any> {
    const request = () =>
      this.axios.patch(url, data, {
        ...config,
      });
    return await request();
  }

  private async handleError(error: AxiosError): Promise<any> {
    if (!error.response) {
      throw error;
    }

    throw error;
  }
}

const api = new Api();

export { api };
