import React from 'react';
import styled from 'styled-components';
import { ElevatedButton } from '../buttons/elevated-button';

interface ErrorScreenProps {
  title: string;
  message: string;
  actionText: string;
  onActionClick: () => void;
}

export const ErrorScreen: React.FC<ErrorScreenProps> = ({
  title,
  message,
  actionText,
  onActionClick,
}) => (
  <ErrorScreenContainer>
    <ErrorScreenTitle>{title}</ErrorScreenTitle>

    <ErrorScreenMessage>{message}</ErrorScreenMessage>

    {onActionClick ? <ElevatedButton text={actionText} onClick={onActionClick} /> : null}
  </ErrorScreenContainer>
);

const ErrorScreenContainer = styled.div`
  min-height: 15rem;
  width: 100%;
  margin: 6.4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ErrorScreenTitle = styled.h2`
  color: white;
  padding-bottom: 1.6rem;
  font-size: 1.8rem;
`;

const ErrorScreenMessage = styled.p`
  color: white;
  padding-bottom: 3.2rem;
  font-size: 1.6rem;
`;
