import React from 'react';
import styled from 'styled-components';

interface AddFileProps {
  acceptedFiles?: string[];
  errorMessage?: string | null;
  preview?: boolean;
  accept?: string;
  sourceFile?: string;
  videoSnapShot?: string;
  file?: File | null;
  setFieldValue: (type: string, file: File | null) => void;
}

export const AddCover = React.forwardRef<HTMLInputElement, AddFileProps>(
  ({ file, setFieldValue, accept = '*' }, ref) => {
    return (
      <FolderContainer>
        {!file ? (
          <UploadedFileInfo dashed={!file}>
            <Placeholder>Choose a cover</Placeholder>
          </UploadedFileInfo>
        ) : (
          <UploadedFileInfo dashed={!file}>
            {file && <Preview src={URL.createObjectURL(file)} />}
          </UploadedFileInfo>
        )}

        <InputFile
          ref={ref}
          id="file"
          type="file"
          accept={accept}
          name="file"
          onChange={(event: any) => {
            setFieldValue && setFieldValue('file', event!.currentTarget!.files![0]);
          }}
        />
      </FolderContainer>
    );
  },
);

AddCover.displayName = 'AddCover';

const FolderContainer = styled.div`
  height: 100%;
  position: relative;
`;

const InputFile = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
`;

interface UploadedFileInfoProps {
  dashed?: boolean;
}

const UploadedFileInfo = styled.div<UploadedFileInfoProps>`
  display: flex;
  border-radius: 0.4rem;
  height: 5.6rem;
  cursor: pointer;
  background-color: #367bff33;
  border: ${({ dashed }) => (dashed ? '1px dashed' : '1px solid')};
  border-color: var(--color-primary-crayola);
`;

const Placeholder = styled.div`
  font-size: 1.6rem;
  color: var(--color-primary-crayola);
  font-weight: 400;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.3rem;
`;

const Preview = styled.img`
  height: 100%;
  object-fit: cover;
  border-radius: 0.8rem;
`;
