import React from 'react';
import styled from 'styled-components';

import { InlineTimePeriodSelector } from '../../features/schedule/components/InlineTimePeriodSelector';

interface TimeInputProps {
  value?: number;
  onChange: (value: number) => void;
  label?: string;
  required?: boolean;
}

export const TimeInputDrawer: React.FC<TimeInputProps> = ({ value, label, onChange, required }) => (
  <TimeInputContainer>
    <MyLabel>
      {label} {required && <b>*</b>}
    </MyLabel>

    <TimeInput>
      <InlineTimePeriodSelector key="time-input-drawer" time={value} onChange={onChange} />
    </TimeInput>
  </TimeInputContainer>
);

const TimeInputContainer = styled.div`
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  min-width: 15rem;
  z-index: 99;
  width: 100%;
  color: var(--select-text-color);

  svg {
    fill: var(--select-border-color);
    width: 1.6rem;
    position: absolute;
    right: 1.6rem;
  }
`;

const MyLabel = styled.div`
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: 0.7px;
  line-height: 1.1rem;
  margin-bottom: 0.8rem;
`;

const TimeInput = styled.div`
  box-sizing: border-box;
  width: 18rem;
  height: 2.5rem;
  border-radius: var(--card-border-radius);
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  line-height: 1.6rem;
  display: flex;
  z-index: 1;
  align-items: center;
  border: 1px solid var(--select-border-color);
  color: var(--select-text-color);
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  background: transparent;

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 12px;
    height: 12px;
  }

  &:focus {
    outline: none;
    border: 1px solid var(--select-border-color);
    color: var(----select-text-color);
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  div {
    color: var(--select-text-color);
    padding: 0 100% 0 0;
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin: 0;

    &:hover {
      background-color: transparent;
    }
  }
`;
