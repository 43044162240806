import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { TeamItem } from './team-item';
import { fetchTeams } from '../../teams/stores/team.slice';
import { LoaderContainer } from '../../../routes/project/components/step-container';
import { Loader } from '../../../components/loader/Loader';
import { CreateTeamModal } from '../../../components/dialogs/CreateTeamModal';
import { ProtectedTeamItem } from './protected-team-item';
import { ElevatedButton } from '../../../components/buttons';
import { Header } from '../../../components/table/styled-shared-table';
import { TeamContact } from '../../teams/models/team';
import { Character } from '../../../app/entities/character';
import { Contact } from '../../../app/entities/contact';
import { breakPoint } from '../../../app/theme';

import type { Step } from '../../../app/entities/step';
import type { Project } from '../../../app/entities/project';

type TeamListProps = {
  project: Project;
  step: Step;
};

export const TeamList: React.FC<TeamListProps> = observer(({ step, project }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);

  useEffect(() => {
    fetchTeams(project._id)
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [project._id]);

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  const characters = step.shots
    .map((shot) => shot.characters)
    .flat()
    .map((character) => character._id);
  const charactersIds = [...new Set(characters)];

  const cast = charactersIds
    ?.map((characterId) => {
      const fullCharacter = Character.getOne(characterId);

      if (!fullCharacter || !fullCharacter.cast) {
        return;
      }

      const contact = Contact.getOne(fullCharacter.cast.contactId);

      return {
        ...fullCharacter.cast,
        email: contact?.email,
        phone: contact?.phone,
        role: fullCharacter.name,
      };
    })
    .filter((cast) => !!cast)
    .map((cast) => ({
      ...cast,
      _id: cast?.contactId,
    })) as TeamContact[];

  const checkIsCrewTeam = (teamId: string) => {
    return project.steps.some((step) => step.info?.teamId === teamId);
  };

  return (
    <Container>
      <Header>
        <h1>Teams</h1>

        <ElevatedButton
          onClick={() => setIsTeamModalOpen((prevState) => !prevState)}
          text="Add team"
        />
      </Header>

      <TeamsContainer>
        {!!project?.contacts?.length && (
          <ProtectedTeamItem
            name="Executive"
            contacts={project.contacts}
            step={step}
            meta={step.contactsMetaData}
          />
        )}

        {project.teams.map((team) => {
          const isCrewTeam = checkIsCrewTeam(team._id);

          return (
            <TeamItem
              key={team._id}
              team={team}
              step={step}
              spaceId={project.spaceId}
              isCrewTeam={isCrewTeam}
            />
          );
        })}

        {!!cast?.length && (
          <ProtectedTeamItem name="Cast" contacts={cast} step={step} meta={step.contactsMetaData} />
        )}
      </TeamsContainer>

      {isTeamModalOpen && project && (
        <CreateTeamModal project={project} onCancel={() => setIsTeamModalOpen(false)} />
      )}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    gap: 1.2rem;
  }
`;

const TeamsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    gap: 2.4rem;
  }
`;
