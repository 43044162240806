import React from 'react';

export const RotateRight = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5042_457632)">
      <path d="M20 10.586L21.828 8.757L23.243 10.172L19 14.414L14.757 10.172L16.172 8.757L18 10.586V8C18 7.20435 17.6839 6.44129 17.1213 5.87868C16.5587 5.31607 15.7956 5 15 5H11V3H15C16.3261 3 17.5979 3.52678 18.5355 4.46447C19.4732 5.40215 20 6.67392 20 8V10.586ZM13 9C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V20C14 20.2652 13.8946 20.5196 13.7071 20.7071C13.5196 20.8946 13.2652 21 13 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H13ZM12 11H4V19H12V11Z" />
    </g>
    <defs>
      <clipPath id="clip0_5042_457632">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
