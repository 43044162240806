import React from 'react';
import styled from 'styled-components';

import { Project } from '../../../app/entities/project';
import { ShotLocationSelector } from '../../schedule/components/shot-location-selector';

interface LocationSelectProps {
  projectId: string;
  onChange: (value: string) => void;
  selectedLocationId: string | undefined;
}

export const LocationSelect: React.FC<LocationSelectProps> = ({
  projectId,
  onChange,
  selectedLocationId,
}) => {
  const project = Project.getOne(projectId)!;

  return (
    <Container onClick={(e: React.MouseEvent) => e.stopPropagation()}>
      <Label>Location</Label>

      <ShotLocationSelector
        project={project}
        onChange={onChange}
        selectedLocationId={selectedLocationId}
      />
    </Container>
  );
};

LocationSelect.displayName = 'LocationSelect';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: 1rem;

  .trigger {
    border: 1px solid var(--color-grayscale-trout);

    &:hover {
      border-color: var(--text-color-white);
    }
  }
`;

const Label = styled.div`
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: 0.7px;
  line-height: 1.1rem;
  color: var(--select-text-color);
`;
