import { api } from '../../api';
import { entityPool } from '../../core/engine/engine';
import { Shot } from '../../app/entities/shot';

export const fetchProjectShots = async (projectId: string) => {
  const { data } = await api.get(`/projects/${projectId}/shots`);

  data.forEach((data: any) => {
    const shot = Object.assign(new Shot(), data);
    entityPool.insert(shot);
  });
};
