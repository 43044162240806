import React from 'react';
import styled from 'styled-components';

import { ImagePlaceholder as ImagePlaceholderImg } from '../icons/ImagePlaceholder';
import { PhotoUploader } from '../photo-uploader/photo-uploader';

interface ImagePlaceholderProps {
  onChange?: (file: File) => void;
}

export const ImagePlaceholder: React.FC<ImagePlaceholderProps> = ({ onChange }) => (
  <>
    {onChange ? (
      <PhotoUploader accept="image/png, image/jpg, image/jpeg" onChange={onChange} />
    ) : (
      <ReplacementImage>
        <ImagePlaceholderImg />
      </ReplacementImage>
    )}
  </>
);

const ReplacementImage = styled.div`
  border-radius: 0.6rem;
  font-family: Inter, sans-serif;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-default);
  background-color: var(--deprecated-grey-white);
  box-sizing: border-box;
  height: 100%;
  width: 100%;

  p {
    text-transform: uppercase;
    font-weight: 600;
  }
`;
