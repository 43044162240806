import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { DownloadIcon, Share2Icon, Cross2Icon, TrashIcon } from '@radix-ui/react-icons';

import { IconButton } from '../../../components/buttons';
import { download } from '../../../lib/utils/download';
import { ShareModal } from '../../share/components/share-modal';
import { useSelection } from '../../../core/contexts/selection.context';
import { breakPoint } from '../../../app/theme';
import { Asset } from '../../../app/entities/asset';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';

type ActionBarProps = {
  spaceId: string;
  projectId: string;
};

export const ActionBar: React.FC<ActionBarProps> = ({ projectId, spaceId }) => {
  const { elements, clear } = useSelection();

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [areLinksSelected, idsToHandle] = useMemo(() => {
    const assets = elements.map((id) => Asset.getOne(id)).filter((el) => !!el) as Asset[];
    const areLinksSelected = assets.every((el) => el.type === 'link');
    const idsToHandle = assets.filter((el) => el.type !== 'link').map((el) => el._id);

    return [areLinksSelected, idsToHandle];
  }, [elements]);

  const downloadAllAssets = async () => {
    if (!idsToHandle.length) return;

    for (const id of idsToHandle) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      download(id);
    }
  };

  const deleteFiles = async () => {
    if (!elements.length) return;
    await Asset.deleteMany(elements);
  };

  if (!elements.length) {
    return null;
  }

  return (
    <Container>
      {showDeleteModal && (
        <DeleteDialog
          title="Delete files"
          text="Are you sure you want to delete the selected files?"
          onCancel={() => setShowDeleteModal(false)}
          onSubmit={deleteFiles}
        />
      )}
      <Actions>
        <IconButton icon={<Cross2Icon />} onClick={clear} variant="default" />
        <IconButton
          icon={<CustomTrashCan />}
          onClick={() => setShowDeleteModal(true)}
          variant="default"
        />
      </Actions>

      <p>
        {elements.length} selected file{elements.length > 1 && 's'}
      </p>

      <Actions>
        <IconButton
          icon={<Share2Icon />}
          onClick={() => setIsShareModalOpen(true)}
          variant={'primary'}
        />
        <IconButton
          icon={<DownloadIcon />}
          onClick={downloadAllAssets}
          variant={'primary'}
          disabled={areLinksSelected}
        />
      </Actions>

      {isShareModalOpen && (
        <ShareModal
          elementIds={elements}
          spaceId={spaceId}
          projectId={projectId}
          onClose={() => setIsShareModalOpen(false)}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  z-index: 111111;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.6rem 1.6rem 3.2rem;
  background: var(--color-grayscale-woodsmoke);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: ${breakPoint.small}px) {
    display: none;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 1.6rem;
`;

const CustomTrashCan = styled(TrashIcon)`
  path {
    stroke: var(--color-error);
  }
`;
