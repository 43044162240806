import React, { useState } from 'react';
import { CheckIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';

interface TextInputProps {
  dark?: boolean;
  checked?: boolean;
  rounded?: boolean;
  onChange?: (e?: any) => void;
}

export const Checkbox: React.FC<TextInputProps> = ({
  checked = true,
  dark = true,
  onChange,
  rounded = false,
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  const handleClick = (e: any) => {
    if (onChange) {
      onChange(e);
    }
    setIsChecked(!isChecked);
  };

  return (
    <CheckboxContainer>
      <HiddenCheckbox checked={checked} onChange={handleClick} />

      <StyledCheckbox checked={checked} dark={dark} rounded={rounded} onClick={handleClick}>
        <CheckIcon />
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

interface StyledCheckboxProps {
  checked: boolean;
  dark: boolean;
  rounded: boolean;
}

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 0.1rem;
  border: 0.1rem solid var(--color-grayscale-manatee);
  transition: all 150ms;
  cursor: pointer;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px var(--white-default);
  }

  svg {
    color: var(--color-grayscale-manatee);
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;
