import React, { useState, PropsWithChildren, useContext, useEffect, useCallback } from 'react';
import { KeyboardKeys } from '../../assets/enums/keyboard-keys.enum';
import { useResponsive } from '../../hooks/useResponsive';

export type Modifier = 'ctrl' | 'shift';

type SelectionContextType = {
  select: (element: string, modifier?: Modifier) => void;
  selectAll: (elements: string[]) => void;
  toggleSelectMode: (element: string) => void;
  clear: () => void;
  elements: string[];
  selectionModeOn: boolean;
};

type SelectionProviderProps = {
  items: string[];
  excludedItems?: string[];
};

export const SelectionContext = React.createContext<SelectionContextType>({
  select: () => {},
  selectAll: () => {},
  toggleSelectMode: () => {},
  clear: () => {},
  elements: [],
  selectionModeOn: false,
});

export const useSelection = () => {
  const { elements, select, clear, toggleSelectMode, selectionModeOn, selectAll } =
    useContext(SelectionContext);
  return { elements, select, clear, selectionModeOn, toggleSelectMode, selectAll };
};

export const SelectionProvider: React.FC<PropsWithChildren<SelectionProviderProps>> = ({
  children,
  items,
  excludedItems,
}) => {
  const { isMobile } = useResponsive();
  const [elements, setElements] = useState<string[]>([]);
  const [selectionModeOn, setSelectionModeOn] = useState(false);

  const excludedMap = excludedItems?.reduce(
    (acc, item) => ({
      ...acc,
      [item]: true,
    }),
    {},
  );

  useEffect(() => {
    setElements((elements) => elements.filter((el) => items.includes(el)));
  }, [items]);

  useEffect(() => {
    if (!elements.length) {
      setSelectionModeOn(false);
    }
  }, [elements]);

  const toggleSelectMode: SelectionContextType['toggleSelectMode'] = (element) => {
    if (!isMobile) {
      return;
    }

    if (elements.includes(element)) {
      return setElements(elements.filter((el) => el !== element));
    }

    setSelectionModeOn(true);
    setElements([...elements, element]);
  };

  const selectAll: SelectionContextType['selectAll'] = (elements) => {
    setElements(elements);
  };

  const select: SelectionContextType['select'] = (element, modifier) => {
    if (isMobile && selectionModeOn) {
      if (elements.includes(element)) {
        return setElements(elements.filter((el) => el !== element));
      } else {
        return setElements([...elements, element]);
      }
    }
    if (modifier === 'ctrl') {
      if (elements.includes(element)) {
        return setElements(elements.filter((el) => el !== element));
      } else {
        return setElements([...elements, element]);
      }
    }

    if (elements.length && modifier === 'shift') {
      const orderedElement = [];
      let currentIndex = 0;
      let firstIndex = 0;
      let lastIndex = 0;

      for (const item of items) {
        if (!elements.includes(item)) {
          currentIndex++;
          continue;
        }

        orderedElement.push({ index: currentIndex, id: item });

        if (orderedElement.length === 1) {
          firstIndex = currentIndex;
        }

        if (orderedElement.length === elements.length) {
          lastIndex = currentIndex;
          break;
        }

        currentIndex++;
      }

      const elementIndex = items.indexOf(element);

      if (elementIndex < firstIndex) {
        const elementsToSelect = items.slice(elementIndex, lastIndex + 1);

        const finalSelection = excludedMap
          ? elementsToSelect.filter((key: string) => !excludedMap[key as keyof typeof excludedMap])
          : elementsToSelect;

        return setElements(finalSelection);
      } else if (elementIndex > lastIndex) {
        const elementsToSelect = items.slice(firstIndex, elementIndex + 1);

        const finalSelection = excludedMap
          ? elementsToSelect.filter((key: string) => !excludedMap[key as keyof typeof excludedMap])
          : elementsToSelect;

        return setElements(finalSelection);
      }
    }

    if (!elements?.includes(element)) {
      return setElements([element]);
    }
  };

  const clear = useCallback(() => {
    setSelectionModeOn(false);
    setElements([]);
  }, []);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      e.stopPropagation();
      if (e.key === KeyboardKeys.Escape) {
        clear();
      }
    };

    window.addEventListener('keydown', listener);
    return () => window.removeEventListener('keydown', listener);
  }, [clear]);

  return (
    <SelectionContext.Provider
      value={{
        elements,
        select,
        toggleSelectMode,
        clear,
        selectAll,
        selectionModeOn,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};
