import React, { useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { Project } from '../../../app/entities/project';
import { listProjectShareLink } from '../share.slice';
import { ShareLinkItem } from './share-link-item';
import { PagePlaceholder } from '../../../components/page-placeholder/page-placeholder';

interface ShareLinkListProps {
  project: Project;
}

export const ShareLinkList: React.FC<ShareLinkListProps> = observer(({ project }) => {
  useEffect(() => {
    listProjectShareLink(project._id);
  }, [project._id]);

  return (
    <Container>
      <Table>
        <TableHead>
          <Row>
            <HeaderCell>Name</HeaderCell>
            <HeaderCell>Creation date</HeaderCell>
            <HeaderCell>Number of items</HeaderCell>
            <HeaderCell>Creator</HeaderCell>
            <HeaderCell>Allow download</HeaderCell>
            <HeaderCell />
          </Row>
        </TableHead>

        {project.shareLinks.length > 0 && (
          <TableBody>
            {project.shareLinks.map((link) => (
              <ShareLinkItem key={link._id} link={link} />
            ))}
          </TableBody>
        )}
      </Table>

      {project.shareLinks.length === 0 && (
        <PagePlaceholder
          title="No files or links shared yet."
          description="You can share multiple items for viewing, commenting or downloading from inside the file's step."
        />
      )}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.6rem;
  overflow: auto;
  max-width: 100%;
  height: 100%;
  padding-bottom: 2.4rem;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead``;

const TableBody = styled.tbody``;

const HeaderCell = styled.th`
  color: var(--color-grayscale-shuttle);
  font-size: 1.4rem;
  font-weight: 400;
  text-align: left;
  width: 1%;
  white-space: nowrap;

  &:nth-child(4) {
    width: 100%;
  }

  &:not(:last-child) {
    padding-right: 9.6rem;
  }
`;

const Row = styled.tr`
  height: 4.8rem;
`;
