import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { ContextualDrawer } from '../../../app/components/contextual-drawer';
import { AssetTags } from './asset-tags';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { CollapsibleItem } from '../../../components/collapsible-item/collapsible-item';
import { VersionListItem } from './version-list-item';

import type { Asset } from 'app/entities/asset';

interface AssetViewerContextDrawerProps {
  asset: Asset;
  selectedAsset: Asset;
  actions: React.ComponentProps<typeof DropdownMenu>['items'];
  onAddTag: (tag: Omit<Asset['tags'][number], '_id'>) => void;
  onRemoveTag: (tag: Asset['tags'][number]) => void;
  onVersionSelect: (version: Asset) => void;
}

export const AssetViewerContextDrawer: React.FC<AssetViewerContextDrawerProps> = observer(
  ({ asset, selectedAsset, actions, onAddTag, onRemoveTag, onVersionSelect }) => {
    const uploader = selectedAsset.uploaderName || asset.uploaderName;
    const relativeTime = selectedAsset.relativeUploadTime || asset.relativeUploadTime;

    const actionsHaveIcon = actions.some((item) => !!item.icon);

    return (
      <ContextualDrawer>
        <InfoContainer>
          <Card>
            <Title>
              {uploader}
              {uploader && relativeTime && ` - `}
              {relativeTime}
            </Title>

            <AssetTags
              key={`context-drawer-tag-${selectedAsset._id}`}
              tags={selectedAsset.tags}
              onAddTag={onAddTag}
              onRemoveTag={onRemoveTag}
            />
          </Card>

          {(asset.type === 'version_stack' || selectedAsset?.version! > 0) && (
            <CollapsibleItem
              title={<Title>Versions</Title>}
              triggerType="transparent-small"
              padding="none"
              defaultOpen
              isTransparent
            >
              <CollapsibleContent data-gap="narrow">
                {asset.sortedChildren.map((asset) => (
                  <VersionListItem
                    key={asset._id}
                    onClick={() => onVersionSelect(asset)}
                    thumbnail={asset.cover?.src || asset.thumbnail}
                    isSelected={selectedAsset?._id === asset._id}
                    name={asset.name}
                    version={asset.version || 1}
                    date={asset.createdAt}
                    uploader={asset.uploader?.firstName || ''}
                    fileType={asset.fileType}
                    extension={asset.extension}
                  />
                ))}
              </CollapsibleContent>
            </CollapsibleItem>
          )}

          {!!actions?.length && (
            <div>
              <Title>Actions</Title>

              <CollapsibleContent>
                {actions.map((action) => {
                  if (action.hidden) {
                    return;
                  }

                  return (
                    <ActionItem
                      key={action.title as string}
                      data-type={action.type}
                      data-disabled={action.disabled}
                      onClick={
                        action.onSelect as React.MouseEventHandler<HTMLDivElement> | undefined
                      }
                    >
                      {actionsHaveIcon && <ActionItemIcon>{action.icon}</ActionItemIcon>}

                      {action.title}
                    </ActionItem>
                  );
                })}
              </CollapsibleContent>
            </div>
          )}
        </InfoContainer>
      </ContextualDrawer>
    );
  },
);

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding-bottom: 1.6rem;
  border-bottom: 0.1rem solid var(--color-grayscale-tuna);
`;

const Title = styled.h6`
  font-size: 1.4rem;
  font-weight: 300;
  padding: 0.8rem 0;
  color: var(--color-grayscale-white);
`;

const CollapsibleContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.8rem;
  gap: 1.2rem;

  &[data-gap='narrow'] {
    gap: 0.8rem;
  }
`;

const ActionItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.8rem 1.6rem;
  gap: 0.8rem;
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--color-grayscale-white);
  cursor: pointer;

  &[data-type='danger'] {
    color: var(--color-error);
  }

  &[data-disabled='true'] {
    color: var(--color-grayscale-trout);
    pointer-events: none;
  }
`;

const ActionItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.8rem;
  width: 1.6rem;
  height: 1.6rem;

  svg {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
  }
`;
