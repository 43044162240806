import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { isToday, format } from 'date-fns';

import { Close } from '../../../components/icons/Close';
import { AlertError } from '../../../components/icons/AlertError';
import { AlertSuccess } from '../../../components/icons/AlertSuccess';
import { AlertWarning } from '../../../components/icons/AlertWarning';
import { timeShort } from '../../../assets/contants/dates-fns';
import { Robot } from '../../../components/icons/Robot';
import { Toast } from '../models/toast';
import { breakPoint } from '../../../app/theme';

type ToastItemProps = {
  toast: Toast;
};

const formattingDayPeriodValues = () => {
  const dayPeriodEnumValue = new Date().getHours() / 12 >= 1 ? 'PM' : 'AM';

  return isToday(new Date())
    ? `Today ${format(new Date(), timeShort)}${dayPeriodEnumValue}`
    : `${format(new Date(), timeShort)}${dayPeriodEnumValue}`;
};

const selectAlertType = (type: string) => {
  switch (type) {
    case 'success':
      return <AlertSuccess />;
    case 'error':
      return <AlertError />;
    case 'warning':
      return <AlertWarning />;
    case 'robot':
      return <Robot />;
  }
};

export const ToastItem: React.FC<ToastItemProps> = ({ toast }) => {
  const [time, setTime] = useState<any>(0);
  const currentMessageTime = formattingDayPeriodValues();
  const icon = selectAlertType(toast.type);

  const setToastTimeout = useCallback(() => {
    const deleteElement = setTimeout(() => toast.delete(), 3000);
    setTime(deleteElement);
  }, [toast]);

  useEffect(() => {
    setToastTimeout();
  }, [setToastTimeout]);

  return (
    <Element onMouseOver={() => clearTimeout(time)} onMouseLeave={setToastTimeout}>
      <CloseIcon onClick={() => toast.delete()}>
        <Close />
      </CloseIcon>

      <AlertContent>
        <AlertIconType>{icon}</AlertIconType>

        <AlertInformation>
          <AlertText>{toast.message}</AlertText>
          <TimeBox>{toast.subheader || currentMessageTime}</TimeBox>
        </AlertInformation>
      </AlertContent>
    </Element>
  );
};

export const Element = styled.div`
  position: relative;
  background: linear-gradient(112.83deg, rgba(53, 53, 53, 0.376) 0%, rgba(56, 59, 70, 0.8) 110.84%);
  border-radius: var(--border-radius-medium);
  backdrop-filter: blur(8px);
  padding: 1.6rem;
  width: 25rem;

  @media screen and (min-width: ${breakPoint.small}px) {
    width: 40rem;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  @keyframes animation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  cursor: pointer;

  svg {
    height: 1.4rem;
    width: 1.4rem;
    fill: white;
  }
`;

const AlertContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

const AlertIconType = styled.div`
  svg {
    height: 1.8rem;
    width: 1.8rem;
  }
`;

const AlertText = styled.p`
  font-weight: 500;
  font-size: 1.4rem;
  color: #ffffff;
  margin-bottom: 0.4rem;
`;

const AlertInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 1.6rem;
`;

const TimeBox = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  color: #ffffff;
  opacity: 0.6;
`;
