import React, { useState } from 'react';
import styled from 'styled-components';

import { BaseDialog, DialogFooter, DialogHeader } from '../dialog';
import { ElevatedButton, TextButton } from '../buttons';
import { TextInput } from '../text-input/TextInput';
import { createTeam } from '../../features/teams/stores/team.slice';
import { Project } from '../../app/entities/project';

type CreateTeamModalProps = {
  project: Project;
  onCancel: () => void;
};

export const CreateTeamModal: React.FC<CreateTeamModalProps> = ({ project, onCancel }) => {
  const [inputValue, setInputValue] = useState('');

  const handleSubmit = async () => {
    await createTeam({ projectId: project._id, name: inputValue });
    onCancel();
  };

  return (
    <BaseDialog onClose={onCancel}>
      <DialogHeader title={'Add team'} onClose={onCancel} />
      <Container>
        <TextInput
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          label={'Team name'}
          placeholder={'Enter...'}
          name={'name'}
          required
        />
      </Container>

      <DialogFooter
        actions={[
          <TextButton key="cancel" text="Cancel" onClick={onCancel} />,
          <ElevatedButton
            key="add"
            text={'Add'}
            onClick={handleSubmit}
            disabled={inputValue.length <= 2}
          />,
        ]}
      />
    </BaseDialog>
  );
};

const Container = styled.div`
  padding: 1.6rem;
`;
