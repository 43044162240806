import { api } from '../../api';
import { Step } from '../../app/entities/step';
import { entityPool } from '../../core/engine/engine';
import { Stage } from '../../app/entities/stage';
import { Project } from '../../app/entities/project';

/**
 * Create a new step
 */
type CreateStepInput = {
  stageId: string;
  name: string;
  startDate?: string;
  dueDate?: string;
  type: string;
};

export const createStep = async ({ stageId, ...input }: CreateStepInput) => {
  const { data } = await api.post(`/stages/${stageId}/steps`, input);
  entityPool.insert(Object.assign(new Step(), { ...data, stageId }));

  const stage = Stage.getOne(stageId);

  if (!stage) {
    return;
  }

  const project = Project.getOne(stage.projectId);
  if (project) {
    project.progress = data.progress;
  }

  return data;
};

/**
 * Get all steps of a project
 */
export const fetchStepsByProject = async (projectId: string) => {
  const { data } = await api.get(`/projects/${projectId}/steps`);
  data.forEach((step: Step) => {
    // @ts-ignore
    entityPool.insert(Object.assign(new Step(), step, { assets: [] }));
  });

  const stages = Stage.getAll().filter((s) => s.projectId === projectId);

  stages.forEach((stage) => {
    if (stage.name.toLowerCase() === 'production') {
      stage.steps.sort(
        (a, b) =>
          (a.dueDate ? new Date(a.dueDate).getTime() : 0) -
          (b.dueDate ? new Date(b.dueDate).getTime() : 0),
      );
    } else {
      stage.steps.sort((a, b) => a.order - b.order);
    }
  });

  return data;
};
