import { makeObservable, observable } from 'mobx';

export class LocalArrayStore<T> {
  elements: T[] = [];

  constructor(initialElements: T[] = []) {
    this.elements = initialElements;

    makeObservable(this, {
      elements: observable,
    });
  }

  insert(element: T) {
    this.elements = [...(this.elements || []), element];
  }

  delete(element: T) {
    this.elements = this.elements.filter((e) => e !== element);
  }
}
