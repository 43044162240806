import React from 'react';

import { LineProducerScreen } from '../../../features/collaborators/screens/line-producer.screen';
import { Project } from '../../../app/entities/project';

import type { Step } from '../../../app/entities/step';

export interface LineProducerPageProps {
  project: Project;
  step: Step;
}

export const LineProducerPage: React.FC<LineProducerPageProps> = ({ project, step }) => (
  <LineProducerScreen step={step} project={project} />
);
