import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { DownloadIcon, Pencil1Icon, Share2Icon, TrashIcon } from '@radix-ui/react-icons';

import { ScreenHeader } from '../../projects/components/screen-header';
import { FilterButton } from '../../../routes/project/components/assets/filter-button';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { DownloadShareButton } from './download-share-button';
import { ShareModal } from '../../share/components/share-modal';
import { download } from '../../../lib/utils/download';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { RenameStepModal } from '../../process/components/rename-step.modal';
import { StyledButton } from '../../../components/buttons/styled-buttons';
import { GridIcon } from '../../../components/icons/grid-icon';
import { ListDisplayIcon } from '../../../components/icons/list-display-icon';
import { useResponsive } from '../../../hooks/useResponsive';
import { AddAssetButton } from '../../../routes/project/components/assets/add-asset-button';
import { ConfigureLinkTypeAssetModal } from '../../process/components/configure-link-type-asset.modal';
import { createAsset } from '../store/assets.slice';

import type { Step } from '../../../app/entities/step';
import { authStore } from '../../../core/stores/auth-store';
import { SORTING_TYPES } from '../../../assets/enums/assetType.enum';
import { Asset } from '../../../app/entities/asset';

type AssetFilesHeaderProps = {
  step: Step;
  spaceId: string;
  fileIds: string[];
  isFolder?: boolean;
  handleAddFiles: (files: File[]) => Promise<void>;
  displayLayout: 'grid' | 'list';
  toggleDisplayLayout: () => void;
};

export const AssetFilesHeader: React.FC<AssetFilesHeaderProps> = observer(
  ({ step, spaceId, isFolder, fileIds, handleAddFiles, displayLayout, toggleDisplayLayout }) => {
    const navigate = useNavigate();
    const { isDesktop } = useResponsive();
    const [isDeleteStepModalOpen, setIsDeleteStepModalOpen] = useState(false);
    const [isDeleteAssetsModalOpen, setIsDeleteAssetsModalOpen] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [isCreateLinkModalOpen, setIsCreateLinkModalOpen] = useState(false);

    const currentMember = authStore.getCurrentMember(spaceId);
    const userStepPreferences = currentMember?.preferences.stepsUI[step._id];

    const [fileIdsToDownload] = useMemo(() => {
      const fileIdsToDownload =
        step.assets?.filter((file) => file.type !== 'link')?.map((file: any) => file._id) || [];

      return [fileIdsToDownload];
    }, [step.assets]);

    const goToLocation = () => navigate(`./steps/${step._id}`);

    const onSortBySelectList = (sortBy: string) => {
      currentMember?.changeStepOrder(step, sortBy);
    };

    const handleDeleteStep = async () => {
      await step.delete();
      const href = `../..`;
      navigate(href);
    };

    const handleDeleteAssets = async () => {
      await Asset.deleteMany(fileIds);
    };

    const downloadAllAssets = async () => {
      if (!step.assets.length || !fileIdsToDownload.length) {
        return;
      }

      for (const id of fileIdsToDownload) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        download(id);
      }
    };

    const items: React.ComponentProps<typeof DropdownMenu>['items'] = [
      {
        title: 'Share all',
        icon: <Share2Icon />,
        disabled: !step.assets.length || !fileIds.length,
        onSelect: () => setIsShareModalOpen(true),
      },
      {
        title: 'Download all',
        icon: <DownloadIcon />,
        disabled: !step.assets.length || !fileIdsToDownload.length,
        onSelect: downloadAllAssets,
      },
      {
        title: 'Rename step',
        icon: <Pencil1Icon />,
        onSelect: () => setIsRenameModalOpen(true),
      },
      {
        title: 'Delete all files',
        icon: <TrashIcon />,
        type: 'danger' as const,
        onSelect: () => setIsDeleteAssetsModalOpen(true),
      },
      {
        title: 'Delete step',
        icon: <TrashIcon />,
        type: 'danger' as const,
        onSelect: () => setIsDeleteStepModalOpen(true),
      },
    ];

    const handleOnCreateLink = async (values: { address: string; name?: string }) => {
      await createAsset({
        parentType: 'step',
        type: 'link',
        stepId: step._id,
        url: values.address,
        name: values.name,
      });
    };

    const handleCreateNoteAsset = async () => {
      const { asset } = await createAsset({
        parentType: 'step',
        type: 'note',
        stepId: step._id,
        name: 'Untitled Note',
        content: '',
      });
      // redirect to newly created note
      if (asset) navigate(`./file/${asset._id}`);
    };

    return (
      <>
        <ScreenHeader
          backButton={
            isFolder ? { label: `Back to ${step.name}`, onClick: goToLocation } : undefined
          }
          stepId={step._id}
          title={step.name}
          dueDate={step.dueDate}
          startDate={step.startDate}
          handleUpdateTimeFrame={(values) => step.update(values)}
          canValidateStep={!isFolder}
          menu={<DropdownMenu items={items} size={'large'} />}
          actions={[
            isDesktop && (
              <DownloadShareButton
                key="download-multiple-files"
                spaceId={step.project!.spaceId}
                projectId={step.projectId}
              />
            ),
            <LayoutToggleButton key="change-layout-button" onClick={toggleDisplayLayout}>
              <span data-active={displayLayout === 'grid'}>
                <GridIcon />
              </span>
              <span data-active={displayLayout === 'list'}>
                <ListDisplayIcon />
              </span>
            </LayoutToggleButton>,
            <FilterButton
              key="files-button"
              value={userStepPreferences?.order || SORTING_TYPES.UPLOADED_DATE}
              onSelect={onSortBySelectList}
            />,
            <AddAssetButtonWrapper key="add-asset-button">
              <AddAssetButton
                onSelectFile={handleAddFiles}
                onSelectLink={() => setIsCreateLinkModalOpen(true)}
                onSelectNote={handleCreateNoteAsset}
              />
            </AddAssetButtonWrapper>,
          ]}
        />

        {isDeleteAssetsModalOpen && (
          <DeleteDialog
            title="Delete files"
            text="Are you sure you want to delete all the files in this step?"
            onCancel={() => setIsDeleteAssetsModalOpen(false)}
            onSubmit={handleDeleteAssets}
          />
        )}

        {isDeleteStepModalOpen && (
          <DeleteDialog
            title="Delete step"
            text="Are you sure you want to delete this step?"
            onCancel={() => setIsDeleteStepModalOpen(false)}
            onSubmit={handleDeleteStep}
          />
        )}

        {isShareModalOpen && (
          <ShareModal
            elementIds={fileIds}
            spaceId={step.project!.spaceId}
            projectId={step.projectId}
            onClose={() => setIsShareModalOpen(false)}
          />
        )}

        {isCreateLinkModalOpen && (
          <ConfigureLinkTypeAssetModal
            onSubmit={(values) => handleOnCreateLink(values as { address: string; name?: string })}
            onCancel={() => setIsCreateLinkModalOpen(false)}
          />
        )}

        {isRenameModalOpen && (
          <RenameStepModal step={step} onCancel={() => setIsRenameModalOpen(false)} />
        )}
      </>
    );
  },
);

const LayoutToggleButton = styled(StyledButton)`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 3.2rem;
  width: 6.4rem;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 100%;
    width: 3.2rem;
    transition: background-color 0.3s ease;

    svg {
      width: 1.6rem;
      height: 1.6rem;
    }

    &[data-active='true'] {
      background-color: var(--color-primary-crayola);

      svg {
        path {
          fill: white;
        }
      }
    }
  }
`;

const AddAssetButtonWrapper = styled.div`
  button {
    background-color: var(--color-primary-crayola);
    color: white;
  }
`;
