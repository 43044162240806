import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import axios from 'axios';

import '@producer-io/ui-kit/dist/style.css';
import './index.css';
import './ds-colors.css';
import * as serviceWorker from './serviceWorker';
import { defaultConfig, globalConfig, globalConfigUrl } from './configuration/config';
import { api } from './api';
import { handleAuthentication, initKeycloak, keycloak } from './keycloak';
import { MixpanelProvider } from './wrappers/mixpanel';
import { initSentry } from './lib/sentry';
import { initHotjar } from './lib/hotjar';
import { routes } from './app/router';

axios
  .get(`${window.location.origin}/${globalConfigUrl}`, {
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  })
  .then((response) => {
    globalConfig.config = response.data;

    /**
     * Initialize sentry if not in development mode
     */
    if (process.env.NODE_ENV !== 'development') {
      initSentry();
    }

    /**
     * Initialize hotjar if not in development mode
     */
    if (globalConfig.config.environment === 'production' && globalConfig.config.hotjar) {
      initHotjar(globalConfig.config.hotjar);
    }

    /**
     * Initialize api with configuration information
     */
    api.init();

    /**
     * Initialize keycloak with configuration information
     */
    return initKeycloak(globalConfig.config.keycloak);
  })
  .catch((e) => {
    if (process.env.NODE_ENV === 'development') {
      // You cannot change the value of NODE_ENV. To test this if, change "development"
      console.warn(
        `Failed to load global configuration from '${globalConfigUrl}', using the default configuration instead:`,
        defaultConfig,
      );
      globalConfig.config = defaultConfig;
      return;
    } else {
      const errorMessage =
        'Error while fetching global config, the App wil not be rendered. (This is NOT a React error.)';
      console.error(errorMessage, `Have you provided the config file '${globalConfigUrl}'?`, e);
      return <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>;
    }
  })
  .then(async () => {
    const token = localStorage.getItem('kc_token');
    const refreshToken = localStorage.getItem('kc_refreshToken');

    try {
      let authenticated = await keycloak.init({
        onLoad: 'check-sso',
        checkLoginIframe: false,
        token: token || undefined,
        refreshToken: refreshToken || undefined,
      });
      handleAuthentication(authenticated);
    } catch (error) {
      console.error(error);
    }
  })
  .then(() => {
    const msalConfig = {
      auth: {
        clientId: globalConfig.config.marketplace.clientId,
        authority: `https://login.microsoftonline.com/${globalConfig.config.marketplace.tenantId}`,
        redirectUri: globalConfig.config.marketplace.redirectUrl,
      },
      cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
    };

    const msalInstance = new PublicClientApplication(msalConfig);

    const root = createRoot(document.getElementById('root')!);
    const router = createBrowserRouter(routes);

    root.render(
      <MixpanelProvider>
        <MsalProvider instance={msalInstance}>
          <RouterProvider router={router} />
        </MsalProvider>
      </MixpanelProvider>,
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
