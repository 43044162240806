import React from 'react';

interface IconProps {
  size?: number;
  strokeWidth?: number;
  color?: string;
  stroke?: string;
  fill?: string;
}

export const Close: React.FC<IconProps> = ({
  size = 16,
  strokeWidth = 1,
  stroke = 'none',
  fill,
}) => (
  <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16" version="1.1">
    <g id="Design-System" stroke={stroke} strokeWidth={strokeWidth} fill={fill} fillRule="evenodd">
      <g id="Basic-UI-Elements" transform="translate(-60.000000, -321.000000)" fillRule="nonzero">
        <g id="icon/close" transform="translate(60.000000, 321.000000)">
          <path
            d="M15.7070944,1.70711911 C16.0976256,1.31660163 16.0976366,0.683436652 15.7071191,0.29290555 C15.3166016,-0.0976255519 14.6834367,-0.0976365936 14.2929056,0.292880888 L8,6.58556696 L1.70709445,0.292880888 C1.31656335,-0.0976365936 0.683398369,-0.0976255519 0.292880888,0.29290555 C-0.0976365936,0.683436652 -0.0976255519,1.31660163 0.29290555,1.70711911 L6.58576177,7.99975586 L0.29290555,14.2923926 C-0.0976255519,14.6829101 -0.0976365936,15.3160751 0.292880888,15.7066062 C0.683398369,16.0971373 1.31656335,16.0971483 1.70709445,15.7066308 L8,9.41394476 L14.2929056,15.7066308 C14.6834367,16.0971483 15.3166016,16.0971373 15.7071191,15.7066062 C16.0976366,15.3160751 16.0976256,14.6829101 15.7070944,14.2923926 L9.41423823,7.99975586 L15.7070944,1.70711911 Z"
            id="x-close"
          />
        </g>
      </g>
    </g>
  </svg>
);
