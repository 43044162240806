import React from 'react';

export const ContactMail = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5198 0.800781H14.4798C14.6708 0.800781 14.8539 0.878043 14.9889 1.01557C15.1239 1.1531 15.1998 1.33962 15.1998 1.53411V13.2674C15.1998 13.4619 15.1239 13.6485 14.9889 13.786C14.8539 13.9235 14.6708 14.0008 14.4798 14.0008H1.5198C1.32885 14.0008 1.14571 13.9235 1.01069 13.786C0.875662 13.6485 0.799805 13.4619 0.799805 13.2674V1.53411C0.799805 1.33962 0.875662 1.1531 1.01069 1.01557C1.14571 0.878043 1.32885 0.800781 1.5198 0.800781ZM8.043 7.16831L3.42636 3.17531L2.49396 4.29291L8.05236 9.09992L13.5107 4.28925L12.5689 3.17971L8.04372 7.16831H8.043Z"
      fill="#606570"
    />
  </svg>
);
