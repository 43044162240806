import { api } from '../../api';

import { entityPool } from '../../core/engine/engine';
import { ShareLink } from '../../app/entities/share-link';

export const listProjectShareLink = async (projectId: string) => {
  const { data } = await api.get(`/projects/${projectId}/share-links`);

  data.forEach((link: ShareLink) => {
    const shareLink = Object.assign(new ShareLink(), link);
    entityPool.insert(shareLink);
  });
};

type FetchShareLinkInput = {
  spaceId: string;
  projectId: string;
  assetIds: string[];
};

export const createShareLink = async ({
  projectId,
  spaceId,
  assetIds,
}: FetchShareLinkInput): Promise<ShareLink> => {
  const { data } = await api.post('/share-links', { projectId, spaceId, assetIds });
  const shareLink = Object.assign(new ShareLink(), data);
  entityPool.insert(shareLink);
  return data;
};
