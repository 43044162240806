import { api } from '../../../api';
import { entityPool } from '../../../core/engine/engine';
import { Member } from '../../../app/entities/member';
import { Invitation } from '../../../app/entities/invitation';

/**
 * Fetch member for an account
 */
export const fetchMembers = async (spaceId: string) => {
  const { data } = await api.get(`/spaces/${spaceId}/members`);

  const existingMembers = Member.getAll();

  existingMembers.forEach((member) => {
    member.remove();
  });

  data.forEach((el: any) => {
    const member = Object.assign(new Member(), el);
    entityPool.insert(member);
  });
};

/**
 * Create member thunk
 */

type CreateMemberInput = {
  spaceId: string;
  members: Array<{
    email: string;
    role: 'admin' | 'creator';
  }>;
};

export const createMember = async ({ spaceId, ...input }: CreateMemberInput) => {
  const { data }: { data: Member[] } = await api.post(`/spaces/${spaceId}/members`, input);
  return data.map((el) => {
    const member = Object.assign(new Invitation(), el);
    entityPool.insert(member);
    return member;
  });
};
