import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Outlet } from 'react-router-dom';
import { SettingsPageLayout } from '../../settings/components/settings-page-layout';
import { AppHeader } from '../../../app/components/app-header';
import { breakPoint } from '../../../app/theme';
import { uiStore } from '../../../core/stores/ui-store';

const menu = [
  {
    key: 'profile',
    label: 'Profile',
    href: `./`,
  },
  {
    key: 'security',
    label: 'Password and authentication',
    href: `./security`,
  },
  {
    key: 'notifications',
    label: 'Notifications',
    href: `./notifications`,
  },
];

export const UserSettingsPage = () => {
  useEffect(() => {
    uiStore.pageTitle = {
      label: 'Personal settings',
    };

    uiStore.showBack = true;
  }, []);

  return (
    <Main>
      <AppHeaderWrapper>
        <AppHeader />
      </AppHeaderWrapper>

      <SettingsPageLayout title="Personal Settings" menu={menu} displayBackButton>
        <Outlet />
      </SettingsPageLayout>
    </Main>
  );
};

const Main = styled.main`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const AppHeaderWrapper = styled.div`
  @media (min-width: ${breakPoint.small}px) {
    display: none;
  }
`;
