import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { ShotLocationSelector } from './shot-location-selector';
import { EditableText } from '../../../components/editable-text/EditableText';
import { Description } from '../../../components/texts/Texts';
import { InlineDropDown } from '../../../components/dropdowns/InlineDropDown';
import { CoverPlaceholder } from '../../strip-board/components/cover-placeholder';
import { InlineTimeSelector } from './InlineTimeSelector';
import { InlineTimePeriodSelector } from './InlineTimePeriodSelector';
import { ShotCharactersSelector } from './shot-characters-selector';
import { AddStripMenu } from './add-strip-menu';
import { Shot, UpdateShotsInput } from '../../../app/entities/shot';
import { Stripboard } from '../../../app/entities/stripboard';
import {
  BaseRow,
  Cell,
  Image,
  CoverItem,
  OrderIndicator,
  HiddenCoverOverlay,
} from './styled-strips';
import { useSelection } from '../../../core/contexts/selection.context';
import { bulkUpdateStore } from '../../../core/stores/bulk-update';
import { ShotStrip } from '../models/types';

type ShotStripProps = {
  stripboard: Stripboard;
  style?: any;
  isSelected?: boolean;
  isDragging?: boolean;
  strip: ShotStrip;
  onClick?: (e: React.MouseEvent) => void;
};

export const ShotStripComponent = observer(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLTableRowElement, ShotStripProps>(
    ({ stripboard, strip, isSelected, isDragging, onClick, ...draggableProps }, ref) => {
      const { elements: selectedStripsIds } = useSelection();

      const shot = Shot.getOne(strip.data.shotId);
      const isBulkUpdate = selectedStripsIds && selectedStripsIds.length > 1;

      const handleSingleEdit = (data: UpdateShotsInput) => {
        shot && shot.update(data!);
      };

      const handleEdit = (data: UpdateShotsInput) => {
        const selectedShotsIds = stripboard.strips
          .filter((el) => selectedStripsIds.includes(el._id) && el.type === 'shot')
          .map((el) => el.data.shotId);

        isBulkUpdate
          ? bulkUpdateStore.handleShotsBulkUpdate(data, selectedShotsIds, shot!)
          : handleSingleEdit(data);
      };

      if (!shot) {
        return <Row />;
      }

      const handleRemoveCharacter = (characterIdToRemove: string) => {
        isBulkUpdate
          ? handleEdit({ characterIdToRemove })
          : shot.deleteCharacter(characterIdToRemove);
      };

      const handleAddCharacter = (characterIdToAdd: string) => {
        isBulkUpdate ? handleEdit({ characterIdToAdd }) : shot.addCharacter(characterIdToAdd);
      };

      return (
        <>
          <Row
            ref={ref}
            onClick={onClick}
            data-selected={isSelected}
            data-dragged={isDragging}
            data-isHidden={strip.data.isHidden}
            {...draggableProps}
          >
            <Cell>
              <CoverWrapper>
                {!!shot.order && <OrderIndicator>{shot.order}</OrderIndicator>}

                {shot.cover?.src ? (
                  <Image src={shot.cover?.src || ''} />
                ) : (
                  <CoverPlaceholder onUpload={(file) => handleSingleEdit({ file })} />
                )}

                <HiddenCoverOverlay data-hide={!strip.data.isHidden} />
              </CoverWrapper>
            </Cell>

            <Cell data-name="Location" onClick={(e) => e.stopPropagation()}>
              {shot.project && (
                <ShotLocationSelector
                  project={shot.project}
                  onChange={(value) => handleEdit({ locationId: value })}
                  selectedLocationId={shot.locationId}
                />
              )}
            </Cell>

            <Cell>
              <InlineDropDown
                value={shot.setting}
                label={shot.setting}
                onChange={(setting) => handleEdit({ setting } as any)}
                options={[
                  { label: 'Interior', value: 'interior' },
                  { label: 'Exterior', value: 'exterior' },
                ]}
              />
            </Cell>

            <Cell>
              <InlineDropDown
                value={shot.timing}
                label={shot.timing}
                onChange={(timing) => handleEdit({ timing } as any)}
                options={[
                  { label: 'Day', value: 'day' },
                  { label: 'Night', value: 'night' },
                ]}
              />
            </Cell>

            <Cell>
              <CustomEditable onSubmit={(title) => handleSingleEdit({ title })}>
                <CustomDescription>{shot.title}</CustomDescription>
              </CustomEditable>
            </Cell>

            <Cell onClick={(e) => e.stopPropagation()}>
              <ShotCharactersSelector
                shot={shot}
                onAdd={handleAddCharacter}
                onRemove={handleRemoveCharacter}
              />
            </Cell>

            <Cell>
              {!strip.data.isHidden && (
                <InlineTimePeriodSelector
                  onChange={(estimatedTime) => handleEdit({ estimatedTime })}
                  time={shot.estimatedTime}
                />
              )}
            </Cell>

            <Cell>
              {strip.startTime && (
                <InlineTimeSelector time={strip.startTime || { hour: 0, minute: 0 }} disabled />
              )}
            </Cell>

            <Cell>
              <AddStripMenu
                stripId={strip._id}
                projectId={stripboard.projectId}
                position={strip.position}
                shot={shot}
                stripType="shot"
              />
            </Cell>
          </Row>
        </>
      );
    },
  ),
);

ShotStripComponent.displayName = 'ShotStrip';

const Row = styled(BaseRow)`
  align-items: center;
  background-color: var(--color-grayscale-charleston);

  &[data-isHidden='true'] {
    *:not(button),
    *:hover:not(button),
    *:focus-visible:not(button) {
      color: var(--color-grayscale-trout);
    }
  }
`;

const CoverWrapper = styled(CoverItem)`
  margin-inline-start: 0.3rem;
`;

const CustomEditable = styled(EditableText)`
  height: 100%;
  display: flex;
  align-items: center;
`;

const CustomDescription = styled(Description)`
  height: fit-content;
  padding: 0;
  line-height: 2.24rem;
  overflow-y: auto;
  color: var(--color-grayscale-ghost);

  &::-webkit-scrollbar {
    display: none;
  }
`;
