import React from 'react';

export const FileTypeJPG = () => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.250019 5L4.75227 0.5H12.9985C13.4125 0.5 13.75 0.84125 13.75 1.244V14.756C13.7498 14.9534 13.6713 15.1426 13.5316 15.2821C13.392 15.4216 13.2027 15.5 13.0053 15.5H0.994769C0.896276 15.4993 0.798884 15.4792 0.708153 15.4409C0.617421 15.4026 0.535128 15.3468 0.465971 15.2766C0.396814 15.2065 0.342148 15.1234 0.305095 15.0322C0.268042 14.9409 0.249327 14.8432 0.250019 14.7448V5ZM5.50002 1.625L1.37502 5.75H5.50002V1.625Z"
      fill="#9D9D9D"
    />
  </svg>
);
