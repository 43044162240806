import styled from 'styled-components';
import React from 'react';
import { PauseIcon, PlayIcon } from '@radix-ui/react-icons';

type ProgressIndicatorProps = {
  progress: number;
  onPause?: () => void;
  onResume?: () => void;
  status?: string;
  displayProgress?: boolean;
  controlsAlwaysDisplayed?: boolean;
  className?: string;
};

export const CircularProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  progress,
  status,
  onPause,
  onResume,
  displayProgress,
  controlsAlwaysDisplayed,
  className,
}) => {
  return (
    <CircularProgressBar
      progress={progress}
      className={className}
      data-controls={!!controlsAlwaysDisplayed}
    >
      {displayProgress && <span className={status ? 'progress' : ''}>{progress}%</span>}
      {status !== 'paused' && onPause && (
        <PauseIcon
          className={'action'}
          onClick={(e) => {
            e.stopPropagation();
            onPause();
          }}
        />
      )}
      {status === 'paused' && onResume && (
        <PlayIcon
          className={'action'}
          onClick={(e) => {
            e.stopPropagation();
            onResume();
          }}
        />
      )}
    </CircularProgressBar>
  );
};

interface ProgressBarProps {
  progress: number;
}

export const CircularProgressBar = styled.div<ProgressBarProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8rem;
  width: 8rem;
  font-size: 1.4rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    z-index: -1;

    background: conic-gradient(
      var(--color-primary) ${({ progress }) => `${progress}%`},
      0,
      hsl(233 34% 92%) calc(100% - ${({ progress }) => `${progress}%`})
    );

    mask-image: radial-gradient(transparent 63%, black calc(63% + 0.5px));
  }

  cursor: pointer;

  &[data-controls='false'] {
    & .action {
      display: none;
    }

    &:hover {
      & .action {
        display: block;
      }

      & .progress {
        display: none;
      }
    }
  }
`;
