import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ElevatedButton } from '../../components/buttons';
import { breakPoint } from '../theme';

const STORAGE_KEY = 'desktop-warning';

export const DesktopWarning = () => {
  const [shouldDisplay, setShouldDisplay] = useState(false);

  useEffect(() => {
    const hasDismissed = window.localStorage.getItem(STORAGE_KEY);

    if (!hasDismissed) {
      setShouldDisplay(true);
    }
  }, []);

  const handleDismiss = () => {
    setShouldDisplay(false);
    window.localStorage.setItem(STORAGE_KEY, 'dismissed');
  };

  if (!shouldDisplay) {
    return null;
  }

  return (
    <Container>
      <Content>
        <svg
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 2.49378L17.53 15.5038H2.47L10 2.49378ZM0.739999 14.5038C-0.0300008 15.8338 0.929999 17.5038 2.47 17.5038H17.53C19.07 17.5038 20.03 15.8338 19.26 14.5038L11.73 1.49378C10.96 0.163777 9.04 0.163777 8.27 1.49378L0.739999 14.5038ZM9 7.50378V9.50378C9 10.0538 9.45 10.5038 10 10.5038C10.55 10.5038 11 10.0538 11 9.50378V7.50378C11 6.95378 10.55 6.50378 10 6.50378C9.45 6.50378 9 6.95378 9 7.50378ZM9 12.5038H11V14.5038H9V12.5038Z"
            fill="#F8A72D"
          />
        </svg>

        <p>For now, some functions of PRODUCER are only accessible on the desktop version.</p>
      </Content>

      <Actions>
        <ElevatedButton text="Okay, got it" onClick={handleDismiss} />
      </Actions>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  z-index: 9999;
  bottom: 1.6rem;
  margin: 0 1.6rem;
  width: calc(100% - 3.2rem);
  background: var(--color-grayscale-woodsmoke);
  padding: 1.6rem;
  border-radius: 0.6rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 24px;

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: none;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  margin-bottom: 1.2rem;

  p {
    font-size: 1.4rem;
    line-height: 2rem;
    color: var(--color-grayscale-white);
  }

  svg {
    min-width: 2rem;
    transform: translateY(0.4rem);
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
