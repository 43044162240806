import { computed, makeObservable, observable } from 'mobx';

import { Model } from '../../core/engine/model';
import { Annotation } from '../../features/comments/models/annotation';
import { Author } from '../../features/comments/models/author';
import { ManyToOne, OneToMany } from '../../core/engine/decorators';
import { api } from '../../api';
import { entityPool } from '../../core/engine/engine';
import { Asset } from './asset';
import { catchError } from '../../core/catch-error';
import { authStore } from '../../core/stores/auth-store';
import { getFullName } from '../../lib/utils/get-full-name';
import { getNameInitials } from '../../lib/utils/get-name-initials';

class Like {
  userId?: string;
  timestamp: Date;
}

export class Comment extends Model {
  text: string;

  targetId: string;

  @ManyToOne('comments')
  target: any;

  parentId?: string;

  @ManyToOne('replies')
  parent?: Comment;

  @OneToMany('parent')
  replies: Comment[] = [];

  isAuthor?: boolean;
  authorId: string;
  shotId: string | null;
  timestamp?: number;
  read: boolean;
  completed: boolean;
  annotations?: Annotation[];
  author: Author;
  page?: number;

  constructor() {
    super('comments');

    makeObservable(this, {
      authorName: computed,
      authorInitials: computed,
      completed: observable,
      replies: observable,
    });
  }

  get authorName() {
    if (!this.author) {
      return '';
    }

    return getFullName(this.author);
  }

  get authorInitials() {
    if (!this.author) {
      return '';
    }

    return getNameInitials(this.author);
  }

  async complete(status: boolean) {
    if (status) {
      await api.patch(`/comments/${this._id}/complete`);
      this.completed = true;
    } else {
      await api.patch(`/comments/${this._id}/uncomplete`);
      this.completed = false;
    }

    this.completed = status;
  }

  static async fetchComments(targetName: string, targetId: string): Promise<Comment[]> {
    const { data } = await api.get(`${targetName}/${targetId}/comments`);
    const user = authStore.currentUser;

    data.forEach((el: Comment) => {
      const isAuthor = user?._id === el.authorId;

      el.isAuthor = isAuthor;
      const comment = Object.assign(new Comment(), el);
      entityPool.insert(comment);
    });

    return data;
  }

  async reply(text: string) {
    const assetUrl = location.href;

    const { data } = await api.post(`assets/${this.targetId}/comments`, {
      parentId: this._id,
      text,
      assetUrl,
    });

    const comment = Object.assign(new Comment(), data);
    comment.isAuthor = true;
    entityPool.insert(comment);

    const asset = Asset.getOne(this.targetId);
    if (asset) {
      asset.commentCount += 1;
      asset.lastCommentedAt = new Date().toString();
    }

    return comment;
  }

  async delete() {
    try {
      await api.delete(`/comments/${this._id}`);

      entityPool.delete(this);

      const asset = Asset.getOne(this.targetId);

      if (asset) {
        asset.commentCount = asset.commentCount - 1 - (this.replies?.length || 0);
        this.replies?.forEach((el) => entityPool.delete(el));

        const index = asset.comments.length - 1;
        if (index >= 0) {
          asset.lastCommentedAt = asset.comments[index]?.createdAt.toString();
        } else {
          asset.lastCommentedAt = '';
        }
      }
    } catch (e) {
      catchError(e);
    }
  }

  static getOne(id: string): Comment | undefined {
    return Model.getOne(id) as Comment;
  }

  toPOJO(): Record<string, any> {
    return {};
  }
}
