export const timeDifference = (current: any, previous: any) => {
  const date = new Date(previous);
  const msPerSecond = 1000;
  const msPerMinute = 60 * msPerSecond;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = current.getTime() - date.getTime();

  if (elapsed < msPerMinute) {
    const diff = Math.round(elapsed / msPerSecond);
    return `${diff} second${diff > 1 ? 's' : ''} ago`;
  } else if (elapsed < msPerHour) {
    const diff = Math.round(elapsed / msPerMinute);
    return `${diff} minute${diff > 1 ? 's' : ''} ago`;
  } else if (elapsed < msPerDay) {
    const diff = Math.round(elapsed / msPerHour);
    return `${diff} hour${diff > 1 ? 's' : ''} ago`;
  } else if (elapsed < msPerMonth) {
    const diff = Math.round(elapsed / msPerDay);
    return `${diff} day${diff > 1 ? 's' : ''} ago`;
  } else if (elapsed < msPerYear) {
    const diff = Math.round(elapsed / msPerMonth);
    return `${diff} month${diff > 1 ? 's' : ''} ago`;
  } else {
    const diff = Math.round(elapsed / msPerYear);
    return `${diff > 1 ? 's' : ''} years ago`;
  }
};
