import { format } from 'date-fns';

export const formatDateTime = (date: Date | string) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  return format(date, 'MMM do, h:mmaaa');
};

/**
 * Transform a date in the year-month-day format to a date object
 * @param date
 */
export const dashToDate = (date: string): Date => {
  const [year, month, day] = date.split('-').map((value) => parseInt(value, 10));
  return new Date(year, month - 1, day);
};
