import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { Space } from '../../../app/entities/space';

type SeatsProgressProps = {
  space: Space;
};

export const SeatsProgress: React.FC<SeatsProgressProps> = observer(({ space }) => {
  const current = space?.seats?.current || 1;
  const limit = space?.seats?.limit || 1;

  const percentage = (current / limit) * 100;

  return (
    <PanelContainer>
      <PanelHeader>
        <HeaderText>Seats used</HeaderText>
      </PanelHeader>

      <AmountSpaceContainer>
        <Amount>
          {current} out of {limit} seats used
        </Amount>
      </AmountSpaceContainer>

      <Footer>
        Invite or remove creators <Link to={'../users'}>here</Link>
      </Footer>
    </PanelContainer>
  );
});

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PanelHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3rem;
`;

const HeaderText = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #afb4c0;
`;

const AmountSpaceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

type AmountProps = {
  disable?: boolean;
};

const Amount = styled.h3<AmountProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: ${({ disable }) => (disable ? '#626878' : '#ffffff')};
`;

const Footer = styled.footer`
  margin-top: 4rem;
  color: var(--color-grayscale-manatee);
  font-size: 1.4rem;

  a {
    color: var(--color-primary-crayola);
  }
`;
