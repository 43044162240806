import React from 'react';
import mixpanel from 'mixpanel-browser';

import type { User } from '../../app/entities/user';

type TrackType = typeof mixpanel.track;

type MixpanelContextType = {
  init: () => void;
  track: TrackType;
  identify: (user: User) => void;
};

export const MixpanelContext = React.createContext<MixpanelContextType>({
  init: () => {},
  track: () => {},
  identify: () => {},
});
