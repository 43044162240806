import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { Header } from '../../../app/components/header';
import { ProjectListPlaceholder } from './project-list.placeholder';
import { DesktopCreateProjectModal } from './create-project-modal/desktop-create-project.modal';
import { Plus } from '../../../components/icons';
import { ElevatedButton } from '../../../components/buttons';
import { createProject, fetchSpaceProjects } from '../store/projects.slice';
import { ProjectItem } from '../../../components/project-item/ProjectItem';
import { PageLoader } from '../../../components/page-loader/PageLoader';
import { AssetGrid } from '../../../components/grid';

import { Space } from '../../../app/entities/space';
import { Project } from '../../../app/entities/project';
import { uiStore } from '../../../core/stores/ui-store';
import { FloatingActionButton } from '../../../components/buttons/floating-action-button';
import { LimitReachedModal } from '../../../components/dialogs/limit-reached.modal';
import { GridIcon } from '../../../components/icons/grid-icon';
import { StyledButton } from '../../../components/buttons/styled-buttons';
import { ProjectListItem } from './project-list-item';
import { ListDisplayIcon } from '../../../components/icons/list-display-icon';
import { sortBy } from '../../settings/helpers/sortBy';
import { ArrowDownIcon, ArrowUpIcon } from '@radix-ui/react-icons';
import { ListView, ListViewHeader } from '../../../components/list-view/list-view';
import { authStore } from '../../../core/stores/auth-store';
import { useResponsive } from '../../../hooks/useResponsive';
import { MobileCreateProjectModal } from './create-project-modal/mobile-create-project-modal';

interface ProjectListProps {
  /** A list of projects **/
  space: Space;
}

export const ProjectList: React.FC<ProjectListProps> = observer(({ space }) => {
  const { isDesktop } = useResponsive();
  const currentMember = authStore.getCurrentMember(space._id);
  const userPreferences = currentMember?.preferences;

  const [isLoading, setIsLoading] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState<null | boolean>(false);
  const [showLimitReachedModal, setShowLimitReachedModal] = useState(false);
  const [orderBy, setOrderBy] = useState('');

  useEffect(() => {
    uiStore.pageTitle = {
      label: 'Projects',
    };

    uiStore.showBack = false;
  }, []);

  const handleSubmit = async (project: any) => {
    await createProject({ spaceId: space._id, ...project });
    setIsModalOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);

    fetchSpaceProjects(space._id).then(() => {
      setIsLoading(false);
    });
  }, [space._id]);

  const handleNewProjectClicked = () => {
    if (space.canCreateProject()) {
      setIsModalOpen(true);
    } else {
      setShowLimitReachedModal(true);
    }
  };

  const toggleDisplayLayout = () => {
    currentMember?.toggleProjectsDisplayLayout();
  };

  const order = orderBy[0] === '-' ? 'asc' : 'desc';
  const projects = sortBy(space.projects, !orderBy ? 'createdAt' : orderBy.replace('-', ''), order);

  const handleOrder = (value: string) => {
    const currentOrder = orderBy[0] === '-' ? 'desc' : 'asc';
    const current = orderBy.replace('-', '');

    /**
     * 1. If it's not the same as current ordering, set it to desc
     * 2. If it's the same and it's already desc, set it to asc
     * 3. If it's the same and it's already asc, set it to default
     */

    if (value !== current) {
      setOrderBy(`-${value}`);
    } else if (currentOrder === 'desc') {
      setOrderBy(value);
    } else {
      setOrderBy('');
    }
  };

  return (
    <>
      {showLimitReachedModal && (
        <LimitReachedModal
          type="projects"
          eventName="Add project attempt"
          onCancel={() => setShowLimitReachedModal(false)}
        />
      )}

      <Header title={'Projects'}>
        <LayoutToggleButton key="change-layout-button" onClick={toggleDisplayLayout}>
          <span data-active={userPreferences?.projectsDisplayLayout !== 'list'}>
            <GridIcon />
          </span>
          <span data-active={userPreferences?.projectsDisplayLayout === 'list'}>
            <ListDisplayIcon />
          </span>
        </LayoutToggleButton>
        {space!.flags && space!.flags['project.create'] ? (
          <ElevatedButton
            id="create-project"
            text="Create project"
            icon={<Plus />}
            onClick={handleNewProjectClicked}
          />
        ) : null}
      </Header>

      {isLoading ? (
        <LoadingContainer>
          <PageLoader />
        </LoadingContainer>
      ) : (
        <Container>
          {space.projects.length === 0 ? (
            <ProjectListPlaceholder onCreateProject={() => setIsModalOpen(true)} />
          ) : userPreferences?.projectsDisplayLayout !== 'list' ? (
            <Grid>
              {projects.map((project: Project) => (
                <ProjectItem key={project._id} project={project} />
              ))}
            </Grid>
          ) : (
            <ListView>
              <ListViewHeader>
                <HeaderCell />

                <HeaderCell onClick={() => handleOrder('name')}>
                  Name
                  {orderBy === '-name' && <ArrowDownIcon />}
                  {orderBy === 'name' && <ArrowUpIcon />}
                </HeaderCell>

                <HeaderCell onClick={() => handleOrder('progress')}>
                  Progress
                  {orderBy === '-progress' && <ArrowDownIcon />}
                  {orderBy === 'progress' && <ArrowUpIcon />}
                </HeaderCell>

                <HeaderCell onClick={() => handleOrder('usedStorage')}>
                  Size
                  {orderBy === '-usedStorage' && <ArrowDownIcon />}
                  {orderBy === 'usedStorage' && <ArrowUpIcon />}
                </HeaderCell>

                <HeaderCell onClick={() => handleOrder('lastUpdatedAt')}>
                  Last updated
                  {orderBy === 'lastUpdatedAt' && <ArrowDownIcon />}
                  {orderBy === '-lastUpdatedAt' && <ArrowUpIcon />}
                </HeaderCell>

                <HeaderCell onClick={() => handleOrder('dueDate')}>
                  Due date
                  {orderBy === 'dueDate' && <ArrowDownIcon />}
                  {orderBy === '-dueDate' && <ArrowUpIcon />}
                </HeaderCell>

                <HeaderCell onClick={() => handleOrder('priority')}>
                  Priority
                  {orderBy === '-priority' && <ArrowDownIcon />}
                  {orderBy === 'priority' && <ArrowUpIcon />}
                </HeaderCell>

                {isDesktop && ['owner', 'admin', 'creator'].includes(currentMember?.role || '') && (
                  <HeaderCell>People on project</HeaderCell>
                )}

                {/* the width property is to let this empty cell expand and take all the empty space */}
                <HeaderCell style={{ width: '80%' }} />

                <th />
              </ListViewHeader>

              <tbody>
                {projects.map((project) => (
                  <ProjectListItem key={project._id} project={project} />
                ))}
              </tbody>
            </ListView>
          )}
        </Container>
      )}

      {isModalOpen &&
        (!isDesktop ? (
          <MobileCreateProjectModal
            isOpen={isModalOpen}
            spaceId={space._id}
            onSubmit={handleSubmit}
            onCancel={() => setIsModalOpen(false)}
          />
        ) : (
          <DesktopCreateProjectModal
            spaceId={space._id}
            onCancel={() => setIsModalOpen(false)}
            onSubmit={handleSubmit}
          />
        ))}

      <FloatingActionButton onClick={handleNewProjectClicked} />
    </>
  );
});

const Container = styled.div`
  padding-left: 1.6rem;
  padding-top: 1.6rem;
  padding-right: 1.6rem;
  overflow-y: auto;
`;

const LoadingContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Grid = styled(AssetGrid)`
  width: 100%;
`;

const LayoutToggleButton = styled(StyledButton)`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 3.2rem;
  width: 6.4rem;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 100%;
    width: 3.2rem;
    transition: background-color 0.3s ease;

    svg {
      width: 1.6rem;
      height: 1.6rem;
    }

    &[data-active='true'] {
      background-color: var(--color-primary-crayola);

      svg {
        path {
          fill: white;
        }
      }
    }
  }
`;

const HeaderCell = styled.th`
  position: relative;
  user-select: none;
  vertical-align: middle;

  &:hover {
    cursor: pointer;
    color: var(--color-grayscale-white);
  }

  svg {
    position: absolute;
    transform: translate(4px, 1px);
    color: var(--color-primary-crayola);
  }
`;
