import { Address } from '../../lib/utils/address';

export const formatAddress = (address: Address) => {
  const isNotEmptyAddress = Object.values(address).some((item) => item && item.length > 0);
  const addressParts = [];

  address.line1 && addressParts.push(address.line1);
  address.line2 && addressParts.push(address.line2);
  (address.postalCode || address.city) &&
    addressParts.push(`${address.postalCode} ${address.city}`);
  address.state && addressParts.push(address.state);
  address.country && addressParts.push(address.country);

  if (isNotEmptyAddress) {
    return addressParts.join(', ');
  }
  return;
};
