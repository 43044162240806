import React from 'react';
import styled from 'styled-components';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';

import { Search } from '../../../components/icons';
import { TextInput } from '../../../components/text-input/TextInput';

type ContactSearchBarProps = {
  value: string;
  onChange: (value: string) => void;
};

export const ContactSearchBar: React.FC<ContactSearchBarProps> = ({ value, onChange }) => (
  <CustomTextInput
    value={value}
    icon={<MagnifyingGlassIcon />}
    placeholder={'Search'}
    onChange={(e) => onChange(e.target.value)}
  />
);

const CustomTextInput = styled(TextInput)`
  & svg {
    color: var(--color-grey-4);
    width: 1.8rem;
    height: 1.8rem;
  }
`;
