import React from 'react';
import styled from 'styled-components';

export const PageUnauthorized = () => (
  <Container>
    <img src="/images/crime-outline.svg" alt="unauthorized-img" />
    <Title>No access to this project</Title>
    <Description>You do not have access to this document</Description>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: var(--color-grey-8-5);
  border-radius: var(--border-radius-medium);
`;

const Title = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.42rem;
  color: var(--white-default);
  text-align: center;
  margin-top: 6.4rem;
`;

const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: var(--color-grey-3);
  text-align: center;
  margin-top: 1.6rem;
`;
