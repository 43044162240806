import React from 'react';
import styled from 'styled-components';

type DialogHeaderProps = {
  title: string | React.ReactNode;
  onClose?: () => void; //todo: responsive - remove all references
  light?: boolean;
};

export const DialogHeader: React.FC<DialogHeaderProps> = ({ title, light }) => (
  <Header data-light={light}>
    <Title>{title}</Title>
  </Header>
);

const Header = styled.header`
  display: flex;
  flex-direction: row;
  border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;
  box-sizing: border-box;
  border-bottom: 0.1rem solid var(--color-grayscale-trout);

  &[data-light] {
    background-color: var(--color-grayscale-eerie-black);
    border-bottom: none;

    & > h1 {
      font-size: 1.6rem;
    }
  }
`;

const Title = styled.h1`
  color: var(--color-grayscale-white);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.4rem;
  min-height: 2.4rem;
  margin-inline-end: 2.4rem; //placeholder for absolute radix close button
`;
