export enum CameraAngleEnum {
  aerial = 'aerial',
  eyeLevel = 'eye_level',
  lowAngle = 'low_angle',
  highAngle = 'high_angle',
  overhead = 'overhead',
  shoulderLevel = 'shoulder_level',
  hipLevel = 'hip_level',
  kneeLevel = 'knee_level',
  groundLevel = 'ground_level',
}
