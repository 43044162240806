import React from 'react';

export const CrossCircledFilledIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 0.5C8.3225 0.5 0.5 8.3225 0.5 18C0.5 27.6775 8.3225 35.5 18 35.5C27.6775 35.5 35.5 27.6775 35.5 18C35.5 8.3225 27.6775 0.5 18 0.5ZM25.525 25.525C24.8425 26.2075 23.74 26.2075 23.0575 25.525L18 20.4675L12.9425 25.525C12.26 26.2075 11.1575 26.2075 10.475 25.525C9.7925 24.8425 9.7925 23.74 10.475 23.0575L15.5325 18L10.475 12.9425C9.7925 12.26 9.7925 11.1575 10.475 10.475C11.1575 9.7925 12.26 9.7925 12.9425 10.475L18 15.5325L23.0575 10.475C23.74 9.7925 24.8425 9.7925 25.525 10.475C26.2075 11.1575 26.2075 12.26 25.525 12.9425L20.4675 18L25.525 23.0575C26.19 23.7225 26.19 24.8425 25.525 25.525Z"
      fill="currentColor"
    />
  </svg>
);
