import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { sortBy } from '../../settings/helpers/sortBy';
import { SettingsTable } from '../../../components/settings-table/settings-table';
import { InvitationItem } from './invitation-item';
import { fetchInvitations } from '../store/guest-invitation.slice';
import { PagePlaceholder } from '../../../components/page-placeholder/page-placeholder';
import { LoaderContainer } from '../../../routes/project/components/step-container';
import { PageLoader } from 'components/page-loader/PageLoader';

import type { Space } from '../../../app/entities/space';

type InvitationsTabProps = {
  space: Space;
};

const tableHeaders = ['Email', 'Role', 'Invited at'];

export const InvitationsTab: React.FC<InvitationsTabProps> = observer(({ space }) => {
  const [isLoading, setIsLoading] = React.useState(true);

  const sortedByLatest = useMemo(
    () => sortBy(space.invitations, 'invitedAt', 'desc'),
    [space.invitations],
  );

  const hasInvitations = !!sortedByLatest?.length;

  useEffect(() => {
    fetchInvitations(space._id)
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [space._id]);

  return (
    <SettingsTable headers={tableHeaders} isFullHeight={isLoading || !hasInvitations}>
      {isLoading ? (
        <LoaderContainer>
          <PageLoader />
        </LoaderContainer>
      ) : hasInvitations ? (
        sortedByLatest?.map((invitation) => (
          <InvitationItem key={invitation?._id} invitation={invitation} />
        ))
      ) : (
        <PagePlaceholder description="Invitations will be displayed here" />
      )}
    </SettingsTable>
  );
});
