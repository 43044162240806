import React from 'react';
import styled from 'styled-components';

import { EditorContent } from '@tiptap/react';

import { CollapsibleItem } from '../../../components/collapsible-item/collapsible-item';

import type { Step } from '../../../app/entities/step';
import { useRichTextEditor } from '../../../hooks/useRichTextEditor';
import { EditorBubbleMenu } from '../../../components/rich-editor/editor-bubble-menu';

type ProductionNotesProps = {
  step: Step;
};

export const ProductionNotes: React.FC<ProductionNotesProps> = ({ step }) => {
  const { editor, isTextSelected } = useRichTextEditor({
    key: step._id,
    value: step.productionNotes || '',
    onUpdate: (text) => {
      step.update({ productionNotes: text });
    },
  });

  return (
    <>
      <CollapsibleItem title="Shooting Day Notes" defaultOpen>
        {editor && isTextSelected && <EditorBubbleMenu editor={editor} />}
        {editor && <NotesInput editor={editor} />}
      </CollapsibleItem>
    </>
  );
};

const NotesInput = styled(EditorContent)`
  width: 100%;
  height: 50vh;
  border-radius: 0.4rem;
  padding: 1.6rem;
  line-height: 1.8rem;
  color: var(--color-grayscale-white);
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 1.6rem;
  border: 0.1rem solid var(--color-grayscale-trout);
  position: relative;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
  letter-spacing: 0.3px;
  resize: none;
  background-color: transparent;

  &::placeholder {
    color: var(--color-grayscale-shuttle);
  }

  &:focus {
    background-color: transparent !important;
    border-color: var(--color-secondary);
  }

  &:hover {
    background-color: #ffffff11;
    cursor: text;
  }

  &[data-error='true'] {
    &:focus {
      border-color: var(--color-error);
    }
  }
`;
