import React, { useState, useRef } from 'react';
import { Cropper, FixedCropperRef } from 'react-advanced-cropper';
import styled from 'styled-components';

import { BaseDialog, DialogHeader } from '../dialog';
import { ElevatedButton, IconButton, TextButton } from '../buttons';
import { Tooltip } from '../tooltip/Tooltip';
import { Crop as CropIcon } from 'components/icons/crop';
import { RotateLeft } from 'components/icons/rotate-left';
import { RotateRight } from 'components/icons/rotate-right';
import { PhotoUploader } from '../photo-uploader/photo-uploader';
import { imagePreview } from './utils/image-preview';

import 'react-advanced-cropper/dist/style.css';
import { breakPoint } from '../../app/theme';

type PhotoCropperModalProps = {
  onCancel: () => void;
  onSave: (file: File) => void;
  imgFile?: File;
  aspectRatio?:
    | number
    | {
        minimum: number;
        maximum: number;
      };
  theme?: 'light' | 'dark';
};

export const PhotoCropperModal: React.FC<PhotoCropperModalProps> = ({
  onCancel,
  onSave,
  aspectRatio = 16 / 9,
  imgFile,
  theme = 'dark',
}) => {
  const [imgSrc, setImgSrc] = useState(imgFile ? URL.createObjectURL(imgFile) : '');
  const cropperRef = useRef<FixedCropperRef>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const originalFileRef = useRef<File | undefined>(imgFile);
  const originalFile = originalFileRef.current;

  const onSelectFile = (file: File) => {
    if (file) {
      originalFileRef.current = file;
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
      reader.readAsDataURL(file);
    }
  };

  const rotate = (angle: number) => {
    if (cropperRef.current) {
      cropperRef.current.rotateImage(angle);
    }
  };

  const handleOnSave = async () => {
    if (isLoading) {
      return;
    }

    if (!cropperRef.current) {
      return;
    }

    setIsLoading(true);

    const { blob } = await imagePreview(cropperRef.current.getCanvas()!);

    const file = new File([blob!], originalFile!.name, { type: originalFile!.type });

    onSave(file);
    setIsLoading(false);
    onCancel();
  };

  return (
    <BaseDialog onClose={onCancel} theme="dark">
      <DialogHeader title="Edit Image" />

      <Container>
        {!imgSrc ? (
          <UploaderContainer>
            <PhotoUploader onChange={onSelectFile} />
          </UploaderContainer>
        ) : (
          <Cropper
            ref={cropperRef}
            src={imgSrc}
            {...(theme === 'light' && { boundaryClassName: 'cropper-light-theme--boundary' })}
            stencilProps={{
              handlers: true,
              lines: true,
              movable: true,
              resizable: true,
              aspectRatio,
              ...(theme === 'light' && {
                overlayClassName: 'cropper-light-theme--overlay',
                handlerClassNames: {
                  default: 'cropper-light-theme--handler',
                },
                lineClassNames: {
                  default: 'cropper-light-theme--stencil-line',
                },
              }),
            }}
          />
        )}
      </Container>

      <Footer>
        <TextButton key="cancel" text="Cancel" onClick={onCancel} />

        <OptionsWrapper>
          <Tooltip text={'Crop'} position="top" disabled={!imgSrc}>
            <IconButtonWrapper data-active={!!imgSrc}>
              <IconButton
                variant="transparent"
                onClick={() => {}}
                icon={<CropIcon />}
                disabled={!imgSrc}
              />
            </IconButtonWrapper>
          </Tooltip>

          <Tooltip text={'Turn Left'} position="top" disabled={!imgSrc}>
            <IconButtonWrapper>
              <IconButton
                variant="transparent"
                onClick={() => {
                  rotate(-90);
                }}
                icon={<RotateLeft />}
                disabled={!imgSrc}
              />
            </IconButtonWrapper>
          </Tooltip>

          <Tooltip text={'Turn Right'} position="top" disabled={!imgSrc}>
            <IconButtonWrapper>
              <IconButton
                variant="transparent"
                onClick={() => {
                  rotate(90);
                }}
                icon={<RotateRight />}
                disabled={!imgSrc}
              />
            </IconButtonWrapper>
          </Tooltip>
        </OptionsWrapper>

        <ElevatedButton text={'Save'} key={'save'} onClick={handleOnSave} isLoading={isLoading} />
      </Footer>
    </BaseDialog>
  );
};

const Container = styled.div`
  min-width: 0;
  width: 90vw;
  max-width: 72rem;
  aspect-ratio: 16 / 9;
  padding: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.1rem solid var(--color-grey-7);

  @media screen and (min-width: ${breakPoint.small}px) {
    padding: 2.4rem 4rem;
  }
`;

const UploaderContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const Footer = styled.footer`
  background: var(--header-bg-color-active);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;
  border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
`;

const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 20rem;
`;

const IconButtonWrapper = styled.div`
  & > div,
  button {
    background-color: transparent;

    & svg {
      stroke: transparent;
      fill: var(--color-grey-5);
    }

    &:disabled {
      pointer-events: none;
      opacity: 60%;
    }

    &:hover {
      background-color: transparent;
      & svg {
        stroke: transparent;
        fill: var(--white-default);
      }

      &[data-active='false'] {
        & svg {
          fill: var(--white-default);
        }
      }

      &[data-active='true'] {
        & svg {
          fill: var(--color-secondary);
        }
      }
    }
  }

  &[data-active='true'] {
    & svg {
      stroke: transparent;
      fill: var(--color-secondary);
    }
  }
`;
