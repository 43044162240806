import React, { useContext, useCallback, useMemo, useLayoutEffect } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { useIntersectionObserver } from 'usehooks-ts';
import styled from 'styled-components';

import { GanttChartContext } from '../context/gantt-chart.context';
import { getScrollableParent, onIntersectionChange } from '../utils/utils';

import type { ChartItem } from '../types';

export interface StripProps {
  index: number;
  item: ChartItem;
  style?: React.CSSProperties;
}

export const DraggableStrip: React.FC<StripProps> = ({ item, style: inlineStyle, index }) => {
  const {
    setCurrentStrip,
    setCurrentItem,
    setResizingSide,
    setResizeStartOffset,
    resizingLayerRef,
    stripIndicatorsListRef,
  } = useContext(GanttChartContext);

  const { setNodeRef, listeners, attributes, transform, isDragging, node } = useDraggable({
    id: `${item.id}_draggable_strip`,
    data: {
      item,
    },
  });

  const scrollableParent = getScrollableParent(node?.current);

  const handleIntersectionChange = (isIntersecting: boolean, entry: IntersectionObserverEntry) => {
    const stripIndicatorElement = stripIndicatorsListRef?.current?.[index];

    onIntersectionChange({
      isIntersecting,
      entry,
      scrollableParent,
      stripIndicatorElement,
    });
  };

  const { ref: setIntersectionRef } = useIntersectionObserver({
    root: scrollableParent,
    rootMargin: '0px 0px 0px -248px',
    onChange: handleIntersectionChange,
  });

  const refCallback = useCallback(
    (node: HTMLDivElement | null) => {
      setNodeRef(node);

      setIntersectionRef?.(node);

      if (isDragging) {
        setCurrentStrip?.(node);
      }
    },
    [isDragging, setCurrentStrip, setIntersectionRef, setNodeRef],
  );

  const style = {
    ...inlineStyle,
    ...(transform && {
      transform: `translateX(${transform?.x ?? 0}px) translateY(-50%)`,
    }),
    cursor: 'grab',
  };

  const mouseDownHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const rect = (event.target as HTMLDivElement).getBoundingClientRect();

    const leftEdgeDelta = event.clientX - rect.left;
    if (leftEdgeDelta < 4 && leftEdgeDelta > -4) {
      event.stopPropagation();

      setResizeStartOffset?.(event.clientX);
      setCurrentStrip?.(node.current as HTMLDivElement);
      setCurrentItem?.(item);
      setResizingSide?.('left');

      if (!resizingLayerRef?.current) {
        return;
      }
      resizingLayerRef.current.style.pointerEvents = 'auto';
    }

    const rightEdgeDelta = rect.right - event.clientX;
    if (rightEdgeDelta < 4 && rightEdgeDelta > -4) {
      event.stopPropagation();

      setResizeStartOffset?.(event.clientX);
      setCurrentStrip?.(node.current as HTMLDivElement);
      setCurrentItem?.(item);
      setResizingSide?.('right');

      if (!resizingLayerRef?.current) {
        return;
      }
      resizingLayerRef.current.style.pointerEvents = 'auto';
    }
  };

  return (
    <Container ref={refCallback} style={style} {...attributes} {...listeners}>
      <Strip
        onMouseDown={mouseDownHandler}
        data-fade-start={!item.startDate}
        data-fade-end={!item.dueDate}
        data-done={item.isDone}
      >
        <Point data-side="left" onMouseDown={mouseDownHandler} />

        <StripTitle id="strip-title" data-shift-name={item.durationInDays || 0 <= 1}>
          {item.name}
        </StripTitle>

        <Point data-side="right" onMouseDown={mouseDownHandler} />
      </Strip>
    </Container>
  );
};

DraggableStrip.displayName = 'GanttDraggableItem';

const Container = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: width 0.3s, left 0.3s;
  user-select: none;
  width: 100%;
  z-index: 4;
`;

const Point = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: var(--color-grayscale-light-slate, #7a8296);
  display: none;

  &[data-side='left'] {
    left: -0.4rem;
  }

  &[data-side='right'] {
    right: -0.4rem;
  }
`;

const StripTitle = styled.p`
  user-select: none;
  color: var(--color-texts-high-contrast);
  transform: translateX(0);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /* Body 2 */
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.8rem;

  &[data-shift-name='true'] {
    transform: translateX(4.8rem);
    white-space: noWrap;
    overflow: visible;
  }
`;

const Strip = styled.div`
  display: flex;
  padding: 0.4rem 0.8rem;
  justify-content: space-between;
  align-items: center;
  cursor: grab;
  border-radius: var(--border-radius-small);
  height: 3.2rem;
  box-shadow: 0px 10px 15px -3px #0000001a;
  position: relative;
  background: var(--color-surfaces-bg-elevation-4);
  border: 0.05rem solid var(--color-surfaces-bg-elevation-5);

  &[data-done='true'] {
    background: var(--color-surfaces-bg-elevation-3);
    /* border: 0.05rem solid var(--color-surfaces-bg-elevation-4); */
    border-color: var(--color-surfaces-bg-elevation-4);

    & ${StripTitle} {
      color: var(--color-texts-middle-contrast);
    }
  }

  &[data-fade-start='true'] {
    background: linear-gradient(
      90deg,
      rgba(73, 78, 90, 0) 17%,
      rgba(73, 78, 90, 0.34) 60.5%,
      #494e5a 89%
    );

    border: 0.5px solid;

    border-image-source: linear-gradient(90deg, rgba(98, 104, 120, 0) 50%, #626878 100%);
  }

  &[data-fade-end='true'] {
    background: linear-gradient(
      90deg,
      #494e5a 11%,
      rgba(73, 78, 90, 0.62) 49%,
      rgba(49, 52, 60, 0) 83%
    );
    border: 0.5px solid;
    border-image-source: linear-gradient(270deg, rgba(98, 104, 120, 0) 50%, #626878 100%);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0.4rem;
    background-color: transparent;
    cursor: ew-resize;
    z-index: 5;
  }

  &::before {
    left: -0.2rem;
  }

  &::after {
    right: -0.2rem;
  }

  &:hover {
    border: 0.1rem solid var(--color-texts-medium-contrast);

    & > ${Point} {
      display: block;
    }
  }
`;
