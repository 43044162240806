import React from 'react';

export const Settings = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.98856 8C10.6454 8 11.9886 6.65685 11.9886 5C11.9886 4.90347 11.984 4.808 11.9751 4.7138C12.6241 4.57375 13.2977 4.5 13.9886 4.5C14.7141 4.5 15.4207 4.58135 16.0997 4.73541C16.0921 4.82259 16.0882 4.91084 16.0882 5C16.0882 6.65685 17.4313 8 19.0882 8C19.7462 8 20.3548 7.78813 20.8494 7.42888C21.9934 8.623 22.8287 10.1152 23.2271 11.7773C22.2005 12.2537 21.4886 13.2937 21.4886 14.5C21.4886 15.6046 22.0856 16.5698 22.9745 17.0905C22.5226 18.4048 21.7904 19.5888 20.8494 20.5711C20.3548 20.2119 19.7462 20 19.0882 20C17.4313 20 16.0882 21.3431 16.0882 23C16.0882 23.0892 16.0921 23.1774 16.0997 23.2646C15.4207 23.4187 14.7141 23.5 13.9886 23.5C13.2977 23.5 12.6241 23.4263 11.9751 23.2862C11.984 23.192 11.9886 23.0965 11.9886 23C11.9886 21.3431 10.6454 20 8.98856 20C8.30456 20 7.67402 20.2289 7.16938 20.6143C6.20828 19.6236 5.46115 18.4242 5.00257 17.0905C5.89154 16.5698 6.48856 15.6046 6.48856 14.5C6.48856 13.2937 5.77662 12.2537 4.75 11.7773C5.15325 10.0951 6.00401 8.58694 7.16938 7.3857C7.67402 7.77109 8.30456 8 8.98856 8Z"
      fill="currentColor"
    />
    <circle cx="13.9883" cy="14" r="3.5" fill="fill" />
  </svg>
);
