import React from 'react';
import { useParams } from 'react-router-dom';

import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb';
import { Asset } from '../../../app/entities/asset';

import type { Step } from '../../../app/entities/step';

type CommonFileBreadcrumbType = {
  projectId: string;
  step: Step;
  assetId: string;
};

export const CommonFileBreadcrumb: React.FC<CommonFileBreadcrumbType> = ({
  projectId,
  step,
  assetId,
}) => {
  const { spaceId } = useParams() as { spaceId: string };

  const currentAsset = Asset.getOne(assetId);

  const commonFileBreadcrumb = [
    {
      label: step.name,
      href: `/space/${spaceId}/projects/${projectId}/steps/${step._id}`,
    },
    {
      label: `${currentAsset?.name}`,
      href: `/space/${spaceId}/projects/${projectId}/steps/${step._id}/file/${assetId}`,
    },
  ];

  return <Breadcrumb breadcrumbs={commonFileBreadcrumb} />;
};
