import { makeObservable, observable } from 'mobx';

import { Model } from '../../core/engine/model';
import { Project } from './project';
import { ManyToOne } from '../../core/engine/decorators';
import { api } from '../../api';
import { TeamContact } from '../../features/teams/models/team';
import { Contact } from './contact';

export class Team extends Model {
  projectId: string;

  name: string;

  contacts: TeamContact[] = [];

  departments: any[] = [];

  @ManyToOne('teams')
  project: Project;

  constructor() {
    super('teams');

    makeObservable<Team>(this, {
      name: observable,
      departments: observable,
      contacts: observable,
      project: observable,
    });
  }

  async addDepartment(name: string) {
    const { data } = await api.post(`/teams/${this._id}/departments`, {
      name,
    });

    this.departments.push(data);
  }

  /** TODO Create a local collection class to encapsulate delete logic**/
  async deleteDepartment(departmentId: string) {
    await api.delete(`/teams/${this._id}/departments/${departmentId}`);

    this.departments = this.departments.filter((department) => department._id !== departmentId);
    this.contacts = this.contacts.filter((contact) => contact.departmentId !== departmentId);
  }

  /**
   * TODO Create a local collection class to encapsulate update logic
   * Avoid the forEach
   * **/
  async updateDepartment(departmentId: string, update: { name: string }) {
    await api.patch(`/teams/${this._id}/departments/${departmentId}`, update);
    this.departments.forEach((department) => {
      if (department._id !== departmentId) return;

      department.name = update.name;
    });
  }

  async addContact({
    contactId,
    role,
    departmentId,
  }: {
    contactId: string;
    role: string;
    departmentId?: string;
  }) {
    const { data } = await api.post(`/teams/${this._id}/contacts`, {
      contactId,
      ...(!!departmentId && { departmentId }),
      role,
    });

    const contact = Contact.getOne(contactId)!;
    this.contacts.push({
      ...data,
      contact,
    });
  }

  async deleteContact(contactId: string) {
    await api.delete(`/teams/${this._id}/contacts/${contactId}`);
    this.contacts = this.contacts.filter((contact) => contact._id !== contactId);
  }

  async updateContact(contactId: string, update: any) {
    await api.patch(`/teams/${this._id}/contacts/${contactId}`, update);
    this.contacts.forEach((contact) => {
      if (contact._id !== contactId) return;
      Object.assign(contact, update);
    });
  }

  toPOJO(): Record<string, any> {
    return {};
  }

  static getOne(id: string): Team | undefined {
    return Model.getOne(id) as Team;
  }
}
