import { api } from '../../api';
import { entityPool } from '../../core/engine/engine';
import { Stage } from '../../app/entities/stage';

/**
 * Get all steps of a project
 */
export const fetchStagesByProject = async (projectId: string) => {
  const { data } = await api.get<Stage[]>(`/projects/${projectId}/stages`);

  data.forEach((s: Stage) => {
    entityPool.insert(Object.assign(new Stage(), s));
  });

  return data;
};
