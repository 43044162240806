import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { timeTo12HourFormat } from '../../../lib/utils/Convertor';
import { Step } from '../../../app/entities/step';

import { DayStartStripProps } from './day-start-strip';
import { InlineTimeSelector } from './InlineTimeSelector';
import { formatDate } from '../../../lib/utils/DateHelper';
import { dateShort } from '../../../assets/contants/dates-fns';

export const MobileDayStartStrip: React.FC<DayStartStripProps> = observer(({ strip }) => {
  const { dod, stepId } = strip.data || {};

  const shootingDay = Step.getOne(stepId)!;
  if (!shootingDay) return null;

  const formattedDate = shootingDay?.dueDate ? formatDate(shootingDay.dueDate, dateShort) : '';

  return (
    <Container>
      <DayName>
        (#{dod}) {shootingDay.name}
      </DayName>

      {shootingDay.info?.generalCallTime && (
        <TimeWrapper>
          <InlineTimeSelector
            backgroundVisible
            time={timeTo12HourFormat(shootingDay.info.generalCallTime)}
            style={{ margin: '0' }}
            onChange={(time) => {
              shootingDay.update({
                info: {
                  generalCallTime: {
                    hour: time.hour,
                    minute: time.minute,
                  },
                },
              });
            }}
          />
        </TimeWrapper>
      )}

      <DateContainer>{formattedDate}</DateContainer>
    </Container>
  );
});

const DayName = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--color-grayscale-white, #fafafa);
  line-height: 2.4rem;
  min-width: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem;
  border-radius: 0.4rem;
  background-color: var(--color-primary-sandal);
`;

const DateContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: var(--color-grayscale-ghost);
`;

const TimeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
