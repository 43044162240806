import { useState, useRef, useEffect, useCallback } from 'react';

export const useBoundingRect = (isElementInDom: boolean) => {
  const ref = useRef<any>(null);
  const [boundingRect, setBoundingRect] = useState<DOMRect | undefined>();

  const set = useCallback(() => {
    return setBoundingRect(ref?.current ? ref.current.getBoundingClientRect() : undefined);
  }, []);

  useEffect(() => {
    if (ref?.current && isElementInDom) {
      set();
    }
  }, [isElementInDom, set]);

  return { boundingRect, ref };
};
