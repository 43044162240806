import { getVideoHeightAndWidth } from './get-video-dimensions';

export type Frame = {
  data: any;
  size: number;
  type: string;
};

const IMAGE_TYPE = 'image/jpeg';
const TAKE_THUMBNAIL_AT_SEC = 2;
const THUMBNAIL_QUALITY = 0.5;

export const getFrame = (file: File): Promise<Frame> => {
  return new Promise(async (resolve, reject) => {
    const url = URL.createObjectURL(file);
    const { videoHeight, videoWidth } = await getVideoHeightAndWidth(url);

    const canvas = document.createElement('canvas');
    canvas.width = videoWidth;
    canvas.height = videoHeight;

    const context = canvas.getContext('2d');
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.src = url;
    video.muted = true;
    video.currentTime = TAKE_THUMBNAIL_AT_SEC;

    const update = () => {
      if (!context) {
        return reject('Failed to fetch context.');
      }

      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const canvasUrl = canvas.toDataURL(IMAGE_TYPE, THUMBNAIL_QUALITY);
      const blob = dataURItoBlob(canvasUrl);

      return resolve({
        data: blob,
        type: IMAGE_TYPE,
        size: blob.size,
      });
    };

    video.addEventListener('error', () => {
      reject();
    });

    video.addEventListener('canplaythrough', async () => {
      await new Promise((resolve) => {
        setTimeout(resolve, 100);
      });
      const promise = video.play();
      if (promise !== undefined) {
        promise
          .catch((e) => {
            reject(e);
          })
          .then(() => {
            video.currentTime = TAKE_THUMBNAIL_AT_SEC;
            video.pause();
            update();
          });
      }
    });
  });
};

export const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result as string);
    };

    reader.onerror = reject;

    reader.readAsDataURL(blob);
  });
};
