import loginEN from './login.json';
import signup from './signup.json';
import onboardingEN from './onboarding.json';
import projectListEN from './projectList.json';
import projectEN from './project.json';
import selectEN from './select.json';
import profileEN from './profile.json';
import talentCardEN from './contacts/talentCard.json';
import moodboardEN from './moodboard.json';
import gearEN from './gear.json';
import shot from './shot.json';
import file from './file.json';
import location from './location.json';
import shootingDay from './shootingDay.json';
import rush from './rush.json';
import team from './team.json';
import folder from './folder.json';
import callsheet from './callsheet.json';
import invitation from './invitation.json';
import common from './common.json';
import createProject from './createProject.json';
import deleteProject from './deleteProject.json';
import video from './video.json';
import shareProject from './shareProject.json';
import contactsList from './contacts/contactsList.json';
import forgotPassword from './forgotPassword.json';
import resetPassword from './resetPassword.json';
import contactsPage from './contacts/contactsPage.json';
import contactModal from './contacts/contactModal.json';
import cast from './cast.json';
import gender from './gender.json';
import step from './step.json';
import assetItem from './assetItem.json';
import commentItem from './commentItem.json';
import storyboard from './storyboard.json';
import askMoreStorage from './askMoreStorage.json';
import screenHeader from './screenHeader.json';

export const en = {
  login: loginEN,
  signup: signup,
  onboarding: onboardingEN,
  projectList: projectListEN,
  select: selectEN,
  project: projectEN,
  profile: profileEN,
  talentCard: talentCardEN,
  moodboard: moodboardEN,
  gear: gearEN,
  shot: shot,
  file: file,
  location: location,
  shootingDay: shootingDay,
  rush: rush,
  team: team,
  folder: folder,
  callsheet: callsheet,
  invitation: invitation,
  common: common,
  screenHeader: screenHeader,
  createProject: createProject,
  deleteProject: deleteProject,
  video: video,
  shareProject: shareProject,
  talentsList: contactsList,
  forgotPassword: forgotPassword,
  resetPassword: resetPassword,
  talentsPage: contactsPage,
  talentModal: contactModal,
  cast: cast,
  gender: gender,
  step: step,
  assetItem: assetItem,
  commentItem: commentItem,
  storyboard: storyboard,
  askMoreStorage: askMoreStorage,
};
