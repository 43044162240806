import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import * as yup from 'yup';

import { EditableText } from '../../../components/editable-text/EditableText';
import { updateCharacterCover } from '../character.slice';
import { InlineDropDown } from '../../../components/dropdowns/InlineDropDown';
import { formatPicture } from '../../../core/services/image.service';
import { ProfileAvatar } from '../../../components/profile-avatar/ProfileAvatar';
import { CandidateCard } from './candidate-card';
import { InlineTextArea } from '../../../components/editable-text/inline-text-area';

import { Character } from '../../../app/entities/character';
import { Candidate } from '../../../app/entities/candidate';

export type CharacterInfoTabProps = {
  characterId: string;
};

type FormValue = Pick<
  Character,
  'name' | 'description' | 'personality' | 'characteristics' | 'appearance' | 'gender'
>;

const options = [
  { value: 'man', label: 'Man' },
  { value: 'woman', label: 'Woman' },
  { value: 'other', label: 'Other' },
];

export const CharacterInfoTab: React.FC<CharacterInfoTabProps> = observer(({ characterId }) => {
  const character = Character.getOne(characterId)!;

  const schema = yup.object().shape({
    name: yup
      .string()
      .matches(/^(.*)?\S+(.*)?$/, 'Name is required')
      .required('Name is required')
      .max(16, 'Max 16 characters'),
    description: yup.string().max(1024, 'Max 1024 characters'),
    personality: yup.string().max(1024, 'Max 1024 characters'),
    characteristics: yup.string().max(1024, 'Max 1024 characters'),
    appearance: yup.string().max(1024, 'Max 1024 characters'),
    gender: yup.string().oneOf(['man', 'woman', 'other'], 'Invalid gender'),
  });

  const {
    register,
    formState: { errors },
  } = useForm<FormValue>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: character.name,
      description: character.description,
      personality: character.personality,
      characteristics: character.characteristics,
      appearance: character.appearance,
      gender: character.gender,
    },
  });

  const handleEdit = async (data: Partial<Character>) => {
    await character.update(data);
  };

  const handleUploadPhoto = async (file?: File) => {
    if (!file) {
      return;
    }

    const options = { width: 340, height: 340 };
    const frame = await formatPicture(file, options);
    await updateCharacterCover({ characterId: character._id, file: frame });
  };

  const selectedCandidate =
    !!character.cast?.candidateId && Candidate.getOne(character.cast.candidateId);

  return (
    <Container>
      <SideInfo>
        <UploaderContainer>
          <ProfileAvatar
            placeholder="text"
            defaultUrl={character?.cover?.src}
            onChange={handleUploadPhoto}
          />
        </UploaderContainer>
        <EditableText onSubmit={(name) => handleEdit({ name })}>
          <CharacterName>{character.name}</CharacterName>
        </EditableText>
        <InfoLabel>Character</InfoLabel>
        <Line />

        {selectedCandidate && <CandidateCard candidate={selectedCandidate} isSelectedCandidate />}
      </SideInfo>
      <MainInfo>
        <CharacterInfoTitle>Character Information</CharacterInfoTitle>

        <CharacterInfoContainer>
          <Row>
            <FieldContainer>
              <InfoLabel>Gender</InfoLabel>
              <InlineDropDown
                value={character.gender || 'man'}
                label={character.gender || 'Man'}
                onChange={(gender) => handleEdit({ gender })}
                options={options}
              />
            </FieldContainer>
          </Row>

          <Row>
            <FieldContainer>
              <InfoLabel>Description</InfoLabel>
              <InlineTextArea
                {...register('description')}
                placeholder="Description"
                onSubmit={(e) => {
                  handleEdit({ description: e.target.value });
                }}
                errorMessage={errors.description?.message}
              />
            </FieldContainer>

            <FieldContainer>
              <InfoLabel>Physical Characteristics</InfoLabel>
              <InlineTextArea
                {...register('characteristics')}
                placeholder="Characteristics"
                onSubmit={(e) => {
                  handleEdit({ characteristics: e.target.value });
                }}
                errorMessage={errors.characteristics?.message}
              />
            </FieldContainer>
          </Row>

          <Row>
            <FieldContainer>
              <InfoLabel>Personality</InfoLabel>
              <InlineTextArea
                {...register('personality')}
                placeholder="Personality"
                onSubmit={(e) => {
                  handleEdit({ personality: e.target.value });
                }}
                errorMessage={errors.personality?.message}
              />
            </FieldContainer>

            <FieldContainer>
              <InfoLabel>Appearance</InfoLabel>
              <InlineTextArea
                {...register('appearance')}
                placeholder="Appearance"
                onSubmit={(e) => {
                  handleEdit({ appearance: e.target.value });
                }}
                errorMessage={errors.appearance?.message}
              />
            </FieldContainer>
          </Row>
        </CharacterInfoContainer>
      </MainInfo>
    </Container>
  );
});

const CharacterInfoContainer = styled.div`
  max-width: 96rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  gap: 1.6rem;
  padding-top: 1.6rem;
`;

const SideInfo = styled.div`
  border-right: 1px solid var(--color-grey-7);
  padding-right: 1.6rem;
  flex: 1 1 0;
  min-width: 33.6rem;
`;

const MainInfo = styled.div`
  flex: 2 0 auto;
`;

const UploaderContainer = styled.div`
  width: 32rem;
  height: 32rem;
  margin-bottom: 1.6rem;
`;

const CharacterName = styled.h1`
  margin: 0 0 0.4rem;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.9rem;
  color: var(--white-default);
`;

const InfoLabel = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const CharacterInfoTitle = styled.h1`
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 2.9rem;
  color: var(--white-default);
  margin-bottom: 1.6rem;
`;

const Row = styled.div`
  display: flex;
  gap: 4rem;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  min-width: 46rem;
  max-width: 46rem;
  min-height: 4.6rem;

  & > div {
    margin-left: -0.8rem;
  }
`;

const Line = styled.hr`
  margin: 1.6rem 0;
  border: 0.1rem solid var(--color-grey-7);
`;
