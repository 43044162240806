import React from 'react';

type UseFileDropzoneInput = {
  handler: (file: File) => void;
};

export const useFileDropzone = ({ handler }: UseFileDropzoneInput) => {
  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.persist();

    const items = Array.from(e.dataTransfer.items).map((item) => item);

    if (items.filter((item) => item.kind === 'string').length > 0) {
      return;
    }

    const files = items.map((item) => item.getAsFile()).filter((file) => file !== null) as File[];
    const file = files[0];

    if (file) {
      handler(file);
    }
  };

  return {
    handleDrop,
  };
};
