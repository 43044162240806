import React from 'react';

export const OutlookLogoIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
  >
    <g id="surface1">
      <path d="M 0 1.804688 L 0 14.046875 L 9.40625 16 L 9.40625 0 Z M 4.738281 10.773438 C 1.75 10.582031 2.058594 5.242188 4.808594 5.203125 C 7.757812 5.394531 7.46875 10.730469 4.738281 10.773438 Z M 4.78125 6.246094 C 3.207031 6.355469 3.277344 9.695312 4.757812 9.722656 C 6.328125 9.625 6.234375 6.273438 4.78125 6.246094 Z M 11.007812 8.375 C 11.152344 8.480469 11.324219 8.375 11.324219 8.375 C 11.152344 8.480469 15.980469 5.300781 15.980469 5.300781 L 15.980469 11.054688 C 15.980469 11.679688 15.578125 11.941406 15.121094 11.941406 L 10 11.941406 L 10 7.691406 Z M 10 3.390625 L 10 6.523438 L 11.105469 7.210938 C 11.136719 7.21875 11.199219 7.21875 11.226562 7.210938 L 15.980469 4.035156 C 15.980469 3.660156 15.625 3.390625 15.425781 3.390625 Z M 10 3.390625 " />
    </g>
  </svg>
);
