import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ChevronLeftIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { breakPoint } from '../theme';
import { IconButton } from '../../components/buttons';
import { uiStore } from '../../core/stores/ui-store';
import { DrawerIcon } from '../../components/icons/drawer-icon';
import { useOnClickOutside } from '../../hooks/useOutsideClick';
import { NotificationCenter } from './notification-center';

interface Props {
  hasSpace?: boolean;
}

export const MobileHeader: React.FC<Props> = observer(({ hasSpace }) => {
  const navigate = useNavigate();

  const handleDrawerClose = () => uiStore.contextDrawer?.close();
  const { ref } = useOnClickOutside(handleDrawerClose);

  const handleBack = () => {
    navigate(-1);
  };

  const goTo = () => {
    const href = uiStore.pageTitle?.href;
    if (!href) {
      return;
    }

    navigate(href);
  };

  return (
    <Header>
      <Left data-with-padding={hasSpace}>
        {uiStore.showBack && (
          <IconButton
            variant="dark"
            size="small"
            icon={<ChevronLeftIcon color="var(--color-grayscale-anti-flash-white)" />}
            onClick={handleBack}
          />
        )}

        {uiStore.pageTitle && <Title onClick={goTo}>{uiStore.pageTitle.label}</Title>}
      </Left>

      <Right>
        <NotificationCenter />

        <DrawerContainer id="contextual-drawer" ref={ref} data-hide={!uiStore.contextDrawer}>
          {uiStore.contextDrawer && (
            <MobileDrawerTrigger onClick={() => uiStore.contextDrawer!.toggle()}>
              <DrawerIcon />
            </MobileDrawerTrigger>
          )}
        </DrawerContainer>

        {uiStore.action && <Action onClick={uiStore.action as any}>Save</Action>}
      </Right>
    </Header>
  );
});

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  height: var(--tab-bar-height);
  min-height: var(--tab-bar-height);
  background-color: transparent;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  padding: 1rem 0;

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: none;
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &[data-with-padding='false'] {
    padding-left: 1.6rem;
  }
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-inline-end: 1.2rem;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 1.6rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--color-grayscale-white);
`;

const DrawerContainer = styled.div`
  display: block;

  &[data-hide='true'] {
    display: none;
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: none;
  }
`;

const MobileDrawerTrigger = styled.div`
  display: flex;
  padding: 0.8rem;
  justify-content: flex-end;
  align-items: center;
  width: fit-content;

  & > svg {
    transform: rotate(180deg);
  }
`;

const Action = styled.span`
  font-weight: 500;
  font-size: 1.4rem;
  margin-inline-end: 0.4rem;
  color: var(--color-primary-crayola);
`;
