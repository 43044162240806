import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Pencil1Icon } from '@radix-ui/react-icons';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';

import { formatDate } from '../../../lib/utils/DateHelper';
import { StringFormat } from '../../../assets/contants/dates-fns';
import { DateSelectionModal } from '../../process/components/date-selection.modal';
import { InlineTimeSelector, TimeDisplay } from '../../schedule/components/InlineTimeSelector';
import { timeTo24HourFormat, timeTo12HourFormat } from '../../../lib/utils/Convertor';
import { fetchStripBoard } from '../../strip-board/strip-board.slice';
import { KeyboardKeys } from '../../../assets/enums/keyboard-keys.enum';
import { breakPoint } from '../../../app/theme';
import { IconButton } from '../../../components/buttons';
import { IconButtonWrapper } from '../../../components/buttons/icon-button';
import { Step } from '../../../app/entities/step';

type InfoCardProps = {
  step: Step;
  projectId: string;
  startDate?: string;
  dueDate?: string;
  generalCall: {
    hour: number;
    minute: number;
  };
};

export const InfoCard: React.FC<InfoCardProps> = observer(
  ({ step, projectId, startDate, dueDate, generalCall }) => {
    const [isEditDateOpen, setIsEditDateOpen] = useState<boolean>();

    const onSubmit = useCallback(
      async (value: InfoCardProps['generalCall']) => {
        const time = timeTo24HourFormat(value!);
        const { hour, minute } = time || {};

        await step.update({
          info: {
            generalCallTime: {
              hour,
              minute,
            },
          },
        });

        fetchStripBoard(projectId);
      },
      [projectId, step],
    );

    const handleOpenDate = () => {
      setIsEditDateOpen(true);
    };

    const handleUpdateStep = async (values: any) => {
      step.update({
        startDate: values,
        dueDate: values,
        timeZone: DateTime.local().zoneName || undefined,
      });
    };

    const generalCallTime = generalCall && timeTo12HourFormat(generalCall);

    return (
      <Container>
        <MobileButtonWrapper>
          <IconButton
            variant="dark"
            icon={<Pencil1Icon />}
            onClick={() => setIsEditDateOpen(true)}
            size="small"
          />
        </MobileButtonWrapper>

        <TimeContainer>
          <Content>
            <InlineTimeSelector onChange={onSubmit} time={generalCallTime} />
          </Content>
        </TimeContainer>

        <Footer
          tabIndex={0}
          onClick={handleOpenDate}
          onKeyDown={(e) => {
            if (e.key === KeyboardKeys.Enter) {
              handleOpenDate();
            }
          }}
        >
          {startDate || dueDate ? formatDate(startDate || dueDate!, StringFormat) : 'Choose date'}
        </Footer>

        {isEditDateOpen && (
          <DateSelectionModal
            onCancel={() => setIsEditDateOpen(false)}
            fieldName="startDate"
            value={startDate}
            onSubmit={handleUpdateStep}
          />
        )}
      </Container>
    );
  },
);

const Container = styled.div`
  position: relative;
  background: var(--color-grayscale-trout);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  border-radius: 0.4rem;
  order: 1;
  padding: 0 1.6rem;
  gap: 0.2rem;
  min-width: 0;

  @media screen and (min-width: ${breakPoint.medium}px) {
    padding: 0;
    border-radius: 0;
    background: var(--color-grayscale-tuna);
    gap: unset;
    order: unset;
  }
`;

const TimeContainer = styled.div`
  width: 100%;
  border-radius: 0;

  @media screen and (min-width: ${breakPoint.medium}px) {
    background: var(--color-grayscale-shark);
    border-radius: 0 0 0.4rem 0.4rem;
    padding: 0 1.6rem 1.6rem 1.6rem;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-grayscale-trout);
  border-radius: 0.4rem;

  & > ${TimeDisplay} {
    color: var(--color-grayscale-white);
    margin: 0;
    font-size: 4rem;
    font-weight: 700;
    padding: 0 1.6rem;

    @media screen and (max-width: ${breakPoint.medium + 1}px) {
      & > span {
        font-weight: 500;
        font-size: 2.4rem;
      }
    }

    @media screen and (min-width: ${breakPoint.medium}px) {
      width: 100%;
      padding: 2.4rem;
      font-size: 6rem;
    }
  }
`;

const Footer = styled.button`
  all: unset;
  font-weight: 500;
  font-size: 1.4rem;
  text-align: center;
  padding: 0.4rem 0.8rem;
  margin: auto;
  color: var(--color-grayscale-white);
  width: fit-content;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    background-color: var(--color-grayscale-arsenic);
    border-radius: 0.4rem;
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    font-size: 2rem;
    line-height: 2.4rem;
  }
`;

const MobileButtonWrapper = styled(IconButtonWrapper)`
  position: absolute;
  top: 0.8rem;
  right: 1.6rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: none;
  }
`;
