import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import placeholder from '../../assets/images/project_cover_placeholder.jpg';

interface CoverImageProps {
  src: string;
  title: string;
}

export const CoverImage: React.FC<CoverImageProps> = observer(({ src, title }) => {
  const [hasError, setHasError] = useState<boolean>();

  if (!src || hasError) {
    return <Image src={placeholder} />;
  }

  return <Image src={src} alt={title} onError={() => setHasError(true)} />;
});

const Image = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  object-fit: cover;
  box-sizing: border-box;
  border: 1px var(--bg-color-dark-black) solid;
  border-radius: 0.8rem;
`;
