import React, { useCallback, useContext, useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import { PlusIcon } from '@radix-ui/react-icons';

import { GanttChartContext } from '../context/gantt-chart.context';

import { Tooltip } from '../../tooltip/Tooltip';
import { formatDate } from '../../../lib/utils/DateHelper';
import { dateShort2 } from '../../../assets/contants/dates-fns';

import type { ChartItem } from '../types';

type HoveringLayerProps = {
  item: ChartItem;
  style?: React.CSSProperties;
};

export const EmptyChartRow: React.FC<HoveringLayerProps> = ({ item, style }) => {
  const layerRef = useRef<HTMLDivElement>(null);

  const { datesFullRange } = useContext(GanttChartContext);

  const [columnIndex, setColumnIndex] = useState<number>(0);
  const [showAddStrip, setShowAddStrip] = useState(false);

  const hoveredDate = useMemo(
    () => datesFullRange?.[columnIndex]?.date,
    [datesFullRange, columnIndex],
  );

  const handleMouseLeave = useCallback(() => {
    setShowAddStrip(false);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setShowAddStrip(true);
  }, []);

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const gridLayer = layerRef.current;

      if (!gridLayer) {
        return;
      }

      const gridRect = gridLayer.getBoundingClientRect();
      const mouseX = event.clientX - gridRect.left;

      const columnIndex = Math.floor(mouseX / 48);

      setColumnIndex(columnIndex);
    },

    [],
  );

  const handleAddStrip = () => {
    if (!hoveredDate || !item) {
      return;
    }

    item.onItemUpdate?.({ startDate: hoveredDate, dueDate: hoveredDate });
  };

  if (!datesFullRange) {
    return null;
  }

  const tooltipDate = hoveredDate ? formatDate(hoveredDate.toISOString(), dateShort2) : '';

  return (
    <Container
      ref={layerRef}
      onMouseMove={handleMouseMove}
      style={style}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
      {showAddStrip && (
        <Tooltip disabled={!tooltipDate || !showAddStrip} text={tooltipDate}>
          <AddStripButton
            onClick={handleAddStrip}
            style={{
              gridColumnStart: (columnIndex || 0) + 1,
              gridColumnEnd: `span 1`,
            }}
          >
            <Content>
              <PlusIcon color="var(--color-grayscale-white)" />
            </Content>
          </AddStripButton>
        </Tooltip>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: repeat(auto-fill, 4.8rem);
  grid-template-rows: repeat(auto-fill, 4rem);
  align-items: center;
  justify-items: center;
  z-index: 2;
  position: relative;
  pointer-events: all;
`;

const AddStripButton = styled.button`
  all: unset;
  display: flex;
  width: 4.8rem;
  height: 4rem;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }
`;

const Content = styled.div`
  display: flex;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0.4rem 0.8rem;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-small);
  background: var(--color-primary-crayola, #367bff);
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }

  & > svg {
    min-width: 2.4rem;
    min-height: 2.4rem;
  }
`;
