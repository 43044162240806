import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { CurrentTask } from '../icons/CurrentTask';
import { defaultFormat } from '../../assets/contants/dates-fns';
import { CoverImage } from './CoverImage';

import type { Project } from '../../app/entities/project';
import { tabPool } from '../../features/tabs/models/tabs-pool';
import { observer } from 'mobx-react-lite';
import { ProjectCollaborators } from 'features/projects/components/project-collaborators';
import { authStore } from '../../core/stores/auth-store';
import { useResponsive } from '../../hooks/useResponsive';
import { ProjectMenu } from '../../features/assets/components/project-menu';

interface ProjectItemProps {
  project: Project;
}

export const ProjectItem: React.FC<ProjectItemProps> = observer(({ project }) => {
  const { t } = useTranslation('project');
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();

  const currentMember = authStore.getCurrentMember(project.spaceId);

  const handleOpenProject = (e: React.MouseEvent) => {
    const href = `/space/${project.spaceId}/projects/${project._id}`;

    tabPool.newTab({
      key: project._id,
      label: project.name,
      href,
    });

    if (!e.shiftKey) {
      navigate(href);
    }
  };

  return (
    <ProjectContainer onClick={handleOpenProject}>
      <Cover>
        <CoverImage key={project.avatar} src={project.avatar || ''} title={project.name} />
        {isDesktop && ['owner', 'admin', 'creator'].includes(currentMember?.role || '') && (
          <Collaborators className="cover-collaborators" onClick={(e) => e.stopPropagation()}>
            <ProjectCollaborators project={project} spaceId={project.spaceId} />
          </Collaborators>
        )}
        {isDesktop && (
          <ProjectMenuWrapper className="project-menu">
            <ProjectMenu project={project} />
          </ProjectMenuWrapper>
        )}
      </Cover>

      <Infos>
        <ProjectTitle>{project.name}</ProjectTitle>

        <Info>{`Last Updated: ${
          project.updatedAt ? format(new Date(project.updatedAt), defaultFormat) : '-'
        }`}</Info>
        <Info>{`Project Progress: ${project.progress}%`}</Info>
      </Infos>
    </ProjectContainer>
  );
});

const Cover = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;

  &:hover + #action {
    display: initial;
  }

  &:hover > #action {
    display: initial;
  }

  &:hover #action {
    display: initial;
  }
`;

const ProjectContainer = styled.div`
  width: 100%;
  cursor: pointer;

  &:hover .cover-collaborators {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover .project-menu {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
`;

const ProjectTitle = styled.h1`
  font-family: Inter, sans-serif;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 1.6rem;
  padding-bottom: 0.8rem;
  text-align: center;
  color: var(--header-project-color);
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Infos = styled.div`
  padding-top: 1.2rem;
`;

const Info = styled.div`
  color: #5f6164;
  font-size: 1.3rem;
  padding-bottom: 0.2rem;
  text-align: center;
`;

const Collaborators = styled.div`
  position: absolute;
  visibility: hidden;
  opacity: 0;
  bottom: 0.8rem;
  right: 0.8rem;
`;

const ProjectMenuWrapper = styled.div`
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 0.8rem;
  right: 0.8rem;
`;
