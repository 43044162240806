import { Tab } from './tab';
import { LocalArrayStore } from '../../../core/stores/local-array-store';
import { makeObservable, observable } from 'mobx';

const initialTabs: Tab[] = [];

class TabStore extends LocalArrayStore<Tab> {
  activeTab: string;

  constructor() {
    super(initialTabs);

    makeObservable(this, {
      activeTab: observable,
    });
  }
  openTab(input: { key: string; href?: string; label?: string }) {
    const existingTab = this.elements.find((tab) => tab.key === input.key);

    if (!existingTab) {
      // @ts-ignore
      this.elements.push(action.payload);
    } else if (input.href) {
      existingTab.href = input.href;
    }

    this.activeTab = input.key;
  }

  newTab(input: { key: string; href?: string; label?: string }) {
    const existingTab = this.elements.find((tab) => tab.key === input.key);

    if (!existingTab) {
      this.elements.push(Object.assign(new Tab(), input));
    }
  }

  updateTab(action: any) {
    const { key, ...update } = action;
    const tab = this.elements.find((el) => el.key === key);

    if (tab) {
      Object.assign(tab, update);
    } else {
      const tab = Object.assign(new Tab(), update);
      tab.key = key;
      this.elements.push(tab);
    }
  }
}

export const tabPool = new TabStore();
