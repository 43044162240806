import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ElevatedButton } from '../../components/buttons';
import { Card } from '../../components/on-boarding/Card';
import { TextInput } from '../../components/text-input/TextInput';
import { Form } from './components/form';
import { api } from '../../api';
import { Help } from '../../components/Input/styled-input';

type FormValues = {
  email: string;
};

const schema = yup.object().shape({
  email: yup.string().email('Please enter a valid email'),
});

export const WelcomePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation() as { state: { spaceId?: string } };

  const { register, handleSubmit, formState } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    api.post('/track', {
      type: 'event',
      eventName: 'Onboarding Started',
    });
  }, []);

  const next = async (values: FormValues) => {
    const href = '/onboarding/update-profile';

    navigate(href, {
      state: {
        ...location.state,
        ...(values.email && {
          referrer: values.email,
        }),
      },
    });
  };

  return (
    <Content>
      <Card
        title="Welcome to PRODUCER"
        footer={
          <ElevatedButton type="submit" text="Get started" form="referrer-form" isFullWidth />
        }
      >
        <Description>Awesome that you are here. Let&apos;s get you started!</Description>

        <Description>
          Did a friend refer you? If so, please provide their email below. To proceed without
          referral, click &apos;Get started&apos;.
        </Description>

        <Form id="referrer-form" onSubmit={handleSubmit(next)}>
          <TextInput
            {...register('email')}
            placeholder="email@example.com"
            errorMessage={formState.errors.email?.message}
          />
        </Form>

        <Help>Tip: Tell a friend and get 25GB of extra storage when they upgrade.</Help>
      </Card>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 427px) {
    & > div > div:first-child {
      text-align: center;
    }
  }
`;

const Description = styled.div`
  @media only screen and (max-width: 427px) {
    text-align: center;
  }
`;
