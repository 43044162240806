import React, { useEffect, useState } from 'react';
import { PdfViewer, VideoPlayer, PageLoader } from '@producer-io/ui-kit';

import { Asset } from '../../../app/entities/asset';
import { AssetViewerImage } from './AssetViewerImage';
import { PagePlaceholder } from 'components/page-placeholder/page-placeholder';
import { download } from '../../../lib/utils/download';
import { secondsToHms } from '../../../lib/utils/Convertor';
import { LoaderContainer } from '../../../routes/project/components/step-container';
import styled from 'styled-components';
import { EditorContent } from '@tiptap/react';
import { useRichTextEditor } from '../../../hooks/useRichTextEditor';
import { EditorBubbleMenu } from '../../../components/rich-editor/editor-bubble-menu';
import { breakPoint } from '../../../app/theme';

interface AssetPLayerProps {
  playerRef: React.RefObject<HTMLVideoElement | null>;
  timeTagRef: React.RefObject<HTMLSpanElement>;
  asset: Asset;
  pdfCurrentPage?: number;
  setPdfCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
}

export const AssetPlayer: React.FC<AssetPLayerProps> = ({
  playerRef,
  timeTagRef,
  asset,
  pdfCurrentPage,
  setPdfCurrentPage,
}) => {
  const [src, setSrc] = useState<null | string>(null);

  const { editor, isTextSelected } = useRichTextEditor({
    key: asset._id,
    value: asset.content || '',
    onUpdate: (text) => {
      asset.update({ content: text });
    },
  });

  const handleDownloadFile = () => {
    download(asset._id);
  };

  useEffect(() => {
    if (asset.type === 'note') return;

    asset.getPlaybackLink().then((url) => {
      setSrc(url);
    });
  }, [asset]);

  const renderComponent = () => {
    if (!src) {
      return;
    }

    if (asset.fileType?.startsWith('video')) {
      return (
        <VideoPlayer
          ref={playerRef as React.RefObject<HTMLVideoElement>}
          url={src}
          onTimeUpdate={(currentTime) => {
            if (!timeTagRef?.current) {
              return;
            }

            timeTagRef.current!.innerHTML = secondsToHms(currentTime);
          }}
          canAnnotate
        />
      );
    }

    if (asset.fileType?.startsWith('image')) {
      return <AssetViewerImage asset={asset} src={src} />;
    }

    if (asset.fileType === 'application/pdf') {
      return (
        <PdfViewer
          url={src}
          currentPage={pdfCurrentPage || 0}
          onPageChange={(page) => {
            if (setPdfCurrentPage) {
              setPdfCurrentPage(page);
            }
          }}
        />
      );
    }

    return (
      <PagePlaceholder
        description="This file type can't be displayed in the browser, you need to download the file"
        actionButton={{ text: 'Download', onClick: handleDownloadFile }}
      />
    );
  };

  if (asset.type === 'note') {
    return (
      <NotesInputWrapper>
        {editor && isTextSelected && <EditorBubbleMenu editor={editor} />}
        <NotesInput editor={editor} />
      </NotesInputWrapper>
    );
  }

  if (!src) {
    return (
      <LoaderContainer>
        <PageLoader />
      </LoaderContainer>
    );
  }

  return <>{renderComponent()}</>;
};

const NotesInput = styled(EditorContent)`
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  border-radius: 0.4rem;
  padding: 1.6rem;
  line-height: 1.8rem;
  color: var(--color-grayscale-white);
  font-size: 1.4rem;
  font-weight: 400;
  border: 0.1rem solid var(--color-grayscale-trout);
  position: relative;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
  letter-spacing: 0.3px;
  resize: none;
  background-color: transparent;

  & .tiptap.ProseMirror[contenteditable='true'] {
    min-height: 20vh;
    max-height: 100%;
  }

  &::placeholder {
    color: var(--color-grayscale-shuttle);
  }

  &:focus {
    background-color: transparent !important;
    border-color: var(--color-secondary);
  }

  &:hover {
    background-color: #ffffff11;
    cursor: text;
  }

  &[data-error='true'] {
    &:focus {
      border-color: var(--color-error);
    }
  }
`;

const NotesInputWrapper = styled.div`
  max-height: 100%;
  flex: 1;

  @media screen and (max-width: ${breakPoint.medium}px) {
    padding-top: 2.4rem;
  }
`;
