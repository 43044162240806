import { action, makeObservable, observable } from 'mobx';

import { Model } from '../../core/engine/model';
import { Project } from './project';
import { ManyToOne } from '../../core/engine/decorators';
import { StoryboardSettings } from '../../features/storyboards/models/storyboard-settings';
import { DEFAULT_STORYBOARD_SETTINGS } from '../../features/storyboards/default-settings';
import { Shot } from './shot';
import { api } from '../../api';
import { catchError } from '../../core/catch-error';
import { string } from 'yup';
import { id } from 'date-fns/locale';

export class Storyboard extends Model {
  projectId: string;
  duration: number;
  shots: StoryboardShot[] = [];
  description?: string;
  aspectRatio?: string;
  status?: string;
  settings: StoryboardSettings = DEFAULT_STORYBOARD_SETTINGS;

  @ManyToOne('storyboards')
  project: Project | null = null;

  constructor() {
    super('storyboards');

    makeObservable(this, {
      projectId: observable,
      duration: observable,
      description: observable,
      aspectRatio: observable,
      settings: observable,
      project: observable,
      addShot: action,
      shots: observable,
      insertShot: action,
    });
  }

  static getOne(id: string): Storyboard | undefined {
    return Model.getOne(id) as Storyboard;
  }

  async addShot(shotId: string, position?: number) {
    const currentShots = JSON.parse(JSON.stringify(this.shots));

    if (position === undefined) {
      position = this.shots.length;
    }

    this.shots.splice(position, 0, {
      _id: shotId,
      position,
    });

    try {
      const { data } = await api.post(`/storyboards/${this._id}/shots`, {
        shotId,
        position,
      });

      const newShot = this.shots.find((shot) => shot._id === shotId);

      if (newShot) {
        Object.assign(newShot, data);
      }
    } catch (e) {
      catchError(e);
      this.shots = currentShots;
    }
  }

  toPOJO(): Record<string, any> {
    return {
      _id: this._id,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      duration: this.duration,
      shots: this.shots,
      description: this.description,
      aspectRatio: this.aspectRatio,
      projectId: this.projectId,
      project: this.project,
    };
  }

  async edit(shotIds: string[], position: number) {
    const currentShots = JSON.parse(JSON.stringify(this.shots));

    const selectedShotIndex = this.shots.findIndex((shot) => shotIds.includes(shot._id));
    const insertIndex = position < selectedShotIndex ? position - 1 : position;
    let dividerIndex;

    if (insertIndex >= -1 && insertIndex < this.shots.length) {
      dividerIndex = insertIndex;
    } else {
      dividerIndex = this.shots.length;
    }

    const selectedShot = this.shots.filter((shot) => shotIds.includes(shot._id));

    const upperHalfRemainingCards = this.shots
      .slice(0, dividerIndex + 1)
      .filter((currShot) => !shotIds.includes(currShot._id));

    const lowerHalfRemainingCards = this.shots
      .slice(dividerIndex + 1)
      .filter((currShot) => !shotIds.includes(currShot._id));

    const finalShots = [...upperHalfRemainingCards, ...selectedShot, ...lowerHalfRemainingCards];
    finalShots.forEach((shot, index) => (shot.position = index));

    this.shots = finalShots;

    try {
      await api.patch(`/projects/${this.projectId}/storyboards/${this._id}`, {
        shotIds,
        position,
      });
    } catch (e) {
      catchError(e);
      this.shots = currentShots;
    }
  }

  insertShot(shot: Shot, position?: number, duration?: number) {
    position = position || this.shots.length;

    const storyboardShot = {
      _id: shot._id,
      duration,
      position,
      locked: false,
    };

    this.shots.splice(position, 0, storyboardShot);
    this.shots.forEach((el, index) => (el.position = index));

    return storyboardShot;
  }
}

// Review StoryboardShot model vs Shot model
export type StoryboardShot = Partial<Shot> & {
  _id: string;
  duration?: number;
  locked?: boolean;
  position: number;
};
