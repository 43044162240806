import React, { useState } from 'react';

import { timeParser } from 'lib/utils/time-parser';
import { EstimatedTimeSelector } from './EstimatedTimeSelector';
import { TimeDisplay } from './InlineTimeSelector';

interface InlineTimePeriodSelectorProps {
  time?: number;
  onChange: (time: number) => void;
}

export const InlineTimePeriodSelector: React.FC<InlineTimePeriodSelectorProps> = ({
  time,
  onChange,
}) => {
  const [showTimeSelector, setShowTimeSelector] = useState(false);

  const handleTimeChange = async (time: number) => {
    await onChange(time);
    setShowTimeSelector(false);
  };
  return (
    <>
      {showTimeSelector && (
        <EstimatedTimeSelector
          onTimeChange={handleTimeChange}
          time={time}
          onCancel={() => setShowTimeSelector(false)}
        />
      )}

      <TimeDisplay
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setShowTimeSelector((prev) => !prev);
          }
        }}
        onClick={(e) => {
          e.stopPropagation();
          setShowTimeSelector((prev) => !prev);
        }}
      >
        {timeParser(time || 0).toString()}
      </TimeDisplay>
    </>
  );
};
