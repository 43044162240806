import React from 'react';

export const Building = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6605_10352)">
      <path
        d="M18.4545 17.4444H20V19H3V17.4444H4.54545V5.77778C4.54545 5.5715 4.62687 5.37367 4.77178 5.22781C4.9167 5.08194 5.11324 5 5.31818 5H13.0455C13.2504 5 13.4469 5.08194 13.5919 5.22781C13.7368 5.37367 13.8182 5.5715 13.8182 5.77778V17.4444H15.3636V9.66667H17.6818C17.8868 9.66667 18.0833 9.74861 18.2282 9.89447C18.3731 10.0403 18.4545 10.2382 18.4545 10.4444V17.4444ZM7.63636 11.2222V12.7778H10.7273V11.2222H7.63636ZM7.63636 8.11111V9.66667H10.7273V8.11111H7.63636Z"
        fill="#626878"
      />
    </g>
    <defs>
      <clipPath id="clip0_6605_10352">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
