import React from 'react';

import { GanttChartProvider } from './context/gantt-chart.provider';
import { ChartWrapper } from './chart-wrapper';

import type { GanttItem } from './types';

export type GanttChartProps = {
  items: GanttItem[];
};

export const GanttChart: React.FC<GanttChartProps> = ({ items }) => {
  return (
    <GanttChartProvider items={items}>
      <ChartWrapper items={items} />
    </GanttChartProvider>
  );
};
