import React from 'react';

export const MapIcon: React.FC = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6667 2.5L13.56 2.52L10 3.9L6 2.5L2.24 3.76667C2.1 3.81333 2 3.93333 2 4.08667V14.1667C2 14.3533 2.14667 14.5 2.33333 14.5L2.44 14.48L6 13.1L10 14.5L13.76 13.2333C13.9 13.1867 14 13.0667 14 12.9133V2.83333C14 2.64667 13.8533 2.5 13.6667 2.5ZM6.66667 4.14667L9.33333 5.08V12.8533L6.66667 11.92V4.14667ZM3.33333 4.80667L5.33333 4.13333V11.9333L3.33333 12.7067V4.80667ZM12.6667 12.1933L10.6667 12.8667V5.07333L12.6667 4.3V12.1933Z"
      fill="currentColor"
    />
  </svg>
);
