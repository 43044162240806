import styled from 'styled-components';

export const InputContainer = styled.div`
  padding-bottom: 0.8rem;
  position: relative;
`;

export const BlockTitle = styled.h4`
  font-size: 1.2rem;
  font-weight: 600;
  padding-bottom: 0.8rem;
  color: var(--card-subtitle-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  b {
    font-weight: 600;
  }

  svg {
    height: 0.8rem;
    width: 0.8rem;
  }
`;

export const Bloc = styled.div`
  margin-top: 1.2rem;
  padding-top: 1.2rem;
  border-top: 1px solid var(--select-border-color);
`;
