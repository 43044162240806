import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';

type SliderInputProps = {
  min?: number;
  max?: number;
  step?: number;
  value?: number;
  onChange?: (value: number) => void;
};

export const SliderInput = forwardRef<HTMLInputElement, SliderInputProps>(
  ({ min = 0, max = 10, step = 1, value = 1, onChange }, ref) => {
    const [currentValue, setCurrentValue] = useState(value);

    useEffect(() => {
      setCurrentValue(value);
    }, [value]);

    const getBackgroundSize = () => {
      const backgroundSizeBase = currentValue - min;

      return {
        backgroundSize: `${(backgroundSizeBase * 100) / (max - min)}% 100%`,
      };
    };

    const handleChange = (value: number) => {
      onChange && onChange(value);
      setCurrentValue(value);
    };

    const handleIncrement = () => {
      if (currentValue >= max) {
        return;
      }
      const value = currentValue + step;
      handleChange(value);
    };

    const handleDecrement = () => {
      if (currentValue <= min) {
        return;
      }
      const value = currentValue - step;
      handleChange(value);
    };

    return (
      <Container>
        <Affix onClick={handleDecrement}>-</Affix>
        <Input
          ref={ref}
          type="range"
          value={currentValue}
          min={min}
          max={max}
          step={step}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.valueAsNumber;
            handleChange(value);
          }}
          style={getBackgroundSize()}
        />
        <Affix onClick={handleIncrement}>+</Affix>
      </Container>
    );
  },
);

SliderInput.displayName = 'SliderInput';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: var(--border-radius-medium);
`;

const Affix = styled.span`
  font-size: 2rem;
  font-weight: 500;
  color: var(--white-default);
  padding: 0.4rem;
  cursor: pointer;
  user-select: none;

  :hover {
    opacity: 0.8;
  }
`;

const Input = styled.input`
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;

  height: 0.8rem;
  background: #313236;
  border-radius: var(--border-radius-medium);
  width: 100%;
  cursor: pointer;
  background-image: linear-gradient(var(--color-secondary), var(--color-secondary));
  background-repeat: no-repeat;
  margin: 0 0.8rem;

  /* Track: webkit*/
  ::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  /* Track: Mozilla Firefox */
  ::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  /* Thumb: webkit */
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 1.6rem;
    width: 1.6rem;
    border-radius: 50%;
    background: var(--color-secondary);
    cursor: pointer;
    box-shadow: 0 0 2px 0 #367bff;
    transition: background 0.3s ease-in-out;

    :hover {
      box-shadow: #367bff50 0px 0px 0px 4px;
    }

    :active {
      box-shadow: #367bff50 0px 0px 0px 6px;
      transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        left 350ms cubic- bezier(0.4, 0, 0.2, 1) 0ms, bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  /* Thumb: Firefox */
  ::-moz-range-thumb {
    height: 1.6rem;
    width: 1.6rem;
    border-radius: 50%;
    background: var(--color-secondary);
    cursor: pointer;
    transition: background 0.3s ease-in-out;
    border: none;

    :hover {
      box-shadow: #367bff50 0px 0px 0px 4px;
    }

    :active {
      box-shadow: #367bff50 0px 0px 0px 6px;
      transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        left 350ms cubic- bezier(0.4, 0, 0.2, 1) 0ms, bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
`;
