import { Frame, getFrame } from '../../../lib/utils/transform-image';
import * as fileService from 'core/services/file.service';
import { api } from 'api';
import { formatPicture } from '../../../core/services/image.service';
import { checkIfFileIsImage, checkIfFileIsVideo } from './upload-utils';
import { Upload } from '../../../app/entities/upload';
import { Asset } from '../../../app/entities/asset';

type AddThumbnailInput = {
  assetId: string;
  frame: Frame;
  options?: {
    parentAssetId?: string;
  };
};

export const addThumbnail = async ({ assetId, frame, options }: AddThumbnailInput) => {
  const { data } = await api.patch(`/assets/${assetId}/thumbnail`, {
    type: frame.type,
    size: frame.size,
  });

  await fileService.upload(data.links.upload, frame.data);

  /**
   * If we are adding a version to a stack, we want to update the thumbnail of the stack
   */
  if (options && options.parentAssetId) {
    data._id = options.parentAssetId;

    const stack = Asset.getOne(options.parentAssetId)!;
    stack.cover = data.cover;
  }

  const asset = Asset.getOne(assetId)!;
  asset.cover = data.cover;

  return data;
};

interface UploadFilesPayload {
  upload: Upload;
  assetId: string;
  file: File;
  options?: {
    parentAssetId?: string;
  };
}

export const uploadFile = async ({ upload, file, assetId, options }: UploadFilesPayload) => {
  await createThumbnail(file, assetId, options);

  await upload.setFile(file);

  upload.start();

  return upload;
};

const createThumbnail = async (
  file: File,
  assetId: string,
  options?: { parentAssetId?: string },
) => {
  try {
    let frame: Frame | null = null;

    if (checkIfFileIsImage(file)) {
      try {
        frame = await formatPicture(file);
      } catch (e) {
        // This should fail silently
      }
    }

    if (checkIfFileIsVideo(file)) {
      try {
        frame = await getFrame(file);
      } catch (e) {
        // This should fail silently
      }
    }

    if (frame) {
      await addThumbnail({
        frame,
        assetId,
        options,
      });
    }
  } catch (e) {
    console.log('Could not create thumbnail.');
  }
};
