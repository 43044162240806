import React, { useCallback } from 'react';
import styled from 'styled-components';

import { Months } from '../types';
import { GanttGrid } from './styled-gantt.components';
import { MonthCell } from './month-cell';

import type { FullDatesRange } from '../utils/date-utils';

type ChartHeaderProps = {
  datesByMonths?: FullDatesRange['byMonth'];
};

export const ChartHeader = React.forwardRef<HTMLDivElement, ChartHeaderProps>(
  ({ datesByMonths }, ref) => {
    const containerCallback = useCallback(
      (node: HTMLDivElement | null) => {
        if (!node) {
          return;
        }

        if (ref) {
          if (typeof ref === 'function') {
            ref(node);
          } else {
            ref.current = node;
          }
        }

        const today = document.getElementById('today');

        if (!today) {
          return;
        }

        today.scrollIntoView({ behavior: 'instant', inline: 'center', block: 'nearest' });
      },
      [ref],
    );

    if (!datesByMonths) {
      return null;
    }

    return (
      <Container ref={containerCallback}>
        {Object.keys(datesByMonths)?.map((monthKey) => {
          const monthDates = datesByMonths[monthKey];

          const monthIndex = monthDates[0].month;
          const year = monthDates[0].year;
          const monthName = Months[monthIndex];

          return (
            <MonthContainer key={monthKey}>
              <MonthLabel>
                <span>{monthName}</span>

                <span>{year}</span>
              </MonthLabel>

              <GanttGrid>
                {monthDates?.map((date) => {
                  const { day, month, year, isToday, weekDay, isWeekend } = date;

                  return (
                    <MonthCell
                      key={`${day}-${month}-${year}`}
                      day={day}
                      month={month}
                      year={year}
                      isToday={isToday}
                      weekDay={weekDay}
                      isWeekend={isWeekend}
                    />
                  );
                })}
              </GanttGrid>
            </MonthContainer>
          );
        })}
      </Container>
    );
  },
);

ChartHeader.displayName = 'ChartHeader';

const Container = styled.div`
  position: relative;
  display: flex;
  height: fit-content;
  width: fit-content;
  pointer-events: auto;
`;

const MonthLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.6rem 1.2rem;
  width: 100%;
  background-color: var(--color-surfaces-bg-elevation-3);
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 400;
  color: var(--color-texts-high-contrast);
  border-inline-end: 0.1rem solid var(--color-surfaces-bg-elevation-4);
  white-space: nowrap;

  & > span:last-child {
    color: var(--color-texts-middle-contrast);
  }
`;

const MonthContainer = styled.div`
  position: relative;
  background-color: var(--color-surfaces-bg-elevation-2);

  &:first-child {
    & ${MonthLabel} {
      border-radius: 0.4rem 0 0 0;
    }
  }

  &:last-child {
    & ${MonthLabel} {
      border-radius: 0 0.4rem 0 0;
      border: none;
    }
  }
`;
