import React from 'react';

export const UrgentPriorityIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.33325 8.99998H7.66659V10.3333H6.33325V8.99998ZM6.33325 3.66665H7.66659V7.66665H6.33325V3.66665ZM6.99325 0.333313C3.31325 0.333313 0.333252 3.31998 0.333252 6.99998C0.333252 10.68 3.31325 13.6666 6.99325 13.6666C10.6799 13.6666 13.6666 10.68 13.6666 6.99998C13.6666 3.31998 10.6799 0.333313 6.99325 0.333313ZM6.99992 12.3333C4.05325 12.3333 1.66659 9.94665 1.66659 6.99998C1.66659 4.05331 4.05325 1.66665 6.99992 1.66665C9.94659 1.66665 12.3333 4.05331 12.3333 6.99998C12.3333 9.94665 9.94659 12.3333 6.99992 12.3333Z"
      fill="#DB5C66"
    />
  </svg>
);
