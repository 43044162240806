import { computed, makeObservable, observable } from 'mobx';

import { Model } from '../../core/engine/model';
import { ManyToOne } from '../../core/engine/decorators';
import { getFullName } from '../../lib/utils/get-full-name';

import type { Project } from './project';

type ShareLinkSettings = {
  canDownload: boolean;
  canComment: boolean;
};

export class ShareLink extends Model {
  name: string;

  projectId: string;

  spaceId: string;

  url: string;

  /*
   * Initializing with empty object as settings on old links might
   * be undefined and it throws an exception when calling update method
   */
  settings: ShareLinkSettings = {} as ShareLinkSettings;

  recipients: string[];

  assetIds: string[] = [];

  expiresAt?: Date;

  creator: {
    firstName: string;
    lastName: string;
  };

  @ManyToOne('shareLinks')
  public project: Project;

  constructor() {
    super('share-links');
    this.assetIds = [];

    makeObservable(this, {
      name: observable,
      project: observable,
      settings: observable,
      assetIds: observable,
      creatorName: computed,
    });
  }

  get creatorName() {
    if (!this.creator) {
      return '';
    }

    return getFullName(this.creator);
  }

  async update(value: any) {
    await super.update(value);

    if (typeof value.canDownload === 'boolean') {
      this.settings.canDownload = value.canDownload;
    }
  }

  toPOJO(): Record<string, any> {
    return {
      _id: this._id,
      name: this.name,
    };
  }

  static getOne(id: string): ShareLink | undefined {
    return Model.getOne(id) as ShareLink;
  }

  static getAll(): ShareLink[] {
    return Model.getAll().filter((entity) => entity instanceof ShareLink) as ShareLink[];
  }

  static removeAsset(assetId: string) {
    const shareLinks = this.getAll();

    shareLinks.forEach((shareLink) => {
      shareLink.assetIds = shareLink.assetIds.filter((id) => id !== assetId);

      // remove the share link if no assetIds left
      if (shareLink.assetIds.length === 0) {
        shareLink.remove();
      }
    });
  }
}
