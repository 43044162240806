import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { PlusIcon } from '@radix-ui/react-icons';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { InfoTextLine } from '../texts/Texts';
import { BlueCheckbox } from './blue-checkbox';
import { KeyboardKeys } from '../../assets/enums/keyboard-keys.enum';

import type { SelectDropdownProps as SelectBaseDropdownProps } from '.';

type SelectDropdownProps = SelectBaseDropdownProps & {
  isMulti?: boolean;
  selectedItemsLabel?: string;
};

export const SelectDropdown: React.FC<SelectDropdownProps> = ({
  title,
  selectedItemsLabel,
  items,
  onCreate,
  isMulti,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [options, setOptions] = useState<typeof items>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!searchValue) {
      setOptions(items);
    } else {
      const filteredItems = items.filter((item) =>
        item.title?.toString().toLowerCase().includes(searchValue.toLowerCase()),
      );

      setOptions(filteredItems);
    }
  }, [items, searchValue]);

  const handleCreate = async () => {
    await onCreate?.(searchValue);
    handleOpenChange(false);
  };

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);

    if (!isOpen) {
      setSearchValue('');
    }
  };

  const shouldDisplayCreate =
    onCreate && searchValue && (options?.length !== 1 || options[0].title !== searchValue);

  return (
    <Container modal={false} open={isOpen} onOpenChange={handleOpenChange}>
      <DropdownMenu.Trigger asChild>
        <Line data-ellipsis={!isMulti} tabIndex={0} className={'trigger'}>
          {selectedItemsLabel ? (
            <InfoTextLine value={selectedItemsLabel as string} />
          ) : (
            <StyledEmpty>{title}</StyledEmpty>
          )}
        </Line>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <MenuContainer sideOffset={4} align="start">
          <SearchInput
            placeholder="Search or create"
            tabIndex={0}
            value={searchValue}
            onKeyDown={(e) => {
              if (e.key !== KeyboardKeys.Escape) {
                e.stopPropagation();
              }
              if (e.key == KeyboardKeys.Enter) {
                handleCreate();
              }
            }}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setSearchValue(value);
            }}
            autoFocus
          />

          <Separator />

          <OptionsContainer>
            {options?.map((item, index) => (
              <CheckboxItem
                key={`item-${item.title}-${index}`}
                checked={item.isChecked}
                onCheckedChange={(isChecked) => {
                  item.onCheckedChange?.(isChecked);
                }}
              >
                <IconContainer>
                  <BlueCheckbox checked={!!item.isChecked!} />
                </IconContainer>

                {item.title}
              </CheckboxItem>
            ))}

            {shouldDisplayCreate && (
              <ListItem onSelect={handleCreate}>
                <IconContainer>
                  <PlusIcon />
                </IconContainer>

                <OptionLabel>
                  Create <span>&quot;{searchValue}&quot;</span>
                </OptionLabel>
              </ListItem>
            )}
          </OptionsContainer>
        </MenuContainer>
      </DropdownMenu.Portal>
    </Container>
  );
};

const Container = styled(DropdownMenu.Root)`
  margin: -1.2rem;
  width: 100%;

  svg {
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 0.5rem;
  }
`;

const Line = styled.div`
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 0.8rem 1.2rem;
  line-height: 1.6rem;
  max-width: 100%;
  overflow: hidden;
  border-radius: 0.4rem;

  &[data-ellipsis='true'] {
    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &:hover,
  &:focus-visible {
    background-color: var(--color-grayscale-arsenic);
    cursor: pointer;

    & div {
      color: var(--color-grayscale-white);
    }

    svg {
      stroke: var(--color-grayscale-white);
    }
  }
`;

const StyledEmpty = styled.div`
  font-size: 1.4rem;
  color: var(--color-grayscale-light-slate);
`;

const MenuContainer = styled(DropdownMenu.Content)`
  min-width: 32rem;
  width: 100%;
  overflow-y: auto;
  max-height: 32rem;
  border-radius: 0.3rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 24px;
  backdrop-filter: blur(10px) saturate(190%) contrast(70%) brightness(80%);
  background-color: rgba(29, 30, 43, 0.498);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.4rem 0;
  transition: all 100ms ease-in-out;
  z-index: 1000;
`;

const SearchInput = styled.input`
  background-color: transparent;
  color: var(--color-grayscale-white);
  border: none;
  padding: 0 1.2rem;
  width: 100%;
  height: 3.2rem;
  font-weight: 400;
  font-size: 1.4rem;
`;

const OptionsContainer = styled.div`
  padding: 0.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;

const Separator = styled(DropdownMenu.Separator)`
  width: 100%;
  margin: 0.8rem 0 0.4rem;
  border-bottom: 0.1rem solid var(--color-grayscale-tuna);
`;

const BaseMenuItemStyles = css`
  color: var(--color-grayscale-light-slate);
  position: relative;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-medium);
  padding: 0.8rem;
  font-size: 1.4rem;
  font-weight: 400;
  height: 3.2rem;

  > svg {
    margin-right: 0.8rem;
  }

  &[data-highlighted] {
    cursor: pointer;
    background-color: rgba(124, 124, 163, 0.125);
  }

  &[data-disabled] {
    color: var(--color-grey-7);
    pointer-events: none;
  }

  &[data-type='danger'] {
    color: var(--color-error);
  }
`;

const CheckboxItem = styled(DropdownMenu.CheckboxItem)`
  ${BaseMenuItemStyles}
`;

const ListItem = styled(DropdownMenu.Item)`
  ${BaseMenuItemStyles}
`;

const IconContainer = styled.div`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 0.8rem;

  & div {
    vertical-align: unset;
  }
`;

const OptionLabel = styled.div`
  align-items: center;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.8rem;
  color: var(--color-grayscale-light-slate);

  > span {
    color: var(--color-grayscale-white);
  }
`;
