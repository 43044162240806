import React, { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import styled from 'styled-components';

import { createStep } from '../step.slice';
import { BaseDialog, DialogFooter, DialogHeader } from '../../../components/dialog';
import { ElevatedButton } from '../../../components/buttons';
import { DescriptiveDropdown } from '../../../components/dropdowns/DescriptiveDropdown';
import { TextInput } from '../../../components/text-input/TextInput';
import { DateInput } from '../../../components/date-input/DateInput';

type CreateStepDialogProps = {
  stageId: string;
  onCancel: () => void;
};

const defaultOptions = [
  {
    value: 'default',
    label: 'Files & Links',
    description:
      'Group your video clips, images and documents for reviewing, commenting and sharing. Add link references.',
  },
];

export const CreateStepDialog: React.FC<CreateStepDialogProps> = ({ stageId, onCancel }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .matches(/^(.*)?\S+(.*)?$/, t('common:fieldRequired'))
          .max(32, t('common:fieldMax', { max: 32 }))
          .required(t('common:fieldRequired')),
        startDate: yup
          .string()
          .transform((_, val) => {
            // if user clicked on the "clear" button
            if (val === '') return;
            return val;
          })
          .test({
            name: 'validateStartDate',
            exclusive: false,
            message: "Start date can't be after due date",
            test: (value, context) => {
              const dueDate = context.parent.dueDate;
              if (!dueDate || !value) return true;

              const maxDate = new Date(dueDate);
              maxDate.setUTCHours(0, 0, 0, 0);

              return maxDate.getTime() >= new Date(value).setUTCHours(0, 0, 0, 0);
            },
          }),
        dueDate: yup
          .string()
          .transform((_, val) => {
            // if user clicked on the "clear" button
            if (val === '') return;
            return val;
          })
          .test({
            name: 'validateDueDate',
            exclusive: false,
            message: 'Due date cannot be earlier than the start date',
            test: (value, context) => {
              const startDate = context.parent.startDate;
              if (!startDate || !value) return true;

              const minDate = new Date(startDate);
              minDate.setUTCHours(0, 0, 0, 0);

              return minDate.getTime() <= new Date(value).setUTCHours(0, 0, 0, 0);
            },
          }),
        type: yup.string(),
      }),
    [t],
  );

  const { control, handleSubmit, getValues } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      startDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      dueDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      type: 'default',
    },
  });

  const handleOnSubmit = async () => {
    if (isLoading) {
      return;
    }

    const { name, dueDate, type, startDate } = getValues();

    const payload = {
      stageId,
      name,
      type,
      ...(dueDate ? { dueDate } : {}),
      ...(startDate ? { startDate } : {}),
    };

    try {
      await createStep(payload);
      onCancel();
    } catch (err) {
      // TODO handle error
      setIsLoading(false);
    }
  };

  return (
    <BaseDialog onClose={onCancel}>
      <DialogHeader title="Add a step" onClose={onCancel} />

      <Container>
        <Form id="create-step-form" onSubmit={handleSubmit(handleOnSubmit)}>
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
              <TextInput
                ref={ref}
                value={value}
                name={name}
                onChange={onChange}
                label="Name"
                errorMessage={error?.message}
                required
              />
            )}
          />

          <Controller
            control={control}
            name="startDate"
            render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
              <DateInput
                ref={ref}
                onChange={onChange}
                value={value}
                name={name}
                dark={true}
                label="Start Date"
                errorMessage={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="dueDate"
            render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
              <DateInput
                ref={ref}
                onChange={onChange}
                value={value}
                name={name}
                dark={true}
                label="Due Date"
                errorMessage={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="type"
            render={({ field: { onChange, value } }) => (
              <DescriptiveDropdown value={value} onChange={onChange} options={defaultOptions} />
            )}
          />
        </Form>
      </Container>

      <DialogFooter
        actions={[
          <ElevatedButton
            key="create"
            text="Create"
            type="submit"
            form="create-step-form"
            isLoading={isLoading}
          />,
        ]}
      />
    </BaseDialog>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  & > div:last-child {
    margin-top: 1.6rem;
  }

  & > div:not(:last-child) {
    span {
      bottom: -1.8rem;
      color: var(--color-error);
      font-size: 1rem;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 70rem;
  height: 100%;
  padding: 1.6rem;
  max-width: 50rem;
  z-index: 999;
  overflow-y: auto;
`;
