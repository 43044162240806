import React, { DragEvent } from 'react';
import styled from 'styled-components';

import { Frame } from '../../../lib/utils/transform-image';
import { formatPicture } from '../../../core/services/image.service';
import { Add } from '../../../components/icons';
import { selectFiles } from '../../../core/services/file.service';

type CoverPlaceholderProps = {
  onUpload: (frame: Frame) => void;
};

export const CoverPlaceholder: React.FC<CoverPlaceholderProps> = ({ onUpload }) => {
  const handleUpload = async (file: File) => {
    let frame;

    if (file.type === 'image/gif') {
      frame = {
        data: file,
        size: file.size,
        type: file.type,
      };
    } else {
      const options = { width: 640, height: 380 };
      frame = await formatPicture(file, options);
    }

    onUpload(frame);
  };

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const files = Array.from(e.dataTransfer.items).map((item) => item);

    if (files.filter((el) => el.kind === 'string').length > 0) {
      return;
    }

    const file = Array.from(e.dataTransfer.items)[0].getAsFile();

    if (file) {
      handleUpload(file);
    }
  };

  const handleClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const files = await selectFiles();

    if (!files) {
      return;
    }

    const file = Array.from(files)[0];

    if (file) {
      handleUpload(file);
    }
  };

  return (
    <Placeholder
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleClick}
    >
      <Add />
      Add photo
    </Placeholder>
  );
};

const Placeholder = styled.div`
  height: 100%;
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 0.4rem;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 400;
  background-color: var(--color-grayscale-tuna);
  color: var(--color-secondary);

  svg {
    height: 1.2rem;
    width: 1.2rem;
  }

  &:hover {
    cursor: pointer;
  }
`;
