import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { OptionDto } from '../../dto/common/option.dto';
import { ChevronDownIcon } from '@radix-ui/react-icons';

export interface SelectProps {
  label: string;
  value?: string;
  placeHolder?: string;
  options: OptionDto[];
  onSelect?: (el: any) => void;
  disabled?: boolean;
  takeDefaultFirst?: boolean;
  name?: string;
}

export const SelectDrawer: React.FC<SelectProps> = ({
  label,
  value,
  onSelect,
  options,
  placeHolder,
  name,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [_value, setValue] = useState<string | undefined>(value);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (el: any) => {
    const e = {
      target: {
        name: name,
        value: el.value,
      },
    };

    if (onSelect) {
      onSelect(e);
    }

    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef!.current!.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [wrapperRef]);

  return (
    <SelectInputContainer ref={wrapperRef}>
      <Label style={{ zIndex: -1 }}>{label}</Label>

      <Input onClick={handleOpen}>
        <Text>
          {_value !== undefined
            ? options.find((el) => el.value === _value)?.label
            : placeHolder
            ? placeHolder
            : options.find((el) => el.value === undefined) === undefined
            ? 'Select value'
            : options.find((el) => el.value === undefined)!.label}
        </Text>

        <Icon>
          <ChevronDownIcon />
        </Icon>

        {isOpen && (
          <Option>
            <ul>
              {options!.map((el, key) => (
                <ListItem key={key} data-hidden={el.isHidden} onClick={() => handleOptionClick(el)}>
                  {el.label}
                </ListItem>
              ))}
            </ul>
          </Option>
        )}
      </Input>
    </SelectInputContainer>
  );
};

const SelectInputContainer = styled.div`
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  min-width: 15rem;
  z-index: 99;
  width: 100%;
  color: var(--select-text-color);
  user-select: none;
`;

const Label = styled.div`
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
`;

const Input = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 18rem;
  height: 2.5rem;
  border-radius: var(--card-border-radius);
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  line-height: 1.6rem;
  display: flex;
  z-index: 1;
  align-items: center;
  border: 1px solid var(--select-border-color);
  color: var(--select-text-color);

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
    border: 1px solid var(--select-border-color);
    color: var(----select-text-color);
  }
`;

const Text = styled.div`
  position: relative;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  font-size: 1.2rem;
  letter-spacing: 0.3px;
  line-height: 1.6rem;
  min-width: 12rem;
`;

const Icon = styled.div`
  position: absolute;
  right: 0.8rem;
  top: 0.2rem;

  svg {
    fill: var(--select-border-color);
    width: 1.6rem;
    height: 1.6rem;
  }
`;

const Option = styled.div`
  position: absolute;
  top: calc(100% + 0.4rem);
  width: 18rem;
  right: 0;
  font-family: Inter, sans-serif;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  background-color: var(--card-bg-color);
  border-radius: var(--card-border-radius);
  padding: 0.6rem 0.4rem;
  animation: fadein 0.2s;
  z-index: 99;

  ul {
    overflow-y: auto;
    max-height: 30rem;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 0.4rem 0.6rem;
    font-size: 1.2rem;
    border-radius: var(--card-border-radius);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  li:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
  }

  @keyframes fadein {
    0% {
      opacity: 0;
      transform: scale(0.95);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const ListItem = styled.li`
  &[data-hidden='true'] {
    display: none;
  }
`;
