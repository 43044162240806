import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled from 'styled-components';

import { BaseDialog, DialogHeader, DialogFooter } from '../../../components/dialog';
import { ElevatedButton, TextButton } from '../../../components/buttons';
import { TextInput } from '../../../components/text-input/TextInput';
import { Asset } from '../../../app/entities/asset';
import { breakPoint } from '../../../app/theme';

interface ConfigureLinkTypeAssetModalProps {
  onCancel: () => void;
  onSubmit: (values: { address: string; name?: string }) => Promise<void>;
  asset?: Asset;
}

export const ConfigureLinkTypeAssetModal: React.FC<ConfigureLinkTypeAssetModalProps> = ({
  onCancel,
  onSubmit,
  asset,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const isUpdate = !!asset;

  const schema = yup.object({
    address: yup.string().max(2048, 'Max 2048 characters').required('Address is required'),
    name: yup.string().max(64, 'Max 64 characters').optional(),
  });

  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      name: asset?.name || '',
      address: asset?.url || '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onHandleSubmit = async (values: { address: string; name?: string }) => {
    setIsLoading(true);

    await onSubmit(values);

    setIsLoading(false);
    onCancel();
  };

  return (
    <BaseDialog onClose={onCancel}>
      <DialogHeader title={isUpdate ? 'Edit link' : 'Add link'} onClose={onCancel} />

      <Container>
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          <Controller
            control={control}
            name="address"
            render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
              <TextInput
                ref={ref}
                value={value}
                name={name}
                onChange={onChange}
                label="Address"
                placeholder="https://..."
                errorMessage={error?.message}
                required
              />
            )}
          />

          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
              <TextInput
                ref={ref}
                value={value}
                name={name}
                onChange={onChange}
                label="Name"
                errorMessage={error?.message}
              />
            )}
          />
        </form>
      </Container>

      <FooterWrapper>
        <DialogFooter
          actions={[
            <TextButton key="cancel" text="Cancel" onClick={onCancel} />,
            <ElevatedButton
              key="submit"
              isLoading={isLoading}
              disabled={isLoading || !formState.isValid}
              onClick={handleSubmit(onHandleSubmit)}
              text="Save"
            />,
          ]}
        />
      </FooterWrapper>
    </BaseDialog>
  );
};

const Container = styled.div`
  overflow-y: scroll;
  padding: 1.6rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    width: 48rem;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    input {
      font-size: 1.2rem;
      font-weight: 400;
    }

    // Instead of modifying the main component
    div:first-child {
      div {
        b {
          display: none;
        }
      }
    }

    div:last-child {
      div::after {
        content: '(Optional)';
        color: var(--color-grayscale-shuttle);
      }
    }
  }
`;

const FooterWrapper = styled.div`
  footer {
    div {
      gap: 0.8rem;
    }
  }

  button {
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0 1.6rem;
  }

  button:first-child {
    height: unset;
    border: 0.1rem solid var(--color-grayscale-light-slate);
  }
`;
