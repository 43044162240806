import { StoryboardService } from '../../../services/storyboard.service';

export const useStoryboard = (projectId: string) => {
  const storyboardService = new StoryboardService();

  ///////////////// Generate pdf /////////////////
  const fetchStoryboardAsPDF = async (storyboardId: string, options?: any) => {
    return storyboardService.getStoryboardAsPDF(projectId, storyboardId, options);
  };

  return {
    fetchStoryboardAsPDF,
  };
};
