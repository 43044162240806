import React, { Ref } from 'react';
import styled from 'styled-components';

import { NotificationCount } from '../notification-count/NotificationCount';

interface TitleWithNotificationsProps {
  title: string | undefined;
  defaultValue?: boolean;
  onClick?: () => void;
  notificationCount?: number;
  className?: string;
}

export const TitleWithNotifications = React.forwardRef<HTMLDivElement, TitleWithNotificationsProps>(
  ({ title, notificationCount = 0, onClick, defaultValue = true, className }, ref) => (
    <FileItemTitleContainer className={className}>
      <FileItemTitle ref={ref} defaultText={defaultValue}>
        {title ? title : '-'}
      </FileItemTitle>

      <FileItemTitleNotification>
        {notificationCount > 0 && <NotificationCount value={notificationCount} onClick={onClick} />}
      </FileItemTitleNotification>
    </FileItemTitleContainer>
  ),
);

TitleWithNotifications.displayName = 'TitleWithNotifications';

interface TitleProps {
  ref?: Ref<HTMLDivElement>;
  defaultText?: boolean;
}

export const FileItemTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FileItemTitle = styled.div<TitleProps>`
  font-family: Inter, sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  width: 100%;
  min-height: 2.4rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
  color: ${({ defaultText }) =>
    defaultText ? 'var(--card-subtitle-color)' : 'var(--card-subtitle-color-low)'};
`;

export const FileItemTitleNotification = styled.div`
  margin-left: 0.4rem;
`;
