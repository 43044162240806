import { api } from '../../../api';
import { entityPool } from '../../../core/engine/engine';
import { Team } from '../../../app/entities/team';

export const fetchTeams = async (projectId: string) => {
  const { data } = await api.get(`/projects/${projectId}/teams`);

  data.forEach((el: Team) => {
    const team = Object.assign(new Team(), el);
    entityPool.insert(team);
  });
};

export const fetchTeam = async (teamId: string) => {
  const { data } = await api.get(`/teams/${teamId}`);
  const team: Team = Object.assign(new Team(), data);

  entityPool.insert(team);

  return team;
};

type CreateTeamInput = {
  projectId: string;
  name: string;
  stepId?: string;
};

export const createTeam = async ({ projectId, name }: CreateTeamInput) => {
  const { data } = await api.post(`/projects/${projectId}/teams`, { name });
  const team = Object.assign(new Team(), data);
  entityPool.insert(team);

  return team;
};
