import React from 'react';
import styled from 'styled-components';

type CountDragPreviewProps = {
  count: number;
  text: string;
};

export const CountDragPreview: React.FC<CountDragPreviewProps> = ({ count, text }) => {
  return (
    <Container>
      <Count>{count}</Count>
      <Text>{text}</Text>
    </Container>
  );
};

const Container = styled.div`
  border-width: 0;
  border-radius: 0.6rem;
  width: 16rem;
  max-width: 16rem;
  height: 3.4rem;
  background-color: var(--color-secondary);
  color: var(--white-default);
  opacity: 1;
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
  align-items: center;
  padding: 0.8rem;
  cursor: grabbing;
`;

const Text = styled.div`
  color: var(--white-default);
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  overflow: hidden;
  white-spaces: nowrap;
  text-overflow: ellipsis;
`;

const Count = styled.div`
  background-color: var(--white-default);
  border-radius: 0.3rem;
  padding: 0.4rem;
  color: var(--color-secondary);
  width: 2.4rem;
  height: 2.4rem;
  line-height: 1.8rem;
  font-size: 1.4rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
`;
