import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { HeaderLeft } from './header-left';
import { BackButton } from './back-button';
import { StepStatusSelector } from '../../process/components/step-status-selector';
import { breakPoint } from '../../../app/theme';
import { useResponsive } from '../../../hooks/useResponsive';

import type { BackButtonProps } from '../models/types';

interface ScreenHeaderProps {
  stepId?: string;
  title: string;
  dueDate?: string;
  startDate?: string;
  actions?: React.ReactNode[];
  backButton?: BackButtonProps;
  canValidateStep?: boolean;
  breadcrumb?: React.ReactNode;
  menu?: React.ReactNode;
  handleUpdateTimeFrame?: (values: {
    dueDate?: string | undefined;
    startDate?: string | undefined;
  }) => void;
}

export const ScreenHeader: React.FC<ScreenHeaderProps> = observer(
  ({
    stepId,
    title,
    dueDate,
    startDate,
    actions,
    backButton,
    canValidateStep = true,
    breadcrumb,
    menu,
    handleUpdateTimeFrame,
  }) => {
    const { isDesktop } = useResponsive();

    const renderLeftComponent = () => {
      if (isDesktop) {
        if (breadcrumb) {
          return breadcrumb;
        } else if (backButton) {
          const { onClick: handleOnBackClick, label: backButtonLabel } = backButton;

          return <BackButton label={backButtonLabel!} handleOnClick={handleOnBackClick} />;
        }
      }

      return (
        <HeaderLeft
          title={title}
          dueDate={isDesktop ? dueDate : undefined}
          startDate={isDesktop ? startDate : undefined}
          onUpdate={handleUpdateTimeFrame!}
        />
      );
    };

    return (
      <Header>
        {renderLeftComponent()}

        <HeaderDocument>
          {actions && actions.length > 0 && actions.map((action) => action)}

          {isDesktop && <>{canValidateStep && stepId && <StepStatusSelector stepId={stepId} />}</>}

          {menu}
        </HeaderDocument>
      </Header>
    );
  },
);

const Header = styled.header`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin: 1.6rem 1.6rem;
  height: 3.2rem;
  cursor: default;

  @media screen and (min-width: ${breakPoint.medium}px) {
    margin: 1.6rem 2.4rem;
  }
`;

const HeaderDocument = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1.2rem;
`;
