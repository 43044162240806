import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { DownloadIcon, Share2Icon, TrashIcon } from '@radix-ui/react-icons';

import { LoaderContainer } from '../../../routes/project/components/step-container';
import { PageLoader } from '../../../app/components/page-loader';
import { FileList } from '../../../components/file-list/file-list';
import { createAsset } from '../../assets/store/assets.slice';
import { ScreenHeader } from '../../projects/components/screen-header';
import { ScreenContainer } from '../../projects/components/styled-project-components';
import { LocationBreadcrumb } from '../components/location-breadcrumb';
import { FilterButton } from '../../../routes/project/components/assets/filter-button';
import { SelectionProvider } from '../../../core/contexts/selection.context';
import { DownloadShareButton } from '../../assets/components/download-share-button';
import { download } from '../../../lib/utils/download';
import { ShareModal } from '../../share/components/share-modal';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { Location } from '../../../app/entities/location';

import type { ScreenProps } from '../../projects/models/types';
import { authStore } from '../../../core/stores/auth-store';
import { GridIcon } from '../../../components/icons/grid-icon';
import styled from 'styled-components';
import { StyledButton } from '../../../components/buttons/styled-buttons';
import { ListDisplayIcon } from '../../../components/icons/list-display-icon';
import { useResponsive } from '../../../hooks/useResponsive';
import { ActionBar } from '../../assets/components/action-bar';
import { SORTING_TYPES } from '../../../assets/enums/assetType.enum';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { Asset } from '../../../app/entities/asset';
import { AddAssetButton } from '../../../routes/project/components/assets/add-asset-button';
import { ConfigureLinkTypeAssetModal } from '../../process/components/configure-link-type-asset.modal';

export const LocationFilesScreen: React.FC<ScreenProps> = observer(
  ({ projectId, step, spaceId, isLoading }) => {
    const { locationId } = useParams() as { locationId: string };
    const navigate = useNavigate();
    const { isDesktop } = useResponsive();

    const [isDeleteAssetsModalOpen, setIsDeleteAssetsModalOpen] = useState(false);

    const currentMember = spaceId ? authStore.getCurrentMember(spaceId) : undefined;
    const userStepPreferences = currentMember?.preferences.stepsUI[step._id];

    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isCreateLinkModalOpen, setIsCreateLinkModalOpen] = useState(false);

    const location = Location.getOne(locationId)!;
    const fileIds = location.assets?.map((file: any) => file._id) || [];
    const fileIdsToDownload =
      location.assets?.filter((file) => file.type !== 'link')?.map((file: any) => file._id) || [];

    const sortBy = userStepPreferences?.order || SORTING_TYPES.UPLOADED_DATE;

    const handleAddFiles = async (files: File[]) => {
      for (const file of files) {
        await createAsset({
          parentType: 'location',
          file,
          locationId,
          name: file.name,
          size: file.size,
          type: 'file',
          stepId: step._id,
          fileType: file.type,
        });
      }
    };

    const handleFileNavigation = async (asset: string, assetId: string) => {
      const href = `./${asset}/${assetId}`;
      navigate(href);
    };

    const onSortBySelectList = (sortBy: string) => {
      currentMember?.changeStepOrder(step, sortBy);
    };

    const downloadAllAssets = async () => {
      if (!location.assets.length || !fileIdsToDownload.length) {
        return;
      }

      for (const id of fileIdsToDownload) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        download(id);
      }
    };

    const options = [
      {
        title: 'Share all',
        icon: <Share2Icon />,
        disabled: !fileIds.length,
        onSelect: () => setIsShareModalOpen(true),
      },
      {
        title: 'Download all',
        icon: <DownloadIcon />,
        disabled: !fileIdsToDownload.length,
        onSelect: downloadAllAssets,
      },
      {
        title: 'Delete all files',
        icon: <TrashIcon />,
        type: 'danger' as const,
        disabled: !fileIds.length,
        onSelect: () => setIsDeleteAssetsModalOpen(true),
      },
    ];

    const handleDeleteAssets = async () => {
      await Asset.deleteMany(fileIds);
    };

    const toggleDisplayLayout = () => {
      const newLayout = userStepPreferences?.displayLayout === 'list' ? 'grid' : 'list';
      currentMember?.updateStepLayout(step, newLayout);
    };

    const handleOnCreateLink = async (values: { address: string; name?: string }) => {
      await createAsset({
        parentType: 'location',
        locationId,
        type: 'link',
        stepId: step._id,
        url: values.address,
        name: values.name,
      });
    };

    const handleCreateNoteAsset = async () => {
      const { asset } = await createAsset({
        parentType: 'location',
        locationId,
        type: 'note',
        stepId: step._id,
        content: '',
        name: 'Untitled Note',
      });

      if (asset) navigate(`./file/${asset._id}`);
    };

    return (
      <SelectionProvider items={fileIds}>
        <ScreenHeader
          breadcrumb={
            <LocationBreadcrumb projectId={projectId} stepId={step._id} locationId={locationId!} />
          }
          menu={<DropdownMenu items={options} size="large" />}
          stepId={step._id}
          title={step.name}
          dueDate={step.dueDate}
          startDate={step.startDate}
          handleUpdateTimeFrame={(values) => step.update(values)}
          canValidateStep={false}
          actions={[
            isDesktop && (
              <DownloadShareButton
                key="download-share-files"
                spaceId={spaceId!}
                projectId={step.projectId}
              />
            ),
            <LayoutToggleButton key="change-layout-button" onClick={toggleDisplayLayout}>
              <span data-active={userStepPreferences?.displayLayout !== 'grid'}>
                <GridIcon />
              </span>

              <span data-active={userStepPreferences?.displayLayout === 'list'}>
                <ListDisplayIcon />
              </span>
            </LayoutToggleButton>,
            <FilterButton key="files-button" value={sortBy} onSelect={onSortBySelectList} />,
            <AddAssetButtonWrapper key="add-location-asset-button">
              <AddAssetButton
                onSelectFile={handleAddFiles}
                onSelectLink={() => setIsCreateLinkModalOpen(true)}
                onSelectNote={handleCreateNoteAsset}
              />
            </AddAssetButtonWrapper>,
          ]}
        />

        {isDeleteAssetsModalOpen && (
          <DeleteDialog
            title="Delete files"
            text="Are you sure you want to delete all the files in this step?"
            onCancel={() => setIsDeleteAssetsModalOpen(false)}
            onSubmit={handleDeleteAssets}
          />
        )}

        {isLoading ? (
          <LoaderContainer>
            <PageLoader />
          </LoaderContainer>
        ) : (
          <ScreenContainer>
            <FileList
              files={location.assets}
              sortBy={sortBy}
              onAddFiles={handleAddFiles}
              assetsRouteTo={handleFileNavigation}
              displayLayout={userStepPreferences?.displayLayout || 'grid'}
            />
          </ScreenContainer>
        )}

        {isShareModalOpen && (
          <ShareModal
            elementIds={fileIds}
            spaceId={spaceId!}
            projectId={projectId}
            onClose={() => setIsShareModalOpen(false)}
          />
        )}

        <ActionBar spaceId={spaceId!} projectId={step.projectId} />

        {isCreateLinkModalOpen && (
          <ConfigureLinkTypeAssetModal
            onSubmit={(values) => handleOnCreateLink(values)}
            onCancel={() => setIsCreateLinkModalOpen(false)}
          />
        )}
      </SelectionProvider>
    );
  },
);

const LayoutToggleButton = styled(StyledButton)`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 3.2rem;
  width: 6.4rem;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 100%;
    width: 3.2rem;
    transition: background-color 0.3s ease;

    svg {
      width: 1.6rem;
      height: 1.6rem;
    }

    &[data-active='true'] {
      background-color: var(--color-primary-crayola);

      svg {
        path {
          fill: white;
        }
      }
    }
  }
`;

const AddAssetButtonWrapper = styled.div`
  button {
    background-color: var(--color-primary-crayola);
    color: white;
  }
`;
