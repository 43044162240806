import { api } from '../../../api';
import { Contact } from '../../../app/entities/contact';
import { Frame } from 'lib/utils/transform-image';
import { upload } from '../../../core/services/file.service';
import { entityPool } from '../../../core/engine/engine';

export const fetchSpaceContacts = async (spaceId: string) => {
  const { data } = await api.get(`/spaces/${spaceId}/contacts`);

  data.forEach((el: any) => {
    const contact = Object.assign(new Contact(), el);
    entityPool.insert(contact);
  });
};

type CreateContactInput = {
  accountId: string;
  contactInfo: Partial<Contact> & {
    file?: {
      size: number;
      fileType: string;
    };
  };
  file?: Frame;
};

export const createContact = async (input: CreateContactInput) => {
  const dto = input.contactInfo;

  if (input.file) {
    dto.file = {
      size: input.file.size,
      fileType: input.file.type,
    };
  }
  const { data } = await api.post(`/spaces/${input.accountId}/contacts`, dto);

  if (input.file) {
    await upload(data.links.upload, input.file.data);
  }

  const contact = Object.assign(new Contact(), data);
  entityPool.insert(contact);

  return contact;
};

type ContactInput = {
  firstName?: string;
  lastName?: string;
};

type CreateBatchContactsInput = {
  spaceId: string;
  contacts: ContactInput[];
};

export const createBatchContacts = async ({ spaceId, contacts }: CreateBatchContactsInput) => {
  contacts = contacts.filter((el) => !!el.firstName?.trim());
  if (!contacts.length) return;

  const { data } = await api.post(`/spaces/${spaceId}/contacts/batch`, { contacts });

  data.forEach((el: any) => {
    const contact = Object.assign(new Contact(), el);
    entityPool.insert(contact);
  });
};
