import React from 'react';

export const Upload = () => (
  <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path
        d="M53.1328 58.7393H42.1422H39.1827H38.5435V43.9972H43.3649C44.5877 43.9972 45.3102 42.6077 44.5877 41.6073L35.2088 28.6298C34.6114 27.7961 33.3747 27.7961 32.7773 28.6298L23.3984 41.6073C22.6759 42.6077 23.3846 43.9972 24.6212 43.9972H29.4426V58.7393H28.8034H25.8439H13.1026C5.80793 58.3363 0 51.5141 0 44.1222C0 39.0229 2.76502 34.5766 6.86392 32.1729C6.48876 31.1586 6.29424 30.0748 6.29424 28.9354C6.29424 23.725 10.5043 19.5149 15.7148 19.5149C16.8402 19.5149 17.924 19.7095 18.9383 20.0846C21.9534 13.6931 28.4561 9.26074 36.0147 9.26074C45.7965 9.27464 53.8553 16.7638 54.7724 26.3094C62.2893 27.6016 68 34.5627 68 42.441C68 50.8611 61.4418 58.1557 53.1328 58.7393Z"
        fill="white"
      />
    </g>
  </svg>
);
