import { api } from 'api';
import { AxiosRequestConfig } from 'axios';
import { BufferToBlob } from '../../../lib/utils/BufferToBolb';
import { createFileName } from '../../../core/services/file.service';

import type { Step } from '../../../app/entities/step';

const config: AxiosRequestConfig = {
  responseType: 'arraybuffer',
  headers: {
    Accept: 'application/pdf',
  },
};

/**
 * Download shotlist as PDF
 */
export const downloadCallSheet = async (step: Step, projectName: string) => {
  try {
    const { data } = await api.get(`/pdf/callsheet/${step._id}`, config);

    const stepName = step.name !== 'Shooting day' ? `_${step.name}` : '';
    const formattedStepName = `#${step.order}${stepName}`.replaceAll(' ', '_');
    const fileName = createFileName(projectName, `Call_Sheet_${formattedStepName}.pdf`, true);
    BufferToBlob(data, fileName);
    return true;
  } catch (e) {
    // TODO end error to sentry
    return false;
  }
};
