import React from 'react';

export const ArrowLeft = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 5.68791L5.98796 11.6999L12 17.7119L13.062 16.6499L8.86796 12.4379H18.012V10.9619H8.86796L13.062 6.74991L12 5.68791Z"
      fill="#F2F3F5"
    />
  </svg>
);
