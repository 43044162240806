import React, { PropsWithChildren, useRef } from 'react';

import { CompareContext } from './compare.context';

export const CompareProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const player1Ref = useRef<HTMLVideoElement>(null);
  const player2Ref = useRef<HTMLVideoElement>(null);

  const togglePlay = async (
    currentPlayerRef: React.RefObject<HTMLVideoElement>,
    isPlaying: boolean,
  ) => {
    if (!player1Ref?.current || !player2Ref?.current) {
      return;
    }

    let otherPlayerNode: HTMLVideoElement = player1Ref.current;

    if (player1Ref.current.isSameNode(currentPlayerRef.current)) {
      otherPlayerNode = player2Ref.current;
    } else if (player2Ref.current.isSameNode(currentPlayerRef.current)) {
      otherPlayerNode = player1Ref.current;
    }

    if (isPlaying) {
      await otherPlayerNode.play();
    } else if (!currentPlayerRef.current!.ended) {
      otherPlayerNode.pause();
    }
  };

  const toggleMute = (currentPlayerRef: React.RefObject<HTMLVideoElement>, isMute: boolean) => {
    if (!player1Ref?.current || !player2Ref?.current) {
      return;
    }

    let otherPlayerNode: HTMLVideoElement = player1Ref.current;

    if (player1Ref.current.isSameNode(currentPlayerRef.current)) {
      otherPlayerNode = player2Ref.current;
    } else if (player2Ref.current.isSameNode(currentPlayerRef.current)) {
      otherPlayerNode = player1Ref.current;
    }

    if (isMute) {
      otherPlayerNode.muted = false;
    } else {
      otherPlayerNode.muted = true;
    }
  };

  const handleSeekTime = (time: number) => {
    if (player1Ref?.current) {
      player1Ref.current.currentTime = time;
    }
    if (player2Ref?.current) {
      player2Ref.current.currentTime = time;
    }
  };

  const handleRateChange = (rate: number) => {
    if (!player1Ref?.current || !player2Ref?.current) {
      return;
    }

    player1Ref.current.playbackRate = rate;
    player2Ref.current.playbackRate = rate;
  };

  const handleLoadStart = (currentPlayerRef: React.RefObject<HTMLVideoElement>) => {
    if (!player1Ref?.current || !player2Ref?.current) {
      return;
    }

    let otherPlayerNode: HTMLVideoElement = player1Ref.current;

    if (player1Ref.current.isSameNode(currentPlayerRef.current)) {
      otherPlayerNode = player2Ref.current;
    } else if (player2Ref.current.isSameNode(currentPlayerRef.current)) {
      otherPlayerNode = player1Ref.current;
    }

    if (otherPlayerNode.muted) {
      currentPlayerRef.current!.muted = false;
    } else {
      currentPlayerRef.current!.muted = true;
    }
  };

  return (
    <CompareContext.Provider
      value={{
        player1Ref,
        player2Ref,
        togglePlay,
        toggleMute,
        handleSeekTime,
        handleRateChange,
        handleLoadStart,
      }}
    >
      {children}
    </CompareContext.Provider>
  );
};
