import { AxiosResponse } from 'axios';
import { Api, api } from '../api';

export class StoryboardService {
  api: Api;

  constructor() {
    this.api = api;
  }

  getStoryboardAsPDF(projectId: string, storyboardId: string, options: any) {
    /**
     * We use a post method here to send the options in the body
     */
    return this.api
      .post(
        `/projects/${projectId}/storyboards/${storyboardId}/pdf`,
        {
          options,
        },
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'application/pdf',
          },
        },
      )
      .then(({ data }: AxiosResponse<any>) => {
        return data;
      });
  }
}
