import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { PopupMenuButton } from '../../assets/components/PopupMenuButton';
import { ToggleMenuItem } from '../../../components/toggle-menu/toggle-menu';
import type { Property } from '../models/storyboard-settings';
import { StoryBoardSettingsKeys } from '../../../app/entities/member';

type StoryboardPropertiesMenuProps = {
  properties: Property[];
  onToggle: (propertyId: string) => void;
  onShowAll: () => void;
  onHideAll: () => void;
  isDisabled?: boolean;
  userPreferences?: Record<StoryBoardSettingsKeys, boolean> | undefined;
};

export const StoryboardPropertiesMenu: React.FC<StoryboardPropertiesMenuProps> = observer(
  ({ properties, onToggle, onShowAll, onHideAll, isDisabled, userPreferences }) => {
    const shownProperties: Property[] = [];
    const hiddenProperties: Property[] = [];

    properties.forEach((property) =>
      userPreferences?.[property.id as keyof typeof userPreferences]
        ? shownProperties.push(property)
        : hiddenProperties.push(property),
    );

    const hasShownProperty = !!shownProperties.length;
    const hasHiddenProperty = !!hiddenProperties.length;

    return (
      <PopupMenuButton
        direction="left"
        items={[
          hasShownProperty && (
            <MenuHeader>
              <MenuTitle>Shown in storyboard</MenuTitle>
              <MenuButton onClick={onHideAll} disabled={isDisabled}>
                Hide all
              </MenuButton>
            </MenuHeader>
          ),
          ...shownProperties.map((property) => (
            <ToggleMenuItem
              key={property.id}
              label={property.name}
              isToggled={userPreferences?.[property.id as keyof typeof userPreferences] || true}
              name={property.id}
              onToggle={() => onToggle(property.id)}
              isDisabled={isDisabled}
            />
          )),
          hasHiddenProperty && hasShownProperty && <MenuDivider />,
          hasHiddenProperty && (
            <MenuHeader>
              <MenuTitle>Hidden in storyboard</MenuTitle>
              <MenuButton onClick={onShowAll} disabled={isDisabled}>
                Show all
              </MenuButton>
            </MenuHeader>
          ),
          ...hiddenProperties.map((property) => (
            <ToggleMenuItem
              key={property.id}
              label={property.name}
              isToggled={userPreferences?.[property.id as keyof typeof userPreferences] !== false}
              name={property.id}
              onToggle={() => onToggle(property.id)}
              isDisabled={isDisabled}
            />
          )),
        ]}
      />
    );
  },
);

const MenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: rgba(55, 53, 47, 0.65);
  margin: 0.6rem 1.6rem;
  min-width: 21.6rem;
`;

const MenuDivider = styled.div`
  margin: 0.8rem 1.6rem;
  height: 1px;
  background-color: #494e5a;
`;

const MenuTitle = styled.p`
  color: white;
  cursor: default;
`;

const MenuButton = styled.button`
  all: unset;
  color: var(--color-secondary);
  border-radius: 0.4rem;
  transition: background-color 0.1s ease-in-out;
  padding: 0.2rem 0.4rem;

  &:hover {
    cursor: pointer;
    background-color: var(--color-secondary-10);
  }

  &:disabled {
    color: var(--color-grey-5);
  }

  &:disabled:hover {
    background-color: transparent;
  }
`;
