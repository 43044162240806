import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { InputWithBackground } from '../../../components/input-with-background/InputWithBackground';
import { ElevatedButton } from '../../../components/buttons';
import { Select } from '../../../components/select/Select';
import { updateCurrentUser, updateCurrentUserAvatar } from '../user.slice';
import { formatPicture } from '../../../core/services/image.service';
import { DescriptionHeader } from '../components/description-header';
import { toastPool } from '../../toasts/models/toast-pool';
import { Toast } from '../../toasts/models/toast';
import { authStore } from '../../../core/stores/auth-store';
import { catchError } from '../../../core/catch-error';
import { ProfileAvatar } from '../../../components/profile-avatar/ProfileAvatar';

type FormValues = {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  location: string;
};

const options = [
  { value: 'Mr', label: 'Mr' },
  { value: 'Ms', label: 'Ms' },
  { value: 'Dr', label: 'Dr' },
  { value: 'Pr', label: 'Pr' },
];

export const ProfilePage: React.FC = observer(() => {
  const { t } = useTranslation('profile');
  const user = authStore.currentUser!;

  const { register, control, handleSubmit } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { ...user.toPOJO() },
  });

  const onHandleSubmit = async (values: any) => {
    const { title, firstName, lastName, phone, location } = values;

    await updateCurrentUser({ _id: user._id, title, firstName, lastName, phone, location })
      .then(() => {
        toastPool.insert(new Toast('Your settings are updated successfully'));
      })
      .catch((e) => {
        catchError(e);
      });
  };

  const handleProfilePhoto = async (file?: File) => {
    if (!file) {
      return;
    }

    const options = { width: 250, height: 250 };
    const frame = await formatPicture(file, options);
    updateCurrentUserAvatar({ file: frame }).catch((e) => {
      catchError(e);
    });
  };

  return (
    <Container>
      <DescriptionHeader title="Profile" description="Manage your profile" />
      <AvatarContainer>
        <ProfileAvatar
          placeholder="image"
          defaultUrl={user?.avatar}
          onChange={handleProfilePhoto}
        />
      </AvatarContainer>

      <form onSubmit={handleSubmit(onHandleSubmit)}>
        <FieldContainer>
          <Controller
            name={'title'}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                onSelect={({ target }) => field.onChange(target.value)}
                options={options}
                label={t('profile:title_label')}
              />
            )}
          />
        </FieldContainer>

        <FieldContainer>
          <InputWithBackground
            {...register('firstName', { required: true })}
            placeholder={t('profile:first_name_label')}
            label={t('profile:first_name_label')}
            required
          />
        </FieldContainer>

        <FieldContainer>
          <InputWithBackground
            {...register('lastName', { required: true })}
            placeholder={t('profile:last_name_label')}
            label={t('profile:last_name_label')}
            required
          />
        </FieldContainer>

        <FieldContainer>
          <InputWithBackground
            {...register('email', { required: true })}
            placeholder={t('profile:last_name_label')}
            label={t('profile:email_label')}
            required
            disabled
          />
        </FieldContainer>

        <FieldContainer>
          <InputWithBackground
            {...register('phone')}
            placeholder={t('profile:phone_label')}
            label={t('profile:phone_label')}
          />
        </FieldContainer>

        <FieldContainer>
          <InputWithBackground
            {...register('location')}
            placeholder={t('profile:location_label')}
            label={t('profile:location_label')}
          />
        </FieldContainer>

        <ButtonGroupContainer>
          <ElevatedButton text="Save" type="submit" />
        </ButtonGroupContainer>
      </form>
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  max-width: 64rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const AvatarContainer = styled.div`
  margin: 0 auto 3.6rem;
  position: relative;
`;

const ButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  border-top: 1px solid #31343c;
  padding-top: 2rem;
  width: 100%;
  max-width: 94.4rem;
`;

export const FieldContainer = styled.div`
  margin-bottom: 2.4rem;
`;
