export const getStorageRegionDisplayName = (region: string): string => {
  switch (region) {
    case 'eu-west':
      return 'Europe';
    case 'us-west':
      return 'North America';
    case 'brazil-south':
      return 'South America';
    default:
      return 'Custom';
  }
};
