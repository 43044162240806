import React from 'react';

export const Robot = () => {
  return (
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9999 0.750001C12.9999 0.335787 13.3357 0 13.7499 0C14.1641 0 14.4999 0.335787 14.4999 0.750002V7.00051H22.9999C24.1044 7.00051 24.9999 7.89594 24.9999 9.00051V12.0858C25.1563 12.0305 25.3246 12.0004 25.5 12.0004C26.3284 12.0004 27 12.672 27 13.5004V17.5004C27 18.3289 26.3284 19.0004 25.5 19.0004C25.3246 19.0004 25.1563 18.9703 24.9999 18.915V24.0006C24.9999 25.1051 24.1044 26.0006 22.9999 26.0006H3.99979C2.89522 26.0006 1.99979 25.1051 1.99979 24.0006V18.9151C1.84346 18.9704 1.67524 19.0004 1.5 19.0004C0.671575 19.0004 0 18.3289 0 17.5004V13.5004C0 12.672 0.671575 12.0004 1.5 12.0004C1.67524 12.0004 1.84346 12.0305 1.99979 12.0857V9.00051C1.99979 7.89594 2.89522 7.00051 3.99979 7.00051H12.9999V0.750001ZM9.00021 17.0016C10.6571 17.0016 12.0002 15.6585 12.0002 14.0016C12.0002 12.3448 10.6571 11.0016 9.00021 11.0016C7.34335 11.0016 6.0002 12.3448 6.0002 14.0016C6.0002 15.6585 7.34335 17.0016 9.00021 17.0016ZM20.9997 14.0016C20.9997 15.6585 19.6566 17.0016 17.9997 17.0016C16.3428 17.0016 14.9997 15.6585 14.9997 14.0016C14.9997 12.3448 16.3428 11.0016 17.9997 11.0016C19.6566 11.0016 20.9997 12.3448 20.9997 14.0016ZM18.0003 20.0029H9.00031V22.0029H18.0003V20.0029Z"
        fill="#D53075"
      />
    </svg>
  );
};
