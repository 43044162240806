import React from 'react';

export const Add = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.3529 32H5.64706C3.78752 32 2.38146 31.5292 1.42888 30.5875C0.476292 29.6458 0 28.2561 0 26.4184L0 5.58159C0 3.74389 0.476292 2.3542 1.42888 1.41252C2.38146 0.47084 3.78752 0 5.64706 0L26.3529 0C28.2125 0 29.6185 0.47084 30.5711 1.41252C31.5237 2.3542 32 3.74389 32 5.58159V26.4184C32 28.2561 31.5237 29.6458 30.5711 30.5875C29.6185 31.5292 28.2125 32 26.3529 32ZM14.7932 23.4306C15.0955 23.7673 15.5033 23.9356 16.0167 23.9356C16.5529 23.9356 16.9721 23.7701 17.2744 23.4391C17.5768 23.1081 17.7279 22.6686 17.7279 22.1208V17.6863H22.468C23.0042 17.6863 23.4463 17.5351 23.7942 17.2326C24.1422 16.9301 24.3161 16.5163 24.3161 15.9913C24.3161 15.4662 24.145 15.0496 23.8028 14.7414C23.4605 14.4332 23.0156 14.2791 22.468 14.2791H17.7279V9.58786C17.7279 9.02855 17.5768 8.5834 17.2744 8.25238C16.9721 7.92137 16.5529 7.75586 16.0167 7.75586C15.5033 7.75586 15.0955 7.92707 14.7932 8.2695C14.4908 8.61193 14.3397 9.05138 14.3397 9.58786V14.2791H9.6338C9.07479 14.2791 8.62417 14.4332 8.28192 14.7414C7.93968 15.0496 7.76855 15.4662 7.76855 15.9913C7.76855 16.5163 7.94253 16.9301 8.29048 17.2326C8.63843 17.5351 9.0862 17.6863 9.6338 17.6863H14.3397V22.1208C14.3397 22.6572 14.4908 23.0938 14.7932 23.4306Z"
      fill="#367BFF"
    />
  </svg>
);
