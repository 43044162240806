import { computed, makeObservable, observable } from 'mobx';

import { Model } from '../../core/engine/model';
import { ManyToOne } from '../../core/engine/decorators';
import { getFullName } from '../../lib/utils/get-full-name';

import type { Character } from './character';
import type { Contact } from './contact';

export class Candidate extends Model {
  contactId: string;
  approved?: boolean;
  approvedAt: string;
  assetId?: string;
  avatar?: string;
  characterId: string;
  firstName: string;
  lastName: string;
  rejected?: boolean;
  rejectedAt?: string;
  score: number;

  votes: any; //Change when we have a vote model

  @ManyToOne('candidates')
  character: Character;

  @ManyToOne()
  contact?: Contact;

  get fullName() {
    return getFullName(this);
  }

  constructor() {
    super('candidates');

    makeObservable(this, {
      contactId: true,
      approved: true,
      approvedAt: true,
      assetId: true,
      avatar: true,
      characterId: true,
      firstName: true,
      lastName: true,
      rejected: true,
      rejectedAt: true,
      score: true,
      votes: true,
      fullName: computed,
      contact: observable,
    });
  }

  toPOJO(): Record<string, any> {
    return {
      _id: this._id,
      contactId: this.contactId,
      approved: this.approved,
      approvedAt: this.approvedAt,
      assetId: this.assetId,
      avatar: this.avatar,
      characterId: this.characterId,
      firstName: this.firstName,
      lastName: this.lastName,
      rejected: this.rejected,
      rejectedAt: this.rejectedAt,
      score: this.score,
      votes: this.votes,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  static getOne(id: string): Candidate {
    return Model.getOne(id) as Candidate;
  }

  static getAll(): Candidate[] {
    return Model.getAll().filter((model) => model instanceof Candidate) as Candidate[];
  }
}
