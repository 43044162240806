import React from 'react';
import styled from 'styled-components';

import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { Project, ProjectPriority } from '../../../app/entities/project';
import { UrgentPriorityIcon } from '../../../components/icons/urgent-priority';

type PrioritySelectorProps = {
  onChange?: (newStatus: ProjectPriority) => void;
  priority?: ProjectPriority;
  isViewOnly?: boolean;
};

export const PrioritySelector: React.FC<PrioritySelectorProps> = ({
  onChange,
  priority = ProjectPriority.NO_PRIORITY,
  isViewOnly,
}) => (
  <>
    {isViewOnly ? (
      <PrioritySelectorTrigger data-disabled>
        <PriorityIcon priority={priority} />
        <PriorityTitle>{Project.PriorityMapper[priority].title}</PriorityTitle>
      </PrioritySelectorTrigger>
    ) : (
      <DropdownMenu
        items={Project.PriorityArray.map(([key, { title }]) => ({
          title,
          onSelect: () => onChange?.(Number(key) as ProjectPriority),
          icon: <PriorityIcon priority={Number(key) as ProjectPriority} />,
        }))}
        align="start"
        trigger={
          <PrioritySelectorTrigger>
            <PriorityIcon priority={priority} />
            <PriorityTitle>{Project.PriorityMapper[priority].title}</PriorityTitle>
          </PrioritySelectorTrigger>
        }
      />
    )}
  </>
);

const PriorityIcon = ({ priority }: { priority: ProjectPriority }) => (
  <Icon>
    {priority !== ProjectPriority.URGENT ? (
      <>
        <span data-priority={`${priority}`} />
        <span data-priority={`${priority}`} />
        <span data-priority={`${priority}`} />
      </>
    ) : (
      <UrgentPriorityIcon />
    )}
  </Icon>
);

const Icon = styled.div`
  display: flex;
  gap: 0.2rem;
  align-items: end;

  & span {
    width: 0.3rem;
    height: 0.6rem;
    border-radius: 0.1rem;
    background-color: #31343c;

    &:nth-child(1) {
      &[data-priority='1'],
      &[data-priority='2'],
      &[data-priority='3'] {
        background-color: #7a8296;
      }
    }

    &:nth-child(2) {
      height: 0.9rem;

      &[data-priority='2'],
      &[data-priority='3'] {
        background-color: #7a8296;
      }
    }

    &:nth-child(3) {
      height: 1.2rem;

      &[data-priority='3'] {
        background-color: #7a8296;
      }
    }
  }
`;

const PrioritySelectorTrigger = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  cursor: pointer;
  padding: 0.4rem 0.8rem;
  background-color: #25272d;
  border-radius: var(--border-radius-medium);

  &[data-disabled] {
    cursor: default;
  }
`;

const PriorityTitle = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: left;
  color: #fafafa;
`;
