export type AssetsType = 'file' | 'folder';

export const FILES_TYPES = {
  VIDEO: 'video',
  AUDIO: 'audio',
  IMAGE: 'image',
  APPLICATION: 'application',
  ZIP: 'zip',
};

export const SORTING_TYPES = {
  UPLOADED_DATE: 'Uploaded Date',
  A_TO_Z: 'A to Z',
  Z_TO_A: 'Z to A',
  FILTER_TYPE: 'Filter type',
};

type WithCreatedAt = {
  createdAt: string | Date;
};

type WithName = {
  name: string;
};

type WithType = {
  type: string;
};

export const sortingFunction = {
  [SORTING_TYPES.UPLOADED_DATE]: (a: WithCreatedAt, b: WithCreatedAt) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  },
  [SORTING_TYPES.A_TO_Z]: (a: WithName, b: WithName) => {
    return a.name?.localeCompare(b.name) || 0;
  },
  [SORTING_TYPES.Z_TO_A]: (a: WithName, b: WithName) => {
    return b.name?.localeCompare(a.name) || 0;
  },
  [SORTING_TYPES.FILTER_TYPE]: (a: WithType, b: WithType) => {
    return a.type?.localeCompare(b.type) || 0;
  },
};
