import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { ScreenContainer } from '../../projects/components/styled-project-components';
import { Tabs } from '../../../components/tabs/Tabs';
import { CallsheetTab } from '../components/callsheet-tab';
import { ShootingDayHeader } from '../components/shooting-day-screen.header';
import { StepDrawer } from '../../projects/components/step.drawer';

import type { Project } from '../../../app/entities/project';
import type { Step } from '../../../app/entities/step';

type ViewType = 'callsheet';

const Views: {
  [key in ViewType]: React.FC<any>;
} = {
  callsheet: CallsheetTab,
};

interface ShootingDayScreenProps {
  project: Project;
  step: Step;
}

export const ShootingDayScreen: React.FC<ShootingDayScreenProps> = observer(({ project, step }) => {
  const [selectedTab, setSelectedTab] = useState<'callsheet' | 'dailies'>('callsheet');

  const View = Views[selectedTab as ViewType];

  const tabs = [
    {
      key: 'callsheet',
      label: 'Callsheet',
    },
    {
      key: 'dailies',
      label: 'Dailies',
    },
  ];

  return (
    <React.Fragment>
      <ShootingDayHeader step={step} project={project} selectedTab={selectedTab} />

      <StepDrawer step={step} />

      <ScreenContainer>
        {window.flags?.dailies ? (
          <Tabs
            tabs={tabs}
            onChange={(value) => setSelectedTab(value as 'callsheet' | 'dailies')}
            selectedTab={selectedTab}
          >
            {View && <View project={project} step={step} />}
          </Tabs>
        ) : (
          <CallsheetTab project={project} step={step} />
        )}
      </ScreenContainer>
    </React.Fragment>
  );
});
