import React from 'react';
import styled, { keyframes } from 'styled-components';

type SkeltonProps = {
  width: number; // unit: rem
  height: number; // unit: rem
};

const skeltonAnimation = keyframes`
  from {
    left: -20px;
  } 
  to {
    left: 100%;
  }
`;

export const Skeleton = styled.span<SkeltonProps>`
  display: inline-block;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  height: ${({ height }) => height}rem;
  width: ${({ width }) => width}rem;
  background: var(--color-grayscale-arsenic);
  border-radius: 0.4rem;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${skeltonAnimation};
    animation-timing-function: linear;

    background-attachment: fixed;
    background-image: linear-gradient(
      to right,
      var(--color-grayscale-arsenic) 0%,
      var(--color-grayscale-trout) 30%,
      var(--color-grayscale-shuttle) 50%,
      var(--color-grayscale-trout) 80%,
      var(--color-grayscale-arsenic) 100%
    );
  }
`;
