import React from 'react';

export const Revoke = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5118_10323)">
      <path d="M12 20C7.5816 20 4 16.4184 4 12C4 7.5816 7.5816 4 12 4C16.4184 4 20 7.5816 20 12C20 16.4184 16.4184 20 12 20ZM12 10.8688L9.7376 8.6056L8.6056 9.7376L10.8688 12L8.6056 14.2624L9.7376 15.3944L12 13.1312L14.2624 15.3944L15.3944 14.2624L13.1312 12L15.3944 9.7376L14.2624 8.6056L12 10.8688Z" />
    </g>
    <defs>
      <clipPath id="clip0_5118_10323">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
