type Relation = {
  type: 'many-to-one' | 'one-to-one';

  /** The name of the property on the decorated object **/
  key: string;

  /** The name of the property in the target object **/
  foreignKey?: string;
};

export function OneToMany(foreignKey?: string) {
  return function (target: any, key: string) {
    target.relations ??= [];
    target.relations.push({
      type: 'one-to-many',
      key,
      foreignKey,
    });
  };
}

export function OneToOne(foreignKey?: string) {
  return function (target: any, key: string) {
    target.relations ??= [];
    target.relations.push({
      type: 'one-to-one',
      key,
      foreignKey,
    });
  };
}

export function ManyToOne(foreignKey?: string) {
  return function (target: any, key: string) {
    target.relations ??= [];
    target.relations.push({
      type: 'many-to-one',
      key,
      foreignKey,
    });
  };
}

/**
 * Transform an array of ids to an array of entities
 * @param foreignKey
 * @constructor
 */
export function ManyToMany(foreignKey?: string) {
  return function (target: any, key: string) {
    target.relations ??= [];
    target.relations.push({
      type: 'many-to-many',
      key,
      foreignKey,
    });
  };
}
