import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Share2Icon } from '@radix-ui/react-icons';

import { AssetViewerScreen } from '../../assets/screens/asset-viewer.screen';
import { LocationBreadcrumb } from '../components/location-breadcrumb';
import { ScreenHeader } from '../../projects/components/screen-header';
import { ElevatedButton } from '../../../components/buttons';
import { ShareModal } from '../../share/components/share-modal';

import type { ScreenProps } from '../../projects/models/types';
import { Asset } from '../../../app/entities/asset';
import { fetchChildren } from '../../assets/store/assets.slice';

export const LocationAssetViewerScreen: React.FC<ScreenProps> = observer(
  ({ step, projectId, spaceId }) => {
    const { locationId, assetId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);

    const asset = Asset.getOne(assetId!)!;

    useEffect(() => {
      if (asset?.type === 'version_stack') {
        fetchChildren({ assetId: asset._id }).then(() => setIsLoading(false));
      } else {
        setIsLoading(false);
      }
    }, [assetId, asset]);

    if (!asset) {
      return <div />; // TODO: handle display file not found placeholder
    }

    const handleOpenShareModal = async () => {
      setIsShareModalOpen(true);
    };

    return (
      <React.Fragment>
        <ScreenHeader
          breadcrumb={
            <LocationBreadcrumb
              projectId={projectId}
              stepId={step._id}
              locationId={locationId!}
              assetId={assetId}
            />
          }
          stepId={step._id}
          title={step.name}
          dueDate={step.dueDate}
          startDate={step.startDate}
          handleUpdateTimeFrame={(values) => step.update(values)}
          actions={[
            <React.Fragment key="share-button">
              {asset && (
                <ElevatedButton onClick={handleOpenShareModal} text="Share" icon={<Share2Icon />} />
              )}
            </React.Fragment>,
          ]}
          canValidateStep={false}
        />

        {!isLoading && <AssetViewerScreen asset={asset} />}

        {asset && isShareModalOpen && (
          <ShareModal
            title={`Share ${asset.name}`}
            elementIds={[asset._id]}
            spaceId={spaceId!}
            projectId={step.projectId}
            onClose={() => setIsShareModalOpen(false)}
          />
        )}
      </React.Fragment>
    );
  },
);
