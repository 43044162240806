import React, { useEffect } from 'react';

import { SettingsMenu } from './settings-menu';
import { uiStore } from '../../../core/stores/ui-store';

const menu = [
  {
    key: 'general',
    label: 'General',
    href: `../settings/general`,
  },
  {
    key: 'billings',
    label: 'Billing',
    href: `../settings/billings`,
  },
  {
    key: 'plans',
    label: 'Plans',
    href: `../settings/plans`,
  },
  {
    key: 'users',
    label: 'Users',
    href: `../settings/users`,
  },
  {
    key: 'storage',
    label: 'Storage',
    href: `../settings/storage`,
  },
];

export const MobileSettingsNavigation = () => {
  useEffect(() => {
    uiStore.pageTitle = {
      label: 'Space settings',
    };

    uiStore.showBack = false;
  }, []);

  return <SettingsMenu items={menu} />;
};
