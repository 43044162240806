import React, { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { createSnapModifier, restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  DragCancelEvent,
  MouseSensor,
  useSensor,
  useSensors,
  TouchSensor,
  DragOverEvent,
  DragMoveEvent,
} from '@dnd-kit/core';
import { GanttChartContext } from '../context/gantt-chart.context';

export const DndContextWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const { currentStripRef, setCurrentStrip, setCurrentItem, resizingLayerRef } =
    useContext(GanttChartContext);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(TouchSensor),
  );

  const handleDragStart = (event: DragStartEvent) => {
    if (!resizingLayerRef?.current) {
      return;
    }
    resizingLayerRef.current.style.pointerEvents = 'auto';
  };

  const handleDragCancel = (event: DragCancelEvent) => {
    if (!resizingLayerRef?.current) {
      return;
    }
    resizingLayerRef.current.style.pointerEvents = 'none';
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    if (resizingLayerRef?.current) {
      resizingLayerRef.current.style.pointerEvents = 'none';
    }

    const { active, delta } = event;

    const daysDelta = Math.round(delta.x / 48);

    if (!daysDelta) {
      return;
    }

    const { item } = active.data.current || {};

    if (item) {
      let startDate;
      let dueDate;

      if (item.startDate) {
        const date = new Date(item.startDate);
        startDate = date.setDate(date.getDate() + daysDelta);
        startDate = date;
      }

      if (item.dueDate) {
        const date = new Date(item.dueDate);
        date.setDate(date.getDate() + daysDelta);
        dueDate = date;
      }

      if (!currentStripRef?.current?.parentElement) {
        return;
      }

      const parentStyle = currentStripRef.current.parentElement.style;

      const currentGridColumnStart = parseInt(parentStyle.gridColumnStart, 10);
      parentStyle.gridColumnStart = `${currentGridColumnStart + daysDelta}`;

      setCurrentStrip?.(null);
      setCurrentItem?.(null);
      await item.onItemUpdate?.({ startDate, dueDate });
    }
  };

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToHorizontalAxis, createSnapModifier(48)]}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      {children}
    </DndContext>
  );
};
