import React from 'react';
import styled from 'styled-components';
import { breakPoint } from '../theme';

interface MenuItemProps {
  id: string;
  name: string;
  current: boolean;
  notifications?: number;
  icon: React.ReactNode;
  onClick?: () => void;
  isDisabled?: boolean;
}

export const SpaceMenuItem: React.FC<MenuItemProps> = ({
  id,
  name,
  notifications,
  current,
  icon,
  onClick,
  isDisabled,
}) => (
  <Item id={id} data-disabled={isDisabled} data-active={current} onClick={onClick}>
    <Icon current={current}>{icon}</Icon>

    <Name current={current}>{name}</Name>

    <div>{notifications && <Notification>{notifications}</Notification>}</div>
  </Item>
);

interface ContainerProps {
  current?: boolean;
}

const Item = styled.div<ContainerProps>`
  width: 100%;
  transition: all 0.1s ease-in;
  user-select: none;
  display: flex;
  align-items: center;
  color: var(--color-grayscale-shuttle, #626878);
  gap: 0.8rem;

  &[data-active='true'] {
    color: var(--color-grayscale-anti-flash-white, #f2f3f5);
  }

  @media (max-width: ${breakPoint.medium - 1}px) {
    border-radius: 0.4rem;
    cursor: pointer;

    &:hover {
      background-color: var(--color-grayscale-tuna);
      color: var(--color-grayscale-anti-flash-white, #f2f3f5);

      svg {
        color: var(--color-grayscale-anti-flash-white, #f2f3f5);
        fill: var(--color-grayscale-manatee, #959bab);
      }
    }

    &[data-active='true'] {
      background: var(--color-grayscale-tuna);
    }
  }

  @media (min-width: ${breakPoint.medium}px) {
    padding: 0;
    flex-direction: column;
    gap: 0.4rem;
  }

  &[data-disabled='true'] {
    display: none;
  }
`;

const Icon = styled.div<ContainerProps>`
  color: var(--text-color-white);
  font-size: 1.6rem;
  font-weight: 500;
  height: 3.2rem;
  width: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  cursor: pointer;

  @media (min-width: ${breakPoint.medium}px) {
    background: ${({ current }) =>
      current ? 'var(--color-grayscale-tuna, #31343c)' : 'transparent'};
  }

  svg {
    color: ${({ current }) =>
      current
        ? 'var(--color-grayscale-anti-flash-white, #f2f3f5)'
        : 'var(--color-grayscale-shuttle, #626878)'};
    fill: ${({ current }) =>
      current
        ? 'var(--color-grayscale-manatee, #959bab)'
        : 'var(--color-grayscale-arsenic, #3c404a)'};
  }

  &:hover {
    background: var(--color-grayscale-tuna, #31343c);
    color: var(--color-grayscale-anti-flash-white, #f2f3f5);

    svg {
      color: var(--color-grayscale-anti-flash-white, #f2f3f5);
      fill: var(--color-grayscale-manatee, #959bab);
    }
  }
`;

const Name = styled.div<ContainerProps>`
  font-size: 1.2rem;
  font-weight: 500;
  font-family: inherit;
`;

const Notification = styled.div`
  color: var(--text-color-white);
  background: var(--color-primary);
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0.4rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1.6rem;
  height: 1.6rem;
`;
