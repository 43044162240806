import React from 'react';
import styled from 'styled-components';

import { Switch } from '../../../../components/toggle/Switch';

const cycles = {
  month: false,
  year: true,
};

type PlanCycleSwitchProps = {
  cycle: 'month' | 'year';
  setCycle: (cycle: 'month' | 'year') => void;
};

export const PlanCycleSwitch: React.FC<PlanCycleSwitchProps> = ({ cycle = 'year', setCycle }) => {
  const handleToggle = (value: boolean) => {
    if (value) {
      setCycle('year');
    } else {
      setCycle('month');
    }
  };

  return (
    <Container>
      <Label>Monthly</Label>

      <Switch onToggle={handleToggle} isToggled={cycles[cycle]} size="medium" grayscale />

      <Label>Yearly</Label>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;

const Label = styled.span`
  color: var(--color-grayscale-white);
  font-size: 1.6rem;
  font-weight: 400;
`;
