import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

type TextButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  text: string;
};

export const TextButton: React.FC<TextButtonProps> = ({ text, ...buttonProps }) => {
  return <Button {...buttonProps}>{text}</Button>;
};

const Button = styled.button`
  min-width: 6.4rem;
  height: 3.2rem;
  line-height: 3.2rem;
  padding: 0 0.8rem;
  color: #8a8f98;
  font-weight: 400;
  text-align: center;
  border-radius: 0.4rem;
  transition: all 0.1s ease-in;
  user-select: none;
  background: transparent;
  border: none;
  font-size: 1.6rem;

  &:hover {
    cursor: pointer;
    background-color: var(--button-bgcolor-default-hover);
  }

  &:focus {
    background-color: var(--button-bgcolor-default-focus);
  }

  &:active {
    background-color: var(--button-bgcolor-default-active);
  }
`;
