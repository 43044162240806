import { computed, makeObservable, observable } from 'mobx';

import { ManyToOne, OneToOne } from '../../core/engine/decorators';
import { Model } from '../../core/engine/model';
import { catchError } from '../../core/catch-error';
import { getFullName } from '../../lib/utils/get-full-name';
import { getNameInitials } from '../../lib/utils/get-name-initials';
import { Invitation } from './invitation';
import { Member } from './member';

import type { Project } from './project';

type Position = {
  _id: string;
  name: string;
  roleId: string;
  departmentId: string;
};

export class Collaborator extends Model {
  email: string = '';

  firstName?: string = '';

  lastName?: string = '';

  avatar?: string = '';

  /** @deprecated */
  position?: string;

  /** @deprecated */
  positions: Position[];

  projectId: string;

  memberId?: string;

  invitationId?: string;

  @OneToOne('collaborator')
  member?: Member | Invitation;

  @ManyToOne('collaborators')
  project: Project | null = null;

  constructor() {
    super('collaborators');

    makeObservable(this, {
      email: observable,
      isMember: computed,
      member: observable,
    });
  }

  get isMember() {
    return this.member instanceof Member;
  }

  get fullName() {
    return getFullName(this) || this.email;
  }

  get initials() {
    const initials = getNameInitials(this);

    if (initials === '#') {
      return getNameInitials(this.email);
    }

    return initials;
  }

  async promote(): Promise<void> {
    try {
      if (!this.member || this.member instanceof Invitation) {
        return;
      }

      await this.member.promote();
    } catch (e) {
      catchError(e);
    }
  }

  delete() {
    return super.delete(`/projects/${this.projectId}/collaborators/${this._id}`);
  }

  toPOJO(): Record<string, any> {
    return {};
  }

  static getAll() {
    return Model.getAll().filter((model) => model.$name === 'collaborators') as Collaborator[];
  }

  static getByMemberId(memberId: string) {
    return Collaborator.getAll().filter((collaborator) => collaborator.memberId === memberId);
  }
}
