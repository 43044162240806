import React from 'react';
import { observer } from 'mobx-react-lite';

import { IconButton } from '../../../components/buttons';
import { IconButtonWrapper } from '../../../components/buttons/icon-button';
import { MapIcon } from '../../../components/icons/map-icon';
import { formatAddress } from '../../../core/services/address.service';
import { CollapsibleItem } from '../../../components/collapsible-item/collapsible-item';
import { EmptyTablePlaceholder } from '../../../components/table/empty-table-placeholder';
import {
  Header,
  Title,
  TableContainer,
  RowTitle,
  RowSubtitle,
  RowHeader,
  CellLabel,
  HorizontalCell,
  CellContent,
  RowContainer,
  VerticalCell,
} from './styled-mobile-table';

import type { Location } from '../../../app/entities/location';

type MobileLocationsTableProps = {
  locations: (Omit<Location, 'update' | 'toPOJO' | 'save' | 'delete' | 'remove'> & {
    parking?: string;
  })[];
  searchLocationOnMaps: (address: string) => void;
};

export const MobileLocationsTable: React.FC<MobileLocationsTableProps> = observer(
  ({ locations, searchLocationOnMaps }) => (
    <div>
      <Header>
        <Title>Locations</Title>
      </Header>

      <TableContainer>
        {!locations?.length && (
          <EmptyTablePlaceholder description="No locations set in the shots of this shooting day." />
        )}

        {locations?.map((location) => {
          const address = location.address && formatAddress(location.address);
          const isSearchable = !!(
            (location.address?.line1 || location.address?.line2) &&
            location.address?.city
          );

          return (
            <CollapsibleItem
              key={location._id}
              title={
                <RowHeader>
                  <RowTitle>{location.name}</RowTitle>

                  {address && <RowSubtitle>{address}</RowSubtitle>}
                </RowHeader>
              }
              isTransparent
            >
              <RowContainer>
                <VerticalCell>
                  <CellLabel>Contact Person</CellLabel>

                  {location.contact && <CellContent>{location.contact}</CellContent>}
                </VerticalCell>

                <VerticalCell>
                  <CellLabel>Parking Instructions</CellLabel>

                  {location.parking && <CellContent>{location.parking}</CellContent>}
                </VerticalCell>

                {isSearchable && address && (
                  <HorizontalCell>
                    <CellLabel>Actions</CellLabel>

                    <IconButtonWrapper>
                      <IconButton
                        icon={<MapIcon />}
                        disabled={!isSearchable}
                        onClick={() => {
                          searchLocationOnMaps(address);
                        }}
                      />
                    </IconButtonWrapper>
                  </HorizontalCell>
                )}
              </RowContainer>
            </CollapsibleItem>
          );
        })}
      </TableContainer>
    </div>
  ),
);
