import React from 'react';

const CloudAndWindy = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4776 14.912V10.919H0.551559C-0.18513 9.03924 -0.183807 6.95041 0.555264 5.07154C1.29433 3.19268 2.71649 1.66275 4.53646 0.788636C6.35642 -0.0854754 8.4396 -0.239114 10.3681 0.358537C12.2966 0.956189 13.9278 2.26093 14.9346 4.01104C15.6869 3.86634 16.4611 3.8804 17.2076 4.05231C17.9542 4.22423 18.6566 4.55021 19.2698 5.00937C19.8831 5.46853 20.3936 6.05074 20.7688 6.71869C21.144 7.38663 21.3754 8.12559 21.4484 8.88819C21.5214 9.6508 21.4342 10.4202 21.1925 11.1472C20.9508 11.8742 20.5599 12.5426 20.0449 13.1097C19.5298 13.6769 18.902 14.1301 18.2016 14.4405C17.5012 14.7508 16.7437 14.9115 15.9776 14.912H12.4786H12.4776ZM4.47756 16.912H14.4776V18.912H4.47756V16.912ZM0.47756 12.912H10.4776V14.912H0.47756V12.912Z"
        fill="#AFB4C0"
      />
    </svg>
  );
};

export default CloudAndWindy;
