import React, { createContext } from 'react';
import { ChartItem, DayProps } from '../types';

type GanttChartContextProps = {
  items: ChartItem[];
  setItems?: (items: ChartItem[]) => void;
  chartStartDate?: Date | undefined;
  chartEndDate?: Date | undefined;
  setCurrentStrip?: (node: HTMLDivElement | null) => void;
  currentStripRef?: React.MutableRefObject<HTMLDivElement | null>;
  setCurrentItem?: (item: ChartItem | null) => void;
  currentItem?: React.MutableRefObject<ChartItem | null>;
  resizingSide?: React.MutableRefObject<'left' | 'right' | undefined>;
  setResizingSide?: (side: 'left' | 'right' | undefined) => void;
  resizeStartOffset?: React.MutableRefObject<number>;
  setResizeStartOffset?: (side: number) => void;
  datesFullRange?: DayProps[];
  datesRangeByMonths?: { [key: string]: DayProps[] };
  resizingLayerRef?: React.MutableRefObject<HTMLDivElement | null>;
  handleItemsCollapse?: (parentId: string, isCollapsed?: boolean) => void;
  dateTrackerLabelRef?: React.MutableRefObject<HTMLDivElement | null>;
  sidebarRef?: React.MutableRefObject<HTMLDivElement | null>;
  stripIndicatorsListRef?: React.MutableRefObject<(HTMLDivElement | null)[]>;
  todayIndex?: number;
  extendStartRange?: (startOffset: number) => void;
  extendEndRange?: (endOffset: number) => void;
};

export const GanttChartContext = createContext<GanttChartContextProps>({
  items: [],
});
