import React, { useMemo } from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';

import { useResponsive } from '../../../../hooks/useResponsive';
import { MembersTable } from './members-table';
import { MobileMembersTable } from './members-table.mobile';

import type { Step } from '../../../../app/entities/step';
import type { TeamContact } from '../../../../features/teams/models/team';
import type { DepartmentWithContacts } from '../../../../lib/utils/generate-departments-objects-array';

export type MemberListProps = {
  contacts?: TeamContact[];
  departments?: DepartmentWithContacts[];
  protectedTeamName?: string;
  onDelete?: (contactId: string) => void;
  onToggleHide?: (contactId: string) => void;
  onUpdate?: (contactId: string, value: Partial<TeamContact>) => void;
  onMetaUpdate?: (
    contactId: string,
    data: Omit<Exclude<Step['contactsMetaData'], undefined>[number], 'contactId'>,
  ) => void;
  meta?: Step['contactsMetaData'];
  defaultCallTime?: { hour: number; minute: number };
  title?: string;
  actions?: React.ReactNode;
  placeholder?: React.ReactNode;
};

export const MembersList: React.FC<MemberListProps> = observer(
  ({
    contacts,
    departments,
    onDelete,
    onToggleHide,
    onUpdate,
    onMetaUpdate,
    meta,
    protectedTeamName,
    defaultCallTime,
    title,
    actions,
    placeholder,
  }) => {
    const { isDesktop } = useResponsive();

    const getDepartmentName = (departmentId: string) => {
      const department = departments?.find((department) => department._id === departmentId);

      return department?.name;
    };

    const contactsWithMeta = useMemo(() => {
      const metaData: any = {};

      meta?.forEach((meta) => {
        // remove contactId from meta since we don't need it in the contact object
        const { contactId, ...props } = meta;
        metaData[meta.contactId] = props;
      });

      return computed(() => {
        const contactsWithMetaData = contacts?.map((contact) => {
          return {
            ...contact,
            ...metaData[contact._id],
          };
        });

        contactsWithMetaData?.sort((a) => {
          return a.isHidden ? 1 : -1;
        });

        return contactsWithMetaData;
      });
    }, [contacts, meta]).get();

    return (
      <>
        {isDesktop ? (
          <MembersTable
            contacts={contactsWithMeta}
            getDepartmentName={getDepartmentName}
            departments={departments}
            onDelete={onDelete}
            onToggleHide={onToggleHide}
            onUpdate={onUpdate}
            onMetaUpdate={onMetaUpdate}
            protectedTeamName={protectedTeamName}
            defaultCallTime={defaultCallTime}
            placeholder={placeholder}
          />
        ) : (
          <MobileMembersTable
            title={title}
            actions={actions}
            contacts={contactsWithMeta}
            getDepartmentName={getDepartmentName}
            placeholder={placeholder}
            onDelete={onDelete}
            onToggleHide={onToggleHide}
            defaultCallTime={defaultCallTime}
            showDepartment={!protectedTeamName && !!departments}
            showCallTime={!!onMetaUpdate}
          />
        )}
      </>
    );
  },
);
