import React, { Ref, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { KeyboardKeys } from '../../assets/enums/keyboard-keys.enum';

interface TextInputProps {
  value: string;
  label?: string | null;
  onChange: (value: string) => void;
  onFocus?: (e: any) => void;
  required?: boolean;
  placeholder?: string | null;
  autocomplete?: string;
  name?: string;
  defaultValue?: string;
  icon?: React.ReactNode;
  ref?: Ref<HTMLInputElement>;
}

export const InputDrawer: React.FC<TextInputProps> = ({
  value,
  label,
  onChange,
  onFocus,
  icon,
  placeholder,
  name,
  required,
  autocomplete,
  defaultValue,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [_value, setValue] = useState(value);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleOnBlur = async () => {
    if (_value !== value) {
      onChange(_value);
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KeyboardKeys.Escape) {
      setValue(value);
      return;
    }

    if (e.key === KeyboardKeys.Enter && value !== _value) {
      inputRef!.current?.blur();
      return;
    }
  };

  return (
    <TextInputContainer hasLabel={label !== undefined ? true : undefined}>
      {icon}

      <Label hasLabel={label !== undefined ? true : undefined}>
        {label} {required && <b>*</b>}
      </Label>

      <Input
        ref={inputRef}
        value={_value}
        onChange={handleChange}
        onFocus={onFocus}
        onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
        hasIcon={!!icon}
        type="text"
        name={name}
        defaultValue={defaultValue}
        placeholder={placeholder || ''}
        onKeyDown={handleKeyDown}
        onBlur={handleOnBlur}
        autoComplete={autocomplete}
      />
    </TextInputContainer>
  );
};

interface InputProps {
  hasIcon?: boolean;
}

interface LabelProps {
  hasLabel?: boolean;
}

const TextInputContainer = styled.div<LabelProps>`
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  min-width: 15rem;
  z-index: 99;
  width: 100%;
  color: var(--select-text-color);

  svg {
    fill: var(--select-border-color);
    width: 1.6rem;
    position: absolute;
    right: 1.6rem;
  }
`;

const Label = styled.div<LabelProps>`
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.1rem;
  display: ${({ hasLabel }) => (hasLabel ? 'inline' : 'none')};
`;

const Input = styled.input<InputProps>`
  position: relative;
  box-sizing: border-box;
  width: 18rem;
  height: 2.5rem;
  border-radius: var(--card-border-radius);
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  line-height: 1.6rem;
  display: flex;
  z-index: 1;
  align-items: center;
  border: 1px solid var(--select-border-color);
  color: var(--select-text-color);
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  background: transparent;

  &:hover {
    cursor: text;
  }

  svg {
    width: 12px;
    height: 12px;
  }

  &:focus {
    outline: none;
    border: 1px solid var(--select-border-color);
    color: var(----select-text-color);
  }
`;
