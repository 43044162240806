import styled from 'styled-components';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2.4rem 0;
  border-radius: 0.4rem;
  border: 1px solid var(--color-grayscale-trout);
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  padding-bottom: 1.6rem;
  margin: 0 2.4rem;
  border-bottom: 1px solid var(--color-grayscale-trout);
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

export const SectionHeader = styled.h2`
  color: var(--color-grayscale-white);
  font-size: 1.8rem;
  font-weight: 700;
`;

export const Description = styled.p`
  color: var(--color-grayscale-manatee);
  font-size: 1.6rem;
  font-weight: 400;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-grayscale-trout);
  }
`;
