import { Character } from '../../app/entities/character';
import { api } from '../../api';
import { upload } from '../../core/services/file.service';
import { Frame } from '../../lib/utils/transform-image';
import { entityPool } from '../../core/engine/engine';
import { Candidate } from '../../app/entities/candidate';

type FetchProjectCharactersInput = {
  projectId: string;
};
export const fetchProjectCharacters = async ({ projectId }: FetchProjectCharactersInput) => {
  const { data } = await api.get(`/projects/${projectId}/characters`);

  data.map(({ candidates, ...el }: any) => {
    const character = Object.assign(new Character(), el);
    entityPool.insert(character);

    candidates.forEach((cd: any) => {
      const candidate = Object.assign(new Candidate(), cd, { projectId, characterId: el._id });
      entityPool.insert(candidate);
    });
  });
};

type AddProjectCharacterInput = {
  projectId: string;
  characterInfo: {
    name: string;
    gender?: string;
    description?: string;
    personality?: string;
    characteristics?: string;
    appearance?: string;
  };
  file?: Frame;
};

export const createCharacter = async ({ projectId, ...input }: AddProjectCharacterInput) => {
  const dto: any = input.characterInfo;

  if (input.file) {
    dto.file = {
      fileType: input.file.type,
      fileSize: input.file.size,
    };
  }

  const { data } = await api.post(`/projects/${projectId}/characters`, dto);

  if (data.links?.upload && input.file) {
    await upload(data.links.upload, input.file.data);
  }

  const character = Object.assign(new Character(), data);
  entityPool.insert(character);

  return character;
};

export const updateCharacterCover = async ({
  characterId,
  file,
}: {
  characterId: string;
  file: Frame;
}) => {
  const { data } = await api.put(`/characters/${characterId}/cover`, {
    fileType: file.type,
    fileSize: file.size,
  });

  if (data.links?.upload) {
    await upload(data.links.upload, file.data);
  }

  const character = Character.getOne(characterId)!;
  character.cover = data.cover;
};
