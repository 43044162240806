import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';

import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { updateCharacterCover } from '../character.slice';
import { CoverImage } from '../../../components/cover-image/cover-image';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { Person as PersonIcon } from '../../../components/icons/person';
import { catchError } from '../../../core/catch-error';
import { FormBuilderModal } from '../../../components/form-builder-modal/form-builder-modal';
import { buildCharacterForm } from '../character-form.definition';
import { formatPicture } from '../../../core/services/image.service';

import type { Character } from '../../../app/entities/character';
import type { Frame } from '../../../lib/utils/transform-image';

type CharacterProps = {
  character: Character;
};

export const CharacterItem: React.FC<CharacterProps> = observer(({ character }) => {
  const { name } = character;
  const navigate = useNavigate();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [updateModalKey, setUpdateModalKey] = useState(character?._id);

  const handleCharacterNavigation = () => navigate(`./character/${character?._id}`);

  const handleConfirmDelete = async () => {
    try {
      await character.delete();
    } catch (e) {
      catchError(e);
    }
  };

  const handleEditCover = async (file: Frame) => {
    try {
      await updateCharacterCover({ characterId: character._id, file });
    } catch (e) {
      catchError(e);
    }
  };

  const handleEdit = async (data: Partial<Character>) => {
    await character.update(data);

    // To rerender the update form modal
    setUpdateModalKey(character._id + Math.random());
  };

  const handleUploadPhoto = async (file?: File) => {
    if (!file) {
      return;
    }

    const options = { width: 340, height: 340 };
    const frame = await formatPicture(file, options);
    await updateCharacterCover({ characterId: character._id, file: frame });
  };

  const menuItems = [
    {
      onSelect: () => setIsEditModalOpen(true),
      title: 'Edit',
      icon: <Pencil1Icon />,
    },
    {
      onSelect: () => setIsDeleteDialogOpen(true),
      title: 'Delete',
      icon: <TrashIcon />,
      type: 'danger' as 'danger',
    },
  ];

  const updateCharacterFormDefinition = buildCharacterForm(false, handleUploadPhoto);

  return (
    <Container onDoubleClick={handleCharacterNavigation}>
      {/* TODO (need to handle this in the characterScreen component stop handling state inside the deleteDialog component to not throw an error once this component is removed) */}
      {isDeleteDialogOpen && (
        <DeleteDialog
          onSubmit={handleConfirmDelete}
          onCancel={() => setIsDeleteDialogOpen(false)}
          title="Delete character"
          text={`Are you sure you want to delete ${name} ?`}
        />
      )}
      <Header>
        <Name>{name}</Name>

        <ActionsContainer>
          <CastListButton>
            <span>{character.candidatesCount}</span>
            <PersonIcon />
          </CastListButton>
          <DropdownMenu items={menuItems} size="large" />
        </ActionsContainer>
      </Header>

      <CharacterImageContainer>
        <CoverImage
          src={character.cover?.src || ''}
          title={character.name}
          onEdit={handleEditCover}
        />
      </CharacterImageContainer>

      <AdditionalInfoBlock>
        <AdditionalInfoLabel>Selected Candidate</AdditionalInfoLabel>
        <SelectedCandidateName>{character.castName}</SelectedCandidateName>
        <SelectedCandidateTitle>{character.cast && 'Cast'}</SelectedCandidateTitle>
      </AdditionalInfoBlock>

      {/* TODO: Handle Requirements */}
      <AdditionalInfoBlock>
        <AdditionalInfoLabel>Description</AdditionalInfoLabel>
        <Requirements>{character.description}</Requirements>
      </AdditionalInfoBlock>

      <FormBuilderModal
        key={updateModalKey}
        model="character"
        isOpen={isEditModalOpen}
        definition={updateCharacterFormDefinition}
        initialValues={{ ...character, avatar: character.cover?.src }}
        onSubmit={handleEdit}
        onCancel={() => setIsEditModalOpen(false)}
      />
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
  background: var(--color-grey-9-5);
  border-radius: var(--border-radius-medium);
  padding: 0.8rem 0.8rem 2.4rem;
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  & > div {
    & > div {
      width: 3.2rem;
      height: 3.2rem;
      background: var(--color-grey-8-5);
      color: var(--color-grey-5);
    }
  }
`;

const Name = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.9rem;
  color: #f7f8f8;
`;

const CharacterImageContainer = styled.div`
  position: relative;
  padding-top: 100%;
`;

const Requirements = styled.h3`
  font-weight: 400;
  font-size: 1.6rem;
  height: 2.4rem;
  color: var(--color-grey-2);
`;

const AdditionalInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.4rem;
`;

const SelectedCandidateName = styled.h6`
  font-size: 1.6rem;
  height: 1.9rem;
  color: var(--white-default);
  font-weight: 500;
`;

const SelectedCandidateTitle = styled.p`
  font-weight: 500;
  font-size: 1.4rem;
  height: 1.7rem;
  color: var(--color-grey-3);
`;

const AdditionalInfoLabel = styled.span`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--color-grey-5);
`;

const CastListButton = styled.button`
  all: unset;
  background: var(--color-grey-8-5);
  color: var(--color-grey-2);
  border-radius: var(--border-radius-medium);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
  padding: 0.4rem 0.8rem;
`;
