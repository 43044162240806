import React from 'react';
import styled from 'styled-components';

type ListProps = {
  title: string;
  list: { name: string; img: any }[];
  className?: string;
};

export const List: React.FC<ListProps> = ({ title, list, className }) => {
  return (
    <Container className={className}>
      {list.some((item) => !!item.name) && <Title>{title}</Title>}

      <Items>
        {list.map(
          (item, i) =>
            item.name && (
              <Item key={item.img + i}>
                {item.img}
                <Text>{item.name}</Text>
              </Item>
            ),
        )}
      </Items>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const Item = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;

const Title = styled.p`
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--color-grayscale-light-slate);
`;

const Text = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-grayscale-white);
`;
