import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OutlineArrowRight } from '@producer-io/ui-kit';
import { initializePaddle, Paddle } from '@paddle/paddle-js';
import styled from 'styled-components';

import { api } from '../../../../api';
import { Space } from '../../../../app/entities/space';
import { UpdateVatInfoModal } from './update-vat-info.modal';
import { globalConfig } from '../../../../configuration/config';
import { UpdateBillingAddressModal } from './update-billing-address';
import {
  Description,
  Header,
  HeaderWrapper,
  ItemsContainer,
  Section,
  SectionHeader,
} from './styled-billing';

type ManageBillingSectionProps = {
  space: Space;
};

export const ManageBillingSection: React.FC<ManageBillingSectionProps> = ({ space }) => {
  const navigate = useNavigate();

  const [openVATInfo, setOpenVATInfo] = useState(false);
  const [openBillingAddress, setOpenBillingAddress] = useState(false);

  const paddleInstance = useRef<Paddle>();
  const paddleToken = globalConfig.config.paddleToken;

  useEffect(() => {
    initializePaddle({
      environment: globalConfig.config.environment === 'production' ? 'production' : 'sandbox',
      token: paddleToken!,
    }).then((paddle: Paddle | undefined) => {
      if (!paddle) {
        return;
      }
      paddleInstance.current = paddle;
    });
  }, []);

  const redirectToPlans = () => {
    navigate(`/space/${space._id}/settings/plans`);
  };

  const handleChangePaymentMethod = async () => {
    if (!space!.hasSubscription()) {
      redirectToPlans();
      return;
    }

    await api.get(`spaces/${space._id}/update-payment-method`).then(({ data }) => {
      const paddle = paddleInstance.current;

      paddle?.Checkout.open({
        settings: {
          theme: 'dark',
          locale: 'en',
          allowLogout: false,
        },
        transactionId: data.transactionId,
      });
    });
  };

  return (
    <Section>
      <HeaderWrapper>
        <Header>
          <SectionHeader>Manage Billing</SectionHeader>
        </Header>
      </HeaderWrapper>

      <ItemsContainer>
        {/* changing payment method requires the subscription to be in automatic collection mode (self-serving) */}
        {space.hasSubscription() && space.isSelfServing && (
          <ItemWrapper onClick={handleChangePaymentMethod}>
            <Item>
              <ItemLabel>Change payment method</ItemLabel>

              <Description>Change or update payment method</Description>
            </Item>

            <OutlineArrowRight />
          </ItemWrapper>
        )}

        <ItemWrapper
          onClick={() => {
            if (!space!.hasSubscription()) {
              redirectToPlans();
              return;
            }

            setOpenVATInfo(true);
          }}
        >
          <Item>
            <ItemLabel>Value Added Tax / Services Tax</ItemLabel>

            <Description>
              Add a VAT or GST identification number if you&apos;re paying as a business
            </Description>
          </Item>

          <OutlineArrowRight />
        </ItemWrapper>

        <ItemWrapper
          onClick={() => {
            if (!space!.hasSubscription()) {
              redirectToPlans();
              return;
            }

            setOpenBillingAddress(true);
          }}
        >
          <Item>
            <ItemLabel>Billing information</ItemLabel>

            <Description>Add billing info for your invoice</Description>
          </Item>

          <OutlineArrowRight />
        </ItemWrapper>
      </ItemsContainer>
      {openVATInfo && <UpdateVatInfoModal space={space!} onCancel={() => setOpenVATInfo(false)} />}

      {openBillingAddress && (
        <UpdateBillingAddressModal space={space!} onCancel={() => setOpenBillingAddress(false)} />
      )}
    </Section>
  );
};

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 0.8rem 2.4rem;

  & > svg {
    width: 1.6rem;
    height: 1.6rem;
    fill: var(--color-grayscale-shuttle);
  }

  &:hover {
    background: var(--color-grayscale-tuna);
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const ItemLabel = styled.h6`
  color: var(--color-grayscale-white);
  font-size: 1.6rem;
  font-weight: 500;
`;
