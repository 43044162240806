import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AssetFilesScreen } from '../../../features/assets/screens/asset-files.screen';
import { CommonFileScreen } from '../../../features/assets/screens/common-file.screen';
import { fetchStepAssets } from '../../../features/assets/store/assets.slice';

import type { Project } from '../../../app/entities/project';
import type { Step } from '../../../app/entities/step';

export type CommonFilePageProps = {
  project: Project;
  step: Step;
};

export const CommonFilePage: React.FC<CommonFilePageProps> = ({ step, project }) => {
  useEffect(() => {
    fetchStepAssets({ stepId: step._id });
  }, [step._id]);

  return (
    <Routes>
      <Route
        path={'/folder/:assetId'}
        element={<AssetFilesScreen step={step} isFolder spaceId={project.spaceId} />}
      />

      <Route
        path={'/file/:assetId'}
        element={<CommonFileScreen step={step} spaceId={project.spaceId} />}
      />

      <Route path={'/'} element={<AssetFilesScreen step={step} spaceId={project.spaceId} />} />
    </Routes>
  );
};
