import React from 'react';
import styled from 'styled-components';
import { Plus } from '../../../components/icons';

type AddCollaboratorCardProps = {
  onClick: () => void;
  label?: string;
};

export const AddCollaboratorCard: React.FC<AddCollaboratorCardProps> = ({ onClick, label }) => (
  <Card onClick={onClick}>
    <Content>
      <Icon>
        <Plus />
      </Icon>

      {label && <Label>{label}</Label>}
    </Content>
  </Card>
);

const Card = styled.div`
  border-radius: var(--border-radius-medium);
  background-color: transparent;
  width: 100%;
  padding-top: 100%;
  position: relative;
  border: #5f6164 dashed 1px;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.4rem;
  border-radius: 50%;
  color: #5f6164;
  border: #5f6164 dashed 1px;
  height: 4.8rem;
  width: 4.8rem;
  user-select: none;

  svg {
    fill: #5f6164;
  }
`;

const Label = styled.div`
  margin-top: 1.2rem;
  font-size: 1.4rem;
  color: #5f6164;
`;
