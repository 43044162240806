import React from 'react';
import styled from 'styled-components';

import { EditableText } from '../../../components/editable-text/EditableText';
import { InlineTimePeriodSelector } from './InlineTimePeriodSelector';
import { InlineTimeSelector } from './InlineTimeSelector';
import { AddStripMenu } from './add-strip-menu';
import { Stripboard } from '../../../app/entities/stripboard';
import { BaseBlock, BaseRow, BlockContainer, Cell } from './styled-strips';

import { BannerStrip } from '../models/types';

type BreakStripProps = {
  stripboard: Stripboard;
  strip: BannerStrip;
  style?: any;
  isSelected?: boolean;
  isDragging?: boolean;
  onClick?: (e: React.MouseEvent) => void;
};

export const BreakStrip = React.forwardRef<HTMLTableRowElement, BreakStripProps>(
  ({ stripboard, onClick, isSelected, strip, isDragging, ...draggableProps }, ref) => {
    const handleEdit = (data: any) => {
      stripboard.editStrip(strip._id, { data });
    };

    return (
      <Row
        ref={ref}
        onClick={onClick}
        data-selected={isSelected}
        data-dragged={isDragging}
        {...draggableProps}
      >
        <Cell
          colSpan={100}
          style={{
            padding: 0,
          }}
        >
          <RowMirror>
            <BlockContainer>
              <BaseBlock>
                <BlockEditableText
                  onSubmit={(text) => handleEdit({ text })}
                  defaultText={'Setup'}
                  maxLength={60}
                >
                  <BlockName>{strip.data.text}</BlockName>
                </BlockEditableText>
              </BaseBlock>
            </BlockContainer>

            <CellMirror>
              <InlineTimePeriodSelector
                onChange={(estimatedTime) => handleEdit({ estimatedTime })}
                time={strip.data.estimatedTime}
              />
            </CellMirror>

            <CellMirror>
              {strip.startTime && (
                <InlineTimeSelector time={strip.startTime || { hour: 0, minute: 0 }} disabled />
              )}
            </CellMirror>

            <CellMirror>
              <AddStripMenu
                stripId={strip._id}
                projectId={stripboard.projectId}
                position={strip.position}
                isDark
                stripType="banner"
              />
            </CellMirror>
          </RowMirror>
        </Cell>
      </Row>
    );
  },
);

BreakStrip.displayName = 'BreakStrip';

const Row = styled(BaseRow)`
  height: 4.8rem;
  background: var(--color-grayscale-tuna);
`;

const BlockEditableText = styled(EditableText)`
  width: 100%;
`;

const BlockName = styled.p`
  font-weight: 400;
  font-size: 1.4rem;
  width: 100%;
  height: 3.2rem;
  padding: 0.8rem;
  text-align: center;
  color: var(--color-grayscale-white);
`;

const RowMirror = styled.div`
  display: grid;
  grid-template-columns: 8.5% 9.2% 8.3% 7.4% 25.7% 26.5% 4.6% 5.4% 4.4%;
  position: relative;
  align-items: center;

  // substitute border on parent, adding 0.3rem to width
  margin: 0px -0.15rem;
`;

const CellMirror = styled.div`
  padding-inline-end: 1.6rem;

  &:nth-child(2) {
    grid-column-start: 7;
  }

  &:last-child {
    justify-self: end;
    padding-inline-end: 1.75rem;
    width: -webkit-fill-available;
  }
`;
