import React from 'react';

export const CrownBadge = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 14.3158H16V16H0V14.3158ZM0 2.52632L4 5.05263L8 0L12 5.05263L16 2.52632V12.6316H0V2.52632Z"
      fill="#367BFF"
    />
  </svg>
);
