import loginEN from './login.json';
import signupEN from './signup.json';
import onboardingEN from './onboarding.json';
import projectListEN from './projectList.json';
import projectEN from './project.json';
import selectEN from './select.json';
import profileEN from './profile.json';
import talentCardEN from './talentCard.json';
import moodboardEN from './moodboard.json';
import gearEN from './gear.json';
import file from './file.json';
import location from './location.json';
import rush from './rush.json';
import scene from './scene.json';
import team from './team.json';
import folder from './folder.json';
import common from './common.json';
import callsheet from './callsheet.json';
import screenHeader from './screenHeader.json';

export const fr = {
  login: loginEN,
  signup: signupEN,
  onboarding: onboardingEN,
  projectList: projectListEN,
  select: selectEN,
  project: projectEN,
  profile: profileEN,
  talentCard: talentCardEN,
  moodboard: moodboardEN,
  gear: gearEN,
  file: file,
  location: location,
  rush: rush,
  scene: scene,
  team: team,
  folder: folder,
  common: common,
  screenHeader: screenHeader,
  callsheet: callsheet,
};
