import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import { BaseDialog, DialogFooter } from '../dialog';
import { OutlinedButton, ElevatedButton } from '../buttons';
import { PlanCardCheckOn } from '../icons';
import { api } from '../../api';

interface LimitReachedModalProps {
  /**
   * @deprecated
   */
  type?: 'projects' | 'creators' | 'shots';

  /**
   * Title to replace type
   */
  title?: string;

  onCancel: () => void;

  /**
   * If an event should be tracked when seeing the modal.
   * Should be the name of an event on Active Campaign
   */
  eventName?: string;
}

export const LimitReachedModal: React.FC<LimitReachedModalProps> = ({
  type,
  title,
  onCancel,
  eventName,
}) => {
  const { spaceId } = useParams() as { spaceId: string };
  const navigate = useNavigate();

  useEffect(() => {
    if (!eventName) {
      return;
    }

    api.post('/track', { type: 'event', eventName });
  }, [eventName]);

  const dialogActions = [
    <OutlinedButton key="keep-current-plan" onClick={onCancel} text="Keep current plan" />,
    <ElevatedButton
      key="upgrade-plan"
      onClick={() => {
        navigate(`/space/${spaceId}/settings/plans`);
      }}
      text="View plans"
    />,
  ];

  const header = title
    ? title
    : `We are happy to see you grow, upgrade to create unlimited ${type}`;

  return (
    <BaseDialog onClose={onCancel}>
      <Container>
        <HeaderWithImage>
          <h3>{header}</h3>
        </HeaderWithImage>

        <Content>
          <span>By upgrading, you get:</span>
          <FeaturesList>
            {[
              'Unlimited projects',
              'Extra storage',
              'Extra seats',
              'Unlimited number of shots',
              'Export to PDF',
            ].map((feature) => (
              <li key={feature}>
                <PlanCardCheckOn />
                <span>{feature}</span>
              </li>
            ))}
          </FeaturesList>
          <span>... and other epic features!</span>
        </Content>

        <DialogFooter actions={dialogActions} />
      </Container>
    </BaseDialog>
  );
};

const Container = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem;

  & > span:first-child {
    color: var(--color-grayscale-athens, #e4e6ea);
  }

  & span {
    color: #7a8296;
  }

  h1 {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 400;
    margin-bottom: 0.8rem;
  }

  p {
    line-height: 2rem;
    font-weight: 300;
  }
`;

const FeaturesList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 0.8rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;

  span {
    line-height: 2.4rem;
    color: var(--color-grayscale-light-slate, #7a8296);
  }

  li {
    display: flex;
    align-items: center;
    line-height: 2.4rem;
    gap: 0.8rem;
  }
`;

const HeaderWithImage = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 15rem;
  width: 100%;
  background-color: #1d1f24;
  background-image: url(/images/default-background.png);
  background-position: center;
  background-size: 16rem;
  background-repeat: no-repeat;
  max-width: 100%;

  h3 {
    text-align: center;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    max-width: 28rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 500;
    color: var(--color-grayscale-athens, #e4e6ea);
  }
`;
