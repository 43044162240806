import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { DescriptionHeader } from '../components/description-header';
import { SettingsAction } from '../components/settings-action';
import { keycloak } from '../../../keycloak';
import { Lock } from '../../../components/icons/lock';
import { ElevatedButton } from '../../../components/buttons';
import { api } from '../../../api';
import { PhoneLock } from '../../../components/icons/phone-lock';
import { uiStore } from '../../../core/stores/ui-store';

export const SecurityPage = () => {
  useEffect(() => {
    uiStore.pageTitle = {
      label: 'Password and authentication',
    };

    uiStore.showBack = true;
  }, []);

  const [credentials, setCredentials] = useState({
    loading: true,
    password: {
      setup: false,
    },
    mfa: {
      id: undefined,
      setup: false,
      loading: false,
    },
  });

  const [identities, setIdentities] = useState({
    loading: true,
    google: {
      connected: false,
    },
    microsoft: {
      connected: false,
    },
  });

  /**
   * Load current identity providers
   */
  useEffect(() => {
    api.get('/me/credentials').then((res) => {
      setCredentials((credentials) => {
        res.data.forEach((el: any) => {
          if (el.type === 'password') {
            credentials.password.setup = true;
          }

          if (el.type === 'otp') {
            credentials.mfa.id = el.id;
            credentials.mfa.setup = true;
          }
        });

        return {
          ...credentials,
          loading: false,
        };
      });
    });
  }, []);

  /**
   * Load identities
   */
  useEffect(() => {
    api.get('/me/identities').then((res) => {
      setIdentities((identities) => {
        res.data.forEach((el: any) => {
          if (el.identityProvider === 'google') {
            identities.google.connected = true;
          }

          if (el.identityProvider === 'microsoft') {
            identities.microsoft.connected = true;
          }
        });

        return {
          ...identities,
          loading: false,
        };
      });
    });
  }, []);

  const handlePasswordChange = () => {
    keycloak.login({
      action: 'UPDATE_PASSWORD',
    });
  };

  const handleIdpConnect = (idp: 'google' | 'microsoft') => {
    keycloak.login({
      idpHint: idp,
      prompt: 'login',
      redirectUri: window.location.href,
    });
  };

  const handleTwoFactorAuthentication = () => {
    keycloak.login({
      action: 'CONFIGURE_TOTP',
    });
  };

  const handleDeleteMFA = () => {
    setCredentials({
      ...credentials,
      mfa: {
        ...credentials.mfa,
        loading: true,
      },
    });

    api.delete(`/me/credentials/${credentials.mfa.id}`).then(() => {
      setCredentials({
        ...credentials,
        mfa: {
          id: undefined,
          setup: false,
          loading: false,
        },
      });
    });
  };

  return (
    <Container>
      <DescriptionHeader
        title="Password"
        description="Use email and password to log in to PRODUCER."
      />

      <SettingsAction
        title={'Change my password'}
        icon={<Lock />}
        action={
          <ElevatedButton
            isLoading={credentials.loading}
            onClick={handlePasswordChange}
            text={'Change'}
          />
        }
      />

      <DescriptionHeader
        title="Identity providers"
        description="Use your social accounts to log in to PRODUCER."
      />

      <SettingsAction
        name={'Google'}
        title={'Connect with Google'}
        icon={<Lock />}
        action={
          <ElevatedButton
            onClick={() => handleIdpConnect('google')}
            isLoading={identities.loading}
            text={identities.google.connected ? 'Disconnect' : 'Connect'}
          />
        }
      />

      <SettingsAction
        name={'Microsoft'}
        title={'Connect with Microsoft'}
        icon={<Lock />}
        action={
          <ElevatedButton
            onClick={() => handleIdpConnect('microsoft')}
            isLoading={identities.loading}
            text={identities.microsoft.connected ? 'Disconnect' : 'Connect'}
          />
        }
      />

      <DescriptionHeader
        title={'Two-factor authentication'}
        description={
          'Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to sign in.'
        }
      />

      <SettingsAction
        title={'Setup two-factor authentication'}
        icon={<PhoneLock />}
        action={
          <ElevatedButton
            onClick={credentials.mfa.setup ? handleDeleteMFA : handleTwoFactorAuthentication}
            isLoading={credentials.loading || credentials.mfa.loading}
            text={credentials.mfa.setup ? 'Disable' : 'Enable'}
          />
        }
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 64rem;
  margin: 0 auto;
`;
