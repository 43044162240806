import { v4 as uuid } from 'uuid';

import { api } from '../../../api';
import { Asset } from '../../../app/entities/asset';
import { uploadFile } from './uploads.slice';
import { loadPdfData } from '../../../lib/utils/load-pdf-data';
import { entityPool } from '../../../core/engine/engine';
import { Upload } from '../../../app/entities/upload';

/**
 * Create asset
 **/
type CreateAssetInputBase = {
  stepId: string;
  type: string;
  parentType: string;
  name?: string;
  fileType?: string;
  index?: number;
  key?: string;
  size?: number;
  file?: File;
  duration?: number;
  uploaded?: boolean;
  pages?: number;
  uploadId?: string;
  url?: string;
  content?: string;
};

type CreateStepAssetInput = CreateAssetInputBase & {
  parentType: 'step';
  locationId?: never;
};

type CreateLocationAssetInput = CreateAssetInputBase & {
  parentType: 'location';
  locationId: string;
};

type CreateAssetInput = CreateStepAssetInput | CreateLocationAssetInput;

export const createAsset = async ({
  parentType,
  stepId,
  locationId,
  file,
  key,
  ...input
}: CreateAssetInput): Promise<{ statusCode: number; asset: Asset | null }> => {
  try {
    /**
     * First create the asset in the API
     */
    let uploadId: string | undefined;
    if (file) {
      uploadId = uuid();
      input.uploadId = uploadId;
    }
    const url =
      parentType === 'step' ? `/steps/${stepId}/assets` : `/locations/${locationId}/assets`;

    if (parentType === 'location') {
      Object.assign(input, { stepId });
    }

    const { data } = await api.post(url, input);

    const asset: Asset = Object.assign(new Asset(), data, {
      targetId: parentType === 'step' ? stepId : locationId,
    });

    /**
     * Trigger a new upload if we have a file
     */
    if (file && data.links?.upload && uploadId) {
      const upload = new Upload(uploadId, data._id, data.links.upload);
      entityPool.insert(upload);

      uploadFile({
        upload,
        assetId: data._id,
        file: file,
      });
    }

    if (file && (file.type === 'video/mp4' || file.type === 'video/quicktime')) {
      new Promise<{ duration: number; width: number; height: number }>((resolve, reject) => {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          const duration = video.duration;
          const width = video.videoWidth;
          const height = video.videoHeight;
          resolve({ duration, width, height });
        };

        video.onerror = function (err) {
          reject(err);
        };

        video.src = URL.createObjectURL(file);
      }).then(({ duration, height, width }) => {
        if (!asset.meta) asset.meta = {};
        asset.update({
          meta: {
            ...asset.meta,
            duration,
            width,
            height,
          },
        });
        asset.meta.duration = duration;
        asset.meta.width = width;
        asset.meta.height = height;
      });
    }

    if (file && file.type === 'application/pdf') {
      loadPdfData(file).then((pages) => {
        if (!pages) return;
        if (!asset.meta) asset.meta = {};
        asset.update({ meta: { ...asset.meta, pages } });
        asset.meta.pages = pages;
      });
    }

    /**
     * Return the asset before the upload is finished, so we can display it
     */
    entityPool.insert(asset);
    return { statusCode: 200, asset };
  } catch (error: any) {
    return { statusCode: error?.response?.status, asset: null };
  }
};

type FetchStepAssetsInput = {
  stepId: string;
  rootId?: string;
};

export const fetchStepAssets = async ({ stepId, rootId }: FetchStepAssetsInput) => {
  let url = `/steps/${stepId}/assets`;

  if (rootId) {
    url += `?root-id=${rootId}`;
  }

  const { data } = await api.get(url);

  data.map((el: any) => {
    const asset = Object.assign(new Asset(), el, { targetId: stepId });
    entityPool.insert(asset);
  });
};

type GetLocationAssetsInput = {
  locationId: string;
};

export const getLocationAssets = async ({ locationId }: GetLocationAssetsInput) => {
  const { data } = await api.get(`/locations/${locationId}/assets`);

  data.map((el: any) => {
    const asset = Object.assign(new Asset(), el, { targetId: locationId });
    entityPool.insert(asset);
  });
};

export const fetchAsset = async (assetId: string) => {
  const { data } = await api.get(`/assets/${assetId}`);
  const asset = Object.assign(new Asset(), data);
  entityPool.insert(asset);
};

export const fetchChildren = async ({ assetId }: { assetId: string }) => {
  const { data } = await api.get(`/assets/${assetId}/children`);

  data.map((el: any) => {
    const asset = Object.assign(new Asset(), el);
    entityPool.insert(asset);
  });
};
