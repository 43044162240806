import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from '@producer-io/ui-kit';
import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { TextInput } from '../text-input/TextInput';
import { KeyboardKeys } from '../../assets/enums/keyboard-keys.enum';

type InteractableTextInputProps = {
  defaultValue?: string;
  label?: string;
  helpText?: string;
  placeholder?: string;
  onSave: (value: string) => Promise<void>;
};

export const InteractableTextInput: React.FC<InteractableTextInputProps> = ({
  defaultValue,
  label,
  helpText,
  placeholder,
  onSave,
}) => {
  const [currentValue, setCurrentValue] = useState(defaultValue);

  const [state, setState] = useState<'loading' | 'success' | 'error' | 'idle'>('idle');

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (['idle', 'loading'].includes(state)) {
      return;
    }

    setTimeout(() => {
      setState('idle');
    }, 3100);
  }, [state]);

  const handleBlur = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (e.currentTarget.disabled || !value || value === defaultValue) {
      return;
    }

    setState('loading');

    try {
      await onSave(value);

      setState('success');
    } catch (e) {
      setState('error');
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!inputRef?.current) {
      return;
    }

    if (e.key === KeyboardKeys.Escape) {
      setCurrentValue(defaultValue);
      /**
       * Setting the disabled property to true will trigger the onblur event
       * and will not call the handleSubmit function
       * We re-enable it immediately after for further update
       */
      inputRef.current.disabled = true;
      inputRef.current.disabled = false;
    }

    if (e.key === KeyboardKeys.Enter) {
      inputRef.current.blur();
    }
  };

  const renderState = () => {
    switch (state) {
      case 'loading':
        return <Spinner small />;
      case 'success':
        return (
          <State data-state="success">
            <CheckIcon />
            Saved
          </State>
        );
      case 'error':
        return (
          <State data-state="error">
            <Cross2Icon />
            Could not save
          </State>
        );
      default:
        return;
    }
  };

  return (
    <TextInput
      ref={inputRef}
      label={label}
      hint={helpText}
      placeholder={placeholder || label}
      defaultValue={defaultValue}
      value={currentValue}
      onKeyDown={onKeyDown}
      onBlur={handleBlur}
      onChange={(e) => setCurrentValue(e.target.value)}
      suffix={renderState()}
    />
  );
};

const State = styled.span`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  color: var(--color-primary-puerto-rico);
  animation: fade-out 3s forwards;

  &[data-state='success'] {
    color: var(--color-primary-puerto-rico);
  }

  &[data-state='error'] {
    color: var(--color-secondary-roman);
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      display: none;
      opacity: 0;
    }
  }
`;
