import React from 'react';

import { ReactSelect } from '../../../components/select';
import {
  handleRevertWrappingModalOverflow,
  handleWrappingModalOverflow,
} from '../../../components/select/utils';

import type { Options } from 'react-select';
import type { SelectOption } from '../../../components/select';

const options: Options<SelectOption> = [
  {
    value: 'clear sky',
    label: 'Clear sky',
  },
  {
    value: 'few clouds',
    label: 'Few clouds',
  },
  {
    value: 'scattered clouds',
    label: 'Scattered clouds',
  },
  {
    value: 'broken clouds',
    label: 'Broken clouds',
  },
  {
    value: 'shower rain',
    label: 'Shower rain',
  },
  {
    value: 'rain',
    label: 'Rain',
  },
  {
    value: 'thunderstorm',
    label: 'Thunderstorm',
  },
  {
    value: 'snow',
    label: 'Snow',
  },
  {
    value: 'mist',
    label: 'Mist',
  },
];

type WeatherSelectProps = {
  onChange: (value: string) => void;
  value: string;
};

export const WeatherSelect: React.FC<WeatherSelectProps> = ({ onChange, value }) => {
  const defaultValue = options.find((option) => option.value === value);

  const handleChange = (option: any) => {
    onChange(option.value);
  };

  return (
    <ReactSelect
      placeholder="Choose weather conditions"
      options={options}
      onChange={handleChange}
      value={defaultValue}
      onMenuOpen={handleWrappingModalOverflow}
      onMenuClose={handleRevertWrappingModalOverflow}
    />
  );
};
