import React, { createContext } from 'react';

type CompareContextProps = {
  player1Ref: React.RefObject<HTMLVideoElement>;
  player2Ref: React.RefObject<HTMLVideoElement>;
  togglePlay: (
    currentPlayerRef: React.RefObject<HTMLVideoElement>,
    isPlaying: boolean,
  ) => Promise<void>;
  toggleMute: (currentPlayerRef: React.RefObject<HTMLVideoElement>, isMute: boolean) => void;
  handleSeekTime: (time: number) => void;
  handleRateChange: (rate: number) => void;
  handleLoadStart: (currentPlayerRef: React.RefObject<HTMLVideoElement>) => void;
};

export const CompareContext = createContext<CompareContextProps>({
  player1Ref: React.createRef<HTMLVideoElement>(),
  player2Ref: React.createRef<HTMLVideoElement>(),
  togglePlay: async () => {},
  toggleMute: () => {},
  handleSeekTime: () => {},
  handleRateChange: () => {},
  handleLoadStart: () => {},
});
