import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { OutlineArrowRight } from '../icons/outline-arrow-right';

export type BreadcrumbObject = {
  href: string;
  label: string;
};

type BreadcrumbProps = {
  breadcrumbs: BreadcrumbObject[];
};

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumbs }) => {
  const navigate = useNavigate();

  return (
    <Container>
      {breadcrumbs.map(({ href, label }, index) => (
        <React.Fragment key={`${label}_${index}`}>
          <StyledBreadcrumb
            onClick={() => {
              if (index === breadcrumbs.length - 1) return;
              navigate(href);
            }}
          >
            {label}
          </StyledBreadcrumb>
          {index !== breadcrumbs.length - 1 && <OutlineArrowRight />}
        </React.Fragment>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1.4rem;
  user-select: none;

  & svg {
    fill: var(--color-grey-5);
  }
`;

const StyledBreadcrumb = styled.div`
  font-size: 2rem;
  font-weight: 500;
  color: var(--color-grey-5);
  line-height: 2.4rem;
  cursor: pointer;

  &:last-child {
    color: var(--white-default);
    cursor: default;
  }
`;
