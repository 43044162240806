import React from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';

import { defaultStyles, mergeSelectStyles } from '../../utils';

import { AvatarSelectOption, type AvatarOption } from './avatar-select-option';
import type { SelectRefType, SelectProps } from '../../types';

export const AvatarSelect = React.forwardRef<
  SelectRefType<AvatarOption>,
  SelectProps<AvatarOption>
>(
  (
    {
      options,
      value,
      defaultValue = [],
      styles,
      onChange,
      isMulti,
      isLoading,
      isDisabled,
      isClearable,
      label,
      required,
      errorMessage,
      ...props
    },
    ref,
  ) => {
    const currentStyles = mergeSelectStyles(defaultStyles<AvatarOption>(), styles);

    return (
      <Container>
        {label && (
          <Label>
            {label} {required && <b>*</b>}
          </Label>
        )}

        <ReactSelect
          ref={ref}
          {...props}
          options={options}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          styles={currentStyles}
          isClearable={isClearable}
          isMulti={isMulti}
          isLoading={isLoading}
          isDisabled={isLoading || isDisabled}
          isOptionDisabled={(option) => !!option.disabled}
          components={{
            Option: AvatarSelectOption,
          }}
        />

        {errorMessage && <Error>{errorMessage}</Error>}
      </Container>
    );
  },
);

AvatarSelect.displayName = 'AvatarSelect';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  position: relative;
`;

const Label = styled.label`
  font-weight: 400;
  color: var(--color-grayscale-white);
`;

const Error = styled.span`
  position: absolute;
  right: 0;
  bottom: -1.4rem;
  color: var(--color-error);
  font-family: Inter, sans-serif;
  font-size: 1rem;
  letter-spacing: -0.2px;
  text-align: right;
`;
