import React from 'react';

export const ProfilePerson = () => {
  return (
    <svg
      width="177"
      height="178"
      viewBox="0 0 177 178"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M88.5 89C104.799 89 118 75.7988 118 59.5C118 43.2013 104.799 30 88.5 30C72.2012 30 59 43.2013 59 59.5C59 75.7988 72.2012 89 88.5 89ZM88.5 103.75C68.8088 103.75 29.5 113.632 29.5 133.25V140.625C29.5 144.681 32.8187 148 36.875 148H140.125C144.181 148 147.5 144.681 147.5 140.625V133.25C147.5 113.632 108.191 103.75 88.5 103.75Z"
        fill="#959BAB"
      />
    </svg>
  );
};
