import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { ToastItem } from './toast-item';
import { toastPool } from '../models/toast-pool';

export const ToastContainer = observer(() => {
  if (toastPool.elements.length === 0) {
    return null;
  }

  return ReactDOM.createPortal(
    <Container onClick={(e) => e.stopPropagation()}>
      {toastPool.elements.map((toast, index) => (
        <ToastItem key={toast.message + index} toast={toast} />
      ))}
    </Container>,
    document.getElementById('toast-root') as HTMLElement,
  );
});

const Container = styled.div`
  z-index: var(--layer-tooltip);
  position: fixed;
  min-height: 5rem;
  display: flex;
  flex-direction: column;
  bottom: 2.4rem;
  right: 2.4rem;
  gap: 0.8rem;
`;
