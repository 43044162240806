import React, { DragEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { ErrorScreen } from '../../../components/error-screen/ErrorScreen';
import { Loader } from '../../../components/loader/Loader';
import { SortableShotGrid } from '../components/SortableShotGrid';
import { LoaderContainer } from '../../../routes/project/components/step-container';
import { createStoryboard, fetchStoryboards } from '../storyboard.slice';
import { PageLoader } from '../../../components/page-loader/PageLoader';
import { ScreenContainer } from '../../projects/components/styled-project-components';

import { Project } from '../../../app/entities/project';
import { Step } from '../../../app/entities/step';
import { SelectionProvider } from '../../../core/contexts/selection.context';
import { StoryboardHeader } from '../components/storyboard-screen.header';
import { StepDrawer } from '../../projects/components/step.drawer';
import { authStore } from '../../../core/stores/auth-store';

type StoryboardScreenProps = {
  project: Project;
  step: Step;
};

export const StoryboardScreen: React.FC<StoryboardScreenProps> = observer(({ project, step }) => {
  const [isLoadingStoryboard, setIsLoadingStoryboard] = useState(true);

  const currentMember = authStore.getCurrentMember(project.spaceId);

  const isLoading = isLoadingStoryboard;
  const storyboard = project.storyboard;
  const shotsIds = storyboard?.shots.map((el) => el._id) || [];

  useEffect(() => {
    if (!storyboard) {
      fetchStoryboards(project._id)
        .then(() => setIsLoadingStoryboard(false))
        .catch(() => setIsLoadingStoryboard(false));
    } else {
      setIsLoadingStoryboard(false);
    }
  }, [storyboard, project._id]);

  if (status === 'idle') {
    return <div />;
  } else if (status === 'loading') {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  } else if (status === 'failed') {
    return <div />;
  }

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <>
      <StoryboardHeader step={step} project={project} />

      <StepDrawer step={step} />

      {isLoading ? (
        <LoaderContainer>
          <PageLoader />
        </LoaderContainer>
      ) : !storyboard ? (
        <ErrorScreen
          title="You do not have a storyboard for this project yet"
          message="Start your next story now"
          actionText="Create storyboard"
          onActionClick={() => createStoryboard(project)}
        />
      ) : (
        <SelectionProvider items={shotsIds}>
          <ScreenContainer
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <SortableShotGrid
              zoomLevel={currentMember?.preferences.storyBoardPreferences.zoom || 1}
              storyboard={storyboard}
              options={storyboard?.settings || { properties: [] }}
              stepId={step._id}
            />
          </ScreenContainer>
        </SelectionProvider>
      )}
    </>
  );
});
