import React, { useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  BaseDialog,
  BaseDialogProps,
  DialogFooter,
  DialogHeader,
} from '../../../components/dialog';
import { TextButton, ElevatedButton } from '../../../components/buttons';
import { useForm, Controller } from 'react-hook-form';
import { WeatherSelect } from './weather.select';
import { TextInput } from '../../../components/text-input/TextInput';
import { Step } from '../../../app/entities/step';

type WeatherModalProps = BaseDialogProps & {
  step: Step;
  defaultValues?: FormValues;
  onClose: () => void;
};

type FormValues = {
  temp: {
    min: string;

    max: string;
  };

  sunrise: string;

  sunset: string;

  description: string;
};

const schema = yup.object().shape({
  temp: yup.object().shape({
    min: yup.string().max(6),
    max: yup.string().max(6),
  }),
  sunrise: yup.string().max(8),
  sunset: yup.string().max(8),
  description: yup.string(),
});

export const WeatherModal: React.FC<WeatherModalProps> = ({ onClose, step, defaultValues }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { control, register, handleSubmit } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: defaultValues || {
      temp: {
        min: '-1°C',
        max: '5°C',
      },
      sunrise: '07:00',
      sunset: '20:00',
      description: 'clear sky',
    },
  });

  const onSubmit = async (values: FormValues) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    await step.update({
      info: {
        weather: values,
      },
    });

    onClose();
  };

  return (
    <BaseDialog onClose={onClose}>
      <DialogHeader title={'Weather'} onClose={onClose} />

      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <TextInput label="Min temp." {...register('temp.min')} />
            <TextInput label="Max temp." {...register('temp.max')} />
            <TextInput label="Sunrise" {...register('sunrise')} />
            <TextInput label="Sunset" {...register('sunset')} />

            <div style={{ gridColumn: 'span 2' }}>
              <Label>Weather</Label>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <WeatherSelect value={field.value} onChange={field.onChange} />
                )}
              />
            </div>
          </Grid>
        </form>
      </Content>

      <DialogFooter
        actions={[
          <TextButton key={'cancel'} onClick={onClose} text={'Cancel'} />,
          <ElevatedButton
            key="save"
            text={'Save'}
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
          />,
        ]}
      />
    </BaseDialog>
  );
};

const Content = styled.div`
  padding: 1.6rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.6rem;
`;

const Label = styled.div`
  font-family: Inter, sans-serif;
  font-weight: 600;
  color: white;
  font-size: 1.4rem;
  letter-spacing: 0.7px;
  line-height: 1.1rem;
  margin-bottom: 0.8rem;
`;
