import { api } from '../../../api';
import { upload } from '../../../core/services/file.service';
import { Frame } from 'lib/utils/transform-image';
import { Project } from '../../../app/entities/project';
import { entityPool } from '../../../core/engine/engine';
import { appSocket } from '../../../app-socket';

export const fetchSpaceProjects = async (spaceId: string) => {
  const { data } = await api.get(`/spaces/${spaceId}/projects`);

  data.forEach((el: any) => {
    /** Backend is sending a shootingDays value that is not use **/
    const { shootingDays, ...values } = el;

    const project = Object.assign(new Project(), values);
    entityPool.insert(project);
  });
};

/**
 * Create Project
 */

type CreateProjectInput = {
  spaceId: string;
  name: string;
  client?: string;
  agency?: string;
  shootingDays?: number;
  fps?: number;
  aspectRatio?: string;
  dueDate?: string;
  file?: Frame;
};

export const createProject = async (input: CreateProjectInput) => {
  const { spaceId, file, ...body } = input;

  if (file) {
    // @ts-ignore
    body.file = {
      type: file.type.split('/')[1],
      size: file.size,
      fileType: file.type,
    };
  }

  const { data } = await api.post(`/spaces/${spaceId}/projects`, body);

  if (data.links && file) {
    const uploadRes = await upload(data.links.upload, file.data);

    if (uploadRes.status === 201) {
      appSocket.projectAvatarUpdated(data);
    }
  }

  const project = Object.assign(new Project(), data);
  entityPool.insert(project);
};

export const duplicateProject = async (projectId: string) => {
  const { data } = await api.post(`/projects/duplicate/${projectId}`);

  const project = Object.assign(new Project(), data);
  entityPool.insert(project);

  return data;
};
