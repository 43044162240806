import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { FileList } from '../../../components/file-list/file-list';
import { createAsset, fetchStepAssets } from '../store/assets.slice';
import { SelectionProvider } from '../../../core/contexts/selection.context';
import { AssetFilesHeader } from '../components/asset-files-screen.header';
import { ScreenContainer } from '../../projects/components/styled-project-components';
import { StepDrawer } from '../../projects/components/step.drawer';

import type { Step } from '../../../app/entities/step';
import { authStore } from '../../../core/stores/auth-store';
import { ActionBar } from '../components/action-bar';
import { SORTING_TYPES } from '../../../assets/enums/assetType.enum';

type AssetFilesScreenProps = {
  step: Step;
  spaceId: string;
  isFolder?: boolean;
};

export const AssetFilesScreen: React.FC<AssetFilesScreenProps> = observer(
  ({ step, isFolder, spaceId }) => {
    const navigate = useNavigate();

    const currentMember = authStore.getCurrentMember(spaceId);
    const userStepPreferences = currentMember?.preferences.stepsUI[step._id];
    const sortOrder = userStepPreferences?.order || SORTING_TYPES.UPLOADED_DATE;

    const fileIds = step.assets?.map((file: any) => file._id) || [];

    const handleAddFiles = async (files: File[]) => {
      for (const file of files) {
        await createAsset({
          parentType: 'step',
          file,
          stepId: step._id,
          name: file.name,
          size: file.size,
          type: 'file',
          fileType: file.type,
        });
      }
    };

    const handleFileNavigation = async (asset: string, assetId: string) => {
      const href = `./${asset}/${assetId}`;

      if (asset === 'folder') {
        await fetchStepAssets({ stepId: step._id });
      }

      await navigate(href);
    };

    const toggleDisplayLayout = () => {
      const newLayout = userStepPreferences?.displayLayout === 'list' ? 'grid' : 'list';
      currentMember?.updateStepLayout(step, newLayout);
    };

    return (
      <SelectionProvider items={fileIds}>
        <AssetFilesHeader
          step={step}
          spaceId={spaceId}
          fileIds={fileIds}
          handleAddFiles={handleAddFiles}
          isFolder={isFolder}
          displayLayout={userStepPreferences?.displayLayout || 'grid'}
          toggleDisplayLayout={toggleDisplayLayout}
        />
        <StepDrawer step={step} />

        <ScreenContainer>
          <FileList
            files={step.assets}
            sortBy={sortOrder}
            assetsRouteTo={handleFileNavigation}
            onAddFiles={handleAddFiles}
            displayLayout={userStepPreferences?.displayLayout || 'grid'}
          />
        </ScreenContainer>

        <ActionBar spaceId={spaceId} projectId={step.projectId} />
      </SelectionProvider>
    );
  },
);
