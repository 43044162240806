import React, { PropsWithChildren } from 'react';
import { DescriptionHeader } from '../../user/components/description-header';

type SettingsSectionProps = {
  title: string;
  description?: string;
};

export const SettingsSection: React.FC<PropsWithChildren<SettingsSectionProps>> = ({
  title,
  description,
  children,
}) => (
  <div>
    <DescriptionHeader title={title} description={description} />

    {children}
  </div>
);
