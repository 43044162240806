import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CommentProvider } from '@producer-io/ui-kit';
import styled from 'styled-components';

import { AssetViewer } from '../components/asset-viewer';
import { breakPoint } from '../../../app/theme';

import type { Asset } from '../../../app/entities/asset';

interface AssetViewerScreenProps {
  asset: Asset;
}

export const AssetViewerScreen: React.FC<AssetViewerScreenProps> = ({ asset }) => {
  const location = useLocation();

  const [displayedAsset, setDisplayedAsset] = useState<Asset>(
    asset.type === 'file'
      ? asset
      : asset.children.find((item) => item._id === asset.currentVersionId) || asset,
  );

  useEffect(() => {
    if (
      displayedAsset.version &&
      displayedAsset.version < asset.children.length &&
      asset.children.some((item) => item._id === displayedAsset._id)
    ) {
      return;
    }
    const assetToDisplay = asset.children.find((item) => item._id === asset.currentVersionId);
    if (assetToDisplay) {
      setDisplayedAsset(assetToDisplay);
    } else {
      setDisplayedAsset(asset);
    }
  }, [asset.currentVersionId, asset.versionsCount]);

  useEffect(() => {
    if (displayedAsset) {
      displayedAsset.fetchComments();
    }
  }, [displayedAsset, location.key]);

  const parentComments = displayedAsset.comments.filter((el) => !el.parentId);

  return (
    <Container>
      <CommentProvider comments={parentComments}>
        <AssetViewer
          asset={asset}
          displayedAsset={displayedAsset}
          setDisplayedAsset={setDisplayedAsset}
        />
      </CommentProvider>
    </Container>
  );
};

const Container = styled.div`
  overflow-y: auto;
  min-height: 0;
  height: 100%;

  @media screen and (min-width: ${breakPoint.medium}px) {
    padding: var(--spacing-top-screen-container) var(--spacing-left-screen-container)
      var(--spacing-bottom-screen-container) var(--spacing-left-screen-container);
  }
`;
