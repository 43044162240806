import React from 'react';

import {
  Header,
  Title,
  TableContainer,
  RowTitle,
  RowHeader,
  CellLabel,
  CellContent,
  RowContainer,
  VerticalCell,
} from './styled-mobile-table';
import { CollapsibleItem } from '../../../components/collapsible-item/collapsible-item';

import type { AdditionalInfo } from './additional-info-table';

type MobileAdditionalInfoTableProps = {
  additionalInfo: AdditionalInfo[];
};

export const MobileAdditionalInfoTable: React.FC<MobileAdditionalInfoTableProps> = ({
  additionalInfo,
}) => (
  <div>
    <Header>
      <Title>Additional Information</Title>
    </Header>

    <TableContainer>
      {additionalInfo?.map((info) => (
        <CollapsibleItem
          key={info.number}
          title={
            <RowHeader>
              <RowTitle>Shot number {info.number}</RowTitle>
            </RowHeader>
          }
          isTransparent
        >
          <RowContainer>
            <VerticalCell>
              <CellLabel>Props</CellLabel>

              {info.props && <CellContent>{info.props}</CellContent>}
            </VerticalCell>

            <VerticalCell>
              <CellLabel>Director&apos;s notes</CellLabel>

              {info.directorsNote && <CellContent>{info.directorsNote}</CellContent>}
            </VerticalCell>
          </RowContainer>
        </CollapsibleItem>
      ))}
    </TableContainer>
  </div>
);
