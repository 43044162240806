/**
 * TODO
 * There is a copy of this file in the backend, should we create
 * an @producer/utils package for common function?
 */

export const minutesFormatter = (minutes: number) => {
  const minute = (minutes % 60).toString();
  const hour = Math.floor(minutes / 60).toString();

  return `${addLeadingCharacter(hour, 2)}:${addLeadingCharacter(minute, 2)}`;
};

/**
 * Given a string and a size, it will add as many character needed at the beginning of the string to make
 * its length equal to the size
 *
 * @param value the base characters string to format
 * @param size the final size of the string
 * @param character an optional character to fill the new string
 */
export const addLeadingCharacter = (value: string, size: number, character = '0') => {
  const missingCharacters = size - value.length;

  if (missingCharacters <= 0) {
    return value;
  }

  const leading = [];
  for (let i = 0; i < missingCharacters; i++) {
    leading.push(character);
  }

  return leading.concat(value).join('');
};
