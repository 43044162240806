import { api } from '../../api';
import { upload } from '../../core/services/file.service';
import { User } from '../../app/entities/user';
import { entityPool } from '../../core/engine/engine';

import type { Frame } from '../../lib/utils/transform-image';

export const fetchCurrentUser = async () => {
  const { data } = await api.get('/me');
  const user = Object.assign(new User(), data);

  entityPool.insert(user);

  return data;
};

export const updateCurrentUser = async ({ _id, ...input }: Partial<User> & { _id: string }) => {
  const { data } = await api.patch(`/users/${_id}`, input);
  if (!data) return data;

  const user = User.getOne(_id);
  if (user) {
    entityPool.updateEntity<User>(_id, data);
  }

  return data;
};

export const updateCurrentUserAvatar = async ({ file }: { file: Frame }) => {
  const input = {
    fileSize: file.size,
    fileType: file.type,
  };

  const { data } = await api.patch('/me/profile-picture', input);

  if (data.links && file) {
    await upload(data.links.upload, file.data);
  }

  const user = User.getOne(data._id);
  if (user) {
    entityPool.updateEntity<User>(data._id, {
      avatar: data.avatar,
    });
  }

  return data;
};
