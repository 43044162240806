import React from 'react';

export const Crop = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5042_457626)">
      <path d="M15 17V19H6C5.73478 19 5.48043 18.8946 5.29289 18.7071C5.10536 18.5196 5 18.2652 5 18V7H2V5H5V2H7V17H15ZM17 22V7H9V5H18C18.2652 5 18.5196 5.10536 18.7071 5.29289C18.8946 5.48043 19 5.73478 19 6V17H22V19H19V22H17Z" />
    </g>
    <defs>
      <clipPath id="clip0_5042_457626">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
