import React from 'react';

export const Contacts = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00279 1.83594C4.73851 1.83594 2.82422 3.57427 2.82422 5.81283C2.82422 7.31486 3.07818 8.91886 3.69712 10.1774C4.32327 11.4506 5.39267 12.4788 7.00279 12.4788C8.57273 12.4788 9.63815 11.4429 10.2708 10.183C10.9012 8.92764 11.1814 7.32417 11.1814 5.81283C11.1814 3.57427 9.26707 1.83594 7.00279 1.83594ZM1.0701 12.2217C1.55154 11.4996 2.18723 10.9321 2.81007 10.519C3.6012 12.0126 5.16391 13.5798 7.00042 13.5798C8.87897 13.5798 10.4426 12.3388 11.1996 10.7998C11.245 10.7077 11.2877 10.6127 11.3281 10.515C12.7803 11.4689 13.5462 13.0901 13.8789 14.2822C14.0422 14.8672 13.8276 15.4882 13.3081 15.8027C12.0315 16.5756 9.78549 17.2217 6.57031 17.2217C2.57031 17.2217 0.501509 16.0845 0.0701048 15.2217C-0.0965619 14.8883 -0.03942 13.8857 1.0701 12.2217Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.63867 1.16284C9.33972 0.443334 10.3412 0 11.432 0C13.4923 0 15.2342 1.58176 15.2342 3.61871C15.2342 4.99393 14.9793 6.45297 14.4057 7.59528C13.9247 8.55308 13.1689 9.36859 12.1002 9.61103C11.9732 9.50919 11.8394 9.40574 11.6981 9.2998C12.1229 8.02559 12.0882 6.65531 12.0681 5.863C12.0645 5.7226 12.0614 5.60035 12.0614 5.5C12.0128 2.77378 10.0964 1.56435 8.63867 1.16284ZM14.7883 13.6127C15.7571 13.3881 16.5264 13.0845 17.0963 12.7526C17.6211 12.4469 17.8369 11.8239 17.6664 11.2411C17.3383 10.1195 16.5972 8.62124 15.2019 7.79228L15.1981 7.79995C14.7456 8.71997 13.8989 9.72603 12.8866 10.3079C13.7572 11.1774 14.2325 12.0807 14.7883 13.6127Z"
      fill="fill"
    />
  </svg>
);
