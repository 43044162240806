import React, { PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { breakPoint } from '../theme';
import { Drawer } from '../../core/stores/drawer';
import { uiStore } from '../../core/stores/ui-store';

export const ContextualDrawer: React.FC<PropsWithChildren> = observer(({ children }) => {
  useEffect(() => {
    uiStore.contextDrawer = new Drawer();

    return () => {
      uiStore.contextDrawer = undefined;
    };
  }, []);

  return ReactDOM.createPortal(
    <Container data-is-closed={!uiStore.contextDrawer?.isOpen}>{children}</Container>,
    document.getElementById('contextual-drawer') as HTMLElement,
  );
});

const Container = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  z-index: 1002;
  height: calc(100% - var(--tab-bar-height));
  width: calc(100% - 5.6rem);
  max-width: 32rem;
  position: absolute;
  right: 0;
  top: var(--tab-bar-height);
  background: var(--color-grayscale-shark);
  padding: 2.4rem 1.6rem;
  transition: all 200ms ease-in;
  transform: translateX(0);

  &[data-is-closed='true'] {
    transform: translateX(100%);
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: none;
  }
`;
