import React from 'react';
import { useMsal } from '@azure/msal-react';
import styled from 'styled-components';

import { Note } from '../../../components/note/note';

export interface MarketplaceSubscriptionDetails {
  email: string;
  planName: string;
  subscriptionName: string;
  tenantId: string;
}

type SubscriptionDetailsProps = {
  subscriptionDetails: MarketplaceSubscriptionDetails;
};

export const SubscriptionDetailsPage: React.FC<SubscriptionDetailsProps> = ({
  subscriptionDetails,
}) => {
  const { accounts } = useMsal();

  return (
    <Container>
      <Note>
        Please verify the following information is correct before submitting your subscription.
      </Note>

      <Items>
        <div>
          <h3>User name</h3>
          <p>{accounts[0].name}</p>
        </div>

        <div>
          <h3>Email</h3>
          <p>{subscriptionDetails.email}</p>
        </div>

        <div>
          <h3>Plan</h3>
          <p>{subscriptionDetails.planName}</p>
        </div>

        <div>
          <h3>Subscription name</h3>
          <p>{subscriptionDetails.subscriptionName}</p>
        </div>

        <div>
          <h3>Tenant ID</h3>
          <p>{subscriptionDetails.tenantId}</p>
        </div>
      </Items>
    </Container>
  );
};

const Container = styled.div`
  & div:first-child {
    margin: 1.6rem 0 2.4rem 0;
  }
`;

const Items = styled.div`
  & div:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  & h3 {
    font-weight: 600;
    font-size: 1.4rem;
    color: var(--color-grayscale-light-slate);
  }

  & p {
    font-weight: 400;
    font-size: 1.4rem;
    color: var(--color-grayscale-white);
  }
`;
