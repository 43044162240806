import React from 'react';
import { FileTextIcon, Link2Icon, PlusIcon } from '@radix-ui/react-icons';
import { useResponsive } from 'hooks/useResponsive';
import styled from 'styled-components';

import { DropdownMenu } from '../../../../components/modals/dropdown-menu/DropdownMenu';
import { DropdownTrigger } from '../../../../features/projects/components/styled-project-components';
import { UploadButton } from './upload-button';
import { breakPoint } from '../../../../app/theme';

type FilterButtonProps = {
  onSelectFile: (files: File[]) => Promise<void>;
  onSelectLink: (event: Event) => void;
  onSelectNote: (event: Event) => void;
};

export const AddAssetButton: React.FC<FilterButtonProps> = ({
  onSelectFile,
  onSelectLink,
  onSelectNote,
}) => {
  const { isDesktop } = useResponsive();

  const items: React.ComponentProps<typeof DropdownMenu>['items'] = [
    {
      customComponent: (
        <UploaderWrapper>
          <UploadButton
            key="upload"
            text="Add file"
            onUploadFileValue={onSelectFile}
            shouldAddFloatButton={false}
            isIcon
            multiple
          />
        </UploaderWrapper>
      ),
    },
    { title: 'Add link', icon: <Link2Icon />, onSelect: onSelectLink },
    { title: 'Add note', icon: <FileTextIcon />, onSelect: onSelectNote },
  ];

  return (
    <DropdownMenuWrapper
      items={items}
      size="free-size"
      side={isDesktop ? 'bottom' : 'top'}
      trigger={
        isDesktop ? (
          <DropdownTrigger>
            <PlusIcon />
          </DropdownTrigger>
        ) : (
          <Button>
            <PlusIcon />
          </Button>
        )
      }
    />
  );
};

const DropdownMenuWrapper = styled(DropdownMenu)`
  @media screen and (max-width: ${breakPoint.medium}px) {
    backdrop-filter: unset;
    background-color: unset;
    animation: overlayShow 500ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
    border-radius: 0.4rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    @keyframes overlayShow {
      from {
        height: 0;
      }
      to {
        height: 11.5rem;
      }
    }

    //& > div:not(:last-child) {
    //  margin-bottom: 0.8rem;
    //}

    //& > div:last-child {
    //  & > div {
    //    background-color: transparent;
    //  }
    //}

    div {
      background-color: var(--color-grayscale-arsenic);
      border-radius: 0.4rem;

      &:hover {
        cursor: pointer;
        background-color: var(--color-grayscale-charleston);
      }
    }
  }
`;

const Button = styled.button`
  position: fixed;
  bottom: 2.4rem;
  right: 2.4rem;
  width: 4.2rem;
  height: 4.2rem;
  border-radius: var(--border-radius-medium);
  background-color: var(--color-secondary);
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
  border: none;
  outline: none;
  color: white;
  z-index: 1000;

  &:active {
    background-color: var(--color-shades-science);
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: none;
  }
`;

const UploaderWrapper = styled.div`
  div {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    transition: 0.1s ease-in background-color;
    user-select: none;
    background-color: rgba(124, 124, 163, 0.125);
    color: var(--color-grayscale-manatee);
    border-radius: 0.4rem;
  }

  button {
    width: 100%;
    background-color: transparent !important;
    color: rgb(228, 229, 241);
    font-size: 1.4rem;
    font-weight: 400;
    box-sizing: border-box;
    padding: 0 0.8rem;

    &:focus {
      outline: 0 !important;
    }

    > svg {
      color: rgb(228, 229, 241);
    }
  }
`;
