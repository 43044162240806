import React from 'react';
import ReactSelectCreatable from 'react-select/creatable';

import { defaultStyles, mergeSelectStyles } from '../../utils';

import type { SelectRefType, SelectProps } from '../../types';

type CreatableSelectProps = SelectProps & {
  onCreate?: (label: string) => void;
  createLabelPrefix?: string;
};

export const CreatableSelect = React.forwardRef<SelectRefType, CreatableSelectProps>(
  (
    {
      options,
      value,
      defaultValue = [],
      isClearable = false,
      styles,
      onCreate,
      onChange,
      isMulti,
      isLoading,
      isDisabled,
      createLabelPrefix,
      ...props
    },
    ref,
  ) => {
    const currentStyles = mergeSelectStyles(defaultStyles(), styles);

    return (
      <ReactSelectCreatable
        ref={ref}
        {...props}
        options={options}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        styles={currentStyles}
        isClearable={isClearable}
        isMulti={isMulti}
        isLoading={isLoading}
        isDisabled={isLoading || isDisabled}
        formatCreateLabel={(inputValue) => `${createLabelPrefix || 'Create'} "${inputValue}"`}
        {...(onCreate ? { onCreateOption: onCreate } : undefined)}
      />
    );
  },
);

CreatableSelect.displayName = 'CreatableSelect';
