import { Stage } from '../../app/entities/stage';
import { Step } from '../../app/entities/step';

export const calculatePercentage = (value: number, total: number): number => {
  if (total <= 0) {
    return 0;
  }

  const percentage = (value / total) * 100;

  if (isNaN(percentage)) {
    return 0;
  }

  return percentage;
};

export const getDone = (stages: Stage[]) => {
  let sum = 0;
  let done = 0;
  stages.forEach((stage: any) => {
    sum = stage.steps.length + sum;
    done = stage.steps.filter((el: Step) => el.done).length + done;
  });

  return { sum, done };
};
