import React from 'react';

import { BurgerMenuIcon } from '../../../../components/icons';
import { DropdownMenu } from '../../../../components/modals/dropdown-menu/DropdownMenu';
import { SORTING_TYPES } from '../../../../assets/enums/assetType.enum';
import { DropdownTrigger } from '../../../../features/projects/components/styled-project-components';

type FilterButtonProps = {
  value: string;
  onSelect: (value: string) => void;
  sortTypes?: string[];
};

export const FilterButton: React.FC<FilterButtonProps> = ({ value, onSelect, sortTypes }) => {
  const items: React.ComponentProps<typeof DropdownMenu>['items'] = (
    sortTypes || Object.values(SORTING_TYPES)
  ).map((item) => ({
    title: item,
    isChecked: value === item,
    onCheckedChange: (isChecked) => {
      if (isChecked) {
        onSelect(item);
      }
    },
  }));

  return (
    <DropdownMenu
      items={items}
      trigger={
        <DropdownTrigger>
          <BurgerMenuIcon />
        </DropdownTrigger>
      }
    />
  );
};
