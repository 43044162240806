import { api } from 'api';
import { AxiosRequestConfig } from 'axios';
import { BufferToBlob } from '../../../lib/utils/BufferToBolb';
import { createFileName } from '../../../core/services/file.service';

const config: AxiosRequestConfig = {
  responseType: 'arraybuffer',
  headers: {
    Accept: 'application/pdf',
  },
};

/**
 * Download schedule as PDF
 */
export const downloadSchedule = async (projectId: string, projectName: string) => {
  try {
    const { data } = await api.get(`/pdf/schedule/${projectId}`, config);
    const fileName = createFileName(projectName, 'Schedule.pdf');
    BufferToBlob(data, fileName);
    return true;
  } catch (e) {
    // TODO end error to sentry
    return false;
  }
};
