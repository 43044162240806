import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { BaseDialog, DialogFooter } from '../dialog';
import { ElevatedButton, OutlinedButton } from '../buttons';
import { Space } from '../../app/entities/space';
import { insufficientStorageStore } from '../../core/stores/insufficient-storage';
import { CrossCircledFilledIcon } from '../icons/cross-circled-filled.icon';

export type InsufficientStorageDialogProps = {
  onCancel: () => void;
  spaceId: string;
};

export const InsufficientStorageDialog: React.FC<InsufficientStorageDialogProps> = observer(
  ({ onCancel, spaceId }) => {
    const space = Space.getOne(spaceId);
    const navigate = useNavigate();

    const hasSubscription = space!.hasSubscription();
    const buttonText = hasSubscription ? 'Upgrade storage' : 'Upgrade plan';

    const handleClick = () => {
      if (hasSubscription) {
        navigate(`/space/${spaceId}/settings/storage`);
      } else {
        navigate(`/space/${spaceId}/settings/plans`);
      }
      insufficientStorageStore.setState(false);
    };

    return (
      <BaseDialog onClose={onCancel}>
        <Container>
          <Icon>
            <CrossCircledFilledIcon />
          </Icon>

          <h1>Storage limit exceeded</h1>
          <p>
            Your upload could not be completed due to insufficient storage. You have the option to
            book additional storage or upgrade your plan.
          </p>
        </Container>

        <DialogFooter
          actions={[
            <OutlinedButton key="cancel" text="Cancel" onClick={onCancel} />,
            <ElevatedButton key="upgrade" onClick={handleClick} text={buttonText} autoFocus />,
          ]}
        />
      </BaseDialog>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.8rem 3.2rem 2.4rem;
  max-width: 47.6rem;
  text-align: center;
  color: white;

  h1 {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 400;
    margin-bottom: 1.2rem;
  }

  p {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
  }
`;

const Icon = styled.div`
  position: relative;
  height: 9.6rem;
  width: 9.6rem;
  border: 0.2rem solid var(--color-error);
  background: var(--color-error-20);
  border-radius: 50%;
  margin-bottom: 3.2rem;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-error);
    height: 3.6rem;
    width: 3.6rem;
  }
`;
