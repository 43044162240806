import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { Project } from '../../../app/entities/project';
import { ProjectSettingsForm } from './project-settings-form';
import { DangerZone } from '../../../features/projects/components/danger-zone';

interface SettingsTabProps {
  project?: Project;
}

export const SettingsTab: React.FC<SettingsTabProps> = observer(({ project }) => (
  <Container>
    <ProjectSettingsForm project={project} />

    <DangerZone project={project} />
  </Container>
));

const Container = styled.div`
  margin-top: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  padding-bottom: 2.4rem;
`;
