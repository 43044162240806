import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { KeyboardKeys } from '../../assets/enums/keyboard-keys.enum';

interface TextAreaDrawerProps {
  value: string;
  onChange: (value: string) => void;
}

export const TextAreaDrawer: React.FC<TextAreaDrawerProps> = ({ value, onChange }) => {
  const [_value, setValue] = useState<string>(value || '');
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    if (_value !== value) {
      onChange(_value.trim());
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === KeyboardKeys.Escape) {
      setValue(value);
    }

    if (e.key === KeyboardKeys.Enter && !e.shiftKey) {
      inputRef!.current?.blur();
    }
  };

  return (
    <Container>
      <Input
        ref={inputRef}
        value={_value}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        rows={3}
      />
    </Container>
  );
};

interface ContainerProps {
  selected?: boolean;
  reply?: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  transition: all 100ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s;

  &:hover {
    cursor: pointer;
  }
`;

const Input = styled.textarea`
  min-height: 6.4rem;
  background-color: transparent;
  padding: 0.8rem 0.8rem 0.8rem;
  outline: none;
  flex: 1;
  width: 100%;
  border: 1px solid var(--select-border-color);
  color: var(--select-text-color);
  border-radius: var(--card-border-radius);
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  resize: vertical;
`;
