const parseTime = (time: number) => {
  return time.toString().length === 1 ? `0${time}` : time || '00';
};

export const timeParser = (time: string | number) => {
  if (typeof time === 'string' && /^([0-1]?[0-9]?|2[0-3]?):[0-5]?[0-9]?$/.test(time)) {
    const splitTime = time.split(':');
    return Number(splitTime[0]) * 60 + Number(splitTime[1]);
  } else {
    const hours = Math.floor(+time / 60);
    const min = +time % 60;
    return [parseTime(hours), ':', parseTime(min)].join('');
  }
};
