import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import { InfoTextLine } from '../texts/Texts';
import { DownArrow } from '../icons/down-arrow';
import { BlueCheckmark } from '../icons/BlueCheckmark';
import { useOnClickOutside } from '../../hooks/useOutsideClick';
import {
  StyledContainer,
  StyledEmpty as Empty,
  StyledLine,
  OptionsList,
  DescriptiveOptionLabel,
  DescriptiveOptionDescription,
} from './StyledDropdown';
import { useAdaptiveDropdown } from '../../hooks/useAdaptiveDropdown';
import { DropdownProps } from '.';

export const InlineDescriptiveDropdown: React.FC<DropdownProps> = ({
  value,
  label,
  onChange,
  options,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState<{
    x?: number;
    y?: number;
  }>();
  const { outOfBoundRef, dropdownButtonDimensions } = useAdaptiveDropdown({
    isOpen,
    setPosition,
  });

  const { ref } = useOnClickOutside(() => {
    setIsOpen(false);
  });

  const handleChange = (value: string) => {
    onChange(value);
    setIsOpen(false);
  };

  const chosenElement = options.find((el) => el.value === value);
  const optionsLastIndex = options?.length - 1;

  const handleOpenDropdown = (e: React.MouseEvent<HTMLElement>) => {
    if (!isOpen) {
      const bounds = e.currentTarget.getBoundingClientRect();

      setPosition({
        x: bounds.x,
        y: bounds.y + bounds.height + 4, // 4 px margin between the dropdown button and options menu
      });
    }
    setIsOpen((currIsOpen) => !currIsOpen);
  };

  return (
    <Container onClick={handleOpenDropdown}>
      <Line ref={dropdownButtonDimensions}>
        {value ? <InfoTextLine value={chosenElement?.label} /> : <Empty>{label}</Empty>}
        <DownArrow />
      </Line>
      {isOpen &&
        position &&
        ReactDOM.createPortal(
          <Option
            style={{
              top: position.y,
              left: position.x,
              position: 'fixed',
              zIndex: 9999,
            }}
            ref={(el) => {
              ref.current = el;
              outOfBoundRef.current = el;
            }}
          >
            {options!.map((el, index) => (
              <React.Fragment key={el.value}>
                <ListItem
                  data-active={el.value === value}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleChange(el.value);
                  }}
                >
                  <DescriptiveOptionLabel>
                    {el.label} {el.value === value && <BlueCheckmark />}
                  </DescriptiveOptionLabel>
                  <DescriptiveOptionDescription>{el?.description} </DescriptiveOptionDescription>
                </ListItem>
                {index !== optionsLastIndex && <Separator />}
              </React.Fragment>
            ))}
          </Option>,
          document.getElementById('dropdown-root') as HTMLElement,
        )}
    </Container>
  );
};

const Container = styled(StyledContainer)`
  margin: 0rem -1.2rem;

  svg {
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 0.5rem;
  }
`;

const Line = styled(StyledLine)`
  align-items: center;
  justify-content: start;
  width: fit-content;
  height: 3rem;
`;

const Option = styled(OptionsList)`
  max-width: 43.2rem;
  padding: 0.8rem;
  min-width: 0;
`;

const Separator = styled.hr`
  margin: 0.8rem 0;
  border: 1px solid var(--color-grey-8);
`;

const ListItem = styled.li`
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  gap: 0.2rem;
`;
