import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { SettingsTable } from '../../../components/settings-table/settings-table';
import { MemberItem } from '../../settings/components/member-item';

import { sortBy } from '../../settings/helpers/sortBy';
import type { Space } from 'app/entities/space';

type MembersTabProps = {
  space: Space;
};

export const MembersTab: React.FC<MembersTabProps> = observer(({ space }) => {
  const members = useMemo(
    () =>
      sortBy(
        space.members.filter((member) => member.role !== 'guest'),
        'invitedAt',
        'desc',
      ),
    [space.members],
  );
  const tableHeaders = ['', 'Full name', 'Email', 'Role'];

  return (
    <SettingsTable headers={tableHeaders}>
      {members.map((member) => (
        <MemberItem key={member?._id} member={member} />
      ))}
    </SettingsTable>
  );
});
