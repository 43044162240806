import React from 'react';
import styled from 'styled-components';
import { Avatar } from '@producer-io/ui-kit';
import { breakPoint } from '../../../app/theme';
import { getNameInitials } from '../../../lib/utils/get-name-initials';

type ProjectContactProps = {
  avatar: string;
  position: string;
  name: string;
};

export const ProjectContactItem: React.FC<ProjectContactProps> = ({ avatar, position, name }) => (
  <Container>
    <Avatar src={avatar} theme="dark" initials={getNameInitials(name)} />

    <Content>
      <Name>{name}</Name>
      <Position>{position}</Position>
    </Content>
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    padding: 0.4rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Name = styled.p`
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--color-grayscale-white);

  @media screen and (min-width: ${breakPoint.medium}px) {
    font-size: 1.6rem;
    font-weight: 600;
  }
`;

const Position = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--color-grayscale-light-slate);

  @media screen and (min-width: ${breakPoint.medium}px) {
    font-size: 1.4rem;
    font-weight: 500;
  }
`;
