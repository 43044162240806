import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react-lite';

type TabsMenu = {
  key: string;
  label: string;
  elementId?: string;
};

type TabsProps = React.PropsWithChildren<{
  tabs: TabsMenu[];
  selectedTab?: string;
  onChange?: (value: string) => void;
}>;

export const Tabs: React.FC<PropsWithChildren<TabsProps>> = observer(
  ({ children, tabs, selectedTab, onChange }) => {
    return (
      <Wrapper>
        <TabsContainer>
          {tabs.map((tab) => (
            <Tab
              id={tab.elementId || `project-${tab.key}-tab`}
              key={tab.key}
              active={tab.key === selectedTab}
              onClick={() => !!onChange && onChange(tab.key)}
            >
              {tab.label}
            </Tab>
          ))}
        </TabsContainer>

        {children && <Content>{children}</Content>}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  position: relative;
  height: 100%;
  padding-bottom: 2rem;
`;

interface TabProps {
  active?: boolean;
}

const Content = styled.div`
  flex: 1;
  min-height: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  max-width: 100%;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  height: 4rem;
  border-bottom: 1px solid var(--color-grey-7);

  a {
    text-decoration: none;

    &:not(:first-child) {
      margin-left: 0.8rem;
    }
  }
`;

const Tab = styled.div<TabProps>`
  position: relative;
  padding: 0.8rem 2.4rem;
  color: #494e5a;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  height: 4rem;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: var(--border-radius-medium) var(--border-radius-medium) 0 0;
  cursor: pointer;

  &:after {
    content: '';
    display: ${({ active }) => (active ? 'block' : 'none')};
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 80%;
    background-color: var(--color-secondary);
    left: 50%;
    transform: translateX(-50%);
  }

  ${({ active }) =>
    active
      ? css`
          color: #ffffff;
          border: 1px solid var(--color-grey-7);
          border-bottom: none;
          background-color: var(--bg-color-dark);
        `
      : ''}
`;
