import React from 'react';
import styled from 'styled-components';

import type { Shot } from '../../../app/entities/shot';
import { ShotCharactersSelector } from '../../schedule/components/shot-characters-selector';

interface CharactersSelectProps {
  key: string;
  shot: Shot;
  isShotsBulkUpdate?: boolean;
  handleShotsBulkUpdate?: ({
    characterIdToRemove,
    characterIdToAdd,
  }: {
    characterIdToRemove?: string;
    characterIdToAdd?: string;
  }) => void;
}

export const CharactersSelect: React.FC<CharactersSelectProps> = ({
  shot,
  handleShotsBulkUpdate,
  isShotsBulkUpdate = false,
}) => {
  const handleRemoveCharacter = (characterIdToRemove: string) => {
    isShotsBulkUpdate
      ? handleShotsBulkUpdate!({ characterIdToRemove })
      : shot.deleteCharacter(characterIdToRemove);
  };

  const handleAddCharacter = (characterIdToAdd: string) => {
    isShotsBulkUpdate
      ? handleShotsBulkUpdate!({ characterIdToAdd })
      : shot.addCharacter(characterIdToAdd);
  };

  return (
    <Container onClick={(e: React.MouseEvent) => e.stopPropagation()}>
      <Label>Characters</Label>

      <ShotCharactersSelector
        shot={shot}
        onAdd={handleAddCharacter}
        onRemove={handleRemoveCharacter}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: 1rem;
  z-index: 2;

  .trigger {
    border: 1px solid var(--color-grayscale-trout);

    &:hover {
      border-color: var(--text-color-white);
    }
  }
`;

const Label = styled.div`
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: 0.7px;
  line-height: 1.1rem;
  color: var(--select-text-color);
`;
