import { Modifier } from '../contexts/selection.context';

export const getModifier = (event: MouseEvent): Modifier | undefined => {
  if (event.ctrlKey || event.metaKey) {
    return 'ctrl';
  }

  if (event.shiftKey) {
    return 'shift';
  }
};
