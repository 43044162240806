import Hotjar from '@hotjar/browser';

type HotjarConfig = {
  siteId: number;
  version: number;
};

export const initHotjar = (config: HotjarConfig) => {
  Hotjar.init(config.siteId, config.version);
};
