import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MeasuringConfiguration,
  MeasuringStrategy,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from 'styled-components';

import { CreateShotCard } from './create-shot-card';
import { Layout, ShotCard, ShotCardProps } from './ShotCard';
import { useSelection } from '../../../core/contexts/selection.context';

import type { StoryboardSettings } from '../models/storyboard-settings';
import { Storyboard } from '../../../app/entities/storyboard';
import { authStore } from '../../../core/stores/auth-store';
import { Shot } from '../../../app/entities/shot';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';

const measuring: MeasuringConfiguration = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

type SortableShotGridProps = {
  storyboard: Storyboard;
  options: StoryboardSettings;
  stepId: string;
  zoomLevel?: number;
};

export const SortableShotGrid: React.FC<SortableShotGridProps> = observer(
  ({ storyboard, options, zoomLevel }) => {
    const { elements, select, clear, selectAll } = useSelection();
    const items =
      storyboard?.shots
        ?.slice()
        ?.sort((a, b) => a.position - b.position)
        ?.map((el) => el._id) || [];
    const [copiedElements, setCopiedElements] = useState<string[]>([]);
    const [showDeleteShotsDialog, setShowDeleteShotsDialog] = useState(false);

    const currentMember = storyboard?.project?.spaceId
      ? authStore.getCurrentMember(storyboard.project.spaceId)
      : undefined;

    const storyboardPreferences = currentMember?.preferences?.storyBoardPreferences;

    const [activeId, setActiveId] = useState<string | null>(null);
    const activeIndex = activeId ? items.indexOf(activeId) : -1;
    const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 6 } }));

    const handleDragStart = ({ active }: DragStartEvent) => {
      select(active.id.toString());
      setActiveId(active.id.toString());
    };

    const handleDragCancel = () => {
      setActiveId(null);
    };

    const handleDragEnd = ({ over, active }: DragEndEvent) => {
      if (over) {
        const overIndex = items.indexOf(`${over.id}`);

        if (activeIndex !== overIndex) {
          const destination = storyboard!.shots.find((el) => el._id === over.id);

          if (!destination) {
            return;
          }

          storyboard!.edit(elements, destination.position);
        }
      }
      clear();
      setCopiedElements([]);
      setActiveId(null);
    };

    useEffect(() => {
      const handleKeyDown = async (e: KeyboardEvent) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
          if (elements.length) {
            e.preventDefault();
            setCopiedElements(elements);
          }
        }

        if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
          if (copiedElements.length) {
            e.preventDefault();
            await Shot.duplicateShots(copiedElements);
            setCopiedElements([]);
          }
        }

        if ((e.ctrlKey || e.metaKey) && e.key === 'a') {
          e.preventDefault();
          selectAll(items);
        }

        if (e.key === 'Delete') {
          if (elements.length) {
            e.preventDefault();
            setShowDeleteShotsDialog(true);
          }
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [elements, copiedElements]);

    const handleDeleteShots = async () => {
      await Shot.deleteShots(elements);
      setShowDeleteShotsDialog(false);
    };

    return (
      <>
        {showDeleteShotsDialog && (
          <DeleteDialog
            onCancel={() => {
              setShowDeleteShotsDialog(false);
            }}
            onSubmit={() => {
              handleDeleteShots();
            }}
            text={`Are you sure you want to delete ${elements.length} shots`}
            title={'Delete multiple shots'}
          />
        )}
        <DndContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
          sensors={sensors}
          collisionDetection={closestCenter}
          measuring={measuring}
        >
          <SortableContext items={items}>
            <Container data-zoom={zoomLevel}>
              {items.map((id, index) => (
                <SortableShot
                  key={id}
                  shotId={id}
                  projectId={storyboard.projectId}
                  storyboardId={storyboard._id}
                  position={index + 1}
                  layout={Layout.Grid}
                  options={options}
                  isSelected={elements.includes(id)}
                  isActive={!!activeId}
                  readOnly={!currentMember || currentMember.role === 'guest'}
                  storyboardPreferences={storyboardPreferences}
                />
              ))}

              <CreateShotCard storyboard={storyboard} zoom={storyboardPreferences?.zoom || 1} />
            </Container>
          </SortableContext>

          <DragOverlay>
            {activeId ? (
              <PageOverlay
                shotId={activeId}
                projectId={storyboard.projectId}
                storyboardId={storyboard._id}
                layout={Layout.Grid}
                items={items}
                options={options}
                storyboardPreferences={storyboardPreferences}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
  margin: 0;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  min-width: calc(100% - var(--spacing-inline-end-screen-container-child));
  padding-bottom: var(--spacing-bottom-screen-container);

  &[data-zoom='4'],
  &[data-zoom='3'],
  &[data-zoom='2'],
  &[data-zoom='1'] {
    display: grid;
    grid-gap: 1.6rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    overflow-x: hidden;
  }

  @media screen and (min-width: 768px) {
    display: grid;
    grid-gap: 1.6rem;
    grid-template-columns: repeat(auto-fill, calc((100% - (2 * 0.8rem)) / 2));
    overflow-x: hidden;
  }

  @media screen and (min-width: 1224px) {
    &[data-zoom='6'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 1.6rem)) / 3));
    }
    &[data-zoom='5'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 2.4rem)) / 4));
    }

    // Starting from here, the cards should hide their details
    &[data-zoom='4'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 3.2rem)) / 5));
    }
    &[data-zoom='3'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 4rem)) / 6));
    }
    &[data-zoom='2'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 4.8rem)) / 7));

      #photo-uploader {
        > div {
          gap: 1.2rem;
          > div {
            margin: 0;
          }
        }

        span {
          display: none;
        }
      }
    }

    &[data-zoom='1'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 5.6rem)) / 8));
      #photo-uploader {
        > div {
          gap: 1.2rem;
          > div {
            margin: 0;
          }
        }

        span {
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: 2000px) {
    &[data-zoom='6'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 2.4rem)) / 4));
    }
    &[data-zoom='5'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 3.2rem)) / 5));
    }
    &[data-zoom='4'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 4rem)) / 6));
    }

    // Starting from here, the cards should hide their details
    &[data-zoom='3'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 4.8rem)) / 7));
    }
    &[data-zoom='2'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 5.6rem)) / 8));
    }
    &[data-zoom='1'] {
      grid-template-columns: repeat(auto-fill, calc((100% - (2 * 6.4rem)) / 9));
    }
  }
`;

const PageOverlay = observer(
  ({ shotId, items, ...props }: Omit<ShotCardProps, 'index'> & { items: string[] }) => {
    return (
      <ShotCard
        shotId={shotId}
        style={{
          cursor: 'grabbing',
          borderRadius: '0.6rem',
          border: '1.5px solid var(--color-secondary)',
        }}
        {...props}
      />
    );
  },
);

const SortableShot = observer(
  ({
    shotId,
    storyboardId,
    isSelected,
    isActive,
    readOnly,
    ...props
  }: ShotCardProps & {
    isSelected?: boolean;
    isActive?: boolean;
    readOnly?: boolean;
  }) => {
    const { select } = useSelection();
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
      id: shotId,
      animateLayoutChanges: always,
      transition: {
        duration: 150,
        easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
      },
      disabled: readOnly,
    });

    const isDraggingCard = isActive && isSelected;

    const style = {
      transition,
      transform: CSS.Translate.toString(transform),
      borderRadius: '0.6rem',
      border: '1.5px solid transparent',
      ...(isSelected &&
        !isDraggingCard && {
          border: '1.5px solid var(--color-secondary)',
        }),
      disabled: readOnly,
    };

    return (
      <ShotCard
        ref={setNodeRef}
        shotId={shotId}
        storyboardId={storyboardId}
        active={isDraggingCard}
        style={style}
        onClick={select}
        {...props}
        {...attributes}
        {...listeners}
      />
    );
  },
);

const always = () => {
  return true;
};
