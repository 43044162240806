import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

import { Plus } from '../icons';
import { useFileDropzone } from '../../hooks/useFileDropzone';

export type ProfilePictureProps = {
  onChange?: (file: File) => void;
  onMultipleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  buttonName?: string;
  placeholderTitle?: string;
  placeholderDescription?: string;
  accept?: string;
  multiple?: boolean;
};

export const PhotoUploader: React.FC<ProfilePictureProps> = ({
  onChange,
  onMultipleChange,
  buttonName = 'Add Photo',
  placeholderTitle = 'Drag and drop',
  placeholderDescription = 'JPG, PNG file size no more that 3MB',
  accept = 'image/*',
  multiple,
}) => {
  const [_, setUrl] = useState<string | undefined>();

  const handleChange = (file: File) => {
    setUrl(URL.createObjectURL(file));
    onChange && onChange(file as File);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    if (!event.currentTarget.files) {
      return;
    }

    if (multiple && onMultipleChange) {
      return onMultipleChange(event);
    }

    const file = event.currentTarget.files![0];
    handleChange(file);
  };

  const { handleDrop } = useFileDropzone({
    handler: handleChange,
  });

  return (
    <UploadContainer onDrop={handleDrop} id="photo-uploader" onClick={(e) => e.stopPropagation()}>
      <Placeholder>
        <Icon>
          <Plus />
        </Icon>

        {buttonName}

        <span>
          {placeholderTitle} <br />
          {placeholderDescription}
        </span>

        <InputFile
          type="file"
          accept={accept}
          name="file"
          onChange={handleFileChange}
          multiple={multiple}
        />
      </Placeholder>
    </UploadContainer>
  );
};

const Placeholder = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(54, 123, 255, 0.12);
  color: var(--color-secondary);
  font-size: 1.6rem;
  padding: 0 2rem;
  span {
    display: inline-block;
    margin-top: 0.8rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: rgba(255, 255, 255, 0.4);
    text-align: center;
  }
  &:hover {
    cursor: pointer;
  }
`;

const Icon = styled.div`
  height: 3.2rem;
  width: 3.2rem;
  border-radius: var(--border-radius-medium);
  cursor: pointer;
  display: flex;
  font-size: 2.4rem;
  align-items: center;
  justify-content: center;
  margin-right: 0.8rem;
  background-color: var(--color-secondary);
  color: #273046;
  margin-bottom: 1.2rem;
`;

const InputFile = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-size: 0;
  filter: alpha(opacity=0);
  opacity: 0;
  z-index: 99;
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: var(--border-radius-medium);
  border: 1.5px dashed var(--color-secondary);
`;
