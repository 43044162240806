import { action, makeObservable, observable } from 'mobx';

export class Drawer {
  isOpen: boolean = false;

  constructor() {
    this.isOpen = false;

    makeObservable(this, {
      isOpen: observable,
      toggle: action,
      open: action,
      close: action,
    });
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }
}
