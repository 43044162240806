import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { Stripboard } from '../../../app/entities/stripboard';
import { timeParser } from '../../../lib/utils/time-parser';
import { BaseBlock } from './styled-strips';
import { AddStripMenu } from './add-strip-menu';

import { BannerStrip } from '../models/types';

interface MobileBreakStripProps {
  strip: BannerStrip;
  stripboard: Stripboard;
}

export const MobileBreakStrip: React.FC<MobileBreakStripProps> = observer(
  ({ stripboard, strip }) => {
    return (
      <Container>
        <Block>
          <BlockText>{strip.data.text}</BlockText>
        </Block>

        <TimeContainer>
          <span>Est. time</span>
          <span>{timeParser(strip.data.estimatedTime || 0).toString()}</span>
        </TimeContainer>

        <AddStripMenu
          stripId={strip._id}
          projectId={stripboard.projectId}
          position={strip.position}
          isDark
          stripType="banner"
        />
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.4rem;
  padding: 0.8rem;
  border-radius: 0.4rem;
  background-color: var(--color-grayscale-tuna);
`;

const Block = styled(BaseBlock)`
  min-width: 14rem;
  width: 14rem;
`;

const BlockText = styled.span`
  flex: 0 0 auto;
  font-weight: 400;
  font-size: 1.2rem;
  padding: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  font-weight: 400;
  font-size: 1.2rem;
  color: var(--color-grayscale-ghost);
`;
