import React from 'react';

import { CreatableSelect } from '../../../components/select';
import {
  handleRevertWrappingModalOverflow,
  handleWrappingModalOverflow,
} from '../../../components/select/utils';

import type { SelectRefType } from '../../../components/select';

interface FpsSelectProps {
  onChange: (value: number) => void;
  value: number;
  initialValue: number;
}

export const FpsSelect = React.forwardRef<SelectRefType, FpsSelectProps>(
  ({ onChange, value, initialValue }, ref) => {
    const selectableFps = [...new Set([23.98, 24, 25, 29.97, 50, initialValue])];
    const fpsOptions = selectableFps.map((fps) => ({
      label: fps.toString(),
      value: fps.toString(),
      key: fps.toString(),
    }));

    const handleChange = (option: any) => {
      onChange(option.value);
    };

    const selectedValue = fpsOptions.find((option) => +option.value === value);
    const defaultValue = fpsOptions.find((option) => +option.value === initialValue);

    return (
      <CreatableSelect
        ref={ref}
        value={selectedValue}
        defaultValue={defaultValue}
        options={fpsOptions}
        onChange={handleChange}
        createLabelPrefix="Create"
        onMenuOpen={handleWrappingModalOverflow}
        onMenuClose={handleRevertWrappingModalOverflow}
      />
    );
  },
);

FpsSelect.displayName = 'FpsSelect';
