import Dexie, { Table } from 'dexie';

export interface Upload {
  id: string;
  assetId: string;
  name: string;
  status: 'pending' | 'uploading' | 'done' | 'failed' | 'paused' | 'finalizing' | 'cancelled';
  blocks: {
    blockId: string;
    position: number;
    start: number;
    end: number;
    uploaded: boolean;
  }[];
  cursor: number;
  totalSize: number;
  type: string;
  url: string | null;
}

export class ProducerDatabase extends Dexie {
  /**
   * Only use for the typing system, uploads is created dynamically
   * eslint-disable-next-line @typescript-eslint/ban-ts-comment
   * @ts-ignore */
  uploads: Table<Upload>;

  constructor() {
    super('producer-db');

    this.version(1).stores({
      uploads: 'id, name, status, assetId',
    });
  }
}

export const db = new ProducerDatabase();
