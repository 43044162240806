import { computed, makeObservable, observable } from 'mobx';
import { Drawer } from './drawer';

interface PageTitle {
  label: string;
  href?: string;
}

class UIStore {
  private _pageTitle?: PageTitle;
  private _contextDrawer: Drawer | undefined;
  private _showBack: boolean;
  private _action: Function | undefined;

  /**
   * goBack can be overriden by screens to allow for going back in the hierarchy.
   */
  goBack: (() => void) | null = null;

  constructor() {
    makeObservable<UIStore, '_pageTitle' | '_contextDrawer' | '_showBack' | '_action'>(this, {
      _pageTitle: observable,
      _contextDrawer: observable,
      _showBack: observable,
      _action: observable,
      pageTitle: computed,
      contextDrawer: computed,
      showBack: computed,
      action: computed,
    });
  }

  set pageTitle(title: PageTitle | undefined) {
    this._pageTitle = title;
  }

  get pageTitle(): PageTitle | undefined {
    return this._pageTitle;
  }

  set contextDrawer(value: Drawer | undefined) {
    if (this._contextDrawer) {
      console.warn('A drawer is already registered on this screen.');
    }

    this._contextDrawer = value;
  }

  get contextDrawer(): Drawer | undefined {
    return this._contextDrawer;
  }

  set showBack(value: boolean) {
    this._showBack = value;
  }

  get showBack(): boolean {
    return this._showBack;
  }

  set action(value: Function | undefined) {
    this._action = value;
  }

  get action(): Function | undefined {
    return this._action;
  }
}

export const uiStore = new UIStore();
