import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { Step } from '../../../app/entities/step';
import { HeaderSimpleWidget } from './header-simple-widget';
import { WeatherWidget } from './weather-widget';
import { InfoWidget } from './info-widget';
import { breakPoint } from '../../../app/theme';
import { Project } from '../../../app/entities/project';

interface TopShootingDayProps {
  project: Project;
  step: Step;
}

export const ShootingDayHeader: React.FC<TopShootingDayProps> = observer(({ project, step }) => {
  const handleUpdatePolice = async (values: any) => {
    return step.update({
      info: {
        police: values,
      },
    });
  };

  const handleUpdateHospital = async (values: any) => {
    return step.update({
      info: {
        hospital: values,
      },
    });
  };

  const handleUpdateProduction = async (values: any) => {
    return project.update({
      production: values,
    });
  };

  return (
    <WidgetGrid>
      <GridRowWrapper>
        <HeaderSimpleWidget
          project={project}
          text={'Client'}
          src={project.client?.logo?.src || ''}
        />

        <MiddleWidgetContainer>
          <HeaderSimpleWidget
            project={project}
            text={'Agency'}
            src={project.agency?.logo?.src || ''}
          />
        </MiddleWidgetContainer>

        <HeaderSimpleWidget
          project={project}
          text={'Production'}
          src={project.production?.logo?.src || ''}
        />
      </GridRowWrapper>

      <GridRowWrapper>
        <WeatherWidget step={step} />
      </GridRowWrapper>

      <GridRowWrapper>
        <InfoWidget
          stepId={step._id}
          title="Police"
          onUpdate={handleUpdatePolice}
          info={step.info?.police || {}}
        />

        <MiddleWidgetContainer>
          <InfoWidget
            stepId={step._id}
            title="Hospital"
            onUpdate={handleUpdateHospital}
            info={step.info?.hospital || {}}
          />
        </MiddleWidgetContainer>

        <InfoWidget
          stepId={step._id}
          title="Production company"
          onUpdate={handleUpdateProduction}
          info={project.production || {}}
        />
      </GridRowWrapper>
    </WidgetGrid>
  );
});

const WidgetGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  cursor: default;

  @media screen and (min-width: ${breakPoint.medium}px) {
    gap: 1.6rem;
  }
`;

const MiddleWidgetContainer = styled.div`
  min-width: 0;

  @media screen and (min-width: ${breakPoint.medium}px) {
    padding: 0 1.6rem;
  }
`;

const GridRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.8rem;

  &:first-child {
    grid-auto-rows: 1fr;
  }

  @media screen and (max-width: ${breakPoint.medium + 1}px) {
    &:first-child {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    grid-gap: unset;
    grid-template-columns: 33% 34% 33%;

    &:last-child {
      grid-auto-rows: 1fr;
    }
  }
`;
