import React, { useContext, useEffect } from 'react';
import { StyleSheetManager } from 'styled-components';
import { Outlet } from 'react-router-dom';

import { keycloak, updateToken } from '../keycloak';
import { MixpanelContext } from '../wrappers/mixpanel';
import './i18n';
import { ToastContainer } from '../features/toasts/components/toasts-container';
import { setSentryUser } from '../lib/sentry';
import { authStore } from '../core/stores/auth-store';
import { useUserGuiding } from './hooks/use-userguiding';

const windowVisibilityObserver = () => {
  if (!document.hidden && keycloak.isTokenExpired()) {
    updateToken();
  }
};

export const AppLayout = () => {
  const user = authStore.currentUser;

  const { init: initMixpanel, identify } = useContext(MixpanelContext);
  useUserGuiding();

  useEffect(() => {
    if (!user) {
      return;
    }

    if (process.env.NODE_ENV !== 'development') {
      initMixpanel();
      identify(user);
      setSentryUser(user);
    }
  }, [initMixpanel, identify, user]);

  /**
   * Check the authorization status when Producer comes back into view
   */
  useEffect(() => {
    document.addEventListener('visibilitychange', windowVisibilityObserver);
    return () => document.removeEventListener('visibilitychange', windowVisibilityObserver);
  }, []);

  /**
   * Refresh the access token before its expiration to keep the user logged in
   * while he uses the application. Auto-refresh is stopped when the application
   * is not into view.
   */
  useEffect(() => {
    let interval = setInterval(updateToken, 3_540_000);

    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        clearInterval(interval);
      } else {
        interval = setInterval(updateToken, 3540000);
      }
    });

    return () => clearInterval(interval);
  }, []);

  return (
    <StyleSheetManager enableVendorPrefixes>
      <Outlet />
      <ToastContainer />
    </StyleSheetManager>
  );
};
