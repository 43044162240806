import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { StripComponent } from './StripComponent';
import { Strip, DayBreakStrip } from '../models/types';
import { MobileDayStartStrip } from './day-start-strip.mobile';

import type { Stripboard } from '../../../app/entities/stripboard';

type ShootingScheduleTableProps = {
  stripboard: Stripboard;
  strips: Strip[];
  placeholder?: React.ReactNode;
};

export const MobileShootingScheduleTable: React.FC<ShootingScheduleTableProps> = observer(
  ({ stripboard, strips, placeholder }) => {
    const firstDayBreakStrip = strips?.find((strip) => strip.type === 'dayBreak');

    return (
      <Container>
        {placeholder ? (
          placeholder
        ) : (
          <>
            {firstDayBreakStrip && (
              <MobileDayStartStrip
                stripboard={stripboard}
                strip={firstDayBreakStrip as DayBreakStrip}
              />
            )}
            {strips?.map((strip) => (
              <StripComponent
                key={strip._id}
                stripboard={stripboard}
                strip={strip}
                screenType="mobile"
              />
            ))}
          </>
        )}
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;
