export const loadPdfData = async (file: File) => {
  return new Promise<number | undefined>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onerror = (err) => {
      reject(err);
    };
    reader.onloadend = function () {
      if (!reader.result) return;
      const count = reader.result.toString().match(/\/Type[\s]*\/Page[^s]/g)?.length;
      resolve(count);
    };
  });
};
