import React from 'react';

import { useResponsive } from '../../hooks/useResponsive';
import { MobileUserSettingsNavigation } from 'features/user/pages/mobile-user-settings-navigation';
import { ProfilePage } from 'features/user/pages/profile.page';

export const AccountRoute = () => {
  const { isMobile } = useResponsive();
  return isMobile ? <MobileUserSettingsNavigation /> : <ProfilePage />;
};
