import { format } from 'date-fns';
import { defaultFormat } from '../../assets/contants/dates-fns';

export const currentDateTimeAsIso = () => {
  return new Date(Date.now()).toISOString();
};

export const formatDate = (date: string, dateFormat?: string) => {
  return format(new Date(date), dateFormat ? dateFormat : defaultFormat);
};
