import React from 'react';
import styled from 'styled-components';

import { BaseGanttCell } from './styled-gantt.components';
import { getDayColor } from '../utils/utils';

import type { DayProps } from '../types';

type MonthCellProps = {
  day: DayProps['day'];
  month: DayProps['month'];
  year: DayProps['year'];
  isToday: DayProps['isToday'];
  weekDay: DayProps['weekDay'];
  isWeekend: DayProps['isWeekend'];
};

const MonthCellComponent: React.FC<MonthCellProps> = ({
  day,
  month,
  year,
  isToday,
  weekDay,
  isWeekend,
}) => {
  return (
    <Cell
      background={getDayColor({ isToday, isWeekend })}
      id={isToday ? 'today' : `${day}-${month}-${year}`}
      data-today={isToday}
      data-weekend={isWeekend}
      data-day={weekDay}
    >
      {weekDay}

      <br />

      {day}
    </Cell>
  );
};

export const MonthCell = React.memo(MonthCellComponent);

const Cell = styled(BaseGanttCell)<{ background: string }>`
  ${({ background }) => `
    background: 
    repeating-linear-gradient(
        90deg,
        ${background} 0rem 4.7rem,
        transparent 4.7rem 4.8rem
      ), 
      repeating-linear-gradient(
        to bottom,
        transparent 0rem 0.3rem,
        var(--color-surfaces-bg-elevation-2) 0.3rem 0.5rem
      ),
      repeating-linear-gradient(
        90deg,
        transparent 0rem 4.7rem,
        var(--color-surfaces-bg-elevation-4) 4.7rem 4.8rem
      );
    
      &[data-day='Sun'] {
        background: repeating-linear-gradient(
          90deg,
          ${background} 0rem 4.7rem,
          transparent 4.7rem 4.8rem
        ), 
        repeating-linear-gradient(
          90deg,
          transparent 0rem 4.7rem,
          var(--color-surfaces-bg-elevation-4) 4.7rem 4.8rem
        );
      }

      &[data-day='Mon'] {
        background: repeating-linear-gradient(
          90deg,
          var(--color-surfaces-bg-elevation-4) 0rem 0.1rem,
          ${background} 0.1rem 4.7rem, 
          transparent 4.7rem 4.8rem
        ),
        repeating-linear-gradient(
          to bottom,
          transparent 0rem 0.3rem,
          var(--color-surfaces-bg-elevation-2) 0.3rem 0.5rem
        ),
        repeating-linear-gradient(
          90deg,
          transparent 0rem 4.7rem,
          var(--color-surfaces-bg-elevation-4) 4.7rem 4.8rem
        );
      }
  `}
`;
