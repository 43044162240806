import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Pencil1Icon, TrashIcon, DownloadIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { downloadCallSheet } from '../services/callsheet.service';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { ScreenHeader } from '../../projects/components/screen-header';
import { ElevatedButton } from '../../../components/buttons';
import { RenameStepModal } from '../../process/components/rename-step.modal';
import { useResponsive } from '../../../hooks/useResponsive';

import type { Step } from '../../../app/entities/step';
import type { Project } from '../../../app/entities/project';
import { LimitReachedModal } from '../../../components/dialogs/limit-reached.modal';

type ShootingDayHeaderProps = {
  step: Step;
  project: Project;
  selectedTab: 'callsheet' | 'dailies';
};

export const ShootingDayHeader: React.FC<ShootingDayHeaderProps> = observer(
  ({ step, project, selectedTab }) => {
    const { isDesktop } = useResponsive();
    const navigate = useNavigate();

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [showLimitReachedModal, setShowLimitReachedModal] = useState(false);

    const handleDownload = async () => {
      if (!project?.space?.canExportPdf()) {
        return setShowLimitReachedModal(true);
      }

      if (isDownloading) {
        return;
      }

      setIsDownloading(true);

      await downloadCallSheet(step, project.name);

      setIsDownloading(false);
    };

    const handleDelete = async () => {
      await step.delete();
      const href = `../`;
      navigate(href);
    };

    const items: React.ComponentProps<typeof DropdownMenu>['items'] = [
      ...(!isDesktop
        ? [
            {
              title: 'Generate PDF',
              icon: <DownloadIcon />,
              onSelect: handleDownload,
              disabled: isDownloading,
            },
          ]
        : []),
      {
        title: 'Rename shooting day',
        icon: <Pencil1Icon />,
        onSelect: () => setIsRenameModalOpen(true),
      },
      {
        title: 'Delete',
        icon: <TrashIcon />,
        type: 'danger' as const,
        onSelect: () => setIsDeleteModalOpen(true),
      },
    ];

    return (
      <>
        <ScreenHeader
          stepId={step._id}
          title={step.displayName}
          dueDate={step.dueDate}
          startDate={step.startDate}
          handleUpdateTimeFrame={(values) => step.update(values)}
          actions={
            isDesktop && selectedTab === 'callsheet'
              ? [
                  <ElevatedButton
                    key={'download'}
                    onClick={handleDownload}
                    text="Generate PDF"
                    isLoading={isDownloading}
                  />,
                ]
              : []
          }
          menu={<DropdownMenu items={items} size={'large'} />}
        />

        {isDeleteModalOpen && (
          <DeleteDialog
            title="Delete shooting day"
            text="Are you sure you want to delete this shooting day?"
            onCancel={() => setIsDeleteModalOpen(false)}
            onSubmit={handleDelete}
          />
        )}

        {isRenameModalOpen && (
          <RenameStepModal step={step} onCancel={() => setIsRenameModalOpen(false)} />
        )}

        {showLimitReachedModal && (
          <LimitReachedModal
            title="Upgrade your space to export your call sheet as a PDF"
            eventName="Export callsheet attempt"
            onCancel={() => setShowLimitReachedModal(false)}
          />
        )}
      </>
    );
  },
);

const ActionsButtonWrapper = styled.div`
  min-width: 13.6rem;
`;
