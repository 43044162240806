import React, { useState } from 'react';
import { Pencil1Icon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { Building } from '../../../components/icons';
import { Phone } from '../../../components/icons/Phone';
import { ContactInfoModal } from './contact-info.modal';
import { IconButton } from '../../../components/buttons';
import { CollapsibleItem } from '../../../components/collapsible-item/collapsible-item';
import { ConditionalWrapper } from '../../../components/conditional-wrapper/conditional-wrapper';
import { IconButtonWrapper } from '../../../components/buttons/icon-button';
import { useResponsive } from '../../../hooks/useResponsive';
import { breakPoint } from '../../../app/theme';
import { CellLabel, HorizontalCell } from './styled-mobile-table';
import { Address } from '../../../dto/common/address.dto';

type InfoWidgetProps = {
  stepId: string;
  title: string;

  info: {
    name?: string;
    phone?: string;
    address?: Address;
  };

  onUpdate: (values: any) => Promise<any>;
};

export const InfoWidget: React.FC<InfoWidgetProps> = ({ title, info, onUpdate }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { name, phone, address } = info;
  const { isDesktop } = useResponsive();

  const handleSubmit = async (values: any) => {
    await onUpdate(values);
    setIsModalOpen(false);
  };

  return (
    <Container>
      {isDesktop && (
        <Header>
          <Invisible />

          <Title>{title}</Title>

          <IconButtonWrapper>
            <IconButton
              variant="dark"
              icon={<Pencil1Icon />}
              onClick={() => setIsModalOpen(true)}
            />
          </IconButtonWrapper>
        </Header>
      )}

      <ConditionalWrapper
        condition={!isDesktop}
        wrapper={(children) => (
          <CollapsibleItem
            title={title}
            triggerType="default-small"
            titleAlignment="center"
            isTransparent
          >
            <Container>{children}</Container>
          </CollapsibleItem>
        )}
      >
        <InfoWrapper>
          <FieldContainer>
            <AddressField>
              <Building />

              <div>
                {name && <Text>{name}</Text>}

                {address?.line1 && <Text>{address.line1}</Text>}

                {(address?.postalCode || address?.city) && (
                  <Text>
                    {address.postalCode} {address.city}
                  </Text>
                )}
              </div>
            </AddressField>
          </FieldContainer>

          <AddressField>
            <Phone />

            <Text>{phone}</Text>
          </AddressField>
        </InfoWrapper>

        {!isDesktop && (
          <ActionsContainer>
            <CellLabel>Actions</CellLabel>

            <IconButtonWrapper>
              <IconButton
                variant="dark"
                icon={<Pencil1Icon />}
                onClick={() => setIsModalOpen(true)}
                size="small"
              />
            </IconButtonWrapper>
          </ActionsContainer>
        )}
      </ConditionalWrapper>

      {isModalOpen && (
        <ContactInfoModal
          title={title}
          onSubmit={handleSubmit}
          defaultValues={info}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
  background: var(--color-grayscale-tuna);
  border-radius: 0.4rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    height: 100%;
    padding: 1.6rem 2.4rem;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.2rem;
  border-bottom: 0.1rem solid var(--color-grayscale-trout);
`;

const Title = styled.div`
  width: 100%;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.4rem;
  text-align: center;
  color: var(--color-grayscale-white);
`;

const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.8rem;
  height: 100%;
  padding: 1.6rem 0 0;
  margin: 0.4rem 1.6rem 0;
  border-top: solid 0.1rem var(--color-grayscale-trout);

  @media screen and (max-width: ${breakPoint.medium + 1}px) {
    & > *:first-child {
      padding-inline-end: 0.8rem;
    }
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    gap: 2.4rem;
    padding: unset;
    margin: unset;
    border-top: unset;
  }
`;

const AddressField = styled.div`
  all: unset;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
  height: fit-content;

  & > svg {
    min-width: fit-content;
    width: auto;

    &:first-child {
      align-self: flex-start;
    }
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    gap: 1.2rem;
  }
`;

const FieldContainer = styled.div`
  border-inline-end: 0.1rem solid var(--color-grayscale-trout);
  height: fit-content;
  min-height: 4.8rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    height: 100%;
  }
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: var(--color-grayscale-white);
`;

const Invisible = styled.div`
  min-width: 3.2rem;
  width: 3.2rem;
  visibility: hidden;
`;

const ActionsContainer = styled(HorizontalCell)`
  margin: 0 1.6rem 0.8rem;
`;
