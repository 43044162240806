import React, { PropsWithChildren } from 'react';

type ConditionalWrapperProps = {
  condition: boolean;
  wrapper: (children: React.ReactNode) => React.ReactNode;
};

export const ConditionalWrapper: React.FC<PropsWithChildren<ConditionalWrapperProps>> = ({
  condition,
  wrapper,
  children,
}) => (condition ? wrapper(children) : children);
