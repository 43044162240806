import React from 'react';
import styled from 'styled-components';

import { CircularProgressIndicator } from '../../../components/circular-progress-bar';

interface DownloadOverlayProps {
  /**
   * Current progress to diplay as a percentage
   */
  progress: number;

  /**
   * Status of the uploading / downloading
   */
  status?: string;

  /**
   * Function to call when the user clicks on the pause button
   */
  onPause?: () => void;

  /**
   * Function to call when the user clicks on the resume button
   */
  onResume?: () => void;
}

export const DownloadOverlay: React.FC<DownloadOverlayProps> = ({
  progress,
  status,
  onPause,
  onResume,
}) => (
  <Overlay>
    <CircularProgressIndicator
      progress={progress}
      onPause={onPause}
      onResume={onResume}
      status={status}
      displayProgress
    />
  </Overlay>
);

const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  background-color: #1f2023cc;
  color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
