import React, { useState } from 'react';
import { CopyIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { IconButton } from '../../../components/buttons/icon-button';
import { Tooltip } from '../../../components/tooltip/Tooltip';

type ShareableLinkProps = {
  url?: string;
};

export const ShareableLink: React.FC<ShareableLinkProps> = ({ url }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    if (!url) {
      return;
    }

    setIsCopied(true);
    await navigator.clipboard.writeText(url);
  };

  return (
    <Container>
      <LinkWrapper>
        <Link onClick={handleCopy}>{url}</Link>

        <Tooltip text="Copied" disabled={!isCopied} position="bottom">
          <IconButton
            icon={<CopyIcon />}
            variant="transparent"
            onClick={handleCopy}
            onMouseLeave={(e) => {
              setIsCopied(false);
              e.currentTarget.blur();
            }}
          />
        </Tooltip>
      </LinkWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid var(--border-color);
  border-radius: 0.4rem;
  gap: 0.4rem;
`;

const LinkWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 0.4rem;
  align-items: center;
  border: 1px solid var(--color-grey-7);
  border-radius: 0.4rem;
  padding: 0 0 0 0.8rem;

  & svg {
    stroke: unset;
  }

  & button:hover:enabled,
  & button:focus,
  & button:active {
    & svg {
      stroke: unset;
    }
  }
`;

const Link = styled.a`
  color: var(--color-secondary);
  text-decoration: underline;
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
`;
