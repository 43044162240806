import { action, makeObservable, observable } from 'mobx';

class InsufficientStorage {
  state = false;

  constructor() {
    makeObservable(this, {
      state: observable,
      setState: action,
    });
  }

  setState(state = false) {
    this.state = state;
  }
}

export const insufficientStorageStore = new InsufficientStorage();
