import React, { useState } from 'react';
import { CopyIcon, ImageIcon, TrashIcon } from '@radix-ui/react-icons';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { CardHeader } from '../../../components/card-header/CardHeader';
import { Select } from '../../../components/select/Select';
import { Stage } from '../../../app/entities/stage';
import { Shot } from '../../../app/entities/shot';
import { Step } from '../../../app/entities/step';
import { DropdownTrigger } from '../../projects/components/styled-project-components';
import { useSelection } from '../../../core/contexts/selection.context';
import { bulkUpdateStore } from '../../../core/stores/bulk-update';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';

interface StoryboardItemHeaderProps {
  shot: Shot;
  setDeleteOpen: (isOpen: boolean) => void;
  zoomLevel: number;
}

export const StoryboardItemHeader: React.FC<StoryboardItemHeaderProps> = observer(
  ({ setDeleteOpen, shot, zoomLevel }) => {
    const { t } = useTranslation('shot');
    const [menuOpen, setMenuOpen] = useState(false);
    const { elements: selectedShotsIds } = useSelection();

    const isBulkUpdate = selectedShotsIds && selectedShotsIds.length > 1;

    const handleOpenDelete = () => {
      setDeleteOpen!(true);
    };

    const handleSubmitShootingDay = async () => {
      if (!shot) return;

      const newShootingDay = await Step.createShootingDay({ projectId: shot.projectId });

      const { _id } = newShootingDay || {};
      if (!_id) return;

      isBulkUpdate
        ? bulkUpdateStore.handleShotsBulkUpdate({ shootingDayId: _id }, selectedShotsIds, shot)
        : handleChangeShootingDay(_id);
    };

    const addRow = {
      label: t('add_shootingDay'),
      onClick: handleSubmitShootingDay,
    };

    const handleChangeShootingDay = async (shootingDayId: string) => {
      if (!shot) return;
      const data = { shootingDayId };
      shot.update(data);
    };

    const shootingDays =
      shot.project?.stages.find((el: Stage) => el.name === 'Production')?.steps || [];

    const options = shootingDays.map((el: any) => ({
      value: el._id,
      label: el.displayName,
    }));

    const menuItems = [
      {
        title: 'Replace image',
        icon: <ImageIcon />,
        onSelect: shot.changeImage,
      },
      {
        title: 'Duplicate shot',
        icon: <CopyIcon />,
        onSelect: () => Shot.duplicateShots([shot._id]),
      },
      {
        title: 'Delete shot',
        icon: <TrashIcon />,
        onSelect: handleOpenDelete,
        type: 'danger' as const,
      },
    ];

    const actions = [
      <React.Fragment key={`day_${shot._id}`}>
        {zoomLevel > 2 && (
          <Select
            placeHolder={'Select shooting day'}
            value={shot.shootingDay?._id}
            options={options}
            takeDefaultFirst={false}
            medium
            addDataRow={addRow}
            onSelect={(e) =>
              isBulkUpdate
                ? bulkUpdateStore.handleShotsBulkUpdate(
                    { shootingDayId: e.target.value },
                    selectedShotsIds,
                    shot,
                  )
                : handleChangeShootingDay(e.target.value)
            }
          />
        )}
      </React.Fragment>,
      <DropdownMenu
        key={`shot-${shot._id}-options`}
        items={menuItems}
        onOpenChange={setMenuOpen}
        trigger={<DropdownTrigger>⋮</DropdownTrigger>}
      />,
    ];

    return (
      <Container>
        <CardHeader actions={actions} isMenuOpen={menuOpen} />
      </Container>
    );
  },
);

const Container = styled.div`
  width: 100%;
  position: relative;
  aspect-ratio: 16 / 9;

  #action {
    background: transparent;
    border: none;
    & div {
      svg {
        fill: var(--color-grey-4);
        height: 1.6rem;
        background-color: transparent;
      }
    }

    #select-text {
      padding-left: 0.8rem;
      background-color: var(--color-grey-8);
      color: var(--color-grey-2);
    }

    #option-menu {
      background-color: var(--color-grey-8);
      color: var(--color-grey-2);
    }

    #delete-button {
      background-color: var(--color-grey-8);
      color: var(--color-grey-2);
      opacity: 1;

      & > svg {
        fill: var(--color-grey-2);
        height: 1.6rem;
        width: 1.6rem;
        background-color: transparent;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;
