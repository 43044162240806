import React, { useState } from 'react';
import styled from 'styled-components';

import { ProjectContactItem } from './project-contact-item';
import { EmptyContact } from './empty-contact';
import { AddKeyContactModal } from '../../collaborators/components/add-key-contact.modal';
import { breakPoint } from '../../../app/theme';
import { getFullName } from '../../../lib/utils/get-full-name';

import type { TeamContact } from '../../teams/models/team';

type ProjectContactsProps = {
  projectId: string;

  spaceId: string;

  contacts: TeamContact[];
};

export const ProjectContacts: React.FC<ProjectContactsProps> = ({
  projectId,
  spaceId,
  contacts,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<string | null>(null);

  const producer = contacts.find((collaborator) => collaborator.role?.toLowerCase() === 'producer');
  const director = contacts.find((collaborator) => collaborator.role?.toLowerCase() === 'director');
  const line = contacts.find(
    (collaborator) => collaborator.role?.toLowerCase() === 'line producer',
  );

  return (
    <Container>
      {producer ? (
        <ProjectContactItem
          avatar={producer.avatar || ''}
          name={getFullName(producer)}
          position="Producer"
        />
      ) : (
        <EmptyContact contactName="Producer" onClick={() => setIsModalOpen('Producer')} />
      )}

      {director ? (
        <ProjectContactItem
          avatar={director.avatar || ''}
          name={getFullName(director)}
          position="Director"
        />
      ) : (
        <EmptyContact contactName="Director" onClick={() => setIsModalOpen('Director')} />
      )}

      {line ? (
        <ProjectContactItem
          avatar={line.avatar || ''}
          name={getFullName(line)}
          position="Line Producer"
        />
      ) : (
        <EmptyContact contactName="Line Producer" onClick={() => setIsModalOpen('Line producer')} />
      )}

      <AddKeyContactModal
        projectId={projectId}
        spaceId={spaceId}
        onCancel={() => setIsModalOpen(null)}
        title={`Select ${isModalOpen?.toLowerCase()}`}
        position={isModalOpen || ''}
        isOpen={!!isModalOpen}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.2rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    min-width: 24rem;
    gap: 0;
    border-left: 1px solid #3c404a;
    padding-left: 2.4rem;
  }
`;
