import merge from 'lodash.merge';

import { entityPool } from './engine';
import { api } from '../../api';
import { catchError } from '../catch-error';

export abstract class Model {
  _id: string = '';

  createdAt: Date;
  updatedAt: Date;

  protected constructor(readonly $name: string) {
    this._id = Math.random().toString();
  }

  save() {
    if (!entityPool.pool.has(this._id)) {
      entityPool.insert(this);
    }
  }

  async delete(url?: string) {
    if (!url) {
      url = `/${this.$name}/${this._id}`;
    }

    try {
      await api.delete(url).then(() => entityPool.delete(this));
    } catch (e) {
      catchError(e);
      throw e; // TODO: check with pablo, we added it to be able to catch the error if it happens here and not continue the execution
    }
  }

  remove() {
    entityPool.delete(this);
  }

  update(values: Partial<Omit<this, '_id'>>) {
    return api.patch(`/${this.$name}/${this._id}`, values).then(({ data }) => {
      merge(this, values);
      return data;
    });
  }

  static getOne(id: string) {
    return entityPool.getEntity(id);
  }

  static getAll(): Model[] {
    return Array.from(entityPool.pool.values());
  }

  abstract toPOJO(): Record<string, any>;
}
