import styled from 'styled-components';
import { StyledLine } from '../../../components/dropdowns/StyledDropdown';

export const Cell = styled.td`
  position: relative;
  font-weight: 400;
  font-size: 1.4rem;
  padding: 0.3rem 1.6rem 0.3rem 0; // 0.3rem because the border is 0.1rem
  color: var(--color-grayscale-ghost);

  border-bottom: 0.15rem solid transparent;
  border-top: 0.15rem solid transparent;

  & ${StyledLine} {
    max-width: unset;
  }

  &:first-child {
    border-left: 0.15rem solid transparent;
    border-radius: 0.4rem 0 0 0.4rem;
  }

  &:last-child {
    border-right: 0.15rem solid transparent;
    border-radius: 0 0.4rem 0.4rem 0;
  }

  &:only-child {
    border: 0.15rem solid transparent;
    border-radius: 0.4rem;
  }
`;

export const BaseRow = styled.tr`
  position: relative;
  width: 100%;
  border-radius: 0.4rem;

  &[data-selected='true'] {
    ${Cell} {
      border-bottom: 0.15rem solid var(--color-primary-crayola);
      border-top: 0.15rem solid var(--color-primary-crayola);

      &:first-child {
        border-left: 0.15rem solid var(--color-primary-crayola);
      }

      &:last-child {
        border-right: 0.15rem solid var(--color-primary-crayola);
      }
    }
  }

  &[data-dragged='true'] {
    ${Cell} {
      background: transparent;
      border-bottom: 0.15rem dashed var(--color-primary-crayola);
      border-top: 0.15rem dashed var(--color-primary-crayola);

      &:first-child {
        border-left: 0.15rem dashed var(--color-primary-crayola);
      }

      &:last-child {
        border-right: 0.15rem dashed var(--color-primary-crayola);
      }

      & > * {
        visibility: hidden;
      }
    }
  }
`;

export const BlockContainer = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BaseBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-grayscale-shark);
  border-radius: 0.4rem;
  min-width: fit-content;
  width: 12.2%;
  border: none;
  color: var(--color-grayscale-white);
  min-height: 3.2rem;
  pointer-events: auto;
`;

export const OrderIndicator = styled.div`
  box-sizing: content-box;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0.4rem;
  left: 0.4rem;
  height: 2.4rem;
  width: 2.4rem;
  background-color: var(--color-grayscale-white);
  color: var(--color-grayscale-woodsmoke);
  border-radius: 0.4rem;
  font-size: 1rem;
  font-weight: 700;
`;

export const Image = styled.img`
  display: block;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  max-width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 0.4rem;
  object-fit: cover;
`;

export const CoverItem = styled.div`
  max-width: 100%;
  max-height: 6.8rem;
  aspect-ratio: 16 / 9;
  position: relative;

  & svg {
    height: 1.2rem;
    width: 1.2rem;
  }
`;

export const HiddenCoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: fill-available;
  height: 100%;
  background-color: var(--color-grayscale-trout);
  opacity: 0.3;
  pointer-events: none;

  &[data-hide='true'] {
    display: none;
  }
`;

export const RowMirror = styled.div`
  position: absolute;
  top: 0;
  height: 4.8rem;
  width: 100%;
  background: var(--color-shades-hibiscus);
  border-radius: 0.4rem;

  display: grid;
  grid-template-columns: 8.5% 9.2% 8.3% 7.4% 25.7% 26.5% 4.6% 5.4% 4.4%;
  align-items: center;

  // substitute border on parent, adding 0.3rem to width
  margin: 0 -0.15rem;

  &[data-shotlist='true'] {
    grid-template-columns: 8.5% 37.5% 10% 10% 10% 10% 5% 5% 4%;
  }
`;
