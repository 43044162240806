import React from 'react';
import { components, MenuListProps } from 'react-select';

import { AvatarOption } from '../avatar-select/avatar-select-option';

type CustomMenuListProps = MenuListProps<AvatarOption>;

export const CustomMenuList: React.FC<CustomMenuListProps> = ({ children, ...props }) => {
  //   @ts-ignore
  const { setHasOptions } = props.selectProps;

  if (children && React.Children.count(children)) {
    setHasOptions(true);
  }

  return <components.MenuList {...props}>{children}</components.MenuList>;
};
