import React, { PropsWithChildren, useDeferredValue, useEffect, useRef, useState } from 'react';

import { GanttChartContext } from './gantt-chart.context';
import { ChartItem, DayProps, GanttItem } from '../types';
import {
  differenceInDays,
  getParentEndDate,
  getFullDatesRange,
  getParentStartDate,
  getEarliestDate,
  getLatestDate,
} from '../utils/date-utils';
import { END_OFFSET, START_OFFSET } from '../constants';

type GanttChartProviderProps = {
  items: GanttItem[];
};

export const GanttChartProvider: React.FC<PropsWithChildren<GanttChartProviderProps>> = ({
  items: initialItems,
  children,
}) => {
  const currentStripRef = useRef<HTMLDivElement | null>(null);
  const resizingLayerRef = useRef<HTMLDivElement | null>(null);
  const currentItem = useRef<ChartItem | null>(null);
  const chartStartDate = useRef<Date>();
  const chartEndDate = useRef<Date>();
  const datesFullRange = useRef<DayProps[]>();
  const resizingSide = useRef<'left' | 'right' | undefined>();
  const resizeStartOffset = useRef<number>(0);
  const todayIndex = useRef<number | undefined>();
  const dateTrackerLabelRef = useRef<HTMLDivElement | null>(null);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const stripIndicatorsListRef = useRef<(HTMLDivElement | null)[]>([]);

  const [items, setItems] = useState<ChartItem[]>([]);
  const [datesByMonths, setDatesByMonths] = useState<{ [key: string]: DayProps[] }>();

  useEffect(() => {
    let normalizedItems: ChartItem[] = [];
    const dates: Date[] = [new Date()];

    initialItems.forEach((item) => {
      const { subItems, ...rest } = item;

      if (subItems) {
        const startDate = getParentStartDate(subItems);
        const dueDate = getParentEndDate(subItems);

        if (startDate) {
          dates.push(startDate);
        }

        if (dueDate) {
          dates.push(dueDate);
        }

        const itemWithDates = {
          ...rest,
          startDate,
          dueDate,
        };

        const subItemsWithParentId = subItems.map((subItem) => ({
          ...subItem,
          parentId: item.id,
        }));

        normalizedItems.push(itemWithDates);
        normalizedItems = normalizedItems.concat(subItemsWithParentId);

        return itemWithDates;
      }

      normalizedItems.push(item);
      return item;
    });

    const dateRangeProps = calculateDateRange(dates, START_OFFSET, END_OFFSET);

    todayIndex.current = dateRangeProps ? dateRangeProps.todayIndex : todayIndex.current;

    datesFullRange.current = dateRangeProps ? dateRangeProps.fullRange : datesFullRange.current;

    if (!datesFullRange?.current) {
      return;
    }

    chartStartDate.current = datesFullRange.current[0].date;
    const lastIndex = datesFullRange.current.length - 1;
    chartEndDate.current = datesFullRange.current[lastIndex].date;

    setDatesByMonths((current) => {
      return dateRangeProps ? dateRangeProps.byMonth : current;
    });

    calculateHorizontalIndices(normalizedItems);
  }, [initialItems]);

  const calculateDateRange = (dates: Date[], startOffset: number, endOffset: number) => {
    const earliestDate = getEarliestDate(dates);
    const latestDate = getLatestDate(dates);

    if (!earliestDate || !latestDate) {
      return;
    }

    const dateRangeProps = getFullDatesRange(earliestDate, latestDate, startOffset, endOffset);

    return dateRangeProps;
  };

  const calculateHorizontalIndices = (items: ChartItem[]) => {
    if (!datesFullRange?.current) {
      return;
    }

    const itemsWithIndices = items.map((item) => {
      if (!item.startDate && !item.dueDate) {
        return item;
      }

      const startDateIndex = differenceInDays(
        chartStartDate.current!,
        (item.startDate || item.dueDate)!,
      );

      const positionLeft = startDateIndex * 48 + 48;

      const durationInDays =
        item.dueDate && item.startDate ? differenceInDays(item.dueDate, item.startDate) : 0;
      return { ...item, startDateIndex, durationInDays: Math.abs(durationInDays), positionLeft };
    });

    setItems((prevItems) => {
      if (!prevItems?.length) {
        return itemsWithIndices;
      }

      const mergedItems = prevItems?.map((prevItem, index) => ({
        ...prevItem,
        ...itemsWithIndices[index],
      }));

      return mergedItems;
    });
  };

  const extendStartRange = (startOffset: number) => {
    if (!chartEndDate.current || !chartStartDate.current || startOffset <= 0) {
      return;
    }

    const newStartDate = new Date(chartStartDate.current);
    newStartDate.setMonth(newStartDate.getMonth() - startOffset);

    const dateRangeProps = calculateDateRange([chartStartDate.current, newStartDate], 0, 0);

    if (!dateRangeProps) {
      return;
    }

    console.log('should extend start range');
    todayIndex.current = dateRangeProps.todayIndex ?? todayIndex.current;

    datesFullRange.current = datesFullRange.current
      ? dateRangeProps.fullRange.concat(datesFullRange.current)
      : dateRangeProps.fullRange;

    if (!datesFullRange?.current) {
      return;
    }

    chartStartDate.current = datesFullRange.current[0].date;
    const lastIndex = datesFullRange.current.length - 1;
    chartEndDate.current = datesFullRange.current[lastIndex].date;

    setDatesByMonths((current) => {
      return current ? { ...dateRangeProps.byMonth, ...current } : dateRangeProps.byMonth;
    });

    calculateHorizontalIndices(items);
  };

  const extendEndRange = (endOffset: number) => {
    if (!chartEndDate.current || !chartStartDate.current || endOffset <= 0) {
      return;
    }

    const newEndDate = new Date(chartEndDate.current);
    newEndDate.setMonth(newEndDate.getMonth() + endOffset);

    const dateRangeProps = calculateDateRange([chartEndDate.current, newEndDate], 0, 0);

    if (!dateRangeProps) {
      return;
    }

    todayIndex.current = dateRangeProps.todayIndex ?? todayIndex.current;

    datesFullRange.current = datesFullRange.current
      ? datesFullRange.current.concat(dateRangeProps.fullRange)
      : dateRangeProps.fullRange;

    if (!datesFullRange?.current) {
      return;
    }

    chartStartDate.current = datesFullRange.current[0].date;
    const lastIndex = datesFullRange.current.length - 1;
    chartEndDate.current = datesFullRange.current[lastIndex].date;

    setDatesByMonths((current) => {
      return current ? { ...current, ...dateRangeProps.byMonth } : dateRangeProps.byMonth;
    });
  };

  const setCurrentStrip = (node: HTMLDivElement | null) => {
    currentStripRef.current = node;
  };

  const setCurrentItem = (item: ChartItem | null) => {
    currentItem.current = item;
  };

  const setResizingSide = (side?: 'left' | 'right' | undefined) => {
    resizingSide.current = side;
  };

  const setResizeStartOffset = (startXOffset?: number) => {
    resizeStartOffset.current = startXOffset || 0;
  };

  const handleItemsCollapse = (parentId: string, isCollapsed?: boolean) => {
    setItems((currItems) => {
      const newItems = currItems?.map((item) => {
        if ([item.parentId].includes(parentId)) {
          return { ...item, isCollapsed };
        }

        return item;
      });

      return newItems;
    });
  };

  return (
    <GanttChartContext.Provider
      value={{
        items,
        setItems,
        setCurrentStrip,
        currentStripRef,
        currentItem,
        setCurrentItem,
        resizeStartOffset,
        setResizeStartOffset,
        resizingSide,
        setResizingSide,
        chartStartDate: chartStartDate.current,
        chartEndDate: chartEndDate.current,
        datesRangeByMonths: datesByMonths,
        datesFullRange: datesFullRange.current,
        resizingLayerRef,
        handleItemsCollapse,
        dateTrackerLabelRef,
        sidebarRef,
        stripIndicatorsListRef,
        todayIndex: todayIndex.current,
        extendStartRange,
        extendEndRange,
      }}
    >
      {children}
    </GanttChartContext.Provider>
  );
};
