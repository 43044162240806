import React, { useState } from 'react';
import { CirclePlus } from '@producer-io/ui-kit';
import { observer } from 'mobx-react-lite';

import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { AssignContactsModal } from '../../contacts/components/assign-contacts.modal';
import { MembersList } from '../../../routes/project/components/shootingday/members-list';
import { CollapsibleItem } from '../../../components/collapsible-item/collapsible-item';
import { DropdownTrigger } from '../../projects/components/styled-project-components';
import { useResponsive } from '../../../hooks/useResponsive';
import { ConditionalWrapper } from '../../../components/conditional-wrapper/conditional-wrapper';

import type { Team } from '../../../app/entities/team';
import type { TeamContact } from '../models/team';

type DepartmentItemProps = {
  spaceId: string;
  projectId: string;
  contacts: TeamContact[];
  team: Team;
};

export const NoDepartmentItem: React.FC<DepartmentItemProps> = observer(
  ({ contacts, team, spaceId }) => {
    const { isDesktop } = useResponsive();

    const [showAddContactModal, setShowAddContactModal] = useState(false);

    const handleDeleteCrewMember = async (contactId: string) => {
      await team.deleteContact(contactId);
    };

    const handleOnUpdate = async (contactId: string, values: Partial<TeamContact>) => {
      await team.updateContact(contactId, values);
    };

    const handleAssignTeamContact = async (contactId: string, role?: string) => {
      await team.addContact({
        contactId,
        role: role || '',
      });
    };

    const menuItems = [
      {
        title: 'Add crew member',
        icon: <CirclePlus />,
        onSelect: () => setShowAddContactModal(true),
      },
    ];

    return (
      <>
        <AssignContactsModal
          assignedContacts={[]}
          addedContacts={[]}
          onAdd={handleAssignTeamContact}
          onCancel={() => setShowAddContactModal(false)}
          spaceId={spaceId}
          title="Add Crew Member"
          subtitle="Added members"
          isOpen={showAddContactModal}
        />

        <ConditionalWrapper
          condition={isDesktop}
          wrapper={(children) => (
            <CollapsibleItem
              title="No department assigned"
              actions={
                <DropdownMenu
                  trigger={<DropdownTrigger data-dark="true">⋮</DropdownTrigger>}
                  items={menuItems}
                />
              }
              defaultOpen
            >
              {children}
            </CollapsibleItem>
          )}
        >
          <MembersList
            title="No department assigned"
            actions={
              <DropdownMenu
                trigger={<DropdownTrigger data-dark="true">⋮</DropdownTrigger>}
                items={menuItems}
              />
            }
            onUpdate={handleOnUpdate}
            contacts={contacts}
            onDelete={handleDeleteCrewMember}
          />
        </ConditionalWrapper>
      </>
    );
  },
);
