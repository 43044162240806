import React from 'react';

export const ProducerLogo = () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 328.66 384.76"
  >
    <path d="M323.58,0H5.08C2.27,0,0,2.27,0,5.08V250.25c0,1.6,.75,3.11,2.03,4.07l159.25,129.42c1.81,1.35,4.29,1.35,6.09,0l159.25-129.42c1.28-.96,2.03-2.47,2.03-4.07V5.08c0-2.81-2.27-5.08-5.08-5.08ZM181.23,333.48l-16.33,13.14,.27,.33h-.55s.27-.34,.28-.34l-17.46-14.04v-77.84l16.96,13.65,16.83-13.54v78.64Zm117.7-94.71l-33.79,27.19V81.47c-17.11,18.96-43.95,38.31-83.92,42.64v44.08c16.44-16.35,38.69-23.41,58.86-33.42v151.37l-33.79,27.19v-121.94l-41.89,33.7-42.02-33.81v121.13l-33.79-27.19V134.77c20.12,10.03,42.42,16.96,58.84,33.29v-43.95c-39.96-4.33-66.8-23.67-83.9-42.64v183.58l-33.79-27.18V30.13h39.85c31.33,80.56,158.28,80.37,189.49,0h39.85V238.77Z" />
  </svg>
);
