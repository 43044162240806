import React from 'react';
import styled from 'styled-components';

import { Skeleton } from '../../../components/skeleton/skeleton';
import { currencyFormatter } from '../../../lib/utils/currency-formatter';
import { PreviewAddMemberUpdatesResponse } from '../../billing/store/billing.slice';

interface PaymentPreviewProps {
  isLoading: boolean;
  paymentDetails?: PreviewAddMemberUpdatesResponse | null;
}

export const PaymentPreview: React.FC<PaymentPreviewProps> = ({ paymentDetails, isLoading }) => {
  const { currentPayment, nextPayment } = paymentDetails || {};
  const { amount: currentAmount, currency: currentCurrency } = currentPayment || {};
  const { date: nextPaymentDate, amount: nextPaymentAmount, currency } = nextPayment || {};

  return (
    <PaymentDetailsSection>
      <PaymentDetailsRow>
        <PaymentDetailsTitle>Total to be charged now</PaymentDetailsTitle>

        {isLoading ? (
          <Skeleton width={4} height={1.4} />
        ) : (
          <PaymentDetailsAmount>
            {paymentDetails &&
              currencyFormatter(parseFloat(currentAmount || '0'), currentCurrency || currency!)}
          </PaymentDetailsAmount>
        )}
      </PaymentDetailsRow>

      <PaymentDetailsRow>
        <PaymentDetailsTitle>
          Total to be charged on{' '}
          {nextPaymentDate ? (
            <PaymentDate>{nextPaymentDate}</PaymentDate>
          ) : (
            <Skeleton width={8} height={1.4} />
          )}
        </PaymentDetailsTitle>

        {isLoading ? (
          <Skeleton width={4} height={1.4} />
        ) : (
          <PaymentDetailsAmount>
            {paymentDetails && currencyFormatter(parseFloat(nextPaymentAmount || '0'), currency!)}
          </PaymentDetailsAmount>
        )}
      </PaymentDetailsRow>
    </PaymentDetailsSection>
  );
};

const PaymentDetailsSection = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
  border-top: 1px solid var(--color-grayscale-tuna);
`;

const PaymentDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PaymentDetailsTitle = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-grayscale-light-slate);
`;

const PaymentDetailsAmount = styled.p`
  color: var(--color-grayscale-white);
  font-size: 1.4rem;
  font-weight: 400;
`;

const PaymentDate = styled.span`
  color: var(--color-grayscale-white);
  font-size: 1.4rem;
  font-weight: 500;
`;
