import React from 'react';

import { InfoTextLine } from '../../../components/texts/Texts';
import { GuestInvitationAction } from './guest-invitation-actions';
import { formatDateTime } from '../../../core/services/date.service';
import { ActionBox, Cell, Row } from '../../../components/settings-table/styled-table';
import { Invitation } from '../../../app/entities/invitation';

type InvitationItemProps = {
  invitation: Invitation;
};

export const InvitationItem: React.FC<InvitationItemProps> = ({ invitation }) => (
  <Row>
    <Cell>{invitation?.email}</Cell>
    <Cell>
      <InfoTextLine value={invitation.role} />
    </Cell>

    <Cell>{(invitation?.invitedAt && formatDateTime(invitation.invitedAt)) || ''}</Cell>

    <ActionBox>
      <GuestInvitationAction invitation={invitation} />
    </ActionBox>
  </Row>
);
