type DirtyFields<T> = {
  [K in keyof T]?: boolean | DirtyFields<T[K]>;
};

export const createFormDiff = <T>(
  formData: Partial<T>,
  dirtyFields: DirtyFields<T>,
): Partial<T> => {
  const diff: Partial<T> = {};

  for (const [key, value] of Object.entries(dirtyFields)) {
    const typedKey = key as keyof T;
    if (typeof value === 'object') {
      diff[typedKey] = createFormDiff(
        formData[typedKey] as Partial<T>,
        dirtyFields[typedKey] as DirtyFields<T>,
      ) as T[keyof T];
    }

    if (value === true) {
      diff[typedKey] = formData[typedKey];
    }
  }

  return diff;
};
