import React from 'react';
import styled from 'styled-components';
import * as RadixMenu from '@radix-ui/react-dropdown-menu';

import { CheckboxItem } from './DropdownMenu';
import {
  DescriptiveOptionLabel,
  DescriptiveOptionDescription,
} from '../../dropdowns/StyledDropdown';

export const DescriptiveOption: React.FC<
  CheckboxItem & { description?: string; width?: number; disabled?: boolean; tag?: string }
> = ({ isChecked, title, description, onCheckedChange, width, disabled, tag }) => {
  return (
    <ListItem
      data-checked={isChecked}
      data-disabled={disabled}
      onCheckedChange={onCheckedChange}
      width={width}
    >
      <Left>
        <DescriptiveOptionLabel>{title}</DescriptiveOptionLabel>
        <DescriptiveOptionDescription>{description} </DescriptiveOptionDescription>
      </Left>

      {tag && <Tag>{tag}</Tag>}
    </ListItem>
  );
};

const ListItem = styled(RadixMenu.CheckboxItem)<{ width?: number }>`
  border-radius: 0.4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem;
  gap: 1.6rem;
  cursor: pointer;
  min-width: ${({ width }) => (width ? `${width}px` : '100%')};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  max-width: ${({ width }) => (width ? `${width}px` : '100%')};
  user-select: none;

  &[data-disabled='true'] {
    pointer-events: none;

    & * {
      color: var(--color-grayscale-light-slate);
    }
  }

  &:hover,
  &[data-checked='true'] {
    background: var(--color-grayscale-arsenic, #3c404a);
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4em 0.8rem;
  background: var(--color-grayscale-arsenic, #3c404a);
  border-radius: var(--border-radius-medium);
  color: var(--color-grayscale-cadet, #afb4c0);

  /* Captions */
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4rem;
`;
