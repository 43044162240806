import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { authStore } from '../../../core/stores/auth-store';
import { BaseDialog, DialogFooter } from '../../../components/dialog';
import { CrossCircledFilledIcon } from '../../../components/icons/cross-circled-filled.icon';
import { ElevatedButton, OutlinedButton } from '../../../components/buttons';

export type PastDueWarningModalProps = {
  onCancel: () => void;
  spaceId: string;
};

export const PastDueWarningModal: React.FC<PastDueWarningModalProps> = ({ onCancel, spaceId }) => {
  const navigate = useNavigate();
  const currentMember = authStore.getCurrentMember(spaceId);

  const handleClick = () => {
    navigate(`/space/${spaceId}/settings/billings`);
    onCancel();
  };

  return (
    <BaseDialog onClose={onCancel}>
      <Container>
        <Icon>
          <CrossCircledFilledIcon />
        </Icon>

        <h1>Your Subscription Needs Attention</h1>

        <p>
          It looks like your subscription is overdue. You have 30 days to renew it before your
          creative space is temporarily locked. Stay ahead by updating your payment info and keeping
          everything running smoothly.
        </p>
      </Container>

      <DialogFooter
        actions={[
          <OutlinedButton key="cancel" text="Cancel" onClick={onCancel} />,
          ...(['owner', 'admin'].includes(currentMember?.role || '')
            ? [<ElevatedButton key="upgrade" onClick={handleClick} text="Subscribe" autoFocus />]
            : []),
        ]}
      />
    </BaseDialog>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.8rem 3.2rem 2.4rem;
  max-width: 47.6rem;
  text-align: center;
  color: white;

  h1 {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 400;
    margin-bottom: 1.2rem;
  }

  p {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
  }
`;

const Icon = styled.div`
  position: relative;
  height: 9.6rem;
  width: 9.6rem;
  border: 0.2rem solid var(--color-error);
  background: var(--color-error-20);
  border-radius: 50%;
  margin-bottom: 3.2rem;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-error);
    height: 3.6rem;
    width: 3.6rem;
  }
`;
