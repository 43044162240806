import React from 'react';

export const FileTypeAudio = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_395_358)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM9.8 5H8.3V13H9.8V5ZM5 7H6.5V11H5V7ZM13 7H11.5V11H13V7Z"
        fill="#9D9D9D"
      />
    </g>
    <defs>
      <clipPath id="clip0_395_358">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
