import React, { Ref } from 'react';
import styled from 'styled-components';

interface TextInputProps {
  value?: string;
  label?: string;
  onChange?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  onBlur?: (e: any) => void;
  hasError?: boolean;
  disabled?: boolean;
  required?: boolean;
  dark?: boolean;
  errorMessage?: string | undefined | any;
  placeholder?: string;
  autocomplete?: string;
  name?: string;
  type?: string;
  defaultValue?: string;
  icon?: React.ReactNode;
  ref?: Ref<HTMLInputElement>;
}

export const DateInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      value,
      label,
      onChange,
      hasError = false,
      errorMessage,
      icon,
      disabled = false,
      dark = true,
      onKeyDown,
      placeholder,
      type,
      name,
      required,
      onBlur,
      autocomplete,
      defaultValue,
    },
    ref,
  ) => {
    return (
      <TextInputContainer hasLabel={label !== undefined ? true : undefined}>
        {icon}
        <Label hasLabel={label !== undefined ? true : undefined}>
          {label} {required && <b>*</b>}
        </Label>

        <Input
          ref={ref}
          onChange={onChange}
          dark={dark}
          hasError={hasError || !!errorMessage}
          name={name}
          type="date"
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
        />

        {errorMessage && <Error>{errorMessage}</Error>}
      </TextInputContainer>
    );
  },
);

DateInput.displayName = 'DateInput';

interface InputProps {
  hasError: boolean;
  hasIcon?: boolean;
  dark?: boolean;
}

interface LabelProps {
  hasLabel?: boolean;
}

const TextInputContainer = styled.div<LabelProps>`
  position: relative;
  display: flex;
  flex-direction: column;

  svg {
    position: absolute;
    left: 0.75rem;
    top: 0.8rem;
    top: ${({ hasLabel }) => hasLabel && '2.5rem'};
    bottom: 0.8rem;
    fill: white;
    z-index: 1;
    width: 1.6rem;
  }
`;

const Label = styled.div<LabelProps>`
  font-family: Inter, sans-serif;
  font-weight: 600;
  color: white;
  font-size: 1.4rem;
  letter-spacing: 0.7px;
  line-height: 1.1rem;
  margin-bottom: 0.8rem;
  display: ${({ hasLabel }) => !hasLabel && 'none'};

  b {
    color: var(--color-primary);
  }
`;

const Input = styled.input<InputProps>`
  width: 100%;
  font-family: Inter, sans-serif;
  background-color: var(--bg-color-dark);
  border: 1px solid var(--color-grey-7);
  border-radius: 0.4rem;
  height: 4rem;
  color: white;
  padding: 0 1.2rem;
  transition: border-color 0.1s;

  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  &::placeholder {
    color: var(--card-subtitle-color);
  }

  &:focus {
    border-color: ${({ hasError }) => (hasError ? 'var(--color-error)' : 'var(--color-secondary)')};
  }

  &:disabled {
    cursor: default;
    color: #f7f8f833;
  }
`;

const Error = styled.span`
  position: absolute;
  right: 0;
  bottom: -15px;
  height: 1.6rem;
  color: var(--color-error);
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  letter-spacing: -0.2px;
  line-height: 1.6rem;
  text-align: right;
`;
