import React from 'react';
import * as yup from 'yup';

import { Select } from '../../components/select/Select';

import type { FormBuilderDefinition } from '../../components/form-builder-modal/form-builder';

const genderOptions = [
  { value: 'man', label: 'Man' },
  { value: 'woman', label: 'Woman' },
  { value: 'other', label: 'Other' },
];

export const buildCharacterForm: (
  isCreate: boolean,
  handleSetImage: (file?: File) => void,
) => FormBuilderDefinition = (isCreate: boolean, handleSetImage: (file?: File) => void) => ({
  header: isCreate ? 'Create character' : 'Edit character',
  submitText: isCreate ? 'Create character' : 'Edit character',
  fields: [
    {
      type: 'upload',
      handleSetImage,
    },
    {
      type: 'group',
      sectionHeader: 'Character information',
    },
    {
      name: 'name',
      label: 'Character name',
      placeholder: 'Character name',
      isRequired: true,
      type: 'text',
      validation: yup
        .string()
        .matches(/^(.*)?\S+(.*)?$/, 'Name is required')
        .required('Name is required')
        .max(32, 'Max 32 characters'),
    },
    {
      name: 'gender',
      type: 'controller',
      render: ({ field, fieldState: { error } }) => (
        <Select
          {...field}
          onSelect={({ target }) => field.onChange(target.value)}
          options={genderOptions}
          label={'Gender'}
          hasError={!!error}
          errorMessage={error?.message}
        />
      ),
    },
    {
      name: 'description',
      label: 'Description',
      placeholder: 'Description',
      type: 'textArea',
      validation: yup.string().max(1024, 'Max 1024 characters'),
    },
    {
      name: 'personality',
      label: 'Personality',
      placeholder: 'Personality',
      type: 'textArea',
      validation: yup.string().max(1024, 'Max 1024 characters'),
    },
    {
      name: 'characteristics',
      label: 'Physical characteristics',
      placeholder: 'Physical characteristics',
      type: 'textArea',
      validation: yup.string().max(1024, 'Max 1024 characters'),
    },
    {
      name: 'appearance',
      label: 'Appearance',
      placeholder: 'Appearance',
      type: 'textArea',
      validation: yup.string().max(1024, 'Max 1024 characters'),
    },
  ],
});
