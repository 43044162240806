import React from 'react';
import styled, { css } from 'styled-components';

import { Close as CloseIcon } from '../icons/Close';

interface TagProps {
  type?: string;
  text: string;
  small?: boolean;
  onDelete?: () => void;
  className?: string;
}

export const Tag: React.FC<TagProps> = ({
  type = 'default',
  text,
  small = false,
  onDelete,
  className,
}) => (
  <TagItem type={type} small={small} close={!!onDelete} className={className}>
    <div>{text}</div>
    {!!onDelete && (
      <CloseItem onClick={onDelete}>
        <CloseIcon strokeWidth={1.5} stroke={'fill'} />
      </CloseItem>
    )}
  </TagItem>
);

interface TagItemProps {
  type: string;
  small: boolean;
  close: boolean;
}

const TagItem = styled.div<TagItemProps>`
  position: relative;
  color: var(--color-error);
  border: 2px solid var(--color-error);
  border-radius: 0.6rem;
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 1.2rem;
  text-transform: uppercase;
  display: inline-block;
  padding: ${({ small }) => (small ? '.4rem 1.6rem' : '.8rem 1.6rem')};
  padding-right: ${({ close }) => (close ? '2.4rem' : '1.6rem')};

  ${(props) => {
    switch (props.type) {
      case 'error':
        return css`
          background-color: var(--color-error);
          color: var(--white-default);
          border: 2px solid var(--color-error);
        `;
      case 'success':
        return css`
          background-color: var(--color-success);
          color: var(--white-default);
          border: 2px solid var(--color-success);
        `;
      case 'data':
        return css`
          background-color: var(--color-primary);
          color: var(--white-default);
          border: 2px solid var(--color-primary);
        `;
      case 'warning':
        return css`
          background-color: var(--color-warning);
          color: var(--white-default);
          border: 2px solid var(--color-warning);
        `;
      default:
        return css`
          background-color: transparent;
          color: var(--color-error);
          border: 2px solid var(--color-error);
        `;
    }
  }};
`;

export const CloseItem = styled.div`
  position: absolute;
  top: 0.2rem;
  width: 1.6rem;
  height: 1.6rem;
  right: 0.2rem;
  opacity: 0.8;
  cursor: pointer;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 100ms ease-in-out;

  svg {
    width: 0.8rem;
    height: 0.8rem;
    fill: var(--text-color-white);
    stroke: var(--text-color-white);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 25%);
  }
`;
