import React from 'react';
import styled from 'styled-components';

interface MenuItemProps {
  id: string;
  name: string;
  current: boolean;
  notifications?: number;
  icon?: React.ReactNode;
  onClick?: () => void;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  id,
  name,
  notifications,
  current,
  icon,
  onClick,
}) => (
  <Item id={id} current={current} onClick={onClick}>
    <Left>
      {icon && <Icon current={current}>{icon}</Icon>}
      <Name current={current}>{name}</Name>
    </Left>

    <div>{notifications && <Notification>{notifications}</Notification>}</div>
  </Item>
);

interface ContainerProps {
  current?: boolean;
}

const Item = styled.div<ContainerProps>`
  width: 100%;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.4rem;
  transition: all 0.1s ease-in;
  margin-bottom: 0.8rem;
  user-select: none;
  background: ${({ current }) => (current ? '#31343c' : 'transparent')};
  overflow: hidden;
  color: ${({ current }) => (current ? '#F2F3F5' : '#626878')};

  svg {
    color: ${({ current }) => (current ? '#F2F3F5' : '#626878')};
    fill: ${({ current }) => (current ? '#959bab' : '#3c404a')};
  }

  &:hover {
    cursor: pointer;
    background-color: #31343c;
    color: #f2f3f5;

    svg {
      color: #f2f3f5;
      fill: #959bab;
    }
  }
`;

const Icon = styled.div<ContainerProps>`
  color: var(--text-color-white);
  font-size: 1.6rem;
  font-weight: 500;
  height: 3.2rem;
  width: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Name = styled.div<ContainerProps>`
  font-size: 1.4rem;
  font-weight: 500;
  margin-left: 0.8rem;
  font-family: inherit;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Notification = styled.div`
  color: var(--text-color-white);
  background: var(--color-primary);
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0.4rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1.6rem;
  height: 1.6rem;
`;
