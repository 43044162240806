interface ObjectWithName {
  firstName?: string;
  lastName?: string;
}

export const getFullName = (name: ObjectWithName) => {
  if (!name) return '';

  const [firstName, lastName] = [
    name.firstName?.replace(/null|undefined/g, '').trim(),
    name.lastName?.replace(/null|undefined/g, '').trim(),
  ];

  return `${firstName || ''}${firstName && lastName ? ' ' : ''}${lastName || ''}`;
};
