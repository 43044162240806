import React from 'react';
import styled from 'styled-components';

import { ElevatedButton } from '../buttons';
import { breakPoint } from '../../app/theme';

type EmptyTablePlaceholderProps = {
  description: string;
  action?: {
    text: string;
    onClick?: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  };
};

export const EmptyTablePlaceholder: React.FC<EmptyTablePlaceholderProps> = ({
  description,
  action,
}) => {
  const { text, onClick = () => {} } = action || {};

  return (
    <Container>
      <Description>{description}</Description>

      {action && (
        <ActionContainer>
          <ElevatedButton onClick={onClick} text={text!} isFullWidth />
        </ActionContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  background-color: transparent;
  background-image: url(/images/default-background.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  min-height: 20rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    gap: 2.4rem;
    min-height: 32rem;
  }
`;

const Description = styled.p`
  max-width: 34rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6rem;
  text-align: center;
  color: var(--color-grayscale-anti-flash-white);
`;

const ActionContainer = styled.div`
  max-width: 18rem;
  width: 100%;

  @media screen and (max-width: ${breakPoint.medium + 1}px) {
    display: flex;
    justify-content: center;

    & > button[data-full='true'] {
      width: fit-content;
      height: 3.2rem;
    }
  }
`;
