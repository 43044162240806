import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { ShootingDayHeader } from './shooting-day-header';
import { ShootingDaySchedule } from './ShootingDaySchedule';
import { TeamList } from './team-list';
import { Project } from '../../../app/entities/project';
import { LocationList } from './location-list';
import { AdditionalInfoList } from './additional-info-list';
import { breakPoint } from '../../../app/theme';
import { ProductionNotes } from './production-notes';

import type { Step } from '../../../app/entities/step';

interface CallsheetTabProps {
  project: Project;
  step: Step;
}

export const CallsheetTab: React.FC<CallsheetTabProps> = observer(({ project, step }) => (
  <SceneContainer>
    <ShootingDayHeader project={project} step={step} />

    <LocationList step={step} project={project} />

    <TeamList step={step} project={project} />

    <ShootingDaySchedule
      projectId={project._id}
      shootingDayId={step._id}
      dueDate={step.dueDate}
      step={step}
    />

    <AdditionalInfoList project={project} shootingDayId={step._id} />

    <ProductionNotes step={step} />
  </SceneContainer>
));

const SceneContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding-bottom: var(--spacing-bottom-screen-container);

  @media screen and (min-width: ${breakPoint.medium}px) {
    gap: 3.2rem;
  }
`;
