import React, { useEffect } from 'react';
import styled from 'styled-components';

import { breakPoint } from '../../../../app/theme';
import { useSelection } from '../../../../core/contexts/selection.context';

export enum ProjectTemplateType {
  Corporate = 'corporate', // Demo
  Commercial = 'commercial',
  ShortFiction = 'short-fiction',
  Documentary = 'documentary',
  SocialMedia = 'social-media',
  Interview = 'interview',
  Event = 'event',
}

export enum ProjectTemplateCategory {
  Corporate = 'Corporate',
  Commercial = 'Commercial',
  ShortFiction = 'Short Fiction',
  Documentary = 'Documentary',
  SocialMedia = 'Social Media',
  Interview = 'Interview',
  Event = 'Event',
}

type ProjectTemplateItemProps = {
  category: ProjectTemplateCategory;
  value: ProjectTemplateType;
  backgroundImage: string;
  badge?: 'Demo' | 'Template';
  subCategory?: string;
  defaultValue?: ProjectTemplateType;
  onSelect?: () => void;
};

export const ProjectTemplateItem: React.FC<ProjectTemplateItemProps> = ({
  category,
  backgroundImage,
  badge,
  value,
  defaultValue,
  subCategory,
  onSelect,
}) => {
  const { select, elements } = useSelection();

  useEffect(() => {
    if (defaultValue) {
      select(ProjectTemplateType.Commercial);
    }
  }, []);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    select(value);
    onSelect && onSelect();
  };

  return (
    <Container onClick={handleClick} data-selected={elements.includes(value)}>
      <Cover src={backgroundImage} />

      <Badge>{badge}</Badge>

      <Content>
        <Header>
          <Title>{category}</Title>
          {subCategory && <Subtitle>{subCategory}</Subtitle>}
        </Header>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 12.8rem;
  overflow: hidden;
  cursor: pointer;
  border-radius: 0.6rem;
  box-sizing: border-box;
  border: 1px solid transparent;

  &[data-selected='true'] {
    border: 1px solid var(--color-primary-crayola, #367bff);
  }

  /* @media screen and (min-width: ${breakPoint.medium}px) {
    min-width: 12.8rem;
  } */
`;

const Cover = styled.div<{ src: string }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(37, 39, 45, 0.25) 0%, #181a1e 73.5%),
    url(${({ src }) => src});
  background-blend-mode: multiply;
  background-position: center;
  background-size: cover;
  transition: transform 0.3s ease-in-out;

  &[data-disabled] {
    background: linear-gradient(#141619cc, #141619cc), url(${({ src }) => src});
    background-size: cover;
  }
`;

const Badge = styled.div`
  position: absolute;
  top: 0.8rem;
  left: 0.8rem;
  background: #25272d;
  color: var(--color-grayscale-white);
  font-size: 1.2rem;
  font-weight: 400;
  padding: 0.4rem;
  border-radius: 0.4rem;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1.6rem;
  z-index: 1;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
`;

const Title = styled.p`
  color: var(--color-grayscale-white);
  font-size: 1.4rem;
  font-weight: 600;
`;

const Subtitle = styled.span`
  color: var(--color-grayscale-manatee);
  font-size: 1.4rem;
  font-weight: 400;
`;

const Footer = styled.div`
  position: relative;
  z-index: 5;

  & > button {
    background: rgba(20, 22, 25, 0.7);

    svg {
      stroke: var(--text-color-white);
      color: white;
    }
  }
`;
