import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface DialogFooterProps {
  actions: ReactElement | ReactElement[];
}

export const DialogFooter: React.FC<DialogFooterProps> = ({ actions }) => (
  <Footer>
    <ActionsContainer>{actions}</ActionsContainer>
  </Footer>
);

const Footer = styled.footer`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  max-width: 100%;
  padding: 1.6rem;
  box-sizing: border-box;
  border-top: 0.1rem solid var(--color-grayscale-trout);
  border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.6rem;
`;
