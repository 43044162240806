import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { EditCoverButton } from '../../features/projects/components/edit-cover.button';
import { PhotoUploader } from '../photo-uploader/photo-uploader';
import { PhotoCropperModal } from '../photo-cropper-modal/photo-cropper.modal';
import { ProfilePerson } from '../icons/ProfilePerson';
import { OutlinedButton } from '../buttons';
import { selectFiles } from '../../core/services/file.service';

export type ProfilePictureProps = {
  placeholder: 'text' | 'image';
  onChange?: (file: File) => void;
  defaultUrl?: string;
  imageHasError?: boolean;
  onImageLoadError?: () => void;
  initials?: string;
  aspectRatio?:
    | number
    | {
        minimum: number;
        maximum: number;
      };
  theme?: 'light' | 'dark';
};

export const ProfileAvatar: React.FC<ProfilePictureProps> = ({
  placeholder = 'text',
  onChange,
  defaultUrl,
  imageHasError,
  onImageLoadError,
  initials,
  aspectRatio = 1,
  theme,
}) => {
  const [url, setUrl] = useState<string | undefined>();
  const [imgFile, setImgFile] = useState<File | undefined>();
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>();

  useEffect(() => {
    setUrl(defaultUrl);
  }, [defaultUrl]);

  const handleCropperChange = async (file: File) => {
    setUrl(URL.createObjectURL(file));
    onChange && onChange(file);
  };

  const handleFileChange = async (file: File) => {
    if (!file) {
      return;
    }
    setImgFile(file);
    setIsImageModalOpen(true);
  };

  const handleSelectFile = async () => {
    const files = await selectFiles({ accept: 'image/*', multiple: false });

    if (!files) {
      return;
    }

    const file = files![0];
    await handleFileChange(file);
  };

  return (
    <Container>
      {placeholder === 'text' && (
        <>
          {url && (
            <UserPhotoContainer data-theme={theme}>
              {!imageHasError ? (
                <UserPhoto src={url} onError={onImageLoadError} />
              ) : (
                <InitialsContainer>{initials && <Initials>{initials}</Initials>}</InitialsContainer>
              )}
              {onChange && <EditCoverButton onChange={handleFileChange} />}
            </UserPhotoContainer>
          )}

          {!url &&
            (onChange ? (
              <PhotoUploader onChange={handleFileChange} />
            ) : (
              <InitialsContainer>{initials && <Initials>{initials}</Initials>}</InitialsContainer>
            ))}
        </>
      )}

      {placeholder === 'image' && (
        <>
          <ImagePlaceholderWrapper data-theme={theme}>
            {!imageHasError ? (
              url ? (
                <UserPhoto src={url} onError={onImageLoadError} />
              ) : (
                <ProfilePerson />
              )
            ) : (
              <InitialsContainer>{initials && <Initials>{initials}</Initials>}</InitialsContainer>
            )}
          </ImagePlaceholderWrapper>

          <Button onClick={handleSelectFile} text={url ? 'Change picture' : 'Add photo'} />
        </>
      )}

      {isImageModalOpen && (
        <PhotoCropperModal
          theme={theme}
          imgFile={imgFile}
          onCancel={() => setIsImageModalOpen(false)}
          onSave={handleCropperChange}
          aspectRatio={aspectRatio}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  &:hover {
    #edit {
      opacity: 1;
      transform: translateX(0) scale(1);
    }
  }
`;

const UserPhoto = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 38rem;
  border-radius: var(--border-radius-medium);
  aspect-ratio: 1;
`;

const UserPhotoContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;

  &[data-theme] {
    background: var(--color-grayscale-white);
  }
`;

export const InitialsContainer = styled.div`
  min-height: 24rem;
  width: 100%;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-default);
  background: var(--color-primary);
`;

export const Initials = styled.div`
  font-weight: 600;
  padding-top: 0.3rem;
  font-size: 6rem;
  padding-bottom: 3px;
  user-select: none;
  background: var(--color-primary);
  text-transform: uppercase;
  text-align: center;
`;

const ImagePlaceholderWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-grayscale-tuna);
  border-radius: 4px;
  width: 20rem;
  height: 20rem;

  &[data-theme] {
    background: var(--color-grayscale-white);
  }
`;

const Button = styled(OutlinedButton)`
  display: block;
  color: var(--color-primary-crayola);
  border: 1px solid var(--color-primary-crayola);
  margin-top: 1rem;
  width: 20rem;
  height: 3.5rem;
  text-align: center;

  &:hover,
  &:focus-visible {
    color: var(--color-primary-crayola);
    background: transparent;
    border-color: var(--color-primary-crayola);
  }
`;
