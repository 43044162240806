import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Pencil1Icon, PlusIcon } from '@radix-ui/react-icons';

import { Plus } from '../../../components/icons/Plus';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { ScreenHeader } from '../../projects/components/screen-header';
import { RenameStepModal } from '../../process/components/rename-step.modal';
import { ElevatedButton } from '../../../components/buttons/elevated-button';
import { useResponsive } from '../../../hooks/useResponsive';

import type { Step } from '../../../app/entities/step';

type TeamHeaderProps = {
  step: Step;
  onOpenAddDepartment: () => void;
};

export const TeamHeader: React.FC<TeamHeaderProps> = observer(({ step, onOpenAddDepartment }) => {
  const { isDesktop } = useResponsive();

  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);

  const items: React.ComponentProps<typeof DropdownMenu>['items'] = [
    ...(!isDesktop
      ? [
          {
            title: 'Add department',
            icon: <PlusIcon />,
            onSelect: onOpenAddDepartment,
          },
        ]
      : []),
    {
      title: 'Rename step',
      icon: <Pencil1Icon />,
      onSelect: () => setIsRenameModalOpen(true),
    },
  ];

  return (
    <>
      <ScreenHeader
        stepId={step._id}
        title={step.name}
        dueDate={step.dueDate}
        startDate={step.startDate}
        handleUpdateTimeFrame={(values) => step.update(values)}
        menu={<DropdownMenu items={items} size={'large'} />}
        actions={[
          <React.Fragment key="add-department">
            {isDesktop && !!step.info?.teamId && (
              <ElevatedButton onClick={onOpenAddDepartment} text="Add department" icon={<Plus />} />
            )}
          </React.Fragment>,
        ]}
        canValidateStep={!!step.info?.teamId}
      />

      {isRenameModalOpen && (
        <RenameStepModal step={step} onCancel={() => setIsRenameModalOpen(false)} />
      )}
    </>
  );
});
