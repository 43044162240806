import React from 'react';
import styled from 'styled-components';

interface NotificationProps {
  value: number;
  onClick?: (e: any) => void;
}

export const NotificationCount: React.FC<NotificationProps> = ({ value, onClick }) => (
  <NotificationItem onClick={onClick}>
    <div>{value}</div>
  </NotificationItem>
);

const NotificationItem = styled.div`
  position: relative;
  border-radius: 0.4rem;
  height: 2.4rem;
  width: 2.4rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: var(--text-color-white);
  background: var(--color-primary);
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.6rem;
  text-align: center;
`;
