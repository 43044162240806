import React from 'react';

import { ScheduleScreen } from '../../../features/schedule/screens/schedule.screen';
import { Project } from '../../../app/entities/project';

import type { Step } from '../../../app/entities/step';

export type SchedulePageProps = {
  project: Project;
  step: Step;
};

export const SchedulePage: React.FC<SchedulePageProps> = ({ project, step }) => (
  <ScheduleScreen step={step} projectId={project._id} />
);
