import Keycloak, { KeycloakConfig } from 'keycloak-js';
import { api } from './api';
import { appSocket } from './app-socket';

export let keycloak: Keycloak;

export const initKeycloak = (config: KeycloakConfig) => {
  keycloak = new Keycloak(config);
  return keycloak;
};

export const updateToken = () => {
  return keycloak
    .updateToken(300)
    .then((refreshed) => {
      if (refreshed) {
        handleAuthentication(true);
      } else {
        const url = keycloak.createLoginUrl();
        return ((window as any).location.href = url);
      }
    })
    .catch(() => {
      const url = keycloak.createLoginUrl();
      return ((window as any).location.href = url);
    });
};

export const handleAuthentication = (authenticated: boolean) => {
  if (!authenticated) {
    const search = new URLSearchParams(window.location.search);
    const action = search.get('action');

    const url = action === 'register' ? keycloak.createRegisterUrl() : keycloak.createLoginUrl();
    return ((window as any).location.href = url);
  }

  appSocket.init();

  if (keycloak.token) {
    localStorage.setItem('kc_token', keycloak.token);
  }

  if (keycloak.refreshToken) {
    localStorage.setItem('kc_refreshToken', keycloak.refreshToken);
  }

  api.updateTokenInHeaders(`${keycloak.token}`);
};
