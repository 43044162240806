import { api } from '../../api';
import { Stripboard } from '../../app/entities/stripboard';
import { entityPool } from '../../core/engine/engine';

import { Strip, type TypeTime } from '../schedule/models/types';

/**
 * Fetch a strip board
 */
export const fetchStripBoard = async (projectId: string) => {
  const { data } = await api.get(`/projects/${projectId}/strip-board`);
  const stripboard = Object.assign(new Stripboard(), {
    ...data,
    strips: data.strips.map((strip: any) => Object.assign(new Strip(), strip)),
  });
  entityPool.insert(stripboard);
};

/**
 * Create a strip board
 */

type CreateStripBoardInput = {
  projectId: string;
};

export const createStripBoard = async ({ projectId }: CreateStripBoardInput) => {
  const { data } = await api.post(`/projects/${projectId}/strip-board`);
  const stripboard = Object.assign(new Stripboard(), data);
  entityPool.insert(stripboard);
};

/**
 * Edit a single strip
 */

export type EditSingleStripInput = {
  stripBoardId: string;
  stripId: string;
  data?: {
    text?: string;
    estimatedTime?: number;
    startTime?: TypeTime;
    isHidden?: boolean;
  };
};
