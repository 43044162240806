import React from 'react';
import styled from 'styled-components';

import { InfoTextLine } from '../texts/Texts';
import { DropdownProps } from '.';
import { StyledContainer, StyledLine as Line, StyledEmpty as Empty } from './StyledDropdown';
import { DropdownMenu } from '../../components/modals/dropdown-menu/DropdownMenu';

export const InlineDropDown: React.FC<DropdownProps> = ({
  value,
  label = '',
  onChange,
  options,
  disabled,
}) => {
  const items = options.map((option) => ({
    title: option.label,
    onSelect: () => onChange(option.value),
  }));

  return (
    <Container>
      <DropdownMenu
        align="start"
        trigger={
          <Line data-disabled={disabled} tabIndex={0} data-ellipsis>
            {value ? <InfoTextLine value={label} /> : <Empty>Choose</Empty>}
          </Line>
        }
        items={items}
        modal={false}
      />
    </Container>
  );
};

const Container = styled(StyledContainer)`
  margin: 0 -1.2rem;
  max-width: 100%;
`;
