import React from 'react';

export const BlueCheckmark = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 11.9505L10.0345 16.0005L19 7.00049"
        stroke="#367BFF"
        strokeWidth="3"
        strokeLinecap="square"
      />
    </svg>
  );
};
