import { makeObservable, observable } from 'mobx';

export interface TypeTime {
  hour: number;
  minute: number;
  period: 'am' | 'pm';
}

export type BannerStripData = {
  text?: string;
  estimatedTime?: number;
};

export type ShotStripData = {
  shotId: string;
  isHidden: boolean;
};

export type DayBreakStripData = {
  stepId: string;
  dod: number;
  endTime?: TypeTime;
  totalEstimatedTime: number;
  totalShotsDuration: number;
};

export class Strip {
  _id: string;
  type: 'banner' | 'dayBreak' | 'shot';
  position: number;
  startTime?: TypeTime;
  count?: number;
  data: BannerStripData | ShotStripData | DayBreakStripData;

  constructor() {
    makeObservable(this, {
      type: observable,
      position: observable,
      startTime: observable,
      count: observable,
      data: observable,
    });
  }
}

export class BannerStrip extends Strip {
  declare data: BannerStripData;
}

export class ShotStrip extends Strip {
  declare data: ShotStripData;
}

export class DayBreakStrip extends Strip {
  declare data: DayBreakStripData;
}
