import React from 'react';

export const RotateLeft = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5042_457629)">
      <path d="M11.0008 9H21.0008C21.266 9 21.5204 9.10536 21.7079 9.29289C21.8955 9.48043 22.0008 9.73478 22.0008 10V20C22.0008 20.2652 21.8955 20.5196 21.7079 20.7071C21.5204 20.8946 21.266 21 21.0008 21H11.0008C10.7356 21 10.4812 20.8946 10.2937 20.7071C10.1062 20.5196 10.0008 20.2652 10.0008 20V10C10.0008 9.73478 10.1062 9.48043 10.2937 9.29289C10.4812 9.10536 10.7356 9 11.0008 9ZM12.0008 11V19H20.0008V11H12.0008ZM6.00081 10.586L7.82881 8.757L9.24381 10.172L5.00081 14.414L0.757812 10.172L2.17281 8.757L4.00081 10.586V8C4.00081 6.67392 4.5276 5.40215 5.46528 4.46447C6.40296 3.52678 7.67473 3 9.00081 3H13.0008V5H9.00081C8.20516 5 7.4421 5.31607 6.87949 5.87868C6.31688 6.44129 6.00081 7.20435 6.00081 8V10.586Z" />
    </g>
    <defs>
      <clipPath id="clip0_5042_457629">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
