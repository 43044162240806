import React from 'react';

import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { DescriptiveOption } from '../../../components/modals/dropdown-menu/auxiliary.components';

import { type Space } from '../../../app/entities/space';

type RoleDropdownProps = {
  space: Space;
  width?: number;
  trigger: (label?: string) => React.ReactNode;
  role?: RoleTypes;
  onChange?: (role?: RoleTypes) => void;
};

const roleDropdownOptions = [
  {
    value: 'creator',
    title: 'Creator',
    description: 'Creators can create projects, edit everything, download and share files.',
    disabledDescription: 'You need admin rights to add additional creators',
  },
  {
    value: 'guest',
    title: 'Guest',
    description: 'Guests can view everything in this project and leave comments.',
  },
] as const;

export type RoleTypes = (typeof roleDropdownOptions)[number]['value'];

export const RoleDropdown: React.FC<RoleDropdownProps> = ({
  space,
  role,
  width,
  trigger,
  onChange,
}) => {
  const dropdownItems: React.ComponentProps<typeof DropdownMenu>['items'] =
    roleDropdownOptions?.map((item) => {
      const isCreatorOption = item.value === 'creator';

      const finalItem = {
        ...item,
        isChecked: item.value === role,
        onCheckedChange: (isChecked: boolean) => {
          if (isChecked) {
            onChange?.(item.value);
          }
        },
      };

      if (!isCreatorOption) {
        return finalItem;
      }

      const isOnFreePlan = space && !space.hasSubscription() && space.flags?.['space.settings'];
      const isDisabled = isOnFreePlan || !space.flags?.['space.settings'];
      const description = isDisabled && !isOnFreePlan ? item.disabledDescription : item.description;

      const tag = isOnFreePlan ? 'UPGRADE' : undefined;

      return {
        ...finalItem,
        disabled: isDisabled,
        tag,
        description,
      };
    }) || [];

  const dropdownLabel =
    (dropdownItems?.find((item) => !!item.isChecked)?.title as string) || undefined;

  return (
    <DropdownMenu
      trigger={trigger(dropdownLabel)}
      items={dropdownItems}
      itemComponent={<DescriptiveOption title="" width={width} />}
      portalContainer={document.body as HTMLDivElement}
    />
  );
};

RoleDropdown.displayName = 'RoleDropdown';
