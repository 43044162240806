import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ProgressIndicator } from '../../../components/progress-indicator/progress-indicator';

export const OnboardingStepsIndicator: React.FC = () => {
  const [step, setStep] = useState<number>(0);

  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath.includes('onboarding/update-profile')) setStep(1);
    else if (currentPath.includes('onboarding/create-workspace')) setStep(2);
    else if (currentPath.includes('onboarding/stats')) setStep(3);
    else setStep(0);
  }, [location]);

  return <>{!!step && <ProgressIndicator max={3} value={step} label={`Step ${step} of 3`} />}</>;
};
