import React from 'react';

export const PhoneLock = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 115" version="1.1" x="0px" y="0px">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M73,57 C79.0751322,57 84,61.9248678 84,68 C84,68.9276215 83.3404069,69.7241774 82.4290548,69.8971481 L82.1349342,69.9529709 C81.16926,70.1362517 80.2378479,69.5019965 80.0545672,68.5363223 C80.0474328,68.4987326 80.0415125,68.4609227 80.0368154,68.4229514 L79.8523369,66.9316273 C79.8523369,63.1461756 76.9952166,61.1309013 73.2406498,61.004059 L73,61 C69.2145483,61 66.1309013,64.0047834 66.004059,67.7593502 L66,68 L66,73 L84,73 C87.3137085,73 90,75.6862915 90,79 L90,90 C90,93.3137085 87.3137085,96 84,96 L61,96 C57.6862915,96 55,93.3137085 55,90 L55,79 C55,75.6862915 57.6862915,73 61,73 L62,73 L62,68 C62,61.9248678 66.9248678,57 73,57 Z M83,77 L62,77 C60.4023191,77 59.0963391,78.24892 59.0050927,79.8237272 L59,80 L59,89 C59,90.5976809 60.24892,91.9036609 61.8237272,91.9949073 L62,92 L83,92 C84.5976809,92 85.9036609,90.75108 85.9949073,89.1762728 L86,89 L86,80 C86,78.3431458 84.6568542,77 83,77 Z"
        fill="#E6EDF3"
      />
      <path
        d="M64,6 C70.627417,6 76,11.372583 76,18 L76,51 C76,52.1045695 75.1045695,53 74,53 C72.8954305,53 72,52.1045695 72,51 L72,19 L72,19 C72,14.0294373 67.9705627,10 63,10 L26,10 C21.1181973,10 17.1442086,13.8868137 17.0038371,18.7346377 L17,19 L17,80 C17,84.8818027 20.8868137,88.8557914 25.7346377,88.9961629 L26,89 L48,89 C49.1045695,89 50,89.8954305 50,91 C50,92.1045695 49.1045695,93 48,93 L25,93 L25,93 C18.372583,93 13,87.627417 13,81 L13,18 C13,11.372583 18.372583,6 25,6 L64,6 Z"
        fill="#E6EDF3"
      />
      <circle fill="#E6EDF3" cx="45" cy="73" r="4" />
      <path
        d="M73,81 C74.1045695,81 75,81.8954305 75,83 C75,83.7398375 74.5982846,84.3858493 74.0010775,84.7318119 L74,87 C74,87.5522847 73.5522847,88 73,88 C72.4477153,88 72,87.5522847 72,87 L71.9999275,84.7323937 C71.4021661,84.3865739 71,83.7402524 71,83 C71,81.8954305 71.8954305,81 73,81 Z"
        fill="#E6EDF3"
      />
    </g>
  </svg>
);
