import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Date } from '../date/date';
import { DateSelectionModal } from '../../features/process/components/date-selection.modal';
import { Step, StepStatus } from '../../app/entities/step';
import { DeleteDialog } from '../dialogs/DeleteDialog';
import { authStore } from '../../core/stores/auth-store';
import { AssigneeSelector } from '../assignee-selector/assignee-selector';
import { StatusSelector } from '../../features/process/components/status-selector';

type StepItemProps = {
  step: Step;
};

export const StepItem: React.FC<StepItemProps> = observer(({ step }) => {
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const navigate = useNavigate();
  const [isEditOpen, setEditOpen] = useState(false);
  const { spaceId } = useParams();

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: step._id,
    data: {
      type: 'step',
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const currentMember = authStore.getCurrentMember(spaceId!);

  const isShootingDay = step.type === 'shootingDay';

  const handleDelete = async () => {
    setDeleteModalIsOpen(false);
  };

  const handleOpenDate = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setEditOpen(true);
  };

  const handleUpdateStep = async (date: any) => {
    step.update({ ...(isShootingDay ? { startDate: date, dueDate: date } : { dueDate: date }) });
  };

  const handleChangeStatus = async (newStatus: StepStatus) => {
    try {
      await step.update({ status: newStatus });
    } catch (e) {
      console.error(e);
    }
  };

  const goTo = () => navigate(`./steps/${step._id}`);

  return (
    <>
      <TaskItem
        data-status={step.status}
        id={`${step.displayName.toLowerCase().replaceAll(' ', '-')}-step`}
        current={step.current}
        onClick={goTo}
        done={step.done}
        ref={!isShootingDay ? setNodeRef : undefined}
        style={!isShootingDay ? style : undefined}
        data-dragging={isDragging}
        {...(!isShootingDay ? attributes : {})}
        {...(!isShootingDay ? listeners : {})}
      >
        <StatusSelector
          onChange={handleChangeStatus}
          status={step.status}
          isViewOnly={currentMember?.role === 'guest'}
        />

        <div style={{ flex: 1 }}>
          <StepHeader>
            <Title done={step.done} current={step.current}>
              {step.displayName}
            </Title>

            <AssigneeSelector
              collaborators={step.project!.collaborators}
              assigneeId={step.assigneeId}
              onCheckedChange={(assigneeId) => {
                step.update({
                  assigneeId,
                });
              }}
            />
          </StepHeader>

          <Date date={isShootingDay ? step.startDate : step.dueDate} onClick={handleOpenDate} />
        </div>
      </TaskItem>

      {deleteModalIsOpen && (
        <DeleteDialog
          title={'Delete step ?'}
          text={`Are you sure you want to delete ${step.name}?`}
          onCancel={() => setDeleteModalIsOpen(false)}
          onSubmit={handleDelete}
        />
      )}

      {isEditOpen && (
        <DateSelectionModal
          onCancel={() => setEditOpen(false)}
          fieldName={isShootingDay ? 'startDate' : 'dueDate'}
          value={isShootingDay ? step.startDate : step.dueDate}
          onSubmit={handleUpdateStep}
        />
      )}
    </>
  );
});

interface TaskItemProps {
  current?: boolean;
  done: boolean;
}

const StepHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TaskItem = styled.div<TaskItemProps>`
  width: 100%;
  font-weight: 600;
  border-radius: var(--card-border-radius);
  background-color: ${({ done }) => (done ? 'rgba(49, 50, 54, 0.5)\n' : '#313236')};
  display: flex;
  padding: 1.2rem;
  position: relative;
  cursor: pointer;
  color: ${({ done }) => (done ? '#8a8f98' : 'var(--step-title-color)')};

  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }

  &[data-dragging='true'] {
    opacity: 0.5;
  }
`;

const Title = styled.div<TaskItemProps>`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.6rem;
  margin-bottom: 1.2rem;
  margin-top: 0.4rem;
`;
