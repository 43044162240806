import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { EditableText } from '../../components/editable-text/EditableText';
import { InlineDropDown } from '../../components/dropdowns/InlineDropDown';
import { enumToSelectOptions } from '../../lib/utils/Convertor';
import { CoverPlaceholder } from '../strip-board/components/cover-placeholder';
import { InlineTimePeriodSelector } from '../schedule/components/InlineTimePeriodSelector';
import { ShotMovementEnum } from '../../assets/enums/shotMovement.enum';
import { CameraAngleEnum } from '../../assets/enums/cameraAngle.enum';
import { ShotSizeEnum } from '../../assets/enums/shotSize.enum';
import { KeyboardKeys } from '../../assets/enums/keyboard-keys.enum';
import { getModifier } from '../../core/services/event.service';
import { Modifier, useSelection } from '../../core/contexts/selection.context';
import { Description } from '../../components/texts/Texts';
import {
  BaseRow,
  Cell,
  OrderIndicator,
  Image,
  CoverItem,
  HiddenCoverOverlay,
} from '../schedule/components/styled-strips';
import { ShotListMenu } from './shot-list-menu';
import { Shot, UpdateShotsInput } from '../../app/entities/shot';
import { Project } from '../../app/entities/project';
import { Stripboard } from '../../app/entities/stripboard';
import { bulkUpdateStore } from '../../core/stores/bulk-update';
import { ShotStrip, Strip } from 'features/schedule/models/types';

export type ShotListItemProps = {
  project: Project;
  stripboard: Stripboard;
  style?: any;
  strip: Strip;
  isSelected?: boolean;
  isDragging?: boolean;
  onClick?: (stripId: string, modifier?: Modifier) => void;
};

export const ShotListItem = observer(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLTableRowElement, ShotListItemProps>(
    ({ project, stripboard, strip, isSelected, isDragging, onClick, ...draggableProps }, ref) => {
      const { t } = useTranslation('shot');
      const { elements: selectedStripsIds } = useSelection();

      const isBulkUpdate = selectedStripsIds && selectedStripsIds.length > 1;
      const shot = Shot.getOne((strip as ShotStrip).data.shotId);

      const handleSingleEdit = (data: UpdateShotsInput) => {
        shot && shot.update(data!);
      };

      const handleEdit = (data: UpdateShotsInput) => {
        const selectedShotsIds = stripboard.strips
          .filter((el) => selectedStripsIds.includes(el._id) && el.type === 'shot')
          .map((el) => el.data.shotId);

        isBulkUpdate
          ? bulkUpdateStore.handleShotsBulkUpdate(data, selectedShotsIds, shot!)
          : handleSingleEdit(data);
      };

      if (!shot) {
        return null;
      }

      const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        const modifier = getModifier(e.nativeEvent);

        onClick && onClick(strip._id, modifier);
      };

      return (
        <>
          <Row
            ref={ref}
            onClick={handleClick}
            data-selected={isSelected}
            data-dragged={isDragging}
            data-isHidden={(strip as ShotStrip).data.isHidden}
            {...draggableProps}
          >
            <Cell>
              <CoverWrapper>
                {!!shot.order && <OrderIndicator>{shot.order}</OrderIndicator>}

                {shot.cover?.src ? (
                  <Image src={shot.cover?.src || ''} />
                ) : (
                  <CoverPlaceholder onUpload={(file) => handleSingleEdit({ file })} />
                )}

                <HiddenCoverOverlay data-hide={!(strip as ShotStrip).data.isHidden} />
              </CoverWrapper>
            </Cell>

            <Cell>
              <TitleDescriptionContainer>
                <EditableText onSubmit={(title) => handleSingleEdit({ title })}>
                  <Title>{shot.title}</Title>
                </EditableText>

                <CustomEditable onSubmit={(description) => handleSingleEdit({ description })}>
                  <CustomDescription>{shot.description}</CustomDescription>
                </CustomEditable>
              </TitleDescriptionContainer>
            </Cell>

            <Cell>
              <InlineDropDown
                value={shot.shotSize}
                label={t(`${shot.shotSize}`)}
                onChange={(shotSize) => handleEdit({ shotSize })}
                options={enumToSelectOptions(ShotSizeEnum, t)}
              />
            </Cell>

            <Cell>
              <InlineDropDown
                value={shot.angle}
                label={t(`shot:${shot.angle}`)}
                onChange={(angle) => handleEdit({ angle })}
                options={enumToSelectOptions(CameraAngleEnum, t)}
              />
            </Cell>

            <Cell>
              <InlineDropDown
                value={shot.movement}
                label={t(`shot:${shot.movement}`)}
                onChange={(movement) => handleEdit({ movement })}
                options={enumToSelectOptions(ShotMovementEnum, t)}
              />
            </Cell>

            <Cell>
              <InputDrawer value={shot.lens || '-'} onChange={(lens) => handleEdit({ lens })} />
            </Cell>

            <Cell>
              <NumberInputDrawer value={shot.fps} onChange={(fps) => handleEdit({ fps })} />
            </Cell>

            <Cell>
              {!(strip as ShotStrip).data.isHidden && (
                <InlineTimePeriodSelector
                  onChange={(estimatedTime) => handleEdit({ estimatedTime })}
                  time={shot.estimatedTime}
                />
              )}
            </Cell>

            <Cell>
              <ShotListMenu
                stripId={strip._id}
                shot={shot}
                stripboard={stripboard}
                isHidden={!!(strip as ShotStrip).data.isHidden}
              />
            </Cell>
          </Row>
        </>
      );
    },
  ),
);

ShotListItem.displayName = 'ShotListItem';

const Row = styled(BaseRow)`
  align-items: center;
  background-color: var(--color-grayscale-charleston);

  &[data-isHidden='true'] {
    *:not(button),
    *:hover:not(button),
    *:focus-visible:not(button) {
      color: var(--color-grayscale-trout);
    }
  }
`;

const CoverWrapper = styled(CoverItem)`
  margin-inline-start: 0.3rem;
`;

const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6rem;
  width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: auto;
  -webkit-box-orient: vertical;
  color: var(--color-grayscale-ghost);

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CustomEditable = styled(EditableText)`
  height: 100%;
  display: flex;
  align-items: center;
`;

const CustomDescription = styled(Description)`
  padding: 0;
  height: fit-content;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  overflow-y: auto;
  color: var(--color-grayscale-ghost);
  -webkit-line-clamp: 2;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TitleDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  width: 100%;
`;

interface TextInputProps {
  value: string;
  onChange: (value: string) => void;
  required?: boolean;
  placeholder?: string;
  autocomplete?: string;
  name?: string;
  defaultValue?: string;
}

export const InputDrawer: React.FC<TextInputProps> = ({
  value,
  onChange,
  placeholder,
  name,
  autocomplete,
  defaultValue,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [_value, setValue] = useState(value);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleOnBlur = async () => {
    if (_value !== value) {
      onChange(_value);
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KeyboardKeys.Escape) {
      setValue(value);
      return;
    }

    if (e.key === KeyboardKeys.Enter && value !== _value) {
      inputRef!.current?.blur();
      return;
    }
  };

  return (
    <Input
      ref={inputRef}
      value={_value}
      onChange={handleChange}
      type="text"
      name={name}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onKeyDown={handleKeyDown}
      onBlur={handleOnBlur}
      onClick={(e) => e.stopPropagation()}
      autoComplete={autocomplete}
    />
  );
};

interface NumberInputProps {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  defaultValue?: number;
}

export const NumberInputDrawer: React.FC<NumberInputProps> = ({ value, onChange, min = 1 }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [_value, setValue] = useState(value?.toString() || '');

  useEffect(() => {
    setValue(value?.toString() || '');
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleOnBlur = async () => {
    if (_value !== value?.toString()) {
      onChange(parseFloat(_value));
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KeyboardKeys.Escape) {
      setValue((value || '').toString());
      return;
    }

    if (e.key === KeyboardKeys.Enter && value?.toString() !== _value) {
      inputRef!.current?.blur();
      return;
    }
  };

  return (
    <Input
      ref={inputRef}
      value={_value}
      onChange={handleChange}
      type={'number'}
      onBlur={handleOnBlur}
      onKeyDown={handleKeyDown}
      onClick={(e) => e.stopPropagation()}
      min={min}
    />
  );
};

const Input = styled.input`
  width: 100%;
  margin-inline-start: -1.2rem;
  padding: 0.8rem 1.2rem;
  line-height: 1.6rem;
  border-radius: var(--border-radius-small);
  transition: all 100ms ease-in-out;
  background-color: transparent;
  border: none;
  color: var(--color-grayscale-ghost);

  &:hover,
  &:focus-visible {
    background-color: var(--color-grayscale-arsenic);
    cursor: auto;
    color: var(--color-grayscale-white);
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
