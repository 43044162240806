import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { DescriptionHeader } from '../../user/components/description-header';

interface SettingScreenProps extends React.PropsWithChildren {
  title: string;
  hint?: string;
  className?: string;
  actions?: ReactElement | ReactElement[];
  onBack?: () => void;
}

export const SettingScreenLayout: React.FC<SettingScreenProps> = ({
  children,
  title,
  hint,
  className,
  actions,
  onBack,
}) => {
  return (
    <Wrapper className={className}>
      <DescriptionHeader title={title} description={hint} actions={actions} onBack={onBack} />

      <Screen>{children}</Screen>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: start;
`;

const Screen = styled.div`
  min-height: 0;
  width: 100%;
  height: 100%;
`;
