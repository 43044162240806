import { makeObservable, runInAction } from 'mobx';

import { Project } from './project';
import { Model } from '../../core/engine/model';
import { ManyToMany, ManyToOne } from '../../core/engine/decorators';
import { Cover } from '../../core/models/cover';
import { Location } from './location';
import { Character } from './character';
import { api } from '../../api';
import { Frame } from '../../lib/utils/transform-image';
import { selectFiles, upload } from '../../core/services/file.service';
import { entityPool } from '../../core/engine/engine';
import { Step } from './step';
import { catchError } from '../../core/catch-error';
import { formatPicture } from '../../core/services/image.service';
import { Strip } from '../../features/schedule/models/types';

export type UpdateShotsInput =
  | Partial<Shot> & {
      file?: Frame;
      characterIdToAdd?: string;
      characterIdToRemove?: string;
    };

export class Shot extends Model {
  /** A short title for the shot**/
  title: string;

  /** A longer description for the shot**/
  description: string;

  timing?: 'day' | 'night';

  setting?: 'interior' | 'exterior';

  directorsNote?: string;
  voiceOver?: string;
  props?: string;
  src?: string;
  order: number;
  shotListOrder?: number;
  number?: number;
  commentIndicator?: number;

  _shootingDayId?: string | null;

  /**
   * If we are updating a shooting day, also update the list of hots in the respective shooting day
   * I haven't found a way to do it directly from the Engine yet.
   */
  set shootingDayId(id: string | undefined | null) {
    this._shootingDayId = id;

    if (this.shootingDay) {
      this.shootingDay.shots = this.shootingDay.shots.filter((shot) => shot !== this);
    }

    if (!id) {
      return;
    }

    const shootingDay = Step.getOne(id);

    if (shootingDay) {
      shootingDay.shots.push(this);
      this.shootingDay = shootingDay;
    }
  }

  get shootingDayId() {
    return this._shootingDayId;
  }

  @ManyToOne('shots')
  shootingDay?: Step;

  shotSize?: string;

  movement?: string;

  lens: string;
  light?: string;
  angle?: string;

  _fps?: number;

  estimatedTime?: number;
  isHidden: boolean;

  cover?: Cover;

  completed: boolean;

  projectId: string;

  /** The shot's project **/
  @ManyToOne('shots')
  public project: Project | null = null;

  locationId: string;

  @ManyToOne('shots')
  location: Location;

  @ManyToMany('shots')
  characters: Character[];

  set fps(fps: number) {
    this._fps = fps;
  }

  get fps(): number {
    return this._fps ? this._fps : this.project!.fps;
  }

  constructor() {
    super('shots');

    this.characters = [];

    makeObservable(this, {
      title: true,
      description: true,
      timing: true,
      setting: true,
      directorsNote: true,
      voiceOver: true,
      props: true,
      src: true,
      order: true,
      shotListOrder: true,
      number: true,
      commentIndicator: true,
      shootingDayId: true,
      shotSize: true,
      movement: true,
      lens: true,
      light: true,
      angle: true,
      _fps: true,
      estimatedTime: true,
      isHidden: true,
      cover: true,
      completed: true,
      projectId: true,
      project: true,
      locationId: true,
      location: true,
      characters: true,
      shootingDay: true,
    });
  }

  toPOJO(): Record<string, any> {
    return {
      _id: this._id,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      title: this.title,
      description: this.description,
      timing: this.timing,
      setting: this.setting,
      directorsNote: this.directorsNote,
      voiceOver: this.voiceOver,
      props: this.props,
      src: this.src,
      order: this.order,
      shotListOrder: this.shotListOrder,
      number: this.number,
      commentIndicator: this.commentIndicator,
      shootingDayId: this.shootingDayId,
      shotSize: this.shotSize,
      movement: this.movement,
      lens: this.lens,
      light: this.light,
      angle: this.angle,
      fps: this.fps,
      estimatedTime: this.estimatedTime,
      isHidden: this.isHidden,
      cover: this.cover,
      completed: this.completed,
      projectId: this.projectId,
      locationId: this.locationId,
      location: this.location,
      characters: this.characters,
    };
  }

  static async createShot({
    projectId,
    file,
    position,
    ...input
  }: {
    projectId: string;
    file?: Frame;
    stripPosition?: number;
    position?: number;
  }) {
    try {
      if (file) {
        // @ts-ignore
        input.file = {
          fileSize: file.size,
          fileType: file.type,
          type: file.type.split('/')[1],
        };
      }

      const { data } = await api.post(`/projects/${projectId}/shots`, input);

      if (data.links && file) {
        await upload(data.links.upload, file.data);
      }
      const { strip, ...shotData } = data;

      const shot = Object.assign(new Shot(), shotData);
      entityPool.insert(shot);

      const project = Project.getOne(projectId);
      await project?.storyboard?.addShot(shot._id, position);
      project?.stripboard?.addStrip(strip, input.stripPosition);
    } catch (e) {
      catchError(e);
    }
  }

  async addCharacter(characterId: string) {
    await api.post(`/shots/${this._id}/characters`, { characterId });

    const character = Character.getOne(characterId)!;
    character.shots.push(this);
    this.characters.push(character);
  }

  async deleteCharacter(characterId: string) {
    await api.delete(`shots/${this._id}/characters/${characterId}`);
    this.characters = this.characters.filter((character) => character._id !== characterId);
  }

  static getOne(id: string): Shot | undefined {
    return Model.getOne(id) as Shot;
  }

  async delete() {
    try {
      await api.delete(`/shots/${this._id}`);

      const stripboard = this.project!.stripboard;

      if (stripboard) {
        this.project!.stripboard.strips = this.project!.stripboard.strips.filter(
          (st) => st.data.shotId !== this._id,
        );

        stripboard.updateSchedule();
      }

      entityPool.delete(this);
    } catch (e) {
      catchError(e);
    }
  }

  async update({ file, ...update }: UpdateShotsInput) {
    // Save current shot state for caught errors revert
    const currentShot = { ...this };

    const payload: any = { ...update };

    if (file) {
      // @ts-ignore
      payload.file = {
        hasChanged: true,
        fileSize: file.size,
        fileType: file.type,
        type: file.type.split('/')[1],
      };
    }

    runInAction(() => {
      Object.assign(this, {
        ...payload,
        cover: {
          src: file ? URL.createObjectURL(file.data) : currentShot.cover?.src,
        },
      });
    });

    const strips = this.project?.stripboard?.strips;
    let currentStrips = null;

    if (strips) {
      currentStrips = JSON.parse(JSON.stringify(strips));

      // Move shot strip if shooting day on shot is updated
      if (payload.shootingDayId !== undefined) {
        const sourceIndex = strips!.findIndex((strip) => strip.data.shotId === this._id);
        const shotStrip = strips!.splice(sourceIndex, 1)?.[0];

        const targetIndex = strips!.findIndex(
          (strip) => strip.data.stepId === payload.shootingDayId,
        );
        const beforeTarget = strips.slice(0, targetIndex);
        const afterTarget = strips.slice(targetIndex);

        this.project!.stripboard!.strips = [...beforeTarget, shotStrip, ...afterTarget];
      }

      // Update shot strip estimated time if changed
      if (payload.estimatedTime !== undefined) {
        const shotStrip = strips?.find((strip) => strip.data.shotId === this._id);
        Object.assign(shotStrip.data, update);
      }

      if (payload.locationId !== undefined) {
        runInAction(() => {
          this.location = Location.getOne(payload.locationId)!;
        });
      }

      runInAction(() => {
        Object.assign(this, payload);
        // Recalculate strip times after update
        this.project?.stripboard.updateSchedule();
      });
    }

    try {
      const { data } = await api.patch(`/shots/${this._id}`, payload);

      if (data.links && file) {
        await upload(data.links.upload, file.data);
        this.cover = data.cover;
      }
    } catch (e) {
      catchError(e);
      Object.assign(this, currentShot);

      if (currentStrips) {
        this.project!.stripboard.strips = currentStrips;
      }
    }
  }

  changeImage = async () => {
    const selectedImages = await selectFiles({
      multiple: false,
      accept: 'image/png, image/jpg, image/jpeg',
    });
    if (!selectedImages || selectedImages.length === 0) return;
    if (!['image/png', 'image/jpg', 'image/jpeg'].includes(selectedImages[0].type)) return;
    const options = { width: 640, height: 380 };
    const frame = await formatPicture(selectedImages[0], options);
    this.update({ file: frame });
  };

  static async bulkUpdateShots(
    shotsIds: string[],
    projectId: string,
    update: Partial<Omit<Shot, '_id'>>,
  ) {
    const payload: any = { ...update, shotsIds };
    const project = Project.getOne(projectId);
    const stripboard = project?.stripboard;
    const strips = stripboard?.strips;
    const oldStrips = strips && JSON.parse(JSON.stringify(strips));
    const oldShots = [];

    let lastShotOrder = 0;

    if (payload.isHidden === false) {
      const sortedShots = [...project!.shots]
        .filter((sh) => !sh.isHidden)
        .sort((a, b) => (a.order > b.order ? -1 : 1));

      lastShotOrder = sortedShots.find((sh) => !sh.isHidden)?.order || 0;
    }

    const strippedShots = [];

    for (const shotId of shotsIds) {
      const shot = Shot.getOne(shotId);

      if (shot) {
        oldShots.push({ ...shot });

        Object.assign(shot, payload);

        if (payload.locationId) {
          shot.location = Location.getOne(payload.locationId)!;
        }

        if (payload.estimatedTime !== undefined) {
          const shotStrip = strips?.find((strip) => strip.data.shotId === shotId);

          if (shotStrip) {
            Object.assign(shotStrip.data, { estimatedTime: payload.estimatedTime });
          }
        }

        if (payload.characterIdToAdd) {
          const character = Character.getOne(payload.characterIdToAdd)!;

          const isAlreadyAdded = shot.characters.find((el) => el._id === payload.characterIdToAdd);
          if (!isAlreadyAdded) {
            character.shots.push(shot);
            shot.characters.push(character);
          }
        }

        if (payload.characterIdToRemove) {
          shot.characters = shot.characters.filter((el) => el._id !== payload.characterIdToRemove);
        }

        if (payload.isHidden !== undefined) {
          const strip = strips?.find((el) => el.data?.shotId === shot._id);
          if (strip) {
            strip.data.isHidden = payload.isHidden;
          }

          if (payload.isHidden) {
            shot.order = 0;
            shot.isHidden = true;
          } else {
            shot.order = lastShotOrder + 1;
            shot.isHidden = false;
            lastShotOrder++;
          }
        }

        if (payload.shootingDayId !== undefined) {
          const stripIndex = strips?.findIndex((el) => el.data?.shotId === shot._id) ?? -1;
          const shotStrip = stripIndex >= 0 && strips?.splice(stripIndex, 1)?.[0];

          if (shotStrip) {
            strippedShots.push(shotStrip);
          }

          shot.shootingDayId = payload.shootingDayId;
        }
      }
    }

    if (payload.shootingDayId !== undefined) {
      // Unassign a shooting day
      if (payload.shootingDayId === null) {
        stripboard!.strips = [...stripboard!.strips, ...strippedShots];
        // eslint-disable-next-line brace-style
      }

      // Reassign a shooting day
      else {
        const shootingDayStripIndex =
          strips?.findIndex((strip) => strip.data?.stepId === payload.shootingDayId) ?? -1;

        if (shootingDayStripIndex >= 0) {
          const beforeTarget = strips?.slice(0, shootingDayStripIndex) || [];
          const afterTarget = strips?.slice(shootingDayStripIndex) || [];

          stripboard!.strips = [...beforeTarget, ...strippedShots, ...afterTarget];
        }
      }
    }

    if (
      payload.estimatedTime !== undefined ||
      payload.isHidden !== undefined ||
      payload.shootingDayId !== undefined
    ) {
      stripboard?.updateSchedule();
    }

    try {
      await api.patch(`/projects/${project!._id}/shots/`, payload);
    } catch (e) {
      catchError(e);

      for (let i = 0; i < shotsIds.length; i++) {
        const shotId = shotsIds[i];
        const oldShot = oldShots[i];
        const updatedShot = Shot.getOne(shotId);

        if (updatedShot) {
          Object.assign(updatedShot, oldShot);
        }
      }

      if (oldStrips) {
        stripboard.strips = oldStrips;
      }
    }
  }

  static duplicateShots = async (shotsIds: string[]) => {
    try {
      const { data } = (await api.post('/shots/duplicate', { shotsIds })) as {
        data: { shots: Array<Shot & { storyBoardPosition: number }>; strips: Strip[] };
      };

      if (!data || !data.shots || !data.shots.length) return;

      const storyboard = Project.getOne(data.shots[0].projectId)?.storyboard;

      data.shots.forEach((shot) => {
        const { storyBoardPosition, ...shotData } = shot;
        const newShot = Object.assign(new Shot(), shotData);
        if (storyboard) {
          storyboard.insertShot(newShot, storyBoardPosition);
        }
        entityPool.insert(newShot);
      });

      const stripBoard = Project.getOne(data.shots[0].projectId)?.stripboard;

      if (!stripBoard) return;

      data?.strips?.forEach((strip) => {
        const newStrip = Object.assign(
          {},
          {
            ...strip,
            position: strip.position - 1,
          },
        );
        stripBoard.addStrip(newStrip);
      });

      stripBoard.updateSchedule();
    } catch (e) {
      catchError(e);
    }
  };

  static async deleteShots(shotsIds: string[]) {
    try {
      await api.post('/shots/delete-shots', { shotsIds });

      shotsIds.forEach((shotId) => {
        const shot = Shot.getOne(shotId);
        if (shot) {
          shot.remove();
        }
      });
    } catch (e) {
      catchError(e);
    }
  }

  static getAll() {
    return Model.getAll().filter((model) => model instanceof Shot) as Shot[];
  }
}
