import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { fetchStripBoard } from '../../strip-board/strip-board.slice';
import { ShootingSchedule } from '../../schedule/components/ShootingSchedule';
import { Project } from '../../../app/entities/project';
import { Header } from '../../../components/table/styled-shared-table';
import { EmptyTablePlaceholder } from 'components/table/empty-table-placeholder';

import type { Step } from '../../../app/entities/step';
import type { DayBreakStripData } from '../../schedule/models/types';

interface ShootingDayScheduleProps {
  projectId: string;
  shootingDayId: string;
  step: Step;
  dueDate?: string;
}

export const ShootingDaySchedule: React.FC<ShootingDayScheduleProps> = observer(
  ({ projectId, shootingDayId, step, dueDate }) => {
    const [state, setState] = useState('loading');
    const [rows, setRows] = useState<any[]>([]);
    const project = Project.getOne(projectId)!;
    const stripBoard = project.stripboard;

    useEffect(() => {
      fetchStripBoard(projectId).then(() => {
        setState('ready');
      });
    }, [projectId]);

    useEffect(() => {
      if (state !== 'ready' || !stripBoard?.strips) return;

      let startIndex = 0;
      let endIndex = 0;

      /**
       * Find all strips corresponding to this shooting day;
       * We use the for loop to break once we found the corresponding shooting day;
       */
      for (let i = 0; i < stripBoard.strips.length; i++) {
        const strip = stripBoard.strips[i];

        if (
          strip.type === 'dayBreak' &&
          (strip.data as DayBreakStripData)!.stepId === shootingDayId
        ) {
          endIndex = i + 1;
          break;
        }

        if (strip.type === 'dayBreak') {
          startIndex = i + 1;
        }
      }

      const strips = stripBoard.strips
        .slice(startIndex, endIndex)
        .filter((strip) => !strip.data.isHidden);

      setRows(strips);
    }, [stripBoard?.strips, state, shootingDayId, step.info?.generalCallTime]);

    if (state !== 'ready' && !stripBoard) {
      return null;
    }

    return (
      <div>
        <Header>
          <h1>Shooting Schedule</h1>
        </Header>

        {stripBoard ? (
          rows.length > 0 ? (
            <ShootingSchedule
              stripboard={stripBoard}
              strips={rows}
              spaceId={project.spaceId}
              step={step}
              placeholder={
                rows.length === 1 && (
                  <EmptyTablePlaceholder description="There are no shots scheduled today." />
                )
              }
            />
          ) : (
            // TODO: should have a loader instead as empty placeholder is inside ShootingSchedule
            <EmptyTablePlaceholder description="There are no shots scheduled today." />
          )
        ) : null}
      </div>
    );
  },
);
