import React from 'react';

import { ProducerScreen } from '../../../features/collaborators/screens/producer.screen';
import { Project } from '../../../app/entities/project';

import type { Step } from '../../../app/entities/step';

export interface ProducerPageProps {
  project: Project;
  step: Step;
}

export const ProducerPage: React.FC<ProducerPageProps> = ({ project, step }) => (
  <ProducerScreen step={step} project={project} />
);
