import React from 'react';
import { ActionMeta } from 'react-select';
import { string as yupString } from 'yup';

import {
  handleRevertWrappingModalOverflow,
  handleWrappingModalOverflow,
} from '../../../components/select/utils';
import { CreatableSelect } from '../../../components/select';

import type { SelectOption } from '../../../components/select';

type ContactsSelectorProps = {
  recipients: SelectOption[];
  contacts: SelectOption[];
  setRecipients: React.Dispatch<React.SetStateAction<SelectOption[]>>;
  isLoading?: boolean;
  onError?: (errorMessage: any) => void;
};

export const ContactsSelector: React.FC<ContactsSelectorProps> = ({
  contacts,
  recipients,
  setRecipients,
  isLoading,
  onError,
}) => {
  const handleOnChange = (_: any, actionMeta: ActionMeta<SelectOption>) => {
    const { action, option, removedValue } = actionMeta;
    switch (action) {
      case 'select-option':
        if (option) {
          setRecipients((prev) => [...prev, option]);
        }
        break;
      case 'remove-value':
        if (removedValue) {
          setRecipients((prev) => prev.filter((value) => value.value !== removedValue.value));
        }
        break;
      default:
        break;
    }
  };

  const handleOnCreate = async (email: string) => {
    const emailValue = email.trim();
    let schema = yupString().email();
    let isValid = schema.isValidSync(emailValue);

    if (!isValid) {
      onError && onError('Not a valid email');
      return;
    } else {
      onError && onError('');
    }

    setRecipients((prev) => [...prev, { value: emailValue, label: emailValue }]);
  };

  return (
    <CreatableSelect
      isLoading={isLoading}
      value={recipients}
      placeholder="Search existing contacts or invite via email"
      options={contacts}
      onChange={handleOnChange}
      onCreate={handleOnCreate}
      createLabelPrefix="Send to"
      onMenuOpen={handleWrappingModalOverflow}
      onMenuClose={handleRevertWrappingModalOverflow}
      isMulti
    />
  );
};
