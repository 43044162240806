import { CopyIcon } from '@radix-ui/react-icons';
import React, { useState } from 'react';

import { catchError } from '../../../core/catch-error';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { Project } from '../../../app/entities/project';
import { duplicateProject } from '../../../features/projects/store/projects.slice';
import { DuplicateProjectDialog } from '../../../components/dialogs/duplicate-project-dialog';

type ProjectMenuProps = {
  project: Project;
};

export const ProjectMenu: React.FC<ProjectMenuProps> = ({ project }) => {
  const [duplicateModalIsOpen, setDuplicateModalIsOpen] = useState(false);

  const handleProjectDuplicate = async () => {
    try {
      await duplicateProject(project._id);
    } catch (e) {
      catchError(e);
    }
  };

  const items = [
    {
      onSelect: () => setDuplicateModalIsOpen(true),
      title: 'Duplicate',
      icon: <CopyIcon />,
    },
  ];

  return (
    <>
      <DropdownMenu items={items} />

      {duplicateModalIsOpen && (
        <DuplicateProjectDialog
          title={'Duplicate project ?'}
          text={`Are you sure you want to duplicate ${project?.name} ?`}
          onCancel={() => setDuplicateModalIsOpen(false)}
          onSubmit={handleProjectDuplicate}
        />
      )}
    </>
  );
};
