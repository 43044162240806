import React from 'react';
import { useParams } from 'react-router-dom';

import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb';
import { Location } from '../../../app/entities/location';
import { Asset } from '../../../app/entities/asset';

type LocationBreadcrumbType = {
  projectId: string;
  stepId: string;
  locationId: string;
  assetId?: string;
};

export const LocationBreadcrumb: React.FC<LocationBreadcrumbType> = ({
  projectId,
  stepId,
  locationId,
  assetId,
}) => {
  const { spaceId } = useParams() as { spaceId: string };

  const currentLocation = Location.getOne(locationId);

  const currentAsset = Asset.getOne(assetId!);

  const locationBreadcrumb = [
    {
      label: 'Location',
      href: `/space/${spaceId}/projects/${projectId}/steps/${stepId}`,
    },
  ];

  if (currentLocation) {
    locationBreadcrumb.push({
      label: `${currentLocation.name}`,
      href: `/space/${spaceId}/projects/${projectId}/steps/${stepId}/location/${locationId}`,
    });
  }

  if (currentAsset) {
    locationBreadcrumb.push({
      label: `${currentAsset.name}`,
      href: `/space/${spaceId}/projects/${projectId}/steps/${stepId}/location/${locationId}/file/${assetId}`,
    });
  }

  return <Breadcrumb breadcrumbs={locationBreadcrumb} />;
};
