import React, { KeyboardEventHandler, useState } from 'react';
import {
  createFilter,
  type ActionMeta,
  type MultiValue,
  type SingleValue,
  type CSSObjectWithLabel,
} from 'react-select';
import ReactSelectCreatable from 'react-select/creatable';
import styled from 'styled-components';

import { defaultStyles, mergeSelectStyles } from '../../utils';
import { CustomMenuList } from './custom-menu-list';

import type { SelectRefType, SelectProps } from '../../types';
import { type AvatarOption, AvatarSelectOption } from '../avatar-select/avatar-select-option';

type AvatarSelectProps = SelectProps<AvatarOption> & {
  onCreate?: (label: string) => void;
  width?: number;
  setHasOptions?: (hasOptions?: boolean) => void;
};

const filterConfig: Parameters<typeof createFilter>[0] = {
  ignoreCase: true,
  ignoreAccents: true,
  stringify: (option) => `${(option.data as AvatarOption).isNew} ${option.value} `,
  trim: true,
  matchFrom: 'any',
};

const createOption = (label: string) => ({
  avatarSrc: '',
  value: label,
  label: label,
  isNew: true,
});

export const AvatarCreatableSelect = React.forwardRef<
  SelectRefType<AvatarOption>,
  AvatarSelectProps
>(
  (
    {
      options,
      defaultValue = [],
      isClearable = false,
      styles,
      onCreate,
      onChange,
      isMulti,
      isLoading,
      isDisabled,
      label,
      required,
      errorMessage,
      width,
      onInputChange,
      setHasOptions,
      ...props
    },
    ref,
  ) => {
    const currentStyles = mergeSelectStyles(defaultStyles<AvatarOption>(), {
      ...styles,
      control: () => ({
        borderWidth: '0',
        borderStyle: 'none',
        borderColor: 'transparent',
        boxShadow: 'none',
        width: '100%',
      }),
      menuList: () => ({
        borderRadius: '0.4rem',
        background: 'var(--color-grayscale-tuna)',
      }),
      menu: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        borderRadius: '0.4rem',
        background: 'var(--color-grayscale-tuna)',
        boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.20)',
        width: `${width}px`,
      }),
      menuPortal: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        zIndex: 'calc(var(--layer-modal) + 1)',
        pointerEvents: 'auto',
      }),
      option: () => ({
        padding: 0,
      }),
      dropdownIndicator: () => ({
        display: 'none',
      }),
      clearIndicator: () => ({
        display: 'none',
      }),
    });

    const [inputValue, setInputValue] = useState<string>();
    const [selectedOption, setSelectedOption] = useState<
      MultiValue<AvatarOption> | SingleValue<AvatarOption>
    >();

    const handleKeyDown: KeyboardEventHandler = (event) => {
      if (['Enter', 'Tab'].includes(event.key)) {
        handleCreate();
      }
    };

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
      event.preventDefault();

      handleCreate();
    };

    const handleChange = (
      option: MultiValue<AvatarOption> | SingleValue<AvatarOption>,
      actionMeta: ActionMeta<AvatarOption>,
    ) => {
      setSelectedOption(option);
      onChange?.(option, actionMeta);
    };

    const handleCreate = () => {
      if (!inputValue) return;

      onCreate?.(inputValue);

      const newOption = createOption(inputValue);
      setSelectedOption(newOption);
    };

    return (
      <Container>
        {label && (
          <Label>
            {label} {required && <b>*</b>}
          </Label>
        )}

        <ReactSelectCreatable
          ref={ref}
          {...props}
          options={options}
          defaultValue={defaultValue}
          value={selectedOption}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onInputBlur={handleBlur}
          styles={currentStyles}
          isClearable={isClearable}
          isMulti={isMulti}
          isLoading={isLoading}
          isDisabled={isLoading || isDisabled}
          inputValue={inputValue}
          onInputChange={(value, actionMeta) => {
            if (!['input-blur', 'menu-close'].includes(actionMeta.action)) {
              setInputValue?.(value);
              onInputChange?.(value, actionMeta);
            }
          }}
          noOptionsMessage={() => {
            setHasOptions?.(false);
            return null;
          }}
          getNewOptionData={(inputValue, optionLabel) => ({
            avatarSrc: '',
            value: inputValue,
            label: optionLabel,
            isNew: true,
          })}
          filterOption={(option, inputValue) => {
            if (option.data.isNew) return false;

            return createFilter(filterConfig)(option, inputValue);
          }}
          onMenuOpen={() => {
            document.addEventListener(
              'wheel',
              (event) => {
                event.stopPropagation();
              },
              { capture: true },
            );
          }}
          components={{
            MenuList: CustomMenuList,
            Option: AvatarSelectOption,
          }}
          {...(onCreate ? { onCreateOption: handleCreate } : undefined)}
          blurInputOnSelect
          tabSelectsValue
          // @ts-ignore
          setHasOptions={setHasOptions}
        />

        {errorMessage && <Error>{errorMessage}</Error>}
      </Container>
    );
  },
);

AvatarCreatableSelect.displayName = 'AvatarCreatableSelect';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  position: relative;
  flex: 1;
`;

const Label = styled.label`
  font-weight: 400;
  color: var(--color-grayscale-white);
  font-size: 1.4rem;
`;

const Error = styled.span`
  position: absolute;
  right: 0;
  bottom: -1.4rem;
  color: var(--color-error);
  font-family: Inter, sans-serif;
  font-size: 1rem;
  letter-spacing: -0.2px;
  text-align: right;
`;
