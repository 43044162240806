import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { ChevronDownIcon } from '@radix-ui/react-icons';

import { OptionDto } from '../../dto/common/option.dto';

export interface SelectProps {
  label?: string | null;
  value?: string;
  addDataRow?: {
    label: string;
    onClick: () => void;
  };
  placeHolder?: string | null;
  options: OptionDto[];
  onSelect?: (el: any) => void;
  hasError?: boolean;
  disabled?: boolean;
  small?: boolean;
  medium?: boolean;
  takeDefaultFirst?: boolean;
  errorMessage?: string;
  name?: string;
  maxHeight?: number;
  icon?: React.ReactNode;
  className?: string;
}

// TODO: Re-implement later using Radix UI
export const Select = React.forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      label,
      value,
      onSelect,
      options,
      placeHolder,
      name,
      disabled = false,
      takeDefaultFirst = false,
      hasError = false,
      small = false,
      medium = false,
      errorMessage,
      addDataRow,
      maxHeight = 30,
      className = '',
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOpenOnTop, setIsOpenOnTop] = useState<boolean>(false);
    const wrapperRef = useRef(null);
    const optionHeight = 40;

    useEffect(() => {
      if (takeDefaultFirst) {
        const e = {
          target: {
            name: name,
            value: options[0].value,
          },
        };
        if (onSelect) {
          onSelect(e);
        }
      }
    }, [name, onSelect, options, takeDefaultFirst]);

    const handleOpen = (e: any) => {
      e.stopPropagation();

      if (!disabled) {
        const windowHeight = window.innerHeight;
        const dropdownHeight = Math.min(maxHeight, options.length * optionHeight);
        if (wrapperRef.current) {
          // @ts-ignore
          setIsOpenOnTop(
            // @ts-ignore
            wrapperRef.current.getBoundingClientRect().bottom + dropdownHeight >= windowHeight,
          );
        }
        setIsOpen(!isOpen);
      }
    };

    const handleOptionClick = (el: any) => {
      const e = {
        target: {
          name: name,
          value: el.value,
        },
      };

      if (onSelect) {
        onSelect(e);
      }

      setIsOpen(false);
    };

    const useOutsideAlerter = (ref: any) => {
      useEffect(() => {
        function handleClickOutside(event: any) {
          if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
          }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [ref]);
    };

    useOutsideAlerter(wrapperRef);

    return (
      <SelectInputContainer
        ref={wrapperRef}
        className={className}
        data-small={small}
        data-medium={medium}
        data-disabled={disabled}
      >
        {label && <Label style={{ zIndex: -1 }}>{label}</Label>}

        <Input
          ref={ref}
          onClick={handleOpen}
          small={small}
          medium={medium}
          data-disabled={disabled}
          data-has-error={hasError || !!errorMessage}
        >
          <Text
            id="select-text"
            data-has-error={hasError || !!errorMessage}
            data-disabled={disabled}
          >
            {value
              ? options.find((el) => el.value === value)?.label
              : placeHolder
              ? placeHolder
              : options.find((el) => el.value === undefined) === undefined
              ? 'Select value'
              : options.find((el) => el.value === undefined)!.label}
          </Text>

          <ChevronDownIcon color={'var(--select-text-color)'} />

          {isOpen && (
            <Option
              id="option-menu"
              small={small}
              medium={medium}
              openOnTop={isOpenOnTop}
              maxHeight={maxHeight}
            >
              <ul>
                {options!.map((el, key) => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <li onClick={() => handleOptionClick(el)} key={key}>
                    {el.label}
                  </li>
                ))}
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                {addDataRow && <li onClick={addDataRow?.onClick}>{addDataRow.label}</li>}
              </ul>
            </Option>
          )}
        </Input>

        {errorMessage && <Error>{errorMessage}</Error>}
      </SelectInputContainer>
    );
  },
);

Select.displayName = 'Select';

const SelectInputContainer = styled.div<InputProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 15rem;
  z-index: 99;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  svg {
    fill: var(--white-default);
    width: 1.6rem;
    position: absolute;
    right: 1.6rem;
  }

  &[data-small='true'] {
    svg {
      right: 0.8rem;
    }
  }

  &[data-medium='true'] {
    height: 3.2rem;

    svg {
      right: 0.8rem;
    }
  }

  &[data-disabled='true'] {
    &:hover {
      color: var(--deprecated-grey-dark);
      cursor: no-drop;
    }
  }
`;

interface InputProps {
  small?: boolean;
  medium?: boolean;
}

interface TextProps {
  hasError?: boolean;
  dark?: boolean;
  small?: boolean;
  disabled?: boolean;
}

interface OptionProps {
  hasError?: boolean;
  dark?: boolean;
  small?: boolean;
  medium?: boolean;
  disabled?: boolean;
  openOnTop?: boolean;
  maxHeight?: number;
}

const Label = styled.div`
  font-family: Inter, sans-serif;
  font-weight: 600;
  color: var(--text-color-white);
  font-size: 1.4rem;
  letter-spacing: 0.7px;
  line-height: 1.1rem;
  display: inline;
  margin-bottom: 0.8rem;
`;

const Input = styled.div<InputProps>`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 3.2rem;
  border-radius: 0.8rem;
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  line-height: 1.6rem;
  display: flex;
  z-index: 1;
  align-items: center;
  border: 1px solid var(--select-border-color);
  color: var(--select-text-color);
  background-color: var(--deprecated-grey-background);
  border: none;
  height: 40px;

  ${({ small, medium }) =>
    (small || medium) &&
    css`
      border-radius: 0.4rem;
    `};

  &:focus {
    outline: none;
    border: 1px solid var(--deprecated-grey-lightest);
    color: var(--white-default);
  }

  &[data-disabled='true'] {
    color: var(--deprecated-grey-dark);
    cursor: no-drop;

    svg {
      fill: var(--color-text-grey-dark);
      stroke: var(--color-text-grey-dark);
    }
  }

  &[data-has-error='true'] {
    &:focus {
      border: 1px solid var(--color-error);
      color: var(--color-error);
    }
  }
`;

const Text = styled.div<TextProps>`
  position: relative;
  padding-left: 1.6rem;
  padding-right: 2.8rem;
  color: var(--text-color-white);
  font-size: 1.4rem;
  letter-spacing: 0.3px;
  line-height: 1.6rem;
  min-width: 12rem;

  &[data-has-error='true'] {
    color: var(--color-error);
  }

  &[data-disabled='true'] {
    color: var(--color-text-grey-dark);
    cursor: no-drop;
  }
`;

const Option = styled.div<OptionProps>`
  position: absolute;
  top: ${({ small, medium }) => (medium ? '5.0rem' : small ? '4.2rem;' : '5.2rem;')};
  width: 100%;
  right: 0;
  font-family: Inter, sans-serif;
  display: flex;
  align-items: center;
  color: var(--text-color-white);
  background-color: var(--card-bg-color);
  transform: translateY(-2rem);

  ul {
    overflow-y: auto;
    max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}rem` : '30rem')};
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding-left: 0.8rem;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 1.2rem;
    letter-spacing: 0.3px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    margin-top: 0;
    -webkit-box-orient: vertical;
  }

  li:hover {
    cursor: pointer;
    background-color: var(--color-secondary);
  }
`;

const Error = styled.span`
  position: relative;
  right: 0;
  height: 1.6rem;
  color: var(--color-error);
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  letter-spacing: -0.2px;
  line-height: 1.6rem;
  text-align: right;
  z-index: -2;

  svg {
    fill: var(--white-default);
    width: 1.6rem;
    position: absolute;
    right: 1.6rem;
    top: 0.8rem;
    bottom: 0.8rem;
  }
`;
