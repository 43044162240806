import { OptionDto } from '../../dto/common/option.dto';
import { SORTING_TYPES } from '../../assets/enums/assetType.enum';
import { Asset } from '../../app/entities/asset';
import { addLeadingCharacter } from '../time-formatters';

export const secondsToHms = (d = 0) => {
  const h = Math.floor(d / 3600);
  let m: any = Math.floor((d % 3600) / 60);
  let s: any = Math.floor((d % 3600) % 60);

  if (m < 10) {
    m = `0${m}`;
  }

  if (s < 10) {
    s = `0${s}`;
  }

  return h > 0 ? `${h}:${m}:${s}` : `${m}:${s}`;
};

export const stdTimezoneOffset = (d: Date) => {
  const jan = new Date(d.getFullYear(), 0, 1);
  const jul = new Date(d.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

export const dst = (d: Date) => {
  return d.getTimezoneOffset() < stdTimezoneOffset(d);
};

export const enumToSelectOptions = (enumObject: any, t?: any): OptionDto[] => {
  // @ts-ignore
  return Object.keys(enumObject).map((el) => ({
    value: enumObject[el],
    label: t && `${t(enumObject[el])}`,
    // Property is added for old hidden Shot sizes values, kept for backward compatibility
    isHidden: ['XLS', 'LS', 'MLS', 'MS', 'BCU'].includes(enumObject[el]),
  }));
};

export const documentsToSelectOptions = (
  documents: any[] = [],
  labelName = 'name',
  valueName = '_id',
): OptionDto[] => {
  return documents.map((el) => ({
    value: el[valueName],
    label: el[labelName],
  }));
};

const AS_FILES = ['file', 'version_stack'];

export const getAssetsByType = (assets: Asset[]) =>
  assets.reduce(
    (acc: { files: Asset[]; folders: Asset[] }, asset: Asset) => {
      const key = AS_FILES.includes(asset.type) ? 'files' : 'folders';
      acc[key].push(asset);
      return acc;
    },
    { files: [], folders: [] },
  );

export const sortByFiles = (files: Asset[], sortBy: string): Asset[] => {
  if (!files || files.length === 0) {
    return [];
  }

  switch (sortBy) {
    case SORTING_TYPES.UPLOADED_DATE:
      return files.sort((a, b) =>
        new Date(a.createdAt).toISOString().localeCompare(new Date(b.createdAt).toISOString()),
      );
    case SORTING_TYPES.A_TO_Z:
      return files.sort((a, b) => a.name.localeCompare(b.name));
    case SORTING_TYPES.Z_TO_A:
      return files.sort((a, b) => b.name.localeCompare(a.name));
    case SORTING_TYPES.FILTER_TYPE:
      return files.sort((a, b) => {
        if (!a.fileType && !b.fileType) {
          return 0;
        }
        if (!a.fileType) {
          return -1;
        }
        if (!b.fileType) {
          return 1;
        }
        return a.fileType.localeCompare(b.fileType);
      });
    default:
      return files;
  }
};

type TimeTo12HourFormatProps = (args: { hour: number; minute: number }) => {
  hour: number;
  minute: number;
  period: 'am' | 'pm';
};

export const timeTo12HourFormat: TimeTo12HourFormatProps = ({ hour, minute }) => {
  const period = hour >= 12 ? 'pm' : 'am';
  const hours = hour % 12 || 12;

  return { hour: hours, minute, period };
};

type TimeTo24HourFormatProps = (args: { hour: number; minute: number; period?: 'am' | 'pm' }) => {
  hour: number;
  minute: number;
};

export const timeTo24HourFormat: TimeTo24HourFormatProps = ({ hour, minute, period }) => {
  if (hour === 12) {
    hour = 0;
  }

  if (period === 'pm') {
    hour += 12;
  }

  return { hour, minute };
};

export const minutesTo12HourFormat = (
  minute: number,
): {
  hour: number;
  minute: number;
  period: 'am' | 'pm';
} => {
  minute = minute % (24 * 60);

  const period = minute < 720 ? 'am' : 'pm';

  if (period === 'pm') {
    minute -= 60 * 12;
  }

  return { hour: Math.floor(minute / 60) || 12, minute: minute % 60, period };
};

export const timeToMinutes = (hour: number, minute: number, period?: 'am' | 'pm') => {
  if (period === 'pm') {
    hour += 12;
  }

  return hour * 60 + minute;
};

// TODO: Add tests
export const getRenderedTime = (time?: { hour: number; minute: number; period?: 'am' | 'pm' }) => {
  if (!time) {
    return '';
  }

  let finalTime = time;
  let renderedTime = '';

  if (finalTime && !finalTime?.period) {
    finalTime = timeTo12HourFormat(finalTime);
  }

  const { hour, minute, period } = finalTime;

  renderedTime = `${hour}:${addLeadingCharacter(minute.toString(), 2)}${period}`;

  return renderedTime;
};
