import type { GroupBase, StylesConfig } from 'react-select';
import type { SelectOption } from '../types';

export const defaultStyles = <Option = SelectOption>(): StylesConfig<
  Option,
  boolean,
  GroupBase<Option>
> => ({
  singleValue: (baseStyles) => ({
    ...baseStyles,
    borderRadius: '0.4rem',
    fontSize: '1.4rem',
    lineHeight: 'normal',
    color: 'var(--color-grayscale-white)',
    margin: 0,
    fontWeight: 400,
  }),
  multiValue: (baseStyles) => {
    return {
      ...baseStyles,
      backgroundColor: 'var(--color-grey-7)',
      color: '#fff',
      opacity: '70%',
      borderRadius: '0.4rem',
      margin: 0,
    };
  },
  multiValueLabel: (baseStyles) => ({
    ...baseStyles,
    color: '#fff',
    fontSize: '1.4rem',
    fontWeight: '400',
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    color: '#fff',
    borderColor: 'var(--color-grey-7)',
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    background: 'transparent',
    color: '#fff',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: state.isFocused ? 'var(--color-secondary)' : 'var(--color-grayscale-trout)',
    padding: 'none',
    minHeight: 0,
    boxSizing: 'border-box',
    paddingInlineStart: '0.4rem',
  }),
  indicatorSeparator: (baseStyles, state) => {
    return {
      ...baseStyles,
      backgroundColor: 'transparent',
    };
  },
  valueContainer: (baseStyles, state) => {
    return {
      ...baseStyles,
      gap: '0.8rem',
      padding: '0.8rem',
    };
  },
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontSize: '1.4rem',
    fontWeight: '400',
    color: 'var(--color-grayscale-shuttle)',
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    color: '#fff',
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
    fontWeight: '400',
    padding: 0,
    margin: 0,
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: 'var(--color-grayscale-charleston)',
    boxShadow: 'none',
    padding: '0.4rem',
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    padding: '0',
    backgroundColor: 'var(--color-grayscale-charleston)',
    borderRadius: 'var(--border-radius-small)',
    boxShadow: 'none',
    fontSize: '1.4rem',
    fontWeight: '400',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    cursor: 'pointer',
    color: 'var(--color-grayscale-white)',
    backgroundColor:
      state.isSelected || (state.isFocused && !state.isDisabled)
        ? 'var(--color-grayscale-arsenic)'
        : 'transparent',
    ':active': {
      backgroundColor:
        state.isSelected || (state.isFocused && !state.isDisabled)
          ? 'var(--color-grayscale-arsenic)'
          : 'transparent',
    },
    fontSize: '1.4rem',
    fontWeight: '400',
    lineHeight: 'normal',
    borderRadius: 'var(--border-radius-small)',
  }),
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    color: 'var(--color-grayscale-shuttle)',
    svg: {
      width: '1.6rem',
      height: '1.6rem',
    },
    paddingInlineEnd: '1.2rem',
  }),
});

export const mergeSelectStyles = (baseStyle: any, style2: any) => {
  const mergedObj = { ...baseStyle };

  for (const key in style2) {
    if (Object.prototype.hasOwnProperty.call(baseStyle, key)) {
      mergedObj[key] = (...args: any[]) => {
        const result1 = baseStyle[key](...args);
        const result2 = style2[key]!(...args);
        return { ...result1, ...result2 };
      };
    } else {
      mergedObj[key] = style2[key];
    }
  }

  return mergedObj;
};

export const handleWrappingModalOverflow = () => {
  const documentItem = document.getElementById('dialog-item');

  documentItem?.style.setProperty('overflow', 'visible');
};

export const handleRevertWrappingModalOverflow = () => {
  const documentItem = document.getElementById('dialog-item');

  documentItem?.style.setProperty('overflow', 'hidden');
};
