import React, { useEffect } from 'react';
import styled from 'styled-components';
import { keycloak } from '../../../keycloak';

import { uiStore } from '../../../core/stores/ui-store';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuItem } from '../../../app/components/menu-item';
import { OutlinedButton } from '../../../components/buttons';

const mobileMenu = [
  {
    key: 'profile',
    label: 'Profile',
    href: `./profile`,
  },
  {
    key: 'security',
    label: 'Password and authentication',
    href: `./security`,
  },
  {
    key: 'notifications',
    label: 'Notifications',
    href: `./notifications`,
  },
];

export const MobileUserSettingsNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.split('/');

  useEffect(() => {
    uiStore.pageTitle = {
      label: 'Personal settings',
    };

    uiStore.showBack = true;
  }, []);

  const logoutUrl = keycloak.createLogoutUrl({ redirectUri: window.location.origin });

  const goTo = (href: string, title: string) => {
    uiStore.pageTitle = {
      label: title,
    };
    navigate(href, { state: location.state });
  };

  const handleLogout = () => {
    const link = document.createElement('a');
    link.href = logoutUrl;
    link.click();
  };

  return (
    <Container>
      <Menu>
        {mobileMenu.map(({ key, label, href }) => (
          <MenuItem
            key={key}
            id={key}
            onClick={() => goTo(href, label)}
            name={label}
            current={(pathname.length === 4 && key === 'profile') || pathname.includes(key)}
          />
        ))}
      </Menu>
      <LogoutButton text="Logout" type="button" onClick={handleLogout} />
    </Container>
  );
};

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1.6rem;
`;

const Menu = styled.div`
  flex: 1;
  width: 100%;
`;

const LogoutButton = styled(OutlinedButton)`
  border-color: #d53075;
  color: #d53075;
  margin: 0 1.6rem;

  &:hover {
    background: #e64186;
  }
`;
