import { KeyboardKeys } from 'assets/enums/keyboard-keys.enum';
import React, { ChangeEvent, MutableRefObject, Ref } from 'react';
import styled, { css } from 'styled-components';

interface InlineTextAreaProps {
  value?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  errorMessage?: string | undefined | any;
  placeholder?: string | null;
  name?: string;
  defaultValue?: string;
  rows?: number;
  cols?: number;
  className?: string;
}

export const InlineTextArea: React.FC<InlineTextAreaProps> = React.forwardRef<
  HTMLTextAreaElement,
  InlineTextAreaProps
>(
  (
    {
      value,
      onChange,
      onSubmit,
      errorMessage,
      placeholder,
      name,
      rows = 4,
      cols = 50,
      defaultValue,
      className,
    },
    ref,
  ) => {
    const handleOnKeyDown = async (e: any) => {
      if (e.key === 'Enter') {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          document.body.tabIndex = 0;
          onSubmit && (await onSubmit(e));
          document.body.focus();
        }
      }
    };

    return (
      <Container>
        <Input
          ref={ref}
          value={value}
          onChange={onChange}
          data-error={!!errorMessage}
          name={name}
          placeholder={placeholder || ''}
          onKeyDown={handleOnKeyDown}
          onBlur={onSubmit}
          rows={rows}
          cols={cols}
          defaultValue={defaultValue}
          className={className}
          onClick={(e) => e.stopPropagation()}
        />

        {errorMessage && <Error>{errorMessage}</Error>}
      </Container>
    );
  },
);

InlineTextArea.displayName = 'InlineTextArea';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Input = styled.textarea`
  position: relative;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
  font-size: 1.6rem;
  letter-spacing: 0.3px;
  line-height: 2rem;
  resize: none;
  padding: 0.6rem 0.8rem;
  background-color: transparent;
  color: var(--white-default);
  border-radius: var(--border-radius-medium);
  border: 0.1rem solid transparent;

  &:focus {
    background-color: transparent !important;
    border-color: var(--color-secondary);
  }

  &:hover {
    background-color: #ffffff11;
    cursor: text;
  }

  &[data-error='true'] {
    &:focus {
      border-color: var(--color-error);
    }
  }
`;

const Error = styled.span`
  position: absolute;
  right: 0;
  bottom: -2.4rem;
  height: 1.6rem;
  color: var(--color-error);
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  letter-spacing: -0.2px;
  line-height: 1.6rem;
  text-align: right;
`;
