import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  DownloadIcon,
  CardStackPlusIcon,
  TrashIcon,
  CardStackIcon,
  ViewVerticalIcon,
  Share2Icon,
} from '@radix-ui/react-icons';
import styled from 'styled-components';

import { Comments } from '../../../components/comment/Comments';
import { AssetPlayer } from './AssetPlayer';
import { VersionSelector } from './version-selector';
import { VersionManager } from './version-manager';
import { LoaderContainer } from '../../../routes/project/components/step-container';
import { breakPoint } from '../../../app/theme';
import { uiStore } from '../../../core/stores/ui-store';
import { selectFiles } from '../../../core/services/file.service';
import { DeleteAssetDialog } from '../../../components/video-asset/delete-asset.dialog';
import { DownloadOverlay } from './download-overlay';
import { download } from '../../../lib/utils/download';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { catchError } from '../../../core/catch-error';
import { AssetTags } from './asset-tags';
import { AssetViewerContextDrawer } from './asset-viewer-context-drawer';
import { useResponsive } from '../../../hooks/useResponsive';
import { ShareModal } from '../../share/components/share-modal';

import type { Asset } from '../../../app/entities/asset';
import type { Comment } from '../../../app/entities/comment';

type AssetViewerProps = {
  asset: Asset;
  displayedAsset: Asset;
  setDisplayedAsset: React.Dispatch<React.SetStateAction<Asset>>;
};

export const AssetViewer: React.FC<AssetViewerProps> = observer(
  ({ asset: baseAsset, displayedAsset, setDisplayedAsset }) => {
    const { isDesktop } = useResponsive();
    const navigate = useNavigate();

    const { spaceId, projectId, stepId } = useParams();

    const playerRef = useRef<HTMLVideoElement | null>(null);
    const timeTagRef = useRef<HTMLSpanElement>(null);

    const [pdfCurrentPage, setPdfCurrentPage] = useState<number>(0);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [isVersionManagerOpen, setIsVersionManagerOpen] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);

    const handleDownload = async () => {
      if (!displayedAsset.uploaded) return;
      await download(displayedAsset._id);
    };

    const handleNewVersion = async () => {
      const files = await selectFiles({
        accept: baseAsset.fileType,
      });

      if (!files) {
        return;
      }

      const file = files[0];

      /**
       * User clicked on cancel, do not reset the current file
       */
      if (!file) {
        return;
      }

      try {
        const version = await baseAsset.addVersion(file);
        if (!version) return;

        setDisplayedAsset(version);

        if (baseAsset.type === 'file') {
          navigate(`../file/${version.parentId}`);
        }
      } catch (e) {
        catchError(e);
      }
    };

    const handleCompareVersions = async () => {
      if (baseAsset.children.length < 2) return;

      let otherAsset: Asset | undefined;
      if (baseAsset.children.length === 2) {
        otherAsset = baseAsset.children.filter((child) => child._id !== displayedAsset._id)[0];
      }

      const v2Query = otherAsset?._id ? `&v2=${otherAsset?._id}` : '';
      const href = `/space/${spaceId}/projects/${projectId}/steps/${stepId}/assets/${baseAsset._id}/compare-versions?v1=${displayedAsset?._id}${v2Query}`;
      navigate(href);
    };

    const menuItems = [
      {
        title: baseAsset.type === 'version_stack' ? 'Download current version' : 'Download',
        icon: <DownloadIcon />,
        onSelect: handleDownload,
        disabled: !displayedAsset.uploaded,
        tooltipText: 'File is still uploading.',
      },

      {
        title: 'Add new version',
        icon: <CardStackPlusIcon />,
        disabled: !baseAsset.uploaded,
        onSelect: handleNewVersion,
      },

      {
        title: 'Version manager',
        icon: <CardStackIcon />,
        disabled: baseAsset.type !== 'version_stack',
        onSelect: () => {
          setIsVersionManagerOpen(true);
        },
      },
      {
        title: 'Compare Versions',
        icon: <ViewVerticalIcon />,
        disabled: baseAsset.type !== 'version_stack',
        hidden: !isDesktop || !baseAsset.fileType?.startsWith('video'),
        onSelect: handleCompareVersions,
      },
      {
        title: 'Share',
        icon: <Share2Icon />,
        hidden: !baseAsset?.project?.spaceId,
        onSelect: () => {
          setIsShareModalOpen(true);
        },
      },
      {
        title:
          baseAsset.children.length > 1
            ? `Delete (${baseAsset.children.length} versions)`
            : 'Delete',
        icon: <TrashIcon />,
        type: 'danger' as 'danger',
        onSelect: () => setDeleteModalIsOpen(true),
      },
    ];

    const handleDeleteAsset = async () => {
      await baseAsset.delete();
      navigate('..');
    };

    const handleSelectVersion = (version: Asset) => {
      setDisplayedAsset(version);
    };

    const handleSelectComment = async (comment: Pick<Comment, 'timestamp' | 'page'>) => {
      if (comment.timestamp && playerRef?.current) {
        playerRef.current.currentTime = comment.timestamp;
      }

      if (comment.page || comment.page === 0) {
        setPdfCurrentPage(comment.page);
      }
    };

    return (
      <Container>
        <AssetViewerContextDrawer
          asset={baseAsset}
          selectedAsset={displayedAsset}
          actions={menuItems}
          onAddTag={(values) => displayedAsset.addTag(values)}
          onRemoveTag={(tag) => displayedAsset.removeTag(tag)}
          onVersionSelect={handleSelectVersion}
        />

        <LeftContainer>
          {displayedAsset.upload ? (
            <LoaderContainer>
              <DownloadOverlay
                progress={displayedAsset.upload.progress}
                status={displayedAsset.upload.status}
                onPause={() => baseAsset.upload!.pause()}
                onResume={() => baseAsset.upload!.resume()}
              />
            </LoaderContainer>
          ) : (
            <AssetPlayer
              timeTagRef={timeTagRef}
              playerRef={playerRef}
              asset={displayedAsset}
              pdfCurrentPage={pdfCurrentPage}
              setPdfCurrentPage={setPdfCurrentPage}
            />
          )}

          <LeftFooter>
            <InfoContainer>
              <Info>
                <AssetName>{displayedAsset.name}</AssetName>

                {(baseAsset.type === 'version_stack' || displayedAsset?.version! > 0) && (
                  <VersionSelector
                    assetChildren={baseAsset.sortedChildren}
                    version={
                      (displayedAsset ? displayedAsset.version : baseAsset.currentVersionNumber) ||
                      0
                    }
                    selectedAssetId={displayedAsset._id}
                    onSelect={handleSelectVersion}
                  />
                )}

                <DropdownMenu items={menuItems} />
              </Info>

              <RelativeTime>
                Uploaded&nbsp;
                {displayedAsset.uploaderName && (
                  <>
                    by&nbsp;<span>{displayedAsset.uploaderName}&nbsp;</span>
                  </>
                )}
                {displayedAsset.relativeUploadTime}
              </RelativeTime>
            </InfoContainer>

            <AssetTags
              key={`main-view-tag-${displayedAsset._id}`}
              tags={displayedAsset.tags}
              onAddTag={(values) => displayedAsset.addTag(values)}
              onRemoveTag={(tag) => displayedAsset.removeTag(tag)}
              overflow={isDesktop ? 'wrap' : 'collapse'}
              alignment={isDesktop ? 'end' : 'start'}
              {...(!isDesktop && {
                onMoreClick: () => {
                  uiStore.contextDrawer!.toggle();
                },
              })}
            />
          </LeftFooter>
        </LeftContainer>

        <CommentDrawer>
          <Comments
            playerRef={playerRef}
            timeTagRef={timeTagRef}
            asset={displayedAsset}
            pdfCurrentPage={pdfCurrentPage}
            onSelectComment={handleSelectComment}
          />
        </CommentDrawer>

        {isVersionManagerOpen && (
          <VersionManager
            asset={baseAsset}
            selectedVersion={displayedAsset?._id || ''}
            onClose={() => {
              setIsVersionManagerOpen(false);
            }}
          />
        )}

        {deleteModalIsOpen && (
          <DeleteAssetDialog
            name={baseAsset.name}
            versionCount={baseAsset.children.length}
            onCancel={() => setDeleteModalIsOpen(false)}
            onSubmit={handleDeleteAsset}
          />
        )}

        {isShareModalOpen && (
          <ShareModal
            title={`Share ${baseAsset.name}`}
            elementIds={[baseAsset._id]}
            spaceId={baseAsset.project!.spaceId}
            projectId={baseAsset.projectId}
            onClose={() => setIsShareModalOpen(false)}
          />
        )}
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  overflow-y: auto;
  gap: 0.8rem;
  height: 100%;

  @media screen and (min-width: ${breakPoint.medium}px) {
    flex-direction: row;
    gap: 1.6rem;
    overflow: hidden;
  }
`;

const LeftContainer = styled.div`
  flex: 1 1 60%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  min-width: 0;
  max-height: 60%;

  @media screen and (min-width: ${breakPoint.medium}px) {
    flex: 1 2 75%;
    max-height: unset;
    & > div {
      min-height: 0;
    }
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;

  @media screen and (max-width: ${breakPoint.medium - 1}px) {
    display: none;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 0.8rem;
`;

const LeftFooter = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0.8rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    flex-direction: column;
    gap: 1.6rem;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0;
  }

  @media screen and (min-width: ${breakPoint.large}px) {
    flex-direction: row;
    gap: 3.2rem;
  }
`;

const AssetName = styled.div`
  color: var(--color-grayscale-white);
  font-family: Inter, sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2.4rem;
`;

const RelativeTime = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-grayscale-light-slate);

  & > span {
    color: var(--color-grayscale-white);
  }

  @media screen and (max-width: ${breakPoint.medium - 1}px) {
    display: none;
  }
`;

const CommentDrawer = styled.div`
  min-height: 0;
  height: 100%;
  min-width: 0;
  width: 100%;
  scroll-behavior: smooth;

  @media screen and (min-width: ${breakPoint.medium}px) {
    border-radius: 0.4rem;
    width: 40rem;
    min-width: 40rem;
    max-width: 40rem;
  }
`;
