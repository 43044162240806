import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface CardHeaderProps {
  actions: ReactNode[];
  isMenuOpen?: boolean;
}

export const CardHeader: React.FC<CardHeaderProps> = ({ actions, isMenuOpen }) => (
  <Actions id="action" style={isMenuOpen ? { visibility: 'visible' } : {}}>
    <ActionsContainer>{actions}</ActionsContainer>
  </Actions>
);

const Actions = styled.div`
  height: 40px;
  width: 100%;
  border: 1px solid var(--deprecated-grey-dark);
  opacity: 0.9;
  background: linear-gradient(51.43deg, #212626 0%, #171f1f 100%);
  position: absolute;
  z-index: 99;
  visibility: hidden;
  cursor: initial;
  top: 0;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: start;
  height: 2.4rem;
  position: absolute;
  top: 1.2rem;
  right: 0.8rem;
  gap: 0.8rem;
`;
