export enum ShotSizeEnum {
  // Old hidden values, kept for backward compatibility
  XLS = 'XLS',
  LS = 'LS',
  MLS = 'MLS',
  MS = 'MS',
  BCU = 'BCU',

  // Recent reviewed Values
  EST = 'EST',
  MA = 'MA',
  XW = 'XW',
  WID = 'WID',
  FUL = 'FUL',
  MED = 'MED',
  MCU = 'MCU',
  CU = 'CU',
  ECU = 'ECU',
  MAC = 'MAC',
}
