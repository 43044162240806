import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { object as yupObject, string as yupString } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';

import { Space } from '../../../../app/entities/space';
import { BaseDialog, DialogFooter, DialogHeader } from '../../../../components/dialog';
import { ElevatedButton, OutlinedButton } from '../../../../components/buttons';
import { TextInput } from '../../../../components/text-input/TextInput';
import { ReactSelect } from '../../../../components/select';
import {
  countries_no_requirement,
  countries_require_region,
  countries_require_zip_code,
} from '../../../settings/utils/paddle-country-codes';
import {
  handleWrappingModalOverflow,
  handleRevertWrappingModalOverflow,
} from '../../../../components/select/utils';

import type { SelectOption } from '../../../../components/select/types';

type UpdateBillingAddressModalProps = {
  onCancel: () => void;
  space: Space;
};

type FormValues = {
  address: string;
  city: string;
  postalCode: string;
  countryCode: string;
  region?: string;
};

const schema = yupObject().shape({
  address: yupString()
    .matches(/^(.*)?\S+(.*)?$/, 'Address is required')
    .required('Address is required'),
  city: yupString()
    .matches(/^(.*)?\S+(.*)?$/, 'City is required')
    .required('City is required'),
  postalCode: yupString()
    .matches(/^(.*)?\S+(.*)?$/, 'Postal code is required')
    .required('Postal code is required'),
  countryCode: yupString()
    .matches(/^(.*)?\S+(.*)?$/, 'Country is required')
    .required('Country is required'),
  region: yupString(),
});

const countries = {
  ...countries_no_requirement,
  ...countries_require_zip_code,
  ...countries_require_region,
};

const countriesList = Object.keys(countries)
  .sort()
  .map((key) => ({
    value: countries[key as keyof typeof countries],
    label: key,
  }));

export const UpdateBillingAddressModal: React.FC<UpdateBillingAddressModalProps> = ({
  onCancel,
  space,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { address, city, postalCode, countryCode, region } = space.billingDetails?.address || {};
  const { handleSubmit, control } = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      address: address || '',
      city: city || '',
      postalCode: postalCode || '',
      countryCode: countryCode || '',
      region: region || '',
    },
  });

  const handleOnSubmit = async (values: FormValues) => {
    setIsLoading(true);
    if (!values.region) {
      delete values.region;
    }

    const isUpdated = await space.updateBillingAddress(values);

    setIsLoading(false);

    if (!isUpdated) {
      return;
    }

    onCancel();
  };

  const getCountryValue = (countryCode: string) => {
    return countriesList.find((country) => country.value === countryCode);
  };
  return (
    <>
      <BaseDialog onClose={onCancel}>
        <DialogHeader title="Billing address" />

        <Container>
          <Form id="billing-address-form" onSubmit={handleSubmit(handleOnSubmit)}>
            <Controller
              name="address"
              control={control}
              render={({ field: { ref, onChange, value }, fieldState: { error } }) => (
                <TextInput
                  ref={ref}
                  value={value}
                  onChange={onChange}
                  placeholder="Address"
                  label="Address"
                  errorMessage={error?.message}
                  required
                  autoFocus
                />
              )}
            />

            <Controller
              name="city"
              control={control}
              render={({ field: { ref, onChange, value }, fieldState: { error } }) => (
                <TextInput
                  ref={ref}
                  value={value}
                  onChange={onChange}
                  placeholder="City"
                  label="City"
                  errorMessage={error?.message}
                  required
                />
              )}
            />

            <Controller
              name="postalCode"
              control={control}
              render={({ field: { ref, onChange, value }, fieldState: { error } }) => (
                <TextInput
                  ref={ref}
                  value={value}
                  onChange={onChange}
                  placeholder="Postal Code"
                  label="Postal Code"
                  errorMessage={error?.message}
                  required
                />
              )}
            />

            <Controller
              name="countryCode"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <ReactSelect
                  label="Country"
                  onChange={(selectedOption) => onChange((selectedOption as SelectOption)!.value)}
                  value={getCountryValue(value)}
                  options={countriesList}
                  onMenuOpen={handleWrappingModalOverflow}
                  onMenuClose={handleRevertWrappingModalOverflow}
                  errorMessage={error?.message}
                  isSearchable
                />
              )}
            />

            <Controller
              name="region"
              control={control}
              render={({ field: { ref, onChange, value }, fieldState: { error } }) => (
                <TextInput
                  ref={ref}
                  value={value}
                  onChange={onChange}
                  placeholder="State/Region"
                  label="State/Region"
                  errorMessage={error?.message}
                />
              )}
            />
          </Form>
        </Container>

        <DialogFooter
          actions={[
            <OutlinedButton key="cancel" text="Cancel" onClick={onCancel} />,
            <ElevatedButton
              type="submit"
              text="Save address"
              key="save"
              form="billing-address-form"
              isLoading={isLoading}
              disabled={isLoading}
            />,
          ]}
        />
      </BaseDialog>
    </>
  );
};

const Container = styled.div`
  padding: 1.6rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;
