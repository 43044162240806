import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as Progress from '@radix-ui/react-progress';

interface ProgressIndicatorProps {
  max: number;
  value: number;
  action?: React.ReactNode;
  label?: string;
}

export const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  max: num,
  value: curr,
  action,
  label,
}) => {
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    const currentProgress = (curr / num) * 100;
    setProgress(currentProgress);
  }, [curr, num]);

  return (
    <Container>
      <Header>
        <Label>{label}</Label>
        {action}
      </Header>
      <Root value={progress}>
        <Indicator style={{ transform: `translateX(-${100 - progress}%)` }} />
      </Root>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--color-grayscale-trout);
`;

const Root = styled(Progress.Root)`
  position: relative;
  overflow: hidden;
  background: var(--color-grayscale-tuna);
  border-radius: 0.8rem;
  width: 100%;
  height: 0.4rem;

  /* Fix overflow clipping in Safari */
  /* https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
  transform: translateZ(0);
`;

const Indicator = styled(Progress.Indicator)`
  background-color: var(--color-primary-telemagenta);
  width: 100%;
  height: 100%;
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
`;
