import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Cross2Icon, PlusIcon } from '@radix-ui/react-icons';
import { Tooltip } from '@producer-io/ui-kit';
import styled from 'styled-components';

import { AddTagModal } from '../../videos/add-tag.modal';

import type { Asset } from '../../../app/entities/asset';

type AssetTagsProps = {
  tags: Asset['tags'];
  onAddTag: (tag: Omit<Asset['tags'][number], '_id'>) => void;
  onRemoveTag: (tag: Asset['tags'][number]) => void;
  onMoreClick?: () => void;
  overflow?: 'wrap' | 'collapse';
  alignment?: 'start' | 'end';
};

const CONTAINER_GAP = 0.8;
const MORE_TEXT_WIDTH = 5.5; // 55px based on 3 digits "xxx more" assumption
const MORE_TEXT_TOTAL_WIDTH = MORE_TEXT_WIDTH + CONTAINER_GAP;
const ADD_TAG_WIDTH = 4.8;
const ADD_TAG_TOTAL_WIDTH = ADD_TAG_WIDTH + CONTAINER_GAP; // + CONTAINER_GAP is just extra offset

const TAG_FONT_SIZE = '1.2rem';
const TAG_FONT_WEIGHT = '400';

const TAG_BORDER_WIDTH = 0.1;
const TAG_INNER_HORIZONTAL_PADDING = 0.8;
const TAG_INNER_GAP = 0.4;
const TAG_COLORED_POINT_SIZE = 0.8;
const TAG_REMOVE_BUTTON_WIDTH = 1.5;
const TOTAL_TAG_FIXED_OFFSET =
  2 * (TAG_BORDER_WIDTH + TAG_INNER_HORIZONTAL_PADDING + TAG_INNER_GAP) +
  TAG_COLORED_POINT_SIZE +
  TAG_REMOVE_BUTTON_WIDTH +
  0.1; // 1px extra offset

export const tagTypeColors = {
  error: 'var(--color-primary-telemagenta)',
  success: 'var(--color-primary-puerto-rico)',
  info: 'var(--color-primary-crayola)',
  warning: 'var(--color-secondary-saffron)',
};

export const AssetTags: React.FC<AssetTagsProps> = observer(
  ({ tags, onAddTag, onRemoveTag, onMoreClick, overflow = 'wrap', alignment = 'start' }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const [containerWidth, setContainerWidth] = useState(0);
    const [visibleCount, setVisibleCount] = useState(0);
    const [isAddTagOpen, setIsAddTagOpen] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        if (containerRef.current) {
          setContainerWidth(containerRef.current.clientWidth);
        }
      };

      window.addEventListener('resize', handleResize);
      handleResize();

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useLayoutEffect(() => {
      if (!tags.length) {
        return;
      }

      if (overflow === 'wrap') {
        return setVisibleCount(tags.length);
      }

      const getTagWidth = (tagText: string) => {
        const offscreenTagText = document.createElement('div');
        offscreenTagText.style.position = 'absolute';
        offscreenTagText.style.left = '-9999px'; // Position it off-screen
        offscreenTagText.style.fontSize = TAG_FONT_SIZE;
        offscreenTagText.style.fontWeight = TAG_FONT_WEIGHT;
        offscreenTagText.innerHTML = tagText;
        document.body.appendChild(offscreenTagText);

        const tagWidth = offscreenTagText.offsetWidth + TOTAL_TAG_FIXED_OFFSET * 10;

        document.body.removeChild(offscreenTagText);
        return tagWidth;
      };

      if (containerRef.current && containerWidth > 0) {
        let totalWidth = 10 * (MORE_TEXT_TOTAL_WIDTH + ADD_TAG_TOTAL_WIDTH);
        let count = 0;

        tags?.forEach((tag, index) => {
          const tagWidth = getTagWidth(tag.text);

          totalWidth += tagWidth + CONTAINER_GAP * 10; //8px is the gap between tags

          if (totalWidth <= containerWidth) {
            count = index + 1;
          }
        });

        if (count > 0) {
          setVisibleCount(count);
        } else {
          setVisibleCount(0);
        }
      }
    }, [containerWidth, overflow, tags, tags.length]);

    const handleAddTag = async (values: Omit<Asset['tags'][number], '_id'>): Promise<any> => {
      return onAddTag(values);
    };

    const removeTag = async (tag: Asset['tags'][number]): Promise<any> => {
      return onRemoveTag(tag);
    };

    return (
      <Container ref={containerRef} data-align={alignment}>
        <TagsContainer data-wrap={overflow === 'wrap'}>
          {tags.map((tag, index) => {
            const color = tagTypeColors[(tag.type || 'info') as keyof typeof tagTypeColors];

            return (
              <Tag key={tag._id} data-visible={index < visibleCount} color={color}>
                <Point color={color} />

                {tag.text}

                <Tooltip text="Remove tag">
                  <RemoveTag
                    onClick={(e) => {
                      e.stopPropagation();
                      removeTag(tag);
                    }}
                  >
                    <Cross2Icon />
                  </RemoveTag>
                </Tooltip>
              </Tag>
            );
          })}

          {!!tags.length && (
            <MoreText
              id="more-text-wrapper"
              data-visible={tags.length > visibleCount}
              onClick={(e) => {
                e.stopPropagation();
                onMoreClick && onMoreClick();
              }}
            >
              {tags.length - visibleCount} {visibleCount > 0 ? 'more' : 'tags'}
            </MoreText>
          )}

          <Tooltip text="Add tag">
            <Tag
              onClick={(e) => {
                e.stopPropagation();
                setIsAddTagOpen(true);
              }}
              color="var(--color-grayscale-trout)"
              data-visible="true"
            >
              <PlusIcon />
            </Tag>
          </Tooltip>
        </TagsContainer>

        {isAddTagOpen && (
          <div onClick={(e) => e.stopPropagation()}>
            <AddTagModal onCancel={() => setIsAddTagOpen(false)} onSubmit={handleAddTag} />
          </div>
        )}
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;

  &[data-align='end'] {
    justify-content: flex-end;
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${CONTAINER_GAP}rem;
  min-width: 0;
  overflow: hidden;

  &[data-wrap='true'] {
    flex-wrap: wrap;
  }
`;

type TagProps = {
  color: string;
};

const Tag = styled.div<TagProps>`
  white-space: nowrap;
  padding: 0.4rem ${TAG_INNER_HORIZONTAL_PADDING}rem;
  background-color: transparent;
  border: ${TAG_BORDER_WIDTH}rem solid;
  border-color: ${({ color }) => color || 'var(--color-primary-sandal)'};
  border-radius: 10rem;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${TAG_INNER_GAP}rem;

  font-size: ${TAG_FONT_SIZE};
  font-weight: ${TAG_FONT_WEIGHT};
  color: var(--color-grayscale-white);

  &[data-visible='true'] {
    display: flex;
  }

  &:last-child {
    width: ${ADD_TAG_WIDTH}rem;
    min-width: ${ADD_TAG_WIDTH}rem;
    color: var(--color-grayscale-trout);

    &:hover,
    &:focus {
      cursor: pointer;

      svg {
        color: var(--color-grayscale-white);
      }
    }
  }
`;

const Point = styled.div<TagProps>`
  background-color: ${({ color }) => color || 'var(--color-primary-sandal)'};
  height: ${TAG_COLORED_POINT_SIZE}rem;
  width: ${TAG_COLORED_POINT_SIZE}rem;
  min-width: ${TAG_COLORED_POINT_SIZE}rem;
  border-radius: 50%;
`;

const MoreText = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  user-select: none;

  font-size: ${TAG_FONT_SIZE};
  font-weight: ${TAG_FONT_WEIGHT};
  color: var(--color-grayscale-cadet);
  white-space: nowrap;

  &[data-visible='true'] {
    display: flex;
  }
`;

const RemoveTag = styled.button`
  background-color: transparent;
  padding: 0.4rem;
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 0.4rem;

  box-sizing: border-box;
  transition: background-color 0.2s;

  svg {
    color: var(--color-grayscale-trout);
  }

  &:hover,
  &:focus {
    cursor: pointer;
    background-color: var(--color-grayscale-arsenic);

    svg {
      color: var(--color-grayscale-white);
    }
  }

  &:focus-visible {
    border: 0.15rem solid var(--color-secondary);
  }
`;
