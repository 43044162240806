import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { StyledButton } from './styled-buttons';
import { Loader } from '../loader/Loader';

interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: React.ReactNode;
  isLoading?: boolean;
  variant?: 'default' | 'transparent' | 'primary' | 'success' | 'danger' | 'warning' | 'dark';
  size?: 'small' | 'medium';
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon, onClick, variant = 'default', isLoading, size, ...props }, ref) => (
    <Button
      ref={ref}
      onClick={onClick}
      data-variant={variant}
      data-size={size}
      tabIndex={0}
      type={props.type || 'button'}
      {...props}
    >
      {isLoading ? <Loader small /> : icon}
    </Button>
  ),
);

IconButton.displayName = 'IconButton';

const Button = styled(StyledButton)`
  height: 3.2rem;
  width: 3.2rem;
  font-size: 1.8rem;

  & svg {
    width: 1.6rem;
    height: 1.6rem;
    stroke: var(--color-grey-5);
    fill: var(--color-grey-5);
  }

  &:hover:enabled,
  &:focus:enabled,
  &:active:enabled {
    svg {
      stroke: var(--white-default);
      fill: var(--white-default);
    }
  }

  &[data-size='small'] {
    height: 2.4rem;
    min-width: 2.4rem;
    width: 2.4rem;
    font-size: 1.4rem;

    & svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  &[data-size='medium'] {
    height: 2.8rem;
    min-width: 2.8rem;
    width: 2.8rem;
    font-size: 1.4rem;

    & svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`;

// TODO: Remove this wrapper when reviewing all IconButtons and update style accordingly
export const IconButtonWrapper = styled.div`
  & svg {
    stroke-width: 0;
    color: var(--color-grayscale-manatee);
  }

  & > button:not(:disabled):hover {
    & > svg {
      color: var(--color-grayscale-white);
    }
  }
`;
