import React from 'react';

import { useResponsive } from '../../hooks/useResponsive';
import { MobileSettingsNavigation } from '../../features/settings/components/mobile-settings-navigation';
import { SpaceSettingsRoute } from './space-settings.route';

export const SettingsRoute = () => {
  const { isMobile } = useResponsive();
  return isMobile ? <MobileSettingsNavigation /> : <SpaceSettingsRoute />;
};
