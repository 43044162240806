import React from 'react';

export const Person = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6994_542742)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00279 0C5.73851 0 3.82422 1.73833 3.82422 3.97689C3.82422 5.47893 4.07818 7.08292 4.69712 8.34149C5.32327 9.61471 6.39267 10.6429 8.00279 10.6429C9.57273 10.6429 10.6381 9.60698 11.2708 8.34707C11.9012 7.0917 12.1814 5.48823 12.1814 3.97689C12.1814 1.73833 10.2671 0 8.00279 0ZM2.0701 10.3867C2.55154 9.66469 3.18723 9.09712 3.81007 8.68403C4.6012 10.1777 6.16391 11.7448 8.00042 11.7448C9.87897 11.7448 11.4426 10.5038 12.1996 8.96484C12.245 8.87274 12.2877 8.77773 12.3281 8.68008C13.7803 9.63394 14.5462 11.2551 14.8789 12.4472C15.0422 13.0322 14.8276 13.6533 14.3081 13.9678C13.0315 14.7406 10.7855 15.3867 7.57031 15.3867C3.57031 15.3867 1.50151 14.2495 1.0701 13.3867C0.903438 13.0534 0.96058 12.0507 2.0701 10.3867Z"
          fill="#626878"
        />
      </g>
      <defs>
        <clipPath id="clip0_6994_542742">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
