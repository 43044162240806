import React, { ReactNode } from 'react';
import { CheckIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import * as RadixMenu from '@radix-ui/react-dropdown-menu';
import styled, { css } from 'styled-components';

import { Tooltip } from '../../tooltip/Tooltip';
import { breakPoint } from '../../../app/theme';

export type Item = {
  onSelect: (event: Event) => void;
  title?: ReactNode;
  icon?: ReactNode;
  type?: 'danger' | 'warning';
  disabled?: boolean;
  hidden?: boolean;
  tooltipText?: string;
  subItems?: DropdownMenuProps['items'];
  isChecked?: never;
  onCheckedChange?: never;
  customComponent?: React.ReactElement;
};

export type CheckboxItem = Omit<Item, 'onSelect' | 'isChecked' | 'onCheckedChange'> & {
  isChecked?: boolean;
  onCheckedChange?: (isChecked: boolean) => void;
  onSelect?: never;
};

export type UploaderItem = Omit<Item, 'onSelect' | 'customComponent'> & {
  customComponent?: React.ReactElement;
  onSelect?: never;
};

type DropdownMenuProps = {
  items: Array<Item | CheckboxItem | UploaderItem>;
  align?: 'start' | 'end';
  size?: 'small' | 'medium' | 'large' | 'free-size';
  trigger?: ReactNode;
  itemComponent?: React.ReactElement;
  tooltipText?: string;
  modal?: boolean;
  onOpenChange?: (value: boolean) => void;
  portalContainer?: HTMLDivElement;
  side?: 'bottom' | 'top';
  className?: string;
};

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  items,
  size,
  trigger,
  itemComponent: optionComponent,
  align = 'end',
  modal = false,
  onOpenChange,
  portalContainer,
  side,
  className,
}) => {
  const hasIcon = items.some((item) => !!item.icon || !!item.onCheckedChange);
  const hasIconAndCheck = items.some((item) => !!item.icon && !!item.onCheckedChange);

  const renderItem = (item: Item | CheckboxItem, index: number, hasIcon?: boolean) => {
    if (item.hidden) {
      return;
    }

    if (optionComponent && React.isValidElement(optionComponent)) {
      return React.cloneElement(optionComponent, { ...item });
    }

    if (hasIconAndCheck) {
      return (
        <CheckboxMenuItem
          key={`option-${index}`}
          checked={item.isChecked}
          onCheckedChange={item.onCheckedChange}
        >
          <MenuItemIcon>{item.icon}</MenuItemIcon>

          {item.title}
          <div style={{ flex: 1 }} />
          <MenuItemIcon>
            <RadixMenu.ItemIndicator>
              <CheckIcon />
            </RadixMenu.ItemIndicator>
          </MenuItemIcon>
        </CheckboxMenuItem>
      );
    }

    if (item.onCheckedChange) {
      return (
        <CheckboxMenuItem
          key={`option-${index}`}
          checked={item.isChecked}
          onCheckedChange={item.onCheckedChange}
        >
          <MenuItemIcon>
            <RadixMenu.ItemIndicator>
              <CheckIcon />
            </RadixMenu.ItemIndicator>
          </MenuItemIcon>

          {item.title}
        </CheckboxMenuItem>
      );
    }

    if (item.customComponent) {
      return React.cloneElement(item.customComponent, { ...item });
    }

    return (
      <MenuItem
        key={`option-${index}`}
        disabled={item.disabled}
        onSelect={item.onSelect}
        data-type={item.type}
      >
        {hasIcon && <MenuItemIcon>{item.icon}</MenuItemIcon>}

        <span>{item.title}</span>
      </MenuItem>
    );
  };

  return (
    <RadixMenu.Root modal={modal} onOpenChange={onOpenChange}>
      <RadixMenu.Trigger asChild onClick={(e) => e.stopPropagation()}>
        {trigger || <Trigger data-size={size}>⋮</Trigger>}
      </RadixMenu.Trigger>

      <RadixMenu.Portal {...(portalContainer && { container: portalContainer })}>
        <Content side={side || 'bottom'} asChild align={align} sideOffset={6}>
          <MenuModal
            className={className || ''}
            data-size={size}
            onClick={(e) => e.stopPropagation()}
          >
            {items.map((item, index) => {
              const hasSubIcon = item.subItems?.some(
                (item) => !!item.icon || !!item.onCheckedChange,
              );

              return (
                <Tooltip
                  text={item.tooltipText || 'Action not allowed'}
                  key={`tip-${index}`}
                  disabled={!item.disabled || !item.tooltipText}
                >
                  {item.subItems ? (
                    <RadixMenu.Sub key={`sub-trigger-${index}`}>
                      <SubTrigger>
                        {hasIcon && <MenuItemIcon>{item.icon}</MenuItemIcon>}

                        {item.title}

                        <ItemRightSlot>
                          <ChevronRightIcon />
                        </ItemRightSlot>
                      </SubTrigger>

                      <SubContent sideOffset={4}>
                        {item.subItems.map((subOption, subOptionIndex) =>
                          renderItem(subOption, subOptionIndex, hasSubIcon),
                        )}
                      </SubContent>
                    </RadixMenu.Sub>
                  ) : (
                    renderItem(item, index, hasIcon)
                  )}
                </Tooltip>
              );
            })}
          </MenuModal>
        </Content>
      </RadixMenu.Portal>
    </RadixMenu.Root>
  );
};

const Content = styled(RadixMenu.Content)`
  @media screen and (max-width: ${breakPoint.small - 1}px) {
    max-width: 100vw;
  }
`;

const Trigger = styled.button`
  all: unset;
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2.8rem;
  font-size: 1.8rem;
  border-radius: 0.4rem;
  transition: 0.1s ease-in background-color;
  user-select: none;
  background-color: var(--color-grayscale-tuna);
  color: var(--color-grayscale-manatee);

  &:hover {
    cursor: pointer;
  }

  &[data-size='large'] {
    width: 3.2rem;
    height: 3.2rem;
  }

  &[data-size='free-size'] {
    width: unset;
    height: unset;
  }
`;

const MenuModal = styled.div`
  opacity: 2;
  min-width: 18rem;
  border-radius: 0.6rem;
  padding: 0.4rem;
  z-index: calc(var(--layer-modal) + 1);
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 24px;
  backdrop-filter: blur(10px) saturate(190%) contrast(70%) brightness(80%);
  background-color: rgba(29, 30, 43, 0.498);

  &[data-size='free-size'] {
    min-width: unset;
  }
`;

export const ItemRightSlot = styled.span`
  margin-left: auto;
  padding-left: 2rem;
  color: var(--color-grey-6);
`;

const baseMenuItem = css`
  color: rgb(228, 229, 241);
  position: relative;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-medium);
  padding: 0.8rem;
  font-size: 1.4rem;
  font-weight: 400;
  height: 3.2rem;

  > svg {
    margin-right: 0.8rem;
  }

  &[data-highlighted] {
    cursor: pointer;
    background-color: rgba(124, 124, 163, 0.125);
  }

  &[data-disabled] {
    color: var(--color-grayscale-trout);
    pointer-events: none;

    ${ItemRightSlot} {
      color: var(--color-grayscale-trout);
    }
  }

  &[data-type='danger']:not([data-disabled]) {
    color: var(--color-error);
  }
`;

export const MenuItem = styled(RadixMenu.Item)`
  ${baseMenuItem}
`;

const CheckboxMenuItem = styled(RadixMenu.CheckboxItem)`
  ${baseMenuItem}
`;

const SubTrigger = styled(RadixMenu.SubTrigger)`
  ${baseMenuItem}
`;

export const SubContent = styled(RadixMenu.SubContent)`
  z-index: 9999;
  min-width: max-content;
  background: #313237;
  border-radius: 0.4rem;
  padding: 0.4rem;

  @media screen and (max-width: ${breakPoint.small - 1}px) {
    &[data-side='left'] {
      transform: translate(
        calc(var(--radix-dropdown-menu-trigger-width)),
        calc(var(--radix-dropdown-menu-trigger-height) - 1rem)
      );
    }

    &[data-side='right'] {
      transform: translate(
        calc(-1 * var(--radix-dropdown-menu-trigger-width)),
        calc(var(--radix-dropdown-menu-trigger-height) - 1rem)
      );
    }
  }
`;

export const MenuItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8rem;
  height: 1.8rem;

  &:not(:last-child) {
    margin-right: 0.8rem;
  }

  &:last-child {
    margin-left: 0.8rem;
  }

  svg {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    fill: white;
  }
`;
