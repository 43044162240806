import React from 'react';

export const DownArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <g clipPath="url(#clip0_287_1147)">
        <path
          d="M11.4676 5.55008L7.99553 9.02211L4.52349 5.55008C4.1745 5.20108 3.61074 5.20108 3.26174 5.55008C2.91275 5.89907 2.91275 6.46283 3.26174 6.81182L7.36913 10.9192C7.71812 11.2682 8.28188 11.2682 8.63087 10.9192L12.7383 6.81182C13.0872 6.46283 13.0872 5.89907 12.7383 5.55008C12.3893 5.21003 11.8166 5.20108 11.4676 5.55008Z"
          fill="#626878"
        />
      </g>
      <defs>
        <clipPath id="clip0_287_1147">
          <rect width="16" height="16" fill="white" transform="translate(0 0.28833)" />
        </clipPath>
      </defs>
    </svg>
  );
};
