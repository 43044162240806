import React from 'react';
import styled from 'styled-components';

import { CreatableSelect } from '../../../components/select';

interface Props {
  onChange: (value: string) => void;
  value?: string;
  errorMessage?: string | null;
  hideLabel?: boolean;
}

interface Option {
  label: string;
  value: string;
}

const createOption = (value: string) => ({
  label: value,
  value: value,
});

export const RoleSelect: React.FC<Props> = React.forwardRef<any, Props>(
  ({ onChange, value, errorMessage, hideLabel }, ref) => {
    const [options, setOptions] = React.useState<Option[]>(value ? [{ label: value, value }] : []);

    const handleChange = (option: any) => {
      onChange(option.value);
    };

    const handleCreate = (inputValue: any) => {
      const newOption = createOption(inputValue);
      setOptions([...options, newOption]);
      onChange(newOption.value);
    };

    const selectedOption = options?.find((option) => option.value === value);

    return (
      <Container>
        {!hideLabel && <Label>Role</Label>}

        <CreatableSelect
          ref={ref}
          value={selectedOption}
          options={options}
          onChange={handleChange}
          onCreate={handleCreate}
          placeholder="Choose from the list or use your own"
        />
      </Container>
    );
  },
);

RoleSelect.displayName = 'RoleSelect';

const Container = styled.div`
  position: relative;
  max-width: 100%;
`;

const Label = styled.label`
  display: inline-block;
  font-weight: 600;
  color: white;
  line-height: 1.1rem;
  letter-spacing: 0.7px;
  margin-bottom: 0.8rem;
`;
