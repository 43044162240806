import React from 'react';

export const Search = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g id="Design-System" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="Basic-UI-Elements" transform="translate(-560.000000, -321.000000)">
        <g id="icon/search-icon" transform="translate(560.000000, 321.000000)">
          <path
            d="M23.6360261,21.8667053 C24.1224498,22.353129 24.1241045,23.140123 23.6321137,23.6321137 C23.1435293,24.1206982 22.3539237,24.1232445 21.8667053,23.6360261 L15.0746426,16.8439634 C13.4960076,18.0500575 11.523289,18.7664814 9.38324072,18.7664814 C4.20101997,18.7664814 1.67865721e-13,14.5654615 1.67865721e-13,9.38324072 C1.67865721e-13,4.20101997 4.20101997,0 9.38324072,0 C14.5654615,0 18.7664814,4.20101997 18.7664814,9.38324072 C18.7664814,11.523289 18.0500575,13.4960076 16.8439634,15.0746426 L23.6360261,21.8667053 Z M9.38324072,16.2642839 C13.1835359,16.2642839 16.2642839,13.1835359 16.2642839,9.38324072 C16.2642839,5.5829455 13.1835359,2.50219752 9.38324072,2.50219752 C5.5829455,2.50219752 2.50219752,5.5829455 2.50219752,9.38324072 C2.50219752,13.1835359 5.5829455,16.2642839 9.38324072,16.2642839 Z"
            id="search-icon"
            transform="translate(12.000488, 12.000000) scale(-1, 1) translate(-12.000488, -12.000000) "
          />
        </g>
      </g>
    </g>
  </svg>
);
