import React from 'react';

export const BurgerMenuIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.66667 12.0001H5.33333C5.7 12.0001 6 11.7001 6 11.3335C6 10.9668 5.7 10.6668 5.33333 10.6668H2.66667C2.3 10.6668 2 10.9668 2 11.3335C2 11.7001 2.3 12.0001 2.66667 12.0001ZM2 4.66679C2 5.03346 2.3 5.33346 2.66667 5.33346H13.3333C13.7 5.33346 14 5.03346 14 4.66679C14 4.30012 13.7 4.00012 13.3333 4.00012H2.66667C2.3 4.00012 2 4.30012 2 4.66679ZM2.66667 8.66679H9.33333C9.7 8.66679 10 8.36679 10 8.00012C10 7.63346 9.7 7.33346 9.33333 7.33346H2.66667C2.3 7.33346 2 7.63346 2 8.00012C2 8.36679 2.3 8.66679 2.66667 8.66679Z"
      fill="#959BAB"
    />
  </svg>
);
