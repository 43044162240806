import * as yup from 'yup';
import { TFunction } from 'i18next';

import type { FormBuilderDefinition } from '../../../components/form-builder-modal/form-builder';

export const buildLocationForm: (isCreate: boolean, t: TFunction) => FormBuilderDefinition = (
  isCreate: boolean,
  t: TFunction,
) => ({
  header: isCreate ? t('add_location') : t('update_location'),
  submitText: t('location:location_submit'),
  fields: [
    {
      name: 'name',
      label: 'Name of the location',
      placeholder: 'Name',
      type: 'text',
      isRequired: true,
      validation: yup
        .string()
        .matches(/^(.*)?\S+(.*)?$/, t('common:fieldRequired'))
        .max(32, t('common:fieldMax', { max: 32 }))
        .required(t('common:fieldRequired')),
    },
    {
      name: 'contact',
      label: t('location:contact'),
      placeholder: t('location:contact'),
      type: 'text',
      validation: yup
        .string()
        .max(64, t('common:fieldMax', { max: 64 }))
        .nullable(),
    },
    {
      name: 'requirements',
      label: t('notes'),
      type: 'textArea',
      validation: yup
        .string()
        .max(1024, t('common:fieldMax', { max: 1024 }))
        .nullable(),
    },
    {
      name: 'address',
      sectionHeader: t('location:address'),
      type: 'group',
      fields: [
        {
          name: 'line1',
          label: t('location:line1_label'),
          placeholder: t('location:line1_label'),
          type: 'text',
          validation: yup
            .string()
            .max(64, t('common:fieldMax', { max: 64 }))
            .nullable(),
        },
        {
          name: 'line2',
          label: t('location:line2_label'),
          placeholder: t('location:line2_label'),
          type: 'text',
          validation: yup
            .string()
            .max(64, t('common:fieldMax', { max: 64 }))
            .nullable(),
        },
        {
          name: 'city',
          type: 'text',
          label: 'City',
          placeholder: 'City',
          validation: yup
            .string()
            .max(64, t('common:fieldMax', { max: 64 }))
            .nullable(),
        },
        {
          name: 'state',
          type: 'text',
          label: t('location:state_label'),
          placeholder: t('location:state_label'),
          validation: yup
            .string()
            .max(64, t('common:fieldMax', { max: 64 }))
            .nullable(),
        },
        {
          name: 'postalCode',
          type: 'text',
          label: t('location:zipCode_label'),
          placeholder: t('location:zipCode_label'),
          validation: yup
            .string()
            .max(10, t('common:fieldMax', { max: 10 }))
            .nullable(),
        },
        {
          name: 'country',
          type: 'text',
          label: 'Country',
          placeholder: 'Country',
          validation: yup
            .string()
            .max(64, t('common:fieldMax', { max: 64 }))
            .nullable(),
        },
      ],
    },
  ],
});
