import React from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Phase } from '../phase-content/PhaseContent';
import { ClapperIcon } from '../icons/clapper';
import { Stage } from '../../app/entities/stage';
import { Tooltip } from '../tooltip/Tooltip';
import { breakPoint } from '../../app/theme';

interface HorizontalMenuProps {
  projectId: string;
  stages: Stage[];
}

export const HorizontalMenu: React.FC<HorizontalMenuProps> = observer(({ projectId, stages }) => {
  const navigate = useNavigate();
  const { spaceId, stepId } = useParams() as { spaceId: string; stepId: string };

  const goTo = () => {
    const href = `/space/${spaceId}/projects/${projectId}`;
    navigate(href);
  };

  return (
    <Menu>
      <Tooltip text="Project Overview" position="right">
        <HomeButton id="project-overview" onClick={goTo}>
          <ClapperIcon />
        </HomeButton>
      </Tooltip>

      <Phases>
        {stages.map((stage: any) => (
          <Phase key={stage._id} stage={stage} projectId={projectId} currentStepId={stepId} />
        ))}
      </Phases>
    </Menu>
  );
});

const Menu = styled.div`
  display: none;
  position: relative;
  z-index: 1000;
  flex-direction: row;
  align-items: center;
  height: var(--header-height);
  min-height: var(--header-height);
  border-bottom: 1px rgba(255, 255, 255, 0.16) solid;
  margin: 0 2.4rem;
  max-width: 100%;

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: flex;
  }
`;

const Phases = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.8rem;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

const HomeButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 3.2rem;
  width: 3.2rem;
  min-width: 3.2rem;
  min-height: 3.2rem;
  transition: 0.1s;
  cursor: pointer;
  background-color: #d53075;
  border-radius: var(--border-radius-large);
  color: #ffffffbf;

  svg {
    width: 1.8rem;
    height: 1.8rem;
    fill: #ffffffbf;
    transition: 0.1s;
  }

  &:hover {
    background-color: var(--color-primary);
    color: #ffffffe6;

    svg {
      fill: #ffffffe6;
    }
  }
`;
