import { toastPool } from './toast-pool';

type AlertType = 'success' | 'error' | 'warning' | 'robot';

export class Toast {
  message: string;

  type: AlertType = 'success';

  subheader?: string;

  constructor(message: string, type: AlertType = 'success') {
    this.message = message;
    this.type = type;
  }

  delete() {
    toastPool.delete(this);
  }
}
