import React, { useState } from 'react';
import styled from 'styled-components';
import { RocketIcon } from '@radix-ui/react-icons';

import { ElevatedButton } from '../../../components/buttons';
import { ChangePlanModal } from '../../billing/components/billing-and-plans/change-plan.modal';

export const SSOPlaceholder = () => {
  const [isSalesModalOpen, setSalesModalOpen] = useState<boolean>();

  return (
    <Container>
      <Content>
        <Header>
          <h2>Secure your data and people with Producer Enterprise</h2>
          <ElevatedButton
            text="Try Producer Enterprise"
            icon={<RocketIcon />}
            onClick={() => setSalesModalOpen(true)}
          />
        </Header>

        <Grid>
          <Card>
            <h3>SAML single sign-on</h3>
            <p>
              Control and secure access to your resorces within your enterprise's organizations by
              enforcing SAML single sign-on (SSO) through your identity provider (IdP).
            </p>
          </Card>

          <Card>
            <h3>Auto-provisioning with SCIM synchronization</h3>
            <p>
              Manage the lifecycle of your enterprise's user accounts from your identity provider
              (IdP) using System for Cross-domain Identity Management (SCIM).
            </p>
          </Card>
        </Grid>
      </Content>

      {isSalesModalOpen && (
        <ChangePlanModal
          title="Contact Sales"
          description='Please contact us at <a href="mailto:sales@the-producer.io">sales@the-producer.io</a> to upgrade your plan.'
          onCancel={() => setSalesModalOpen(false)}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  border-radius: var(--border-radius-medium);
  background-clip: content-box, border-box;
  background-origin: border-box;
  border: 1px solid transparent !important;
  background-image: linear-gradient(var(--app-background), var(--app-background)),
    radial-gradient(circle at top left, var(--color-primary), var(--color-error));
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  padding: 3.2rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3.2rem;
  margin-top: 3.2rem;
`;

const Card = styled.div`
  h3 {
    font-size: 1.6rem;
  }

  p {
    font-size: 1.4rem;
    margin-top: 1.2rem;
    line-height: 1.9rem;
    font-weight: 300;
    color: var(--text-color-white);
  }
`;
