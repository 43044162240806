import React, { useEffect, forwardRef, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { KeyboardKeys } from '../../assets/enums/keyboard-keys.enum';

export type MenuProps = {
  onClose: () => void;
  arrow?: boolean;
  direction?: string;
};

export const Menu = forwardRef<HTMLDivElement, PropsWithChildren<MenuProps>>(
  ({ onClose, children, direction }, ref) => {
    useEffect(() => {
      const shortcutListener = (e: KeyboardEvent) => {
        e.stopPropagation();

        if (e.key === KeyboardKeys.Escape) {
          onClose();
        }
      };

      document.addEventListener('keydown', shortcutListener);
      return () => document.removeEventListener('keydown', shortcutListener);
    }, [onClose]);

    return (
      <MenuContainer ref={ref} direction={direction}>
        {children}
      </MenuContainer>
    );
  },
);

Menu.displayName = 'Menu';

interface MenuContainerProps {
  direction?: string;
}

const MenuContainer = styled.div<MenuContainerProps>`
  position: absolute;
  min-width: 27.2rem;
  max-width: 28rem;
  padding: 0.8rem 0;
  background-color: #31343c;
  z-index: 1000;
  border-radius: var(--border-radius-medium);
  top: ${({ direction }) => (direction ? 0 : '100%')};
  left: ${({ direction }) => (direction ? '100%' : '0')};
  right: ${({ direction }) => direction === 'left' && '0'};

  ${({ direction }) =>
    direction === 'left'
      ? css`
          left: inherit;
          top: calc(100% + 0.8rem);
        `
      : ''};
`;
