import React from 'react';
import styled from 'styled-components';

import { Close } from '../icons/Close';
import { breakPoint } from '../../app/theme';

type ModalHeaderProps = {
  title: string | React.ReactNode;
  onClose?: () => void;
  action?: string;
  onAction?: () => void;
  actionDisabled?: boolean;
  className?: string;
};

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  title,
  onClose,
  action,
  onAction,
  actionDisabled,
  className,
}) => {
  return (
    <Header className={className}>
      {!!onClose && (
        <CloseItem onClick={onClose} className="close-item">
          <Close />
        </CloseItem>
      )}

      <h1>{title}</h1>

      <Action onClick={onAction} data-disabled={actionDisabled}>
        {action}
      </Action>
    </Header>
  );
};

const Header = styled.header`
  display: flex;
  border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
  gap: 1.6rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 2.4rem;
  font-weight: 500;
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-grayscale-tuna);
  margin-bottom: 2.4rem;

  svg {
    width: 1rem;
    fill: grey;
    transition: 0.1s;
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    border-bottom: none;
    padding: 2.4rem;
    margin-bottom: 0;
  }
`;

const Action = styled.span`
  cursor: pointer;
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--color-primary-crayola);

  &[data-disabled='true'] {
    color: var(--color-shades-tropaz, #254f9f);
    pointer-events: none;
    user-select: none;
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: none;
  }
`;

const CloseItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  transition: 0.1s;

  @media screen and (min-width: ${breakPoint.medium}px) {
    order: 3;
  }

  svg {
    width: 1.4rem;
    fill: var(--color-grayscale-trout);
    transition: 0.1s;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.4rem;

    svg {
      fill: white;
    }
  }
`;
