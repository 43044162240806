import React, { useEffect, useId } from 'react';
import { useRichTextEditor } from '../../hooks/useRichTextEditor';
import { EditorBubbleMenu } from '../rich-editor/editor-bubble-menu';
import styled from 'styled-components';
import { EditorContent } from '@tiptap/react';

interface CommentInputProps {
  value: string;
  onChange: (value: string) => void;
  onChangeText?: (value: string) => void;
  handleKeyDown: (event: React.KeyboardEvent) => void;
  className?: string;
  autoFocus?: boolean;
}

export const CommentInput: React.FC<CommentInputProps> = ({
  value,
  onChange,
  onChangeText,
  handleKeyDown,
  className = '',
  autoFocus = false,
}) => {
  const key = useId();

  const { editor, isTextSelected } = useRichTextEditor({
    key,
    value: value || '',
    autoFocus,
    onUpdate: (text) => {
      onChange(text);
    },
    onUpdateText: (text) => {
      onChangeText && onChangeText(text);
    },
  });

  useEffect(() => {
    if (editor && value === '') {
      editor.commands.clearContent();
    }
  }, [editor, value]);

  return (
    <div style={{ position: 'relative' }}>
      {editor && isTextSelected && <EditorBubbleMenu editor={editor} />}
      <Input
        editor={editor}
        placeholder={'Leave your comment here...'}
        onKeyDown={handleKeyDown}
        className={className}
        autoFocus={autoFocus}
      />
    </div>
  );
};

const Input = styled(EditorContent)`
  outline: none;
`;
