import React, { useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';

import { LocationListScreen } from '../../../features/locations/screens/location-list.screen';
import { LocationAssetViewerScreen } from '../../../features/locations/screens/location-asset-viewer.screen';
import { LocationFilesScreen } from '../../../features/locations/screens/location-files.screen';

import type { Project } from '../../../app/entities/project';
import type { Step } from '../../../app/entities/step';
import { getLocationAssets } from '../../../features/assets/store/assets.slice';

export type LocationPageProps = {
  project: Project;
  step: Step;
};

const LocationFiles: React.FC<LocationPageProps> = ({ project, step }) => {
  const { locationId } = useParams() as { locationId: string };

  useEffect(() => {
    getLocationAssets({ locationId: locationId! });
  }, [locationId]);

  return (
    <Routes>
      <Route
        path="/file/:assetId"
        element={
          <LocationAssetViewerScreen
            projectId={project._id}
            step={step}
            spaceId={project.spaceId}
          />
        }
      />

      <Route
        path="/"
        element={
          <LocationFilesScreen projectId={project._id} step={step} spaceId={project.spaceId} />
        }
      />
    </Routes>
  );
};

export const LocationPage: React.FC<LocationPageProps> = ({ project, step }) => (
  <Routes>
    <Route
      path="/location/:locationId/*"
      element={<LocationFiles project={project} step={step} />}
    />
    <Route path={'/'} element={<LocationListScreen projectId={project?._id} step={step} />} />
  </Routes>
);
