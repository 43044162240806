import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { InfoIcon, WarningDanger } from '../icons';

type NoteProps = {
  variant?: 'info' | 'danger';
  isFullWidth?: boolean;
};

export const Note: React.FC<PropsWithChildren<NoteProps>> = ({
  children,
  variant = 'info',
  isFullWidth,
}) => (
  <Container className="note-container" data-variant={variant} data-full={isFullWidth}>
    {variant === 'danger' ? <WarningDanger /> : <InfoIcon />}

    <Text>{children}</Text>
  </Container>
);

const Container = styled.div`
  display: flex;
  gap: 1.2rem;
  padding: 0.8rem;
  background: rgba(54, 123, 255, 0.2);
  box-sizing: border-box;
  border-radius: 0.4rem;
  border: 1px solid var(--color-primary-crayola, #367bff);

  svg {
    flex: 0 0 auto;
  }

  &[data-full='true'] {
    width: 100%;
  }

  &[data-variant='danger'] {
    background: rgba(219, 92, 102, 0.2);
    border: 0.1rem solid var(--color-secondary-roman, #db5c66);
  }
`;

const Text = styled.div`
  color: var(--color-grayscale-white);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
`;
