import { useEffect, useRef } from 'react';
import { useResponsive } from '../../hooks/useResponsive';

interface LongTouchProps {
  onLongTouch: () => void;
}

export const useLongTouch = ({ onLongTouch }: LongTouchProps) => {
  const { isMobile } = useResponsive();
  const itemRef = useRef<any>(null);

  const touchTimer = useRef<any>(null);
  const isTouchDownActive = useRef<boolean>(false);

  const onTouchStart = () => {
    isTouchDownActive.current = true;
    touchTimer.current = setTimeout(() => {
      if (isTouchDownActive.current) onLongTouch();
    }, 500);
  };

  const onTouchEnd = () => {
    isTouchDownActive.current = false;
    clearTimeout(touchTimer.current);
  };

  const onContextMenu = (e: any) => {
    if (isMobile) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    itemRef.current?.addEventListener('touchstart', onTouchStart);
    itemRef.current?.addEventListener('touchend', onTouchEnd);
    itemRef.current?.addEventListener('contextmenu', onContextMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemRef.current]);

  return { longTouchRef: itemRef };
};
