import * as yup from 'yup';
import { TFunction } from 'i18next';

import { RoleSelect } from '../../features/teams/components/role-select';

import type { ControllerRenderProps } from 'react-hook-form';
import type { FormBuilderDefinition } from '../../components/form-builder-modal/form-builder';

export const buildContactForm: (
  isCreate: boolean,
  t: TFunction,
  handleSetImage: (file?: File) => void,
) => FormBuilderDefinition = (
  isCreate: boolean,
  t: TFunction,
  handleSetImage: (file?: File) => void,
) => ({
  header: isCreate ? 'Create contact' : 'Update contact',
  submitText: isCreate ? 'Create contact' : 'Update contact',
  fields: [
    {
      type: 'upload',
      handleSetImage,
    },
    {
      type: 'group',
      sectionHeader: 'Personal information',
    },
    {
      name: 'firstName',
      label: 'First name',
      isRequired: true,
      type: 'text',
      validation: yup
        .string()
        .matches(/^(.*)?\S+(.*)?$/, t('common:fieldRequired'))
        .required(t('common:fieldRequired'))
        .max(64, t('common:fieldMax', { max: 64 })),
    },
    {
      name: 'lastName',
      label: 'Last name',
      type: 'text',
      validation: yup
        .string()
        .max(64, t('common:fieldMax', { max: 64 }))
        .nullable(),
    },
    {
      name: 'city',
      label: 'City',
      type: 'text',
      validation: yup
        .string()
        .max(64, t('common:fieldMax', { max: 64 }))
        .nullable(),
    },
    {
      name: 'country',
      label: 'Country',
      type: 'text',
      validation: yup
        .string()
        .max(64, t('common:fieldMax', { max: 64 }))
        .nullable(),
    },
    {
      name: 'phone',
      label: 'Phone number',
      type: 'text',
      validation: yup
        .string()
        .transform((value) => value.replaceAll('  ', ' '))
        .max(32, t('common:fieldMax', { max: 32 })),
    },
    {
      name: 'email',
      label: 'Email',
      type: 'text',
      validation: yup.string().email(t('common:wrong_email_error')).nullable(),
    },
    {
      name: 'role',
      label: 'Main Job Title',
      type: 'controller',
      render: ({ field: { onChange, value } }: { field: ControllerRenderProps }) => (
        <RoleSelect value={value} onChange={onChange} hideLabel />
      ),
    },
    {
      type: 'group',
      sectionHeader: 'Additional information',
    },
    {
      name: 'website',
      label: 'Website',
      type: 'text',
      validation: yup
        .string()
        .max(64, t('common:fieldMax', { max: 64 }))
        .nullable(),
    },
    {
      name: 'foodPreferences',
      label: 'Food preferences',
      type: 'text',
      validation: yup
        .string()
        .max(200, t('common:fieldMax', { max: 200 }))
        .nullable(),
    },
    {
      name: 'driverLicense',
      label: "Driver's license",
      type: 'text',
      validation: yup
        .string()
        .max(64, t('common:fieldMax', { max: 64 }))
        .nullable(),
    },
  ],
});
