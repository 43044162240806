import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { CalendarIcon } from '@radix-ui/react-icons';

import { formatDate } from '../../lib/utils/DateHelper';
import { dateShort } from '../../assets/contants/dates-fns';

interface IconSmallProps {
  onClick: (e: React.MouseEvent) => void;
  date?: string;
}

export const Date: React.FC<IconSmallProps> = observer(({ date, onClick }) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick(e);
  };

  return (
    <DateItem onClick={handleClick}>
      <CalendarIcon />
      {date && <DateText>{formatDate(date, dateShort)}</DateText>}
    </DateItem>
  );
});

const DateItem = styled.div`
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0.3rem 0.6rem;
  background-color: #1f2023;

  &:hover {
    color: var(--date-color);
    background-color: var(--date-bg-color-hover);
    border-color: var(--date-border-color);
  }

  svg {
    width: 1.4rem;
    height: 1.4rem;
    fill: var(--icon-fill);
    transition: 0.1s;
  }
`;

const DateText = styled.div`
  margin-left: 0.8rem;
  padding-right: 0.4rem;
  font-weight: 400;
  font-size: 1.4rem;
`;
