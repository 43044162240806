import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  BaseDialog,
  BaseDialogProps,
  DialogFooter,
  DialogHeader,
} from '../../../components/dialog';
import { ElevatedButton, TextButton } from '../../../components/buttons';
import { TextInput } from '../../../components/text-input/TextInput';
import { Label } from '../../../components/Input/styled-input';
import { createFormDiff } from '../../../core/services/form.service';

type ContactInfoModalProps = BaseDialogProps & {
  onSubmit: (values: FormValues) => Promise<void>;
  defaultValues?: FormValues;
  title: string;
  onClose: () => void;
};

type FormValues = {
  name?: string;

  phone?: string;

  address?: {
    line1?: string;

    line2?: string;

    postalCode?: string;

    city?: string;

    country?: string;
  };
};

export const ContactInfoModal: React.FC<ContactInfoModalProps> = ({
  onClose,
  defaultValues,
  title,
  ...props
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().max(32, t('common:fieldMax', { max: 32 })),
        phone: yup.string().max(16, t('common:fieldMax', { max: 16 })),
        address: yup.object().shape({
          line1: yup.string(),
          line2: yup.string(),
          postalCode: yup.string().max(12, t('common:fieldMax', { max: 12 })),
          city: yup.string(),
          country: yup.string(),
        }),
      }),
    [t],
  );

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: defaultValues || {
      name: '',
      phone: '',
      address: {
        line1: '',
        line2: '',
        postalCode: '',
        city: '',
        country: '',
      },
    },
  });

  const onSubmit = async (values: FormValues) => {
    if (isLoading) {
      return;
    }

    const diffs = createFormDiff(values, dirtyFields);

    setIsLoading(true);

    await props.onSubmit(diffs);

    onClose();
  };

  return (
    <BaseDialog onClose={onClose}>
      <DialogHeader title={title} onClose={onClose} />

      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label={`${title} name`}
            {...register('name')}
            errorMessage={errors.name?.message}
          />

          <Separator />

          <Grid>
            <div style={{ gridColumn: 'span 3' }}>
              <TextInput label="Address" {...register('address.line1')} />
            </div>

            <TextInput
              label="Postal Code"
              {...register('address.postalCode')}
              errorMessage={errors.address?.postalCode?.message}
            />

            <div style={{ gridColumn: 'span 2' }}>
              <TextInput label="City" {...register('address.city')} />
            </div>

            <div style={{ gridColumn: 'span 3' }}>
              <TextInput label="Country" {...register('address.country')} />
            </div>
          </Grid>

          <Separator />

          <TextInput label="Phone" {...register('phone')} errorMessage={errors.phone?.message} />
        </form>
      </Content>

      <DialogFooter
        actions={[
          <TextButton key={'cancel'} onClick={onClose} text={'Cancel'} />,
          <ElevatedButton
            key="save"
            text={'Save'}
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
          />,
        ]}
      />
    </BaseDialog>
  );
};

const Content = styled.div`
  padding: 1.6rem;
  flex: 1;
  overflow-y: scroll;

  ${Label} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.6rem;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 3.2rem 0 2.4rem;
  background-color: var(--color-grey-6);
`;
