import { Location } from 'react-router-dom';

interface Tab {
  key: string;
  label: string;
}
export const getCurrentActiveTab = (tabs: Tab[], defaultTab: string, location?: Location) => {
  const splitLocation = (location || window.location).pathname.split('/');
  let selectedTab = splitLocation[splitLocation.length - 1];
  if (!tabs.some((tab) => tab.key === selectedTab)) {
    selectedTab = defaultTab;
  }
  return selectedTab;
};
