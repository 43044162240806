import React from 'react';

export const TimeIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5118_9365)">
      <path
        d="M12 20C7.5816 20 4 16.4184 4 12C4 7.5816 7.5816 4 12 4C16.4184 4 20 7.5816 20 12C20 16.4184 16.4184 20 12 20ZM12.8 12V8H11.2V13.6H16V12H12.8Z"
        fill="#367BFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_5118_9365">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
