import React from 'react';

export const WarningDanger = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0001 5.49384L19.5301 18.5038H4.47012L12.0001 5.49384ZM2.74012 17.5038C1.97012 18.8338 2.93012 20.5038 4.47012 20.5038H19.5301C21.0701 20.5038 22.0301 18.8338 21.2601 17.5038L13.7301 4.49384C12.9601 3.16384 11.0401 3.16384 10.2701 4.49384L2.74012 17.5038ZM11.0001 10.5038V12.5038C11.0001 13.0538 11.4501 13.5038 12.0001 13.5038C12.5501 13.5038 13.0001 13.0538 13.0001 12.5038V10.5038C13.0001 9.95384 12.5501 9.50384 12.0001 9.50384C11.4501 9.50384 11.0001 9.95384 11.0001 10.5038ZM11.0001 15.5038H13.0001V17.5038H11.0001V15.5038Z"
      fill="currentColor"
    />
  </svg>
);
