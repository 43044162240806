import React from 'react';
import styled from 'styled-components';
import { ArrowRightIcon } from '@radix-ui/react-icons';

import { DateSelector } from '../../../components/date-selector/date-selector';

interface TimeFrameProps {
  startDate: Date | string | undefined;
  dueDate: Date | string | undefined;
  onUpdate: (values: { dueDate?: string; startDate?: string }) => void;
}

export const TimeFrame: React.FC<TimeFrameProps> = ({ startDate, dueDate, onUpdate }) => (
  <Container>
    <DateSelector
      date={startDate}
      max={dueDate}
      field={'startDate'}
      onUpdate={(startDate) => onUpdate({ startDate })}
    />
    <ArrowRightIcon />
    <DateSelector
      date={dueDate}
      min={startDate}
      field={'dueDate'}
      onUpdate={(dueDate) => onUpdate({ dueDate })}
    />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
  color: var(--color-grayscale-manatee);
  font-size: 1.2rem;
  font-weight: 400;
`;
