import React from 'react';

export const ListDisplayIcon = () => (
  <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 0.699951C0.723858 0.699951 0.5 0.923809 0.5 1.19995C0.5 1.47609 0.723858 1.69995 1 1.69995H17C17.2761 1.69995 17.5 1.47609 17.5 1.19995C17.5 0.923809 17.2761 0.699951 17 0.699951H1ZM0.5 5.99995C0.5 5.72381 0.723858 5.49995 1 5.49995H17C17.2761 5.49995 17.5 5.72381 17.5 5.99995C17.5 6.27609 17.2761 6.49995 17 6.49995H1C0.723858 6.49995 0.5 6.27609 0.5 5.99995ZM0.5 10.8C0.5 10.5238 0.723858 10.3 1 10.3H17C17.2761 10.3 17.5 10.5238 17.5 10.8C17.5 11.0761 17.2761 11.3 17 11.3H1C0.723858 11.3 0.5 11.0761 0.5 10.8Z"
      fill="#959BAB"
    />
    <path
      d="M1 1.19995V0.199951C0.447715 0.199951 0 0.647666 0 1.19995H1ZM1 1.19995H0C0 1.75224 0.447715 2.19995 1 2.19995V1.19995ZM17 1.19995H1V2.19995H17V1.19995ZM17 1.19995V2.19995C17.5523 2.19995 18 1.75224 18 1.19995H17ZM17 1.19995H18C18 0.647666 17.5523 0.199951 17 0.199951V1.19995ZM1 1.19995H17V0.199951H1V1.19995ZM1 4.99995C0.447715 4.99995 0 5.44767 0 5.99995H1V4.99995ZM17 4.99995H1V5.99995H17V4.99995ZM18 5.99995C18 5.44767 17.5523 4.99995 17 4.99995V5.99995H18ZM17 6.99995C17.5523 6.99995 18 6.55224 18 5.99995H17V6.99995ZM1 6.99995H17V5.99995H1V6.99995ZM0 5.99995C0 6.55224 0.447715 6.99995 1 6.99995V5.99995H0ZM1 9.79995C0.447715 9.79995 0 10.2477 0 10.8H1V9.79995ZM17 9.79995H1V10.8H17V9.79995ZM18 10.8C18 10.2477 17.5523 9.79995 17 9.79995V10.8H18ZM17 11.8C17.5523 11.8 18 11.3522 18 10.8H17V11.8ZM1 11.8H17V10.8H1V11.8ZM0 10.8C0 11.3522 0.447715 11.8 1 11.8V10.8H0Z"
      fill="#959BAB"
    />
  </svg>
);
