import React from 'react';
import styled from 'styled-components';
import { Close } from '../../icons/Close';

type DialogHeaderProps = {
  title: string;
  onClose?: () => void;
};

export const ModalHeader: React.FC<DialogHeaderProps> = ({ title, onClose }) => {
  return (
    <Header>
      <Title>{title}</Title>

      {!!onClose && (
        <CloseItem className="close-item" onClick={onClose}>
          <Close />
        </CloseItem>
      )}
    </Header>
  );
};

const Header = styled.header`
  display: flex;
  border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.6rem;
  justify-content: space-between;
  background: transparent;
  font-weight: 500;

  svg {
    width: 1rem;
    fill: grey;
    transition: 0.1s;
  }
`;

const Title = styled.div`
  color: var(--step-title-color);
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.9rem;
`;

const CloseItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  transition: 0.1s;

  svg {
    width: 1.4rem;
    fill: #898a8f;
    transition: 0.1s;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.4rem;

    svg {
      fill: white;
    }
  }
`;
