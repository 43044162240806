import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';

import { Menu } from '../../../components/menu/Menu';
import { IconButton } from '../../../components/buttons';
import { useOnClickOutside } from '../../../hooks/useOutsideClick';

interface PopupMenuButtonProps {
  items: ReactNode[];
  direction?: string;
}

export const PopupMenuButton: React.FC<PopupMenuButtonProps> = ({ items, direction }) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeMenu = () => setIsOpen(false);
  const toggleMenu = () => setIsOpen(!isOpen);
  const { ref: menuRef } = useOnClickOutside(() => setIsOpen(false));

  return (
    <PopupMenu ref={menuRef}>
      <IconButton icon="⋮" onClick={toggleMenu} />

      {isOpen && (
        <Menu onClose={closeMenu} direction={direction}>
          {items}
        </Menu>
      )}
    </PopupMenu>
  );
};

const PopupMenu = styled.div`
  position: relative;
`;
