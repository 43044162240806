import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { SortableContext } from '@dnd-kit/sortable';

import { StepItem } from '../task/StepItem';
import { Step } from '../../app/entities/step';

type StepListProps = {
  isCurrent?: boolean;
  tasks?: Step[];
};

export const StepList: React.FC<StepListProps> = observer(({ tasks = [] }) => {
  const stepsIds = tasks.filter((task) => task.type !== 'shootingDay').map((task) => task._id);

  return (
    <TaskListItem>
      <SortableContext items={stepsIds}>
        {tasks.map((task) => (
          <StepItem key={task._id} step={task} />
        ))}
      </SortableContext>
    </TaskListItem>
  );
});

interface TaskListItemProps {
  current?: boolean;
}

const TaskListItem = styled.div<TaskListItemProps>`
  position: relative;
  z-index: 99;

  a {
    text-decoration: none;
  }
`;
