import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import UseCSV from '@usecsv/react';
import { observer } from 'mobx-react-lite';

import { createBatchContacts } from '../../features/contacts/stores/contact.slice';
import { ElevatedButton } from '../../components/buttons';
import { PagePlaceholder } from '../../components/page-placeholder/page-placeholder';
import { globalConfig } from '../../configuration/config';
import { ContactSearchBar } from '../../features/contacts/components/contact-search.bar';
import { searchContacts } from '../../features/contacts/components/utils/search-contacts';
import { CreateContactDrawer } from '../../features/contacts/components/create-contact.drawer';
import { ContactList } from '../../features/contacts/components/contact-list';
import { ContactDetailsPage } from './contact-detail.page';

import type { Contact } from '../../app/entities/contact';

type ContactListPageProps = {
  contacts: Contact[];
};

const transform = (rows: any[]) =>
  rows.map((row) => ({
    firstName: row['First Name'],
    lastName: row['Last Name'],
    role: row['Role'],
    email: row['Email'],
    phone: row['Phone'],
    city: row['City'],
    country: row['Country'],
  }));

export const ContactsListScreen: React.FC<ContactListPageProps> = observer(({ contacts }) => {
  const navigate = useNavigate();
  const { spaceId } = useParams<{ spaceId: string }>();
  const [searchValue, setSearchValue] = useState('');
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>(contacts);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const handleContactImport = async (data: any) => {
    await createBatchContacts({
      spaceId: spaceId!,
      contacts: transform(data.rows),
    });
  };

  const hasContacts = !!contacts?.length;
  const importerKey = globalConfig.config.csvImporterKey;

  useEffect(() => {
    setFilteredContacts(searchContacts(contacts, searchValue));
  }, [searchValue, contacts]);

  const selectCreatedContact = (contact: Contact) => {
    navigate(`../${contact._id}`);
  };

  return (
    <>
      {hasContacts && (
        <HeaderContainer>
          <Title>Contacts</Title>

          <RightHeaderContainer>
            <ContactSearchBar onChange={setSearchValue} key="search-contacts" value={searchValue} />

            {importerKey && (
              <UseCSV
                importerKey={importerKey}
                onData={handleContactImport}
                render={(openModal) => <ElevatedButton text="Import" onClick={openModal} />}
              />
            )}
            <ElevatedButton
              key="create-contact"
              onClick={() => setIsCreateModalOpen(true)}
              text="Add contact"
            />
          </RightHeaderContainer>
        </HeaderContainer>
      )}

      <CreateContactDrawer
        spaceId={spaceId!}
        isOpen={isCreateModalOpen}
        onCancel={() => setIsCreateModalOpen(false)}
        onSubmit={selectCreatedContact}
      />

      <Container>
        {hasContacts ? (
          <>
            <ScrollContainer>
              <ContactList contacts={filteredContacts} />
            </ScrollContainer>

            <Routes>
              <Route path="/*" element={<ContactDetailsPage />} />
            </Routes>
          </>
        ) : (
          <PagePlaceholder
            description='Contacts will be displayed here, you can add them by clicking on "Import" or "Create
        Contact"'
            actions={[
              <React.Fragment key="import-contacts">
                {importerKey && (
                  <UseCSV
                    importerKey={importerKey}
                    onData={handleContactImport}
                    render={(openModal) => <ElevatedButton text="Import" onClick={openModal} />}
                  />
                )}
              </React.Fragment>,
              <ElevatedButton
                key="create-contact"
                onClick={() => setIsCreateModalOpen(true)}
                text="Create contact"
              />,
            ]}
          />
        )}
      </Container>
    </>
  );
});

const Container = styled.div`
  display: flex;
  margin-top: 1.6rem;
  flex-direction: row;
  gap: 2.4rem;
  margin-inline-start: 1.6rem;
  min-height: 0;
  height: 100%;
`;

const RightHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--color-grayscale-white);
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  padding: 0.8rem 1.6rem;
  gap: 1.2rem;

  form {
    width: 100%;
    max-width: 50rem;
  }
`;

const ScrollContainer = styled.div`
  padding-bottom: 1.6rem;
  padding-right: 1.2rem;
  min-height: 0;
  overflow-y: auto;
  max-height: 100%;
  flex: 1 0 32rem;
`;
