import React from 'react';
import styled from 'styled-components';

import {
  Cell,
  Table,
  TableHeader,
  THead,
  Row,
} from '../../../components/table/styled-shared-table';

type AdditionalInfoTableProps = {
  additionalInfo: AdditionalInfo[];
};

export type AdditionalInfo = {
  number: number;
  props?: string;
  directorsNote?: string;
};

export const AdditionalInfoTable: React.FC<AdditionalInfoTableProps> = ({ additionalInfo }) => (
  <Table>
    <col
      style={{
        width: '8%',
      }}
    />

    <col
      style={{
        width: '42%',
      }}
    />

    <col
      style={{
        width: '50%',
      }}
    />

    <TableHeader>
      <TableHeadCell>Shot number</TableHeadCell>

      <TableHeadCell>Props</TableHeadCell>

      <TableHeadCell>Director&apos;s notes</TableHeadCell>
    </TableHeader>

    <tbody>
      {additionalInfo.map((info) => (
        <Row key={info.number}>
          <TableCell>{info.number}</TableCell>
          <TableCell>{info.props || ''}</TableCell>
          <TableCell>{info.directorsNote || ''}</TableCell>
        </Row>
      ))}
    </tbody>
  </Table>
);

const TableHeadCell = styled(THead)`
  border-bottom: 0.1rem solid var(--color-grayscale-tuna);
  white-space: nowrap;

  padding-inline-start: 1.6rem;
`;

const TableCell = styled(Cell)`
  padding-inline-start: 1.6rem;
`;
