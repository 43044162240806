import React, { useContext, useEffect, useRef, useState } from 'react';
import { CommentProvider, VideoPlayer } from '@producer-io/ui-kit';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { LoaderContainer } from '../step-container';
import { PageLoader } from '../../../../components/page-loader/PageLoader';
import { VersionSelector } from '../../../../features/assets/components/version-selector';
import { timeDifference } from '../../../../lib/utils/TimeDifference';
import { Comments } from '../../../../components/comment/Comments';
import { CompareContext } from './compare.context';
import { secondsToHms } from '../../../../lib/utils/Convertor';
import { Asset } from '../../../../app/entities/asset';

import type { Comment } from '../../../../app/entities/comment';

type VideoCompareProps = {
  id: 'v1' | 'v2';
  selectedAssetId: string;
  onSelectVersion: (id: 'v1' | 'v2', versionId: string) => void;
  assetChildren: Asset[];
};

export const VideoToCompare = observer(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLVideoElement, VideoCompareProps>(
    ({ id, selectedAssetId, onSelectVersion, assetChildren }, ref) => {
      const playerRef = ref as React.RefObject<HTMLVideoElement>;
      const timeTagRef = useRef<HTMLDivElement | null>(null);

      const selectedAsset = Asset.getOne(selectedAssetId);
      const { togglePlay, toggleMute, handleSeekTime, handleRateChange, handleLoadStart } =
        useContext(CompareContext);

      const [videoLink, setVideoLink] = useState<null | string>(null);
      const [isLoading, setIsLoading] = useState(true);

      useEffect(() => {
        if (!selectedAsset) {
          return;
        }

        selectedAsset.fetchComments();

        selectedAsset
          .getPlaybackLink()
          .then((url) => {
            setVideoLink(url);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }, [selectedAsset]);

      if (isLoading || !selectedAsset) {
        return (
          <LoaderContainer>
            <PageLoader />
          </LoaderContainer>
        );
      }

      const handleSelectComment = async (comment: Pick<Comment, 'timestamp'>) => {
        if (comment.timestamp && playerRef?.current) {
          handleSeekTime(comment.timestamp);
        }
      };

      return (
        <CommentProvider comments={selectedAsset.comments}>
          <Container>
            <VideoPlayerWrapper>
              {videoLink && (
                <VideoPlayer
                  key={`video-player-${id}-${selectedAsset._id}`}
                  ref={playerRef}
                  url={videoLink}
                  onLoadStart={() => handleLoadStart(playerRef)}
                  onRateChange={handleRateChange}
                  onSetCurrentTime={handleSeekTime}
                  onMuteChange={(isMute) => toggleMute(playerRef, isMute)}
                  onPlayChange={(isPlaying) => togglePlay(playerRef, isPlaying)}
                  onTimeUpdate={(currentTime) => {
                    if (!timeTagRef?.current) {
                      return;
                    }

                    timeTagRef.current.innerHTML = secondsToHms(currentTime);
                  }}
                />
              )}

              <VideoInfo>
                <AssetName>{selectedAsset.name}</AssetName>

                {assetChildren?.length > 0 && (
                  <VersionSelector
                    key={`video-asset-selector-${id}-${selectedAsset._id}`}
                    assetChildren={assetChildren}
                    version={selectedAsset.version!}
                    selectedAssetId={selectedAsset._id}
                    onSelect={(asset) => onSelectVersion(id, asset._id)}
                  />
                )}

                <RelativeTime>{timeDifference(new Date(), selectedAsset.createdAt)}</RelativeTime>
              </VideoInfo>
            </VideoPlayerWrapper>

            <CommentsWrapper>
              <Comments
                asset={selectedAsset}
                showHeader={false}
                playerRef={playerRef}
                timeTagRef={timeTagRef}
                onSelectComment={handleSelectComment}
                defaultShowCompleted
              />
            </CommentsWrapper>
          </Container>
        </CommentProvider>
      );
    },
  ),
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  max-height: 100%;
  width: 100%;
`;

const VideoPlayerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 65%;
  width: 100%;
`;

const CommentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 0 0 100%;
  max-height: 35%;
  min-height: 35%;

  > div {
    max-height: 100%;
    min-height: 100%;

    & textarea {
      min-height: 4rem;
    }
  }
`;

const VideoInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.6rem;
  padding: 1.6rem;
  height: 6.4rem;
  background-color: var(--color-grey-9-5);
`;

const AssetName = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--step-title-color);
`;

const RelativeTime = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.9rem;
  color: var(--color-grey-4);
`;
