import React, { useEffect, useState } from 'react';
import { useResponsive } from '../../../hooks/useResponsive';
import styled from 'styled-components';

import { Contact } from '../../../app/entities/contact';
import { ContactWithPreview } from './contact-with-preview';
import { breakPoint } from '../../../app/theme';
import { ContactSearchBar } from './contact-search.bar';
import { searchContacts } from './utils/search-contacts';

export type ContactListProps = {
  contacts: Contact[];
};

export const ContactList: React.FC<ContactListProps> = ({ contacts }) => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>(contacts);
  const { isMobile } = useResponsive();

  useEffect(() => {
    setFilteredContacts(searchContacts(contacts, searchValue));
  }, [searchValue, contacts]);

  return (
    <Container>
      {isMobile && (
        <ContactSearchBarWrapper>
          <ContactSearchBar onChange={setSearchValue} key="search-contacts" value={searchValue} />
        </ContactSearchBarWrapper>
      )}

      {filteredContacts?.map((contact) => (
        <ContactWithPreview key={contact._id} contact={contact} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakPoint.small}px) {
    gap: 0.8rem;
  }
`;

const ContactSearchBarWrapper = styled.div`
  margin: 1.6rem 0;

  input {
    font-weight: 400;
    font-size: 1.2rem;
  }

  svg {
    width: 1.6rem;
    color: var(--color-grayscale-shuttle);
  }
`;
