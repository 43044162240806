import React from 'react';
import { observer } from 'mobx-react-lite';

import { ShotStripComponent } from './shot-strip';
import { DayBreakStripComponent } from './day-break-strip';
import { BreakStrip } from './break-strip';
import { Modifier } from '../../../core/contexts/selection.context';
import { getModifier } from '../../../core/services/event.service';
import { MobileShotStripComponent } from './shot-strip.mobile';
import { Stripboard } from '../../../app/entities/stripboard';
import { MobileDayBreakStrip } from './day-break-strip.mobile';
import { MobileBreakStrip } from './break-strip.mobile';
import { Strip } from '../models/types';

const stripType: {
  [key: string]: {
    [key: string]: any;
  };
} = {
  desktop: {
    shot: ShotStripComponent,
    dayBreak: DayBreakStripComponent,
    banner: BreakStrip,
  },
  mobile: {
    shot: MobileShotStripComponent,
    dayBreak: MobileDayBreakStrip,
    banner: MobileBreakStrip,
  },
};

export type StripProps = {
  strip: Strip;
  stripboard: Stripboard;
  style?: any;
  isSelected?: boolean;
  isDragging?: boolean;
  onClick?: (stripId: string, modifier?: Modifier) => void;
  screenType?: 'desktop' | 'mobile';
};

export const StripComponent = observer(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLDivElement, StripProps>(
    (
      {
        stripboard,
        strip,
        isSelected,
        isDragging,
        onClick,
        screenType = 'desktop',
        ...draggableProps
      },
      ref,
    ) => {
      const Component = stripType[screenType][strip.type];
      if (!Component) return null;

      const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        const modifier = getModifier(e.nativeEvent);

        onClick && onClick(strip._id, modifier);
      };

      return (
        <Component
          ref={ref}
          key={strip._id}
          stripboard={stripboard}
          strip={strip}
          onClick={handleClick}
          isSelected={isSelected}
          isDragging={isDragging}
          {...draggableProps}
        />
      );
    },
  ),
);

StripComponent.displayName = 'Strip';
