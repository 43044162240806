import React, { useEffect } from 'react';
import styled from 'styled-components';

import { SettingScreenLayout } from '../../features/settings/components/setting-screen-layout';
import { ManageBillingSection } from '../../features/billing/components/billing-and-plans/manage-billing-section';
import { StorageBillingSection } from '../../features/billing/components/billing-and-plans/storage-billing-section';
import { uiStore } from '../../core/stores/ui-store';
import { useCurrentSpace } from '../../hooks/use-current-space';

export const BillingSettingsRoute: React.FC = () => {
  const space = useCurrentSpace();

  useEffect(() => {
    uiStore.showBack = true;
  }, []);

  useEffect(() => {
    space?.getBillingDetails();
    space?.getBillingHistory();
  }, [space]);

  return (
    <SettingScreenLayout title="Billing">
      <Container>
        <StorageBillingSection space={space} />

        {space.hasSubscription() && space.subscription?.provider === 'paddle' && (
          <ManageBillingSection space={space} />
        )}
      </Container>
    </SettingScreenLayout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
