import React, { useRef, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';

import { KeyboardKeys } from '../../assets/enums/keyboard-keys.enum';
import { CommentInput } from './comment-input';

interface CommentFormProps {
  formId: string;
  defaultValue?: string;
  onSubmit: (comment: string) => void;
  onCancel: () => void;
}

interface FormValues {
  text: string;
}

export const CommentForm: React.FC<CommentFormProps> = ({
  onCancel,
  onSubmit,
  formId,
  defaultValue,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const [comment, setComment] = React.useState(defaultValue || '');
  const [commentText, setCommentText] = React.useState(defaultValue || '');

  useEffect(() => {
    if (inputRef.current) inputRef.current?.focus();
  }, []);

  const validationSchema = yup.object().shape({
    text: yup
      .string()
      .matches(/^(?!\s*$).+/s, 'Comment is required')
      .required('Comment is required')
      .max(256, 'Max 256 characters'),
  });

  const { handleSubmit, control, setError } = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      text: defaultValue,
    },
    values: {
      text: comment,
    },
  });

  const handleOnSubmit = async (data: FormValues) => {
    try {
      await validationSchema.validate({ text: commentText });
    } catch (e: any) {
      setError('text', {
        type: 'manual',
        message: e.errors[0],
      });
      return;
    }

    if (onSubmit) {
      onSubmit(data.text);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation();

    if (event.key === KeyboardKeys.Enter && !event.altKey && !event.shiftKey) {
      event.preventDefault();
      formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }

    if (event.key === KeyboardKeys.Escape) {
      onCancel();
    }
  };

  return (
    <form id={formId} onSubmit={handleSubmit(handleOnSubmit)} ref={formRef}>
      <InputWrapper>
        <Controller
          name="text"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <Input
                autoFocus
                value={value}
                handleKeyDown={handleKeyDown}
                onChange={(value) => {
                  onChange(value);
                  setComment(value);
                }}
                onChangeText={(value) => setCommentText(value)}
              />
              <Error data-hidden={!error?.message}>{error?.message}</Error>
            </>
          )}
        />
      </InputWrapper>

      <Footer>
        <ActionButton style={{ color: 'rgb(195,195,195)' }} onClick={onCancel}>
          Cancel
        </ActionButton>

        <ActionButton type="submit" form={formId}>
          {defaultValue ? 'Save' : 'Reply'}
        </ActionButton>
      </Footer>
    </form>
  );
};

const Input = styled(CommentInput)`
  position: relative;
  width: 100%;
  min-height: 9.6rem;
  max-height: 19.2rem;
  resize: vertical;
  box-sizing: border-box;
  border-radius: 0.8rem;
  font-family: Inter, sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.3px;
  line-height: 1.6rem;
  padding: 1.2rem 1.6rem;
  background-color: transparent;
  border: 1px solid #61616a;
  color: white;

  &::placeholder {
    font-size: 1.4rem;
  }

  &:focus {
    outline: none;
    border: 1px solid white;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--color-grayscale-manatee);
`;

const Error = styled.span`
  right: 0;
  height: 1.6rem;
  color: var(--color-error);
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  letter-spacing: -0.2px;
  line-height: 1.6rem;
  text-align: right;
  display: flex;
  justify-content: flex-end;

  &[data-hidden='true'] {
    visibility: hidden;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin: 1.6rem 0 0.4rem;
`;

const ActionButton = styled.button`
  all: unset;
  font-size: 1.4rem;

  &:hover {
    color: var(--text-color-white);
    transition: color 100ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
  }

  &:first-child {
    margin-right: 0.8rem;
  }

  &:last-child {
    margin-left: 0.8rem;
  }
`;
