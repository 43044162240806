import merge from 'lodash.merge';
import { action, computed, makeObservable } from 'mobx';

import { ManyToOne } from '../../core/engine/decorators';
import { api } from '../../api';
import { catchError } from '../../core/catch-error';
import { getFullName } from '../../lib/utils/get-full-name';
import { getNameInitials } from '../../lib/utils/get-name-initials';
import { Model } from '../../core/engine/model';

import type { Space } from './space';
import type { User } from './user';
import { Step } from './step';
import { DEFAULT_STORYBOARD_SETTINGS } from '../../features/storyboards/default-settings';

export type StoryboardDisplayedProperties = Record<Partial<StoryBoardSettingsKeys>, boolean>;

export type StoryBoardSettingsKeys =
  | 'angle'
  | 'movement'
  | 'shotSize'
  | 'shotNumber'
  | 'lens'
  | 'light'
  | 'props'
  | 'estimatedTime'
  | 'fps'
  | 'locationId'
  | 'characters'
  | 'voiceOver'
  | 'directorsNote';

export class Member extends Model {
  displayName: string;
  userId: string;
  role: string;
  email: string;
  insertedAt: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  status?: string;
  membershipRequested: boolean;

  preferences: {
    storyBoardPreferences: {
      zoom: number;
      displayedProperties?: StoryboardDisplayedProperties;
    };
    projectsDisplayLayout?: 'list' | 'grid';
    stepsUI: Record<
      string,
      {
        displayLayout?: 'list' | 'grid';
        order?: string;
        showHiddenShots?: boolean;
      }
    >;
  };

  spaceId: string;

  @ManyToOne('members')
  user: User;

  @ManyToOne('members')
  space: Space;

  constructor() {
    super('members');

    this.membershipRequested = false;
    this.preferences = {
      stepsUI: {},
      projectsDisplayLayout: 'grid',
      storyBoardPreferences: {
        zoom: 1,
        displayedProperties: DEFAULT_STORYBOARD_SETTINGS.properties.reduce(
          (acc, property) => ({
            ...acc,
            [property.id]: true,
          }),
          {},
        ) as StoryboardDisplayedProperties,
      },
    };

    makeObservable(this, {
      displayName: true,
      role: true,
      email: true,
      firstName: true,
      lastName: true,
      avatar: true,
      status: true,
      user: true,
      space: true,
      preferences: true,
      requestMembership: action,
      fullName: computed,
      initials: computed,
      toggleSetting: action,
      showAllSettings: action,
      hideAllSettings: action,
      toggleProjectsDisplayLayout: action,
      toggleShowHiddenShots: action,
    });
  }

  get fullName() {
    return getFullName(this);
  }

  get initials() {
    return getNameInitials(this);
  }

  async update(values: Partial<Omit<this, '_id'>>): Promise<void> {
    await api.patch(`/spaces/${this.spaceId}/members/${this._id}`, values);
    merge(this, values);
  }

  toggleShowHiddenShots(stepId: string) {
    const stepPreferences = this.preferences.stepsUI?.[stepId];
    const newPreferences = { ...this.preferences.stepsUI };

    if (!stepPreferences) {
      newPreferences[stepId] = {
        showHiddenShots: false,
      };
    } else {
      newPreferences[stepId].showHiddenShots = stepPreferences.showHiddenShots === false;
    }

    this.update({
      preferences: {
        ...this.preferences,
        stepsUI: newPreferences,
      },
    } as Partial<Omit<this, '_id'>>);
  }

  toggleProjectsDisplayLayout(): void {
    const newLayout = this.preferences.projectsDisplayLayout === 'list' ? 'grid' : 'list';
    this.update({
      preferences: {
        ...this.preferences,
        projectsDisplayLayout: newLayout,
      },
    } as Partial<Omit<this, '_id'>>);
  }

  updateStepLayout(step: Step, layout: 'list' | 'grid'): Promise<void> {
    return this.update({
      preferences: {
        ...this.preferences,
        stepsUI: {
          ...this.preferences?.stepsUI,
          [step._id]: {
            ...this.preferences?.stepsUI?.[step._id],
            displayLayout: layout,
          },
        },
      },
    } as Partial<Omit<this, '_id'>>);
  }

  async delete(): Promise<void> {
    return super.delete(`/spaces/${this.spaceId}/members/${this._id}`);
  }

  async requestMembership(): Promise<void> {
    try {
      const { data } = await api.get(
        `/spaces/${this.spaceId}/members/${this._id}/request-membership`,
      );
      this.membershipRequested = data.membershipRequested;
    } catch (e) {
      catchError(e);
    }
  }

  async promote(): Promise<void> {
    try {
      const { data } = await api.get(`/spaces/${this.spaceId}/members/${this._id}/promote`);
      this.membershipRequested = data.membershipRequested;
      this.role = data.role;
    } catch (e) {
      catchError(e);
    }
  }

  static getAll(): Member[] {
    return Model.getAll().filter((model) => model instanceof Member) as Member[];
  }

  toPOJO(): Record<string, any> {
    return {};
  }

  changeZoomLevel(zoomLevel: number) {
    if (zoomLevel < 1 || zoomLevel > 6) return;

    this.update({
      preferences: {
        ...this.preferences,
        storyBoardPreferences: {
          ...this.preferences.storyBoardPreferences,
          zoom: zoomLevel,
        },
      },
    } as Partial<Omit<this, '_id'>>);
  }

  toggleSetting(propertyId: string) {
    let settings = this.preferences.storyBoardPreferences?.displayedProperties;

    if (!settings) {
      settings = DEFAULT_STORYBOARD_SETTINGS.properties.reduce(
        (acc, property) => ({
          ...acc,
          [property.id]: true,
        }),
        {},
      ) as StoryboardDisplayedProperties;
    }

    this.update({
      preferences: {
        ...this.preferences,
        storyBoardPreferences: {
          ...this.preferences.storyBoardPreferences,
          displayedProperties: {
            ...settings,
            [propertyId]: !settings[propertyId as keyof typeof settings],
          },
        },
      },
    } as Partial<Omit<this, '_id'>>);
  }

  showAllSettings() {
    this.preferences.storyBoardPreferences.displayedProperties =
      DEFAULT_STORYBOARD_SETTINGS.properties.reduce(
        (acc, property) => ({
          ...acc,
          [property.id]: true,
        }),
        {},
      ) as StoryboardDisplayedProperties;

    this.update({ preferences: this.preferences } as Partial<Omit<this, '_id'>>);
  }

  hideAllSettings() {
    this.preferences.storyBoardPreferences.displayedProperties =
      DEFAULT_STORYBOARD_SETTINGS.properties.reduce(
        (acc, property) => ({
          ...acc,
          [property.id]: false,
        }),
        {},
      ) as StoryboardDisplayedProperties;

    this.update({ preferences: this.preferences } as Partial<Omit<this, '_id'>>);
  }

  changeStepOrder(step: Step, sortBy: string) {
    const stepSettings = this.preferences.stepsUI?.[step._id];

    if (!stepSettings) {
      this.preferences.stepsUI[step._id] = {};
    }

    return this.update({
      preferences: {
        ...this.preferences,
        stepsUI: {
          ...this.preferences.stepsUI,
          [step._id]: {
            ...this.preferences.stepsUI[step._id],
            order: sortBy,
          },
        },
      },
    } as Partial<Omit<this, '_id'>>);
  }
}
