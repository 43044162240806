import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { ElevatedButton } from '../../components/buttons';
import { Card } from '../../components/on-boarding/Card';
import { Form } from './components/form';
import { TextInput } from '../../components/text-input/TextInput';
import { updateCurrentUser } from '../../features/user/user.slice';
import { authStore } from '../../core/stores/auth-store';

type FormValues = {
  firstName: string;
  lastName: string;
};

const schema = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^(.*)?\S+(.*)?$/, 'Not valid name')
    .required('Field is required')
    .min(1)
    .max(64, 'Name too long'),
  lastName: yup
    .string()
    .matches(/^(.*)?\S+(.*)?$/, 'Not valid name')
    .required('Field is required')
    .min(1)
    .max(64, 'Name too long'),
});

export const UpdateProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation() as { state: { spaceId?: string } };
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, formState } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleOnSubmit = async (values: FormValues) => {
    if (isLoading) return;
    setIsLoading(true);

    const currentUser = authStore.currentUser;

    try {
      await updateCurrentUser({ _id: currentUser!._id, ...values });
      setIsLoading(false);
      navigate('/onboarding/create-workspace', {
        state: {
          ...location.state,
          firstName: values.firstName,
          lastName: values.lastName,
        },
      });
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <Card
      title="Let's start with your name!"
      footer={
        <ElevatedButton
          text="Next"
          isLoading={isLoading}
          isFullWidth
          type="submit"
          form="profile-form"
        />
      }
    >
      <Form id="profile-form" onSubmit={handleSubmit(handleOnSubmit)}>
        <TextInput
          {...register('firstName', { required: true })}
          label="First name"
          placeholder={'Ex. John'}
          errorMessage={formState.errors.firstName?.message}
          required
        />

        <TextInput
          {...register('lastName', { required: true })}
          label="Last name"
          placeholder={'Ex. Doe'}
          errorMessage={formState.errors.lastName?.message}
          required
        />
      </Form>
    </Card>
  );
};
