import { useEffect, useState } from 'react';
import { api } from '../../api';

export const useLiveChat = () => {
  const [showChat, setShowChat] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);

  const openChat = () => {
    window.zE('messenger', 'open');
    setShowChat(true);
  };

  const closeChat = () => {
    window.zE('messenger', 'close');
    setShowChat(false);
  };

  const handleUnreadMessages = (count: number) => {
    setUnreadMessages(count);
  };

  const toggleChat = () => {
    if (showChat) {
      closeChat();
    } else {
      openChat();
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=d78f2c86-887c-4733-b58d-a1245a04fb04';
    script.id = 'ze-snippet';
    script.async = true;

    script.addEventListener('load', () => {
      window.zE('messenger', 'loginUser', (callback: (token: string) => void) => {
        api.get('/externals/livechat/visitors/identify').then(({ data }) => {
          callback(data.token);
        });
      });

      window.zE('messenger:on', 'unreadMessages', handleUnreadMessages);
    });

    document.body.appendChild(script);
  }, []);

  return { openChat, closeChat, toggleChat, unreadMessages };
};
