import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { breakPoint } from '../theme';

type HeaderProps = React.PropsWithChildren<{
  title?: string;
}>;

export const Header: React.FC<PropsWithChildren<HeaderProps>> = ({ title, children }) => {
  return (
    <HeaderItem>
      <Title>{title}</Title>

      <HeaderItems>{children}</HeaderItems>
    </HeaderItem>
  );
};

const HeaderItem = styled.div`
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 4.8rem;
  padding: 0 1.6rem;
  background: var(--color-grayscale-shark, #1d1f24);

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: flex;
  }
`;

const Title = styled.h1`
  font-family: Inter, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 4.2rem;
  color: white;
`;

const HeaderItems = styled.div`
  display: flex;
  gap: 1.6rem;
`;
