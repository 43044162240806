import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Pencil1Icon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { PageLoader } from '../../../components/page-loader/PageLoader';
import { CharacterCards } from '../components/character-cards';
import { CharacterListPlaceholder } from '../components/character-list-placeholder';
import { ScreenHeader } from '../../projects/components/screen-header';
import { ScreenContainer } from '../../projects/components/styled-project-components';
import { IconButton } from '../../../components/buttons';
import { Plus } from '../../../components/icons';
import { entityPool } from '../../../core/engine/engine';
import { Project } from '../../../app/entities/project';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { RenameStepModal } from '../../process/components/rename-step.modal';
import { CreateCharacterDrawer } from './create-character.screen';

import type { ScreenProps } from '../../projects/models/types';
import { StepDrawer } from '../../projects/components/step.drawer';

export const CharacterListScreen: React.FC<ScreenProps> = observer(
  ({ projectId, step, isLoading }) => {
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    const project = entityPool.getEntity<Project>(projectId)!;

    const hasCharacters = !!project.characters.length;

    const items = [
      {
        title: 'Rename step',
        icon: <Pencil1Icon />,
        onSelect: () => setIsRenameModalOpen(true),
      },
    ];

    return (
      <React.Fragment>
        <ScreenHeader
          stepId={step._id}
          title={step.name}
          dueDate={step.dueDate}
          startDate={step.startDate}
          handleUpdateTimeFrame={(values) => step.update(values)}
          menu={<DropdownMenu items={items} size={'large'} />}
          actions={[
            <IconButton key="add" onClick={() => setIsCreateModalOpen(true)} icon={<Plus />} />,
          ]}
        />

        <StepDrawer step={step} />

        {isLoading ? (
          <LoaderContainer>
            <PageLoader />
          </LoaderContainer>
        ) : hasCharacters ? (
          <ScreenContainer>
            <CharacterCards characters={project.characters} />
          </ScreenContainer>
        ) : (
          <CharacterListPlaceholder onAddCharacterClicked={() => setIsCreateModalOpen(true)} />
        )}

        <CreateCharacterDrawer
          projectId={project._id}
          isOpen={isCreateModalOpen}
          onCancel={() => setIsCreateModalOpen(false)}
        />

        {isRenameModalOpen && (
          <RenameStepModal step={step} onCancel={() => setIsRenameModalOpen(false)} />
        )}
      </React.Fragment>
    );
  },
);

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  height: 61vh;
`;
