import React from 'react';
import styled from 'styled-components';

import { ArrowLeft } from '../../../components/icons/arrow-left';

interface BackButtonProps {
  handleOnClick?: () => void;
  label: string;
}

export const BackButton: React.FC<BackButtonProps> = ({ label, handleOnClick }) => {
  return (
    <Container onClick={handleOnClick}>
      <ArrowLeft />
      <Label>{label}</Label>
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  gap: 0.4rem;
  justify-content: start;
  align-items: center;
  cursor: pointer;
`;

export const Label = styled.h6`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.7rem;
  color: var(--white-default);
`;
