import React from 'react';
import styled from 'styled-components';

interface ProgressProps {
  percentage: number;
}

export const Progress: React.FC<ProgressProps> = ({ percentage }) => (
  <ProgressContainer>
    <ProgressInset>
      <ProgressBar percentage={percentage} />
    </ProgressInset>
  </ProgressContainer>
);

interface ProgressBarProps {
  percentage: number;
}

const ProgressContainer = styled.div`
  width: 100%;
`;

const ProgressInset = styled.div`
  border-radius: var(--border-radius-medium);
  width: 100%;
  background: #313236;
  display: flex;
  align-items: center;
`;

const ProgressBar = styled.div<ProgressBarProps>`
  width: ${({ percentage }) => percentage}%;
  height: 0.6rem;
  border-radius: 0.4rem;
  background-color: var(--color-secondary);
`;
