import React from 'react';

import { Stripboard } from '../../../app/entities/stripboard';
import { SplitButton } from '../../../components/split-button/split-button';
import { Plus } from '../../../components/icons';

type AddLineProps = {
  stripboard: Stripboard;
  targetPosition?: number;
};

export const bannerItems = [
  {
    title: 'Setup',
    estimatedTime: 90,
  },
  {
    title: 'Coffee',
    estimatedTime: 5,
  },
  {
    title: 'Company move',
    estimatedTime: 90,
  },
  {
    title: 'Lunch time',
    estimatedTime: 60,
  },
  {
    title: 'Thats a wrap',
    estimatedTime: 10,
  },
  {
    title: 'Packup',
    estimatedTime: 90,
  },
  {
    title: 'Custom',
    estimatedTime: 0,
  },
];

export const AddLine: React.FC<AddLineProps> = ({ stripboard, targetPosition = 1 }) => {
  const handleSelect = ({ title, estimatedTime }: any) => {
    stripboard.addBannerStrip({
      position: targetPosition,
      text: title,
      estimatedTime,
    });
  };

  return (
    <SplitButton
      text="Add Line"
      icon={<Plus />}
      items={bannerItems.map((item) => ({ ...item, onSelect: () => handleSelect(item) }))}
    />
  );
};
