import React from 'react';
import { useMsal } from '@azure/msal-react';
import styled from 'styled-components';

import { Card } from '../../../components/on-boarding/Card';
import { ElevatedButton } from '../../../components/buttons';
import { Note } from '../../../components/note/note';
import { OutlookLogoIcon } from 'components/icons/outlook-logo';

export const MicrosoftAuth: React.FC = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType: 'popup' | 'redirect') => {
    if (loginType === 'popup') {
      instance.loginPopup({ scopes: ['User.Read'] }).catch((e) => {
        console.log(e);
      });
    } else if (loginType === 'redirect') {
      instance.loginRedirect({ scopes: ['User.Read'] }).catch((e) => {
        console.log(e);
      });
    }
  };

  return (
    <Container>
      <Card title="Sing in to PRODUCER">
        <Note>Microsoft has to be connected to activate subscription</Note>
        <ElevatedButton
          style={{ margin: '1.6rem 0' }}
          text="Connect with Microsoft"
          onClick={() => handleLogin('redirect')}
          isFullWidth
          type="button"
          icon={<OutlookLogoIcon />}
        />
      </Card>
    </Container>
  );
};

const Container = styled.div`
  div {
    & + div:last-child {
      padding: 2.4rem 1.6rem;
    }
  }

  .note-container {
    background: var(--color-error-20);
    border: 1px solid var(--color-error);

    svg path {
      fill: var(--color-error);
    }
  }

  button {
    color: var(--color-grayscale-woodsmoke) !important;
    background-color: var(--color-grayscale-mischka) !important;
    margin-top: 0.8rem !important;
    margin-bottom: unset !important;

    svg {
      path {
        stroke: none;
        fill-rule: nonzero;
        fill: var(--color-grayscale-tuna);
        fill-opacity: 1;
      }
    }
  }
`;
