import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { BaseDialog, BaseDialogProps, DialogHeader } from '../../../components/dialog';
import { ElevatedButton } from '../../../components/buttons';
import { ShareLink } from '../../../app/entities/share-link';
import { Switch } from '../../../components/toggle/Switch';
import { createShareLink } from '../share.slice';
import { ShareableLink } from './shareable-link';
import { ContactsSelector } from './contacts-selector';
import { Space } from '../../../app/entities/space';
import { toastPool } from '../../toasts/models/toast-pool';
import { Toast } from '../../toasts/models/toast';
import { InteractableTextInput } from '../../../components/interactable-text-input/interactable-text-input';
import { Error } from '../../../components/Input/styled-input';

import type { SelectOption } from '../../../components/select';
import { catchError } from '../../../core/catch-error';

type ShareModalProps = BaseDialogProps & {
  spaceId: string;
  projectId: string;
  elementIds: string[];
  title?: string;
  onClose: () => void;
};

export const ShareModal: React.FC<ShareModalProps> = observer(
  ({ spaceId, projectId, title, elementIds, onClose }) => {
    const { t } = useTranslation('common');

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [linkId, setLinkId] = useState<string>();
    const [recipients, setRecipients] = useState<SelectOption[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>();

    const contacts = Space.getOne(spaceId)?.contacts ?? [];
    const link = linkId ? ShareLink.getOne(linkId) : undefined;

    const options = contacts
      ?.filter((contact) => contact.email)
      ?.map((contact) => {
        const { firstName, lastName, email } = contact;

        return {
          value: email,
          label: firstName || lastName ? `${firstName} ${lastName}` : email,
        };
      });

    useEffect(() => {
      if (!errorMessage) {
        return;
      }

      setTimeout(() => {
        setErrorMessage('');
      }, 3100);
    }, [errorMessage]);

    useEffect(() => {
      createShareLink({
        projectId,
        assetIds: elementIds,
        spaceId,
      }).then((link) => {
        setLinkId(link._id);
      });
      // TODO For some reason depending on elementIds causes infinite loop
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, spaceId]);

    const handleSendInvites = async () => {
      setIsLoading(true);
      const emails = recipients.map((recipient) => recipient.value);

      await link!
        .update({ recipients: emails })
        .then(() => {
          toastPool.insert(
            new Toast(`Share link sent to ${t('personWithCount', { count: emails.length })}.`),
          );
          setIsLoading(false);
          onClose();
        })
        .catch((e) => {
          catchError(e);
          // TODO: Display error message when error model is ready instead of hard coded message
          setIsLoading(false);
        });
    };

    const handleOnClose = () => {
      onClose();
    };

    const handleNameChange = async (value: string) => {
      if (!value) return;

      await link!.update({ name: value });
    };

    if (!link) {
      return null;
    }

    const shareTitle =
      title ||
      (elementIds?.length && `Sharing ${t('itemWithCount', { count: elementIds.length })}`);

    return (
      <BaseDialog onClose={handleOnClose}>
        <DialogHeader title={shareTitle} onClose={handleOnClose} />

        <Container>
          <Content>
            <InteractableTextInput
              label="Link name"
              helpText="This is used internally for you to be able to identify this link from others."
              defaultValue={link.name}
              onSave={handleNameChange}
            />

            <DownloadSettings>
              <Label>Allow people to download the files</Label>

              <Switch
                onToggle={(canDownload) => link.update({ canDownload })}
                isToggled={link.settings?.canDownload}
                size="medium"
              />
            </DownloadSettings>
          </Content>

          {link && (
            <Content>
              <InputContainer>
                <Label>Share via link</Label>

                <ShareableLink url={link.url} />
              </InputContainer>

              <InputContainer>
                <Label>Share via email</Label>

                <ContactsSelector
                  contacts={options}
                  recipients={recipients}
                  setRecipients={setRecipients}
                  isLoading={false}
                  onError={setErrorMessage}
                />

                {errorMessage && <Error>{errorMessage}</Error>}
              </InputContainer>

              <ActionContainer>
                <ElevatedButton
                  key="Share"
                  text="Share"
                  disabled={!recipients.length}
                  onClick={handleSendInvites}
                  isLoading={isLoading}
                />
              </ActionContainer>
            </Content>
          )}
        </Container>
      </BaseDialog>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1.6rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  &:first-child {
    padding-bottom: 1.6rem;
    border-bottom: 1px solid var(--color-grayscale-tuna);
  }
`;

const Label = styled.div`
  color: var(--color-grayscale-white);
  font-size: 1.4rem;
  font-weight: 400;
`;

const DownloadSettings = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  position: relative;

  & > ${Error} {
    display: inline-block;
    animation: fade-out 3s forwards;
    bottom: -1.8rem;

    @keyframes fade-out {
      0% {
        opacity: 1;
      }
      90% {
        opacity: 1;
      }
      100% {
        display: none;
        opacity: 0;
      }
    }
  }
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.8rem;
`;
