import React, { useState } from 'react';
import styled from 'styled-components';
import { TextButton, ElevatedButton } from '../../../components/buttons';

import { BaseDialog } from '../../../components/dialog';
import { DialogHeader } from '../../../components/dialog';
import { DialogFooter } from '../../../components/dialog';
import { Stripboard } from '../../../app/entities/stripboard';

type RenumberShotsDialogProps = {
  stripboard: Stripboard;
  onCancel: () => void;
};

export const RenumberShotsDialog: React.FC<RenumberShotsDialogProps> = ({
  stripboard,
  onCancel,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onHandleConfirm = async () => {
    setIsLoading(true);

    try {
      await stripboard.renumberShots();
    } finally {
      onCancel();
    }

    setIsLoading(false);
  };

  return (
    <BaseDialog onClose={onCancel}>
      <DialogHeader title="Renumber shots" />

      <Container>
        Reorder Shot List numbering to become the new source of truth, to match the visuals of the
        Storyboard.
      </Container>

      <DialogFooter
        actions={[
          <TextButton key="cancel" text="Cancel" onClick={onCancel} />,
          <ElevatedButton
            key="re-number"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onHandleConfirm}
            text="Yes, confirm"
            variant="danger"
          />,
        ]}
      />
    </BaseDialog>
  );
};

const Container = styled.div`
  padding: 1.6rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  max-width: 50rem;
  color: #7a8296;
`;
