import React, { useState } from 'react';
import styled from 'styled-components';

import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import { Dot } from './step-status-selector';

import { Step, type StepStatus } from '../../../app/entities/step';

type StatusSelectorProps = {
  onChange?: (newStatus: StepStatus) => void;
  status?: StepStatus;
  isViewOnly?: boolean;
};

export const StatusSelector: React.FC<StatusSelectorProps> = ({
  onChange,
  status = 'not-started',
  isViewOnly,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpenChanged = (value: boolean) => {
    setIsOpen(value);
  };

  return (
    <>
      {isViewOnly ? (
        <Tooltip text={Step.StatusMapper[status].title}>
          <Next className="status-indicator" data-status={status} data-isOpen={isOpen} disabled>
            <Dot data-status={status} />
          </Next>
        </Tooltip>
      ) : (
        <DropdownMenu
          onOpenChange={onOpenChanged}
          items={Object.entries(Step.StatusMapper).map(([key, { title }]) => ({
            title,
            onSelect: () => onChange?.(key as StepStatus),
            icon: <Dot data-status={key as StepStatus} />,
          }))}
          align="start"
          trigger={
            <Next className="status-indicator" data-status={status} data-isOpen={isOpen}>
              <Dot data-status={status} />
            </Next>
          }
        />
      )}
    </>
  );
};

const Next = styled.button`
  display: flex;
  position: relative;
  margin-right: 0.8rem;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  min-width: 1.8rem;
  transition: all 0.3s ease;
  border-radius: 0.4rem;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  outline: none;
  background-color: transparent;

  &:not(:disabled) {
    cursor: pointer;
  }

  &::after {
    content: '';
    position: absolute;
    display: none;
    border-radius: 0.4rem;
    height: calc(100% + 0.8rem);
    width: calc(100% + 0.8rem);
    border: 1px solid var(--color-grayscale-trout);
  }

  &[data-isOpen='true']::after {
    display: block;
  }

  &:not(:disabled):hover,
  &[data-isOpen='true'] {
    &[data-status='not-started'] {
      border-color: var(--color-grayscale-white, #fafafa);
      background-color: #fafafa33;
    }

    &[data-status='on-hold'] {
      border-color: var(--color-grayscale-light-slate, #7a8296);
      background-color: #7a829633;
    }

    &[data-status='in-progress'] {
      border-color: var(--color-primary-crayola, #367bff);
      background-color: #367bff33;
    }

    &[data-status='needs-review'] {
      border-color: var(--color-secondary-saffron, #f8a72d);
      background-color: #f8a72d33;
    }

    &[data-status='changes-requested'] {
      border-color: var(--color-secondary-roman, #db5c66);
      background-color: #db5c6633;
    }

    &[data-status='approved'] {
      border-color: var(--color-primary-puerto-rico, #38c2b8);
      background-color: #38c2b833;
    }
  }
`;
