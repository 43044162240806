import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';

import { BaseDialog, DialogFooter, DialogHeader } from '../dialog';
import { ElevatedButton, OutlinedButton } from '../buttons';
import { breakPoint } from '../../app/theme';

export type ShotsBulkUpdateModalProps = {
  shotsCount: number;
  onUpdate: () => void;
  onCancel: () => void;
};

export const ShotsBulkUpdateModal: React.FC<ShotsBulkUpdateModalProps> = ({
  shotsCount = 1,
  onUpdate,
  onCancel,
}) => {
  const [isBulkUpdatePermitted, setIsBulkUpdatePermitted] = useState(false);

  useEffect(() => {
    const isChecked = JSON.parse(localStorage.getItem('isBulkUpdatePermitted') || 'false');
    setIsBulkUpdatePermitted(isChecked);
  }, []);

  const handleCheckboxChange = (checked: boolean) => {
    setIsBulkUpdatePermitted(checked);
  };

  const handleUpdate = () => {
    localStorage.setItem('isBulkUpdatePermitted', JSON.stringify(isBulkUpdatePermitted));
    onUpdate();
    onCancel();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <BaseDialog onClose={onCancel}>
      <DialogHeader title="Editing multiple shots" />

      <Content>
        You are about to edit multiple selected shots at once. This action cannot be undone.
      </Content>

      <DialogFooterWrapper>
        <DialogFooter
          actions={[
            <CheckboxContainer>
              <Checkbox.Root
                className="checkbox-root"
                onCheckedChange={handleCheckboxChange}
                defaultChecked={isBulkUpdatePermitted}
                id="bulk-update-checkbox"
              >
                <Checkbox.Indicator className="checkbox-indicator">
                  <CheckIcon />
                </Checkbox.Indicator>
              </Checkbox.Root>

              <label htmlFor="bulk-update-checkbox">Don't show again</label>
            </CheckboxContainer>,
            <ButtonsWrapper>
              <OutlinedButton key="cancel" text="Cancel" onClick={handleCancel} />,
              <ElevatedButton
                key="update"
                onClick={handleUpdate}
                text={`Edit ${shotsCount} shots`}
                autoFocus
              />
            </ButtonsWrapper>,
          ]}
        />
      </DialogFooterWrapper>
    </BaseDialog>
  );
};

const Content = styled.div`
  padding: 1.6rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  color: var(--color-grayscale-light-slate);
  white-space: pre-line;

  @media screen and (min-width: ${breakPoint.medium}px) {
    max-width: 38rem;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.6rem;
  align-self: flex-start;

  @media screen and (min-width: ${breakPoint.small}px) {
    margin-top: unset;
  }

  button {
    all: unset;
  }

  .checkbox-root {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 1.1rem;
    height: 1.1rem;
    background-color: transparent;
    border-radius: 1px;
    box-shadow: 0 0 0 1px var(--color-primary-crayola);
  }

  .checkbox-indicator {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 5.13px;
    height: 4.75px;
    color: var(--color-primary-crayola);
  }

  label {
    color: var(--color-grayscale-light-slate);
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 2rem;
    user-select: none;
  }

  @media screen and (min-width: ${breakPoint.small}px) {
    align-self: unset;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;

  button {
    box-sizing: border-box;
  }

  button:first-child {
    border: none;

    &:hover {
      background-color: transparent;
      color: var(--color-grayscale-light-slate);
    }
  }
`;

const DialogFooterWrapper = styled.div`
  display: flex;
  align-content: center;
  padding-bottom: 1.6rem;
  height: 10.5rem;

  @media screen and (min-width: ${breakPoint.small}px) {
    height: 6.5rem;
    padding-bottom: unset;
  }

  footer {
    width: 100%;

    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @media screen and (min-width: ${breakPoint.small}px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
`;
