import React from 'react';

export const Lock = () => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8625 5.89375H3.4875V4.4875C3.4875 2.725 4.98125 1.3375 6.8875 1.325C8.75625 1.3375 10.1563 2.6875 10.1563 4.4875V5.1875C10.1563 5.38125 10.3125 5.5375 10.5063 5.5375C10.7 5.5375 10.8563 5.38125 10.8563 5.1875V4.4875C10.8563 2.3 9.1625 0.64375 6.90625 0.625C6.89375 0.625 6.875 0.625 6.8625 0.625C4.56875 0.64375 2.78125 2.3375 2.78125 4.4875V5.925C1.59375 6.09375 0.675003 7.1125 0.675003 8.35V12.9125C0.675003 14.2687 1.78125 15.3687 3.13125 15.3687H10.8563C12.2125 15.3687 13.3125 14.2625 13.3125 12.9125V8.34375C13.325 6.99375 12.2188 5.89375 10.8625 5.89375ZM12.6188 12.9187C12.6188 13.8875 11.8313 14.675 10.8625 14.675H3.1375C2.16875 14.675 1.38125 13.8875 1.38125 12.9187V8.35C1.38125 7.38125 2.16875 6.59375 3.1375 6.59375H10.8625C11.8313 6.59375 12.6188 7.38125 12.6188 8.35V12.9187ZM7 8C5.8375 8 4.89375 8.94375 4.89375 10.1062C4.89375 11.15 5.65625 12.0125 6.65625 12.1812C6.65625 12.1937 6.65 12.2062 6.65 12.2187V13.275C6.65 13.4687 6.80625 13.625 7 13.625C7.19375 13.625 7.35 13.4687 7.35 13.275V12.2187C7.35 12.2062 7.34375 12.1937 7.34375 12.1812C8.34375 12.0187 9.10625 11.1562 9.10625 10.1062C9.10625 8.94375 8.1625 8 7 8ZM7 11.5125C6.225 11.5125 5.59375 10.8812 5.59375 10.1062C5.59375 9.33125 6.225 8.7 7 8.7C7.775 8.7 8.40625 9.33125 8.40625 10.1062C8.40625 10.8812 7.775 11.5125 7 11.5125Z"
      fill="#E6EDF3"
    />
  </svg>
);
