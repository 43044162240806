import React from 'react';

export const ArrowDown = () => (
  <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.9697 1.48528L9.39909 9.8L0.999091 1.48528"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
