import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Pencil1Icon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { ScreenHeader } from '../../projects/components/screen-header';
import { RenameStepModal } from '../../process/components/rename-step.modal';

import type { Step } from '../../../app/entities/step';

type KeyContactHeaderProps = {
  step: Step;
};

export const KeyContactHeader: React.FC<KeyContactHeaderProps> = observer(({ step }) => {
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);

  const items: React.ComponentProps<typeof DropdownMenu>['items'] = [
    {
      title: 'Rename step',
      icon: <Pencil1Icon />,
      onSelect: () => setIsRenameModalOpen(true),
    },
  ];

  return (
    <>
      <ScreenHeader
        stepId={step._id}
        title={step.name}
        dueDate={step.dueDate}
        startDate={step.startDate}
        handleUpdateTimeFrame={(values) => step.update(values)}
        menu={<DropdownMenu items={items} size={'large'} />}
      />

      {isRenameModalOpen && (
        <RenameStepModal step={step} onCancel={() => setIsRenameModalOpen(false)} />
      )}
    </>
  );
});
