import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { Avatar } from '@producer-io/ui-kit';

import { breakPoint } from '../theme';
import { SpaceMenu } from './space-menu';
import { SpaceSelector } from './space-selector';
import { ProducerLogo } from '../../components/logo/producer-logo';

import type { Space } from '../entities/space';
import type { User } from '../entities/user';

interface DrawerProps {
  /**
   * The current logged in user
   */
  currentUser: User;
  /**
   * The current space
   */
  space: Space;

  /**
   * Is the drawer closed or open
   * Only works on mobile
   */
  isOpen: boolean;

  /**
   * Close the drawer
   */
  onClose: () => void;

  /**
   * True if the payment due banner is displayed
   */
  hasBanner: boolean;
}

export const Drawer: React.FC<DrawerProps> = ({
  currentUser,
  space,
  isOpen,
  onClose,
  hasBanner,
}) => {
  const navigate = useNavigate();

  return (
    <Container data-is-open={isOpen} data-has-banner={hasBanner}>
      <Content>
        <Logo>
          <ProducerLogo />
        </Logo>

        <SpaceSelector space={space} isOpen={isOpen} onClose={onClose} />

        <SpaceMenu space={space} onClose={onClose} />

        <UserWrapper
          onClick={() => {
            navigate(`/space/${space._id}/account`, { state: { prevPath: location.pathname } });
          }}
        >
          <CurrentUserDiv>
            <Avatar
              size="medium"
              src={currentUser.avatar}
              initials={currentUser.initials}
              theme="dark"
            />
            <UserNameEmailWrapper>
              <span>{currentUser.fullName}</span>
              <span>{currentUser.email}</span>
            </UserNameEmailWrapper>
          </CurrentUserDiv>
          <IconWrapper>
            <ChevronRightIcon color="var(--color-grayscale-light-slate, #7A8296)" />
          </IconWrapper>
        </UserWrapper>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: var(--tab-bar-height);
  background: var(--color-grayscale-shark, #1d1f24);
  display: flex;
  flex-direction: column;
  width: var(--drawer-width-closed);
  height: calc(100% - var(--tab-bar-height));
  transition: all 200ms ease-in;
  z-index: 9999;

  transform: translateX(calc(-1 * var(--drawer-width-closed)));

  &[data-is-open='true'] {
    transform: translateX(0);
  }

  &[data-has-banner='true'] {
    top: calc(var(--tab-bar-height) + 3.2rem);
    height: calc(100% - var(--tab-bar-height) - 3.2rem);
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    position: absolute;
    top: 0;
    height: 100%;
    transform: translateX(0);
    background: var(--color-grayscale-woodsmoke);

    &[data-has-banner='true'] {
      top: 3.2rem;
      height: calc(100% - 3.2rem);
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
`;

const Logo = styled.div`
  margin-top: 1.6rem;
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  order: 0;

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: flex;
  }

  svg {
    height: 2.4rem;
    fill: #ffffff;
  }
`;

const UserWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 1.6rem 3.2rem 1.6rem;
  background: var(--color-grayscale-charleston, #25272d);
  order: 3;
  cursor: pointer;

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: none;
  }
`;

const CurrentUserDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

const UserNameEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 3.2rem;

  span:first-child {
    color: var(--color-grayscale-white, #fafafa);
    font-size: 1.4rem;
    font-weight: 500;
  }

  span:last-child {
    color: var(--color-grayscale-shuttle, #626878);
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const IconWrapper = styled.div`
  padding: 0.4rem;
`;
