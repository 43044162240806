import React from 'react';
import styled from 'styled-components';
import { keycloak } from 'keycloak';
import { DownArrow } from '@producer-io/ui-kit';
import { ExitIcon } from '@radix-ui/react-icons';

import { authStore } from '../../../core/stores/auth-store';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';

export const UserDropdown: React.FC = () => {
  const user = authStore.currentUser;

  const logout = () => {
    const logoutUrl = keycloak.createLogoutUrl({ redirectUri: window.location.origin });
    const link = document.createElement('a');
    link.href = logoutUrl;
    link.click();
  };

  const options = [
    {
      title: 'Logout',
      onSelect: logout,
      icon: <ExitIcon />,
      type: 'danger' as const,
    },
  ];

  if (!user) {
    return null;
  }

  return (
    <DropdownMenu
      items={options}
      trigger={
        <Trigger>
          <Wrapper>
            <Label>Logged in as</Label>
            <Username>{user?.email}</Username>
          </Wrapper>

          <DownArrow />
        </Trigger>
      }
    />
  );
};

const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-grayscale-charleston);
  padding: 0.8rem;
  min-width: 18rem;
  border-radius: 0.4rem;

  & svg {
    stroke: var(--color-grayscale-shuttle);
  }

  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`;

const Label = styled.span`
  color: var(--color-grayscale-shuttle);
  leading-trim: both;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5rem;
`;

const Username = styled.span`
  color: var(--color-grayscale-white);
  leading-trim: both;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
