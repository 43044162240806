import { action, computed, makeObservable, runInAction } from 'mobx';

import { getFullName } from '../../lib/utils/get-full-name';
import { api } from '../../api';
import { entityPool } from '../../core/engine/engine';
import { Model } from '../../core/engine/model';
import { ManyToMany, ManyToOne, OneToMany } from '../../core/engine/decorators';
import { Candidate } from './candidate';

import type { Cast } from '../../features/character/models/cast';
import type { Shot } from './shot';
import type { Project } from './project';

export class Character extends Model {
  /** Character's name on screen **/
  name: string;

  description?: string;
  gender?: string;
  personality?: string;
  appearance?: string;
  characteristics?: string;

  @OneToMany('character')
  candidates: Candidate[] = [];

  cast?: Cast | null;

  cover?: {
    src: string;
  };

  /**
   * Only when listing all characters in a project
   */
  candidatesCount: number = 0;

  projectId: string;

  @ManyToOne('characters')
  project: Project | null = null;

  @ManyToMany('characters')
  shots: Shot[];

  constructor() {
    super('characters');

    this.shots = [];

    makeObservable(this, {
      name: true,
      description: true,
      gender: true,
      personality: true,
      appearance: true,
      characteristics: true,
      candidates: true,
      cast: true,
      cover: true,
      candidatesCount: true,
      projectId: true,
      removeCandidate: action,
      castName: computed,
    });
  }

  get castName() {
    if (!this.cast) {
      return '';
    }

    return getFullName(this.cast);
  }

  static getOne(id: string): Character | undefined {
    return Model.getOne(id) as Character;
  }

  async assignCast(candidateId: string | null) {
    const { data } = await api.post(`/characters/${this._id}/cast`, {
      candidateId,
    });

    runInAction(() => {
      this.cast = data;
    });
  }

  addCandidate = async (contactId: string) => {
    if (this.candidates?.some((candidate) => candidate.contactId === contactId)) return;
    const { data } = await api.post(`characters/${this._id}/candidates`, {
      contactId,
    });

    const candidate = Object.assign(new Candidate(), data, { characterId: this._id });
    this.candidatesCount++;

    entityPool.insert(candidate);
  };

  async removeCandidate(candidateId: string) {
    const candidate = Candidate.getOne(candidateId);

    if (!candidate) return;

    await api.delete(`/characters/${this._id}/candidates/${candidate._id}`);

    if (this.cast && this.cast.candidateId === candidateId) {
      this.cast = undefined;
    }

    this.candidatesCount--;

    entityPool.delete(candidate);
  }

  async update(input: Partial<Omit<this, '_id'>>) {
    await super.update(input);
    Object.assign(this, input);
  }

  toPOJO(): Record<string, any> {
    return {};
  }
}
