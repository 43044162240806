import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { authStore } from '../../core/stores/auth-store';
import { WarningDanger } from '../../components/icons';
import { breakPoint } from '../theme';

type PastDueSubscriptionBannerProps = {
  spaceId: string;
};

export const PastDueSubscriptionBanner: React.FC<PastDueSubscriptionBannerProps> = ({
  spaceId,
}) => {
  const currentMember = authStore.getCurrentMember(spaceId);

  return (
    <Banner>
      <WarningDanger />
      <Text>
        Your payment is overdue.{' '}
        <span>Update your payment method to avoid loosing access to your space.</span>
      </Text>

      {['owner', 'admin'].includes(currentMember?.role || '') && (
        <Link to={`/space/${spaceId}/settings/billings`}>Go to settings</Link>
      )}
    </Banner>
  );
};

const Banner = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  min-height: 3.2rem;
  background: var(--color-secondary-roman);

  & svg {
    width: 2rem;
    color: white;
  }

  & > a {
    position: absolute;
    right: 1.6rem;
    color: white;
    font-size: 1.4rem;
    margin: 0.8rem 0;

    &:hover {
      text-decoration: underline;
    }
  }

  & > div {
    display: flex;
    gap: 0.8rem;
    align-items: center;
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: flex;
    flex-direction: row;
  }
`;

const Text = styled.span`
  color: var(--color-grayscale-white);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.4rem;

  & > span {
    display: none;
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    & > span {
      display: inline;
    }
  }
`;
