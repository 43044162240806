import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { SortableStripList } from './sortable-strip-list';
import { SelectionProvider } from '../../../core/contexts/selection.context';
import { TableHeader, THead } from '../../../components/table/styled-shared-table';
import { Strip, DayBreakStrip } from '../models/types';
import { DayStartStrip } from './day-start-strip';

import type { Stripboard } from '../../../app/entities/stripboard';

type ShootingScheduleTableProps = {
  stripboard: Stripboard;
  strips: Array<Strip>;
  placeholder?: React.ReactNode;
};

export const ShootingScheduleTable: React.FC<ShootingScheduleTableProps> = observer(
  ({ stripboard, strips, placeholder }) => {
    const stripIds = strips?.map((strip) => strip._id);

    const dayBreakStripIds = strips
      ?.filter((strip) => strip.type === 'dayBreak')
      ?.map((strip) => strip._id);

    const firstDayBreakStrip =
      dayBreakStripIds?.length > 0
        ? (strips?.find((strip) => strip.type === 'dayBreak') as DayBreakStrip)
        : undefined;

    return (
      <Table>
        <col
          style={{
            width: '8.5%',
          }}
        />
        <col
          style={{
            width: '9.2%',
          }}
        />
        <col
          style={{
            width: '8.3%',
          }}
        />
        <col
          style={{
            width: '7.4%',
          }}
        />
        <col
          style={{
            width: '25.7%',
          }}
        />
        <col
          style={{
            width: '26.5%',
          }}
        />
        <col
          style={{
            width: '4.6%',
          }}
        />
        <col
          style={{
            width: '5.4%',
          }}
        />
        <col
          style={{
            width: '4.4%',
          }}
        />

        <TableHeader>
          <THead>Shot</THead>

          <THead>Location</THead>

          <THead>I/E</THead>

          <THead>D/N</THead>

          <THead>Title</THead>

          <THead>Characters</THead>

          <THead>Est. Time</THead>

          <THead>Time</THead>

          <THead />
        </TableHeader>

        <tbody>
          {firstDayBreakStrip && (
            <DayStartStrip stripboard={stripboard} strip={firstDayBreakStrip} />
          )}

          <SelectionProvider items={stripIds} excludedItems={dayBreakStripIds}>
            <SortableStripList stripboard={stripboard} strips={strips} placeholder={placeholder} />
          </SelectionProvider>
        </tbody>
      </Table>
    );
  },
);

const Table = styled.table`
  border-collapse: separate;
  width: 100%;
  border-spacing: 0 0.8rem;
  table-layout: fixed;
  height: 1px; // for td to have 100% height
`;
