import React from 'react';
import { observer } from 'mobx-react-lite';

import { CollapsibleItem } from '../../../components/collapsible-item/collapsible-item';
import { TableContainer } from '../../../components/table/styled-shared-table';
import { useResponsive } from '../../../hooks/useResponsive';
import { AdditionalInfoTable } from './additional-info-table';
import { MobileAdditionalInfoTable } from './additional-info-table.mobile';

import type { Project } from '../../../app/entities/project';
import type { AdditionalInfo } from './additional-info-table';

type AdditionalInfoListProps = {
  project: Project;
  shootingDayId: string;
};

export const AdditionalInfoList: React.FC<AdditionalInfoListProps> = observer(
  ({ project, shootingDayId }) => {
    const { isDesktop } = useResponsive();

    const additionalInfo: AdditionalInfo[] =
      project.shots
        .filter(
          (shot) =>
            !shot.isHidden &&
            shot.shootingDayId === shootingDayId &&
            (shot.props || shot.directorsNote),
        )
        .map((shot) => ({
          number: shot.order,
          props: shot.props,
          directorsNote: shot.directorsNote,
        }))
        .sort((a, b) => a.number - b.number) || [];

    if (!additionalInfo?.length) {
      return null;
    }

    return (
      <>
        {isDesktop ? (
          <CollapsibleItem title="Additional Information" defaultOpen>
            <TableContainer>
              <AdditionalInfoTable additionalInfo={additionalInfo} />
            </TableContainer>
          </CollapsibleItem>
        ) : (
          <MobileAdditionalInfoTable additionalInfo={additionalInfo} />
        )}
      </>
    );
  },
);
