import React from 'react';
import { observer } from 'mobx-react-lite';

import { sortBy } from '../../settings/helpers/sortBy';
import { SettingsTable } from '../../../components/settings-table/settings-table';
import { MemberItem } from '../../settings/components/member-item';
import { PagePlaceholder } from '../../../components/page-placeholder/page-placeholder';

import type { Space } from '../../../app/entities/space';

type GuestsTabProps = {
  space: Space;
};
export const GuestsTab: React.FC<GuestsTabProps> = observer(({ space }) => {
  const sortedByLatest = sortBy(
    space.members.filter((member) => member.role === 'guest'),
    'invitedAt',
    'desc',
  );

  const tableHeaders = ['', 'Full name', 'Email'];
  const hasGuests = !!sortedByLatest?.length;

  return (
    <SettingsTable
      headers={tableHeaders}
      isFullHeight={!hasGuests}
      hint="You can invite guests from the project overview. Guests can only access the projects they
        are invited to."
    >
      {hasGuests ? (
        sortedByLatest?.map((member) => <MemberItem key={member._id} member={member} />)
      ) : (
        <PagePlaceholder description="Guests will be displayed here" />
      )}
    </SettingsTable>
  );
});
