import React from 'react';

export const Project = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.57031 5.75C9.57031 5.33579 9.9061 5 10.3203 5H17.1775C17.5917 5 17.9275 5.33579 17.9275 5.75C17.9275 6.16421 17.5917 6.5 17.1775 6.5L10.3203 6.5C9.9061 6.5 9.57031 6.16421 9.57031 5.75ZM7.25 8.09961C7.25 7.6854 7.58579 7.34961 8 7.34961H19.4286C19.8428 7.34961 20.1786 7.6854 20.1786 8.09961C20.1786 8.51382 19.8428 8.84961 19.4286 8.84961H8C7.58579 8.84961 7.25 8.51382 7.25 8.09961ZM6 12V21C6 22.1046 6.89543 23 8 23H20C21.1046 23 22 22.1046 22 21V11.2C22 10.5373 21.4627 10 20.8 10L18.8 12.8889H16.8L18.8 10H16L14 12.8889H12L14 10H11.2L9.2 12.8889H7.2L9.2 10H8C6.89543 10 6 10.8954 6 12Z"
      fill="currentColor"
    />
  </svg>
);
