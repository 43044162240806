import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DownloadIcon, Pencil1Icon } from '@radix-ui/react-icons';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { useStoryboard } from '../hooks/useStoryboard';
import { ScreenHeader } from '../../projects/components/screen-header';
import { BufferToBlob } from '../../../lib/utils/BufferToBolb';
import { StoryboardPropertiesMenu } from './storyboard-properties-menu';
import { createFileName } from '../../../core/services/file.service';
import { SliderInput } from '../../../components/slider-input/slider-input';
import { ElevatedButton } from '../../../components/buttons';
import { RenameStepModal } from '../../process/components/rename-step.modal';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { useResponsive } from '../../../hooks/useResponsive';

import type { Step } from '../../../app/entities/step';
import type { Project } from '../../../app/entities/project';
import { authStore } from '../../../core/stores/auth-store';
import { LimitReachedModal } from '../../../components/dialogs/limit-reached.modal';

type StoryboardHeaderProps = {
  step: Step;
  project: Project;
};

export const StoryboardHeader: React.FC<StoryboardHeaderProps> = observer(({ step, project }) => {
  const { isDesktop } = useResponsive();

  const { spaceId } = useParams();

  const currentMember = authStore.getCurrentMember(spaceId!);

  const storyboardPreferences = currentMember?.preferences?.storyBoardPreferences;

  const [generatingPDF, setGeneratingPDF] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [showLimitReachedModal, setShowLimitReachedModal] = useState(false);

  const storyboard = project.storyboard;

  const { fetchStoryboardAsPDF } = useStoryboard(project._id);

  const handleDownload = async () => {
    if (generatingPDF || !project || !storyboard) {
      return;
    }

    if (!storyboard.project?.space?.canExportPdf()) {
      return setShowLimitReachedModal(true);
    }

    setGeneratingPDF(true);

    try {
      const file = await fetchStoryboardAsPDF(
        storyboard._id,
        storyboardPreferences?.displayedProperties,
      );

      BufferToBlob(file, createFileName(project.name, 'Storyboard.pdf'));
    } catch (e) {
      console.log(e);
    }

    setGeneratingPDF(false);
  };

  const handleToggleProperties = (propertyId: string) => {
    if (!storyboard) return;
    currentMember?.toggleSetting(propertyId);
  };

  const handleShowAll = () => {
    if (!storyboard) return;
    currentMember?.showAllSettings();
  };

  const handleHideAll = () => {
    if (!storyboard) return;
    currentMember?.hideAllSettings();
  };

  const handleZoomLevel = (value: number) => {
    if (!storyboard) return;
    currentMember?.changeZoomLevel(value);
  };

  const items: React.ComponentProps<typeof DropdownMenu>['items'] = [
    // ...(!isDesktop
    //   ? [
    //       {
    //         title: 'Generate PDF',
    //         icon: <DownloadIcon />,
    //         onSelect: handleDownload,
    //         disabled: generatingPDF || !shotCount,
    //       },
    //     ]
    //   : []),
    ...(!isDesktop
      ? [
          {
            title: 'Generate PDF',
            icon: <DownloadIcon />,
            onSelect: handleDownload,
            disabled: generatingPDF || !storyboard?.shots?.length,
          },
        ]
      : []),
    {
      title: 'Rename step',
      icon: <Pencil1Icon />,
      onSelect: () => setIsRenameModalOpen(true),
    },
  ];

  return (
    <>
      <ScreenHeader
        stepId={step._id}
        title={step.name}
        dueDate={step.dueDate}
        startDate={step.startDate}
        handleUpdateTimeFrame={(values) => step.update(values)}
        menu={<DropdownMenu items={items} size={'large'} />}
        actions={
          isDesktop
            ? [
                <p>Total shots: {storyboard?.shots.length}</p>,
                <ZoomInputWrapper key="zoom-input">
                  <SliderInput
                    min={1}
                    max={6}
                    step={1}
                    value={storyboardPreferences?.zoom || 5}
                    onChange={handleZoomLevel}
                  />
                </ZoomInputWrapper>,
                <StoryboardPropertiesMenu
                  key="properties"
                  properties={storyboard?.settings.properties || []}
                  userPreferences={storyboardPreferences?.displayedProperties}
                  onToggle={handleToggleProperties}
                  onShowAll={handleShowAll}
                  isDisabled={(storyboardPreferences?.zoom || 1) <= 4}
                  onHideAll={handleHideAll}
                />,
                <ElevatedButton
                  key="download"
                  onClick={handleDownload}
                  isLoading={generatingPDF}
                  disabled={!storyboard?.shots?.length}
                  text="Generate PDF"
                  icon={<DownloadIcon />}
                />,
              ]
            : []
        }
      />

      {isRenameModalOpen && (
        <RenameStepModal step={step} onCancel={() => setIsRenameModalOpen(false)} />
      )}

      {showLimitReachedModal && (
        <LimitReachedModal
          title="Upgrade your space to export your storyboard as a PDF"
          eventName="Export storyboard attempt"
          onCancel={() => setShowLimitReachedModal(false)}
        />
      )}
    </>
  );
});

const ZoomInputWrapper = styled.div`
  flex: 1 1 0;
`;
