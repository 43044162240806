export enum KeyboardKeys {
  Enter = 'Enter',
  Escape = 'Escape',
  Space = ' ',
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
  KEY_K = 'k',
  KEY_L = 'l',
  KEY_J = 'j',
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
  NINE = '9',
  MINUS = '-',
  BACKSPACE = 8,
  ARROW_LEFT = 37,
  ARROW_RIGHT = 39,
  VIRGULE = 188,
  DOT = 190,
}
