interface ObjectWithName {
  firstName?: string;
  lastName?: string;
}

export const getNameInitials = (name?: string | ObjectWithName) => {
  if (!name) return '#';

  const [firstName, lastName] =
    typeof name === 'string'
      ? name
          .replace(/null|undefined/g, '')
          .trim()
          .split(' ')
      : [
          name.firstName?.replace(/null|undefined/g, '').trim(),
          name.lastName?.replace(/null|undefined/g, '').trim(),
        ];

  if (!firstName) return '#';

  return `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`.toUpperCase();
};
