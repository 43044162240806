import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { Loader } from '../loader/Loader';

type OutlinedButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  text: string;
  isLoading?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  endIcon?: React.ReactNode;
  isFullWidth?: boolean;
};

export const OutlinedButton: React.FC<OutlinedButtonProps> = ({
  icon,
  endIcon,
  text,
  isLoading,
  disabled,
  isFullWidth,
  ...buttonProps
}) => (
  <Button disabled={disabled || isLoading} data-full={isFullWidth} {...buttonProps}>
    {isLoading ? (
      <Loader small />
    ) : (
      <>
        {icon}
        {text}
        {endIcon}
      </>
    )}
  </Button>
);

const Button = styled.button`
  all: unset;
  display: flex;
  height: 3.2rem;
  padding: 0 1.6rem;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  white-space: nowrap;

  border-radius: 0.4rem;
  border: 1px solid var(--color-grayscale-light-slate);
  cursor: pointer;

  color: var(--color-grayscale-light-slate);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: normal;
  box-sizing: border-box;

  & svg {
    width: 1.2rem;
    height: 1.2rem;
  }

  &[data-full='true'] {
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    height: 4rem;
  }

  &:hover,
  &:focus-visible {
    color: var(--color-grayscale-white);
    background: var(--color-grayscale-light-slate);
    border-color: transparent;
  }

  &:focus-visible {
    outline: var(--color-grayscale-light-slate) solid 0.15rem;
    outline-offset: 0.15rem;
  }
`;
