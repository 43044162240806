import React, { ChangeEvent, useRef } from 'react';
import styled from 'styled-components';

type EditCoverButtonProps = {
  onChange: (file: File) => void;
};

export const EditCoverButton: React.FC<EditCoverButtonProps> = ({ onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files![0];
    onChange(file);
  };

  return (
    <EditCover id="edit">
      Edit
      <CoverInput
        ref={inputRef}
        id="file"
        type="file"
        accept="image/*"
        name="file"
        onChange={handleChange}
      />
    </EditCover>
  );
};

const EditCover = styled.div`
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  font-size: 1.4rem;
  border-radius: var(--border-radius-small);
  padding: 0.2rem 0.8rem;
  color: white;
  background: black;
  opacity: 0;
  transform: translateX(1rem) scale(0.95);
  transition: all 0.1s ease-in;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
`;

const CoverInput = styled.input`
  position: absolute;
  font-size: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
  cursor: pointer;

  &:hover {
    cursor: pointer;
  }
`;
