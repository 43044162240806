import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { SettingScreenLayout } from '../../features/settings/components/setting-screen-layout';
import { fetchMembers } from '../../features/members/store/member.slice';
import { Plus } from '../../components/icons';
import { InviteMemberModal } from '../../features/members/components/invite-member.modal';
import { Tabs } from '../../components/tabs/Tabs';
import { MembersTab } from '../../features/members/components/members-tab';
import { GuestsTab } from '../../features/members/components/guests-tab';
import { InvitationsTab } from '../../features/guest-invitation/components/invitations-tab';
import { ElevatedButton } from '../../components/buttons/elevated-button';
import { SubscriptionStatus } from '../../app/entities/space';
import { LoaderContainer } from '../project/components/step-container';
import { PageLoader } from '../../components/page-loader/PageLoader';
import { SubscriptionFailureDialog } from '../../components/dialogs/subscription-failure-dialog';
import { uiStore } from '../../core/stores/ui-store';
import { getCurrentActiveTab } from '../../lib/utils/get-current-active-tab';
import { LimitReachedModal } from '../../components/dialogs/limit-reached.modal';
import { api } from '../../api';
import { useCurrentSpace } from '../../hooks/use-current-space';

const tabs = [
  {
    key: 'creators',
    label: 'Creators',
  },
  {
    key: 'guests',
    label: 'Guests',
  },
  {
    key: 'invitations',
    label: 'Invitations',
  },
];

export const MembersSettingsRoute: React.FC = observer(() => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isSubscriptionFailureModalOpen, setIsSubscriptionFailureModalOpen] = useState(false);
  const [hint, setHint] = useState('');
  const [isShowingLimitsModal, setIsShowingLimitsModal] = useState(false);
  const space = useCurrentSpace();

  useEffect(() => {
    uiStore.showBack = true;
  }, []);

  useEffect(() => {
    fetchMembers(space._id).then(() => setIsLoading(false));
  }, [space._id]);

  const selectedTab = getCurrentActiveTab(tabs, 'creators');

  const handleClick = () => {
    if (!space.canInviteMember()) {
      api.post('/track', {
        type: 'event',
        eventName: 'Add user attempt',
      });

      setIsShowingLimitsModal(true);
      return;
    }

    if (
      space.subscription?.subscriptionId &&
      !space.isSelfServing &&
      space.subscription.status === SubscriptionStatus.PAST_DUE
    ) {
      return setIsSubscriptionFailureModalOpen(true);
    }

    setIsInviteModalOpen(true);
  };

  useEffect(() => {
    if (space.subscription?.subscriptionId && !space.isSelfServing) {
      const limitReached = !space.canAddNewMember(1);
      const limit = space.seats?.limit || 1;
      const current = space.seats?.current || 1;

      if (limitReached) {
        return setHint(
          `All ${limit} seats are taken. To add a creator you first have to remove a creator or revoke an invitation. To request additional seats, please contact <a href="mailto:sales@the-producer.io">sales@the-producer.io</a>`,
        );
      }

      setHint(`${limit - current} seats of ${limit} available`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [space.seats?.current]);

  return (
    <SettingScreenLayout
      title="User settings"
      hint={hint}
      actions={
        space.canAddNewMember(1)
          ? [
              <ElevatedButton
                key="invite"
                text="Invite creator"
                icon={<Plus />}
                onClick={handleClick}
              />,
            ]
          : []
      }
    >
      <Container>
        {isShowingLimitsModal && (
          <LimitReachedModal type="creators" onCancel={() => setIsShowingLimitsModal(false)} />
        )}

        <Tabs
          tabs={tabs}
          onChange={(value) => {
            navigate(`/space/${space._id}/settings/users/${value}`, { replace: true });
          }}
          selectedTab={selectedTab}
        >
          <TabWrapper>
            {isLoading ? (
              <LoaderContainer>
                <PageLoader />
              </LoaderContainer>
            ) : (
              <Routes>
                <Route path="/" element={<MembersTab space={space} />} />
                <Route path="/creators" element={<MembersTab space={space} />} />
                <Route path="/guests" element={<GuestsTab space={space} />} />
                <Route path="/invitations" element={<InvitationsTab space={space} />} />
              </Routes>
            )}
          </TabWrapper>
        </Tabs>
      </Container>

      {isInviteModalOpen && (
        <InviteMemberModal onCancel={() => setIsInviteModalOpen(false)} spaceId={space._id} />
      )}

      {isSubscriptionFailureModalOpen && (
        <SubscriptionFailureDialog
          onCancel={() => setIsSubscriptionFailureModalOpen(false)}
          reason="pastDue"
        />
      )}
    </SettingScreenLayout>
  );
});

const Container = styled.div`
  height: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #7a8296;
`;

const TabWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-top: 1.6rem;
`;
