import React from 'react';

export const PlanCardCheckOn = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2426 7.11724L9.1558 18.2467L3 12.0672L5.12539 9.95L9.1558 13.9959L18.1172 5L20.2426 7.11724Z"
      fill="#367BFF"
    />
  </svg>
);
