import React from 'react';

import { DirectorScreen } from '../../../features/collaborators/screens/director.screen';
import { Project } from '../../../app/entities/project';

import type { Step } from '../../../app/entities/step';

export interface DirectorPageProps {
  project: Project;
  step: Step;
}

export const DirectorPage: React.FC<DirectorPageProps> = ({ project, step }) => (
  <DirectorScreen step={step} project={project} />
);
