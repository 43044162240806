import React from 'react';

export const ContactPhone = () => (
  <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.71429 2.03675H11.1429C11.3702 2.03675 11.5882 2.12296 11.7489 2.27639C11.9097 2.42983 12 2.63794 12 2.85494V17.5822C12 17.7992 11.9097 18.0073 11.7489 18.1608C11.5882 18.3142 11.3702 18.4004 11.1429 18.4004H0.857143C0.629814 18.4004 0.411797 18.3142 0.251051 18.1608C0.090306 18.0073 0 17.7992 0 17.5822V0.400391H1.71429V2.03675ZM1.71429 3.67312V7.76403H10.2857V3.67312H1.71429Z"
      fill="#606570"
    />
  </svg>
);
