import React from 'react';

export const ContactWebsite = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.52005 0.759766H14.48C14.671 0.759766 14.8541 0.838432 14.9892 0.978459C15.1242 1.11849 15.2 1.3084 15.2 1.50643V13.4531C15.2 13.6511 15.1242 13.841 14.9892 13.9811C14.8541 14.1211 14.671 14.1998 14.48 14.1998H1.52005C1.32909 14.1998 1.14596 14.1211 1.01093 13.9811C0.875906 13.841 0.800049 13.6511 0.800049 13.4531V1.50643C0.800049 1.3084 0.875906 1.11849 1.01093 0.978459C1.14596 0.838432 1.32909 0.759766 1.52005 0.759766ZM13.76 5.98643H2.24005V12.7064H13.76V5.98643ZM2.96005 2.99977V4.4931H4.40005V2.99977H2.96005ZM5.84005 2.99977V4.4931H7.28005V2.99977H5.84005Z"
      fill="#606570"
    />
  </svg>
);
