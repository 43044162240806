import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { CharacterDetailScreen } from '../../../features/character/screens/character-detail.screen';
import { CharacterListScreen } from '../../../features/character/screens/character-list.screen';
import { fetchProjectCharacters } from '../../../features/character/character.slice';
import { Project } from '../../../app/entities/project';

import type { Step } from '../../../app/entities/step';

export type CastSelectionPageProps = {
  project: Project;
  step: Step;
};

export const CastSelectionPage: React.FC<CastSelectionPageProps> = ({ project, step }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchProjectCharacters({
      projectId: project._id,
    })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [project?._id]);

  return (
    <Routes>
      <Route
        path="/character/:characterId/*"
        element={
          <CharacterDetailScreen
            projectId={project._id}
            step={step}
            spaceId={project.spaceId}
            isLoading={isLoading}
          />
        }
      />

      <Route
        path="/"
        element={<CharacterListScreen projectId={project._id} step={step} isLoading={isLoading} />}
      />
    </Routes>
  );
};
