import { TeamContact } from '../../features/teams/models/team';

export interface DepartmentWithContacts {
  _id: string;
  name: string;
  contacts: TeamContact[];
}

export const generateDepartmentsObjectsArray = ({
  departments,
  contacts,
}: {
  departments: { _id: string; name: string }[];
  contacts: TeamContact[];
}): DepartmentWithContacts[] => {
  const departmentsObject: Record<string, { _id: string; name: string; contacts: TeamContact[] }> =
    departments.reduce(
      (acc: Record<string, { _id: string; name: string; contacts: TeamContact[] }>, curr) => (
        (acc[curr._id] = {
          _id: curr._id,
          name: curr.name,
          contacts: [],
        }),
        acc
      ),
      {},
    );

  contacts.forEach((contact) => {
    if (!contact.departmentId) {
      return;
    }

    if (contact.departmentId in departmentsObject) {
      departmentsObject[contact.departmentId].contacts.push({
        ...contact,
        phone: typeof contact.phone === 'string' ? contact.phone : contact.phone?.number,
      });
    }
  });

  return Object.keys(departmentsObject).map((_id) => ({
    _id: departmentsObject[_id]._id,
    name: departmentsObject[_id].name,
    contacts: departmentsObject[_id].contacts,
  }));
};
