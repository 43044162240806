import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ElevatedButton } from '../../../components/buttons';
import { TextInput } from '../../../components/text-input/TextInput';
import { ProfileAvatar } from '../../../components/profile-avatar/ProfileAvatar';
import { formatPicture } from '../../../core/services/image.service';
import { createContact } from '../stores/contact.slice';
import { RoleSelect } from '../../teams/components/role-select';
import { useResponsive } from '../../../hooks/useResponsive';
import { breakPoint } from '../../../app/theme';

import type { Frame } from '../../../lib/utils/transform-image';
import type { Contact } from '../../../app/entities/contact';
import type { TeamContact } from '../../teams/models/team';

type AssignAddedContactFormProps = {
  spaceId: string;
  onAssignCandidate: (contactId: string, role?: string) => Promise<any>;
  onSuccess?: () => void;
};

export const AssignAddedContactForm: React.FC<AssignAddedContactFormProps> = ({
  spaceId,
  onAssignCandidate,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<Frame>();
  const [isCreateLoading, setIsCreateLoading] = useState<boolean>();
  const [serverError, setServerError] = useState<any>();
  const { isDesktop } = useResponsive();

  const schema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup
          .string()
          .matches(/^(.*)?\S+(.*)?$/, t('common:fieldRequired'))
          .required(t('common:fieldRequired'))
          .max(64, t('common:fieldMax', { max: 64 })),
        lastName: yup.string().max(64, t('common:fieldMax', { max: 64 })),
        phone: yup
          .string()
          .trim()
          .max(32, t('common:fieldMax', { max: 32 })),
        email: yup.string().email(t('common:wrong_email_error')),
      }),
    [t],
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      role: '',
    },
  });

  const handleSetImage = async (file?: File) => {
    if (!file) {
      return null;
    }

    const options = { width: 480, height: 480 };
    const frame = await formatPicture(file, options);
    setFile(frame);
  };

  const handleCreateAssignContact = async (contactData: Partial<Contact>) => {
    if (isCreateLoading) {
      return;
    }

    setIsCreateLoading(true);

    try {
      const data = await createContact({
        contactInfo: contactData,
        accountId: spaceId!,
        file,
      });

      await onAssignCandidate(data?._id, (data as TeamContact).role || '');
      onSuccess && onSuccess();
    } catch (e) {
      setServerError(e);
    }
    setIsCreateLoading(false);
  };

  return (
    <Container>
      <ProfileAvatarWrapper>
        <ProfileAvatar placeholder="image" onChange={handleSetImage} />
      </ProfileAvatarWrapper>

      <InfoContainer onSubmit={handleSubmit(handleCreateAssignContact)}>
        <TextInput
          label="First name"
          {...register('firstName')}
          errorMessage={errors.firstName?.message}
          required
        />

        <TextInput
          label="Last name"
          {...register('lastName')}
          errorMessage={errors.lastName?.message}
        />

        <SelectWrapper>
          <Controller
            control={control}
            name="role"
            render={({ field: { onChange, value } }) => (
              <RoleSelect value={value} onChange={onChange} />
            )}
          />
        </SelectWrapper>

        <TextInput label="Phone" {...register('phone')} errorMessage={errors.phone?.message} />

        <TextInput label="Email" {...register('email')} errorMessage={errors.email?.message} />
      </InfoContainer>

      <ButtonWrapper>
        <ElevatedButton
          id="assign-added-contact-form-button"
          text="Create and add"
          onClick={handleSubmit(handleCreateAssignContact)}
          disabled={isCreateLoading || isCreateLoading}
          isFullWidth={!isDesktop}
        />
      </ButtonWrapper>
    </Container>
  );
};

const InfoContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2.4rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 2.4rem;
  overflow: auto;
  padding: 0 2.4rem 3.2rem 2.4rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    padding-bottom: 2.4rem;
  }
`;

const ButtonWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    align-items: flex-end;
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ProfileAvatarWrapper = styled.div`
  margin: 0 auto;
  position: relative;
`;
