import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { format } from 'date-fns';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Project } from '../../../app/entities/project';
import { TextInput } from '../../../components/text-input/TextInput';
import { DateInput } from '../../../components/date-input/DateInput';
import { HasServerResponse } from '../../../components/PanelContainer';
import { ElevatedButton } from '../../../components/buttons';
import { Tag } from '../../../components/tag/Tag';
import { createFormDiff } from '../../../core/services/form.service';
import { Space } from '../../../app/entities/space';
import { Label } from '../../../components/Input/styled-input';
import { FpsSelect } from '../../../features/projects/components/fps-select';
import { Tooltip } from '../../../components/tooltip/Tooltip';

interface ProjectSettingsFormProps {
  project?: Project;
}

interface FormValues {
  name: string;
  client: { name: string };
  agency: { name: string };
  dueDate?: string | null;
  fps?: number;
}

export const ProjectSettingsForm: React.FC<ProjectSettingsFormProps> = ({ project }) => {
  const { t } = useTranslation('createProject');
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState<any>();

  const space = Space.getOne(project!.spaceId);
  const defaultFpsValue = project?.fps ?? space?.fps ?? 25;

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .transform((value) => value.trim())
          .matches(/^(.*)?\S+(.*)?$/, t('common:fieldRequired'))
          .required(t('common:fieldRequired'))
          .max(32, t('common:fieldMax', { max: 32 })),
        client: yup.object().shape({
          name: yup.string().max(32, t('common:fieldMax', { max: 32 })),
        }),
        agency: yup.object().shape({
          name: yup
            .string()
            .max(32, t('common:fieldMax', { max: 32 }))
            .nullable(),
          fps: yup.number().positive(),
        }),
      }),
    [t],
  );

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, dirtyFields },
  } = useForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    shouldUseNativeValidation: true,
    defaultValues: {
      name: project?.name,
      client: { ...project?.client, name: project?.client?.name || '' },
      agency: { ...project?.agency, name: project?.agency?.name || '' },
      dueDate: project?.dueDate ? format(new Date(project?.dueDate), 'yyyy-MM-dd') : null,
      fps: defaultFpsValue,
    },
  });

  const handleSubmitEdit = async (values: FormValues) => {
    if (!project) return;

    const diffs = createFormDiff<FormValues>(values, dirtyFields);

    if (values.fps !== undefined) {
      project.fps = values.fps;
    }

    await project.update(diffs);

    // Reset the form with value in place to clean dirty fields
    reset(undefined, {
      keepValues: true,
    });
  };

  const onHandleSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    let dueDate;

    try {
      dueDate = new Date(values.dueDate).toISOString();
    } catch (e) {
      dueDate = null;
    }

    try {
      await handleSubmitEdit({
        ...values,
        fps: parseFloat(values.fps),
        dueDate,
      });
    } catch (e) {
      setServerError(e);
    }

    setIsLoading(false);
  };

  return (
    <Container>
      <Header>Project Settings</Header>
      {serverError && serverError.response && (
        <HasServerResponse>
          <Tag text={serverError.response.data.message} />
        </HasServerResponse>
      )}

      <SettingsForm>
        <FieldContainer>
          <TextInput
            {...register('name')}
            errorMessage={errors.name?.message}
            label={t('name')}
            placeholder={t('name_placeholder')}
            required
          />
        </FieldContainer>

        <FieldContainer>
          <TextInput
            {...register('client.name')}
            errorMessage={errors.client?.name?.message}
            label={t('client')}
            placeholder={t('client_placeholder')}
            autocomplete="false"
          />
        </FieldContainer>

        <FieldContainer>
          <TextInput
            {...register('agency.name')}
            errorMessage={errors.agency?.name?.message}
            label={t('agency')}
            placeholder={t('agency_placeholder')}
            autocomplete="false"
          />
        </FieldContainer>

        <FieldContainer>
          <DateInput {...register('dueDate')} dark={true} label={t('due_date') || ''} />
        </FieldContainer>

        <FieldContainer>
          <Tooltip text={t('fps_tooltip')} position="top">
            <Label>{t('fps')}</Label>
          </Tooltip>

          <Controller
            name="fps"
            control={control}
            render={({ field }) => (
              <FpsSelect
                initialValue={defaultFpsValue}
                value={field.value!}
                onChange={field.onChange}
              />
            )}
          />
        </FieldContainer>
      </SettingsForm>
      <ButtonWrapper>
        <ElevatedButton
          key="submit"
          isLoading={isLoading}
          disabled={isLoading}
          onClick={handleSubmit(onHandleSubmit)}
          text="Save"
        />
      </ButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
`;

const FieldContainer = styled.div`
  margin-bottom: 2.2rem;

  div[side='top'] {
    margin-left: 5rem;
  }
`;

const Header = styled.h3`
  margin: 0;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.42rem;
  color: var(--step-title-color);
  padding-bottom: 1.6rem;
  border-bottom: 0.1rem solid var(--color-grey-7-5);
`;

const SettingsForm = styled.form`
  max-width: 64.8rem;
  padding-bottom: 1.8rem;
  border-bottom: 0.1rem solid var(--color-grey-7-5);
`;

const ButtonWrapper = styled.div`
  max-width: 64.8rem;
  margin-top: 0.8rem;
  display: flex;
  justify-content: end;
  min-height: 4rem;
`;
