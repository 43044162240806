import React from 'react';
import { Cross1Icon } from '@radix-ui/react-icons';
import * as RadixDialog from '@radix-ui/react-dialog';
import styled from 'styled-components';

import { breakPoint } from '../../app/theme';

export type BaseDialogProps = {
  isOpen?: boolean;
  onClose?: () => void;
  mobilePosition?: 'center' | 'bottom';
  hideClose?: boolean;
  theme?: 'light' | 'dark';
  isMobileFill?: boolean;
};

export const BaseDialog: React.FC<React.PropsWithChildren<BaseDialogProps>> = ({
  isOpen = true,
  onClose,
  mobilePosition = 'center',
  children,
  hideClose,
  theme = 'light',
  isMobileFill,
}) => {
  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen && onClose) {
      onClose();
    }
  };

  return (
    <RadixDialog.Root open={isOpen} onOpenChange={handleOpenChange}>
      <RadixDialog.Portal>
        <DialogOverlay />

        <DialogContent
          id="dialog-item"
          data-theme={theme}
          data-position={mobilePosition}
          data-fill={isMobileFill}
          onClick={(e) => e.stopPropagation()}
          onDoubleClick={(e) => e.stopPropagation()}
        >
          {children}

          {!hideClose && (
            <RadixDialog.Close asChild>
              <CloseButton aria-label="Close">
                <Cross1Icon />
              </CloseButton>
            </RadixDialog.Close>
          )}
        </DialogContent>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};

const DialogOverlay = styled(RadixDialog.Overlay)`
  background: rgba(0, 0, 0, 0.81);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: var(--layer-overlay);

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const DialogContent = styled(RadixDialog.Content)`
  border-radius: var(--card-border-radius);
  position: fixed;
  box-shadow: rgba(0, 0, 0, 1) 0 10px 38px -10px, rgba(0, 0, 0, 1) 0 10px 20px -15px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 24rem;
  width: 100%;
  max-width: 90vw;
  max-height: 85vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(10px) saturate(190%) contrast(70%) brightness(80%);
  background-color: var(--color-grayscale-charleston);
  z-index: calc(var(--layer-overlay) + 1);
  animation: dialog-show 300ms cubic-bezier(0.16, 1, 0.3, 1);

  &[data-theme='dark'] {
    background-color: var(--color-grayscale-eerie-black);
  }

  @media screen and (max-width: ${breakPoint.medium}px) {
    max-height: 100vh;

    &[data-fill='true'] {
      max-width: unset;
      width: 100%;
    }
  }

  @media screen and (max-width: ${breakPoint.small - 1}px) {
    &[data-position='bottom'] {
      top: unset;
      bottom: 0;
      transform: translate(-50%, 0);
      max-width: unset;
      height: 100%;
    }
  }

  @media screen and (min-width: ${breakPoint.small}px) {
    min-width: 40rem;
    width: unset;
  }

  @keyframes dialog-show {
    from {
      opacity: 0;
      transform: translate(-50%, 100vh);
    }
    to {
      opacity: 1;
    }
  }
`;

const CloseButton = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 0.4rem;
  color: var(--color-grayscale-shuttle);
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  box-sizing: border-box;
  border: 0.15rem solid transparent;

  &:hover,
  &:focus {
    cursor: pointer;
    background-color: var(--color-grayscale-arsenic);

    svg {
      color: var(--color-grayscale-white);
    }
  }

  &:focus-visible {
    border: 0.15rem solid var(--color-secondary);
  }
`;
