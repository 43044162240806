import React from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb';
import { Character } from '../../../app/entities/character';

type CastBreadcrumbType = {
  projectId: string;
  stepId: string;
  characterId?: string;
  candidateId?: string;
};

export const CastBreadcrumb: React.FC<CastBreadcrumbType> = observer(
  ({ projectId, stepId, characterId }) => {
    const { spaceId } = useParams() as { spaceId: string };

    const character = Character.getOne(characterId!);

    const castBreadcrumb = [
      {
        label: 'Cast',
        href: `/space/${spaceId}/projects/${projectId}/steps/${stepId}`,
      },
    ];

    if (character) {
      castBreadcrumb.push({
        label: `${character.name}`,
        href: `/space/${spaceId}/projects/${projectId}/steps/${stepId}/character/${characterId}`,
      });
    }

    return <Breadcrumb breadcrumbs={castBreadcrumb} />;
  },
);
