import { makeObservable, observable, runInAction } from 'mobx';

import { Model } from '../../core/engine/model';
import { api } from '../../api';
import { OneToOne } from '../../core/engine/decorators';
import { Project } from './project';
import { toastPool } from '../../features/toasts/models/toast-pool';
import { Toast } from '../../features/toasts/models/toast';
import { minutesTo12HourFormat, timeToMinutes } from '../../lib/utils/Convertor';
import { Shot } from './shot';
import { Step } from './step';
import { catchError } from '../../core/catch-error';

import { DayBreakStrip, Strip } from '../../features/schedule/models/types';

export class Stripboard extends Model {
  strips: any[] = [];

  totalShootingDays: number;

  projectId: string;

  @OneToOne('stripboard')
  project: Project;

  constructor() {
    super('stripboards');

    makeObservable(this, {
      strips: observable,
    });
  }

  async addBannerStrip({
    text,
    position,
    estimatedTime,
  }: {
    text: string;
    position: any;
    estimatedTime?: number;
  }) {
    const body = {
      type: 'banner',
      data: {
        text,
        ...(estimatedTime && { estimatedTime }),
      },
      position: position - 1,
    };

    try {
      const { data } = await api.post(`/strip-boards/${this._id}/strips`, body);
      Object.assign(this, data);
    } catch (e) {
      catchError(e);
    }
  }

  addStrip(strip: Strip, stripPosition?: number) {
    let position = stripPosition ?? strip.position ?? this.strips.length;

    if (strip.type === 'dayBreak') {
      const lastShootingDayIndex = this.strips.map((st) => st.type).lastIndexOf('dayBreak');

      // If the strip was the first shooting day, move it to the top, and any unassigned shot to the bottom
      if (lastShootingDayIndex === -1) {
        position = 0;
      } else {
        position = lastShootingDayIndex + 1;
      }
    }

    this.strips.splice(position!, 0, strip);

    // To reflect changes on UI, Check with Pablo is it OK?
    this.strips = [...this.strips];

    this.updateSchedule();
  }

  async deleteStrip(stripId: string) {
    try {
      await api.delete(`/strip-boards/${this._id}/strips/${stripId}`);

      const stripIndex = this.strips.findIndex((st) => st._id === stripId);
      const strip = this.strips[stripIndex];

      if (strip.type === 'shot') {
        this.strips.splice(stripIndex, 1);
        this.strips = [...this.strips, strip];

        // @todo un-assigning shooting day shouldn't be handled here,
        // it should be handled in the update shot request and not using the delete method
        // the logic below works but it should be changed as it's not related
        const shot = Shot.getOne(strip.data.shotId);

        if (shot) {
          shot.shootingDayId = undefined;
        }
      } else {
        this.strips = this.strips.filter((strip) => strip._id !== stripId);
      }

      this.updateSchedule();
    } catch (e) {
      catchError(e);
    }
  }

  removeShotStrip(shotId: string) {
    this.strips = this.strips.filter((strip) => strip.data.shotId !== shotId);
  }

  // @todo Move in a strip entity
  async editStrip(
    stripId: string,
    update: {
      data?: {
        text?: string;
        estimatedTime?: number;
        isHidden?: boolean;
      };
    },
  ) {
    const currentStrips = JSON.parse(JSON.stringify(this.strips));

    const strip = this.strips.find((strip) => strip._id === stripId);
    runInAction(() => {
      Object.assign(strip.data, update.data);
    });

    if (update?.data?.estimatedTime !== undefined) {
      this.updateSchedule();
    }

    if (update?.data?.isHidden !== undefined) {
      if (strip.type !== 'shot') {
        return;
      }

      const shotId = strip.data?.shotId;
      const shot = Shot.getOne(shotId);
      if (!shot) {
        return;
      }

      runInAction(() => {
        this.strips = [...this.strips];
      });

      runInAction(() => {
        if (update.data?.isHidden) {
          shot.order = 0;
          shot.isHidden = true;
        } else {
          const project = Project.getOne(this.projectId);

          if (project) {
            const sortedShots = [...project!.shots]
              .filter((sh) => !sh.isHidden)
              .sort((a, b) => (a.order > b.order ? -1 : 1));

            const lastShot = sortedShots.find((sh) => !sh.isHidden);
            shot.order = (lastShot?.order || 0) + 1;
            shot.isHidden = false;
          }

          this.updateSchedule();
        }
      });
    }

    try {
      await api.patch(`/strip-boards/${this._id}/strips/${stripId}`, update);
    } catch (e) {
      catchError(e);
      this.strips = currentStrips;
    }
  }

  /**
   * Copy of the logic from the slice
   * @todo improve to make it more class friendly
   * @param stripIds The list of strips to move
   * @param newPosition The position to move the strips to
   */
  async edit(stripIds: string[], newPosition: number) {
    // const currentStrips = JSON.parse(JSON.stringify(this.strips));
    const currentStrips = [...this.strips];

    const firstSelectedStripIndex = this.strips.findIndex((strip) => stripIds.includes(strip._id));

    const insertIndex = newPosition - 1 < firstSelectedStripIndex ? newPosition - 1 : newPosition;
    let dividerIndex;

    if (insertIndex > -1 && insertIndex < this.strips.length) {
      dividerIndex = insertIndex - 1;
    } else {
      dividerIndex = this.strips.length;
    }

    const selectedStrips = this.strips.filter((strip) => stripIds.includes(strip._id));

    const upperHalfRemainingStrips = this.strips
      .slice(0, dividerIndex + 1)
      .filter((currStrip) => !stripIds.includes(currStrip._id));

    const lowerHalfRemainingStrips = this.strips
      .slice(dividerIndex + 1)
      .filter((currStrip) => !stripIds.includes(currStrip._id));

    const finalStrips = [
      ...upperHalfRemainingStrips,
      ...selectedStrips,
      ...lowerHalfRemainingStrips,
    ];

    runInAction(() => {
      this.strips = finalStrips;
    });

    this._reassignShotsToShootingDays(newPosition, selectedStrips);

    this.updateSchedule();

    try {
      await api.patch(`/strip-boards/${this._id}/strips`, {
        stripIds,
        newPosition,
        // @todo projectId should not be sent into the request
        projectId: this.projectId,
      });
    } catch (e) {
      catchError(e);
      this.strips = currentStrips;
    }
  }

  private _reassignShotsToShootingDays(newPosition: number, selectedStrips: any[]) {
    const shootingDayStrip = this.getNextShootingDay(newPosition);

    selectedStrips.forEach((st) => {
      if (st.type === 'shot') {
        const shot = Shot.getOne(st.data.shotId);
        if (shot) {
          shot.shootingDayId = shootingDayStrip ? shootingDayStrip.data.stepId : undefined;
        }
      }
    });
  }

  async renumberShots() {
    try {
      await api.patch(`/projects/${this.projectId}/shots/renumber`);

      const activeShotsOrderingInStripBoard: { [key: string]: number } = {};

      let currentIndex = 0;

      for (const strip of this.strips) {
        if (strip.type === 'shot') {
          if (!strip.data.isHidden) {
            activeShotsOrderingInStripBoard[strip.data.shotId] = ++currentIndex;
          } else {
            activeShotsOrderingInStripBoard[strip.data.shotId] = 0;
          }
        }
      }

      this.project.shots.forEach(
        (shot) => (shot.order = activeShotsOrderingInStripBoard[shot._id]),
      );

      toastPool.insert(new Toast('Shots renumbered.'));
      return true;
    } catch (e) {
      catchError(e);
      return false;
    }
  }

  async updateSchedule() {
    this.recalculateStripsPosition();
    const firstShootingDay = this.getNextShootingDay();
    if (!firstShootingDay) return;

    const hour = firstShootingDay?.startTime?.hour || 7;
    const minute = firstShootingDay?.startTime?.minute || 0;

    let startTimeInMin = timeToMinutes(hour, minute);
    let estimatedTimeInMin = 0;
    let totalShotsDurationInMin = 0;
    let totalNumberOfShootingDays = 0;

    let lastShootingDayIndex = 0;

    for (let i = 0; i < this.strips.length; i++) {
      const strip = this.strips[i];
      strip.position = i + 1;

      const stripEstimatedTimeInMin = strip.data?.estimatedTime || 0;

      if (strip.type !== 'dayBreak') {
        strip.startTime = minutesTo12HourFormat(startTimeInMin);

        if (strip.data && strip.data.isHidden === false) {
          startTimeInMin += stripEstimatedTimeInMin;
        }

        if (!strip.data?.isHidden) estimatedTimeInMin += stripEstimatedTimeInMin;

        if (strip.type === 'shot') {
          if (!strip.data?.isHidden) totalShotsDurationInMin += stripEstimatedTimeInMin;
        }

        if (strip.data?.isHidden) {
          strip.startTime = null;
        }
      }

      if (strip.type === 'dayBreak') {
        totalNumberOfShootingDays++;

        strip.data.totalEstimatedTime = estimatedTimeInMin;
        strip.data.totalShotsDuration = totalShotsDurationInMin;
        strip.data.endTime = minutesTo12HourFormat(startTimeInMin);
        strip.data.dod = totalNumberOfShootingDays;

        estimatedTimeInMin = 0;
        totalShotsDurationInMin = 0;

        const nextShootingDay = this.getNextShootingDay(strip._id);
        if (!nextShootingDay) {
          lastShootingDayIndex = i;
          break;
        }

        const hour = nextShootingDay.startTime?.hour || 7;
        const minute = nextShootingDay.startTime?.minute || 0;

        startTimeInMin = timeToMinutes(hour, minute);
      }
    }

    // Recalculate position for unassigned strips
    this.recalculateStripsPosition(lastShootingDayIndex, true);

    this.totalShootingDays = totalNumberOfShootingDays;
  }

  private recalculateStripsPosition(fromIndex = 0, isNoShootingDay = false) {
    for (let i = fromIndex; i < this.strips.length; i++) {
      const strip = this.strips[i];
      strip.position = i + 1;

      if (isNoShootingDay) {
        strip.startTime = null;
      }
    }
  }

  toPOJO(): Record<string, any> {
    return {};
  }

  removeShootingDayStrips(step: Step) {
    const stripIndex = this.strips.findIndex((strip) => strip.data?.stepId === step._id);
    this.strips.splice(stripIndex, 1);

    const shotsToMove = [];

    for (let i = stripIndex - 1; i >= 0; i--) {
      const strip = this.strips[i];
      if (strip.type === 'dayBreak') {
        break;
      }
      if (strip.type === 'banner' || strip.type === 'companyMove') {
        this.strips.splice(i, 1);
      }

      if (strip.type === 'shot') {
        const shot = Shot.getOne(strip.data.shotId);

        if (shot) {
          shot.shootingDayId = undefined;
        }

        const shotToMove = this.strips.splice(i, 1)[0];
        shotsToMove.push(shotToMove);
      }
    }

    this.strips.push(...shotsToMove);

    this.totalShootingDays--;

    this.updateSchedule();
  }

  getShootingDaysStrips = (shootingDay: Step) => {
    const shootingDayStripIndex = this.strips.findIndex((st) => shootingDay._id === st.data.stepId);
    let firstStripIndex = 0;

    for (let i = shootingDayStripIndex - 1; i >= 0; i--) {
      const strip = this.strips[i];
      if (strip.type === 'dayBreak') {
        firstStripIndex = i + 1;
        break;
      }
    }

    return this.strips.slice(firstStripIndex, shootingDayStripIndex + 1);
  };

  getUnassignedStrips = () => {
    const strips = [];

    for (let i = this.strips.length - 1; i >= 0; i--) {
      const strip = this.strips[i];
      if (strip.type === 'dayBreak') {
        break;
      } else {
        strips.push(strip);
      }
    }

    return strips.reverse();
  };

  reorderShootingDaysStrips = (orderedShootingDays: Step[]) => {
    const orderedStrips = [];

    for (let i = 0; i < orderedShootingDays.length; i++) {
      const shootingDayStep = orderedShootingDays[i];
      const shootingDayStrips = this.getShootingDaysStrips(shootingDayStep);

      orderedStrips.push(...shootingDayStrips);
    }

    const unassignedStrips = this.getUnassignedStrips();

    this.strips = [...orderedStrips, ...unassignedStrips];

    this.updateSchedule();
  };

  getNextShootingDay(idOrPosition?: string | number): DayBreakStrip | undefined {
    const stripIndex =
      idOrPosition === undefined
        ? 0
        : typeof idOrPosition === 'number'
        ? idOrPosition
        : this.strips.findIndex((strip) => strip._id === idOrPosition);
    const nextShootingDayIndex = this.strips.findIndex(
      (strip, index) => index > stripIndex && strip.type === 'dayBreak',
    );
    if (nextShootingDayIndex === -1) return;
    return this.strips[nextShootingDayIndex];
  }

  getPreviousShootingDayPosition(stripId: string): number {
    const dayBreakStrips = this.strips.filter((strip) => strip.type === 'dayBreak');
    const stripIndex = dayBreakStrips.findIndex((strip) => strip._id === stripId);
    if (stripIndex === -1) return 0;
    if (stripIndex === 0) return 0;
    return dayBreakStrips[stripIndex - 1].position;
  }
}
