import React from 'react';
import styled from 'styled-components';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';

import { WelcomePage } from './welcome.page';
import { CreateWorkspacePage } from './create-workspace.page';
import { OnBoardingLayout } from './on-boarding.layout';
import { OnboardingStepsIndicator } from './components/onboarding-steps-indicator';
import { StatsPage } from './stats.page';
import { UpdateProfilePage } from './update-profile.page';

export const OnBoardingPage: React.FC = () => {
  const location = useLocation();

  return (
    <OnBoardingLayout>
      <Container>
        <OnboardingStepsIndicator />

        <Routes>
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/update-profile" element={<UpdateProfilePage />} />
          <Route path="/create-workspace" element={<CreateWorkspacePage />} />
          <Route path="/stats" element={<StatsPage />} />
          <Route
            path="/"
            element={<Navigate to={'/onboarding/welcome'} state={location.state} />}
          />
        </Routes>
      </Container>
    </OnBoardingLayout>
  );
};

const Container = styled.div`
  max-width: 37rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.2rem;
`;
