import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { CoverPlaceholder } from '../../strip-board/components/cover-placeholder';
import { AddStripMenu } from './add-strip-menu';
import { Stripboard } from '../../../app/entities/stripboard';
import { CollapsibleItem } from '../../../components/collapsible-item/collapsible-item';
import { timeParser } from '../../../lib/utils/time-parser';
import { getRenderedTime } from '../../../lib/utils/Convertor';
import {
  CellContent,
  CellLabel,
  HorizontalCell,
  VerticalCell,
} from '../../shootingdays/components/styled-mobile-table';
import { CoverItem, OrderIndicator, Image, HiddenCoverOverlay } from './styled-strips';
import { Shot } from '../../../app/entities/shot';
import { ShotStrip } from '../models/types';

import type { Frame } from '../../../lib/utils/transform-image';

type MobileShotStripProps = {
  strip: ShotStrip;
  stripboard: Stripboard;
};

export const MobileShotStripComponent: React.FC<MobileShotStripProps> = observer(
  ({ stripboard, strip }) => {
    const shot = Shot.getOne(strip.data.shotId);
    const locations = shot?.project?.locations;

    const location = locations?.find((location) => location._id === shot?.locationId);
    const characters = shot?.characters?.map((character) => character.name).join(', ');

    const handleEdit = async (data: Partial<Shot> & { file?: Frame }) => {
      if (!shot) {
        return;
      }

      shot.update(data);
    };

    if (!shot) {
      return;
    }

    return (
      <Container>
        <CollapsibleItem
          title={
            <Header>
              <CoverItem>
                {!!shot.order && <OrderIndicator>{shot.order}</OrderIndicator>}

                {shot.cover?.src ? (
                  <Image src={shot.cover?.src || ''} />
                ) : (
                  <CoverPlaceholder onUpload={(file) => handleEdit({ file })} />
                )}

                <HiddenCoverOverlay data-hide={!strip.data.isHidden} />
              </CoverItem>

              <HeaderDataWrapper data-hidden={strip.data.isHidden}>
                <ShotTitle>{shot.title}</ShotTitle>

                {strip.startTime && (
                  <HeaderData>
                    <CellLabel>Time</CellLabel>

                    <CellContent>{getRenderedTime(strip.startTime)}</CellContent>
                  </HeaderData>
                )}

                {!strip.data.isHidden && (
                  <HeaderData>
                    <CellLabel>Est. Time</CellLabel>

                    <CellContent>{timeParser(shot.estimatedTime || 0).toString()}</CellContent>
                  </HeaderData>
                )}
              </HeaderDataWrapper>
            </Header>
          }
          isTransparent
        >
          <ContentContainer data-hidden={!!strip.data.isHidden}>
            <HorizontalCell>
              <CellLabel>Location</CellLabel>

              <CellContent>{location?.name}</CellContent>
            </HorizontalCell>

            <HorizontalCell>
              <CellLabel>I/E</CellLabel>

              <CellContent style={{ textTransform: 'capitalize' }}>{shot.setting}</CellContent>
            </HorizontalCell>

            <HorizontalCell>
              <CellLabel>D/N</CellLabel>

              <CellContent style={{ textTransform: 'capitalize' }}>{shot.timing}</CellContent>
            </HorizontalCell>

            <VerticalCell>
              <CellLabel>Description</CellLabel>

              {shot.description && <CellContent>{shot.description}</CellContent>}
            </VerticalCell>

            <VerticalCell>
              <CellLabel>Characters</CellLabel>

              {characters && <CellContent>{characters}</CellContent>}
            </VerticalCell>

            <HorizontalCell>
              <CellLabel>Actions</CellLabel>

              <AddStripMenu
                stripId={strip._id}
                projectId={stripboard.projectId}
                position={strip.position}
                shot={shot}
                stripType="shot"
              />
            </HorizontalCell>
          </ContentContainer>
        </CollapsibleItem>
      </Container>
    );
  },
);

const Container = styled.div`
  background-color: var(--color-grayscale-charleston);
  border-radius: 0.4rem;

  & > * {
    #collapsible-trigger {
      padding: 0.8rem;

      & > div:first-child {
        width: 100%;
      }

      & button {
        background-color: var(--color-grayscale-tuna);
      }
    }
  }
`;

const ContentContainer = styled.div`
  padding: 0 1.6rem 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  &[data-hidden='true'] {
    ${CellContent} {
      color: var(--color-grayscale-trout);
    }
  }
`;

const ShotTitle = styled.h3`
  color: var(--color-grayscale-white);
  font-size: 1.2rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;

  & ${CoverItem} {
    min-width: 12rem;
  }
`;

const HeaderDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;

  &[data-hidden='true'] {
    ${ShotTitle} , ${CellContent} {
      color: var(--color-grayscale-trout);
    }
  }
`;

const HeaderData = styled(HorizontalCell)`
  max-width: 12rem;
  width: 100%;
`;
