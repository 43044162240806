import React from 'react';

export const FileTypePNG = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 1.75H2V12.25L8.969 5.2795C9.10965 5.1389 9.30038 5.05991 9.49925 5.05991C9.69812 5.05991 9.88885 5.1389 10.0295 5.2795L14 9.2575V1.75ZM0.5 0.99475C0.501373 0.797784 0.580175 0.609263 0.719385 0.469913C0.858594 0.330563 1.04704 0.251571 1.244 0.25H14.756C15.167 0.25 15.5 0.58375 15.5 0.99475V13.0052C15.4986 13.2022 15.4198 13.3907 15.2806 13.5301C15.1414 13.6694 14.953 13.7484 14.756 13.75H1.244C1.04661 13.7498 0.857373 13.6712 0.717867 13.5316C0.578362 13.392 0.5 13.2026 0.5 13.0052V0.99475ZM5 6.25C4.60218 6.25 4.22064 6.09196 3.93934 5.81066C3.65804 5.52936 3.5 5.14782 3.5 4.75C3.5 4.35218 3.65804 3.97064 3.93934 3.68934C4.22064 3.40804 4.60218 3.25 5 3.25C5.39782 3.25 5.77936 3.40804 6.06066 3.68934C6.34196 3.97064 6.5 4.35218 6.5 4.75C6.5 5.14782 6.34196 5.52936 6.06066 5.81066C5.77936 6.09196 5.39782 6.25 5 6.25Z"
      fill="#9D9D9D"
    />
  </svg>
);
