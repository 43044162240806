import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { generateDepartmentsObjectsArray } from '../../../lib/utils/generate-departments-objects-array';
import { PagePlaceholder } from '../../../components/page-placeholder/page-placeholder';
import { DepartmentItem } from './department-item';
import { NoDepartmentItem } from './no-department-item';
import { useResponsive } from '../../../hooks/useResponsive';

import type { Project } from '../../../app/entities/project';
import type { Team } from '../../../app/entities/team';

interface DepartmentsCrewProps {
  projectId: Project['_id'];
  spaceId: Project['spaceId'];
  team: Team;
  onOpenAddDepartment: () => void;
}

export const DepartmentList: React.FC<DepartmentsCrewProps> = observer(
  ({ projectId, spaceId, team, onOpenAddDepartment }) => {
    const { isDesktop } = useResponsive();

    const departments = generateDepartmentsObjectsArray({
      departments: team.departments,
      contacts: team.contacts,
    });

    const contactsWithoutDepartments = team.contacts
      .filter((contact) => !contact.departmentId)
      .map((contact) => ({
        ...contact,
        phone: typeof contact.phone === 'string' ? contact.phone : contact.phone?.number,
      }));

    if (!departments.length && !contactsWithoutDepartments.length) {
      return (
        <PlaceholderContainer>
          <PagePlaceholder
            {...(isDesktop
              ? {
                  description: 'No departments found',
                }
              : {
                  description: 'No departments found',
                  actionButton: { text: 'Add department', onClick: onOpenAddDepartment },
                })}
          />
        </PlaceholderContainer>
      );
    }

    return (
      <Container>
        {departments.map((department) => (
          <DepartmentItem
            key={department._id}
            spaceId={spaceId}
            projectId={projectId}
            department={department}
            team={team}
          />
        ))}

        {!!contactsWithoutDepartments.length && (
          <NoDepartmentItem
            spaceId={spaceId}
            projectId={projectId}
            team={team}
            contacts={contactsWithoutDepartments}
          />
        )}
      </Container>
    );
  },
);

const PlaceholderContainer = styled.div`
  height: 100%;
  padding-bottom: var(--spacing-bottom-screen-container);
`;

const Container = styled.div`
  min-height: 100%;
  padding-bottom: var(--spacing-bottom-screen-container);
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;
