import { v4 as uuid } from 'uuid';
import { makeObservable, observable } from 'mobx';

import { getChunkSize } from '../../features/assets/uploads/utils';
import { db } from '../../core/db';
import { selectFiles, upload } from '../../core/services/file.service';
import { Asset } from './asset';
import { Model } from '../../core/engine/model';
import { OneToMany } from '../../core/engine/decorators';
import { entityPool } from '../../core/engine/engine';
import axios from 'axios';
import { api } from '../../api';

type UploadStatus =
  | 'pending'
  | 'uploading'
  | 'done'
  | 'failed'
  | 'paused'
  | 'finalizing'
  | 'cancelled';

/**
 * The base state defines all the methods base method to handle
 * the state switching,
 */
abstract class UploadState {
  protected constructor(protected upload: Upload) {}

  start() {
    this.upload.changeState(new UploadStateUploading(this.upload));
  }

  async resume() {
    this.upload.changeState(new UploadStateUploading(this.upload));
  }

  pause() {
    this.upload.changeState(new UploadStatePaused(this.upload));
  }

  cancel() {
    this.upload.changeState(new UploadStateCancelled(this.upload));
  }
}

class UploadStatePending extends UploadState {
  constructor(upload: Upload) {
    super(upload);
  }

  start() {
    super.start();

    this.upload.startUploading();
  }

  cancel() {
    super.cancel();

    this.upload.cancelUploading();
  }
}

/**
 * In uploading state, it is only possible to pause or
 * cancel an upload
 */
class UploadStateUploading extends UploadState {
  constructor(upload: Upload) {
    super(upload);
  }

  pause() {
    super.pause();

    this.upload.pauseUploading();
  }

  cancel() {
    super.cancel();

    this.upload.cancelUploading();
  }
}

/**
 * In paused state, it is only possible to resume or
 * cancel an upload
 */
export class UploadStatePaused extends UploadState {
  constructor(upload: Upload) {
    super(upload);
  }

  async resume() {
    const isResuming = await this.upload.resumeUploading();
    if (isResuming) {
      await super.resume();
    }
  }

  cancel() {
    super.cancel();

    this.upload.cancelUploading();
  }
}

export class UploadStateFailed extends UploadState {
  constructor(upload: Upload) {
    super(upload);
  }

  async resume() {
    const isResuming = await this.upload.resumeUploading();
    if (isResuming) {
      await super.resume();
    }
  }

  cancel() {
    super.cancel();

    this.upload.cancelUploading();
  }
}

class UploadStateCancelled extends UploadState {
  constructor(upload: Upload) {
    super(upload);
  }

  start() {
    super.start();

    this.upload.startUploading();
  }
}

export class Upload extends Model {
  state: UploadState;

  name: string;

  assetId: string;

  @OneToMany('upload')
  assets: Asset[];

  reader?: FileReader;

  /**
   * Current chunk cursor
   */
  cursor: number = 0;

  /**
   * Current status of the upload
   */
  status: UploadStatus = 'pending';

  /**
   * List of uploaded blocks
   */
  blocks: {
    blockId: string;
    position: number;
    start: number;
    end: number;
    uploaded: boolean;
  }[] = [];

  /**
   *
   */
  controller?: AbortController;

  /**
   * File to upload;
   */
  file?: File;

  /**
   * Total size of the file
   */
  totalSize: number;

  /**
   * the type of the file for checking when the user attempts to resume in another session
   */
  type: string;

  /**
   * Url to upload the file
   */
  url: string;

  progress = 0;

  constructor(uploadId: string, assetId: string, uploadUrl: string) {
    super('uploads');

    this._id = uploadId;
    this.assets = [];
    this.state = new UploadStatePending(this);
    this.assetId = assetId;
    this.url = uploadUrl;
    this.status = 'pending';
    this.blocks = [];

    makeObservable(this, {
      status: observable,
      progress: observable,
    });
  }

  getStateFromStatus(status: UploadStatus) {
    switch (status) {
      case 'pending':
        return new UploadStatePending(this);
      case 'paused':
        return new UploadStatePaused(this);
      case 'uploading':
      case 'finalizing':
        return new UploadStateUploading(this);
      case 'failed':
        return new UploadStateFailed(this);
      default:
        return new UploadStateCancelled(this);
    }
  }

  changeState(state: UploadState) {
    this.state = state;
  }

  static async fromFile(uploadId: string, assetId: string, file: File, uploadUrl: string) {
    const upload = new Upload(uploadId, assetId, uploadUrl);

    await upload.setFile(file);

    return upload;
  }

  async setFile(file: File) {
    this.name = file.name;
    this.file = file;
    this.totalSize = file.size;
    this.type = file.type;

    await db.uploads.put({
      id: this._id,
      assetId: this.assetId,
      name: this.name,
      status: 'pending',
      blocks: [],
      cursor: 0,
      totalSize: file.size,
      type: file.type,
      url: this.url,
    });
  }

  static async loadDB() {
    return db.uploads
      .where('status')
      .anyOf(['uploading', 'finalizing', 'paused', 'failed'])
      .toArray()
      .then((uploads) => {
        Promise.all(
          uploads.map(async (upload) => {
            const newUpload = new Upload(upload.id, upload.assetId, upload.url || '');
            newUpload.name = upload.name;
            newUpload.totalSize = upload.totalSize;
            newUpload.cursor = upload.cursor;
            newUpload.type = upload.type;
            newUpload.blocks = upload.blocks;
            newUpload.status = upload.status;
            newUpload.updateProgress();

            if (!['done', 'paused', 'failed'].includes(upload.status)) {
              newUpload.status = 'paused';
              await db.uploads.update(upload.id, { status: newUpload.status });
            }

            const state = newUpload.getStateFromStatus(newUpload.status);
            newUpload.changeState(state);
            entityPool.insert(newUpload);
          }),
        ).catch(console.log);
      });
  }

  async start() {
    this.state.start();
  }

  async resume() {
    await this.state.resume();
  }

  async pause() {
    this.state.pause();
  }

  async cancel() {
    this.state.cancel();
  }

  /**
   * Start uploading a file
   */
  async startUploading() {
    this.status = 'uploading';

    await db.uploads.update(this._id, {
      status: this.status,
    });

    this.nextChunk();
  }

  /**
   * Pause uploading a file
   */
  async pauseUploading() {
    if (!this.controller) return;
    this.controller.abort();

    this.status = 'paused';

    await Promise.all([
      db.uploads.update(this._id, {
        status: this.status,
      }),
    ]);
  }

  async cancelUploading() {
    if (!this.controller) return;
    this.controller.abort();
    this.status = 'cancelled';
    await db.uploads.delete(this._id);
  }

  async resumeUploading(): Promise<boolean> {
    if (!this.file) {
      const files = await selectFiles();
      if (!files || files.length === 0) return false;
      const file = files[0];
      if (!file) return false;

      if (file.name !== this.name || file.size !== this.totalSize || file.type !== this.type) {
        alert("You can't resume this upload because the file has changed");
        return false;
      }

      this.file = files[0];
    }

    if (this.status !== 'uploading') {
      this.status = 'uploading';
      await db.uploads.update(this._id, {
        status: this.status,
      });
    }

    const uploadedBlocks = await this.fetchUploadedBlocks(
      this.url,
      this.updateUploadUrl.bind(this),
    );
    if (!uploadedBlocks) {
      await this.handleUploadFailed();
      return false;
    }

    const unfinishedBlocks = this.blocks.filter((block) => !block.uploaded);

    if (unfinishedBlocks.length > 0) {
      for (const block of unfinishedBlocks) {
        try {
          if (!(block.blockId in uploadedBlocks)) {
            const data = await this.readFileChunk(block.start, block.end);
            await this.uploadBlockBlob(
              this.url,
              block.blockId,
              data,
              this.updateUploadUrl.bind(this),
              this.controller?.signal,
              3,
            );
          }

          this.blocks = this.blocks.map((b) => {
            if (b.blockId === block.blockId) {
              return {
                ...b,
                uploaded: true,
              };
            }
            return b;
          });

          await db.uploads.update(this._id, {
            blocks: this.blocks,
          });
        } catch (e) {
          console.log(e);
          this.handleUploadFailed();
          return false;
        }
      }
    }

    this.cursor = this.blocks.length;
    await db.uploads.update(this._id, {
      cursor: this.cursor,
    });

    this.nextChunk();
    return true;
  }

  /**
   * Upload next chunk of the file
   * @private
   */
  private async nextChunk() {
    if (this.status !== 'uploading' || !this.file) return;

    const chunkSize = getChunkSize(this.file.size);

    const start = this.cursor * chunkSize;
    const end = start + chunkSize;

    try {
      const data = await this.readFileChunk(start, end);

      const blockId = btoa(uuid());

      const newBlock = {
        blockId,
        position: this.cursor,
        start,
        end,
        uploaded: false,
      };

      this.blocks = [...this.blocks, newBlock];

      await db.uploads.update(this._id, {
        blocks: this.blocks,
      });

      try {
        await this.uploadBlockBlob(
          this.url,
          blockId,
          data,
          this.updateUploadUrl.bind(this),
          this.controller?.signal,
          3,
        );
      } catch (e) {
        this.handleUploadFailed();
        return;
      }

      this.blocks = this.blocks.map((b) => {
        if (b.blockId === newBlock.blockId) {
          return {
            ...b,
            uploaded: true,
          };
        }
        return b;
      });

      await db.uploads.update(this._id, {
        blocks: this.blocks,
      });

      if (end < this.file!.size) {
        this.incrementCursor();

        await Promise.all([
          db.uploads.update(this._id, {
            cursor: this.cursor,
          }),
        ]);

        this.updateProgress();
        this.nextChunk();
      } else {
        this.finish();
      }
    } catch (e) {
      this.status = 'failed';

      await db.uploads.update(this._id, {
        status: this.status,
      });
    }
  }

  async readFileChunk(start: number, end: number) {
    return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
      if (!this.file) {
        return reject('No file to read from');
      }
      const content = this.file.slice(start, end);

      this.reader ??= new FileReader();

      this.controller = new AbortController();

      this.reader.readAsArrayBuffer(content);

      this.reader.onerror = () => {
        reject();
      };

      this.reader.onload = () => {
        resolve(this.reader!.result);
      };
    });
  }

  /**
   * Finish the upload by putting all the blocks together
   */
  async finish() {
    if (!this._id) return;

    if (this.status !== 'finalizing') {
      await db.uploads.update(this._id, {
        status: 'finalizing',
      });
    }

    try {
      await this.commitBlockBlob(
        this.url,
        this.blocks.sort((a, b) => a.position - b.position).map((block) => block.blockId),
        this.updateUploadUrl.bind(this),
        10,
      );
    } catch (e) {
      console.log(e);
      return;
    }

    if (this.assets && this.assets.length > 0) {
      this.assets[0].finishedUploading();
    } else {
      const asset = Asset.getOne(this.assetId);
      if (asset) {
        asset.finishedUploading();
      }
    }

    entityPool.delete(this);

    this.status = 'done';

    await db.uploads.update(this._id, {
      status: 'done',
      blocks: [],
    });
  }

  private incrementCursor() {
    this.cursor++;
  }

  async handleUploadFailed() {
    if (this.status === 'paused') return false;
    this.status = 'failed';

    await db.uploads.update(this._id, {
      status: this.status,
    });

    this.changeState(new UploadStateFailed(this));
  }

  async updateUploadUrl(url: string) {
    this.url = url;
    await db.uploads.update(this._id, {
      url: this.url,
    });
  }

  /**
   * Return the progress of the confirmed (completed on azure) progress of the upload
   * as a value between 0 and 1
   */
  private getProgress() {
    if (!this.totalSize) {
      this.progress = 0;
    }

    return this.cursor / (this.totalSize / getChunkSize(this.totalSize));
  }

  private updateProgress(currentChunkProgress: number = 0) {
    const blockSize = getChunkSize(this.totalSize) / this.totalSize;
    const currentProgress = Math.ceil(
      (this.getProgress() + blockSize * currentChunkProgress) * 100,
    );
    this.progress = currentProgress > 100 ? 100 : currentProgress;
  }

  private async uploadBlockBlob(
    url: string,
    blockId: string,
    block: string | ArrayBuffer | null,
    updateUploadUrl: (url: string) => void,
    controllerSignal?: AbortSignal,
    retries = 0,
  ): Promise<void> {
    try {
      await axios.put(`${url}&comp=block&blockid=${blockId}`, block, {
        signal: controllerSignal,
        headers: {
          'x-ms-blob-type': 'BlockBlob',
        },
        onUploadProgress: (e) => {
          this.updateProgress(e.progress);
        },
      });
    } catch (e: any) {
      if (e?.response?.status === 403) {
        try {
          const newUrl = await this.generateNewUploadUrl();
          updateUploadUrl(newUrl);
          return this.uploadBlockBlob(
            newUrl,
            blockId,
            block,
            updateUploadUrl,
            controllerSignal,
            retries,
          );
        } catch (e) {
          return Promise.reject('Failed to generate new upload url');
        }
      }
      if ('code' in (e as { code?: string }) && (e as { code: string }).code === 'ERR_CANCELED')
        return Promise.reject('upload paused');
      if (retries > 0) {
        const delay = Math.abs(1000 * (4 - retries));
        await new Promise((resolve) => setTimeout(resolve, Math.max(delay, 1000)));

        await this.uploadBlockBlob(
          url,
          blockId,
          block,
          updateUploadUrl,
          controllerSignal,
          retries - 1,
        );
      } else {
        return Promise.reject('Failed to upload the block');
      }
    }
  }

  async commitBlockBlob(
    url: string,
    blocks: string[],
    updateUploadUrl: (url: string) => void,
    retries = 0,
  ): Promise<void> {
    try {
      let request = '<?xml version="1.0" encoding="utf-8"?>';
      request += '<BlockList>';
      request += blocks.map((block) => `<Latest>${block}</Latest>`).join('');
      request += '</BlockList>';

      await axios.put(`${url}&comp=blocklist`, request, {
        headers: {
          'Content-Type': 'text/plain; charset=UTF-8',
        },
      });
    } catch (e: any) {
      if (e?.response?.status === 403) {
        try {
          const newUrl = await this.generateNewUploadUrl();
          updateUploadUrl(newUrl);
          return this.commitBlockBlob(newUrl, blocks, updateUploadUrl, retries);
        } catch (e) {
          return Promise.reject('Failed to generate new upload url');
        }
      }
      if (retries > 0) {
        const delay = Math.abs(1000 * (4 - retries));
        await new Promise((resolve) => setTimeout(resolve, Math.max(delay, 1000)));
        await this.commitBlockBlob(url, blocks, updateUploadUrl, retries - 1);
      } else {
        return Promise.reject('Failed to commit the blob to the storage');
      }
    }
  }

  private async fetchUploadedBlocks(
    url: string,
    updateUploadUrl: (url: string) => void,
    retries = 0,
  ): Promise<Record<string, number>> {
    try {
      const response = await axios.get(`${url}&comp=blocklist&blocklisttype=uncommitted`);

      if (!response?.data) return Promise.reject('Failed to fetch uploaded blocks');

      const xmlText = new DOMParser().parseFromString(response.data, 'text/xml');
      const blocks = xmlText.getElementsByTagName('Block');
      const blocksMap: Record<string, number> = {};
      for (let i = 0; i < blocks.length; i++) {
        const blockId = blocks[i].getElementsByTagName('Name')[0].textContent;

        const blockSize = blocks[i].getElementsByTagName('Size')[0].textContent;
        if (!blockId || !blockSize) return Promise.reject('Failed to parse uploaded blocks data');

        blocksMap[blockId] = parseInt(blockSize, 10);
      }

      return blocksMap;
    } catch (e: any) {
      if (e?.response.status === 403) {
        try {
          url = await this.generateNewUploadUrl();
          updateUploadUrl(url);
        } catch (e) {
          return Promise.reject('Failed to generate new upload url');
        }
        return this.fetchUploadedBlocks(url, updateUploadUrl, retries);
      }
      if (retries > 0) {
        const delay = Math.abs(1000 * (4 - retries));
        await new Promise((resolve) => setTimeout(resolve, Math.max(delay, 1000)));
        return await this.fetchUploadedBlocks(url, updateUploadUrl, retries - 1);
      } else {
        return Promise.reject('Failed to fetch uploaded blocks');
      }
    }
  }

  private async generateNewUploadUrl() {
    const { data } = await api.get<{ uploadLink: string }>(`/assets/${this.assetId}/upload-link`);
    if (!data?.uploadLink) return Promise.reject('Failed to generate new upload url');
    return data.uploadLink;
  }

  toPOJO() {
    return {};
  }

  static getAll(): Upload[] {
    return super.getAll().filter((entity) => entity instanceof Upload) as Upload[];
  }
}
