import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ContactList } from '../../features/contacts/components/contact-list';
import { Contact } from '../../app/entities/contact';
import { uiStore } from '../../core/stores/ui-store';
import { FloatingActionButton } from '../../components/buttons/floating-action-button';
import { CreateContactDrawer } from '../../features/contacts/components/create-contact.drawer';
import { ScreenContainer } from '../../features/projects/components/styled-project-components';

type MobileContactsListScreenProps = {
  contacts: Contact[];
  spaceId: string;
};

export const MobileContactsListScreen: React.FC<MobileContactsListScreenProps> = ({
  contacts,
  spaceId,
}) => {
  const navigate = useNavigate();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  useEffect(() => {
    uiStore.pageTitle = {
      label: 'Contacts',
    };

    uiStore.showBack = false;
  }, []);

  const selectCreatedContact = (contact: Contact) => {
    navigate(`../${contact._id}`);
  };

  return (
    <ScreenContainer>
      <ContactList contacts={contacts} />

      <FloatingActionButton onClick={() => setIsCreateModalOpen(true)} />

      <CreateContactDrawer
        spaceId={spaceId!}
        isOpen={isCreateModalOpen}
        onCancel={() => setIsCreateModalOpen(false)}
        onSubmit={selectCreatedContact}
      />
    </ScreenContainer>
  );
};

const Container = styled.div`
  margin: 0 1.2rem 1.2rem;
  overflow: auto;
`;
