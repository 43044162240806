import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { CreatableSelect } from '../../../components/select';
import {
  handleWrappingModalOverflow,
  handleRevertWrappingModalOverflow,
} from 'components/select/utils';

interface DepartmentSelectProps {
  onChange: (option: Option) => void;
  value?: string;
  errorMessage?: string;
}

interface Option {
  label: string;
  value: string;
}

const createOption = (department: string | { _id: string; name: string }) => ({
  label: typeof department === 'string' ? department : department.name,
  value: typeof department === 'string' ? department : department._id,
});

const initialState: Option[] = [
  {
    value: 'accounting',
    label: 'Accounting',
  },
  {
    value: 'agency',
    label: 'Agency',
  },
  {
    value: 'art',
    label: 'Art',
  },
  {
    value: 'Camera',
    label: 'Camera',
  },
  {
    value: 'casting',
    label: 'Casting',
  },
  {
    value: 'catering',
    label: 'Catering',
  },
  {
    value: 'construction',
    label: 'Construction',
  },
  {
    value: 'craft',
    label: 'Craft',
  },
  {
    value: 'electric',
    label: 'Electric',
  },
  {
    value: 'grip',
    label: 'Grip',
  },
  {
    value: 'location',
    label: 'Location',
  },
  {
    value: 'make_up_and_hair',
    label: 'Make Up and Hair',
  },
  {
    value: 'miscellaneous',
    label: 'Miscellaneous',
  },
  {
    value: 'music',
    label: 'Music',
  },
  {
    value: 'post_production',
    label: 'Post Production',
  },
  {
    value: 'production',
    label: 'Production',
  },
  {
    value: 'production_office',
    label: 'Production Office',
  },
  {
    value: 'property',
    label: 'Property',
  },
  {
    value: 'script',
    label: 'Script',
  },
  {
    value: 'SFX',
    label: 'SFX',
  },
  {
    value: 'Sound',
    label: 'Sound',
  },
  {
    value: 'studio_operation',
    label: 'Studio Operation',
  },
  {
    value: 'stunt',
    label: 'Stunt',
  },
  {
    value: 'transportation',
    label: 'Transportation',
  },
  {
    value: 'VFX',
    label: 'VFX',
  },
  {
    value: 'video',
    label: 'Video',
  },
  {
    value: 'Wardrobe',
    label: 'Wardrobe',
  },
  {
    value: 'wrangling',
    label: 'Wrangling',
  },
];

export const DepartmentSelect: React.FC<DepartmentSelectProps> = ({
  onChange,
  value,
  errorMessage,
}) => {
  const [options, setOptions] = useState<Option[]>(initialState);
  const [error, setError] = useState<string>();

  useEffect(() => {
    setError(errorMessage || undefined);
  }, [errorMessage]);

  const handleCreate = (inputValue: string) => {
    if (inputValue === '') return;

    if (inputValue.length > 24) {
      return setError('Department name must be less than 24 characters');
    }

    const newOption = createOption(inputValue);
    setOptions([...options, newOption]);

    new Promise<void>((resolve) => resolve()).then(() => {
      handleChange(newOption);
    });
  };

  const handleChange = (option: unknown) => {
    setError('');
    onChange(option as Option);
  };

  const selectedOption = options?.find((option) => option.value === value);

  return (
    <Container>
      <CreatableSelect
        placeholder="Choose from the list or use your own"
        options={options}
        onChange={handleChange}
        onCreate={handleCreate}
        value={selectedOption}
        onMenuOpen={handleWrappingModalOverflow}
        onMenuClose={handleRevertWrappingModalOverflow}
      />
      {error && <Error>{error}</Error>}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  max-width: 100%;
`;

const Error = styled.span`
  position: absolute;
  left: 0;
  bottom: -2.4rem;
  height: 1.6rem;
  color: var(--color-error);
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  letter-spacing: -0.2px;
  line-height: 1.6rem;
  text-align: right;
`;
