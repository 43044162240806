import React from 'react';

import { SelectDropdown } from './select-dropdown';

import type { SingleSelectDropdownProps } from '.';

export const SingleSelectDropdown: React.FC<SingleSelectDropdownProps> = ({
  title,
  options,
  value,
  onValueChange,
  onCreate,
}) => {
  const labels: { [key: string]: string } = {};

  const items: React.ComponentProps<typeof SelectDropdown>['items'] = options?.map((option) => {
    labels[option.value] = option.label;

    return {
      title: option.label,
      isChecked: option.value === value,
      onCheckedChange: (isChecked) => {
        isChecked ? onValueChange(option.value) : onValueChange();
      },
    };
  });

  const selectedItemLabel = value !== undefined ? labels[value] : undefined;

  return (
    <SelectDropdown
      title={title}
      selectedItemsLabel={selectedItemLabel}
      items={items}
      onCreate={onCreate}
    />
  );
};
