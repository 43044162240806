import styled from 'styled-components';

export const Row = styled.div`
  display: table-row;
  height: 6.4rem;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-grayscale-tuna);
  }

  /* 
    TODO: This styling should not be the responsibility of a cell div like implemented below, 
    but rather this style should belong to the table-row instead. 
    
    Check a potential solution (workaround) for the problem of not being 
    able to add spacing on table-row element, while \`border-collapse\` 
    is set to \`collapse\` on the table element. (\`border-collapse:collapse;\`)
    to show borders.  
    https://stackoverflow.com/a/42557550/6596397
  */

  & > div {
    margin: 1.6rem 1rem;
  }
`;

export const Cell = styled.div`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 17px;
  color: var(--color-grayscale-white);
  display: flex;
  align-items: center;
  padding: 0 1rem;
  display: table-cell;
  vertical-align: middle;
`;

export const ActionBox = styled.div`
  display: table-cell;
  vertical-align: middle;
`;
