import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled from 'styled-components';

import { BaseDialog, DialogHeader, DialogFooter } from '../../../components/dialog';
import { ElevatedButton, TextButton } from '../../../components/buttons';
import { Step } from '../../../app/entities/step';
import { TextInput } from '../../../components/text-input/TextInput';

interface RenameStepModalProps {
  onCancel: () => void;
  step: Step;
}

export const RenameStepModal: React.FC<RenameStepModalProps> = ({ onCancel, step }) => {
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object({
    name: yup
      .string()
      .max(32, 'Max 32 characters')
      .min(1, 'Min 1 characters')
      .required('Name is required'),
  });

  const { handleSubmit, control } = useForm({
    defaultValues: {
      name: step.name || '',
    },
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (name: string) => {
    await step.update({ name });
  };

  const onHandleSubmit = async (values: any) => {
    if (!isLoading) {
      setIsLoading(true);

      await onSubmit(values.name);

      setIsLoading(false);
      onCancel();
    }
  };

  return (
    <BaseDialog onClose={onCancel}>
      <DialogHeader title={'Rename step'} onClose={onCancel} />

      <Container>
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
              <TextInput
                ref={ref}
                value={value}
                name={name}
                onChange={onChange}
                label="Name"
                errorMessage={error?.message}
                required
              />
            )}
          />
        </form>
      </Container>

      <DialogFooter
        actions={[
          <TextButton key="cancel" text="Cancel" onClick={onCancel} />,
          <ElevatedButton
            key="submit"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={handleSubmit(onHandleSubmit)}
            text="Update"
          />,
        ]}
      />
    </BaseDialog>
  );
};

const Container = styled.div`
  overflow-y: scroll;
  padding: 1.6rem;
`;
