import React from 'react';
import styled from 'styled-components';
import { PageLoader as PageLoaderComponent } from '../../components/page-loader/PageLoader';

export const PageLoader = () => (
  <Main>
    <Content>
      <PageLoaderComponent />
    </Content>
  </Main>
);

const Main = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
