import React from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';

import { ProjectOverviewHeader } from '../../../features/projects/components/project-overview-header';
import { OverviewTab } from '../components/overview-tab';
import { SettingsTab } from '../components/settings-tab';
import { CalendarTab } from '../components/calendar-tab';
import { Tabs } from '../../../components/tabs/Tabs';
import { Project } from '../../../app/entities/project';
import { ShareLinkList } from '../../../features/share/components/share-link-list';
import { ProjectContextualDrawer } from '../components/project-contextual-drawer';
import { breakPoint } from '../../../app/theme';

interface ProjectRootScreenProps {
  project: Project;
}

const tabs = [
  {
    key: 'overview',
    label: 'Project overview',
  },
  {
    key: 'share-links',
    label: 'Shared links',
  },
  {
    key: 'calendar',
    label: 'Calendar',
  },
  {
    elementId: 'project-settings-tab',
    key: 'project-settings',
    label: 'Settings',
  },
];

const getSelectedTab = (value: string) => {
  const pathname = value.split('/');
  const tabKey = pathname[pathname.length - 1];

  return pathname?.length === 6
    ? ['share-links', 'project-settings', 'calendar'].includes(
        value.substring(value.lastIndexOf('/') + 1)?.toLowerCase(),
      )
      ? tabKey?.toLowerCase()
      : 'overview'
    : 'overview';
};

export const ProjectRootScreen: React.FC<ProjectRootScreenProps> = observer(({ project }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleSwitchTabs = (selectedTab: string) => {
    if (!project) {
      return;
    }

    const key = selectedTab === 'overview' ? '' : selectedTab;

    const href = `/space/${project.spaceId}/projects/${project._id}/${key}`;
    navigate(href);
  };

  const selectedTab = getSelectedTab(location.pathname);

  return (
    <Screen>
      <ProjectOverviewHeader project={project} stages={project.stages} />

      <ProjectContextualDrawer
        project={project}
        selectedTab={selectedTab || 'overview'}
        onSelectTab={handleSwitchTabs}
      />

      <Container>
        <TabsContainer>
          {/* TODO: TabsContainer component is temp until all tabs are updated */}
          <Tabs tabs={tabs} onChange={handleSwitchTabs} selectedTab={selectedTab || 'overview'} />
        </TabsContainer>

        <Routes>
          <Route path="/project-settings" element={<SettingsTab project={project} />} />
          <Route path="/calendar" element={<CalendarTab project={project} />} />
          <Route path="/share-links" element={<ShareLinkList project={project} />} />
          <Route path="/*" element={<OverviewTab project={project} />} />
        </Routes>
      </Container>
    </Screen>
  );
});

const Screen = styled.div`
  height: calc(100vh - var(--tab-bar-height));
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-width: 100%;
`;

const Container = styled.div`
  margin: 1.6rem;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media screen and (min-width: ${breakPoint.medium}px) {
    margin: 3.2rem 2.4rem 0 2.4rem;
  }
`;

// TODO: This component is temp until all tabs are updated
const TabsContainer = styled.div`
  & > div {
    height: unset;
    padding-bottom: 0;
    display: none;

    @media screen and (min-width: ${breakPoint.medium}px) {
      display: flex;
    }
  }
`;
