import React from 'react';
import styled from 'styled-components';

type InputWithBackgroundProps = {
  value?: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string | null;
  name?: string;
  type?: string;
  defaultValue?: string;
  errorMessage?: string;
} & Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'onFocus' | 'onBlur' | 'onKeyDown'
>;

export const InputWithBackground = React.forwardRef<HTMLInputElement, InputWithBackgroundProps>(
  (props, ref) => (
    <Container>
      <Label>{props.label}</Label>
      <Input ref={ref} {...props} placeholder={props.placeholder || ''} />

      <Error isVisible={!!props.errorMessage}>{props.errorMessage}</Error>
    </Container>
  ),
);

InputWithBackground.displayName = 'InputWithBackground';

const Container = styled.div`
  width: 100%;
  position: relative;
`;

type StyledInputProps = {
  errorMessage?: string;
};
const Input = styled.input<StyledInputProps>`
  display: block;
  width: 100%;
  padding: 11px 12px;
  outline: none;
  font-family: inherit;
  background: #31343c;
  border-radius: 8px;
  color: #959bab;
  margin-top: 0.4rem;
  border: ${({ errorMessage }) => (errorMessage ? 'solid 0.1rem var(--color-error)' : 'none')};

  ::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #626878;
  }

  &:focus {
    border: ${({ errorMessage }) => (errorMessage ? 'solid 0.1rem var(--color-error)' : 'none')};
  }
`;

const Label = styled.label`
  font-weight: 400;
  line-height: 20px;
  color: var(--color-grayscale-white);
`;

type StyledErrorProps = {
  isVisible: boolean;
};

const Error = styled.span<StyledErrorProps>`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  min-height: 1.6rem;
  display: block;
  margin-top: 0.8rem;
  color: var(--color-error);
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  line-height: 1.6rem;
`;
