import React, { PropsWithChildren, useRef } from 'react';
import mixpanel from 'mixpanel-browser';

import { globalConfig } from '../../configuration/config';
import { MixpanelContext } from './mixpanel.context';

import type { User } from '../../app/entities/user';

export const MixpanelProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const isInitialized = useRef<boolean>(false);
  const isInitStarted = useRef<boolean>(false);

  const init = () => {
    if (isInitialized.current || isInitStarted.current) {
      return;
    }

    const mixpanelToken = globalConfig.config.mixpanelToken;

    if (!mixpanelToken) {
      return;
    }

    isInitStarted.current = true;

    mixpanel.init(mixpanelToken, {
      loaded: function () {
        isInitialized.current = true;
      },
    });
  };

  const identify = (user: User) => {
    if (!isInitialized.current) {
      console.warn('Mixpanel is not initialized yet!');
      return;
    }

    mixpanel.identify(user._id);

    mixpanel.people.set({
      $email: user.email,
      name: `${user.firstName} ${user.lastName}`,
    });
  };

  return (
    <MixpanelContext.Provider
      value={{
        init,
        track: mixpanel.track,
        identify,
      }}
    >
      {children}
    </MixpanelContext.Provider>
  );
};
