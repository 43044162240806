import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Avatar } from '@producer-io/ui-kit';
import { TrashIcon } from '@radix-ui/react-icons';

import { InlineDescriptiveDropdown } from '../../../components/dropdowns/InlineDescriptiveDropdown';
import { InfoTextLine } from '../../../components/texts/Texts';
import { ActionBox, Cell, Row } from '../../../components/settings-table/styled-table';
import { Member } from '../../../app/entities/member';
import { DropdownMenu, Item } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { PromoteMemberModal } from '../../members/components/promote-member.modal';
import { RemoveMemberModal } from '../../members/components/remove-member.modal';
import { LimitReachedModal } from '../../../components/dialogs/limit-reached.modal';
import { UpgradeIcon } from '../../../components/icons/upgrade-icon';

type MemberItemProps = {
  member: Member;
};

export const MemberItem: React.FC<MemberItemProps> = observer(({ member }) => {
  const [showPromoteModal, setShowPromoteModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isShowingLimitsModal, setIsShowingLimitsModal] = useState(false);

  const isDeletable = member?.role !== 'owner';

  const roleDropdownOptions = useMemo(
    () => [
      {
        value: 'admin',
        label: 'Admin',
        description:
          'Administrator has full access, including managing users and deleting current administrators.',
      },
      {
        value: 'creator',
        label: 'Creator',
        description: 'Creators can create projects, edit everything, download and share files.',
      },
    ],
    [],
  );

  const handleRoleChange = async (role: string) => {
    await member.update({ role });
  };

  const menuItems: Item[] = [];

  const onPromoteMemberClicked = () => {
    if (member.space.canInviteMember()) {
      setShowPromoteModal(true);
    } else {
      setIsShowingLimitsModal(true);
    }
  };

  if (member.role === 'guest') {
    menuItems.push({
      title: 'Promote to creator',
      onSelect: onPromoteMemberClicked,
      icon: <UpgradeIcon />,
    });
  }

  if (isDeletable) {
    menuItems.push({
      title: 'Revoke access',
      onSelect: () => setIsDeleteModalOpen(true),
      type: 'danger' as const,
      icon: <TrashIcon />,
    });
  }

  return (
    <Row>
      {isShowingLimitsModal && (
        <LimitReachedModal type="creators" onCancel={() => setIsShowingLimitsModal(false)} />
      )}

      {showPromoteModal && (
        <PromoteMemberModal
          member={member}
          space={member.space}
          onCancel={() => {
            setShowPromoteModal(false);
          }}
        />
      )}
      {isDeleteModalOpen && (
        <RemoveMemberModal onCancel={() => setIsDeleteModalOpen(false)} member={member} />
      )}
      <Cell>
        <Avatar src={member.avatar} initials={member.initials} theme="dark" />
      </Cell>

      <Cell>{member.fullName}</Cell>
      <Cell>{member.email}</Cell>
      {![undefined, 'guest'].includes(member.role) && (
        <Cell>
          {member.role?.toLowerCase() === 'owner' ? (
            <InfoTextLine value="Owner" />
          ) : (
            <InlineDescriptiveDropdown
              value={member.role || 'guest'}
              label={member.role || 'Role'}
              onChange={handleRoleChange}
              options={roleDropdownOptions}
            />
          )}
        </Cell>
      )}

      <ActionBox>{menuItems.length > 0 && <DropdownMenu items={menuItems} />}</ActionBox>
    </Row>
  );
});
