import React from 'react';
import styled from 'styled-components';
import { calculatePercentage } from '../../../lib/utils/math';
import { Progress } from '../../../components/progress/Progress';

type StageHeaderProps = {
  index: number;
  name: string;
  completed: number;
  total: number;
  isActive?: boolean;
};

export const StageHeader: React.FC<StageHeaderProps> = ({
  index,
  name,
  completed,
  total,
  isActive,
}) => {
  const progress = calculatePercentage(completed, total);

  return (
    <Container>
      <Header>
        <Title isActive={isActive}>
          <StageNumber isActive={isActive}>{index.toString().padStart(2, '0')}</StageNumber>
          <h3>{name}</h3>
        </Title>

        <HeaderProgress>
          <ProgressIndicator>
            {completed}/{total}
          </ProgressIndicator>
        </HeaderProgress>
      </Header>

      <Progress percentage={progress} />
    </Container>
  );
};

type IsActiveProps = {
  isActive?: boolean;
};

const Container = styled.div`
  width: 100%;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8rem;
`;

const StageNumber = styled.div<IsActiveProps>`
  font-size: 1.4rem;
  color: #1f2023;
  border-radius: 0.4rem;
  background-color: ${({ isActive }) => (isActive ? '#fff' : '#8A8F98')};
  margin-right: 0.8rem;
  padding: 0.1rem 0.4rem;
  font-weight: 600;
`;

const Title = styled.div<IsActiveProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  h3 {
    color: ${({ isActive }) => (isActive ? '#fff' : '#8A8F98')};
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

const HeaderProgress = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div:not(:last-child) {
    margin-right: 0.8rem;
  }
`;

const ProgressIndicator = styled.div`
  font-size: 1.4rem;
  color: #8a8f98;
`;
