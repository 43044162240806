export const getVideoHeightAndWidth = (
  url: string,
): Promise<{ videoWidth: number; videoHeight: number }> => {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.src = url;
    video.addEventListener('loadedmetadata', function () {
      return resolve({ videoWidth: this.videoWidth, videoHeight: this.videoHeight });
    });
  });
};
