import { Asset } from '../../app/entities/asset';
import { toastPool } from '../../features/toasts/models/toast-pool';
import { Toast } from '../../features/toasts/models/toast';

export const download = async (assetId: string) => {
  const link = document.createElement('a');
  link.href = await Asset.getDownloadLinkById(assetId);
  document.body.appendChild(link);
  link.click();
  toastPool.insert(new Toast('Download should start soon'));
  document.body.removeChild(link);
};
