import { styled } from 'styled-components';

export const GanttGrid = styled.div`
  display: grid;
  grid-auto-columns: minmax(4.8rem, 1fr);
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fill, 4.8rem);
`;

export const BaseGanttCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--color-texts-low-contrast);
  padding: 0.4rem;
  background-color: transparent;
  position: relative;
  height: 3.6rem;

  &[data-today='true'] {
    color: var(--color-grayscale-white, #fafafa);
  }
`;
