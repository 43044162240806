import React from 'react';

export const ContactLocation = () => (
  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4545 12.4444H17V14H0V12.4444H1.54545V0.777778C1.54545 0.571498 1.62687 0.373667 1.77178 0.227806C1.9167 0.0819442 2.11324 0 2.31818 0H10.0455C10.2504 0 10.4469 0.0819442 10.5919 0.227806C10.7368 0.373667 10.8182 0.571498 10.8182 0.777778V12.4444H12.3636V4.66667H14.6818C14.8868 4.66667 15.0833 4.74861 15.2282 4.89447C15.3731 5.04033 15.4545 5.23816 15.4545 5.44444V12.4444ZM4.63636 6.22222V7.77778H7.72727V6.22222H4.63636ZM4.63636 3.11111V4.66667H7.72727V3.11111H4.63636Z"
      fill="#606570"
    />
  </svg>
);
