import { useEffect, useState } from 'react';
import { Invitation } from '../models/invitation';
import { api } from '../../../api';
import { AxiosError, AxiosResponse } from 'axios';

export const useInvitation = (invitationId: string) => {
  const [invitation, setInvitation] = useState<Invitation | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api
      .get(`/invitations/${invitationId}`)
      .then(({ data }) => {
        setInvitation(data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          setError(error.response.data.message);
        } else {
          setError(error.response || error);
        }

        setIsLoading(false);
      });
  }, [invitationId]);

  return {
    invitation,
    isLoading,
    error,
  };
};
