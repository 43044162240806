const canvasToBlob = (canvas: HTMLCanvasElement): Promise<Blob | null> => {
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob === null) {
        return reject('Could not create image');
      }

      return resolve(blob);
    });
  });
};

// Returns an image source you should set to state and pass
// `{previewSrc && <img alt="Crop preview" src={previewSrc} />}`
export const imagePreview = async (canvas: HTMLCanvasElement) => {
  try {
    const blob = await canvasToBlob(canvas);
    const previewUrl = URL.createObjectURL(blob!);

    return { url: previewUrl, blob: blob! };
  } catch (e) {
    console.error('Failed to create blob');
    return { url: undefined, blob: undefined };
  }
};
