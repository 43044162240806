import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { ContactsListScreen } from './contacts-list.screen';
import { Space } from '../../app/entities/space';
import { uiStore } from '../../core/stores/ui-store';
import { useResponsive } from '../../hooks/useResponsive';
import { MobileContactsListScreen } from './mobile-contacts-list.screen';
import { ContactDetailScreen } from './contact-detail.screen';

type ContactPageProps = {
  space: Space;
};

export const ContactPage: React.FC<ContactPageProps> = observer(({ space }) => {
  const { isMobile } = useResponsive();

  useEffect(() => {
    uiStore.pageTitle = {
      label: 'Contacts',
    };

    uiStore.showBack = false;
  }, []);

  return (
    <Routes>
      {isMobile && (
        <Route
          path="/:contactId/*"
          element={
            <ContactDetailScreenWrapper>
              <ContactDetailScreen />
            </ContactDetailScreenWrapper>
          }
        />
      )}

      <Route
        path="/*"
        element={
          isMobile ? (
            <MobileContactsListScreen contacts={space.contacts} spaceId={space._id} />
          ) : (
            <ContactsListScreen contacts={space.contacts} />
          )
        }
      />
    </Routes>
  );
});

const ContactDetailScreenWrapper = styled.div`
  margin: 2.4rem;
  overflow: auto;
`;
