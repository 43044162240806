import React from 'react';

export const Resend = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5083_471528)">
      <path d="M12 20C7.5816 20 4 16.4184 4 12C4 7.5816 7.5816 4 12 4C16.4184 4 20 7.5816 20 12C20 16.4184 16.4184 20 12 20ZM15.856 16.0608C16.8755 15.0907 17.4924 13.7723 17.5839 12.368C17.6754 10.9637 17.2349 9.57642 16.3499 8.48223C15.4649 7.38804 14.2003 6.66717 12.8079 6.46308C11.4155 6.25898 9.9973 6.58662 8.83551 7.3808C7.67373 8.17498 6.85355 9.37748 6.53819 10.749C6.22283 12.1205 6.43542 13.5604 7.13365 14.7823C7.83187 16.0042 8.96455 16.9184 10.3063 17.3429C11.648 17.7675 13.1004 17.6714 14.3744 17.0736L13.5944 15.6696C12.8047 16.0128 11.9253 16.092 11.087 15.8955C10.2487 15.699 9.49618 15.2372 8.9413 14.5788C8.38643 13.9203 8.05883 13.1004 8.0072 12.2409C7.95557 11.3814 8.18266 10.5282 8.65473 9.80812C9.1268 9.088 9.81864 8.53945 10.6274 8.24398C11.4362 7.94851 12.3187 7.9219 13.1438 8.16812C13.9689 8.41433 14.6925 8.92022 15.2071 9.61059C15.7217 10.301 15.9998 11.1389 16 12H13.6L15.856 16.0608Z" />
    </g>
    <defs>
      <clipPath id="clip0_5083_471528">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
