import styled from 'styled-components';

interface CheckboxProps {
  checked: boolean;
  darkMode?: boolean;
}

export const CommentCheckbox = styled.div<CheckboxProps>`
  height: 1.6rem;
  width: 1.6rem;
  border: var(--color-grayscale-manatee) solid 1.5px;
  border-radius: 50%;
  transition: all 0.1s ease-in;
  position: relative;
  background-color: ${({ checked }) =>
    checked ? 'var(--color-grayscale-manatee)' : 'transparent'};
  font-size: 1rem;
  font-weight: 600;

  &:hover {
    border-color: var(--text-color-white);
    cursor: pointer;
  }

  &::before {
    content: '✓';
    color: ${({ checked }) => (checked ? 'white' : 'transparent')};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
