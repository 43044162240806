import { api } from '../../../api';
import { entityPool } from '../../../core/engine/engine';
import { Invitation } from '../../../app/entities/invitation';

/**
 * Fetch invitations
 */
export const fetchInvitations = async (spaceId: string) => {
  const { data } = await api.get(`/spaces/${spaceId}/invitations`);

  data.forEach((el: any) => {
    const invitation = Object.assign(new Invitation(), el);
    entityPool.insert(invitation);
  });
};
