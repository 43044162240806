import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { ArrowRightIcon } from '@radix-ui/react-icons';
import { useIntersectionObserver } from 'usehooks-ts';
import styled from 'styled-components';

import { dateShort2 } from '../../../assets/contants/dates-fns';
import { formatDate } from '../../../lib/utils/DateHelper';
import { formatDuration, getDuration } from '../utils/date-utils';
import { GanttChartContext } from '../context/gantt-chart.context';
import { getScrollableParent, onIntersectionChange } from '../utils/utils';

import type { StripProps } from './draggable-strip';

export const ParentStrip: React.FC<StripProps> = ({ item, style, index }) => {
  const { stripIndicatorsListRef } = useContext(GanttChartContext);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const scrollableParent = getScrollableParent(containerRef.current);

  const handleIntersectionChange = (isIntersecting: boolean, entry: IntersectionObserverEntry) => {
    const stripIndicatorElement = stripIndicatorsListRef?.current?.[index];

    onIntersectionChange({
      isIntersecting,
      entry,
      scrollableParent,
      stripIndicatorElement,
    });
  };

  const { ref } = useIntersectionObserver({
    root: scrollableParent,
    rootMargin: '0px 0px 0px -248px',
    onChange: handleIntersectionChange,
  });

  if (!item.startDate && !item.dueDate) {
    return null;
  }

  const formattedStartDate = item.startDate
    ? formatDate(item.startDate.toISOString(), dateShort2)
    : '';
  const formattedDueDate = item.dueDate ? formatDate(item.dueDate.toISOString(), dateShort2) : '';

  const startDate = item.startDate || item.dueDate;
  const dueDate = item.dueDate || item.startDate;

  const duration = getDuration(startDate!, dueDate!, 1);

  const durationText = formatDuration(duration);

  return (
    <Container
      id={`strip-${item.id}`}
      ref={(node) => {
        containerRef.current = node;

        ref?.(node);
      }}
      style={style}
    >
      <Left>
        <Title>
          {item.nameSuffix}

          <Caption>{item.name}</Caption>
        </Title>

        <Dates>
          {formattedStartDate}

          <ArrowRightIcon color="var(--color-texts-high-contrast)" width="1.2rem" height="1.2rem" />

          {formattedDueDate}
        </Dates>
      </Left>

      <Caption>{durationText}</Caption>
    </Container>
  );
};

ParentStrip.displayName = 'GanttParentIndicator';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  z-index: 4;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: width 0.3s, left 0.3s;

  height: 3.2rem;
  width: 100%;
  padding: 0.8rem;
  user-select: none;
  cursor: default;

  background-color: var(--color-surfaces-app-background-0);
  border-radius: var(--border-radius-small);
  border-right: var(--border-radius-small, 0.4rem) solid var(--color-actions-main-action);
  border-left: var(--border-radius-small, 0.4rem) solid var(--color-actions-main-action);
`;

const Caption = styled.span`
  /* Captions */
  font-family: Inter;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
  color: var(--color-grayscale-white);
`;

const Dates = styled(Caption)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 3.2rem;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;
