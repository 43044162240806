import React, { useState } from 'react';
import styled from 'styled-components';

import { BaseDialog, DialogFooter, DialogHeader } from '../../../components/dialog';
import { ElevatedButton, OutlinedButton } from '../../../components/buttons';
import { Toast } from '../../toasts/models/toast';
import { toastPool } from '../../toasts/models/toast-pool';
import { catchError } from '../../../core/catch-error';
import { Space } from '../../../app/entities/space';

import type { Member } from '../../../app/entities/member';
import { Collaborator } from '../../../app/entities/collaborator';

type ModalProps = {
  member: Member;
  onCancel: () => void;
};

export const RemoveMemberModal: React.FC<ModalProps> = ({ onCancel, member }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (isLoading) return;

    setIsLoading(true);

    try {
      const collaborators = Collaborator.getByMemberId(member._id);
      await member.delete();
      collaborators.forEach((collaborator) => collaborator.remove());
      toastPool.insert(new Toast('Access revoked'));

      const space = Space.getOne(member.spaceId);
      if (space && space.hasSubscription() && !space.isSelfServing) {
        space.seats!.current--;
      }
    } catch (error) {
      catchError(error);
    } finally {
      setIsLoading(false);
      onCancel();
    }
  };

  return (
    <BaseDialog onClose={onCancel}>
      <DialogHeader
        title={`Are you sure you want to remove "${member.fullName}"`}
        onClose={onCancel}
      />

      <Container>
        <InformationText>
          They won&apos;t be able to access your creative space anymore.
        </InformationText>
      </Container>

      <DialogFooter
        actions={[
          <OutlinedButton key="cancel" text="Cancel" onClick={onCancel} />,
          <ElevatedButton
            variant="danger"
            text="Yes, remove access"
            type="submit"
            onClick={handleSubmit}
            key="delete-submit-button"
            isLoading={isLoading}
            autoFocus
          />,
        ]}
      />
    </BaseDialog>
  );
};

const Container = styled.div`
  padding: 1.6rem;
`;

const InformationText = styled.span`
  font-style: normal;
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: #959bab;
`;
