import React from 'react';

export const OutlineArrowRight = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4729_474678)">
      <path d="M10.1727 7.99974L5.22266 3.04974L6.63666 1.63574L13.0007 7.99974L6.63666 14.3637L5.22266 12.9497L10.1727 7.99974Z" />
    </g>
    <defs>
      <clipPath id="clip0_4729_474678">
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </svg>
);
