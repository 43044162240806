import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { CollaboratorsGrid } from '../../../components/grid';
import { CollaboratorCard } from '../components/CollaboratorCard';
import { AddCollaboratorCard } from '../components/AddCollaboratorCard';
import { AddKeyContactModal } from '../components/add-key-contact.modal';
import { ScreenContainer } from '../../projects/components/styled-project-components';
import { KeyContactHeader } from '../components/key-contact-screen.header';
import { StepDrawer } from 'features/projects/components/step.drawer';

import type { CollaboratorScreenProps } from '../models/types';

export const DirectorScreen: React.FC<CollaboratorScreenProps> = observer(({ project, step }) => {
  const contacts =
    project.contacts.filter((contact) => contact.role?.toLowerCase() === 'director') || [];

  const [isAddContactOpen, setIsAddContactOpen] = useState(false);

  return (
    <React.Fragment>
      <KeyContactHeader step={step} />

      <StepDrawer step={step} />

      <ScreenContainer>
        <CollaboratorsGrid>
          {contacts.map((contact) => (
            <CollaboratorCard key={contact._id} project={project} contact={contact} />
          ))}

          <AddCollaboratorCard onClick={() => setIsAddContactOpen(true)} label="Add director" />
        </CollaboratorsGrid>
      </ScreenContainer>

      <AddKeyContactModal
        projectId={project._id}
        spaceId={project.spaceId}
        onCancel={() => setIsAddContactOpen(false)}
        title="Select director"
        position="Director"
        isOpen={isAddContactOpen}
      />
    </React.Fragment>
  );
});
