import React from 'react';

import { ShootingDayScreen } from '../../../features/shootingdays/screens/shooting-day.screen';

import type { Step } from '../../../app/entities/step';
import { Project } from '../../../app/entities/project';

export interface ShootingDayPageProps {
  project: Project;
  step: Step;
}

export const ShootingDayPage: React.FC<ShootingDayPageProps> = ({ project, step }) => (
  <ShootingDayScreen project={project} step={step} />
);
