import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { object as yupObject, string as yupString } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';

import { Space } from '../../../../app/entities/space';
import { BaseDialog, DialogFooter, DialogHeader } from '../../../../components/dialog';
import { ElevatedButton, OutlinedButton } from '../../../../components/buttons';
import { TextInput } from '../../../../components/text-input/TextInput';

type UpdateVatInfoModalProps = {
  onCancel: () => void;
  space: Space;
};

type FormValues = {
  taxIdentifier: string;
};

const schema = yupObject().shape({
  taxIdentifier: yupString()
    .matches(/^(.*)?\S+(.*)?$/, 'Identification number is required')
    .required('Identification number is required')
    .max(64, 'Identification number must be 64 characters or less'), // Check the limit
});

export const UpdateVatInfoModal: React.FC<UpdateVatInfoModalProps> = ({ onCancel, space }) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      taxIdentifier: space.billingDetails?.taxIdentifier || '',
    },
  });

  const handleOnSubmit = async ({ taxIdentifier }: FormValues) => {
    setIsLoading(true);
    const isUpdated = await space.updateIdentificationNumber({ taxIdentifier });

    setIsLoading(false);

    if (!isUpdated) {
      return;
    }

    onCancel();
  };

  return (
    <BaseDialog onClose={onCancel}>
      <DialogHeader title="Add an identification number" />

      <Container>
        <Description>
          If you are paying for this plan as a business, enter your full Value Added Tax (VAT) or
          Goods and Services Tax (GST) identification number. This helps ensure that your invoices
          are accurate.
        </Description>

        <form id="vat-form" onSubmit={handleSubmit(handleOnSubmit)}>
          <TextInput
            {...register('taxIdentifier')}
            label="VAT/GST ID"
            errorMessage={errors?.taxIdentifier?.message}
            placeholder="1234 5678 91011"
            autoFocus
          />
        </form>
      </Container>

      <DialogFooter
        actions={[
          <OutlinedButton key="cancel" text="Cancel" onClick={onCancel} />,
          <ElevatedButton
            type="submit"
            text="Save"
            key="save"
            form="vat-form"
            isLoading={isLoading}
            disabled={isLoading}
          />,
        ]}
      />
    </BaseDialog>
  );
};

const Container = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  max-width: 40rem;
`;

const Description = styled.p`
  color: var(--color-grayscale-light-slate);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.8rem;
`;
