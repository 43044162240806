import { tabPool } from './tabs-pool';

export class Tab {
  key: string;

  href: string;

  label?: string;

  close() {
    tabPool.delete(this);
  }
}
