import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Member } from '../../../app/entities/member';
import { BaseDialog, DialogFooter, DialogHeader } from '../../../components/dialog';
import { ElevatedButton, OutlinedButton } from '../../../components/buttons';
import {
  previewAddMemberUpdates,
  PreviewAddMemberUpdatesResponse,
} from '../../billing/store/billing.slice';
import { Space } from '../../../app/entities/space';
import { PaymentPreview } from './payment-preview';
import { Tag } from '../../../components/tag/Tag';
import { HasServerResponse } from '../../../components/PanelContainer';
import { toastPool } from '../../toasts/models/toast-pool';
import { Toast } from '../../toasts/models/toast';

interface PromoteMemberModalProps {
  member: Member;
  space: Space;
  onCancel: () => void;
}

export const PromoteMemberModal: React.FC<PromoteMemberModalProps> = ({
  member,
  space,
  onCancel,
}) => {
  const [isFetchingPrice, setIsFetchingPrice] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState<PreviewAddMemberUpdatesResponse | null>(
    null,
  );
  const [serverError, setServerError] = useState('');
  const [isPromoting, setIsPromoting] = useState(false);

  const handlePromote = async () => {
    if (isPromoting) return;

    setIsPromoting(true);
    try {
      await member.promote();
      toastPool.insert(new Toast('Member successfully promoted', 'success'));
      onCancel();
      setIsPromoting(false);
    } catch (e) {
      setIsPromoting(false);
    }
  };

  useEffect(() => {
    if (!space?.hasSubscription()) return;

    setIsFetchingPrice(true);
    previewAddMemberUpdates(space._id, 1)
      .then((response) => {
        setPaymentDetails(response || null);
        setIsFetchingPrice(false);
      })
      .catch((e) => {
        setServerError(`${e}`);
        setIsFetchingPrice(false);
      });
  }, [space, space?.subscription?.subscriptionId]);

  return (
    <BaseDialog onClose={onCancel}>
      <DialogHeader title={`Promote ${member.fullName}`} onClose={onCancel} />
      <Container>
        {serverError && (
          <ServerErrorMessage>
            <Tag text={serverError} />
          </ServerErrorMessage>
        )}
        <p>
          By promoting a guest to a creator, they will gain access to your whole space and you will
          be charged for the additional creator.
        </p>
      </Container>

      {space?.hasSubscription() && (
        <PaymentPreview isLoading={isFetchingPrice} paymentDetails={paymentDetails} />
      )}

      <DialogFooter
        actions={[
          <OutlinedButton key="cancel" text="Cancel" onClick={onCancel} disabled={isPromoting} />,
          <ElevatedButton
            key="validate"
            onClick={handlePromote}
            text="Promote to creator"
            isLoading={isPromoting}
            autoFocus
          />,
        ]}
      />
    </BaseDialog>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1.6rem;
  max-width: 56rem;

  p {
    line-height: 2.4rem;
  }
`;

const ServerErrorMessage = styled(HasServerResponse)`
  display: flex;
  justify-content: start;
  margin-top: -3rem;
  margin-bottom: 2rem;
  max-width: 100%;

  & > div {
    max-width: 32rem;
  }
`;
