import React from 'react';
import { CSS } from '@dnd-kit/utilities';
import { observer } from 'mobx-react-lite';
import { useSortable } from '@dnd-kit/sortable';

import { ShotListItem } from '../../../features/shot-list/shot-list-item';
import { MobileShotListItem } from '../../../features/shot-list/shot-list-item.mobile';
import { DayBreakStripComponent } from '../../../features/schedule/components/day-break-strip';
import { MobileDayBreakStrip } from '../../../features/schedule/components/day-break-strip.mobile';
import { useSelection, Modifier } from '../../../core/contexts/selection.context';
import { Project } from '../../../app/entities/project';
import { Stripboard } from '../../../app/entities/stripboard';
import { Strip } from 'features/schedule/models/types';

type ShotListStripProps = {
  stripboard: Stripboard;
  strip: Strip;
  project: Project;
  style?: any;
  isSelected?: boolean;
  isActive?: boolean;
  isDragging?: boolean;
  onClick?: (stripId: string, modifier?: Modifier) => void;
  readOnly?: boolean;
  screenType?: 'desktop' | 'mobile';
};

export const SortableShotListStrip: React.FC<ShotListStripProps> = observer(
  ({ stripboard, project, strip, isSelected, isActive, readOnly }) => {
    const { select } = useSelection();

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: strip._id,
      data: { strip },
      disabled: readOnly || strip.type === 'dayBreak',
      animateLayoutChanges: () => true,
      transition: {
        duration: 150,
        easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
      },
    });

    const isActiveCard = isActive && isSelected;

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      cursor: 'grab',
      borderRadius: '0.6rem',
      userSelect: 'none',
      ...(isActiveCard && !isDragging && { opacity: 0.5 }),
    };

    return (
      <ShotListStrip
        ref={setNodeRef}
        stripboard={stripboard}
        project={project}
        strip={strip}
        style={style}
        onClick={select}
        isSelected={isSelected && !isActiveCard}
        isDragging={isDragging}
        screenType="desktop"
        {...attributes}
        {...listeners}
      />
    );
  },
);

const stripType: {
  [key: string]: {
    [key: string]: any;
  };
} = {
  desktop: {
    shot: ShotListItem,
    dayBreak: DayBreakStripComponent,
  },
  mobile: {
    shot: MobileShotListItem,
    dayBreak: MobileDayBreakStrip,
  },
};

export const ShotListStrip = observer(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLTableRowElement, ShotListStripProps>(
    (
      {
        screenType = 'desktop',
        stripboard,
        project,
        strip,
        isSelected,
        onClick,
        ...draggableProps
      },
      ref,
    ) => {
      const Component = stripType[screenType][strip.type];

      return (
        <Component
          ref={ref}
          project={project}
          strip={strip}
          stripboard={stripboard}
          onClick={onClick}
          isSelected={isSelected}
          position={strip.position}
          {...draggableProps}
          isShotList
        />
      );
    },
  ),
);

ShotListStrip.displayName = 'ShotListStrip';
