import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from '../locales/en';
import { fr } from '../locales/fr';

i18n.use(initReactI18next).init({
  resources: {
    en: en,
    fr: fr,
  },
  supportedLngs: ['en', 'fr'],
  lng: 'en',
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false,
  },
});

export { i18n };
