import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { fetchAsset } from '../../features/assets/store/assets.slice';
import { VideoCompareScreen } from './video-compare.screen';
import { Asset } from '../../app/entities/asset';

export const VideoComparePage: React.FC = () => {
  const { assetId } = useParams();

  const asset = Asset.getOne(assetId!)!;

  useEffect(() => {
    fetchAsset(assetId!);
  }, [assetId]);

  return <>{asset && <VideoCompareScreen asset={asset!} />}</>;
};
