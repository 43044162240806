type StorageRegion = {
  label: string;
  value: string;
  description: string;
};

export type Config = {
  webSocketHost: string;
  version: string;

  baseApiUrl: string;

  environment?: string;

  keycloak: {
    url: string;
    realm: string;
    clientId: string;
  };

  stripePublishableKey?: string;

  mixpanelToken?: string;

  paddleToken?: string;

  paddlePriceItems: string[];

  csvImporterKey?: string;

  storage_regions?: StorageRegion[];

  hotjar?: {
    siteId: number;
    version: number;
  };

  novuAppIdentifier?: string;

  checkoutRedirectUrl?: string;

  marketplace: {
    tenantId: string;
    clientId: string;
    redirectUrl: string;
  };
};

/**
 * Default config for local development
 */
export const defaultConfig: Config = {
  version: '1',

  baseApiUrl: 'http://127.0.0.1:9080/v1',

  webSocketHost: '127.0.0.1:9080',

  environment: 'development',

  stripePublishableKey:
    'pk_test_51MjzstLx4DpACCehzx5BkNaN8X6v5SODh2tq9RrSXDTf3H20LVisMGfPPDQgPgZZ9O2PuSiy5VJZCcjPoE0lEVpy00WJbMe4wt',

  paddleToken: 'test_27f9363557a543361162eb3d730',

  paddlePriceItems: [
    'pri_01hmbva1s6mjb80q3jwxc14cfe',
    'pri_01hmbv8hhx3nf5602pn9r2agp4',
    'pri_01hm9j96typ09azene08x8b2ek',
    'pri_01hm9ja91a73ryxrqd3vqs5tck',
  ],

  keycloak: {
    url: 'http://127.0.0.1:8008/',
    realm: 'producer_realm',
    clientId: 'web-local',
  },

  marketplace: {
    tenantId: '12cf2055-17fd-48fc-bff0-a591d4b061fa',
    clientId: 'a965e004-b861-4e8f-ab9f-22e7abdece33',
    redirectUrl: 'https://app.staging.the-producer.io/marketplace/activation/',
  },
};

export class GlobalConfig {
  private _config: Config = defaultConfig;
  isReady: boolean = false;

  set config(value: Config) {
    if (this.isReady) {
      throw new Error(
        'Global config has already been defined and now has been called second time. This is probably not intended.',
      );
    }

    this.isReady = true;
    this._config = value;
  }

  get config(): Config {
    if (!this.isReady) {
      throw new Error(
        'Global config has not been defined yet. Be sure to call the getter only after the config has been downloaded and set. Probable cause is accessing globalConfig in static context.',
      );
    }

    return this._config;
  }
}

export const globalConfig = new GlobalConfig();
export const globalConfigUrl = 'config.json';
