import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Avatar } from '@producer-io/ui-kit';

import { Invite, Member, Name, TextWrapper } from './assign-contacts.modal';
import { ContactSearchBar } from './contact-search.bar';
import { breakPoint } from '../../../app/theme';

import type { Contact } from '../../../app/entities/contact';

type ContactSearchProps = {
  contacts: Contact[];

  onAdd: (contactId: string) => void;
};

type SearchField = 'firstName' | 'lastName' | 'email';
type SearchFieldsProps = Array<keyof Pick<Contact, SearchField>>;
const searchFields: SearchFieldsProps = ['firstName', 'lastName', 'email'];

const searchContact = (contact: Contact, value: string) => {
  return searchFields.some((searchField) =>
    value
      .split(' ')
      .some((inputValue: string) =>
        contact[searchField]?.toLowerCase().includes(inputValue.toLowerCase()),
      ),
  );
};

export const ContactSearch: React.FC<ContactSearchProps> = ({ contacts, onAdd }) => {
  const [searchInput, setSearchInput] = useState('');
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>(contacts);

  useEffect(() => {
    if (searchInput) {
      const filteredCandidates = contacts?.filter((candidate) =>
        searchContact(candidate, searchInput),
      );

      return setFilteredContacts(filteredCandidates);
    }

    setFilteredContacts(contacts);
  }, [searchInput, contacts]);

  return (
    <React.Fragment>
      <ContactSearchBar key="search-candidates" value={searchInput} onChange={setSearchInput} />

      <SearchedCandidatesContainer>
        {filteredContacts?.length ? (
          filteredContacts.map((contact) => {
            return (
              <Member key={`contacts-${contact._id}`} onClick={() => onAdd(contact._id)}>
                <TextWrapper>
                  <Avatar src={contact.avatar || ''} initials={contact.initials} theme="dark" />
                  <Name>{contact.fullName}</Name>
                </TextWrapper>

                <Invite>Add</Invite>
              </Member>
            );
          })
        ) : (
          <Name>No contacts found</Name>
        )}
      </SearchedCandidatesContainer>
    </React.Fragment>
  );
};

const SearchedCandidatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.6rem;
  padding-inline-end: 1.2rem;
  overflow-y: auto;
  padding-bottom: 1.6rem;

  @media screen and (max-width: ${breakPoint.small - 1}px) {
    height: 100%;
  }

  @media screen and (min-width: ${breakPoint.small}px) {
    min-height: 50%;
    max-height: 50%;
    border-bottom: 0.1rem solid var(--color-grayscale-trout, #494e5a);

    & > div {
      cursor: pointer;
    }
  }
`;
