import { DownloadIcon, EnterIcon, Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import React, { useState } from 'react';

import { download } from '../../../lib/utils/download';
import { RenameDialog } from '../../../components/dialogs/rename.dialog';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { catchError } from '../../../core/catch-error';
import { Asset } from '../../../app/entities/asset';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { ConfigureLinkTypeAssetModal } from '../../../features/process/components/configure-link-type-asset.modal';

type AssetMenuProps = {
  asset: Asset;
  onOpen: () => void;
};

export const AssetMenu: React.FC<AssetMenuProps> = ({ asset, onOpen }) => {
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isEditLinkModalOpen, setIsEditLinkModalOpen] = useState(false);

  const handleDownload = async (event: Event) => {
    event.stopPropagation();
    if (!asset.uploaded) return;
    await download(asset._id);
  };

  const handleDelete = () => {
    asset.delete();
  };

  const handleRenameFile = async (name: string) => {
    try {
      await asset.update({ name });
    } catch (e) {
      catchError(e);
    }
  };

  const items = [
    {
      onSelect: onOpen,
      title: asset.type === 'link' ? 'Open in new tab' : 'Open',
      icon: <EnterIcon />,
    },
    ...(asset.type === 'link'
      ? [
          {
            onSelect: () => setIsEditLinkModalOpen(true),
            title: 'Edit link',
            icon: <Pencil1Icon />,
          },
        ]
      : []),
    ...(asset.type !== 'link'
      ? [
          {
            onSelect: () => setIsRenameModalOpen(true),
            title: 'Rename',
            icon: <Pencil1Icon />,
          },
          {
            onSelect: handleDownload,
            title: 'Download',
            icon: <DownloadIcon />,
            disabled: !asset.uploaded,
            tooltipText: 'File is still uploading.',
          },
        ]
      : []),
    {
      onSelect: () => setDeleteModalIsOpen(true),
      title: 'Delete',
      icon: <TrashIcon />,
      type: 'danger' as 'danger',
    },
  ];

  const handleOnEditLink = async (values: { address?: string; name?: string }) => {
    const updatedAsset = await asset.update({
      ...(values.name !== undefined && { name: values.name }),
      ...(values.address && { url: values.address }),
    });

    if (updatedAsset.meta) asset.meta = updatedAsset.meta;
    if (updatedAsset.name) asset.name = updatedAsset.name;
  };

  return (
    <>
      <DropdownMenu items={items} />

      {isRenameModalOpen && (
        <RenameDialog
          defaultName={asset.name}
          onSubmit={handleRenameFile}
          onCancel={() => setIsRenameModalOpen(false)}
          title="Rename file"
          label="File name"
        />
      )}

      {isEditLinkModalOpen && (
        <ConfigureLinkTypeAssetModal
          onSubmit={handleOnEditLink}
          onCancel={() => setIsEditLinkModalOpen(false)}
          asset={asset}
        />
      )}

      {deleteModalIsOpen && (
        <DeleteDialog
          title={'Delete file ?'}
          text={`Are you sure you want to delete ${asset?.name} ?`}
          onCancel={() => setDeleteModalIsOpen(false)}
          onSubmit={handleDelete}
        />
      )}
    </>
  );
};
