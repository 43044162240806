import React, { useState } from 'react';
import styled from 'styled-components';
import { object as yupObject, string as yupString } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import { ElevatedButton } from 'components/buttons/elevated-button';
import { TextButton } from 'components/buttons/text-button';
import { BaseDialog, DialogFooter, DialogHeader } from 'components/dialog';
import { DepartmentSelect } from './department-select';
import { Team } from '../../../app/entities/team';

interface CreateNewDepartmentModalProps {
  team: Team;
  onClose: () => void;
  onCancel: () => void;
}

export const CreateNewDepartmentModal: React.FC<CreateNewDepartmentModalProps> = ({
  team,
  onClose,
  onCancel,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const schema = yupObject().shape({
    departmentName: yupString()
      .matches(/^(.*)?\S+(.*)?$/, 'Department is required')
      .required('Department is required'),
  });

  const { handleSubmit, control } = useForm<{ departmentName: string }>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const onSubmit = async (values: { departmentName: string }) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      await team.addDepartment(values.departmentName);
      setIsLoading(false);
      onClose();
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <BaseDialog onClose={onClose}>
      <DialogHeader title={'Create Department'} onClose={onClose} />

      <Container>
        <form id="add-department-form" onSubmit={handleSubmit(onSubmit)}>
          <Label>Name</Label>
          <Controller
            control={control}
            name="departmentName"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <DepartmentSelect
                value={value}
                onChange={({ label }: { label: string; value: string }) => onChange(label)}
                errorMessage={error?.message}
              />
            )}
          />
        </form>
      </Container>

      <DialogFooter
        actions={[
          <TextButton key="cancel" text="Cancel" onClick={onCancel} />,
          <ElevatedButton
            key="add"
            form="add-department-form"
            type="submit"
            isLoading={isLoading}
            text={'Add'}
          />,
        ]}
      />
    </BaseDialog>
  );
};

const Container = styled.div`
  width: 64rem;
  max-width: 100%;
  padding: 1.6rem;
`;

const Label = styled.label`
  display: inline-block;
  font-weight: 600;
  color: white;
  line-height: 1.1rem;
  margin-bottom: 1.2rem;
`;
