import React, { useState } from 'react';
import styled from 'styled-components';
import { Pencil1Icon } from '@radix-ui/react-icons';

import { PhotoCropperModal } from '../../../components/photo-cropper-modal/photo-cropper.modal';
import { IconButton } from '../../../components/buttons';
import { IconButtonWrapper } from '../../../components/buttons/icon-button';
import { breakPoint } from '../../../app/theme';

import type { Project } from '../../../app/entities/project';

export type HeaderSimpleWidgetProps = {
  project: Project;
  src: string;
  text: string;
};

export const HeaderSimpleWidget: React.FC<HeaderSimpleWidgetProps> = ({ project, src, text }) => {
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);

  const handleUpload = (blob: Blob) => {
    project.update({
      [text.toLowerCase()]: {
        logo: {
          fileType: blob.type,
          size: blob.size,
          file: blob,
        },
      },
    });
  };

  return (
    <Card>
      <MobileHeader>
        <Text>{text}</Text>

        <IconButtonWrapper>
          <IconButton
            variant="dark"
            icon={<Pencil1Icon />}
            onClick={() => setIsImageModalOpen(true)}
            size="small"
          />
        </IconButtonWrapper>
      </MobileHeader>

      {src ? (
        <LogoHolder>
          <Logo src={src} />
        </LogoHolder>
      ) : (
        <LogoPlaceholder onClick={() => setIsImageModalOpen(true)}>Add logo</LogoPlaceholder>
      )}

      <IconButtonWrapper>
        <IconButton
          variant="dark"
          icon={<Pencil1Icon />}
          onClick={() => setIsImageModalOpen(true)}
        />
      </IconButtonWrapper>

      {isImageModalOpen && (
        <PhotoCropperModal
          theme="light"
          onCancel={() => setIsImageModalOpen(false)}
          onSave={handleUpload}
          aspectRatio={{
            minimum: 1,
            maximum: 4.5,
          }}
        />
      )}
    </Card>
  );
};

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--color-grayscale-tuna);
  padding: 0.8rem;
  border-radius: 0.4rem;
  min-width: 0;

  & > ${IconButtonWrapper} {
    display: none;
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    padding: 1.2rem 2.4rem;
    flex-direction: row;
    gap: unset;

    & > ${IconButtonWrapper} {
      display: block;
    }
  }
`;

const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media screen and (min-width: ${breakPoint.medium}px) {
    width: unset;

    & > ${IconButtonWrapper} {
      display: none;
    }
  }
`;

const LogoHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-grayscale-white);
  border-radius: 0.6rem;
  width: 100%;
  padding: 0.8rem;
  aspect-ratio: 1;

  @media screen and (min-width: ${breakPoint.medium}px) {
    height: 100%;
    width: 38%;
    height: 7.6rem;
    padding: 0.8rem 1.6rem;
    aspect-ratio: unset;
  }
`;

const Logo = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const LogoPlaceholder = styled.div`
  all: unset;
  aspect-ratio: 1;
  font-size: 2rem;
  font-weight: 600;
  color: var(--color-grayscale-trout);
  cursor: pointer;
  padding: 1.6rem;
  text-align: center;

  @media screen and (min-width: ${breakPoint.medium}px) {
    font-size: 3rem;
    line-height: 3.6rem;
    height: 4.4rem;
    aspect-ratio: unset;
  }
`;

const Text = styled.h6`
  font-weight: 500;
  font-size: 1.2rem;
  color: var(--color-grayscale-white);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (min-width: ${breakPoint.medium}px) {
    font-size: 2rem;
    line-height: 2.4rem;
  }
`;
