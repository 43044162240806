import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { Loader } from '../loader/Loader';
import { StyledButton } from './styled-buttons';

type ElevatedButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  text: string;
  isLoading?: boolean;
  tabIndex?: number;
  disabled?: boolean;
  variant?: 'default' | 'primary' | 'secondary' | 'success' | 'danger' | 'warning';
  icon?: React.ReactNode;
  endIcon?: React.ReactNode;
  isFullWidth?: boolean;
};

export const ElevatedButton: React.FC<ElevatedButtonProps> = ({
  icon,
  endIcon,
  text,
  isLoading,
  tabIndex,
  disabled,
  variant = 'primary',
  isFullWidth,
  ...buttonProps
}) => (
  <Button
    disabled={disabled || isLoading}
    tabIndex={tabIndex}
    data-variant={variant}
    data-full={isFullWidth}
    {...buttonProps}
  >
    {isLoading ? (
      <Loader small />
    ) : (
      <>
        {icon}
        {text}
        {endIcon}
      </>
    )}
  </Button>
);

const Button = styled(StyledButton)`
  gap: 0.8rem;
  padding: 0 1.6rem;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: normal;

  & svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`;
