import React, { useState } from 'react';
import styled from 'styled-components';

import { useOnClickOutside } from '../../hooks/useOutsideClick';
import { DrawerIcon } from '../../components/icons/drawer-icon';
import { Drawer } from './drawer';
import { breakPoint } from '../theme';
import { Space } from '../entities/space';
import { User } from '../entities/user';

type LeftDrawerProps = {
  currentUser: User;
  space: Space;
  hasBanner: boolean;
};

export const LeftDrawer: React.FC<LeftDrawerProps> = ({ currentUser, space, hasBanner }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
  const handleDrawerClose = () => setIsDrawerOpen(false);

  const { ref } = useOnClickOutside(handleDrawerClose);

  return (
    <div ref={ref}>
      <MenuTrigger onClick={toggleDrawer}>
        <DrawerIcon />
      </MenuTrigger>

      <Drawer
        currentUser={currentUser}
        space={space}
        isOpen={isDrawerOpen}
        onClose={handleDrawerClose}
        hasBanner={hasBanner}
      />
    </div>
  );
};

const MenuTrigger = styled.div`
  padding: 1.2rem;
  margin: 0 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: none;
  }
`;
