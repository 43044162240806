import React, { useState } from 'react';
import styled from 'styled-components';

import { PlanCardCheckOn } from '../../../components/icons';
import { ElevatedButton, OutlinedButton } from '../../../components/buttons';
import { Plan } from '../../../routes/settings/plans-settings.route';
import { breakPoint } from '../../../app/theme';

type CardProps = Omit<Plan, 'productId' | 'price'> & {
  isSelfServing: boolean;
  onSelect(priceId: string): void;
  handleDowngrade: () => void;
  handleUpgrade: () => void;
};

export const PlanCard: React.FC<CardProps> = ({
  name,
  description,
  label,
  features,
  disabled,
  formattedPrice,
  priceId,
  status,
  interval,
  isSelfServing,
  onSelect,
  handleDowngrade,
  handleUpgrade,
}) => {
  const renderButton = () => {
    const [isLoading, setIsLoading] = useState(false);

    const cycleName = interval === 'month' ? 'monthly' : 'yearly';

    if (disabled) {
      return <ElevatedButton text="Get in touch" onClick={handleUpgrade} isFullWidth />;
    }

    const handleOnSelect = async () => {
      setIsLoading(true);
      await onSelect(priceId);
      setTimeout(() => setIsLoading(false), 500);
    };

    switch (status) {
      case 'currentPlan':
        return <ElevatedButton text="Current plan" variant="secondary" isFullWidth disabled />;

      case 'changeInterval':
        return (
          isSelfServing && (
            <ElevatedButton
              text={`Change to ${cycleName}`}
              variant="default"
              onClick={handleDowngrade}
              isFullWidth
            />
          )
        );

      case 'upgrade':
        return (
          isSelfServing && (
            <ElevatedButton
              text="Start free trial"
              variant="primary"
              onClick={handleOnSelect}
              isFullWidth
              isLoading={isLoading}
              disabled={isLoading}
            />
          )
        );

      case 'downgrade':
        return (
          isSelfServing && (
            <OutlinedButton text="Downgrade plan" onClick={handleDowngrade} isFullWidth />
          )
        );

      default:
        return (
          isSelfServing && (
            <ElevatedButton text="Get in touch" onClick={handleUpgrade} isFullWidth />
          )
        );
    }
  };

  return (
    <Container data-current={status === 'currentPlan'}>
      <PlanContainer>
        <PlanDetails>
          <Label>{label}</Label>
          <Title>
            {name.split(' ')[0]}
            <br />
            {name.split(' ')[1]}
          </Title>
          <Description>{description}</Description>
        </PlanDetails>
      </PlanContainer>

      {formattedPrice ? (
        <Price>
          <span>{formattedPrice}</span>
          <span>per user/month</span>
        </Price>
      ) : (
        <CustomPrice>
          <b>from $1'000</b> /month
        </CustomPrice>
      )}

      {renderButton()}

      <PlanInformation>
        {features?.map((info) => {
          return (
            <InformationCard key={info}>
              <PlanCardCheckOn />
              <InformationText>{info}</InformationText>
            </InformationCard>
          );
        })}
      </PlanInformation>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  min-width: 27.2rem;
  height: 100%;
  padding: 1.6rem;
  background: var(--color-grayscale-eerie-black);
  border-radius: 0.4rem;
  flex: 1 1 0;

  &[data-disabled='true'] {
    background: var(--color-grayscale-eerie-black);

    h1,
    p,
    span {
      color: var(--color-grayscale-arsenic) !important;
    }

    img {
      opacity: 0.5;
    }

    svg,
    svg * {
      fill: var(--color-grayscale-arsenic);
    }
  }

  &[data-current='true'] {
    box-sizing: border-box;
    box-shadow: inset 0 0 0 2px var(--color-grayscale-tuna);
    background: var(--color-grayscale-charleston);
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    min-height: auto;
    height: 100%;
  }
`;

const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const PlanDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  min-height: 11rem;
`;

const Label = styled.label`
  color: var(--color-grayscale-light-slate);
  font-weight: 400;
  letter-spacing: 0.1rem;
  margin-bottom: 0.4rem;
`;

const Title = styled.h2`
  color: var(--color-grayscale-white);
  font-size: 2.4rem;
  font-weight: 600;
`;

const Description = styled.p`
  color: var(--color-grayscale-light-slate);
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 1.6rem;
`;

const Price = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.4rem;

  & > span:first-of-type {
    color: var(--color-grayscale-white);
    font-size: 2.4rem;
    font-weight: 700;
  }

  color: var(--color-grayscale-light-slate);
  font-size: 1.6rem;
  font-weight: 500;
`;

const PlanInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const InformationCard = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

const InformationText = styled.p`
  color: var(--color-grayscale-light-slate);
  font-size: 1.4rem;
  font-weight: 400;
`;

const CustomPrice = styled.h6`
  & > b {
    color: var(--color-grayscale-white);
    font-size: 2.4rem;
    font-weight: 700;
  }

  color: var(--color-grayscale-light-slate);
  font-size: 1.6rem;
  font-weight: 500;
`;
