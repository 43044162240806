import React from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { OutlineArrowRight } from '@producer-io/ui-kit';

import { ProducerLogo } from '../../components/icons/producer-logo';
import { UserDropdown } from './components/user-dropdown';
import { BackButton } from '../../features/settings/components/settings-page-layout';
import { Space } from '../../app/entities/space';

export const OnBoardingLayout: React.FC<React.PropsWithChildren> = observer(({ children }) => {
  const spaces = Space.getAll();
  const hasSpaces = spaces.length;

  const navigate = useNavigate();
  const location = useLocation() as { state: { spaceId: string } };

  const goBack = () => {
    const spaceId = location?.state?.spaceId;
    const href = spaceId ? `/space/${spaceId}` : `/`;

    navigate(href);
  };

  return (
    <Page>
      <PageHeader>
        <UserDropdown />

        {hasSpaces !== 0 && (
          <BackButton onClick={goBack}>
            <OutlineArrowRight />
            <span>Back to PRODUCER</span>
          </BackButton>
        )}
      </PageHeader>

      <Container>
        <ProducerLogo />

        <Content>{children}</Content>
      </Container>
    </Page>
  );
});

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.2rem;
  width: 100%;
  padding-bottom: 1.6rem;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  padding: 1.6rem;
  height: 100%;
  background-color: var(--color-grayscale-eerie-black);
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
`;
