import * as Sentry from '@sentry/react';
import { globalConfig } from '../configuration/config';

import type { User } from '../app/entities/user';

export const initSentry = () => {
  Sentry.init({
    environment: globalConfig.config.environment,
    release: globalConfig.config.version,
    dsn: 'https://7f7c223d760d423f86028292cc1c832d@o4505051139866624.ingest.sentry.io/4505051144978432',
    /*
     * 'tracesSampleRate' value should be at its min in production.
     * Goal is finding the balance between acceptable sampling rate and quota usage
     * for getting real performance insights.
     * Currently it's set at 50% of requests
     */
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.5, // This sets the sample rate at 1%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracePropagationTargets: [/https:\/\/api.the-producer.io\/v\d+/],
  });
};

export const setSentryUser = (user: Pick<User, '_id' | 'email'>) => {
  Sentry.setUser({
    id: user._id,
    email: user.email,
  });
};
