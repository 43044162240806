import React from 'react';
import styled from 'styled-components';

import { CharacterItem } from './character-item';

import type { Character } from '../../../app/entities/character';

type CharacterCardsProps = {
  characters: Character[];
};

export const CharacterCards: React.FC<CharacterCardsProps> = ({ characters }) => (
  <Container>
    {characters?.map((character) => (
      <CharacterItem key={character._id} character={character} />
    ))}
  </Container>
);

const Container = styled.div`
  display: grid;
  position: relative;
  grid-gap: 2.4rem;
  font-size: 1.6rem;
  flex: 1;
  grid-auto-rows: 1fr;
  padding-bottom: var(--spacing-bottom-screen-container);

  @media screen and (min-width: 420px) {
    grid-template-columns: 1fr;
  }

  @media screen and (min-width: 750px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1124px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media screen and (min-width: 1920px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;
