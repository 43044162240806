import React from 'react';

export const Upload = () => (
  <svg width="30px" height="40px" viewBox="0 0 30 40">
    <g id="Web-App" stroke="none" fill="none">
      <g id="Projects-Admin" transform="translate(-1185.000000, -316.000000)" fill="#96A3A1">
        <g id="Projects" transform="translate(91.000000, 161.000000)">
          <g id="Drag&amp;Drop" transform="translate(897.000000, 0.000000)">
            <g id="Group" transform="translate(21.000000, 42.000000)">
              <g id="Group-2" transform="translate(30.000000, 113.000000)">
                <g id="icon/upload" transform="translate(146.000000, 0.000000)">
                  <path d="M8.33333333,16.6666667 L0,16.6666667 L15,0 L30,16.6666667 L21.6666667,16.6666667 L21.6666667,33.3333333 L8.33333333,33.3333333 L8.33333333,16.6666667 Z M26.6666667,31.6666667 L26.6666667,36.6666667 L3.33333333,36.6666667 L3.33333333,31.6666667 L0,31.6666667 L0,40 L30,40 L30,31.6666667 L26.6666667,31.6666667 Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
