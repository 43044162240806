export const breakPoint = {
  tiny: 320,
  small: 768,
  medium: 990,
  large: 1280,
  xlarge: 1600,
  xxlarge: 1920,
  xxxlarge: 2560,
};

export const Theme = {
  device: {
    smobile: {
      over: `(min-width: ${breakPoint.tiny + 1}px)`,
      under: `(max-width: ${breakPoint.tiny}px)`,
    },
    mobile: {
      over: `(min-width: ${breakPoint.small + 1}px)`,
      under: `(max-width: ${breakPoint.small}px)`,
    },
    tablet: {
      over: `(min-width: ${breakPoint.medium + 1}px)`,
      under: `(max-width: ${breakPoint.medium}px)`,
    },
    desktop: {
      over: `(min-width: ${breakPoint.large + 3}px)`,
      under: `(max-width: ${breakPoint.large}px)`,
    },
    xdesktop: {
      over: `(min-width: ${breakPoint.xlarge + 1}px)`,
      under: `(max-width: ${breakPoint.xlarge}px)`,
    },
  },

  retina: '(-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi)',
  retinaSmall: '(-webkit-min-device-pixel-ratio: 2)',

  orientation: {
    portrait: 'only screen and (orientation: portrait)',
    landscape: 'only screen and (orientation: landscape)',
  },

  boxShadow: {
    default: '-10px -10px 25px 0 rgba(255,255,255,0.07), 11px 14px 21px 0 rgba(20,23,26,0.69);',
    header: '-11px -7px 22px 0 #2A2E2E, 2px 11px 19px 0 #1E1E20',
  },

  linearBg: {
    default: 'linear-gradient(51.43deg, #212626 0%, #171F1F 100%);',
  },
};
