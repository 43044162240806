import React, { useContext, useId, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { object as yupObject, string as yupString } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PaperPlaneIcon } from '@radix-ui/react-icons';
import { CommentContext, Avatar } from '@producer-io/ui-kit';
import styled from 'styled-components';

import { KeyboardKeys } from '../../assets/enums/keyboard-keys.enum';
import { secondsToHms } from '../../lib/utils/Convertor';
import { Checkbox } from '../checkbox/Checkbox';
import { IconButton } from '../buttons';
import { IconButtonWrapper } from '../buttons/icon-button';
import { breakPoint } from '../../app/theme';
import { authStore } from '../../core/stores/auth-store';

import type { Comment } from '../../app/entities/comment';
import { CommentInput } from './comment-input';

interface AddCommentProps {
  playerRef: React.RefObject<HTMLVideoElement | null>;
  timeTagRef: React.RefObject<HTMLSpanElement>;
  onAddComment: (
    comment: Pick<Comment, 'text' | 'timestamp' | 'page' | 'annotations'>,
  ) => Promise<string>;
  mimeType?: string;
  pdfCurrentPage?: number;
  timeStampId?: string;
}

export const AddComment: React.FC<AddCommentProps> = observer(
  ({ onAddComment, mimeType, playerRef, pdfCurrentPage = 0, timeTagRef }) => {
    const id = useId();
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const [comment, setComment] = useState('');
    const [commentText, setCommentText] = useState('');
    const [commentStampChecked, setCommentStampChecked] = useState(true);
    const [_, setSearchParams] = useSearchParams();

    const user = authStore.currentUser;

    const { drawingPath, color, clearDrawingPath, isDrawing, setIsDrawing } =
      useContext(CommentContext);

    const validationSchema = yupObject().shape({
      comment: yupString()
        .matches(/^(?!\s*$).+/s, 'Comment is blank')
        .required('Comment is blank')
        .max(256, "Can't exceed 256 characters"),
    });

    const {
      handleSubmit,
      control,
      formState: { isValid, isSubmitting },
      setError,
    } = useForm<{ comment: string }>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: yupResolver(validationSchema),
      values: { comment },
    });

    const handleAddComment = async (text: string, commentStampChecked: boolean) => {
      const comment: Pick<Comment, 'text' | 'timestamp' | 'page' | 'annotations'> = {
        text,
        ...(commentStampChecked && {
          ...(mimeType === 'video' && {
            timestamp: (playerRef.current as HTMLVideoElement)?.currentTime,
          }),
          ...(mimeType === 'application' &&
            pdfCurrentPage !== undefined && {
              page: pdfCurrentPage,
            }),
        }),
      };

      if (isDrawing && drawingPath && drawingPath.length) {
        comment.annotations = [
          {
            color: color,
            path: drawingPath,
          },
        ];
      }

      clearDrawingPath();
      setIsDrawing(false);

      const commentId = await onAddComment(comment);
      if (commentId) {
        setSearchParams((searchParams) => {
          searchParams.set('commentId', commentId);
          return searchParams;
        });
      }
      setComment('');
    };

    const cancelComment = () => {
      setComment('');
      inputRef?.current?.blur();
    };

    const onSubmit = async () => {
      if (!isValid) return;

      try {
        await validationSchema.validate({ comment: commentText });
      } catch (e: any) {
        setError('comment', {
          type: 'manual',
          message: e.errors[0],
        });
        return;
      }

      handleAddComment(comment!, commentStampChecked);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
      event.stopPropagation();

      if (event.key === KeyboardKeys.Enter && !event.altKey && !event.shiftKey) {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }

      if (event.key === KeyboardKeys.Escape) {
        cancelComment();
      }
    };

    return (
      <Container>
        <AvatarWrapper>
          <Avatar src={user?.avatar} initials={user?.initials} size="small" theme="dark" />
        </AvatarWrapper>

        <Form onSubmit={handleSubmit(onSubmit)} id={`add-comment-form-${id}`} ref={formRef}>
          <Controller
            name="comment"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <Input
                  value={value}
                  handleKeyDown={handleKeyDown}
                  onChange={(value) => {
                    onChange(value);
                    setComment(value);
                  }}
                  onChangeText={(value) => setCommentText(value)}
                />
                {error?.message && <Error>{error.message}</Error>}
              </>
            )}
          />

          <Footer>
            {mimeType === 'application' && (
              <AddCommentItemTimeCode
                onClick={() => setCommentStampChecked((isChecked) => !isChecked)}
              >
                <Checkbox checked={commentStampChecked} />

                {`Page `}

                <span>{pdfCurrentPage + 1}</span>
              </AddCommentItemTimeCode>
            )}

            {mimeType === 'video' && (
              <AddCommentItemTimeCode
                onClick={() => setCommentStampChecked((isChecked) => !isChecked)}
              >
                <span ref={timeTagRef}>{`${secondsToHms(0)}`}</span>

                <Checkbox checked={commentStampChecked} />
              </AddCommentItemTimeCode>
            )}

            <IconButtonWrapper>
              <IconButton
                variant="primary"
                size="small"
                icon={<PaperPlaneIcon color="var(--color-grayscale-white)" />}
                type="submit"
                form={`add-comment-form-${id}`}
                isLoading={isSubmitting}
                disabled={!comment}
              />
            </IconButtonWrapper>
          </Footer>
        </Form>
      </Container>
    );
  },
);

const Container = styled.article`
  display: flex;
  flex-direction: row;
  background-color: var(--color-grayscale-eerie-black);
  gap: 1rem;
  margin: 0.8rem 0.8rem 2rem;
  bottom: 0;
  position: fixed;
  width: calc(100% - 1.6rem);

  @media screen and (max-width: ${breakPoint.medium - 1}px) {
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    padding: 1.6rem 0.8rem 2.4rem;
    margin: 2rem 0 0;
    border-top: 0.1rem solid var(--color-grayscale-trout);
    position: unset;
    min-height: unset;
    width: 100%;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 0.8rem;
  border-radius: var(--border-radius-small);
  box-sizing: border-box;
  border: 0.1rem solid var(--color-grayscale-trout);
  width: 100%;
  padding: 0.8rem;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: end;
  gap: 1.2rem;
  align-items: center;
`;

const Input = styled(CommentInput)`
  width: 100%;
  border: none;
  border-radius: var(--border-radius-small);
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  background-color: transparent;
  color: var(--color-grayscale-white);
  resize: none;

  @media screen and (min-width: ${breakPoint.medium}px) {
    font-size: 1.4rem;
  }

  ::placeholder,
  ::-webkit-input-placeholder,
  :-ms-input-placeholder {
    color: var(--color-grayscale-shuffle);
  }

  &:focus {
    outline: none;
  }
`;

const Error = styled.span`
  position: absolute;
  right: 0;
  bottom: -2rem;
  height: 1.6rem;
  color: var(--color-error);
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  letter-spacing: -0.2px;
  line-height: 1.6rem;
  text-align: right;
`;

const AddCommentItemTimeCode = styled.div`
  background-color: var(--color-grayscale-charleston);
  border-radius: var(--border-radius-small);
  padding: 0.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  font-size: 1.2rem;
  color: var(--color-primary-crayola);
  font-family: Inter, sans-serif;
  font-weight: 400;
  cursor: pointer;
  user-select: none;

  @media screen and (min-width: ${breakPoint.medium}px) {
    font-size: 1.4rem;
  }
`;

const AvatarWrapper = styled.div`
  @media screen and (max-width: ${breakPoint.medium - 1}px) {
    display: none;
  }
`;
