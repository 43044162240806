import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { ElevatedButton, TextButton, TextInput } from '@producer-io/ui-kit';
import { object as yupObject, string as yupString } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { BaseDialog, DialogFooter, DialogHeader } from '../dialog';

type RenameModalProps = {
  title: string;
  label: string;
  placeholder?: string;
  defaultName: string;
  onSubmit: (name: string) => void;
  onCancel: () => void;
};

const schema = yupObject().shape({
  name: yupString()
    .matches(/^(.*)?\S+(.*)?$/, 'Name is required')
    .required('Name is required')
    .max(64, 'Name must be 64 characters or less'),
});

export const RenameDialog: React.FC<RenameModalProps> = ({
  defaultName,
  title,
  label,
  placeholder,
  onSubmit,
  onCancel,
}) => {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<{ name: string }>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: defaultName,
    },
  });

  const handleOnSubmit = async ({ name }: { name: string }) => {
    onSubmit(name);

    onCancel();
  };

  return (
    <BaseDialog onClose={onCancel}>
      <DialogHeader title={title} />

      <Container>
        <form id="rename-form" onSubmit={handleSubmit(handleOnSubmit)}>
          <TextInput
            {...register('name')}
            label={label}
            errorMessage={errors?.name?.message}
            placeholder={placeholder || label}
          />
        </form>
      </Container>

      <DialogFooter
        actions={[
          <TextButton key="cancel" text="Cancel" onClick={onCancel} />,
          <ElevatedButton
            key="rename"
            text="Rename"
            type="submit"
            form="rename-form"
            isLoading={isSubmitting}
          />,
        ]}
      />
    </BaseDialog>
  );
};

const Container = styled.div`
  padding: 1.6rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  max-width: 50rem;
`;
