import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { ContextualDrawer } from '../../../app/components/contextual-drawer';
import { DateSelector } from '../../../components/date-selector/date-selector';
import { StepStatusSelector } from '../../process/components/step-status-selector';

import type { Step } from '../../../app/entities/step';

interface StepContextualDrawerProps {
  step: Step;
  canValidate?: boolean;
}

export const StepDrawer: React.FC<React.PropsWithChildren<StepContextualDrawerProps>> = observer(
  ({ step, canValidate = true, children }) => {
    const handleUpdateTimeFrame = (values: { dueDate?: string; startDate?: string }) => {
      step.update(values);
    };

    return (
      <>
        <ContextualDrawer>
          <Container>
            <Card>
              <Title>{step.name}</Title>

              <Properties>
                {canValidate && (
                  <Property>
                    <Label>Status</Label>
                    <Action>
                      <StepStatusSelector stepId={step._id} />
                    </Action>
                  </Property>
                )}

                <Property>
                  <Label>Start date</Label>
                  <Action>
                    <DateSelector
                      date={step.startDate}
                      max={step.dueDate}
                      field={'startDate'}
                      onUpdate={(startDate) => handleUpdateTimeFrame({ startDate })}
                    />
                  </Action>
                </Property>

                <Property>
                  <Label>Due date</Label>
                  <Action>
                    <DateSelector
                      date={step.dueDate}
                      min={step.startDate}
                      field={'dueDate'}
                      onUpdate={(dueDate) => handleUpdateTimeFrame({ dueDate })}
                    />
                  </Action>
                </Property>
              </Properties>
            </Card>

            {children}
          </Container>
        </ContextualDrawer>
      </>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  &:not(:last-child) {
    padding-bottom: 1.2rem;
    border-bottom: 1px solid var(--color-grayscale-arsenic);
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 1.4rem;
  color: var(--color-grayscale-white);
`;

export const Properties = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const Property = styled.div`
  display: flex;
  flex-direction: row;
  height: 2.4rem;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 8.6rem;
`;

export const Action = styled.div`
  min-width: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
