import React from 'react';
import styled from 'styled-components';

import { Switch, SwitchProps } from 'components/toggle/Switch';

type ToggleMenuItemProps = SwitchProps & {
  label: string;
};

export const ToggleMenuItem: React.FC<ToggleMenuItemProps> = ({
  label,
  isToggled,
  onToggle,
  name,
  isDisabled,
}) => (
  <MenuItem>
    <Label>{label}</Label>

    <Switch isToggled={isToggled} onToggle={onToggle} name={name} isDisabled={isDisabled} />
  </MenuItem>
);

const Label = styled.label`
  margin-right: 3.2rem;
  color: #d7dae0;
  cursor: default;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 2.8rem;
  line-height: 3.2rem;
  color: black;
  font-size: 1.4rem;
  font-weight: 400;
  text-align: left;
  padding: 0 1.6rem;
  border-radius: 0.4rem;
  width: 100%;
  min-width: max-content;
`;
