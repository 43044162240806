import React from 'react';
import { format, isToday, isYesterday } from 'date-fns';
import styled from 'styled-components';
import { TrashIcon } from '@radix-ui/react-icons';

import type { Asset } from '../../../app/entities/asset';

export interface VersionManagerItemProps {
  version: Asset;
  isSelected?: boolean;
  canDelete?: boolean;
}

export const VersionManagerItem: React.FC<VersionManagerItemProps> = ({
  version,
  isSelected = false,
  canDelete = true,
}) => {
  return (
    <Container>
      <VersionText isSelected={isSelected}>v{version.version}</VersionText>

      <AssetThumbnail src={version.cover?.src} />

      <VersionDetails>
        <VersionTitle isSelected={isSelected}>{version.name}</VersionTitle>
        <VersionUploaderAndTime>
          {version.uploaderName}
          {' - '}
          {version.createdAt && isToday(new Date(version.createdAt))
            ? 'Today'
            : isYesterday(new Date(version.createdAt))
            ? 'Yesterday'
            : format(new Date(version.createdAt), 'MMM dd, yyyy')}
          , {version.createdAt && format(new Date(version.createdAt), 'hh:mm a')}
        </VersionUploaderAndTime>
      </VersionDetails>

      {canDelete && (
        <DeleteButton onClick={() => version.delete()}>
          <TrashIcon />
        </DeleteButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  padding: 0.8rem 0.8rem;
  display: flex;
  justify-content: space-between;
  min-height: 7.2rem;
  gap: 1.6rem;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-grey-7);
    margin-bottom: 1.2rem;
  }
`;

const VersionText = styled.div<{ isSelected?: boolean }>`
  color: ${({ isSelected }) => (isSelected ? 'var(--color-secondary)' : 'var(--color-grey-3)')};
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-align: center;
`;

const AssetThumbnail = styled.img`
  width: 7.6rem;
  min-width: 7.6rem;
  flex-basis: 0;
  height: 4.275rem;
  border-radius: 0.6rem;
  border: none;
  object-fit: cover;
  overflow: hidden;
`;

const VersionDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.4rem;
  min-width: 0;
`;

const VersionTitle = styled.h3<{ isSelected?: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ isSelected }) => (isSelected ? 'var(--color-secondary)' : '#FFFFFF')};
`;

const VersionUploaderAndTime = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: var(--color-grey-4);
`;

const DeleteButton = styled.div`
  cursor: pointer;
  margin-right: 0.8rem;

  svg path {
    fill: #959bab;
    transition: all 0.1s ease-out;
  }

  :hover {
    svg path {
      fill: white;
    }
  }
`;
