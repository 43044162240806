import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface CardProps extends React.PropsWithChildren {
  title?: string;
  subtitle?: string;
  footer?: string | ReactNode;
}

export const Card: React.FC<CardProps> = ({ title, subtitle, children, footer }) => (
  <CardItem>
    {title && <CardTitle>{title}</CardTitle>}
    <Content>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {children}
    </Content>
    {footer && <Footer>{footer}</Footer>}
  </CardItem>
);

const CardItem = styled.div`
  border-radius: 0.8rem;
  width: 100%;

  @media only screen and (min-width: 427px) {
    background: var(--color-grayscale-charleston);
  }
`;

const CardTitle = styled.div`
  height: 4rem;
  padding: 1.2rem 0;
  overflow: hidden;
  border-radius: 0.8rem 0.8rem 0 0;

  color: var(--color-grayscale-white);
  font-size: 1.4rem;
  font-weight: 600;
  line-height: normal;

  @media only screen and (min-width: 427px) {
    background: var(--color-grayscale-shark);
    padding: 1.2rem 1.6rem;
  }
`;

const Subtitle = styled.div`
  color: var(--color-grayscale-manatee);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.2rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1.6rem 0;

  color: var(--color-grayscale-white);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.2rem;

  @media only screen and (min-width: 427px) {
    padding: 1.6rem;
  }
`;

const Footer = styled.div`
  width: 100%;
  padding: 1.6rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;

  @media only screen and (min-width: 427px) {
    padding-top: 1.6rem;
    border-top: 1px solid var(--color-grayscale-tuna);
    padding: 1.6rem;
  }
`;
