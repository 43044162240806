import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { DescriptionHeader } from '../components/description-header';
import { SettingsAction } from '../components/settings-action';
import { Lock } from '../../../components/icons/lock';
import { Switch } from '../../../components/toggle/Switch';
import { api } from '../../../api';
import { EnvelopeClosedIcon } from '@radix-ui/react-icons';
import { authStore } from '../../../core/stores/auth-store';
import { toastPool } from '../../toasts/models/toast-pool';
import { Toast } from '../../toasts/models/toast';
import { observer } from 'mobx-react-lite';

type Device = {
  _id: string;
  name: string;
};

export const NotificationPage = observer(() => {
  const user = authStore.currentUser!;

  const [devices, setDevices] = useState<Device[]>([]);

  useEffect(() => {
    /*api.get('/me/devices').then((res) => {
      setDevices(res.data);
    });*/
  });

  const toggleDesktopNotification = () => {
    if (!('Notification' in window)) {
      return;
    }

    if (Notification.permission === 'granted') {
      console.log('granted');
    } else {
      Notification.requestPermission().then((permission) => {});
    }
  };

  const handleRegisterDevice = () => {
    // Get device token

    api
      .post('/me/devices', {
        token: '',
      })
      .then((res) => {
        setDevices((devices) => [...devices, res.data]);
      });
  };

  const handleDeleteDevice = () => {
    api.delete('/me/devices/:deviceId').then((res) => {
      setDevices((devices) => devices.filter((device) => device._id !== res.data._id));
    });
  };

  const handleUpdatePreference = () => {
    const emailPreferences = user.notifications.preferences.find(({ type }) => type === 'email');

    api
      .patch('/me/notifications/preferences', {
        preferences: [
          {
            type: 'email',
            enabled: !emailPreferences?.enabled,
          },
        ],
      })
      .then(({ data }) => {
        user.notifications = data;
      })
      .catch(() => {
        toastPool.insert(
          new Toast('Could not update notification preferences, try again later.', 'error'),
        );
      });
  };

  const emailPreferences = user.notifications.preferences.find(({ type }) => type === 'email');

  return (
    <Container>
      <DescriptionHeader
        title={'Notifications'}
        description={'Select where you will be notified.'}
      />

      <SettingsAction
        title={'Email notifications'}
        icon={<EnvelopeClosedIcon />}
        action={
          <Switch isToggled={!!emailPreferences?.enabled} onToggle={handleUpdatePreference} />
        }
      />

      {/*<SettingsAction
        title={'Desktop notifications'}
        icon={<Lock />}
        action={<Switch isToggled={true} onToggle={toggleDesktopNotification} />}
      />

      <DescriptionHeader
        title={'Devices'}
        description={'Your registered devices which will receive Producer notifications.'}
      />*/}
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  max-width: 64rem;
  margin: 0 auto;
`;
