import React from 'react';

export const Delete = () => (
  <svg width="12px" height="14px" viewBox="0 0 12 14">
    <g id="Design-System">
      <g id="Basic-UI-Elements" transform="translate(-60.000000, -321.000000)" stroke="none">
        <g id="icon/square" transform="translate(60.000000, 321.000000)">
          <path d="M2.21052632,12.526583 C2.21052632,12.5273256 9.580112,12.5263158 9.580112,12.5263158 C9.57868553,12.5263158 9.57883064,5.30746293 9.5789162,2.94736842 L2.21052632,2.94736842 L2.21052632,12.526583 Z M11.0526316,12.526583 C11.0526316,13.3403287 10.3916399,14 9.580112,14 L2.20936169,14 C1.39611158,14 0.736842105,13.3417072 0.736842105,12.526583 L0.736842105,2.94736815 C0.329835949,2.94702454 1.67865721e-13,2.62009471 1.67865721e-13,2.21052632 C1.67865721e-13,1.80357966 0.32762274,1.47368421 0.73748438,1.47368421 L4.42105263,1.47368421 C4.42105263,0.659790895 5.08084353,0 5.89473684,0 C6.70863016,0 7.36842105,0.659790895 7.36842105,1.47368421 L11.0519893,1.47368421 C11.4592907,1.47368421 11.7894737,1.8007425 11.7894737,2.21052632 C11.7894737,2.61726038 11.4621932,2.94702372 11.0526316,2.94736815 L11.0526316,12.526583 Z M6.63157895,5.15608553 C6.63157895,4.75013807 6.95863724,4.42105263 7.36842105,4.42105263 C7.77536771,4.42105263 8.10526316,4.74991366 8.10526316,5.15608553 L8.10526316,10.3175987 C8.10526316,10.7235461 7.77820487,11.0526316 7.36842105,11.0526316 C6.96147439,11.0526316 6.63157895,10.7237705 6.63157895,10.3175987 L6.63157895,5.15608553 Z M3.68421053,5.15608553 C3.68421053,4.75013807 4.01126882,4.42105263 4.42105263,4.42105263 C4.82799929,4.42105263 5.15789474,4.74991366 5.15789474,5.15608553 L5.15789474,10.3175987 C5.15789474,10.7235461 4.83083645,11.0526316 4.42105263,11.0526316 C4.01410597,11.0526316 3.68421053,10.7237705 3.68421053,10.3175987 L3.68421053,5.15608553 Z" />
        </g>
      </g>
    </g>
  </svg>
);
