import React, { Ref } from 'react';
import styled from 'styled-components';

interface TextAreaInputProps {
  value?: string;
  label?: string | null;
  onChange?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  onBlur?: (e: any) => void;
  hasError?: boolean;
  disabled?: boolean;
  required?: boolean;
  errorMessage?: string | undefined | any;
  placeholder?: string | null;
  autocomplete?: string;
  name?: string;
  type?: string;
  defaultValue?: string;
  rows?: number;
  cols?: number;
  icon?: React.ReactNode;
  ref?: Ref<HTMLInputElement>;
  horizontalResize?: boolean;
  verticalResize?: boolean;
}

export const TextAreaInput = React.forwardRef<HTMLTextAreaElement, TextAreaInputProps>(
  (
    {
      value,
      label,
      onChange,
      hasError = false,
      errorMessage,
      icon,
      disabled = false,
      onKeyDown,
      placeholder,
      name,
      required,
      onBlur,
      autocomplete,
      rows = 4,
      cols = 50,
      defaultValue,
      horizontalResize = false,
      verticalResize = false,
    },
    ref,
  ) => {
    const handleOnKeyDown = (e: any) => {
      if (e.key === 'Enter') {
        if (onKeyDown) {
          e.target.value = '';
          onKeyDown(e);
        }
      }
    };

    return (
      <TextAreaInputContainer hasLabel={label !== undefined ? true : undefined}>
        {icon}
        <MyLabel hasLabel={label !== undefined ? true : undefined}>
          {label} {required && <b>*</b>}
        </MyLabel>

        <Input
          ref={ref}
          value={value}
          onChange={onChange}
          hasError={hasError || !!errorMessage}
          hasIcon={!!icon}
          disabled={disabled}
          name={name}
          placeholder={placeholder || ''}
          onKeyDown={handleOnKeyDown}
          onBlur={onBlur}
          autoComplete={autocomplete}
          rows={rows}
          cols={cols}
          defaultValue={defaultValue}
          horizontalResize={horizontalResize}
          verticalResize={verticalResize}
        />

        {errorMessage && <Error>{errorMessage}</Error>}
      </TextAreaInputContainer>
    );
  },
);

TextAreaInput.displayName = 'TextAreaInput';

interface InputProps {
  hasError: boolean;
  hasIcon?: boolean;
  horizontalResize?: boolean;
  verticalResize?: boolean;
}

interface LabelProps {
  hasLabel?: boolean;
}

const TextAreaInputContainer = styled.div<LabelProps>`
  position: relative;
  display: flex;
  flex-direction: column;

  svg {
    position: absolute;
    left: 0.75rem;
    top: 0.8rem;
    top: ${({ hasLabel }) => hasLabel && '2.5rem'};
    bottom: 0.8rem;
    fill: white;
    z-index: 1;
    width: 1.6rem;
  }
`;

const MyLabel = styled.div<LabelProps>`
  font-family: Inter, sans-serif;
  font-weight: 600;
  color: var(--white-default);
  font-size: 1.4rem;
  letter-spacing: 0.7px;
  line-height: 1.1rem;
  display: ${({ hasLabel }) => (hasLabel ? 'inline' : 'none')};
  margin-bottom: 1.2rem;

  b {
    color: var(--color-primary);
  }
`;

const Input = styled.textarea<InputProps>`
  position: relative;
  min-height: 14.4rem;
  resize: ${({ horizontalResize }) => (horizontalResize ? 'horizontal' : 'none')};
  resize: ${({ verticalResize }) => (verticalResize ? 'vertical' : 'none')};
  box-sizing: border-box;
  border-radius: 0.6rem;
  font-family: Inter, sans-serif;
  font-size: 1.6rem;
  letter-spacing: 0.3px;
  line-height: 1.6rem;
  border: 1px solid ${({ hasError }) => (hasError ? 'var(--color-error)' : 'var(--color-grey-7)')};
  padding: ${({ hasIcon }) => (hasIcon ? '1.2rem 1.6rem 1.2rem 3.6rem' : '1.2rem 1.6rem')};
  background-color: transparent;
  color: var(--white-default);

  &:focus {
    border: 1px solid
      ${({ hasError }) => (hasError ? 'var(--color-error)' : 'var(--color-secondary)')};
  }
`;

const Error = styled.span`
  position: absolute;
  right: 0;
  bottom: -2.4rem;
  height: 1.6rem;
  color: var(--color-error);
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  letter-spacing: -0.2px;
  line-height: 1.6rem;
  text-align: right;
`;
