import React from 'react';
import { redirect, RouteObject } from 'react-router-dom';

import { SSOPage } from '../routes/orgs';
import { AcceptInvitationPage } from '../routes/invitations/accept-invitation.page';
import { AppLayout } from './App';
import { OnBoardingPage } from '../routes/on-boarding/on-boarding.page';
import { UserSettingsPage } from '../features/user/pages/user-settings.page';
import { Core } from './core';
import { fetchCurrentUser } from '../features/user/user.slice';
import { fetchSpaces } from 'features/spaces/stores/space.slice';
import { OnBoardingLayout } from '../routes/on-boarding/on-boarding.layout';
import { ProfilePage } from '../features/user/pages/profile.page';
import { SecurityPage } from '../features/user/pages/security.page';
import { NotificationPage } from '../features/user/pages/notifications.page';
import { StorageSettingsRoute } from '../routes/settings/storage-settings.route';
import { BillingSettingsRoute } from '../routes/settings/billing-settings.route';
import { BillingHistoryRoute } from '../routes/settings/billing-history.route';
import { PlansSettingsRoute } from '../routes/settings/plans-settings.route';
import { MembersSettingsRoute } from '../routes/settings/members-settings.route';
import { SpaceSettingsRoute } from '../routes/settings/space-settings.route';
import { SecuritySettingsRoute } from '../routes/settings/security-settings.route';
import { SettingsRoute } from '../routes/settings';
import { SpaceSettingsPage } from 'features/settings/components/settings-page';
import { AccountRoute } from '../routes/account';
import { MarketplaceLandingPage } from 'routes/marketplace/landing.page';
import { MarketplaceThanksPage } from '../routes/marketplace/thanks.page';

export const routes: RouteObject[] = [
  {
    path: '/orgs/:alias/sso',
    Component: SSOPage,
  },
  {
    path: '/accept-invite/:invitationId',
    loader: async () => {
      await fetchCurrentUser();
      return null;
    },
    Component: AcceptInvitationPage,
  },
  {
    path: '/onboarding/*',
    Component: AppLayout,
    loader: async () => {
      await fetchCurrentUser();
      return null;
    },
    children: [
      {
        path: '*',
        Component: OnBoardingPage,
      },
    ],
  },
  {
    path: '/marketplace/*',
    loader: async () => {
      const user = await fetchCurrentUser();
      await fetchSpaces({ userId: user._id });
      return null;
    },
    children: [
      { path: 'activation', Component: MarketplaceLandingPage },
      { path: 'thanks', Component: MarketplaceThanksPage },
    ],
  },
  {
    path: '/',
    Component: AppLayout,
    loader: async (params) => {
      const user = await fetchCurrentUser();
      const spaces = await fetchSpaces({ userId: user._id });

      if (spaces.length === 0) {
        return redirect('/onboarding' /* TODO find a way to send state { firstVisit: true } */);
      } else if (!params.params.spaceId) {
        return redirect(`/space/${spaces[0]._id}`);
      }

      return null;
    },
    children: [
      {
        path: '/space/:spaceId/*',
        errorElement: (
          <OnBoardingLayout>
            <h2
              style={{
                marginTop: '15rem',
                fontSize: '2.4rem',
                textAlign: 'center',
              }}
            >
              You do not have access to this creative space.
            </h2>
            <h3
              style={{
                textAlign: 'center',
              }}
            >
              Verify that you are logged in with the correct email or contact a space admin to gain
              access.
            </h3>
          </OnBoardingLayout>
        ),
        children: [
          {
            path: 'account/*',
            Component: UserSettingsPage,
            children: [
              { path: 'profile/*', Component: ProfilePage },
              { path: 'security/*', Component: SecurityPage },
              { path: 'notifications/*', Component: NotificationPage },
              { path: '', Component: AccountRoute },
            ],
          },
          {
            path: '*',
            Component: Core,
            children: [
              {
                path: 'settings/*',
                Component: SpaceSettingsPage,
                children: [
                  { path: 'general', Component: SpaceSettingsRoute },
                  { path: 'storage', Component: StorageSettingsRoute },
                  { path: 'billings', Component: BillingSettingsRoute },
                  { path: 'billings/history', Component: BillingHistoryRoute },
                  { path: 'plans', Component: PlansSettingsRoute },
                  { path: 'users/*', Component: MembersSettingsRoute },
                  { path: 'security', Component: SecuritySettingsRoute },
                  { path: '', Component: SettingsRoute },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
