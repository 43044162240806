import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Share2Icon } from '@radix-ui/react-icons';

import { ScreenHeader } from '../../projects/components/screen-header';
import { CommonFileBreadcrumb } from '../components/common-file-breadcrumb';
import { AssetViewerScreen } from './asset-viewer.screen';
import { ElevatedButton } from '../../../components/buttons';
import { ShareModal } from '../../share/components/share-modal';
import { fetchChildren } from '../store/assets.slice';
import { useResponsive } from 'hooks/useResponsive';
import { Asset } from '../../../app/entities/asset';

import type { Step } from '../../../app/entities/step';

type CommonFileScreenProps = {
  step: Step;
  spaceId: string;
};

export const CommonFileScreen: React.FC<CommonFileScreenProps> = observer(({ step, spaceId }) => {
  const { isDesktop } = useResponsive();

  const { assetId, projectId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const asset = Asset.getOne(assetId!);

  useEffect(() => {
    if (!asset) {
      return;
    }

    if (asset?.type === 'version_stack') {
      fetchChildren({ assetId: asset._id }).then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [assetId, asset]);

  if (!asset) {
    return <div />; // TODO: handle display file not found placeholder
  }

  const handleOpenShareModal = () => {
    setIsShareModalOpen(true);
  };

  return (
    <React.Fragment>
      {isDesktop && (
        <ScreenHeader
          breadcrumb={
            <CommonFileBreadcrumb step={step} projectId={projectId!} assetId={assetId!} />
          }
          stepId={step._id}
          title={step.name}
          dueDate={step.dueDate}
          startDate={step.startDate}
          handleUpdateTimeFrame={(values) => step.update(values)}
          actions={[
            <ElevatedButton
              key="share-button"
              onClick={handleOpenShareModal}
              text="Share"
              icon={<Share2Icon />}
            />,
          ]}
          canValidateStep={false}
        />
      )}

      {!isLoading && <AssetViewerScreen asset={asset} />}

      {isShareModalOpen && (
        <ShareModal
          title={`Share ${asset.name}`}
          elementIds={[asset._id]}
          spaceId={spaceId}
          projectId={step.projectId}
          onClose={() => setIsShareModalOpen(false)}
        />
      )}
    </React.Fragment>
  );
});
