import React from 'react';

import { TeamScreen } from '../../../features/teams/screens/team.screen';

import type { Step } from '../../../app/entities/step';
import { Project } from '../../../app/entities/project';

export type CrewPageProps = {
  project: Project;
  step: Step;
};

export const TeamPage: React.FC<CrewPageProps> = ({ project, step }) => (
  <TeamScreen projectId={project._id} spaceId={project.spaceId} step={step} />
);
