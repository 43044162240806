import React from 'react';
import styled from 'styled-components';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { VersionListItem } from './version-list-item';
import { ArrowDown } from 'features/schedule/icons/ArrowDown';
import { Asset } from '../../../app/entities/asset';

type VersionSelectorProps = {
  assetChildren: Asset[];
  version: number;
  onSelect: (version: Asset) => void;
  selectedAssetId?: string;
};

export const VersionSelector: React.FC<VersionSelectorProps> = ({
  assetChildren,
  version,
  onSelect,
  selectedAssetId,
}) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Version>
          <div>
            <span>v</span>
            {version}
          </div>

          <ArrowDown />
        </Version>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <VersionList sideOffset={5} align="start">
          {assetChildren.map((asset) => (
            <DropdownMenu.Item key={asset._id}>
              <VersionListItem
                onClick={() => onSelect(asset)}
                thumbnail={asset.cover?.src || asset.thumbnail}
                isSelected={selectedAssetId === asset._id}
                name={asset.name}
                version={asset.version || 1}
                date={asset.createdAt}
                uploader={asset.uploader?.firstName || ''}
                fileType={asset.fileType}
                extension={asset.extension}
              />
            </DropdownMenu.Item>
          ))}
        </VersionList>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

const Version = styled.div`
  background: #3c404a;
  border-radius: var(--border-radius-medium);
  height: 2.8rem;
  padding: 0 0.8rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 19px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #afb4c0;

  span {
    opacity: 0.6;
  }

  &:hover {
    background: #494e5a;
    transition: background 0.2s ease-in-out;
    cursor: pointer;
  }

  > svg {
    fill: #afb4c0;
    width: 1rem;
    transition: transform 0.2s ease-in-out;
  }

  &[data-active='open'] {
    transform: rotate(-180deg);
  }

  > div {
    margin-right: 0.4rem;
  }
`;

const VersionList = styled(DropdownMenu.Content)`
  width: 44rem;
  max-height: 28rem;
  overflow-y: auto;
  padding: 0.8rem;
  z-index: 1000;
  border-radius: var(--border-radius-medium);
  background: var(--color-grey-7-5);
`;
