import React, { useState } from 'react';
import styled from 'styled-components';
import { ArrowDown } from '../icons/ArrowDown';
import { ElevatedButton } from '../../../components/buttons/elevated-button';
import { BaseDialog, DialogFooter, DialogHeader } from 'components/dialog';
import { TextButton } from '../../../components/buttons/text-button';

interface TimeSelectorProps {
  time?: number;
  onTimeChange: (time: number) => void;
  onCancel: () => void;
}

const DEFAULT_MINUTE_INCREMENT = 15;

export const EstimatedTimeSelector: React.FC<TimeSelectorProps> = ({
  time,
  onTimeChange,
  onCancel,
}) => {
  const [internalTime, setInternalTime] = useState<number>(time || 0);

  const handleClear = (e: any) => {
    e.stopPropagation();
    setInternalTime(0);
  };

  const handleSubmitTime = (e: any) => {
    e.stopPropagation();
    onTimeChange(internalTime);
  };

  const handleTimeChange = (type: 'hours' | 'minutes') => (e: any) => {
    e.stopPropagation();
    const value = e.target.value;
    if (isNaN(+value)) {
      return;
    }
    if (type === 'hours') {
      setInternalTime(+value * 60 + (internalTime % 60));
    } else {
      setInternalTime(Math.floor(internalTime / 60) * 60 + +value);
    }
  };

  const incrementTime = (type: 'hours' | 'minutes') => (e: any) => {
    e.stopPropagation();
    const newTime = type === 'hours' ? internalTime + 60 : internalTime + DEFAULT_MINUTE_INCREMENT;
    setInternalTime(newTime);
  };

  const decrementTime = (type: 'hours' | 'minutes') => (e: any) => {
    e.stopPropagation();
    const newTime = type === 'hours' ? internalTime - 60 : internalTime - DEFAULT_MINUTE_INCREMENT;
    setInternalTime(newTime < 0 ? 0 : newTime);
  };

  return (
    <BaseDialog onClose={onCancel}>
      <DialogHeader title="" onClose={onCancel} />

      <DialogContent>
        <TimeSelectorContainer>
          <TimeInputContainer>
            <UpArrowContainer onClick={incrementTime('hours')}>
              <ArrowDown />
            </UpArrowContainer>

            <TimeInput
              type="text"
              maxLength={2}
              value={Math.floor(internalTime / 60)}
              onChange={handleTimeChange('hours')}
            />

            <DownArrowContainer onClick={decrementTime('hours')}>
              <ArrowDown />
            </DownArrowContainer>
          </TimeInputContainer>

          <TimeLetters>h</TimeLetters>

          <TimeInputContainer>
            <UpArrowContainer onClick={incrementTime('minutes')}>
              <ArrowDown />
            </UpArrowContainer>

            <TimeInput
              type="text"
              maxLength={2}
              value={internalTime % 60}
              onChange={handleTimeChange('minutes')}
            />

            <DownArrowContainer onClick={decrementTime('minutes')}>
              <ArrowDown />
            </DownArrowContainer>
          </TimeInputContainer>

          <TimeLetters>m</TimeLetters>
        </TimeSelectorContainer>
      </DialogContent>

      <DialogFooter
        actions={[
          <TextButton key="time-selector-clear-button" text="Clear" onClick={handleClear} />,
          <ElevatedButton
            key="time-selector-submit-button"
            text="Submit"
            onClick={handleSubmitTime}
          />,
        ]}
      />
    </BaseDialog>
  );
};

const TimeSelectorContainer = styled.div`
  display: flex;
  background-color: var(--color-gray-8);
  align-items: center;
  justify-content: center;
`;

const TimeInputContainer = styled.div`
  width: 4.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:last-of-type {
    margin-left: 1.6rem;
  }
`;

const TimeInput = styled.input`
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1.5px solid var(--color-grey-5);
  font-size: 3.2rem;
  line-height: 3.873rem;
  text-align: center;
  font-weight: 700;
  color: #f2f3f5;
  background-color: transparent;
`;

const DownArrowContainer = styled.div`
  margin-top: 1.7rem;
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: var(--color-secondary);

    & > svg {
      stroke: white;
    }
  }
  & > svg {
    stroke: #367bff;
  }
`;

const UpArrowContainer = styled.div`
  margin-bottom: 1.7rem;
  transform: rotate(180deg);
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: var(--color-secondary);

    & > svg {
      stroke: white;
    }
  }
  & > svg {
    stroke: #367bff;
  }
`;

const TimeLetters = styled.span`
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 3.873rem;
  text-align: center;
  color: var(--color-grey-4);
  margin-left: 0.4rem;
`;

const DialogContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
