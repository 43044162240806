import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { Avatar } from '@producer-io/ui-kit';

import { Contact } from '../../../app/entities/contact';
import { breakPoint } from '../../../app/theme';
import { useResponsive } from '../../../hooks/useResponsive';

type ContactWithPreviewProps = {
  contact: Contact;
};

const isSelected = (contactId: string) => {
  const path = window.location.pathname.split('/');

  return path[path.length - 1] === contactId;
};

export const ContactWithPreview: React.FC<ContactWithPreviewProps> = observer(({ contact }) => {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  return (
    <Container
      onClick={() => navigate(`../${contact._id}`)}
      data-selected={isSelected(contact._id)}
    >
      <Left>
        {/* @todo: Remove the AvatarWrapper and instead redesign the Avatar component itself */}
        <AvatarWrapper>
          <Avatar src={contact.avatar} initials={contact.initials} size="fill" theme="dark" />
        </AvatarWrapper>

        <Content>
          <Title>{contact.fullName}</Title>
          <Profession>{contact.role}</Profession>
        </Content>
      </Left>

      {isMobile && <ChevronRightIcon color="var(--color-grayscale-manatee)" />}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 0.8rem 1.6rem 0;
  min-width: 0;
  box-sizing: border-box;
  border: 1px solid transparent;

  & > svg {
    width: 1.6rem;
    height: 1.6rem;
  }

  @media screen and (max-width: ${breakPoint.small - 1}px) {
    &:not(:last-child) {
      border-bottom: 0.1rem solid var(--color-grayscale-trout);
    }
  }

  @media screen and (min-width: ${breakPoint.small}px) {
    cursor: pointer;
    border-color: transparent;
    border-radius: 0.4rem;
    background: var(--color-grayscale-eerie-black);
    padding: 0.8rem;

    &[data-selected='true'] {
      border-color: var(--color-primary-crayola);
    }
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  min-width: 0;

  @media screen and (min-width: ${breakPoint.small}px) {
    gap: 1.6rem;
  }
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 1.4rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--color-grayscale-anti-flash-white);

  @media screen and (min-width: ${breakPoint.small}px) {
    font-weight: 600;
    color: var(--color-grayscale-white);
  }
`;

const Profession = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--color-grayscale-manatee);

  @media screen and (min-width: ${breakPoint.small}px) {
    font-weight: 400;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: 3.8rem;
  gap: 0.4rem;
`;

const AvatarWrapper = styled.div`
  flex: 1 0 auto;
  width: 3.2rem;
  height: 3.2rem;

  span {
    &[data-size='fill'] {
      font-size: 1.4rem;
    }
  }

  @media screen and (min-width: ${breakPoint.small}px) {
    width: 4.8rem;
    height: 4.8rem;

    span {
      &[data-size='fill'] {
        font-size: 1.6rem;
      }
    }
  }
`;
