import axios, { AxiosRequestConfig } from 'axios';
import { addLeadingCharacter } from '../../lib/time-formatters';

export const isVideo = (type: string) => {
  const regexp = /^video\//;
  return regexp.test(type);
};

export const upload = (url: string, file: File, config: AxiosRequestConfig = {}): Promise<any> => {
  const reg = /blob.core.windows.net/;
  const isAzureStorage = reg.test(url);

  /**
   * Test if the upload is directed to Azure or AWS to set the correct headers
   */
  config.headers = isAzureStorage
    ? {
        'x-ms-blob-type': 'BlockBlob',
      }
    : {
        'Content-Type': file.type,
      };

  return axios.put(url, file, config);
};

type SelectFilesConfig = {
  multiple?: boolean;

  accept?: string;
};

/**
 * Resolve with an array of file or null if user cancel the action
 */
export const selectFiles = (config: SelectFilesConfig = {}): Promise<File[] | null> => {
  return new Promise((resolve) => {
    let inputElement = document.createElement('input') as HTMLInputElement;

    /**
     * Set a 'FILE' type for this input element
     * @type {string}
     */
    inputElement.type = 'file';

    if (config.multiple) {
      inputElement.setAttribute('multiple', 'multiple');
    }

    if (config.accept) {
      inputElement.setAttribute('accept', config.accept);
    }

    inputElement.style.display = 'none';
    document.body.appendChild(inputElement);

    /**
     * Add onchange listener for «choose file» pop-up
     */
    inputElement.addEventListener(
      'change',
      (event) => {
        // @ts-ignore
        if (!event.target || !event.target.files) {
          return resolve([]);
        }

        /**
         * Get files from input field
         */
        // @ts-ignore
        const files = event.target.files;

        /**
         * Return ready to be uploaded files array
         */
        resolve(files);

        /**
         * Remove element from a DOM
         */
        document.body.removeChild(inputElement);
      },
      false,
    );

    /**
     * Resolve with empty array if user cancel the action
     */
    inputElement.addEventListener('cancel', () => {
      resolve(null);
    });
    /**
     * Fire click event on «input file» field
     */
    inputElement.click();
  });
};

export const createFileName = (
  projectName: string,
  fileName: string,
  includeTime?: boolean,
): string => {
  const date = new Date();
  const year = date.getFullYear();
  const month = addLeadingCharacter((date.getMonth() + 1).toString(), 2);
  const day = addLeadingCharacter(date.getDate().toString(), 2);
  const hours = addLeadingCharacter(date.getHours().toString(), 2);
  const minutes = addLeadingCharacter(date.getMinutes().toString(), 2);
  const formattedDate = `${year}-${month}-${day}${includeTime ? `_${hours}-${minutes}` : ''}`;
  const formattedProjectName = projectName.replaceAll(' ', '_');

  const name = fileName.split('.');
  const extension = name.pop();

  return `${formattedProjectName}_${name.join('.')}_${formattedDate}.${extension}`;
};
