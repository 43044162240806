import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Note } from '../note/note';

interface SettingsTableProps extends React.PropsWithChildren {
  headers: string[];
  isFullHeight?: boolean;
  hint?: string;
}

export const SettingsTable: React.FC<PropsWithChildren<SettingsTableProps>> = ({
  headers,
  children,
  isFullHeight,
  hint,
}) => {
  const columnsCount = headers.length;

  return (
    <Container>
      {hint && <Note isFullWidth>{hint}</Note>}

      <Table data-fullHeight={isFullHeight}>
        {!isFullHeight && (
          <TableHeader columnsCount={columnsCount}>
            {headers.map((header) => (
              <Cell key={`header_${header}`}>{header}</Cell>
            ))}
          </TableHeader>
        )}
        {children}
      </Table>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  height: 100%;
`;

const Table = styled.div`
  width: 100%;
  display: table;
  border-collapse: collapse;
  border-spacing: 0 1.6rem;

  &[data-fullHeight='true'] {
    height: 100%;
    margin-top: 0;
  }
`;

type TableHeaderType = {
  columnsCount: number;
};
const TableHeader = styled.div<TableHeaderType>`
  display: table-row;
  margin-bottom: 0.6rem;
  padding-left: 1.6rem;
  border-bottom: 1px solid var(--color-grayscale-tuna);
`;

const Cell = styled.div`
  display: table-cell;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--color-grayscale-shuttle);
  padding: 0 1rem;

  /* 
    height is 3rem, to include padding, as padding doesn't work with table-cell
    padding: 1.6rem, font-size: 1.4rem; => 1.6rem + 1.4rem = 3rem
    vertical-align: top;
  */
  height: 3rem;
`;
