import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { ModalHeader } from '../../../components/dialogs/components/ModalHeader';
import { VersionManagerItem } from './version-manager-item';
import { Asset } from '../../../app/entities/asset';
import { BaseDialog } from '../../../components/dialog';

interface VersionManagerProps {
  asset: Asset;
  selectedVersion: string;
  onClose: () => void;
}

export const VersionManager: React.FC<VersionManagerProps> = observer(
  ({ asset, selectedVersion, onClose }) => (
    <BaseDialog onClose={onClose}>
      <Header>
        <ModalHeader title="Version manager" onClose={onClose} />
        <ModalHint>You can easily work with versions, delete, move and get information</ModalHint>
      </Header>

      <ModalContent>
        {asset.sortedChildren.map((version) => (
          <VersionManagerItem
            key={version._id}
            isSelected={selectedVersion === version._id}
            version={version}
            canDelete={asset.children.length > 1}
          />
        ))}
      </ModalContent>
    </BaseDialog>
  ),
);

const ModalHint = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 19px;

  color: #9d9d9f;

  margin-top: 0.8rem;
  max-width: 97%;
`;

const Header = styled.div`
  padding: 1.6rem;
  border-bottom: 1px solid #3c404a;
`;

const ModalContent = styled.div`
  padding: 1.6rem;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: 64rem;
  max-height: min(80vh, 500px);
  overflow: auto;
`;
