import React from 'react';

const Sunrise = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2034_348441)">
        <path
          d="M5.20091 15.7247L7.00054 14.009L9.7 16.5825L7.90036 18.2982L5.20091 15.7247ZM24.9995 14.0078L26.7991 15.7247L24.0996 18.2982L22.3 16.5825L24.9995 14.009V14.0078ZM30 23.0908V25.5175H26.1818V23.0908H30ZM5.81818 23.0908V25.5175H2V23.0908H5.81818Z"
          fill="#AFB4C0"
        />
        <path
          d="M23.9515 25.5197C23.9515 23.4404 23.1255 21.4463 21.6552 19.976C20.1849 18.5057 18.1908 17.6797 16.1115 17.6797C14.0322 17.6797 12.0381 18.5057 10.5678 19.976C9.09748 21.4463 8.27148 23.4404 8.27148 25.5197L16.1115 25.5197H23.9515Z"
          fill="#AFB4C0"
        />
      </g>
      <rect x="17" y="14" width="2" height="5" transform="rotate(180 17 14)" fill="#AFB4C0" />
      <path d="M16 4L20.3301 10L11.6699 10L16 4Z" fill="#AFB4C0" />
      <defs>
        <clipPath id="clip0_2034_348441">
          <rect width="28" height="28" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Sunrise;
