import React from 'react';
import { components, OptionProps } from 'react-select';
import { Avatar } from '@producer-io/ui-kit';
import styled from 'styled-components';

import { getNameInitials } from '../../../../lib/utils/get-name-initials';

export type AvatarOption = {
  value: string;
  avatarSrc: string;
  label: React.ReactNode;
  subtitle?: string;
  suffix?: string;
  isNew?: boolean;
  disabled?: boolean;
};

type AvatarSelectOptionProps = OptionProps<AvatarOption>;

export const AvatarSelectOption: React.FC<AvatarSelectOptionProps> = ({ data, ...props }) => {
  const { label, subtitle, suffix } = data;

  const avatarInitials = typeof label === 'string' ? getNameInitials(label) : '';

  return !data.isNew ? (
    <components.Option {...props} data={data}>
      <Container data-disabled={!!data.disabled}>
        <Left>
          <Avatar size="medium" src={data.avatarSrc} initials={avatarInitials} theme="dark" />

          <TextContainer>
            <Title>{label}</Title>

            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </TextContainer>
        </Left>

        {suffix && <Title data-capitalize="true">{suffix}</Title>}
      </Container>
    </components.Option>
  ) : null;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  gap: 1.6rem;
  padding: 0.8rem;
  border-radius: var(--border-radius-small);

  &[data-disabled='true'] {
    & * {
      color: var(--color-grayscale-trout);
    }

    & > div > *:first-child {
      opacity: 40%;
    }
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.6rem;
  min-width: 0;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & > * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Title = styled.p`
  text-align: start;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-grayscale-white);

  &[data-capitalize='true'] {
    text-transform: capitalize;
  }
`;

const Subtitle = styled.span`
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--color-grayscale-light-slate);
`;
