import React from 'react';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import styled from 'styled-components';

import { Project } from '../../../app/entities/project';
import { GanttChart } from '../../../components/gantt-chart/gantt-chart';
import { StepGanttHeader } from './step-gantt-header';
import { authStore } from '../../../core/stores/auth-store';

interface CalendarProps {
  project: Project;
}

export const CalendarTab: React.FC<CalendarProps> = observer(({ project }) => {
  const currentMember = authStore.getCurrentMember(project.spaceId);

  const isViewOnly = currentMember?.role === 'guest';

  const ganttItems: React.ComponentProps<typeof GanttChart>['items'] = project?.stages.map(
    (stage, index) => ({
      id: stage._id,
      name: stage.name,
      nameSuffix: <StageNumber>{(index + 1).toString().padStart(2, '0')}</StageNumber>,
      ...(stage.startDate && { startDate: new Date(stage.startDate) }),
      ...(stage.dueDate && { dueDate: new Date(stage.dueDate) }),
      ...(stage.completedSteps && { completed: stage.completedSteps }),
      ...(stage.totalSteps && { total: stage.totalSteps }),
      subItems: stage.steps?.map((step) => ({
        id: step._id,
        name: step.displayName,
        onItemUpdate: (values: { dueDate?: Date; startDate?: Date }) => {
          const startDate =
            (values.startDate && DateTime.fromJSDate(values.startDate).toISO()) || undefined;
          const dueDate =
            (values.dueDate && DateTime.fromJSDate(values.dueDate).toISO()) || undefined;

          if (startDate || dueDate) {
            step.update({
              ...(startDate && { startDate }),
              ...(dueDate && { dueDate }),
            });
          }
        },
        isDone: step.done,
        sideHeader: <StepGanttHeader step={step} isViewOnly={isViewOnly} />,
        ...(step.startDate && { startDate: new Date(step.startDate) }),
        ...(step.dueDate && { dueDate: new Date(step.dueDate) }),
      })),
    }),
  );

  return (
    <Container>
      <Wrapper>
        <GanttChart items={ganttItems} />
      </Wrapper>
    </Container>
  );
});

const Container = styled.div`
  padding-top: 1.6rem;
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding-bottom: 2.4rem;
  overflow-x: clip;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 100%;
  width: fit-content;
`;

const StageNumber = styled.div`
  font-size: 1.4rem;
  color: var(--color-surfaces-app-background-0);
  border-radius: 0.4rem;
  background-color: var(--color-texts-high-contrast);
  padding: 0.1rem 0.4rem;
  font-weight: 600;
`;
