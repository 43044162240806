import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { Contacts, Project, Settings } from '../../components/icons';
import { SpaceMenuItem } from './space-menu-item';

import type { Space } from '../entities/space';
import { breakPoint } from '../theme';
import { authStore } from '../../core/stores/auth-store';

type ProjectMenuProps = {
  space: Space;
  onClose: () => void;
};

export const SpaceMenu: React.FC<ProjectMenuProps> = observer(({ space, onClose }) => {
  let location = useLocation();
  const navigate = useNavigate();

  const { spaceId } = useParams();
  const pathname = location.pathname.split('/');

  const goTo = (href: string) => {
    onClose();
    navigate(href);
  };

  const currentMember = authStore.getCurrentMember(space._id);

  const isProject = pathname.includes('projects') && !pathname.includes('settings');

  return (
    <Container>
      <SpaceMenuItem
        id="menu-projects"
        onClick={() => goTo(`/space/${spaceId}`)}
        name={'Projects'}
        current={pathname.length === 3 || isProject}
        icon={<Project />}
      />

      {currentMember?.role !== 'guest' && (
        <SpaceMenuItem
          id="menu-contacts"
          onClick={() => goTo(`/space/${spaceId}/contacts`)}
          current={!!pathname.find((el) => el === 'contacts')}
          name={'Contacts'}
          icon={<Contacts />}
        />
      )}

      {/* TODO: uncomment when analytics feature is implemented*/}
      {/*<SpaceMenuItem
        id="menu-insights"
        onClick={() => goTo(`/space/${spaceId}/analytics`)}
        current={pathname[pathname.length - 1] === 'analytics'}
        name={'Analytics'}
        icon={<Analytics />}
      />*/}

      <SpaceMenuItem
        id="menu-settings"
        onClick={() => goTo(`/space/${spaceId}/settings`)}
        current={pathname.includes('settings')}
        name={'Settings'}
        icon={<Settings />}
        isDisabled={!(space.flags || {})['space.settings']}
      />
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 0 1.6rem;
  flex: 1;
  order: 2;

  @media screen and (min-width: ${breakPoint.medium}px) {
    gap: 0.8rem;
    padding: 1.6rem;
  }
`;
