import React, { useContext } from 'react';
import styled from 'styled-components';

import { CollapsibleItem } from '../../collapsible-item/collapsible-item';
import { StageHeader } from '../../../features/process/components/stage-header';
import { GanttChartContext } from '../context/gantt-chart.context';

import type { GanttChartProps } from '../gantt-chart';

export const Sidebar = React.forwardRef<HTMLDivElement, GanttChartProps>(({ items }, ref) => {
  const { handleItemsCollapse } = useContext(GanttChartContext);

  return (
    <Container ref={ref}>
      {items?.map((strip, index) => (
        <CollapsibleItem
          key={strip.id}
          title={
            <CollapsibleContainer>
              <HeaderWrapper>
                <StageHeader
                  name={strip.name}
                  index={index + 1}
                  total={strip.total || 0}
                  completed={strip.completed || 0}
                  isActive
                />
              </HeaderWrapper>
            </CollapsibleContainer>
          }
          triggerType="transparent-small"
          padding="none"
          onCollapseChange={(isOpen) => {
            handleItemsCollapse?.(strip.id, !isOpen);
          }}
          defaultOpen
          isTransparent
        >
          <SubList>
            {strip.subItems?.map((subItem) => {
              return subItem.sideHeader ? (
                subItem.sideHeader
              ) : (
                <CollapsibleContainer key={subItem.id}>
                  <HeaderWrapper data-subItem="true">{subItem.name}</HeaderWrapper>
                </CollapsibleContainer>
              );
            })}
          </SubList>
        </CollapsibleItem>
      ))}
    </Container>
  );
});

Sidebar.displayName = 'Sidebar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  z-index: 10;
  min-width: 24rem;
  max-width: 24rem;
  width: 24rem;
  padding-inline-end: 1.6rem;
  background-color: var(--color-surfaces-bg-elevation-1);
  gap: 0.4rem;

  & #collapsible-trigger {
    gap: unset;

    & svg {
      position: absolute;
      left: 0.8rem;
    }
  }
`;

const CollapsibleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
`;

const HeaderWrapper = styled.div`
  min-width: 24rem;
  max-height: 4rem;
  background-color: var(--color-surfaces-bg-elevation-1);
  display: flex;
  align-items: end;

  &[data-subItem='true'] {
    padding: 1.2rem 1.6rem;
    background-color: var(--color-grayscale-eerie-black);
    cursor: default;

    color: var(--color-grayscale-white, #fafafa);
    /* Body - Regular */
    font-size: 1.6rem;
    font-weight: 400;

    &:hover {
      cursor: pointer;
    }
  }

  & > div > div {
    &:first-child {
      padding: 0.9rem 1.6rem 0 4rem;
      margin-bottom: 0.9rem;

      h3 {
        font-weight: 400;
      }

      & > :last-child {
        display: none;
      }
    }

    &:last-child {
      & > div {
        border-radius: 0;
        background-color: var(--color-grayscale-arsenic);
        height: 0.4rem;
      }

      & * {
        border-radius: 0 0.4rem 0.4rem 0;
      }
    }
  }
`;

const SubList = styled.div`
  margin-top: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  user-select: none;
`;
