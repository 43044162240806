import React, { useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { Avatar } from '@producer-io/ui-kit';
import { Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';

import { CommentCheckbox } from './CommentCheckbox';
import { Tooltip } from '../tooltip/Tooltip';
import { dateAndTimeShort } from '../../assets/contants/dates-fns';
import { secondsToHms } from '../../lib/utils/Convertor';
import { Brush } from '../icons/Brush';
import { Storyboard } from '../icons/Storyboard';
import { catchError } from '../../core/catch-error';
import { breakPoint } from '../../app/theme';
import { CommentForm } from './comment-form';

import type { Comment } from '../../app/entities/comment';

interface CommentItemProps {
  comment: Comment;
  onClick?: (comment: Comment) => void;
  isReply?: boolean;
}

export const CommentItem: React.FC<CommentItemProps> = observer(({ comment, onClick, isReply }) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isReplying, setIsReplying] = useState<boolean>(false);

  const handleClick = () => {
    onClick && onClick(comment);
  };

  const startUpdate = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsUpdating((prev) => !prev);
  };

  const stopUpdate = (e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }

    setIsUpdating(false);
  };

  const handleDelete = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await comment.delete();
  };

  const handleUpdate = async (text: string) => {
    await comment.update({ text });
    stopUpdate();
  };

  const startReply = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsReplying(true);
  };

  const stopReply = () => {
    setIsReplying(false);
  };

  const handleReply = async (text: string) => {
    try {
      await comment.reply(text);
    } catch (e) {
      catchError(e);
    }
    stopReply();
  };

  const handleValidateComment = async () => {
    await comment.complete(!comment.completed);
  };

  return (
    <Container id={comment._id} onClick={handleClick}>
      <Header>
        <Left>
          <AvatarWrapper>
            <Avatar
              src={comment.author?.avatar}
              initials={comment.authorInitials}
              size="small"
              theme="dark"
            />
          </AvatarWrapper>

          {comment.authorName && <Author>{comment.authorName}</Author>}

          {comment.createdAt && (
            <TimeStamp>
              {comment.createdAt ? format(new Date(comment.createdAt), dateAndTimeShort) : '-'}
            </TimeStamp>
          )}
        </Left>

        {isReply && (
          <Actions>
            {comment.isAuthor && (
              <>
                <ActionContainer onClick={startUpdate}>
                  <Pencil1Icon />
                </ActionContainer>

                <ActionContainer onClick={handleDelete}>
                  <TrashIcon />
                </ActionContainer>
              </>
            )}
          </Actions>
        )}

        {!isReply && (
          <Tooltip text="Mark as completed" position="left">
            <CommentCheckbox checked={comment.completed} onClick={handleValidateComment} />
          </Tooltip>
        )}
      </Header>

      {isUpdating ? (
        <CommentForm
          formId={`update-comment-form-${comment._id}`}
          onSubmit={handleUpdate}
          defaultValue={comment.text}
          onCancel={stopUpdate}
        />
      ) : (
        <>
          <CommentContainer>
            {!isReply && (
              <TimeCodeContainer>
                {comment.annotations &&
                  comment.annotations.length > 0 &&
                  comment.annotations[0].path && (
                    <IconContainer>
                      <Brush />
                    </IconContainer>
                  )}

                {comment.shotId && (
                  <IconContainer>
                    <Storyboard />
                  </IconContainer>
                )}

                {comment.timestamp && <TimeCode>{secondsToHms(comment.timestamp)}</TimeCode>}
              </TimeCodeContainer>
            )}

            <Text dangerouslySetInnerHTML={{ __html: comment.text }} />
          </CommentContainer>

          {!isReply && (
            <>
              <Footer>
                <span onClick={startReply}>Reply</span>

                {comment.isAuthor && (
                  <Actions>
                    <ActionContainer onClick={startUpdate}>
                      <Pencil1Icon />
                    </ActionContainer>

                    <ActionContainer onClick={() => comment.delete()}>
                      <TrashIcon />
                    </ActionContainer>
                  </Actions>
                )}
              </Footer>

              {isReplying && (
                <CommentForm
                  formId={`reply-to-comment-form-${comment._id}`}
                  onSubmit={handleReply}
                  onCancel={stopReply}
                />
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
});

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  opacity: 0;
  gap: 1.2rem;
  transform: translateX(1rem) scale(0.95);
  transition: all 0.1s ease-in;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  position: relative;
  padding: 0.8rem 0 0.8rem 0.8rem;

  transition: all 100ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
  background-color: transparent;
  overflow-x: hidden;

  ${CommentCheckbox} {
    opacity: 1;
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    &:hover {
      cursor: pointer;

      ${Actions} {
        opacity: 1;
        transform: translateX(0) scale(1);
      }

      ${CommentCheckbox} {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: ${breakPoint.medium - 1}px) {
    ${Actions} {
      opacity: 1;
      transform: translateX(0) scale(1);
    }
  }
`;

const Header = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;
`;

const Left = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  min-width: 0;
`;

const AvatarWrapper = styled.div`
  @media screen and (max-width: ${breakPoint.medium - 1}px) {
    & span {
      &:first-of-type {
        width: 1.6rem;
        height: 1.6rem;
        min-width: 1.6rem;
        min-height: 1.6rem;
        border-radius: 0.4rem;
      }
    }
  }
`;

const IconContainer = styled.div`
  color: var(--text-color-white);
  font-size: 1.6rem;
  font-weight: 500;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    fill: var(--color-primary-crayola);
  }
`;

const Text = styled.div`
  color: var(--color-grayscale-white);
  font-size: 1.2rem;
  font-weight: 400;

  @media screen and (min-width: ${breakPoint.medium}px) {
    font-size: 1.4rem;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > span {
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--color-grayscale-shuttle);

    @media screen and (min-width: ${breakPoint.medium}px) {
      font-size: 1.4rem;
    }

    &:hover {
      color: var(--color-grayscale-white);
      transition: color 100ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
    }
  }
`;

const Author = styled.div`
  color: var(--color-grayscale-white);
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (min-width: ${breakPoint.medium}px) {
    font-size: 1.4rem;
  }
`;

const TimeStamp = styled.div`
  color: var(--color-grayscale-shuttle);
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  flex: 1 0 auto;
`;

const ActionContainer = styled.div`
  height: 1.6rem;
  width: 1.6rem;
  cursor: pointer;
  color: var(--color-grayscale-shuttle);

  svg {
    height: 1.6rem;
    width: 1.6rem;
  }

  &:hover {
    cursor: pointer;
    svg {
      color: var(--color-grayscale-white);
    }
  }
`;

const TimeCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
`;

const TimeCode = styled.div`
  color: var(--color-primary-crayola);
  font-size: 1.2rem;
  font-weight: 400;

  @media screen and (min-width: ${breakPoint.medium}px) {
    font-size: 1.4rem;
  }
`;

const CommentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
`;
