import { StoryboardSettings } from './models/storyboard-settings';

export const DEFAULT_STORYBOARD_SETTINGS: StoryboardSettings = {
  properties: [
    {
      type: 'none',
      name: 'Shot number',
      id: 'shotNumber',
    },
    {
      type: 'select',
      name: 'Angle',
      id: 'angle',
    },
    {
      type: 'select',
      name: 'Movement',
      id: 'movement',
    },
    {
      type: 'select',
      name: 'Shot size',
      id: 'shotSize',
    },
    {
      type: 'text',
      name: 'Camera lens',
      id: 'lens',
    },
    {
      type: 'text',
      name: 'Light',
      id: 'light',
    },
    {
      type: 'text',
      name: 'Props',
      id: 'props',
    },
    {
      type: 'time',
      name: 'Est. shooting time',
      id: 'estimatedTime',
    },
    {
      type: 'number',
      name: 'FPS',
      id: 'fps',
    },
    {
      type: 'location',
      name: 'Location',
      id: 'locationId',
    },
    {
      type: 'characters',
      name: 'Characters',
      id: 'characters',
    },
    {
      type: 'voiceOver',
      name: 'Voice over',
      id: 'voiceOver',
    },
    {
      type: 'directorsNote',
      name: "Director's note",
      id: 'directorsNote',
    },
  ],
};
