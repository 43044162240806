import { HeadlessService, IMessageId, IStoreQuery } from '@novu/headless';
import { makeObservable, runInAction } from 'mobx';

import { globalConfig } from '../../configuration/config';
import { Notification } from '../../app/entities/notification';

export class NovuService {
  private _userId: string;
  private _headlessService?: HeadlessService | null = null;

  notifications: Notification[] = [];
  page: number;
  totalCount: number = 0;
  pageSize: number = 20;
  isFetching: boolean;
  isLoading: boolean;
  hasMore: boolean = true;
  unreadCount: number = 0;

  public get isInitialized() {
    return !!this._headlessService;
  }

  public get userId() {
    return this._userId;
  }

  constructor() {
    this.isFetching = false;
    this.isLoading = false;

    makeObservable(this, {
      notifications: true,
      page: true,
      totalCount: true,
      pageSize: true,
      isFetching: true,
      isLoading: true,
      hasMore: true,
      unreadCount: true,
    });
  }
  /**
   * Initialize the session with Novu
   * @param {string} userId - The user id to initialize the session with as the subscriber for the notifications
   */
  async initializeSession(userId: string) {
    this._userId = userId;

    if (this.isInitialized && this._userId === userId) {
      return;
    }

    this._headlessService = new HeadlessService({
      applicationIdentifier: globalConfig.config.novuAppIdentifier!,
      subscriberId: `user:${userId}`,
    });

    this._headlessService?.initializeSession({
      listener: (result) => {
        const { error, isError, isFetching, isLoading } = result;

        runInAction(() => {
          this.isFetching = isFetching;
          this.isLoading = isLoading;
        });

        if (isError) {
          console.error(error);
        }
      },
      onError: (error) => {
        console.error(error);
      },
      onSuccess: (response) => {
        this.initializeListeners();
      },
    });
  }

  async setOnNotificationReceivedListener() {
    this._headlessService?.listenNotificationReceive({
      listener: (message) => {
        if (message) {
          runInAction(() => {
            this.notifications = [new Notification(message), ...this.notifications];
          });
        }
      },
    });
  }

  async setOnUnreadCountChangeListener() {
    this._headlessService?.listenUnreadCountChange({
      listener: (count) => {
        if (count) {
          runInAction(() => {
            this.unreadCount = count;
          });
        }
      },
    });
  }

  /**
   * Fetch the notifications for the current subscriber
   * @param {number} page - The page number to be fetched (default is 0)
   * @param {number} limit - The number of notifications to be fetched per page (default is 20)
   * @param {Omit<IStoreQuery, 'limit'>} options - The options to filter the notifications
   */
  async fetchNotifications(
    page: number = 0,
    limit: number = 20,
    options: Omit<IStoreQuery, 'limit'> = {},
  ) {
    this._headlessService?.fetchNotifications({
      listener: (result) => {
        const { error, isError, isFetching, isLoading } = result;

        runInAction(() => {
          this.isFetching = isFetching;
          this.isLoading = isLoading;
        });

        if (isError) {
          console.error(error);
        }
      },
      onError: (error) => {
        console.error(error);
      },
      onSuccess: (response) => {
        const { data, page, totalCount, pageSize, hasMore } = response;

        /* onSuccess callback is triggered when marking a notification as read
         * this check below is to ignore any updates re-triggered from the callback
         * as it will be handled in the markNotificationsAsRead method
         */
        if (!this.hasMore || (page <= this.page && pageSize === this.pageSize)) {
          return;
        }

        const notifications =
          data?.map((notification) => {
            return new Notification(notification);
          }) || [];

        runInAction(() => {
          this.notifications = [...this.notifications, ...notifications];

          this.page = page;
          this.totalCount += totalCount;
          this.pageSize = pageSize;
          this.hasMore = hasMore;
        });
      },
      page,
      query: {
        limit,
        ...options,
      },
    });
  }

  /**
   * Fetch the unread count for the current subscriber
   */
  async fetchUnreadCount() {
    this._headlessService?.fetchUnreadCount({
      listener: (result) => {},
      onSuccess: (data) => {
        runInAction(() => {
          this.unreadCount = data.count;
        });
      },
      onError: (error) => {
        console.error(error);
      },
    });
  }

  async markNotificationsAsRead(notificationIds: IMessageId) {
    this._headlessService?.markNotificationsAsRead({
      messageId: notificationIds,
      listener: (result) => {},
      onSuccess: (messages) => {
        runInAction(() => {
          messages.forEach((message) => {
            const notification = this.notifications.find(
              (notification) => notification._id === message._id,
            );

            if (notification) {
              notification.read = true;
            }
          });

          this.unreadCount -= messages.length;
        });
      },
      onError: (error) => {
        console.error(error);
      },
    });
  }

  async initializeListeners() {
    this.fetchUnreadCount();
    this.fetchNotifications();
    this.setOnNotificationReceivedListener();
    this.setOnUnreadCountChangeListener();
  }
}

export const novuService = new NovuService();
