import { PaymentMethod as StripePaymentMethod } from '@stripe/stripe-js';
import { api } from 'api';
export interface PaymentMethod {
  id: string;
  title: string;
  cardNumber: string;
  isDefault: boolean;
}

export const fetchPaymentMethods = async (spaceId: string) => {
  const { data } = await api.get<{ paymentMethods: StripePaymentMethod[] }>(
    `/spaces/${spaceId}/payment-methods`,
  );

  return data.map((paymentMethod: StripePaymentMethod) => ({
    id: paymentMethod.id,
    title: paymentMethod.card?.brand || '',
    cardNumber: `**** ${paymentMethod.card?.last4}`,
    isDefault: false,
  }));
};

export interface PreviewAddMemberUpdatesResponse {
  currentPayment: {
    amount: string;
    currency: string;
  };
  nextPayment: {
    amount: string;
    currency: string;
    date: string;
  };
}

export const previewAddMemberUpdates = async (
  spaceId: string,
  numberOfMembers: number = 1,
): Promise<PreviewAddMemberUpdatesResponse | null> => {
  const { data } = await api.post(`/payment/space/${spaceId}/preview-add-member-updates`, {
    numberOfMembers,
  });
  return data;
};

export const getTransactionDetails = async (spaceId: string, transactionId: string) => {
  const { data } = await api.get(`/payment/spaces/${spaceId}/transactions/${transactionId}`);

  return data;
};
