import React, { useRef, useEffect, useState } from 'react';
import { useIsOutOfBound } from './useIsOutOfBound';

type useAdaptiveDropdownPropsType = {
  isOpen: boolean;
  menuMargin?: number; // margin between dropdown button and options menu in px
  setPosition: React.Dispatch<
    React.SetStateAction<
      | {
          x?: number | undefined;
          y?: number | undefined;
        }
      | undefined
    >
  >;
};

// TODO: Remove and use radix instead asap
export const useAdaptiveDropdown = ({
  isOpen,
  setPosition,
  menuMargin = 4,
}: useAdaptiveDropdownPropsType) => {
  const dropdownButtonDimensions = useRef<HTMLInputElement>(null);
  const { isOutOfBound, outOfBoundRef } = useIsOutOfBound(isOpen);

  useEffect(() => {
    if (isOutOfBound?.bottom) {
      /*  New y-position should be (position set at handleOpenDropdown function)
          minus (dropdown button height)
          minus (height of the options menu)
          minus 4px margin set from handleOpenDropdown function
          minus 4px added margin between dropdown button and options menu
      */
      setPosition((currPosition) => {
        if (currPosition) {
          return {
            ...currPosition,
            y:
              (currPosition?.y || 0) -
              (dropdownButtonDimensions?.current?.clientHeight || 0) -
              (outOfBoundRef?.current?.clientHeight || 0) -
              menuMargin * 2,
          };
        }
        return currPosition;
      });
    }

    if (isOutOfBound?.right) {
      /*  New x-position should be (position set at handleOpenDropdown function)
          minus (width of the options menu)
          plus (dropdown button width)
      */
      setPosition((currPosition) => {
        if (currPosition) {
          return {
            ...currPosition,
            x:
              (currPosition?.x || 0) -
              (outOfBoundRef?.current?.clientWidth || 0) +
              (dropdownButtonDimensions?.current?.clientWidth || 0),
          };
        }
        return currPosition;
      });
    }
  }, [isOutOfBound, menuMargin, outOfBoundRef, setPosition]);

  return { outOfBoundRef, dropdownButtonDimensions };
};
