import { makeObservable, observable } from 'mobx';

import { Cover } from '../../core/models/cover';
import { Address } from '../../lib/utils/address';
import { Asset } from './asset';
import { Model } from '../../core/engine/model';
import { ManyToOne, OneToMany } from '../../core/engine/decorators';
import { Project } from './project';
import { Shot } from './shot';

export class Location extends Model {
  name: string;
  address?: Address;
  contact?: string;
  cover?: Cover;
  requirements?: string;
  nearestParking?: string;
  nearestParkingPin?: string;
  nearestHospital?: string;
  nearestHospitalPin?: string;

  @OneToMany('location')
  shots: Shot[] = [];

  /** The location's assets **/
  @OneToMany('location')
  assets: Asset[] = [];

  projectId: string;

  @ManyToOne('locations')
  public project: Project | null = null;

  constructor() {
    super('locations');

    makeObservable(this, {
      assets: observable,
      cover: observable,
    });
  }

  async update(input: Partial<Omit<this, '_id'>>) {
    await super.update(input);
    Object.assign(this, input);
  }

  static getOne(id: string): Location | undefined {
    return Model.getOne(id) as Location;
  }

  toPOJO(): Record<string, any> {
    return {};
  }
}
