import React from 'react';

export const FileTypeMP4 = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3.5C1.89543 3.5 1 4.39543 1 5.5V12.5C1 13.6046 1.89543 14.5 3 14.5H15C16.1046 14.5 17 13.6046 17 12.5V5.5C17 4.39543 16.1046 3.5 15 3.5H3ZM7 12.4641L13 9L7 5.5359L7 12.4641Z"
      fill="#9D9D9D"
    />
  </svg>
);
