import React from 'react';

import { Step } from '../../../app/entities/step';
import { ShotListScreen } from '../../../features/shots/screens/shot-list.screen';
import { Project } from '../../../app/entities/project';

interface ShotListPageProps {
  project: Project;
  step: Step;
}

export const ShotListPage: React.FC<ShotListPageProps> = ({ project, step }) => (
  <ShotListScreen project={project} step={step} />
);
