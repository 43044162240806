import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { BaseDialog, DialogFooter } from '../dialog';
import { ElevatedButton, OutlinedButton } from '../buttons';
import { CrossCircledFilledIcon } from '../icons/cross-circled-filled.icon';

export type SubscriptionFailureDialogProps = {
  onCancel: () => void;
  reason: 'default' | 'pastDue';
  onClick?: () => void;
};

export const SubscriptionFailureDialog: React.FC<SubscriptionFailureDialogProps> = observer(
  ({ onCancel, reason = 'default', onClick }) => {
    const [messageHeader, setMessageHeader] = useState('Subscription error');
    const [messageBody, setMessageBody] = useState('Something went wrong');
    const [buttonText, setButtonText] = useState('Click');

    useEffect(() => {
      switch (reason) {
        case 'pastDue': {
          setMessageHeader('There are unpaid invoices');
          setMessageBody('Please do the transfer first before adding new creators');
          break;
        }

        default: {
          break;
        }
      }
    }, [reason]);

    return (
      <BaseDialog onClose={onCancel}>
        <Container>
          <Icon>
            <CrossCircledFilledIcon />
          </Icon>

          <h1>{messageHeader}</h1>
          <p>{messageBody}</p>
        </Container>

        <DialogFooter
          actions={[
            <OutlinedButton key="cancel" text="Cancel" onClick={onCancel} autoFocus={!onClick} />,
            ...(onClick
              ? [
                  <ElevatedButton
                    key="click"
                    onClick={onClick}
                    text={buttonText}
                    autoFocus={!!onClick}
                  />,
                ]
              : []),
          ]}
        />
      </BaseDialog>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.8rem 3.2rem 2.4rem;
  max-width: 47.6rem;
  text-align: center;
  color: white;

  h1 {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 400;
    margin-bottom: 1.2rem;
  }

  p {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
  }
`;

const Icon = styled.div`
  position: relative;
  height: 9.6rem;
  width: 9.6rem;
  border: 0.2rem solid var(--color-error);
  background: var(--color-error-20);
  border-radius: 50%;
  margin-bottom: 3.2rem;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-error);
    height: 3.6rem;
    width: 3.6rem;
  }
`;
