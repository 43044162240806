import React from 'react';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { CastBreadcrumb } from '../components/cast-breadcrumb';
import { CharacterInfoTab } from '../components/character-info-tab';
import { ScreenHeader } from '../../projects/components/screen-header';
import { Tabs } from '../../../components/tabs/Tabs';
import { CandidatesTab } from '../components/candidates-tab';
import { LoaderContainer } from '../../../routes/project/components/step-container';
import { PageLoader } from '../../../app/components/page-loader';
import { getCurrentActiveTab } from '../../../lib/utils/get-current-active-tab';

import type { ScreenProps } from '../../projects/models/types';

const tabs = [
  {
    key: 'character',
    label: 'Character',
  },
  {
    key: 'candidates',
    label: 'Candidates',
  },
];

type CharacterDetailScreenProps = ScreenProps & {
  spaceId: string;
};

export const CharacterDetailScreen: React.FC<CharacterDetailScreenProps> = ({
  projectId,
  step,
  spaceId,
  isLoading,
}) => {
  const { characterId } = useParams() as { characterId: string };
  const location = useLocation();
  const navigate = useNavigate();

  const selectedTab = getCurrentActiveTab(tabs, 'character', location);

  return (
    <React.Fragment>
      <ScreenHeader
        breadcrumb={
          <CastBreadcrumb projectId={projectId} stepId={step._id} characterId={characterId!} />
        }
        stepId={step._id}
        title={step.name}
        dueDate={step.dueDate}
        startDate={step.startDate}
        handleUpdateTimeFrame={(values) => step.update(values)}
        canValidateStep={false}
      />

      {isLoading ? (
        <LoaderContainer>
          <PageLoader />
        </LoaderContainer>
      ) : (
        <TabsContainer>
          <Tabs
            tabs={tabs}
            onChange={(value: string) => {
              const path = location.pathname.split('/');
              if (['character', 'candidates'].includes(path[path.length - 1])) path.pop();
              path.push(value);
              navigate(path.join('/'), { replace: true });
            }}
            selectedTab={selectedTab}
          >
            <Routes>
              <Route path="/" element={<CharacterInfoTab characterId={characterId} />} />
              <Route path="/character" element={<CharacterInfoTab characterId={characterId} />} />
              <Route
                path="/candidates"
                element={<CandidatesTab characterId={characterId} spaceId={spaceId} />}
              />
            </Routes>
          </Tabs>
        </TabsContainer>
      )}
    </React.Fragment>
  );
};

const TabsContainer = styled.div`
  padding: 0 2.4rem 2.4rem;
  height: 100%;

  & > div {
    height: 100%;
  }
`;
