import { Contact } from '../../../../app/entities/contact';

const SEARCHABLE_FIELDS = [
  'firstName',
  'lastName',
  'fullName',
  'email',
  'role',
  'notes',
  'city',
  'country',
];

export const searchContacts = (contacts: Contact[], searchValue: string = '') => {
  if (searchValue.trim() === '') return contacts;

  return contacts.filter((contact: any) =>
    SEARCHABLE_FIELDS.some((field) =>
      contact[field]?.toLowerCase().includes(searchValue.toLowerCase()),
    ),
  );
};
