import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { Project } from '../../../app/entities/project';
import { ContextualDrawer } from '../../../app/components/contextual-drawer';
import { getDone } from '../../../lib/utils/math';
import { ProjectContacts } from '../../../features/projects/components/project-contacts';
import { ProjectCollaborators } from '../../../features/projects/components/project-collaborators';
import { uiStore } from 'core/stores/ui-store';
import { MenuItem } from '../../../app/components/menu-item';
import { TimeFrame } from '../../../features/projects/components/timeframe';
import { StepStatusSelector } from '../../../features/process/components/step-status-selector';
import { DateSelector } from '../../../components/date-selector/date-selector';
import {
  Action,
  Label,
  Properties,
  Property,
} from '../../../features/projects/components/step.drawer';
import { PrioritySelector } from '../../../features/process/components/priority-selector';

interface ProjectRootScreenProps {
  project: Project;
  selectedTab: string;
  onSelectTab: (tabKey: string) => void;
}

const tabs = [
  {
    key: 'overview',
    label: 'Project overview',
  },
  {
    key: 'share-links',
    label: 'Shared links',
  },
  {
    key: 'project-settings',
    label: 'Project settings',
  },
];

export const ProjectContextualDrawer: React.FC<ProjectRootScreenProps> = observer(
  ({ project, selectedTab, onSelectTab }) => {
    const { sum, done } = getDone(project.stages);

    const handleUpdateTimeFrame = (values: { dueDate?: string; startDate?: string }) => {
      project.update(values);
    };

    return (
      <ContextualDrawer>
        <Container>
          <TopContainer>
            <Card>
              <Title>{project.name}</Title>

              {project.client?.name && <Client>{project.client.name}</Client>}

              <Properties>
                <Property>
                  <Label>Progress</Label>

                  <Action>
                    <Info>
                      {done}/{sum}
                    </Info>
                  </Action>
                </Property>

                <Property>
                  <Label>Start date</Label>

                  <Action>
                    <DateSelector
                      date={project.startDate}
                      max={project.dueDate}
                      field={'startDate'}
                      onUpdate={(startDate) => handleUpdateTimeFrame({ startDate })}
                    />
                  </Action>
                </Property>

                <Property>
                  <Label>Due date</Label>

                  <Action>
                    <DateSelector
                      date={project.dueDate}
                      min={project.startDate}
                      field={'dueDate'}
                      onUpdate={(dueDate) => handleUpdateTimeFrame({ dueDate })}
                    />
                  </Action>
                </Property>

                <Property>
                  <Label>Priority</Label>

                  <Action>
                    <PrioritySelector
                      priority={project.priority}
                      onChange={(priority) => {
                        project.update({ priority: priority });
                      }}
                    />
                  </Action>
                </Property>
              </Properties>
            </Card>

            <Card>
              <ProjectContacts
                projectId={project._id}
                spaceId={project.spaceId}
                contacts={project.contacts || []}
              />
            </Card>

            <Card>
              <Menu>
                {tabs.map((tab) => (
                  <MenuItem
                    key={tab.key}
                    id={tab.key}
                    onClick={() => {
                      onSelectTab(tab.key);
                      uiStore.contextDrawer?.close();
                    }}
                    name={tab.label}
                    current={tab.key === selectedTab}
                  />
                ))}
              </Menu>
            </Card>
          </TopContainer>

          <ProjectCollaborators project={project} spaceId={project.spaceId} collapsible />
        </Container>
      </ContextualDrawer>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  &:not(:last-child) {
    padding-bottom: 1.2rem;
    border-bottom: 1px solid var(--color-grayscale-arsenic);
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 1.4rem;
  color: var(--color-grayscale-white);
`;

const Client = styled.h3`
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--color-grayscale-cadet);
`;

const Info = styled.span`
  font-weight: 500;
  font-size: 1.2rem;
  color: var(--color-grayscale-white);
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
`;
