import React from 'react';
import { observer } from 'mobx-react-lite';

import { createProjectLocation } from '../../locations/location.slice';
import { SingleSelectDropdown } from '../../../components/select-dropdown';

import type { Project } from '../../../app/entities/project';

type ShotLocationSelectorProps = {
  project: Project;
  onChange: (value: string) => void;
  selectedLocationId: string | undefined;
};

export const ShotLocationSelector: React.FC<ShotLocationSelectorProps> = observer(
  ({ project, onChange, selectedLocationId }) => {
    const options: React.ComponentProps<typeof SingleSelectDropdown>['options'] =
      project.locations?.map((location) => ({
        label: location.name,
        value: location._id,
      }));

    const handleLocationChange = (value?: string) => {
      onChange(value || '');
    };

    const handleCreate = async (name: string) => {
      return createProjectLocation({ projectId: project._id, name }).then((location) => {
        onChange(location._id);
      });
    };

    return (
      <SingleSelectDropdown
        title="Location"
        value={selectedLocationId}
        options={options}
        onValueChange={handleLocationChange}
        onCreate={handleCreate}
      />
    );
  },
);
