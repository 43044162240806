import React from 'react';

import { SelectDropdown } from './select-dropdown';

import type { MultiSelectDropdownProps } from '.';

export const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  title,
  options,
  selectedOptions,
  onValueChange,
  onCreate,
}) => {
  const labels: { [key: string]: string } = {};

  const items: React.ComponentProps<typeof SelectDropdown>['items'] = options?.map((option) => {
    labels[option.value] = option.label;

    return {
      title: option.label,
      isChecked: selectedOptions?.some((value) => value === option.value),
      onCheckedChange: (isChecked) => {
        onValueChange(option.value, isChecked);
      },
    };
  });

  const selectedItemsLabel = selectedOptions?.map((value) => labels[value] || '').join(', ');

  return (
    <SelectDropdown
      title={title}
      selectedItemsLabel={selectedItemsLabel}
      items={items}
      onCreate={onCreate}
      isMulti
    />
  );
};
