import React from 'react';

export const Diapo = () => (
  <svg width="20px" height="21px" viewBox="0 0 20 21" version="1.1">
    <g id="Web-App" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Notifications-Menu" transform="translate(-20.000000, -111.000000)" fill="#FFFFFF">
        <g id="Left-Column" transform="translate(0.000000, 1.000000)">
          <g id="Menu" transform="translate(19.000000, 32.000000)">
            <g id="projects" transform="translate(1.000000, 78.842817)">
              <g id="icon/current-projects">
                <path d="M0.901136866,17.8578495 L0.0112252146,8.97643915 C-0.115346166,7.71324368 0.843692244,6.65340231 2.11830198,6.65340231 L17.6719205,6.65340231 C18.9438965,6.65340231 19.9057872,7.71106264 19.7789973,8.97643915 L18.8890856,17.8578495 C18.7714032,19.0323327 17.7457914,19.9602069 16.5666422,19.9602069 L3.22358031,19.9602069 C2.04003301,19.9602069 1.01918588,19.0359908 0.901136866,17.8578495 Z M17.555358,8.87996744 L2.23445425,8.87587302 L3.22844459,17.7424062 L16.5617779,17.7424062 L17.555358,8.87996744 Z M4.3395557,1.10890038 C4.3395557,0.496471613 4.83856257,0 5.44555299,0 L14.3446695,0 C14.955495,0 15.4506668,0.492201872 15.4506668,1.10890038 C15.4506668,1.72132916 14.9516599,2.21780077 14.3446695,2.21780077 L5.44555299,2.21780077 C4.83472755,2.21780077 4.3395557,1.7255989 4.3395557,1.10890038 Z M2.11733347,4.43560154 C2.11733347,3.82317277 2.61727654,3.32670115 3.22358031,3.32670115 L16.5666422,3.32670115 C17.1776054,3.32670115 17.672889,3.81890303 17.672889,4.43560154 C17.672889,5.04803031 17.172946,5.54450192 16.5666422,5.54450192 L3.22358031,5.54450192 C2.61261705,5.54450192 2.11733347,5.05230005 2.11733347,4.43560154 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
