import { useEffect, useRef } from 'react';

const addScriptToHead = (token: string, features: string[]) => {
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');

  window.userGuidingLayer = window.userGuidingLayer || [];

  if (window.userGuiding) return;

  const ug: any = (window.userGuiding = {
    q: [],
  });

  ug.c = (n: string) => {
    return () => {
      ug.q.push([n]);
    };
  };

  features.forEach((key) => {
    ug[key] = ug.c(key);
  });

  script.src = `https://static.userguiding.com/media/user-guiding-${token}-embedded.js`;
  script.async = true;

  head.appendChild(script);
};

export const useUserGuiding = (): void => {
  const isInitialized = useRef(false);

  useEffect(() => {
    if (isInitialized.current) return;
    const token = '36W69531JNXID';
    const features = [
      'previewGuide',
      'finishPreview',
      'track',
      'triggerNps',
      'hideChecklist',
      'launchChecklist',
    ];

    addScriptToHead(token, features);

    isInitialized.current = true;
  }, []);
};
