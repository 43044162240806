import React, { Ref, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { KeyboardKeys } from '../../assets/enums/keyboard-keys.enum';

interface NumberInputProps {
  value?: number;
  byStep?: number;
  label?: string;
  onChange: (value: number) => void;
  onKeyDown?: (e: any) => void;
  hasError?: boolean;
  disabled?: boolean;
  required?: boolean;
  dark?: boolean;
  canNeg?: boolean;
  errorMessage?: string | undefined | any;
  placeholder?: string;
  autocomplete?: string;
  type?: string;
  min?: number;
  defaultValue?: number;
  icon?: React.ReactNode;
  ref?: Ref<HTMLInputElement>;
}

export const NumberInputDrawer: React.FC<NumberInputProps> = ({
  value,
  label,
  onChange,
  disabled = false,
  canNeg = false,
  placeholder,
  required,
  min = 1,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [_value, setValue] = useState(value?.toString() || '');

  useEffect(() => {
    setValue(value?.toString() || '');
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleOnBlur = async () => {
    if (_value !== value?.toString()) {
      onChange(parseFloat(_value));
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KeyboardKeys.Escape) {
      setValue((value || '').toString());
      return;
    }

    if (e.key === KeyboardKeys.Enter && value?.toString() !== _value) {
      inputRef!.current?.blur();
      return;
    }
  };

  return (
    <NumberInputContainer>
      <MyLabel>
        {label} {required && <b>*</b>}
      </MyLabel>

      <Input
        ref={inputRef}
        value={_value}
        onChange={handleChange}
        onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
        disabled={disabled}
        type={'number'}
        placeholder={placeholder}
        onBlur={handleOnBlur}
        onKeyDown={handleKeyDown}
        min={min}
      />
    </NumberInputContainer>
  );
};

const NumberInputContainer = styled.div`
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  min-width: 15rem;
  z-index: 99;
  width: 100%;
  color: var(--select-text-color);

  svg {
    fill: var(--select-border-color);
    width: 1.6rem;
    position: absolute;
    right: 1.6rem;
  }
`;

const MyLabel = styled.div`
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: 0.7px;
  line-height: 1.1rem;
  margin-bottom: 0.8rem;
`;

const Input = styled.input`
  position: relative;
  box-sizing: border-box;
  width: 18rem;
  height: 2.5rem;
  border-radius: var(--card-border-radius);
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  line-height: 1.6rem;
  display: flex;
  z-index: 1;
  align-items: center;
  border: 1px solid var(--select-border-color);
  color: var(--select-text-color);
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  background: transparent;

  &:hover {
    cursor: text;
  }

  svg {
    width: 12px;
    height: 12px;
  }

  &:focus {
    outline: none;
    border: 1px solid var(--select-border-color);
    color: var(----select-text-color);
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
