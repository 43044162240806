import { action, makeObservable, observable } from 'mobx';

import { Address } from '../../dto/common/address.dto';
import { Stage } from './stage';
import { Model } from '../../core/engine/model';
import { api } from '../../api';
import { Asset } from './asset';
import { ManyToOne, OneToMany, OneToOne } from '../../core/engine/decorators';
import { entityPool } from '../../core/engine/engine';
import { Project } from './project';
import { Shot } from './shot';
import { catchError } from '../../core/catch-error';
import { Member } from './member';

export type StepStatus =
  | 'not-started'
  | 'in-progress'
  | 'on-hold'
  | 'needs-review'
  | 'changes-requested'
  | 'approved';

type ContactsCallTime = {
  hour: number;
  minute: number;
  period: string;
};

type ContactsMetaData = {
  contactId: string;
  callTime?: ContactsCallTime;
  note?: string;
  isHidden?: boolean;
};

type LocationsMetaData = {
  locationId: string;
  parking?: string;
};

export class Step extends Model {
  /** The step's name **/
  name: string;

  type: string;
  projectId: string;
  dueDate?: string;
  startDate?: string;
  timeZone?: string;
  validations: any[];
  phase: number;
  current: boolean;
  order: number;
  data: StepData[];
  status: StepStatus = 'not-started';

  get done(): boolean {
    return this.status === 'approved';
  }

  info?: StepInfo;

  productionNotes?: string;

  contactsMetaData?: ContactsMetaData[];

  locationsMetaData?: LocationsMetaData[];

  stageId: string;

  @ManyToOne('steps')
  stage?: Stage;

  assigneeId: string | null;

  @OneToOne()
  assignee: Member | null;

  @ManyToOne('steps')
  project: Project | null = null;

  /** The step's assets **/
  @OneToMany('step')
  assets: Asset[] = [];

  /** If the step is a shooting day, the shots for this day **/
  @OneToMany('step')
  shots: Shot[] = [];

  static StatusMapper: Record<StepStatus, { title: string; color: string }> = {
    'not-started': {
      title: 'Not Started',
      color: '#367bff',
    },
    'in-progress': {
      title: 'In Progress',
      color: '#367bff',
    },
    'on-hold': {
      title: 'On Hold',
      color: '#7a8296',
    },
    'needs-review': {
      title: 'Needs Review',
      color: '#f8a72d',
    },
    'changes-requested': {
      title: 'Changes Requested',
      color: '#db5c66',
    },
    approved: {
      title: 'Approved',
      color: '#38c2b8',
    },
  };

  constructor() {
    super('steps');

    this.status = 'not-started';

    makeObservable<Step>(this, {
      _id: observable,
      name: observable,
      type: observable,
      projectId: observable,
      status: observable,
      dueDate: observable,
      startDate: observable,
      validations: observable,
      phase: observable,
      current: observable,
      order: observable,
      data: observable,
      info: observable,
      contactsMetaData: observable,
      locationsMetaData: observable,
      assigneeId: observable,
      assignee: observable,
      stageId: observable,
      stage: observable,
      assets: observable,
      productionNotes: observable,
      update: action,
    });
  }

  get displayName() {
    return this.type === 'shootingDay' ? `(#${this.order}) ${this.name}` : this.name;
  }

  set displayName(_: string) {}

  static getOne(id: string): Step | undefined {
    return Model.getOne(id) as Step;
  }

  static async createShootingDay({
    projectId,
    dueDate,
  }: {
    projectId: string;
    dueDate?: string;
  }): Promise<Step | undefined> {
    try {
      const { data } = await api.post(`/projects/${projectId}/shooting-days`, {
        dueDate,
      });

      const { progress, strip, ...shootingDayData } = data;

      const shootingDay = Object.assign(new Step(), shootingDayData);
      entityPool.insert(shootingDay);

      const project = Project.getOne(projectId);
      const stripboard = project?.stripboard;

      if (stripboard) {
        stripboard.addStrip(strip);
      }

      const stage = Stage.getOne(shootingDayData.stageId)!;

      stage?.reorderShootingDays();
      stripboard?.reorderShootingDaysStrips(stage!.steps!);

      if (project) {
        project.progress = progress;
      }

      return shootingDay;
    } catch (e) {
      catchError(e);
    }
  }

  async update(values: Partial<Omit<this, '_id'>>) {
    const data = await super.update(values);

    if (values.contactsMetaData) {
      this.contactsMetaData = data.contactsMetaData;
    }

    if (values.locationsMetaData) {
      this.locationsMetaData = data.locationsMetaData;
    }

    if (values.status) {
      this.status = data.status;
    }

    if (values.assigneeId === null) {
      this.assignee = null;
    } else {
      this.assignee = (Member.getOne(values.assigneeId!) as Member) || null;
    }

    const project = Project.getOne(this.projectId);

    if (values.startDate !== undefined && this.type === 'shootingDay') {
      const stage = Stage.getOne(this.stageId);
      if (stage) {
        stage.reorderShootingDays();
      }

      if (project?.stripboard) {
        project.stripboard.reorderShootingDaysStrips(stage!.steps!);
      }
    }

    if (values.info?.generalCallTime) {
      const newCallTime = values.info.generalCallTime;

      if (project?.stripboard) {
        const dayIndex = project.stripboard.strips.findIndex((el) => el.data.stepId === this._id);

        if (!project.stripboard.strips[dayIndex].startTime) {
          project.stripboard.strips[dayIndex].startTime = {};
        }

        project.stripboard.strips[dayIndex].startTime.hour = newCallTime.hour;
        project.stripboard.strips[dayIndex].startTime.minute = newCallTime.minute;

        project.stripboard.updateSchedule();
      }
    }
  }

  async toggleHideContact(contactId: string) {
    const contact = this.contactsMetaData?.find((cont) => cont.contactId === contactId);
    const contactMeta = { contactId, isHidden: !contact?.isHidden };
    await this.update({ contactsMetaData: contactMeta } as any);
  }

  async delete() {
    try {
      const { data } = await api.delete(`/steps/${this._id}`);

      const project = Project.getOne(this.projectId);
      const stripboard = project?.stripboard;

      if (stripboard) {
        stripboard.removeShootingDayStrips(this);
      }

      const stage = Stage.getOne(this.stageId);
      if (stage) {
        stage.steps = stage.steps.filter((step) => step?._id !== this._id);
        stage.reorderShootingDays();
      }

      if (project) {
        project.progress = data.progress;
      }

      entityPool.delete(this);
    } catch (e) {
      catchError(e);
    }
  }

  toPOJO(): Record<string, any> {
    return {
      _id: this._id,
      name: this.name,
      type: this.type,
      projectId: this.projectId,
      status: this.status,
      dueDate: this.dueDate,
      startDate: this.startDate,
      validations: this.validations,
      phase: this.phase,
      current: this.current,
      order: this.order,
      data: this.data,
      info: this.info,
      productionNotes: this.productionNotes,
      contactsMetaData: this.contactsMetaData,
      locationsMetaData: this.locationsMetaData,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }
}

type StepInfo = {
  generalNote?: string;
  teamId?: string;

  generalCallTime?: {
    hour: number;
    minute: number;
  };

  weather?: {
    temp: {
      min: string;

      max: string;
    };

    sunrise: string;

    sunset: string;

    description: string;
  };

  hospital?: {
    name: string;
    phone: string;
    address: Address;
  };

  police?: {
    name: string;
    phone: string;
    address: Address;
  };
};

export type StepData = {
  assetId?: string;
  _id: string;
  name?: string;
  directorId?: string;
  producerId?: string;
  lineProducerId?: string;
  order?: number;
  directorsNote?: {
    createdAt: Date;
    text: string;
  };
  cast?: {
    _id: string;
    name: string;
    talents: string[];
  };
  crew?: {
    _id: string;
    name: string;
    talents: string[];
  };
  meta?: {
    status?: string;
    presentation?: {
      authorId: string;
      timestamp: Date;
      likes: string[];
      presentation: string;
    };
    tags: [
      {
        name: string;
        type: string;
      },
    ];
  };
};
