import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';

import { ProjectRootScreen } from './pages/project-root.screen';
import { StepPage } from './step.page';
import { fetchSpaceProjects } from '../../features/projects/store/projects.slice';
import { PageUnauthorized } from '../../components/unauthorized-placeholder/unauthorized-placeholder';
import { PageLoader } from '../../app/components/page-loader';
import { VideoComparePage } from './video-compare.page';
import { fetchStagesByProject } from '../../features/process/stages.slice';
import { fetchStepsByProject } from '../../features/process/step.slice';
import { Project } from '../../app/entities/project';
import { fetchProjectShots } from '../../features/shots/shots.slice';
import { fetchProjectLocations } from '../../features/locations/location.slice';
import { fetchProjectCharacters } from '../../features/character/character.slice';
import { tabPool } from '../../features/tabs/models/tabs-pool';
import { fetchStoryboards } from '../../features/storyboards/storyboard.slice';
import { uiStore } from '../../core/stores/ui-store';
import { fetchStripBoard } from '../../features/strip-board/strip-board.slice';
import { CrashErrorScreen } from './components/crash-error-screen';

export const ProjectPage = observer(() => {
  const { spaceId, projectId } = useParams() as { spaceId: string; projectId: string };
  const navigate = useNavigate();

  const [isAuthorized, setIsAuthorized] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const project = Project.getOne(projectId);

  /**
   * TODO URGENT
   *
   * How do we handle unauthorized errors ?
   */

  useEffect(() => {
    if (project) {
      uiStore.pageTitle = {
        label: project.name,
        href: `/space/${project.spaceId}/projects/${project._id}`,
      };

      uiStore.showBack = true;
      uiStore.goBack = () => navigate('../');
    }

    tabPool.updateTab({
      key: projectId,
      label: project?.name,
    });

    return () => {
      uiStore.pageTitle = undefined;
      uiStore.showBack = false;
      uiStore.goBack = null;
    };
  }, [project, projectId]);

  useEffect(() => {
    fetchSpaceProjects(spaceId!)
      .then(() => {
        return Promise.all([
          fetchStoryboards(projectId),
          fetchStripBoard(projectId),
          fetchStagesByProject(projectId),
          fetchStepsByProject(projectId),
          fetchProjectShots(projectId),
          fetchProjectLocations({ projectId }),
          fetchProjectCharacters({ projectId }),
        ]);
      })
      .then(() => {
        setIsLoading(false);
      });
  }, [spaceId, projectId]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Sentry.ErrorBoundary fallback={<CrashErrorScreen />}>
      {project && isAuthorized ? (
        <Routes>
          <Route
            path="/steps/:stepId/assets/:assetId/compare-versions"
            element={<VideoComparePage />}
          />
          <Route path="/steps/:stepId/*" element={<StepPage project={project} />} />
          <Route path="/*" element={<ProjectRootScreen project={project} />} />
        </Routes>
      ) : (
        <PageUnauthorized />
      )}
    </Sentry.ErrorBoundary>
  );
});
