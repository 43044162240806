import React from 'react';
import { observer } from 'mobx-react-lite';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { StripComponent } from './StripComponent';
import { useSelection } from '../../../core/contexts/selection.context';

import type { StripProps } from './StripComponent';

type SortableStripProps = StripProps & {
  isSelected?: boolean;
  isActive?: boolean;
  readOnly?: boolean;
};

export const SortableStrip: React.FC<SortableStripProps> = observer(
  ({ stripboard, strip, isSelected, isActive, readOnly }) => {
    const { select } = useSelection();

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: strip._id,
      data: strip.data,
      animateLayoutChanges: () => true,
      transition: {
        duration: 150,
        easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
      },
      disabled: readOnly || strip.type === 'dayBreak',
    });

    const isActiveCard = isActive && isSelected;

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      cursor: 'grab',
      borderRadius: '0.6rem',
      userSelect: 'none',
      ...(isActiveCard && !isDragging && { opacity: 0.5 }),
    };

    return (
      <StripComponent
        ref={setNodeRef}
        key={strip._id}
        stripboard={stripboard}
        strip={strip}
        style={style}
        onClick={select}
        isSelected={isSelected && !isActiveCard}
        isDragging={isDragging}
        screenType="desktop"
        {...attributes}
        {...listeners}
      />
    );
  },
);
