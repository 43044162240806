import React from 'react';

export const FilePreview: React.FC<{ text?: string }> = ({ text = 'docx' }) => (
  <svg width="211" height="211" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.75 0C14.4469 0 12.5625 1.88437 12.5625 4.1875V62.8125C12.5625 65.1156 14.4469 67 16.75 67H58.625C60.9281 67 62.8125 65.1156 62.8125 62.8125V16.75L46.0625 0H16.75Z"
      fill="#E2E5E7"
    />
    <path
      d="M50.25 16.75H62.8125L46.0625 0V12.5625C46.0625 14.8656 47.9469 16.75 50.25 16.75Z"
      fill="#B0B7BD"
    />
    <path d="M62.8125 29.3125L50.25 16.75H62.8125V29.3125Z" fill="#CAD1D8" />
    <path
      d="M54.4375 54.4375C54.4375 55.5891 53.4953 56.5312 52.3438 56.5312H6.28125C5.12969 56.5312 4.1875 55.5891 4.1875 54.4375V33.5C4.1875 32.3484 5.12969 31.4062 6.28125 31.4062H52.3438C53.4953 31.4062 54.4375 32.3484 54.4375 33.5V54.4375Z"
      fill="#367BFF"
    />
    <path
      d="M52.3438 56.5312H12.5625V58.625H52.3438C53.4953 58.625 54.4375 57.6828 54.4375 56.5312V54.4375C54.4375 55.5891 53.4953 56.5312 52.3438 56.5312Z"
      fill="#CAD1D8"
    />
    <text
      x="12"
      y="49"
      fill="white"
      style={{
        fontSize: '1.4rem',
        fontFamily: 'inter',
        fontWeight: '600',
        textTransform: 'uppercase',
      }}
    >
      {text}
    </text>
  </svg>
);
