import { dataURItoBlob, Frame } from '../../lib/utils/transform-image';

const IMAGE_TYPE = 'image/jpeg';
const THUMBNAIL_QUALITY = 0.5;

type FormatPictureOptions = {
  width?: number;
  height?: number;
  aspectRatio?: number;
};

const defaultOptions: FormatPictureOptions = {
  aspectRatio: 16 / 9,
};

export const formatPicture = (
  file: File,
  options: FormatPictureOptions = defaultOptions,
): Promise<Frame> => {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(file);
    const image = new Image();
    image.src = url;

    image.addEventListener('error', () => {
      reject();
    });

    image.addEventListener('load', () => {
      let y = 0;
      let x = 0;

      const canvas = document.createElement('canvas');

      canvas.width = options.width || image.width;
      canvas.height = options.height || image.height;

      const context = canvas.getContext('2d');

      if (!context) {
        return reject();
      }

      if (options.width && options.height) {
        if (image.height > image.width) {
          /**
           * Image is in portrait format
           */
          image.height = image.height * (options.width / image.width);
          image.width = options.width;
          y = -(image.height / 2 - canvas.height / 2);
        } else {
          /**
           * Image is in landscape format
           */
          image.width = image.width * (options.height / image.height);
          image.height = options.height;
          x = -(image.width / 2 - canvas.width / 2);
        }
      }

      context.drawImage(image, x, y, image.width, image.height);

      const canvasUrl = canvas.toDataURL(IMAGE_TYPE, THUMBNAIL_QUALITY);
      const blob = dataURItoBlob(canvasUrl);

      return resolve({
        data: blob,
        type: IMAGE_TYPE,
        size: blob.size,
      });
    });
  });
};
