import React from 'react';
import styled from 'styled-components';

export const DropArrow: React.FC = () => (
  <Arrow>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M295.6 163.7c-5.1 5-5.1 13.3-.1 18.4l60.8 60.9H124.9c-7.1 0-12.9 5.8-12.9 13s5.8 13 12.9 13h231.3l-60.8 60.9c-5 5.1-4.9 13.3.1 18.4 5.1 5 13.2 5 18.3-.1l82.4-83c1.1-1.2 2-2.5 2.7-4.1.7-1.6 1-3.3 1-5 0-3.4-1.3-6.6-3.7-9.1l-82.4-83c-4.9-5.2-13.1-5.3-18.2-.3z" />
    </svg>
  </Arrow>
);

const Arrow = styled.div`
  height: 9.6rem;
  width: 9.6rem;
  border-radius: 50%;
  background-color: var(--color-secondary);
  animation: bounce 1.5s ease-in-out infinite;

  svg {
    width: 9.6rem;
    fill: white;
    transform: rotate(-90deg);
  }

  @keyframes bounce {
    0% {
      transform: translateY(-0.2rem);
    }

    50% {
      transform: translateY(0.2rem);
    }

    100% {
      transform: translateY(-0.2rem);
    }
  }
`;
