import React from 'react';

type Year19xx = `19${'7' | '8' | '9'}${'0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'}`;
type Year2xxx = `2${'0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'}${
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'}${'0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'}`;

export type Years = Year19xx | Year2xxx;

export type Days =
  | `${'1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'}`
  | `1${'0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'}`
  | `2${'0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8'}`
  | `3${'0' | '1'}`;

export const Months: { [key: number]: string } = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

export enum WeekDays {
  Sun = 'Sun',
  Mon = 'Mon',
  Tue = 'Tue',
  Wed = 'Wed',
  Thu = 'Thu',
  Fri = 'Fri',
  Sat = 'Sat',
}
export type DayData = {
  date: Date;
  weekDay: WeekDays;
  isWeekend: boolean;
  isToday: boolean;
};

export type GanttDroppableCellRef = {
  current: HTMLDivElement | null;
  date: Date;
};

export type GanttDraggableItemRef = {
  current: HTMLDivElement | null;
  startX?: number;
};

export type GanttItem = {
  id: string;
  name: string;
  nameSuffix?: React.ReactNode;
  sideHeader?: React.ReactNode;
  startDate?: Date;
  dueDate?: Date;
  total?: number;
  completed?: number;
  isDone?: boolean;
  onItemUpdate?: (values: { dueDate?: Date; startDate?: Date }) => void;
  subItems?: Omit<GanttItem, 'subItems'>[];
};

export type ChartItem = GanttItem & {
  parentId?: string;
  startDateIndex?: number;
  durationInDays?: number;
  isCollapsed?: boolean;
  positionLeft?: number;
};

export type DateObject = {
  day: Days;
  month: keyof typeof Months;
  year: Years;
};

export type DayProps = {
  date: Date;
  day: Days;
  month: number;
  year: Years;
  weekDay: WeekDays;
  isToday: boolean;
  isWeekend: boolean;
};
