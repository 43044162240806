import React from 'react';
import styled from 'styled-components';
import { ProducerLogo } from '../logo/producer-logo';

export const PageLoader = () => (
  <PageLoaderItem>
    <Pulse />
    <Logo>
      <ProducerLogo />
    </Logo>
  </PageLoaderItem>
);

const PageLoaderItem = styled.div`
  position: relative;
  width: 9.6rem;
  height: 9.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Pulse = styled.div`
  position: absolute;
  border: 4px solid var(--color-primary);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  animation: ripple-loader 1.3s ease-out infinite;
  transform: translate(-50%, -50%);

  @keyframes ripple-loader {
    0% {
      width: 2.6rem;
      height: 2.6rem;
      opacity: 1;
    }
    100% {
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
`;

const Logo = styled.div`
  margin-left: 2px;
  width: 3.2rem;
  height: 3.2rem;
  transform-origin: center;
  animation: ripple-logo 1.3s ease-out infinite;

  svg {
    fill: white;
  }

  @keyframes ripple-logo {
    0% {
      transform: scale(0.95);
    }
    25% {
      transform: scale(1.05);
    }
    75% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(0.95);
    }
  }
`;
