import React from 'react';

export const LocationPin = () => (
  <svg width="11px" height="16px" viewBox="0 0 11 16">
    <g id="Design-System">
      <g id="Basic-UI-Elements" transform="translate(-60.000000, -321.000000)" fillRule="nonzero">
        <g id="icon/square" transform="translate(60.000000, 321.000000)">
          <path d="M5.33333333,0 C2.53466667,0 0,2.26866667 0,5.068 C0,7.86666667 2.31266667,11.208 5.33333333,16 C8.354,11.208 10.6666667,7.86666667 10.6666667,5.068 C10.6666667,2.26866667 8.13266667,0 5.33333333,0 Z M5.33333333,7.33333333 C4.22866667,7.33333333 3.33333333,6.438 3.33333333,5.33333333 C3.33333333,4.22866667 4.22866667,3.33333333 5.33333333,3.33333333 C6.438,3.33333333 7.33333333,4.22866667 7.33333333,5.33333333 C7.33333333,6.438 6.438,7.33333333 5.33333333,7.33333333 Z" />
        </g>
      </g>
    </g>
  </svg>
);
