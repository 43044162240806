import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { MenuItem } from '../../../app/components/menu-item';

type MenuOptions = {
  key: string;
  label: string;
  href: string;
};

type SpaceSettingsMenuProps = {
  items: MenuOptions[];
};

export const SettingsMenu: React.FC<SpaceSettingsMenuProps> = ({ items }) => {
  const navigate = useNavigate();
  const pathname = window.location.pathname.split('/');
  const location = useLocation() as { state: { prevPath: string } };

  const goTo = (href: string) => navigate(href, { state: location.state });

  return (
    <Menu>
      {items.map(({ key, label, href }) => (
        <MenuItem
          key={key}
          id={key}
          onClick={() => goTo(href)}
          name={label}
          current={(pathname.length === 4 && key === 'general') || pathname.includes(key)}
        />
      ))}
    </Menu>
  );
};

const Menu = styled.div`
  flex: 1;
  width: 100%;
`;
