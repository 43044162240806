import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { AssetGrid } from '../../../components/grid';
import { LocationCard } from './location-card';

import type { Location } from '../../../app/entities/location';

type LocationListProps = {
  locations: Location[];
};

export const LocationList: React.FC<LocationListProps> = observer(({ locations }) => (
  <Container>
    {locations.map((location) => (
      <LocationCard key={location._id} location={location} />
    ))}
  </Container>
));

const Container = styled(AssetGrid)`
  padding-bottom: var(--spacing-bottom-screen-container);
`;
