import React, { useId } from 'react';
import styled from 'styled-components';

export type SwitchProps = {
  isToggled: boolean;
  onToggle: (value: boolean) => void;
  name?: string;
  dark?: boolean;
  isDisabled?: boolean;
  grayscale?: boolean;
  size?: 'default' | 'medium' | 'large';
};

export const Switch: React.FC<SwitchProps> = ({
  isToggled = true,
  onToggle,
  name,
  isDisabled,
  size = 'default',
  grayscale,
}) => {
  const id = useId();
  const handleToggle = () => onToggle(!isToggled);

  return (
    <Wrapper data-size={size} data-grayscale={grayscale}>
      <CheckBox
        id={id}
        type="checkbox"
        onChange={handleToggle}
        checked={isToggled}
        defaultChecked={isToggled}
        name={name}
        disabled={isDisabled}
        data-size={size}
      />

      <Label htmlFor={id} data-size={size} />
    </Wrapper>
  );
};

const Label = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 2.4rem;
  height: 1.2rem;
  border-radius: 15px;
  background: var(--color-grayscale-arsenic);
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50%;
    width: 1.2rem;
    height: 1.2rem;
    background: var(--color-grayscale-cadet););
    transition: 0.2s;
  }

  &[data-size='medium'] {
    height: 1.6rem;
    width: 3.2rem;
    border-radius: 5rem;
    padding: 0.4rem;

    &::after {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  &[data-size='large'] {
    width: 6.4rem;
    height: 3.2rem;
    border-radius: 5rem;

    &::after {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 1.5rem;
  width: 2.4rem;
  height: 1.2rem;
  background: var(--color-grayscale-arsenic);

  &:disabled + ${Label} {
    opacity: 0.5;
  }

  &:checked + ${Label} {
    &::after {
      left: calc(100% - 1.2rem);
      background: var(--color-secondary);
    }

    &[data-size='medium'] {
      &::after {
        left: calc(100% - 1.6rem);
      }
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  line-height: initial;
  height: 1.2rem;

  &[data-size='medium'] {
    height: 1.6rem;
    width: 3.2rem;
  }

  &[data-size='large'] {
    height: 3.2rem;
    width: 6.4rem;
  }

  &[data-grayscale='true'] {
    ${Label} {
      &::after {
        background: var(--color-secondary);
      }
    }
  }
`;
